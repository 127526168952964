import theme from "./theme"

export const setTheme = ({
  themeLightMode,
  themeColor
}: {
  themeLightMode: boolean
  themeColor: string
}) => {
  const themeTextColor = themeLightMode
    ? theme.studli.classroom.appBarTextLight
    : theme.studli.classroom.appBarTextDark

  theme.studli.classroom.themeColor = themeColor
  theme.studli.classroom.themeTextColor = themeTextColor
}
