import { pipe } from "fp-ts/lib/pipeable"
import { reduce } from "fp-ts/lib/Array"
import { Lens } from "monocle-ts"
import { setField } from "../../shared/tools/monocle"
import { createReducer } from "../../shared/tools/redux"
import * as actions from "./actions"
import {
  State,
  ExercisesProgress,
  ExerciseStates,
  ExerciseState,
  ReadingStates,
  ExerciseProgress
} from "./types"

const initialState: State = {
  exercisesProgress: [],
  temporaryProgress: [],
  readingStates: [],
  exerciseStates: []
}

const setTemporaryProgress = setField<State, ExercisesProgress | undefined>(
  "temporaryProgress",
  p => p || []
)
const setProgress = setField<State, ExercisesProgress | undefined>(
  "exercisesProgress",
  p => p || []
)

const setExerciseStates = setField<State, ExerciseStates | undefined>(
  "exerciseStates",
  p => p || []
)

const setReadingStates = (readingState: ReadingStates | undefined) =>
  Lens.fromProp<State>()("readingStates").modify(states => {
    if (readingState) {
      const newReadingState = states.filter(
        r =>
          !readingState.some(
            reading =>
              reading.bookId === r.bookId && reading.studliId === r.studliId
          )
      )

      return [...newReadingState, ...readingState]
    }
    return [...states]
  })

const setProgressAndStates =
  (payload: {
    progress?: ExercisesProgress
    readingState?: ReadingStates
    history?: ExerciseStates
  }) =>
  (state: State) =>
    pipe(
      state,
      setProgress(payload.progress),
      setReadingStates(payload.readingState),
      setExerciseStates(payload.history)
    )
const emptyExerciseProgress = setField<State>("exercisesProgress", () => [])
const modifyExerciseStates = (progress: ExercisesProgress) =>
  Lens.fromProp<State>()("exerciseStates").modify(states =>
    reduce<ExerciseProgress, ExerciseStates>(states, (res, p) => {
      if (!p.exerciseStateId) {
        return res
      }

      const foundState = res.find(s => s.exerciseStateId === p.exerciseStateId)

      if (!foundState) {
        return [...res, p as ExerciseState]
      }

      return [
        ...res.filter(s => s.exerciseStateId !== p.exerciseStateId),
        {
          exerciseId: p.exerciseId,
          time: p.time ? p.time : foundState.time,
          studliId: p.studliId,
          modifiedAt: p.modifiedAt,
          status: p.status ? p.status : foundState.status,
          score: p.score ? p.score : foundState.score,
          exerciseStateId: p.exerciseStateId as number,
          maxScore: p.maxScore ? p.maxScore : foundState.maxScore,
          isInProgress: p.isInProgress,
          extraTries: p.extraTries ? p.extraTries : foundState.extraTries
        } as ExerciseState
      ]
    })(progress)
  )

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.setExerciseProgress, setProgressAndStates)
    .case(actions.resetExercisesProgress, emptyExerciseProgress)
    .case(actions.updateTemporaryProgress, setTemporaryProgress)
    .case(actions.updateExerciseProgress, setProgress)
    .case(actions.updateReadingState, setReadingStates)
    .case(actions.updateExerciseStates, modifyExerciseStates)
)

export default reducer
