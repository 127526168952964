import { styled } from "@mui/material"
import IconButton from "@mui/material/IconButton"

export const Wrapper = styled("div")<{ playing: boolean }>`
  display: inline-flex;
  padding: 0.25rem 1.25rem 0.25rem 0.25rem;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  align-items: center;
`

export const Button = styled(IconButton)`
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 0.5rem;
  border: 0;
  background: transparent;
  padding: 0;
`
