import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import AbilityIndicator from "../../components/AbilityIndicator"
import GoalTable from "../../components/GoalPageTable"
import { TableData } from "../../components/GoalPageTable/common/types"
import Progress from "../../components/Progress"
import DifficultyDropDownMenu from "../../components/DifficultyDropDownMenu/DifficultyDropDownMenu"
import {
  selectStudentsWithProductLength,
  selectStudents
} from "../../modules/members/selectors"
import { Select } from "../../components/Select/Select"
import {
  selecterOptions,
  filterProgress,
  collectTableData,
  calcAverageGrade,
  renderMenuItems
} from "./helpers/helpers"
import InfoPopover from "../../components/InfoPopover"
import { sortBy } from "../../components/GoalPageTable/common/sortableList"
import {
  selectGoal,
  selectGoalTitleAndOrderFromRoute
} from "../../modules/goals/selectors"
import { selectGoalsProgress } from "../../modules/goalsProgress/selectors"
import { selectUsersOnline } from "../../modules/users/selectors"
import { PROGRESS_SEGMENT } from "../../components/Progress/constants"
import {
  StyledDetailsHeader,
  StyledTitle,
  StyledBread,
  StyledDescription,
  StyledLine,
  StyledMessage,
  StyledProgress,
  StyledSelectionBar,
  StyledWrapper,
  StyledStudentCount,
  StyledSelectBarWrapper,
  StyledHeaderBlock,
  StyledProgressWrapper
} from "./StyledGoalsCard"
import {
  StyledLegendContainer,
  StyledLegendBox
} from "../../components/GoalsTable/StyledGoalsTable"
import { selectActiveProduct } from "../../modules/products/selectors"
import { Dialog } from "@mui/material"
import ProgressDialog from "../../components/ProgressDialog"
import { selectGoalsForGoalsList } from "../Goals/store/selectors"
import { GoalInGoalsList } from "../Goals/store/types"

let previewExampleExercisesURL = process.env.REACT_APP_ADAPT_URL || ""
previewExampleExercisesURL = previewExampleExercisesURL.endsWith("/")
  ? previewExampleExercisesURL
  : `${previewExampleExercisesURL}/`
const previewString = "?preview="

const GoalsCard = () => {
  const [filteredProgress, setFilteredProgress] = useState<TableData[]>([])
  const studLength = useSelector(selectStudentsWithProductLength)
  const goal = useSelector(selectGoal)
  const goals = useSelector(selectGoalsForGoalsList)
  const goalTitleAndOrder = useSelector(selectGoalTitleAndOrderFromRoute)
  const students = useSelector(selectStudents)
  const studentProgress = useSelector(selectGoalsProgress)
  const [selectedOptionValue, setSelectedOptionValue] = useState("")
  const activeProduct = useSelector(selectActiveProduct)
  const [averageGrade, setAverageGrade] = useState(0)
  const usersOnline = useSelector(selectUsersOnline)
  const [progressDialogGoal, setProgressDialogGoal] = useState(false)
  const [currentGoal, setCurrentGoal] = useState<GoalInGoalsList | undefined>(
    undefined
  )

  const handleSelectChange = (value: string) => {
    setSelectedOptionValue(value)
    setFilteredProgress(sortBy(filteredProgress, "asc", value))
  }

  const goToExampleExercises = (difficulty: number) => {
    if (goal && goal.sampleExercises && activeProduct) {
      const ExampleExercisesURL =
        previewExampleExercisesURL +
        activeProduct.sesamName +
        previewString +
        goal.sampleExercises[difficulty]
      window.open(ExampleExercisesURL, "_blank", "noreferrer noopener")
    }
  }

  useEffect(() => {
    if (studentProgress.length > 0 && students.length > 0 && goal) {
      setFilteredProgress(
        collectTableData(students, studentProgress, goal, usersOnline)
      )
    }
  }, [goal, usersOnline, students, studentProgress])

  useEffect(() => {
    if (filteredProgress.length > 0) {
      goal &&
        setCurrentGoal(
          goals.find((g: GoalInGoalsList) => g.goalRef === goal.goalRef)
        )
      setAverageGrade(calcAverageGrade(filteredProgress))
    }
  }, [filteredProgress, goal, goals])

  return (
    <>
      {goal && (
        <StyledDetailsHeader>
          <StyledHeaderBlock>
            <AbilityIndicator
              grade={filteredProgress.length ? averageGrade : 0}
              size={80}
            />
            <div>
              <StyledTitle>{goalTitleAndOrder}</StyledTitle>
              <StyledBread>({goal.path})</StyledBread>
            </div>
          </StyledHeaderBlock>
          <StyledProgressWrapper>
            <InfoPopover
              type="Info"
              content={
                <div>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                    Klar
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.IN_PROGRESS} />
                    Påbörjad
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.NOT_STARTED} />
                    Ej påbörjad
                  </StyledLegendContainer>
                </div>
              }
            />
            <StyledProgress>
              <Progress
                done={filterProgress(filteredProgress, "done")}
                inProgress={filterProgress(filteredProgress, "inProgress")}
                notStarted={filterProgress(
                  filteredProgress,
                  "notStarted",
                  studLength
                )}
                onOpenProgressDialog={() => setProgressDialogGoal(true)}
                type="goals"
              />
            </StyledProgress>
          </StyledProgressWrapper>
          {goal && goal.description && (
            <StyledDescription>{goal.description}</StyledDescription>
          )}
          {goal && goal.sampleExercises && (
            <DifficultyDropDownMenu
              buttonLabel="VISA EXEMPELÖVNINGAR"
              callback={goToExampleExercises}
              menuItems={renderMenuItems(goal.sampleExercises)}
            />
          )}
          <StyledLine></StyledLine>
        </StyledDetailsHeader>
      )}
      <StyledWrapper>
        {filteredProgress.length ? (
          <>
            <StyledSelectionBar>
              <StyledStudentCount>
                Elever({filteredProgress.length})
              </StyledStudentCount>
              <StyledSelectBarWrapper>
                <Select
                  selectLabel="Sortera på"
                  selected={selectedOptionValue}
                  options={selecterOptions}
                  onChangeCallback={handleSelectChange}
                />
              </StyledSelectBarWrapper>
            </StyledSelectionBar>
            <GoalTable
              data={filteredProgress}
              setData={setFilteredProgress}
              amountOfStudents={filteredProgress.length}
            />
          </>
        ) : (
          <StyledMessage>
            Det är inga elever som påbörjat målet än.
          </StyledMessage>
        )}
        {currentGoal && (
          <Dialog
            maxWidth="lg"
            onClose={() => setProgressDialogGoal(false)}
            open={progressDialogGoal}
          >
            <ProgressDialog
              onClose={() => setProgressDialogGoal(false)}
              goal={currentGoal}
            />
          </Dialog>
        )}
      </StyledWrapper>
    </>
  )
}

export default GoalsCard
