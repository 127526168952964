import React from "react"
import { useSelector } from "react-redux"
import { selectAllAssignmentsMessages } from "../../modules/assignments/selectors"
import { Assignment } from "../../modules/assignments/types"
import { Member } from "../../modules/members/types"
import { ReactComponent as MessageIcon } from "../../assets/images/message.svg"
import { ReactComponent as NewMessageIcon } from "../../assets/images/new-message.svg"

type Props = {
  assignmentId: Assignment["id"]
  memberId?: Member["studliId"]
}

/**
 * If assignmentId only is provided the message indicator will only show icon when there is a new message
 * If a memberId also is provided the message icon will show an icon if there are messages from/to the student
 * and another icon if there is a new message from memberId.
 */

const MessageIndicator = ({ assignmentId, memberId }: Props) => {
  const messages = useSelector(selectAllAssignmentsMessages)
  const assignmentMessages = messages.filter(
    m => m.assignmentId === assignmentId
  )

  if (memberId) {
    const messagesForStudent = assignmentMessages.filter(
      m => m.studliId === memberId
    )

    const newMessagesFromStudent = messagesForStudent.filter(
      m => m.studliIdSender === memberId && m.readByTeacherAt === null
    )

    if (newMessagesFromStudent.length) {
      return (
        <span>
          <NewMessageIcon aria-label="Nytt meddelande" />
        </span>
      )
    }

    if (messagesForStudent.length) {
      return (
        <span>
          <MessageIcon aria-label="Meddelande" />
        </span>
      )
    }
  } else {
    const unreadMessages = assignmentMessages.filter(
      m => m.readByTeacherAt === null
    )

    if (unreadMessages.length) {
      return (
        <span>
          <NewMessageIcon aria-label="Nytt meddelande" />
        </span>
      )
    }
  }

  return null
}

export default MessageIndicator
