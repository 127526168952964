import { styled } from "@mui/material"

import { StateProps, SnackbarData } from "./types"
import SnackbarContent, {
  SnackbarContentProps
} from "@mui/material/SnackbarContent"

const getBackgroundColor = (props: any, snackbar?: SnackbarData) => {
  const severity: SnackbarData["severity"] | undefined =
    snackbar && snackbar.severity
  let color = ""

  switch (severity) {
    case "info":
      color = props.theme.studli.snackbarColor.info
      break
    case "error":
      color = props.theme.studli.snackbarColor.error
      break
    case "warning":
      color = props.theme.studli.snackbarColor.warning
      break
    case "success":
      color = props.theme.studli.snackbarColor.success
      break
    default:
      color = "transparent"
  }

  return color
}

export const StyledSnackbarContent = styled(
  SnackbarContent as React.FC<SnackbarContentProps & StateProps>
)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  background-color: ${props => props.theme.palette.primary.main};

  background-color: ${(props: StateProps) => {
    return getBackgroundColor(props, props.snackbar)
  }};
`

export const StyledMessage = styled("span")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1.3em;
`
