import React from "react"
import {
  AssignmentsProgress,
  AssignmentProgress
} from "../../modules/assignmentsProgress/types"
import {
  StyledProgressHistoryWrapper,
  StyledProgressItem,
  StyledProgressHistoryMessage
} from "./StyledProgressHistory"
import { Member } from "../../modules/members/types"
import ProgressHistoryTeacherAction from "../ProgressHistoryTeacherAction"
import ProgressHistoryStudentAction from "../ProgressHistoryStudentAction"
import { ReactComponent as SubmittedIcon } from "../../assets/images/task-question.svg"

type Props = {
  progressForAssignment: AssignmentsProgress
  student: Member
  showTeacherRespons: boolean
}

const ProgressHistory = ({
  progressForAssignment,
  student,
  showTeacherRespons
}: Props) => {
  return (
    <StyledProgressHistoryWrapper>
      {progressForAssignment &&
        progressForAssignment.map(
          (progress: AssignmentProgress, index: number) => {
            if (
              progress.submittedAt &&
              (progress.approvedAt || progress.rejectedAt)
            ) {
              return (
                <StyledProgressItem key={`mix${progress.id}`}>
                  {index !== 0 && (
                    <li>
                      <ProgressHistoryTeacherAction
                        progress={progress}
                        withFeedbackMessage={false}
                      />
                    </li>
                  )}
                  <li>
                    <ProgressHistoryStudentAction
                      progress={progress}
                      student={student}
                    />
                  </li>
                </StyledProgressItem>
              )
            }

            if (
              progress.submittedAt &&
              !progress.rejectedAt &&
              !progress.approvedAt
            ) {
              return (
                <StyledProgressHistoryMessage key={`student${progress.id}`}>
                  <SubmittedIcon />
                  <ProgressHistoryStudentAction
                    progress={progress}
                    student={student}
                    strong={true}
                  />
                </StyledProgressHistoryMessage>
              )
            }

            return null
          }
        )}
    </StyledProgressHistoryWrapper>
  )
}

export default ProgressHistory
