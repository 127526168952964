import { styled } from "@mui/material"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"

export const StyledMessageFormWrapper = styled("div")`
  display: flex;
`

export const StyledArrowButton = styled(
  IconButton as React.FC<IconButtonProps>
)`
  box-sizing: border-box;
  height: 33.5px;
  width: 33.5px;
  border: 1.5px solid #006cd0;
  background-color: #006cc8;
  color: white;
  margin-left: 0.25rem;
  &:hover,
  &:focus {
    background-color: #006cc8;
  }

  & > .MuiIconButton-label {
    display: block;
    position: relative;

    & > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export const StyledAudioButton = styled(
  IconButton as React.FC<IconButtonProps>
)`
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  color: black;
  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.08);
  }
`
