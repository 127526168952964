import { styled, Button, Box, Chip } from "@mui/material"

const FilterPrimaryButton = styled(Button)`
  color: black;
  background-color: white;
  border: none;
  hover: {
    background-color: white;
  }
`

const FilterChip = styled(Chip)`
  background-color: #aaceed;
  color: #002443;
  .MuiChip-deleteIcon {
    color: #002443;
  }
`

const StyledFilterCount = styled(Box, {
  shouldForwardProp: prop => prop !== "bgcolor" && prop !== "textColor"
})<{ bgcolor?: string; textColor?: string }>`
  border-radius: 50%;
  padding: 0.5rem;
  background-color: ${props => props.bgcolor || "black"};
  color: ${props => props.textColor || "white"};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.75rem;
`

export { FilterPrimaryButton, StyledFilterCount, FilterChip }
