import ListItem, { ListItemProps } from "@mui/material/ListItem"
import { DialogContentText, styled } from "@mui/material"
import React from "react"

export const NotFoundItem = styled(ListItem as React.FC<ListItemProps>)`
  justify-content: center;
`

export const StyledDialogContentText = styled(DialogContentText)`
  padding-left: 24px;
  padding-right: 24px;
`
