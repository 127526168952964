/**
 * These select* functions are  for redux select(), they expose parts of the state.
 */

import { RootState } from "../store"
import { createSelector } from "reselect"

export const selectCurrentClassroomId = (store: RootState): number =>
  store.location.payload.classroomId

export const selectCurrentExerciseId = (store: RootState): string =>
  store.location.payload.exerciseId
export const selectPreviousRoute = (store: RootState): string =>
  store.location.prev.type

export const selectCurrentRoute = (store: RootState): string =>
  store.location.type

export const selectCurrentAssignmentId = (store: RootState): number =>
  parseInt(store.location.payload.assignmentId)

export const selectCurrentTestId = (store: RootState): number =>
  parseInt(store.location.payload.testId)

export const selectCurrentMemberId = (store: RootState): number =>
  parseInt(store.location.payload.memberId)

export const selectCurrentHelpPage = (store: RootState): string =>
  store.location.payload.helpPage

function isFunction(functionToCheck: {}) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  )
}

type StateResolvedString = (state: RootState) => string

function stringOrFunction(state: RootState, val: StateResolvedString | string) {
  return isFunction(val) ? (val as StateResolvedString)(state) : (val as string)
}

const getParent = (
  state: RootState,
  routeMap: {
    [key: string]: {
      parent: string
      title: StateResolvedString | string
    }
  },
  payload: {},
  route: string
): { type: string; payload: {}; title: string }[] => {
  let parents: { type: string; payload: {}; title: string }[] = []

  const currentRoute = routeMap[route]

  if (routeMap[currentRoute.parent].parent) {
    parents = [...getParent(state, routeMap, payload, currentRoute.parent)]
  }

  const parent = routeMap[currentRoute.parent]

  return [
    ...parents,
    {
      type: currentRoute.parent,
      payload,
      title: stringOrFunction(state, parent.title)
    }
  ]
}

export const selectBreadcrumbsStructureForRoute = createSelector(
  ({ location }: RootState) => location.type,
  ({ location }: RootState) => location.payload,
  ({ location }: RootState) =>
    location.routesMap as {
      [key: string]: {
        parent: string
        title: StateResolvedString | string
      }
    },
  (state: RootState) => state,
  (currentRoute, currentPayload, routesMap, state) => {
    const current = routesMap[currentRoute]

    if (typeof current === "string") {
      return []
    }

    return [
      ...(current.parent
        ? getParent(state, routesMap, currentPayload, currentRoute)
        : []),
      {
        type: currentRoute,
        payload: currentPayload,
        title: stringOrFunction(state, current.title)
      }
    ]
  }
)

export const selectHelpTagsForRoute = createSelector(
  ({ location }: RootState) => location.type,
  ({ location }: RootState) =>
    location.routesMap as {
      [key: string]: {
        helpTags: string[]
      }
    },
  (currentRoute, routesMap) => {
    return routesMap[currentRoute].helpTags || []
  }
)
