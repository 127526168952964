import { Groups } from "../../modules/classroom/types"

export const sortGroupsOnActive = (
  unsortedGroups: Groups | undefined,
  activeGroups: Groups | undefined
) => {
  if (unsortedGroups && activeGroups) {
    const activeGroupIds = activeGroups.map(ag => ag.id)

    const activeGroupsSorted = unsortedGroups
      .filter(g => activeGroupIds.includes(g.id))
      .sort((a, b) =>
        a.name
          .toLowerCase()
          .localeCompare(b.name.toLowerCase(), "sv", { sensitivity: "base" })
      )

    const notActiveGroupsSorted = unsortedGroups
      .filter(g => !activeGroupIds.includes(g.id))
      .sort((a, b) =>
        a.name
          .toLowerCase()
          .localeCompare(b.name.toLowerCase(), "sv", { sensitivity: "base" })
      )

    return [...activeGroupsSorted, ...notActiveGroupsSorted]
  }
  return []
}
