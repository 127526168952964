import { ListItem, ListItemProps, styled } from "@mui/material"

type IconContainerProps = {
  iconColor: string
}

export const StyledIconContainer = styled("div")<IconContainerProps>`
  margin-top: 9px;
  margin-right: 10px;
  color: ${props => props.iconColor};
`
export const StyledListItem = styled(ListItem as React.FC<ListItemProps | any>)`
  padding-left: 0;
  &:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }
`
export const StyledNumberOfPagesText = styled("span")`
  color: gray;
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
`

export const StyledPrimaryTextContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
`

export const StyledTextSegment = styled("div")`
  display: flex;
`
