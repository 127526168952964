/**
 * These select* functions expose parts of the state. Play nice with redux select() and inside mapStateToProps etc.
 */

import { Members, Member } from "./types"
import { RootState } from "../store"
import { USER_ROLE } from "../users/constants"
import {
  selectUsersWithProduct,
  selectUsersOnline,
  selectAuthenticatedUser,
  selectUserActiveProducts
} from "../users/selectors"
import { createSelector } from "reselect"
import { Product } from "../products/types"

export const selectAllMembers = (store: RootState): Members =>
  store.members.members

export const selectStudentsWithActiveProductLength = (
  store: RootState
): number => {
  return selectMembersWithActiveProduct(store).filter(
    member => member.type === USER_ROLE.STUDENT
  ).length
}

export const selectMembersThatCanChangePassword = (
  store: RootState
): Members => {
  return store.members.members.filter(member => member.isPasswordChangeable)
}

export const selectMembersWithNoActiveProduct = (store: RootState): Members => {
  const allMembers = selectAllMembers(store)
  const usersWithProduct = selectUsersWithProduct(store) || []

  return allMembers.filter(
    member => !usersWithProduct.includes(member.studliId)
  )
}

export const selectMembersWithActiveProduct = (store: RootState): Members => {
  const allMembers = selectAllMembers(store)
  const usersWithProduct = selectUsersWithProduct(store) || []

  return allMembers.filter(member => usersWithProduct.includes(member.studliId))
}

export function selectStudents(store: RootState): Members {
  return selectAllMembers(store).filter(
    member => member.type === USER_ROLE.STUDENT
  )
}

export const selectStudentsLength = (store: RootState): number =>
  selectStudents(store).length

export const selectStudentsOnlineLength = (store: RootState): number =>
  selectStudentsOnline(store).length

export const selectStudentsOnline = (store: RootState): Members => {
  const usersOnline = selectUsersOnline(store) || []

  return selectStudents(store).filter(student =>
    usersOnline.includes(student.studliId)
  )
}

export const selectTeachers = (store: RootState): Members =>
  selectAllMembers(store).filter(
    (member: Member) => member.type === USER_ROLE.TEACHER
  )

export const selectStudentsWithProduct = (store: RootState): Members => {
  const usersWithProduct = selectUsersWithProduct(store) || []

  return selectStudents(store).filter(member =>
    usersWithProduct.includes(member.studliId)
  )
}

export const selectStudentsWithoutProduct = (store: RootState): Members => {
  const usersWithProduct = selectUsersWithProduct(store) || []

  return selectStudents(store).filter(
    member => !usersWithProduct.includes(member.studliId)
  )
}

export const selectStudentsWithProductLength = (store: RootState): number =>
  selectStudentsWithProduct(store).length

export const selectTeacherLoggedIn = createSelector(
  [selectTeachers, selectAuthenticatedUser],
  (teachers, authenticatedUser) => {
    if (!authenticatedUser) {
      return null
    }
    const teacher = teachers.find(
      teacher => teacher.studliId === authenticatedUser.studliId
    )

    return teacher || null
  }
)

export const selectMemberHasAnyProducts = createSelector(
  ({ members }: RootState, { memberId }: { memberId: number }) => {
    const member = members.members.find(m => m.studliId === memberId)

    if (!member) {
      return null
    }

    return member.studliId
  },
  ({ users }: RootState) => users.activeProducts || [],
  ({ products }: RootState) =>
    products.products.find(p => p.id === products.activeProductId) ||
    ({} as Product),
  (studliId, activeProducts, product) => {
    const ap = activeProducts.find(p => p.studliId === studliId)

    if (!ap || !ap.products.length) {
      return "Eleven har inte aktiverat något läromedel som ingår i det här klassrummet. Hantera licens eller aktiveringskod i Min bokhylla."
    }
    const hasOtherProduct = ap.codes.find(c => c.active)
    const hasHadProduct = ap.codes.find(
      c => c.sesamGroups.some(sesam => sesam === product.sesamName) && !c.active
    )

    if (hasOtherProduct && !hasHadProduct) {
      return "Eleven har inte det valda läromedlet aktiverat. Hantera licens eller aktiveringskod i Min bokhylla."
    }

    if (hasHadProduct) {
      return "Eleven har inte längre tillgång till det valda läromedlet. Hantera licens eller aktiveringskod i Min bokhylla."
    }
    return ""
  }
)

export const selectAllStudentsWithProducts = createSelector(
  selectStudents,
  selectUserActiveProducts,
  (students, products) => {
    return students.map(student => {
      const product = products.find(p => p.studliId === student.studliId)
      return { ...student, products: product ? product.products : [] }
    })
  }
)

export const selectStudentsWithOnlineStatus = createSelector(
  selectStudents,
  selectUsersOnline,
  (students, studentsOnline) => {
    return students.map(student => ({
      ...student,
      isOnline: (studentsOnline || []).indexOf(student.studliId) > -1
    }))
  }
)

export const selectRecentlyAddedMembers = (state: RootState) =>
  state.members.recentlyAddedMembers

export const selectStudentCardMember = createSelector(
  [selectStudents, ({ location }: RootState) => +location.payload.memberId],
  (students, memberId) => {
    const currentStudent = students.find(
      student => student.studliId === memberId
    )
    if (currentStudent) {
      return currentStudent
    }
  }
)

export const selectStudentByPayload = createSelector(
  selectStudents,
  ({ location }: RootState) => +location.payload.memberId,
  (students, memberId) =>
    students.find(s => s.studliId === memberId) || ({} as Member)
)
