import { ListItem, ListItemIcon, ListItemProps, styled } from "@mui/material"
import { ReactComponent as AssignmentIcon } from "../../assets/images/menu-task.svg"

export const StyledPrimaryTextContainer = styled("div")`
  display: flex;
  justify-content: flex-start;

  ${props => props.theme.breakpoints.up("sm")} {
    width: 35%;
    align-items: center;
  }
`

export const StyledAssignmentIcon = styled(AssignmentIcon)`
  color: #057dda;
`

export const StyledPrimaryText = styled("div")`
  ${props => props.theme.breakpoints.up("sm")} {
    margin-right: 10px;
  }
`

export const StyledListItemIcon = styled(ListItemIcon)`
  align-items: flex-start;
`

export const StyledSticker = styled("img")`
  width: 40px;
`

export const StyledListItemContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin: 0.25rem 0;
  }

  ${props => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      margin: inherit;
    }
  }
`

export const StyledErrorMsg = styled("span")`
  height: 20px;
  color: #cc0000;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.23px;
  line-height: 20px;
  margin-top: 6px;
`

export const StyledListItem = styled(
  ListItem as React.FC<ListItemProps & { redbar: number }>
)`
  ${({ redbar }) =>
    redbar === 1 &&
    `
    border-left: 5px solid #CC0000;
  `}
`
