import { fold, tryCatch } from "fp-ts/lib/Either"
import { pipe } from "fp-ts/lib/pipeable"
import * as L from "monocle-ts/lib/Lens"

export const setField =
  <State, Payload = unknown>(
    field: keyof State,
    payloadFinder: (p: Payload) => State[keyof State]
  ) =>
  (payload: Payload) =>
  (state: State) =>
    pipe(
      tryCatch(
        () =>
          pipe(
            L.id<State>(),
            L.prop(field),
            L.modify(_ => payloadFinder(payload))
          )(state),
        () => state
      ),
      fold(
        s => s,
        s => s
      )
    )
