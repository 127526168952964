import {
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ListItemButton,
  SelectChangeEvent
} from "@mui/material"
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { selectGoalsWithProgress } from "../../modules/goals/selectors"
import { GoalsProgress } from "../../modules/goalsProgress/types"
import {
  StyledNameTableCellContent,
  StyledAvatar,
  StyledToGoalText,
  StyledToGoalTextWrapper,
  StyledMovingBackwardsText
} from "./StyledSetStartGoalDialog"
import { StudentInStudentList } from "../../pages/Members/store/types"
import { buildName } from "../../shared/tools/strings"
import AbilityIndicator from "../AbilityIndicator"
import Button from "../Button"
import useChooseGoalsDialog from "./useChooseGoalsDialog"
import { getAverageGrade } from "../GoalAssignmentList/helpers"
import Progress from "../Progress"
import { howManyGoalsBack } from "./helpers"

type Props = {
  onClose: () => void
  members: StudentInStudentList[]
  total: number
  currentGoal?: string
  onSubmit: (members: number[], goalRef: string) => void
}

const NOOP = () => {}

const SetStartGoalDialog = ({
  onClose,
  members,
  currentGoal,
  total,
  onSubmit
}: Props) => {
  const [checkedGoal, setCheckedGoal] = useState<string | null>(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const goals = useSelector(selectGoalsWithProgress)
  const {
    currentGoals,
    filters,
    selectedFilter,
    onFilter,
    currentGoalSingleUser
  } = useChooseGoalsDialog({
    goals,
    members
  })

  useEffect(() => {
    if (currentGoal) {
      setCheckedGoal(currentGoal)
    }
  }, [currentGoal])

  const onContinueClick = () => {
    if (checkedGoal) {
      showConfirmation
        ? onSubmit(
            members.map(m => m.studliId),
            checkedGoal
          )
        : setShowConfirmation(true)
    }
  }
  const onGradeFilterChange = (e: SelectChangeEvent<number>) => {
    onFilter(+e.target.value)
  }

  const getProgressForBar = (progress: GoalsProgress) => {
    const progressForBar = progress.reduce(
      (acc, curr) => {
        return curr.isInProgress
          ? { ...acc, inProgress: acc.inProgress + 1 }
          : { ...acc, done: acc.done + 1 }
      },
      { done: 0, inProgress: 0, notStarted: 0 }
    )
    progressForBar.notStarted =
      total - progressForBar.done - progressForBar.inProgress

    return progressForBar
  }

  const renderStudentTable = (newGoal: string) => {
    const goal = goals.find(g => g.goalRef === newGoal)
    return (
      <>
        <DialogContentText>
          Du har valt att ändra startmål till
          <StyledToGoalText>{` ${goal?.order}. ${goal?.title}`}</StyledToGoalText>{" "}
          för följande
          {members.length > 1 ? " elever" : " elev"}:
        </DialogContentText>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell align="left">Nuvarande mål</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map(m => {
              const stepsBackwards = howManyGoalsBack(newGoal, m.goalRef, goals)
              return (
                <TableRow key={m.studliId}>
                  <TableCell>
                    <StyledNameTableCellContent>
                      <StyledAvatar
                        picture={m.picture}
                        studliId={m.studliId}
                        firstName={m.firstName}
                        lastName={m.lastName}
                      />
                      {buildName(m.firstName, m.lastName)}
                    </StyledNameTableCellContent>
                  </TableCell>
                  <TableCell align="left">
                    <StyledToGoalTextWrapper>
                      {m.goalTitle}
                    </StyledToGoalTextWrapper>
                    {stepsBackwards > 0 && (
                      <StyledMovingBackwardsText>
                        Resultatet för {stepsBackwards + 1} mål kommer att
                        nollställas
                      </StyledMovingBackwardsText>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </>
    )
  }

  const renderGoalList = () => {
    return (
      <>
        <DialogContentText>
          Välj vilket startmål du vill att {getNameOrNumber()} ska flyttas till
          i listan. Vid ändring till ett tidigare mål kommer resultaten i redan
          gjorda mål att nollställas. OBS! Ändringen påverkar bara matematiken,
          inte märken eller var eleven befinner sig i spelet i Tomoyo.
        </DialogContentText>
        {filters && (
          <Select
            onChange={onGradeFilterChange}
            value={selectedFilter}
            label="Årskurs"
            id="filter-menu"
          >
            {Object.keys(filters).map(key => (
              <MenuItem key={filters[+key].value} value={filters[+key].value}>
                {filters[+key].title}
              </MenuItem>
            ))}
          </Select>
        )}
        <List>
          {currentGoals.map(g => {
            const progress = getProgressForBar(g.progress)
            return (
              <ListItem sx={{ padding: "0px" }} divider key={g.goalRef}>
                <ListItemButton
                  onClick={() => setCheckedGoal(g.goalRef)}
                  autoFocus={currentGoalSingleUser === g.goalRef}
                >
                  <ListItemIcon>
                    <Radio
                      color="primary"
                      onClick={() => setCheckedGoal(g.goalRef)}
                      checked={checkedGoal === g.goalRef}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <AbilityIndicator
                      grade={getAverageGrade(
                        members
                          ? g.progress.filter(p =>
                              members.some(m => m.studliId === p.studliId)
                            )
                          : g.progress
                      )}
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    {g.order}. {g.title}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Progress
                      done={progress.done}
                      inProgress={progress.inProgress}
                      notStarted={progress.notStarted}
                      onOpenProgressDialog={NOOP}
                      type="goals"
                    />
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </>
    )
  }

  const getNameOrNumber = () => {
    return members.length === 1
      ? buildName(members[0].firstName, members[0].lastName)
      : `${members.length} elever`
  }

  const renderTitle = () => {
    return `${
      showConfirmation
        ? "Är du säker på att du vill ändra mål för "
        : "Ändra startmål för "
    } ${getNameOrNumber()}`
  }

  return (
    <>
      <DialogTitle>{renderTitle()}</DialogTitle>
      <DialogContent>
        {showConfirmation && checkedGoal
          ? renderStudentTable(checkedGoal)
          : renderGoalList()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            showConfirmation ? () => setShowConfirmation(false) : onClose
          }
        >
          Avbryt
        </Button>
        <Button
          color="primary"
          onClick={onContinueClick}
          disabled={Boolean(checkedGoal === currentGoal || !checkedGoal)}
        >
          {showConfirmation ? "Bekräfta" : "välj"}
        </Button>
      </DialogActions>
    </>
  )
}

export default SetStartGoalDialog
