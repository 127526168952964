import React, { useEffect, useMemo, useRef } from "react"
import { styled } from "@mui/material"
import Fab from "@mui/material/Fab"
import { ReactComponent as HelpIcon } from "../../assets/images/menu-question-mark.svg"
import { useSelector } from "react-redux"
import { selectClassroomHelpTags } from "../../modules/classroom/selectors"
import {
  selectHelpTagsForRoute,
  selectCurrentRoute
} from "../../modules/router/selectors"
import { Help } from "@studli/aidahelp"
import { TEMP_FIX } from "../HeaderHelp/HeaderHelp"
import * as routerActions from "../../modules/router/actions"

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: 1em;
  margin: 0;
  right: 1em;
`
const StyledFabUpper = styled(Fab)`
  position: fixed;
  bottom: 4.5em;
  margin: 0;
  right: 1em;
`
const HelpButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const helpClassroomTags = useSelector(selectClassroomHelpTags)
  const currentTags = useSelector(selectHelpTagsForRoute)

  const savedCurrentRoute = useRef<string>()
  const currentRoute = useSelector(selectCurrentRoute)
  useEffect(() => {
    if (savedCurrentRoute.current !== currentRoute) {
      setAnchorEl(null)
      savedCurrentRoute.current = currentRoute
    }
  }, [currentRoute])

  const isStudentCardRoute = useMemo(
    () => routerActions.ROUTE_ELEVKORT === currentRoute,
    [currentRoute]
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <aside>
      {isStudentCardRoute ? (
        <StyledFabUpper onClick={handleClick} color="primary" aria-label="Hjälp">
          <HelpIcon />
        </StyledFabUpper>
      ) : (
        <StyledFab onClick={handleClick} color="primary" aria-label="Hjälp">
          <HelpIcon />
        </StyledFab>
      )}
      {open && (
        <Help
          position={{ windowPosition: "bottomRight" }}
          anchorEl={anchorEl}
          open={open}
          env={process.env.REACT_APP_ENV as "dev" | "test" | "production"}
          applicationTag="Tecla"
          tags={currentTags}
          excludedTags={helpClassroomTags ? helpClassroomTags.excludedTags : []}
          excludeVisibleTags={
            helpClassroomTags
              ? [helpClassroomTags.activeTag, ...TEMP_FIX]
              : [...TEMP_FIX]
          }
          closeHelp={handleClose}
          supportURL={
            `${process.env.REACT_APP_BOOKSHELF_URL}/kontakta-oss` || ""
          }
        />
      )}
    </aside>
  )
}

export default HelpButton
