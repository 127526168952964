import { RootState } from "../store"
import {
  Assignments,
  Assignment,
  PartialAssignment,
  PreparedAssignment,
  AssignmentMessages,
  ImportableAssignmentClassroom
} from "./types"
import { createSelector } from "reselect"

export const selectAllAssignments = (state: RootState): Assignments =>
  state.assignments.assignments

export const selectAllImportableAssignments = (
  state: RootState
): ImportableAssignmentClassroom[] => state.assignments.importableAssignments

export const selectLoadingImportAssignment = (state: RootState): number =>
  state.assignments.loadingImportAssignment

export const selectPendingImportAssignment = (
  state: RootState
): PartialAssignment | undefined => state.assignments.pendingImport

export const selectAllPreparedAssignments = (
  state: RootState
): PreparedAssignment[] => state.assignments.preparedAssignments

export const selectAllPreparedAssignmentsLength = (state: RootState): number =>
  state.assignments.preparedAssignments.length

export const selectAllAssignmentsMessages = (
  state: RootState
): AssignmentMessages => state.assignments.feedbackMessages

export const selectMessagesLength = (state: RootState): number =>
  state.assignments.feedbackMessages.length

const byMemberId = (memberId: number) => (a: Assignment) =>
  Array.isArray(a.participants) && a.participants.indexOf(memberId) > -1

export const selectAssignmentsByStudent = createSelector(
  selectAllAssignments,
  ({ location }: RootState) => +location.payload.memberId,
  (assignments, memberId) => assignments.filter(byMemberId(memberId))
)

export const selectCreateEditValidationError = (state: RootState) =>
  state.assignments.createEditValidationError

export const selectPreviewUrl = (state: RootState) =>
  state.assignments.previewUrl

export const selectCurrentAssignment = createSelector(
  ({ location }: RootState) => +location.payload.assignmentId,
  selectAllAssignments,
  (assignmentId, assignments) => {
    if (assignmentId) {
      return assignments.find(a => a.id === assignmentId)
    }
  }
)
