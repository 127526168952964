import React from "react"
import moment from "moment"
import * as momentLocale from "../../shared/locale/timeAndDate"
import { StyledDuration } from "./StyledDuration"

type Props = {
  duration: number | undefined
  children?: never
}

const Duration = ({ duration }: Props) => {
  moment.updateLocale("sv", momentLocale.sv)

  const momentDuration = moment.duration(duration, "milliseconds")
  const durationToRender = momentDuration.humanize()
  return <StyledDuration>{durationToRender}</StyledDuration>
}

export default Duration
