import * as React from "react"

import Button from "../Button"

import { Wrapper } from "./StyledAudioRecorder"

type Props = {
  onRemove: () => void
  onCancel: () => void
  fullWidth?: boolean
}

const Remove: React.FC<Props> = ({ onRemove, onCancel, fullWidth }) => (
  <Wrapper fullWidth={fullWidth}>
    <Button onClick={onRemove} color="primary">
      Ta bort
    </Button>
    <Button onClick={onCancel} color="primary">
      Avbryt
    </Button>
  </Wrapper>
)

export default Remove
