import React, { useEffect } from "react"
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertFromHTML,
  ContentState
} from "draft-js"
import {
  StyledButton,
  StyledEditorContainer,
  StyledEditor,
  StyledMaxLength
} from "./StyledFormikTextEditor"
import { convertToHTML } from "draft-convert"

import { ReactComponent as Header } from "../../assets/images/header.svg"
import { ReactComponent as Bold } from "../../assets/images/bold.svg"
import { ReactComponent as Italic } from "../../assets/images/italic.svg"
import { ReactComponent as Underline } from "../../assets/images/underlined.svg"
import { ReactComponent as ListBullets } from "../../assets/images/list-bullets.svg"
import { ReactComponent as ListNumbers } from "../../assets/images/list-numbers.svg"

type Props = {
  name: string
  onBlurHandler: Function
  placeholder: string
  value?: string
  marginTop?: boolean
  ariaLabel?: string
  maxLength?: number
  disabled?: boolean
}

const FORMAT_TYPES = [
  {
    label: <Header aria-label="Header" />,
    style: "header-four",
    type: "block"
  },
  { label: <Bold aria-label="Fetstil" />, style: "BOLD", type: "inline" },
  { label: <Italic aria-label="Italic" />, style: "ITALIC", type: "inline" },
  {
    label: <Underline aria-label="Understryk" />,
    style: "UNDERLINE",
    type: "inline"
  },
  {
    label: <ListBullets aria-label="Onumrerad lista" />,
    style: "unordered-list-item",
    type: "block"
  },
  {
    label: <ListNumbers aria-label="Numrerad lista" />,
    style: "ordered-list-item",
    type: "block"
  }
]

const FormikTextEditor = ({
  name,
  onBlurHandler,
  placeholder,
  value,
  marginTop = false,
  ariaLabel,
  maxLength,
  disabled
}: Props) => {
  const [editorState, setEditorState] = React.useState<any>(
    EditorState.createEmpty()
  )
  const editor = React.useRef<HTMLElement>()

  useEffect(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(state))
    }
  }, [value])

  const onChange = (editorState: any) => {
    setEditorState(editorState)
  }

  const handleBeforeInput = (chars: any) => {
    if (maxLength) {
      const totalLength =
        editorState.getCurrentContent().getPlainText().length + chars.length
      return totalLength > maxLength
    }
  }

  const onBlur = () => {
    const convertedToHTML = convertToHTML(editorState.getCurrentContent())
    onBlurHandler(name, convertedToHTML)
  }

  const focus = () => {
    if (!editor.current) {
      return
    }

    editor.current.focus()
  }

  const handleKeyCommand = (command: any, editorState: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      setEditorState(newState)

      return "handled"
    }

    return "not-handled"
  }

  const mapKeyToEditorCommand = (e: any) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */)
      if (newEditorState !== editorState) {
        setEditorState(newEditorState)
      }

      return
    }

    return getDefaultKeyBinding(e)
  }

  const toggleBlockType = (blockType: any) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType))
  }

  const toggleInlineStyle = (inlineStyle: any) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const contentState = editorState.getCurrentContent()

  const hasContent =
    !contentState.hasText() &&
    contentState.getBlockMap().first().getType() !== "unstyled"

  const currentLength = editorState
    .getCurrentContent()
    .getPlainText("\u0001").length
  return (
    <>
      <StyledEditor margintop={marginTop}>
        <div className="RichEditor-controls">
          {FORMAT_TYPES.map(({ type, style, label }) =>
            type === "block" ? (
              <BlockStyleControl
                key={style}
                style={style}
                label={label}
                editorState={editorState}
                onToggle={toggleBlockType}
              />
            ) : (
              <InlineStyleControl
                key={style}
                style={style}
                label={label}
                editorState={editorState}
                onToggle={toggleInlineStyle}
              />
            )
          )}
        </div>
        <StyledEditorContainer hidePlaceholder={hasContent} onClick={focus}>
          <Editor
            ref={editor}
            readOnly={disabled}
            ariaLabel={ariaLabel ? ariaLabel : "Textfält"}
            editorState={editorState}
            keyBindingFn={mapKeyToEditorCommand}
            handleKeyCommand={handleKeyCommand}
            onChange={onChange}
            onBlur={onBlur}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handleBeforeInput}
            placeholder={placeholder}
          />
        </StyledEditorContainer>
      </StyledEditor>
      {maxLength && (
        <StyledMaxLength>
          {maxLength - currentLength} tecken kvar
        </StyledMaxLength>
      )}
    </>
  )
}

export default FormikTextEditor

const StyleButton = (props: any) => {
  const onToggle = (e: any) => {
    e.preventDefault()
    props.onToggle(props.style)
  }

  return (
    <StyledButton onMouseDown={onToggle} active={props.active}>
      {props.label}
    </StyledButton>
  )
}

const BlockStyleControl = ({ editorState, style, label, onToggle }: any) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <StyleButton
      key={style}
      active={style === blockType}
      label={label}
      onToggle={onToggle}
      style={style}
    />
  )
}

const InlineStyleControl = ({ editorState, style, label, onToggle }: any) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  return (
    <StyleButton
      key={style}
      active={currentStyle.has(style)}
      label={label}
      onToggle={onToggle}
      style={style}
    />
  )
}
