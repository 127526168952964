import * as React from "react"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import Collapse from "@mui/material/Collapse"

import Button from "../Button"
import Dialog from "../Dialog"
import {
  PartialAssignment,
  PartialAssignmentWithDepth
} from "../../modules/assignments/types"
import { ListTreeItem, ListTreeItemLeaf, NotFoundItem } from "./ListItem"
import {
  StyledDialogContent,
  StyledDialogContentText
} from "./StyledAssignmentImportDialog"

export interface Chapter {
  id: string
  title: string
  count: number
  depth: number
  assignments?: PartialAssignmentWithDepth[]
  subChapters?: Chapter[]
}

export interface Props {
  folders: Chapter[]
  open: boolean
  onClose: () => void
  secondTitle: string
  onSelect: (assignment: PartialAssignment) => void
  notFoundText: string
  elementId: string
  title: string
}

const AssignmentImportDialog: React.FC<Props> = ({
  open,
  onClose,
  onSelect,
  folders,
  notFoundText,
  elementId,
  title,
  secondTitle
}): JSX.Element => {
  const [openFolders, toggleOpenFolders] = React.useState<string[]>([])

  React.useEffect(() => {
    if (folders.length === 1 && openFolders.length === 0) {
      const [{ id }] = folders
      toggleOpenFolders([id])
    }
  }, [folders, toggleOpenFolders, openFolders])

  const toggleFolder = (id: string) => {
    if (folders.length === 1) {
      // disable the toggle when only one folder exist
      return
    }

    if (openFolders.indexOf(id) > -1) {
      toggleOpenFolders(openFolders.filter(i => i !== id))

      return
    }

    toggleOpenFolders([...openFolders, id])
  }

  const _onSelect = (assignment: PartialAssignment) => onSelect(assignment)

  const renderListItemAssignment = (assignment: PartialAssignmentWithDepth) => {
    return (
      <React.Fragment key={assignment.id}>
        <ListTreeItemLeaf
          title={assignment.title}
          indent={assignment.depth}
          onClick={() => _onSelect(assignment)}
        />
        <Divider light />
      </React.Fragment>
    )
  }

  const renderListItemFolder = (
    { id, title: folderTitle, count, depth, assignments, subChapters }: Chapter,
    i: number,
    arr: Chapter[]
  ) => {
    const isOpen = openFolders.indexOf(id) > -1

    return (
      <React.Fragment key={id}>
        <ListTreeItem
          title={folderTitle}
          items={count}
          indent={depth}
          open={isOpen}
          onClick={() => toggleFolder(id)}
        />
        {(arr.length > 1 || isOpen) && <Divider light />}
        <Collapse in={isOpen}>
          {subChapters
            ? subChapters.map(renderListItemFolder)
            : (assignments || []).map(renderListItemAssignment)}
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <Dialog open={open} aria-labelledby={elementId} onClose={onClose}>
      <DialogTitle id={elementId}>{title}</DialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>{secondTitle}</StyledDialogContentText>
        <List component="div">
          {folders.map(renderListItemFolder)}
          {folders.length === 0 && <NotFoundItem>{notFoundText}</NotFoundItem>}
        </List>
      </StyledDialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignmentImportDialog
