import { useSelector, useDispatch } from "react-redux"

import { selectStudentByPayload } from "../../modules/members/selectors"
import { selectAssignmentsByStudent } from "../../modules/assignments/selectors"
import {
  selectAssignmentProgressByStudent,
  selectAssignmentTaskProgressByStudent
} from "../../modules/assignmentsProgress/selectors"
import { createAssignmentsArrayWithStatusAndProgress } from "../../pages/Assignments/common/helpers"
import {
  selectClassroomStickers,
  selectClassroomFeatures,
  selectClassroomId,
  selectTypeOfClassroom
} from "../../modules/classroom/selectors"
import sortBy from "../../shared/tools/sortBy"
import {
  AssignmentWithStatusAndProgression,
  ProgressionWithMembers,
  AssignmentStatuses
} from "../../modules/assignments/types"
import { useMemo } from "react"
import moment, { Moment } from "moment"
import {
  gotoRoute,
  ROUTE_UPPDRAG_STUDENT_INFO
} from "../../modules/router/actions"
import { selectCurrentMemberId } from "../../modules/router/selectors"
import { selectActiveProductName } from "../../modules/products/selectors"

const sortAssignments = sortBy<AssignmentWithStatusAndProgression>(
  {
    name: "status",
    primer: (status: AssignmentStatuses) => {
      if (status === "finished" || status === "archived") {
        return -1
      }

      if (status === "draft") {
        return 0
      }

      return 1
    },
    reverse: true
  },
  {
    name: "endDate",
    primer: (endDate: Moment | null) => {
      if (!endDate) {
        return -1 * Number.MAX_SAFE_INTEGER
      }

      const currentDate = moment()

      const diffAgainstEndDate = currentDate.diff(moment(endDate), "days")

      return diffAgainstEndDate
    },
    reverse: true
  },
  {
    name: "progress",
    primer: (p: ProgressionWithMembers) => {
      if (p.notStarted.length > 0) {
        return 3
      }

      if (p.inProgress.length > 0) {
        return 2
      }

      if (p.done.length > 0) {
        return 1
      }

      if (p.approved.length > 0) {
        return 0
      }

      return -1
    },
    reverse: true
  }
)

function useStudentAssignmentList() {
  const memberId = useSelector(selectCurrentMemberId)
  const assignments = useSelector(selectAssignmentsByStudent)
  const progress = useSelector(selectAssignmentProgressByStudent)
  const taskProgress = useSelector(selectAssignmentTaskProgressByStudent)
  const member = useSelector(selectStudentByPayload)
  const stickers = useSelector(selectClassroomStickers)
  const features = useSelector(selectClassroomFeatures)
  const classroomId = useSelector(selectClassroomId)
  const activeProduct = useSelector(selectActiveProductName)
  const classroomType = useSelector(selectTypeOfClassroom)
  const dispatch = useDispatch()

  const memoAssignments = useMemo(
    () =>
      createAssignmentsArrayWithStatusAndProgress(
        assignments,
        progress,
        taskProgress,
        [member],
        classroomType === "adapt"
      ).sort(sortAssignments),
    [assignments, progress, taskProgress, member, classroomType]
  )

  const goToAssignment = (assignmentId: number) =>
    dispatch(
      gotoRoute(ROUTE_UPPDRAG_STUDENT_INFO, {
        assignmentId,
        classroomId,
        memberId
      })
    )

  return {
    assignments: memoAssignments,
    stickers,
    features,
    goToAssignment,
    member,
    activeProduct
  }
}

export default useStudentAssignmentList
