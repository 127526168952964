import Paper, { PaperProps } from "@mui/material/Paper"
import ListItem, { ListItemProps } from "@mui/material/ListItem"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import Typography, { TypographyProps } from "@mui/material/Typography"
import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText"
import AudioPlayer from "../../components/AudioPlayer"
import ArrowIcon from "@mui/icons-material/ArrowForwardIos"
import { TableCell, TableRow, styled } from "@mui/material"
import { PieChart } from "react-minimal-pie-chart"

type IconContainerProps = {
  iconColor: string
}

type StatusTagProps = {
  status: string
}

export const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
`

export const StyledPopoverInfoWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

export const StyledChartResultText = styled(Typography)`
  text-align: center;
  position: absolute;
  left: 40%;
  bottom: 0;
`

export const StyledPopoverText = styled(Typography)``

export const StyledPopoverChartIndicator = styled("div")<{
  type: "correct" | "second" | "wrong"
}>`
  width: 2em;
  height: 2em;
  background: ${props => getPopoverBackgroundColor(props.type)};
  margin-right: 5px;
`

export const StyledCollapseTableCell = styled(TableCell)`
  padding-bottom: 0;
  padding-top: 0;
`

export const StyledResults = styled("div")`
  padding: 1em;
`

export const StyledResultsTitle = styled(Typography)`
  font-size: 1.1em;
  font-weight: 600;
`

export const StyledChartContainer = styled("div")`
  margin-top: 1em;
  position: relative;
`

export const StyledChart = styled(PieChart)``

export const StyledChartWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`

export const StyledTableRow = styled(TableRow)`
  border-bottom: unset;
  > td {
    border-bottom: none;
  }
`

export const StyledChartLabel = styled(Typography)`
  text-align: center;
  position: absolute;
  top: 1em;
  left: 42%;
`

export const StyledTitleWrapper = styled("div")`
  display: flex;
  align-items: center;
`

export const SmallTableCell = styled(TableCell)`
  width: 3em;
`

export const StyledStatusTag = styled("div")`
  color: ${(props: StatusTagProps) => getStatusTextColor(props.status)};
  background-color: ${(props: StatusTagProps) => getStatusColor(props.status)};
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 6px 2px 6px;
  display: inline-block;
  margin-left: 0.5rem;
`

export const StyledAssignmentsHeaderWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 0.8em 1em;
`
export const StyledPaper = styled(Paper as React.FC<PaperProps>)`
  padding: 2em 0;
`
export const StyledContentWrapper = styled("div")`
  margin-top: 2rem;
`

export const FeedbackWrapper = styled("div")`
  max-width: 856px;
  margin: 2rem auto;
`

export const StyledCurrentSticker = styled("img")`
  width: 5em;
  flex-basis: 5em;
  padding: 0.4em;
`
export const StyledStudentInfo = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledAdaptCard = styled("div")`
  margin-top: 1em;
`

export const StyledAdaptStudentInfo = styled("div")`
  margin-left: 7em;
  margin-bottom: 2em;
`
export const StyledStudentInfoElementWrapper = styled("div")`
  margin-right: 1em;
`
export const StyledIconButton = styled(IconButton as React.FC<IconButtonProps>)`
  height: 48px;
  width: 48px;
`
export const StyledNextPrevButtons = styled("div")`
  display: flex;
  align-items: center;
  position: absolute;
  top: 4.5em;
  right: 0;
`
export const StyledIconContainer = styled("div")<IconContainerProps>`
  margin-right: 10px;
  color: ${props => props.iconColor};
`

export const StyledStatusTagWrapper = styled("div")`
  margin-top: 0.55em;
  display: flex;
  flex: 0.9;
  padding-right: 1rem;
`

export const StyledTaskContent = styled("div")`
  display: flex;
  flex: 6;
`
export const StyledListItem = styled(ListItem as React.FC<ListItemProps>)`
  display: flex;
  padding-right: 0;
`

const getStatusColor = (status: string) => {
  switch (status) {
    case "notStarted":
      return "#EBF4FB"
    case "inProgress":
      return "#42B9FE"
    case "approved":
      return "#04B887"
    default:
      return "#006CC8"
  }
}

const getStatusTextColor = (status: string) => {
  switch (status) {
    case "notStarted":
      return "#006CC8"
    case "inProgress":
      return "white"
    default:
      return "white"
  }
}

export const StyledListColumnWrapper = styled("div")`
  padding-right: 2rem;
  padding-left: 0.5rem;
`
export const StyledTitle = styled(Typography as React.FC<TypographyProps>)`
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  font-size: 21px;
  font-weight: 350;
`

export const StyledFeedbackTitle = styled(Typography)`
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: 350;
`

export const StyledTaskTitle = styled(
  ListItemText as React.FC<ListItemTextProps>
)`
  margin-bottom: 1rem;

  & .MuiListItemText-secondary > p {
    display: flex;
  }

  & .MuiListItemText-secondary > p > p {
    margin: 0 0 0 0.25rem;
  }
`

export const StyledOpenQuestion = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > div:first-child {
    width: 100%;
  }
`
export const StyledToggleReadMoreLess = styled("button")`
  background: transparent;
  border: 0;
  color: #006cc8;
  padding: 0;
  font-size: 14px;
  margin-left: 0.25rem;
`

export const StyledApproveDeclineWrapper = styled("div")`
  margin-left: 1.5rem;
  margin-bottom: 2rem;
`
export const StyledButtonWrapper = styled("div")`
  margin-top: 1rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  display: flex;
  width: 28em;
`

export const StyledAudioPlayer = styled(AudioPlayer)`
  margin: 0.5rem 0.75rem 0 0;
`
export const StyledMarginLeftWrapper = styled("div")`
  margin-left: 1.5rem;
`
export const StyledOpenQuestionItem = styled(ListItemText)`
  flex: inherit;
  margin-right: 0.5rem;
`

export const Wrapper = styled("div")`
  margin-bottom: calc(64px + 1rem);

  ${props => props.theme.breakpoints.up("md")} {
    margin-bottom: 0;
  }
`

export const StyledExampleAnswer = styled(
  ListItemText as React.FC<ListItemTextProps>
)`
  & .MuiListItemText-secondary > p {
    display: flex;
  }

  & .MuiListItemText-secondary > p > p {
    margin: 0 0 0 0.25rem;
  }
`

export const StyledQuestion = styled("div")`
  display: flex;
  align-items: center;
`
export const StyledProgressHistoryMessage = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledErrorMsg = styled("div")`
  height: 20px;
  color: #cc0000;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.23px;
  line-height: 20px;
  margin-top: 6px;
`

const getPopoverBackgroundColor = (type: "correct" | "wrong" | "second") => {
  switch (type) {
    case "correct":
      return "#038763"
    case "second":
      return "#66b5a0"
    case "wrong":
      return `repeating-linear-gradient(
        -45deg,
        #fadbd8,
        #fadbd8 5px,
        #d7513e 3px,
        #d7513e 8px
      );`
  }
}
