import { takeLatest, put, all, select } from "redux-saga/effects"

import * as actions from "./actions"
import * as communicationActions from "../communication/actions"
import * as routerSelectors from "../router/selectors"
import { sendRequest } from "../communication/actions"
import * as types from "./types"
import { MODULE_NAME } from "./constants"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import * as applicationActions from "../application/actions"
import { selectActiveProductTitle } from "./selectors"

export function* getAllProductsSaga() {
  const classroomId = yield select(routerSelectors.selectCurrentClassroomId)
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_ALL_PRODUCTS_REQUEST,
      classroomId
    )
  )
}

function* watchGetAllProductsSaga() {
  yield takeLatest(actions.getAllProducts, getAllProductsSaga)
}

export function* handleReceivedProductsResponseSaga(
  message: types.ReceiveProductsMessageTypes
) {
  const { type, action, payload, error } = message.payload
  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.GET_ALL_PRODUCTS_RESPONSE:
        yield put(
          actions.addProducts(payload as types.ReceiveProductsAction["payload"])
        )
        break
      case actions.SERVER_MESSAGE_ACTION.CHANGE_ACTIVE_PRODUCT_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.CHANGE_PRODUCT_RESPONSE_FAILURE
            )
          )
          break
        }

        const { productId } =
          payload as types.ReceiveChangeActiveProductAction["payload"]

        yield put(actions.changeActiveProductResponse(productId))
        yield put(actions.productChanged())

        const title: string = yield select(selectActiveProductTitle)

        yield put(
          applicationActions.setSnackbar({
            ...snackbarMessages.CHANGE_PRODUCT_RESPONSE,
            message: snackbarMessages.CHANGE_PRODUCT_RESPONSE.message.replace(
              "{product}",
              title
            )
          })
        )

        break
      default:
        throw new Error(
          `Unhandled message action ${message.action} for message type ${message.action} received`
        )
    }
  }
}

export function* changeActiveProductSaga(
  message: ReturnType<typeof actions.changeActiveProduct>
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.CHANGE_ACTIVE_PRODUCT_REQUEST,
      { productId: message.payload }
    )
  )
}

function* watchChangeActiveProductSaga() {
  yield takeLatest(actions.changeActiveProduct, changeActiveProductSaga)
}

function* watchReceiverSaga() {
  yield takeLatest(
    communicationActions.RECEIVE_RESPONSE,
    handleReceivedProductsResponseSaga
  )
}

export function* rootSaga() {
  yield all([
    watchGetAllProductsSaga(),
    watchReceiverSaga(),
    watchChangeActiveProductSaga()
  ])
}
