import React from "react"

import { GoalInGoalsList } from "../../pages/Goals/store/types"
import AvatarGroup from "../AvatarGroup"
import { StyledListItem, StyledListItemText } from "./StyledGoalsListItem"

type Props = {
  goal: GoalInGoalsList
  children?: never
}

export const MAX_AVATARS = 6

const GoalsListItem = ({ goal }: Props) => {
  return (
    <StyledListItem>
      <StyledListItemText>{goal.title}</StyledListItemText>
      <AvatarGroup userList={goal.current} userMax={MAX_AVATARS} />
    </StyledListItem>
  )
}

export default GoalsListItem
