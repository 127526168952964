import { createTheme } from "@mui/material/styles"
import {
  amber,
  green,
  blue,
  grey,
  teal,
  orange,
  red
} from "@mui/material/colors"

import { Theme, ThemeOptions } from "@mui/material"

export type StudliTheme = {
  studli: {
    palette: {
      onlineGreen: string
      disabledBackgroundColor: string
      disabledColor: string
      appBarBackground: string
      abilityGradeBase: string
      colorPicker: { [key: string]: { main: string; checkMarkColor: string } }
    }
    status: {
      warning: string
      ok: string
      error: string
    }
    content: {
      maxWidth: {
        text: string
        card: string
      }
    }
    progressBar: {
      segmentColor: {
        done: string
        inProgress: string
        notStarted: string
      }
      segmentTextColor: {
        done: string
        inProgress: string
        notStarted: string
      }
    }
    table: {
      hover: string
      selected: string
    }
    snackbarColor: {
      info: string
      warning: string
      error: string
      success: string
    }
    classroom: {
      themeColor: string
      themeTextColor: string
      appBarTextLight: string
      appBarTextDark: string
    }
  }
  props: {
    MuiButtonBase: {
      disableRipple: boolean
    }
  }
}

/**
 * The object specified to crateMuiTheme enhances / overrides MUI default theme
 */
const baseTheme = createTheme({
  studli: {
    palette: {
      onlineGreen: green[500],
      disabledBackgroundColor: grey[200],
      disabledColor: grey[600],
      appBarBackground: teal[700],
      abilityGradeBase: teal,

      colorPicker: {
        turquoise: { main: "#D4FCF4", checkMarkColor: "#0A3F35" },
        lightPurple: { main: "#E4DCF9", checkMarkColor: "#432B5E" },
        yellow: { main: "#FCE4B4", checkMarkColor: "#7B5437" },
        orange: { main: "#F0C9AD", checkMarkColor: "#AA251F" },
        lightRed: { main: "#F9DAD7", checkMarkColor: "#AA251F" },
        lightBlue: { main: "#E3ECFE", checkMarkColor: "#4160B9" },
        darkGreen: { main: "#0A3F35", checkMarkColor: "#D4FCF4" },
        purple: { main: "#432B5E", checkMarkColor: "#E4DCF9" },
        blue: { main: "#4160B9", checkMarkColor: "#E3ECFE" },
        red: { main: "#AA251F", checkMarkColor: "#F9DAD7" },
      },
    },
    status: {
      warning: orange[500],
      ok: green[800],
      error: red[600]
    },
    content: {
      maxWidth: {
        text: "54em",
        card: "64em"
      }
    },
    progressBar: {
      segmentColor: {
        done: "#006CC8",
        inProgress: "#42B9FE",
        notStarted: "#E0F2FF"
      },
      segmentTextColor: {
        done: "white",
        inProgress: "#004383",
        notStarted: "#006CC8"
      }
    },
    snackbarColor: {
      info: blue[600],
      warning: amber[700],
      error: red[600],
      success: green[600]
    },
    table: {
      hover: "#ecf4fb",
      selected: "#d6e7f7"
    },
    classroom: {
      themeColor: teal[700],
      themeTextColor: "white",
      appBarTextLight: "white",
      appBarTextDark: "black"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#f8f8f8"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "black",
          backgroundColor: "white"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#F1F5F9"
        }
      }
    }
  },
  overrides: {
    MuiList: {
      root: {
        outline: "none",
        "& > div > a": {
          "&:focus": {
            outline: "none",
            "& > div": {
              backgroundColor: "rgba(0, 0, 0, 0.14)"
            }
          }
        }
      }
    },
    MuiCardActions: {
      root: {
        "& > *": {
          "&:focus": {
            outline: "none",
            "& > *": {
              backgroundColor: "rgba(0, 0, 0, 0.14)"
            }
          }
        }
      }
    },
    MuiIconButton: {
      root: {
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "black"
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
} as ThemeOptions)

export default baseTheme as Theme & StudliTheme
