import React, { useState, useEffect } from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import moment from "moment"
import Button from "../../components/Button"
import { RootState } from "../../modules/store"
import {
  StyledPaper,
  StyledPaperContent,
  StyledHeaderButtonContainer,
  StyledHeaderButtonsLeft,
  StyledButtonWrapper,
  StyledGridContainer,
  StyledTitleContainer,
  StyledTitle,
  StyledParagraph,
  StyledStudentsHeader,
  StyledSticker,
  StyledCurrentSticker,
  StyledContentTitle,
  StyledProgressGrid
} from "./StyledAssignmentCard"
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material"
import {
  selectCurrentAssignmentId,
  selectCurrentClassroomId
} from "../../modules/router/selectors"
import { selectAllAssignments } from "../../modules/assignments/selectors"
import {
  Assignments,
  AssignmentWithStatus,
  CreateAssignmentData,
  Assignment,
  EditEchoActionTypes
} from "../../modules/assignments/types"
import {
  calculateStatus,
  calculatedStatusTag,
  getProgressAndMemberForAdapt,
  getMenuOptions
} from "../Assignments/common/helpers"
import { StyledStatusTag } from "../Assignments/StyledAssignments"
import AssignmentTaskList from "../../components/AssignmentTaskList"
import { selectStudentsWithOnlineStatus } from "../../modules/members/selectors"
import { Member, Members } from "../../modules/members/types"
import MoreMenu from "../../components/PopupMenu"
import * as routerActions from "../../modules/router/actions"
import {
  selectClassroomStickers,
  selectClassroomFeatures,
  selectTypeOfClassroom
} from "../../modules/classroom/selectors"
import { Stickers, Sticker, Features } from "../../modules/classroom/types"
import * as assignmentsProgressSelectors from "../../modules/assignmentsProgress/selectors"
import {
  AssignmentsTaskProgress,
  AssignmentsProgress
} from "../../modules/assignmentsProgress/types"
import * as assignmentsActions from "../../modules/assignments/actions"
import HtmlRenderer from "../../components/HtmlRenderer"
import AudioPlayer from "../../components/AudioPlayer"
import { toTextAndAudio } from "../../shared/tools/parse"
import PreviewModal from "../../components/PrevieModal"
import Dialog from "../../components/Dialog"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { selectGoalsWithProgress } from "../../modules/goals/selectors"
import GoalAssignmentList from "../../components/GoalAssignmentList/GoalAssignmentList"
import Progress from "../../components/Progress"
import InfoPopover from "../../components/InfoPopover"
import {
  StyledLegendBox,
  StyledLegendContainer
} from "../../components/GoalsTable/StyledGoalsTable"
import { PROGRESS_SEGMENT } from "../../components/Progress/constants"
import AssignmentCardTable from "./AssignmentCardTable"
import { MembersWithOnlineStatus } from "../Exercises/store/types"
import ExercisesProgressDialog from "../../components/ExercisesProgressDialog"
import IsPublicIcon from "../../components/IsPublicIcon/IsPublicIcon"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import ResultTabs from "../../components/ResultTabs/ResultTabs"
import Avatar from "../../components/Avatar"
import { Theme } from "@mui/system"

type StateProps = {
  assignmentId: number
  assignments: Assignments
  members: MembersWithOnlineStatus
  classroomId: number
  stickers: Stickers
  classroomFeatures: Features
  assignmentsTaskProgress: AssignmentsTaskProgress
  assignmentsProgress: AssignmentsProgress
}

type DispatchProps = {
  goToRoute: (type: string, payload: object) => void
  editAssignment: (assignment: Assignment, echo: EditEchoActionTypes) => void
  createAssignment: (assignment: CreateAssignmentData) => void
  removeAssignment: (assignmentId: Assignment["id"]) => void
  storePreview: (data: string) => void
}

type Props = DispatchProps & StateProps

const NOOP = () => { }

export const AssignmentCard = ({
  assignmentId,
  assignments,
  members,
  classroomId,
  goToRoute,
  stickers,
  assignmentsProgress,
  assignmentsTaskProgress,
  editAssignment,
  removeAssignment,
  createAssignment,
  classroomFeatures,
  storePreview
}: Props) => {
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentWithStatus | null>(null)
  const user = useSelector(selectAuthenticatedUser)

  const [progressDialogData, setProgressDialogData] = useState<{
    done: Members
    inProgress: Members
    notStarted: Members
    title: string
  } | null>(null)

  const isMobile = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down("sm")
  )
  const classroomType = useSelector(selectTypeOfClassroom)
  const goals = useSelector(selectGoalsWithProgress)
  const [onConfirmRemoveDialog, setOnConfirmRemoveDialog] = useState<{
    assignment: Assignment
  } | null>(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (assignmentId) {
      dispatch(assignmentsActions.getAssignment(assignmentId))
    }
  }, [dispatch, assignmentId])

  useEffect(() => {
    if (assignments.length) {
      const assignment = assignments.find(
        assignment => assignment.id === assignmentId
      )
      if (assignment) {
        const isAdapt = classroomType === "adapt"
        const assignmentWithStatus = {
          ...assignment,
          calculatedStatus: calculateStatus(
            assignment.status,
            assignment.startDate,
            assignment.endDate,
            isAdapt
          )
        }
        setSelectedAssignment(assignmentWithStatus)
      }
    }
  }, [assignments, assignmentId, classroomType])

  const getMenuItems = () => {
    let optionsAlreadyAsButtons = ["Redigera", "Avsluta"]
    selectedAssignment &&
      selectedAssignment.calculatedStatus === "finished" &&
      optionsAlreadyAsButtons.push("Skapa kopia")
    selectedAssignment &&
      selectedAssignment.calculatedStatus === "archived" &&
      optionsAlreadyAsButtons.push("Aktivera") &&
      optionsAlreadyAsButtons.push("Ta bort")
    let menuItems = []
    if (selectedAssignment) {
      menuItems = getMenuOptions(
        onMenuItemClick,
        selectedAssignment.id,
        selectedAssignment.calculatedStatus,
        selectedAssignment.isPublic,
        selectedAssignment.createdBy === user?.studliId
      )
      menuItems = menuItems.filter(
        menuItem =>
          !optionsAlreadyAsButtons.some(option => option === menuItem.option)
      )
    }

    return isMobile
      ? [...menuItems, { action: handlePreview, option: "Förhandsgranska" }]
      : menuItems
  }

  const getCurrentSticker = (id: Sticker["id"]) => {
    const currentSticker = stickers.filter(
      (sticker: Sticker) => sticker.id === id
    )

    return currentSticker.length > 0 ? currentSticker[0] : stickers[0]
  }
  const onMenuItemClick = (type: string) => {
    const assignment = selectedAssignment as Assignment
    switch (type) {
      case "share":
        editAssignment(
          { ...assignment, isPublic: !assignment.isPublic },
          "togglePrivate"
        )
        break
      case "activate":
        editAssignment({ ...assignment, status: "published" }, "publish")
        break
      case "archive":
        editAssignment({ ...assignment, status: "archived" }, "archive")
        break
      case "createCopy":
        const assignmentToCopy = { ...assignment, participants: [] }
        // @ts-ignore
        delete assignmentToCopy.id
        assignmentToCopy.assignmentTasks = (
          assignmentToCopy.assignmentTasks || []
        ).filter(t => !t.needsManualModification)
        assignmentToCopy.title = `Kopia av: ${assignmentToCopy.title}`
        assignmentToCopy.status = "draft"
        assignmentToCopy.isPublic = false

        createAssignment(assignmentToCopy as CreateAssignmentData)
        goToRoute(routerActions.ROUTE_UPPDRAG, {
          classroomId
        })
        break
      case "edit":
        goToRoute(routerActions.ROUTE_UPPDRAG_REDIGERA, {
          classroomId,
          assignmentId: assignment.id
        })
        break
      case "finish":
        editAssignment({ ...assignment, status: "finished" }, "finish")
        break

      case "remove":
        setOnConfirmRemoveDialog({ assignment })
        break
      case "start":
        editAssignment({ ...assignment, startDate: moment() }, "start")
        break
    }
  }

  const onRemoveAssignment = (assignmentId: number) => {
    removeAssignment(assignmentId)
    setOnConfirmRemoveDialog(null)
    goToRoute(routerActions.ROUTE_UPPDRAG, {
      classroomId
    })
  }

  const handlePreview = () => {
    const assignment: AssignmentWithStatus | null = selectedAssignment
    if (assignment) {
      const stickerUrl = assignment.stickerId
        ? getCurrentSticker(assignment.stickerId).path
        : null

      const values = {
        stickerUrl: stickerUrl,
        title: assignment.title,
        instruction: toTextAndAudio(assignment.instruction as string),
        stickerId: assignment.stickerId,
        status: assignment.status,
        members: assignment.participants,
        assignmentTasks: assignment.assignmentTasks,
        startDate: assignment.startDate,
        endDate: assignment.endDate
      }

      storePreview(
        JSON.stringify({
          ...values,
          assignmentTasks: values.assignmentTasks.map((t, i) => ({
            ...t,
            id: i
          }))
        })
      )
    }
  }

  const moveToAssignmentStudentCard = (memberId: Member["studliId"]) => {
    goToRoute(routerActions.ROUTE_UPPDRAG_STUDENT_INFO, {
      classroomId,
      assignmentId: assignmentId,
      memberId: memberId
    })
  }

  const getStartDate = (assignment: AssignmentWithStatus) => {
    if (
      assignment.calculatedStatus === "ongoing" ||
      assignment.calculatedStatus === "finished" ||
      assignment.calculatedStatus === "passed"
    ) {
      return <> Startade: {moment(assignment.startDate).format("YYYY-MM-DD")}</>
    } else if (assignment.calculatedStatus === "coming") {
      return <> Startar: {moment(assignment.startDate).format("YYYY-MM-DD")}</>
    }

    return null
  }

  const adaptProgress =
    selectedAssignment &&
    getProgressAndMemberForAdapt(
      selectedAssignment,
      assignmentsProgress,
      members
    )

  const getEndDate = (assignment: AssignmentWithStatus) => {
    if (assignment.endDate) {
      if (
        assignment.calculatedStatus === "ongoing" ||
        assignment.calculatedStatus === "coming"
      ) {
        return <> Slutar: {moment(assignment.endDate).format("YYYY-MM-DD")}</>
      } else if (
        assignment.calculatedStatus === "finished" ||
        assignment.calculatedStatus === "passed"
      ) {
        return <> Slutade: {moment(assignment.endDate).format("YYYY-MM-DD")}</>
      }
    } else {
      return "Inget slutdatum"
    }

    return null
  }

  const renderButtons = () => {
    return (
      <StyledHeaderButtonContainer>
        <StyledHeaderButtonsLeft>
          <StyledButtonWrapper>
            {selectedAssignment?.status !== "finished" &&
              selectedAssignment?.status !== "archived" && (
                <Button
                  onClick={() => onMenuItemClick("edit")}
                  variant="outlined"
                  color="primary"
                >
                  Redigera
                </Button>
              )}
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            {selectedAssignment &&
              (selectedAssignment.calculatedStatus === "ongoing" ||
                selectedAssignment.calculatedStatus === "passed") && (
                <Button
                  onClick={() => onMenuItemClick("finish")}
                  variant="outlined"
                  color="primary"
                >
                  Avsluta
                </Button>
              )}
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            {selectedAssignment &&
              selectedAssignment.calculatedStatus === "finished" && (
                <Button
                  onClick={() => onMenuItemClick("createCopy")}
                  variant="outlined"
                  color="primary"
                >
                  Skapa kopia
                </Button>
              )}
          </StyledButtonWrapper>
          {selectedAssignment &&
            selectedAssignment.calculatedStatus === "archived" && (
              <>
                <StyledButtonWrapper>
                  <Button
                    onClick={() => onMenuItemClick("activate")}
                    variant="outlined"
                    color="primary"
                  >
                    Aktivera
                  </Button>
                </StyledButtonWrapper>
                <StyledButtonWrapper>
                  {selectedAssignment.createdBy === user?.studliId && (
                    <Button
                      onClick={() => onMenuItemClick("remove")}
                      variant="outlined"
                      color="primary"
                    >
                      Ta bort
                    </Button>
                  )}
                </StyledButtonWrapper>
              </>
            )}
          {getMenuItems().length > 0 && <MoreMenu menuItems={getMenuItems()} />}
        </StyledHeaderButtonsLeft>
        {classroomType !== "adapt" && !isMobile && (
          <Button variant="outlined" color="primary" onClick={handlePreview}>
            Förhandsgranska
          </Button>
        )}
      </StyledHeaderButtonContainer>
    )
  }

  const onOpenProgressDialog = (
    done: Members,
    inProgress: Members,
    notStarted: Members,
    title: string
  ) => {
    const doneWithMember: Members = getMembersByIds(done) as Members
    const inProgressWithMember: Members = getMembersByIds(inProgress) as Members
    const notStartedWithMember: Members = getMembersByIds(notStarted) as Members

    setProgressDialogData({
      done: doneWithMember,
      inProgress: inProgressWithMember,
      notStarted: notStartedWithMember,
      title
    })
  }

  const getMembersByIds = (ids: Members) =>
    ids.map(getMemberById).filter(Boolean)

  const getMemberById = (m: Member) =>
    members.find(member => member.studliId === m.studliId)

  const renderTitle = (assignment: AssignmentWithStatus) => {
    const instructions = assignment.instruction
      ? JSON.parse(assignment.instruction as string)
      : {}

    return (
      <StyledGridContainer wrap="nowrap" container>
        <Grid item>
          {assignment.stickerId && (
            <StyledSticker>
              <StyledCurrentSticker
                src={getCurrentSticker(assignment.stickerId).path}
                alt={getCurrentSticker(assignment.stickerId).name}
              />
            </StyledSticker>
          )}
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <StyledTitleContainer>
              <StyledTitle variant="h1">{assignment.title}</StyledTitle>
              <StyledStatusTag status={assignment.calculatedStatus}>
                {calculatedStatusTag(
                  assignment.calculatedStatus,
                  assignment.startDate,
                  assignment.endDate
                )}
              </StyledStatusTag>
            </StyledTitleContainer>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1.25} mt={1}>
              <Typography>Skapat av</Typography>
              <Avatar
                firstName={assignment.createdByFirstName}
                lastName={assignment.createdByLastName}
                studliId={assignment.createdBy}
                picture={assignment.createdByPictureUrl}
              />
              <Box ml={1} alignItems="center" display="flex">
                <IsPublicIcon
                  isPublic={assignment.isPublic}
                  createdBy={assignment.createdBy}
                />
              </Box>
            </Stack>
          </Grid>
          {classroomType === "adapt" && (
            <>
              {adaptProgress && (
                <>
                  <StyledProgressGrid item>
                    <InfoPopover
                      type="Info"
                      content={
                        <div>
                          <StyledLegendContainer>
                            <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                          Inlämnat / Klar
                        </StyledLegendContainer>
                          <StyledLegendContainer>
                            <StyledLegendBox
                              segment={PROGRESS_SEGMENT.IN_PROGRESS}
                            />
                          Påbörjad
                        </StyledLegendContainer>
                          <StyledLegendContainer>
                            <StyledLegendBox
                              segment={PROGRESS_SEGMENT.NOT_STARTED}
                            />
                          Ej påbörjad
                        </StyledLegendContainer>
                        </div>
                      }
                    />
                    {!isMobile && (
                      <Progress
                        onOpenProgressDialog={() =>
                          onOpenProgressDialog(
                            adaptProgress.done,
                            adaptProgress.inProgress,
                            adaptProgress.notStarted,
                            assignment.title
                          )
                        }
                        done={adaptProgress.done.length}
                        notStarted={adaptProgress.notStarted.length}
                        inProgress={adaptProgress.inProgress.length}
                        type="assignments"
                      />)}
                  </StyledProgressGrid>
                  {isMobile && (
                    <Progress
                      onOpenProgressDialog={() =>
                        onOpenProgressDialog(
                          adaptProgress.done,
                          adaptProgress.inProgress,
                          adaptProgress.notStarted,
                          assignment.title
                        )
                      }
                      done={adaptProgress.done.length}
                      notStarted={adaptProgress.notStarted.length}
                      inProgress={adaptProgress.inProgress.length}
                      type="assignments"
                    />
                  )}
                </>
              )}
              <Grid item>
                <StyledParagraph>
                  Räkna i {assignment.threshold} min{" "}
                  {assignment.assignmentTasks.length > 0 &&
                    `(varav ${assignment.repetitionAmount}% uppdragsmål) `}
                </StyledParagraph>
              </Grid>
            </>
          )}
          <Grid item>
            {instructions && (
              <>
                <HtmlRenderer html={instructions.text} />
                {instructions.audio && (
                  <AudioPlayer label="Ljudklipp" src={instructions.audio} />
                )}
              </>
            )}
          </Grid>
          <Grid item>
            <StyledParagraph>
              {getStartDate(assignment)}
              <br />
              {assignment.endDate && getEndDate(assignment)}
            </StyledParagraph>
          </Grid>
        </Grid>
      </StyledGridContainer>
    )
  }

  const renderAssignmentTask = (assignment: AssignmentWithStatus) => {
    const currentMembers = assignment.participantsInfo
      .filter(p => members.some(m => m.studliId === p.studliId))
      .map(p => p.studliId)
    return (
      <StyledPaper>
        <StyledPaperContent>
          <StyledStudentsHeader>
            <StyledContentTitle>
              Innehåll ({assignment.assignmentTasks.length})
            </StyledContentTitle>
          </StyledStudentsHeader>
          {classroomType === "adapt" ? (
            <GoalAssignmentList
              goals={goals}
              tasks={assignment.assignmentTasks}
              members={assignment.participants}
              selectedGoals={[]}
              onToggle={NOOP}
              inCreateList
              showRemove={false}
              onRemove={NOOP}
            />
          ) : (
              <AssignmentTaskList
                classroomFeatures={classroomFeatures}
                editTask={() => null}
                removeTask={() => null}
                setFieldValue={() => null}
                assignmentTasks={assignment.assignmentTasks}
                editMode={false}
                assignmentsTaskProgress={assignmentsTaskProgress}
                assignmentMembers={currentMembers}
                members={members}
              />
            )}
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const renderStudents = (assignment: AssignmentWithStatus) => {
    const isRowClickable =
      assignment.calculatedStatus !== "draft" &&
      assignment.calculatedStatus !== "coming" &&
      classroomType !== "adapt"
    return (
      <StyledPaper>
        <StyledPaperContent>
          <StyledStudentsHeader>
            <h3>Elever ({assignment.participants.length})</h3>
          </StyledStudentsHeader>
          {selectedAssignment && (
            <ResultTabs
              type="assignment"
              data={assignment}
              resultTable={
                <AssignmentCardTable
                  isAdapt={classroomType === "adapt"}
                  assignment={selectedAssignment}
                  members={members}
                  progress={{ assignmentsTaskProgress, assignmentsProgress }}
                  rowsClickable={isRowClickable}
                  onRowClick={moveToAssignmentStudentCard}
                />
              }
            />
          )}
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  return (
    <>
      {selectedAssignment && (
        <>
          {renderButtons()}
          {renderTitle(selectedAssignment)}
          {selectedAssignment.assignmentTasks.length > 0 &&
            renderAssignmentTask(selectedAssignment)}
          {renderStudents(selectedAssignment)}
        </>
      )}
      {onConfirmRemoveDialog && (
        <Dialog
          onClose={() => setOnConfirmRemoveDialog(null)}
          maxWidth="xs"
          fullWidth
          open
          aria-modal="true"
          aria-labelledby="ta-bort-dialog-title"
          aria-describedby="ta-bort-dialog-description"
        >
          <ConfirmationDialog
            title="Ta bort uppdrag"
            body={`Vill du ta bort ${onConfirmRemoveDialog.assignment.title}?  Uppdraget kommer inte längre synas i klassrummet. Det tas även bort från elevernas vy. `}
            onCancel={() => setOnConfirmRemoveDialog(null)}
            confirmLabel="Ta bort uppdrag"
            cancelLabel="Avbryt"
            onConfirm={() =>
              onRemoveAssignment(onConfirmRemoveDialog.assignment.id)
            }
            ariaTitleId="ta-bort-dialog-title"
            ariaDescId="ta-bort-dialog-description"
          />
        </Dialog>
      )}
      <PreviewModal />
      {progressDialogData !== null && (
        <Dialog
          onClose={() => setProgressDialogData(null)}
          open={progressDialogData !== null}
          maxWidth="sm"
          fullWidth
        >
          <ExercisesProgressDialog
            onClose={() => setProgressDialogData(null)}
            inProgress={
              progressDialogData.inProgress as MembersWithOnlineStatus
            }
            done={progressDialogData.done as MembersWithOnlineStatus}
            notStarted={
              progressDialogData.notStarted as MembersWithOnlineStatus
            }
            type="assignmentsTask"
            title={progressDialogData.title}
          />
        </Dialog>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  assignmentId: selectCurrentAssignmentId(state),
  assignments: selectAllAssignments(state),
  members: selectStudentsWithOnlineStatus(state),
  classroomId: selectCurrentClassroomId(state),
  stickers: selectClassroomStickers(state),
  classroomFeatures: selectClassroomFeatures(state),
  assignmentsTaskProgress:
    assignmentsProgressSelectors.selectAllAssignmentsTaskProgress(state),
  assignmentsProgress:
    assignmentsProgressSelectors.selectAllAssignmentsProgress(state)
})
const mapDispatchToProps: DispatchProps = {
  goToRoute: routerActions.gotoRoute,
  editAssignment: assignmentsActions.editAssignment,
  createAssignment: assignmentsActions.createAssignment,
  removeAssignment: assignmentsActions.removeAssignment,
  storePreview: assignmentsActions.storePreview
}
const ConnectedAssignmentCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentCard)

export default ConnectedAssignmentCard
