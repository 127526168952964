export const statuses = {
  coming: "Kommande",
  ongoing: "Pågående",
  passed: "Slutdatum paserat",
  finished: "Avslutat",
  draft: "Utkast",
  archived: "Arkiverat"
}

export const menuItemActions = {
  START: "start",
  EDIT: "edit",
  CREATE_COPY: "createCopy",
  ARCHIVE: "archive",
  REMOVE: "remove",
  FINISH: "finish",
  ACTIVATE: "activate",
  SHARE: "share"
}
