import React from "react"
import { ListItemIcon, Checkbox } from "@mui/material"
import { ReactComponent as Folder } from "../../assets/images/folder-closed.svg"
import { ReactComponent as FolderOpen } from "../../assets/images/folder-open.svg"
import { ReactComponent as Hearing } from "../../assets/images/volume-control-full.svg"
import { ReactComponent as Videocam } from "../../assets/images/video-player.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/exercise.svg"
import { ReactComponent as TestIcon } from "../../assets/images/test-icon.svg"
import { ReactComponent as BoardIcon } from "../../assets/images/board.svg"
import { ReactComponent as PresentationIcon } from "../../assets/images/presentation.svg"

import {
  StyledListItem,
  StyledNumberOfExercisesText,
  StyledPrimaryTextContainer,
  StyledTextSegment,
  StyledTextContainer
} from "./StyledExerciseListItem"
import ExerciseTypeIcon from "../ExerciseTypeIcon"
import Progress, {
  SimpleProgress,
  SmallProgressBar,
  getSegment
} from "../Progress"
import { isParent } from "../../pages/Exercises/common/helpers"
import {
  ParentOrExerciseNode,
  ExerciseId,
  ExerciseNode,
  ParentNode
} from "../../modules/exercises/types"
import { ExerciseProgress } from "../../modules/exercisesProgress/types"
import { Members, Member } from "../../modules/members/types"
import { StyledIconContainer } from "../ExerciseTypeIcon/StyledExerciseTypeIcon"
import HtmlRenderer from "../HtmlRenderer"
import ExerciseResult from "../ExerciseResult"

type Props = {
  node: ParentOrExerciseNode
  expandedNodes?: ExerciseId[]
  exercisesProgress: ExerciseProgress[]
  members?: Members
  numberOfNodesBelow: number
  isChecked: boolean
  onCheckboxClicked: Function
  onProgressDialogOpen?: Function
  onItemClick?: Function
  selectable: boolean
  hideStatus: boolean
  type: "exercises" | "pages" | "studentCard"
}

export const ICONS: any = {
  audio: <Hearing aria-label="Lyssna" />,
  video: <Videocam aria-label="Titta" />,
  exercise: <ExerciseIcon aria-label="Övning" />,
  text: <ExerciseIcon aria-label="Övning" />,
  xcase: <ExerciseIcon aria-label="Övning" />,
  board: <BoardIcon aria-label="Tavla" />,
  presentation: <PresentationIcon aria-label="Presentation" />,
  test: <TestIcon aria-label="Test" />
}

const ExerciseListItem = ({
  node,
  expandedNodes = [],
  numberOfNodesBelow,
  isChecked,
  onCheckboxClicked,
  exercisesProgress,
  members,
  onProgressDialogOpen,
  onItemClick,
  selectable,
  hideStatus,
  type
}: Props) => {
  const getInProgress = (id: string, membersWithProducts: Members) => {
    return exercisesProgress.filter(
      progress =>
        progress.exerciseId === id &&
        progress.isInProgress &&
        membersWithProducts.find(
          (member: Member) => member.studliId === progress.studliId
        )
    ).length
  }
  const getDone = (id: string, membersWithProducts: Members) => {
    return exercisesProgress.filter(
      progress =>
        progress.exerciseId === id &&
        !progress.isInProgress &&
        membersWithProducts.find(
          (member: Member) => member.studliId === progress.studliId
        )
    ).length
  }

  const getNotStarted = (id: string) => {
    if (members) {
      return (
        members.length -
        exercisesProgress.filter(
          progress =>
            progress.exerciseId === id &&
            members.find(
              (member: Member) => member.studliId === progress.studliId
            )
        ).length
      )
    }

    return 0
  }

  const renderIcon = () => {
    if (!isParent(node) && node.type) {
      return <ExerciseTypeIcon iconColor="#4f93d5" type={node.type} />
    }

    return (
      <StyledIconContainer marginTop iconColor="#5e646c">
        {expandedNodes.some(expandedNode => expandedNode === node.uniqueId) ? (
          <FolderOpen aria-label="Mapp öppen" />
        ) : (
          <Folder aria-label="Mapp" />
        )}
      </StyledIconContainer>
    )
  }

  const rowClicked = () => {
    if (onItemClick && !isParent(node)) {
      onItemClick(node)
    }
  }

  const renderProgress = (
    exeNode: ExerciseNode,
    membersWithProducts: Members
  ) => {
    let status = "notStarted"
    const inProgress = getInProgress(exeNode.id, membersWithProducts)
    const done = getDone(exeNode.id, membersWithProducts)
    const notStarted = getNotStarted(exeNode.id)

    if (done > inProgress) {
      status = "done"
    } else if (inProgress > notStarted) {
      status = "inProgress"
    }

    return type !== "studentCard" ? (
      <Progress
        type="exercises"
        inProgress={inProgress}
        done={done}
        notStarted={notStarted}
        onOpenProgressDialog={() =>
          onProgressDialogOpen && onProgressDialogOpen(exeNode)
        }
      />
    ) : (
      <SimpleProgress status={status} type="exercise" />
    )
  }

  const getNodeProgress = (
    node: ParentNode,
    membersWithProducts: Members
  ): { done: number; inProgress: number; notStarted: number } =>
    node.children.reduce(
      (result, child) => {
        if (isParent(child)) {
          const childrenResult = getNodeProgress(child, membersWithProducts)

          return {
            done: result.done + childrenResult.done,
            inProgress: result.inProgress + childrenResult.inProgress,
            notStarted: result.notStarted + childrenResult.notStarted
          }
        }

        const inProgress = getInProgress(child.id, membersWithProducts)
        const done = getDone(child.id, membersWithProducts)
        const notStarted = getNotStarted(child.id)

        return {
          done: result.done + done,
          inProgress: result.inProgress + inProgress,
          notStarted: result.notStarted + notStarted
        }
      },
      { done: 0, inProgress: 0, notStarted: 0 }
    )

  const getParentProgress = (
    node: ParentNode,
    membersWithProducts: Members
  ) => {
    const { done, inProgress, notStarted } = getNodeProgress(
      node,
      membersWithProducts
    )

    const total = done + inProgress + notStarted

    let status = "inProgress"

    if (done === total) {
      status = "done"
    } else if (notStarted === total) {
      status = "notStarted"
    }

    return (
      <SmallProgressBar
        segment={getSegment(
          status as "done" | "inProgress" | "notStarted" | "approved"
        )}
      >
        {done} av {total} klara
      </SmallProgressBar>
    )
  }

  const getProgressForOneMember = () => {
    if (type !== "studentCard" || isParent(node) || !members) {
      return false
    }

    return exercisesProgress.find(
      progress =>
        progress.exerciseId === node.id &&
        progress.studliId === members[0].studliId
    )
  }
  const memberProgress = getProgressForOneMember()
  return (
    <StyledListItem
      onClick={rowClicked}
      component={"div" as any}
      divider={true}
    >
      <ListItemIcon>
        <>
          {selectable && (
            <Checkbox
              edge="start"
              aria-label={node.title}
              onClick={event => onCheckboxClicked(event, node)}
              checked={isChecked}
              tabIndex={-1}
              disableRipple
            />
          )}

          {renderIcon()}
        </>
      </ListItemIcon>
      <StyledTextContainer>
        <StyledPrimaryTextContainer>
          <StyledTextSegment>
            <HtmlRenderer html={node.title} />
            <StyledNumberOfExercisesText>
              {type !== "studentCard" && numberOfNodesBelow
                ? `(${numberOfNodesBelow})`
                : ""}
            </StyledNumberOfExercisesText>
            {!isParent(node) &&
              members &&
              !hideStatus &&
              type === "studentCard" &&
              renderProgress(node, members)}
            {isParent(node) &&
              members &&
              type === "studentCard" &&
              getParentProgress(node, members)}
          </StyledTextSegment>

          {!isParent(node) &&
            members &&
            !hideStatus &&
            type !== "studentCard" && (
              <StyledTextSegment>
                {renderProgress(node, members)}
              </StyledTextSegment>
            )}
        </StyledPrimaryTextContainer>
        {memberProgress && !memberProgress.isInProgress && (
          <ExerciseResult progress={memberProgress} />
        )}
      </StyledTextContainer>
    </StyledListItem>
  )
}

export default ExerciseListItem
