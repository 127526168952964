import React from "react"
import { GoalInGoalsList } from "../../pages/Goals/store/types"
import AvatarGroup from "../AvatarGroup"
import Progress from "../Progress"
import { StyledTableCell, StyledTableRow } from "./StyledGoalsTableRow"

type Props = {
  goal: GoalInGoalsList
  totalMembers: number
  children?: never
  index: number
  onOpenProgressDialog: Function
  goToDetails: (goalRef: string) => void
}

export const MAX_AVATARS = 6

export const Row = ({
  goal,
  totalMembers,
  onOpenProgressDialog,
  index,
  goToDetails
}: Props) => {
  const onProgressBarClick = () => {
    onOpenProgressDialog(index)
  }

  return (
    <StyledTableRow hover onClick={() => goToDetails(goal.goalRef)}>
      <StyledTableCell>
        {goal.title}
        <AvatarGroup userMax={MAX_AVATARS} userList={goal.current} />
      </StyledTableCell>
      <StyledTableCell>
        <Progress
          onOpenProgressDialog={onProgressBarClick}
          done={goal.done.length}
          inProgress={goal.inProgress.length}
          notStarted={
            totalMembers - (goal.done.length + goal.inProgress.length)
          }
          type="goals"
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default Row
