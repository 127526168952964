import { FilterValue } from "../../components/TableFilter/types"

export const tableFilter = (filterValues: FilterValue[], arr: any) => {
  const groupedFiltersByKey = filterValues.reduce((acc, filter) => {
    if (!acc[filter.key]) {
      acc[filter.key] = []
    }
    acc[filter.key].push(filter)
    return acc
  }, {} as Record<string, FilterValue[]>)
  const filterValuesArray = Object.values(groupedFiltersByKey)
  const filtered = arr.filter((a: any) =>
    filterValuesArray.every(filters =>
      filters.some(filter => filter.filterFunction(a))
    )
  )
  return filtered
}
