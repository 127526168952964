import React from "react"
import { connect } from "react-redux"
import { RootState } from "../../modules/store"
import * as routerActions from "../../modules/router/actions"
import { Container } from "@mui/material"
import * as routeSelectors from "../../modules/router/selectors"
import { selectStudentsLength } from "../../modules/members/selectors"

type StateProps = {
  currentRoute: string
  numberOfMembersInClassroom: number
}

type OwnProps = {
  children: React.ReactNode
}

type Props = StateProps & OwnProps

export const ContentWrapper = ({
  currentRoute,
  numberOfMembersInClassroom,
  children
}: Props) => {
  if (!children) {
    return null
  }

  return currentRoute === routerActions.ROUTE_MAL ||
    currentRoute === routerActions.ROUTE_ELEVER ||
    currentRoute === routerActions.ROUTE_HJALP ||
    currentRoute === routerActions.ROUTE_FORMATIVA_FRAGOR ||
    (currentRoute === routerActions.ROUTE_AKTIVERADE &&
      numberOfMembersInClassroom < 1) ||
    currentRoute === routerActions.ROUTE_UPPDRAG ? (
    <span>{children}</span>
  ) : (
    <Container maxWidth="lg">{children}</Container>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentRoute: routeSelectors.selectCurrentRoute(state),
  numberOfMembersInClassroom: selectStudentsLength(state)
})

const ConnectedContentWrapper = connect(mapStateToProps)(ContentWrapper)

export default ConnectedContentWrapper
