import { takeLatest, put, all, race } from "redux-saga/effects"
import { sendRequest } from "../communication/actions"
import * as exercisesActions from "./actions"
import * as communicationActions from "../communication/actions"
import * as exercisesTypes from "./types"
import { MODULE_NAME } from "./constants"

function* getAllExercisesSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      exercisesActions.SERVER_MESSAGE_ACTION.GET_ALL_EXERCISES_REQUEST
    )
  )
}

function* getAllTemaTocSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      exercisesActions.SERVER_MESSAGE_ACTION.GET_TEMATOC_REQUEST
    )
  )
}

function* watchGetAllExercisesSaga() {
  yield takeLatest(exercisesActions.getAllExercises, getAllExercisesSaga)
}

function* watchGetTemaTocSaga() {
  yield takeLatest(exercisesActions.getTemaToc, getAllTemaTocSaga)
}

export function* handleReceiveExercisesResponseSaga(
  message: exercisesTypes.ReceiveExercisesResponseMessagesTypes
) {
  const { type, action, payload } = message.payload
  if (type === MODULE_NAME) {
    switch (action) {
      case exercisesActions.SERVER_MESSAGE_ACTION.GET_ALL_EXERCISES_RESPONSE:
        if (payload && payload.toc) {
          yield put(exercisesActions.setExercises(payload))
        }
        break
      case exercisesActions.SERVER_MESSAGE_ACTION.GET_TEMATOC_RESPONSE:
        if (payload && payload.toc) {
          yield put(exercisesActions.setTemaToc({ temaToc: payload.toc }))
        }
        break
    }
  }
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceiveExercisesResponseSaga
    )
  ])
}

export function* rootSaga() {
  yield all([
    watchGetAllExercisesSaga(),
    watchReceiverSaga(),
    watchGetTemaTocSaga()
  ])
}
