import * as React from "react"

import useAudio from "./useAudio"
import { ReactComponent as Play } from "../../assets/images/play.svg"
import { ReactComponent as Stop } from "../../assets/images/stop.svg"
import { Wrapper, Button } from "./StyledAudioPlayer"
import { getTime } from "../../shared/tools/misc"
import { Typography } from "@mui/material"

export interface Props {
  src: string
  label: string
  className?: string
}

const AudioPlayer: React.FC<Props> = ({
  src,
  className,
  label
}): JSX.Element => {
  const [srcError, setSrcError] = React.useState(false)
  const [{ duration, currentTime, playing }, toggle, setPlayer] = useAudio(src)

  return (
    <>
      {!srcError ? (
        <Wrapper
          playing={playing}
          className={className}
          aria-label={label}
          onError={() => setSrcError(true)}
        >
          <Button
            onClick={toggle}
            aria-label={playing ? "Stoppa" : "Spela"}
            aria-pressed={playing ? "true" : "false"}
          >
            {playing ? <Stop /> : <Play />}
          </Button>
          <span aria-hidden>
            {playing
              ? `${getTime(currentTime)} / ${getTime(duration)}`
              : getTime(duration)}
          </span>
          <audio ref={setPlayer} />
        </Wrapper>
      ) : (
        <Typography sx={{ fontStyle: "italic" }}>
          Ljudfilen har tagits bort
        </Typography>
      )}
    </>
  )
}

export default AudioPlayer
