import Button from "../../components/Button"
import {
  Paper,
  PaperProps,
  Grid,
  Typography,
  TableCell,
  TableRow,
  TableRowProps,
  styled
} from "@mui/material"

export const StyledButton = styled(Button)`
  margin-right: 0.5em;
`
export const StyledButtonContainer = styled("div")`
  display: flex;
  margin-top: 2em;
  margin-bottom: 4em;
`

export const StyledPaper = styled(Paper as React.FC<PaperProps>)`
  margin-bottom: 1.5em;
`

export const StyledPaperContent = styled("div")`
  width: 100%;
`

export const StyledHeaderButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledTitleContainer = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledTitle = styled(Typography)`
  font-size: 25px;
  font-weight: 350;
  margin-right: 1em;
`

export const StyledContentTitle = styled("h2")`
  font-size: 16px;
`

export const StyledHeaderButtonsLeft = styled("div")`
  display: flex;
  align-items: center;
`
export const StyledButtonWrapper = styled("div")`
  margin-right: 10px;
`
export const StyledGridContainer = styled(Grid)`
  margin: 1em 0;
`
export const StyledParagraph = styled("p")``
export const StyledStudentsHeader = styled("div")`
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledAvatarCell = styled(TableCell)`
  width: 2em;
`

export const StyledNameCell = styled(TableCell)`
  align-items: center;
`

export const StyledName = styled(Typography)`
  margin-right: 1rem;
`

export const StyledProgressGrid = styled(Grid)`
  display: flex;
  align-items: center;
`
export const StyledSticker = styled(
  Paper as React.FC<PaperProps> & { color?: string }
)`
  display: flex;
  justify-content: center;
  width: 9em;
  height: 10em;
  padding: 0.5em;
  margin-right: 1em;
  ${props =>
    props.color &&
    `
    border-radius: 50%;
    padding: 10px;
    width: 100px;
    height: 100px;
    background-color: ${props.color};
    margin-top: 1em;
    > img {
      width:100%;
      height:100%;
    }
  `}
`

export const StyledTableRow = styled(
  TableRow as React.FC<TableRowProps & { has_on_click: number }>
)`
  cursor: ${props => (props.has_on_click ? "pointer" : "normal")};
`

export const StyledCurrentSticker = styled("img")`
  max-width: 100%;
  max-height: 100%;
`
