import * as React from "react"
import { connect, useSelector } from "react-redux"

import * as authActions from "../../modules/auth/actions"
import * as routerActions from "../../modules/router/actions"

import * as qs from "query-string"
import { RootState } from "../../modules/store"
import {
  AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_KEY,
  AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_PAYLOAD_KEY,
  AUTH_LOCALSTORAGE_ORIGINAL_PATH_KEY,
  AUTH_URL
} from "../../modules/auth/constants"
import { selectJwt } from "../../modules/auth/selectors"
import { selectActiveProduct } from "../../modules/products/selectors"

type OwnProps = {
  children: React.ReactChild
}

type StateProps = {
  jwt: string
  currentRoute: string
  routePayload: object
  currentPath: string
}

type DispatchProps = {
  requestAuth: typeof authActions.requestAuth
  gotoRoute: (route: string, payload: object) => void
}

type Props = OwnProps & StateProps & DispatchProps

const authUrl = process.env.REACT_APP_AUTH_URL || AUTH_URL
const redirectUri = `${window.location.protocol}//${window.location.host}/`
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || "products"
const scope = "openid%20offline_access"
const tokenUrl = process.env.REACT_APP_AUTH_TOKEN_URL || "/token"

const AuthComponent = ({
  jwt,
  currentRoute,
  routePayload,
  currentPath,
  children,
  gotoRoute,
  requestAuth
}: Props) => {
  const activeProduct = useSelector(selectActiveProduct)
  if (activeProduct) {
    document.title = `Klassrum ${activeProduct.niceName || activeProduct.title}`
  }

  const authenticate = () => {
    const queryParams = qs.parse(window.location.search)

    // Request token if auth code is present in querystring
    if (queryParams && queryParams.code && queryParams.state) {
      requestAuth({
        tokenUrl,
        redirectUri,
        code: queryParams.code.toString(),
        state: queryParams.state.toString()
      })

      // Get original URL and set it now when auth is done
      const { originalRoute, originalRoutePayload, originalPath } =
        getOriginalUrlFromLocalStorage()

      try {
        window.history.replaceState(null, "", originalPath)
        gotoRoute(originalRoute, JSON.parse(originalRoutePayload))
      } catch (e) {
        e.message += ` originalRoute: ${originalRoute}, originalRoutePayload: ${originalRoutePayload}`

        throw e
      }
    } else if (!jwt) {
      // Store original URL for redirect after auth is done
      setOriginalUrlToLocalStorage(currentRoute, routePayload, currentPath)
      // Redirect to authorize url
      window.location.href = getLoginUrl()
    }
  }

  /**
   * WHEN:
   *  Component did mount, done only once (compare to componentDidMount())
   * DO (the effect):
   *  Authenticate
   */
  // eslint-disable-next-line
  React.useEffect(authenticate, [])

  const getLoginUrl = (): string => {
    const state = generateState()

    return `${authUrl}?state=${state}&scope=${scope}&response_type=code&approval_prompt=auto&redirect_uri=${redirectUri}&client_id=${clientId}`
  }

  const generateState = () => {
    let state = ""
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (let i = 0; i < 30; i++) {
      state += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return state
  }

  return <React.Fragment>{children}</React.Fragment>
}

const getOriginalUrlFromLocalStorage = () => {
  const originalRoute =
    window.localStorage.getItem(AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_KEY) || "/"
  const originalRoutePayload =
    window.localStorage.getItem(AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_PAYLOAD_KEY) ||
    "/"
  const originalPath =
    window.localStorage.getItem(AUTH_LOCALSTORAGE_ORIGINAL_PATH_KEY) || "/"

  window.localStorage.removeItem(AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_KEY)
  window.localStorage.removeItem(AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_PAYLOAD_KEY)
  window.localStorage.removeItem(AUTH_LOCALSTORAGE_ORIGINAL_PATH_KEY)

  return { originalRoute, originalRoutePayload, originalPath }
}

const setOriginalUrlToLocalStorage = (
  currentRoute: string,
  routePayload: object,
  currentPath: string
) => {
  window.localStorage.setItem(
    AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_KEY,
    currentRoute
  )
  window.localStorage.setItem(
    AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_PAYLOAD_KEY,
    JSON.stringify(routePayload)
  )
  window.localStorage.setItem(AUTH_LOCALSTORAGE_ORIGINAL_PATH_KEY, currentPath)
}

const mapStateToProps = (state: RootState): StateProps => ({
  jwt: selectJwt(state),
  currentRoute: state.location.type,
  routePayload: state.location.payload,
  currentPath: state.location.pathname
})

const mapDispatchToProps: DispatchProps = {
  requestAuth: authActions.requestAuth,
  gotoRoute: routerActions.gotoRoute
}

export const Auth = connect(mapStateToProps, mapDispatchToProps)(AuthComponent)
