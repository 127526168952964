import { styled } from "@mui/material"
import RLink from "redux-first-router-link"
import { ReactComponent as backgroundSVG2 } from "../../assets/images/card-background-darker.svg"

export const StyledNavigationButton = styled(RLink)`
  text-decoration: none;
`
export const StyledStartContent = styled("div")`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5rem;
`
export const StyledTomoyoBackgroundSvg = styled(backgroundSVG2)`
  opacity: 20%;
`
export const StyledButtonWrapper = styled("div")`
  flex-direction: column;
  align-items: start;
`
