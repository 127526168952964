import React from "react"
import { useSelector } from "react-redux"
import {
  DialogTitle,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox
} from "@mui/material"
import {
  StyledNameTableCell,
  StyledRecorderContainer,
  StyledDialogContent,
  StyledInstructions,
  StyledCheckboxCell,
  StyledInstructionsLabel,
  StyledSelectStudentsTableRow,
  StyledNameContainer,
  StyledErrorMessage
} from "./StyledFormativeQuestions"
import Button from "../../components/Button"
import { Members } from "../../modules/members/types"
import FormikTextEditor from "../../components/FormikTextEditor"
import AudioRecorder from "../../components/AudioRecorder"
import { buildName } from "../../shared/tools/strings"
import Avatar from "../../components/Avatar"
import { Formik, FormikProps, FormikValues, ErrorMessage, Form } from "formik"
import * as Yup from "yup"
import FormativeResultProgress from "../../components/FormativeResultProgress"
import { getProgressForBar } from "../FormativeQuestionsStudentResult/helpers"
import {
  FormativeSession,
  FormativeTest,
  ParsedFormativeProgressionAnswers
} from "../../modules/formative/types"
import { selectAllProgressionAnswers } from "../../modules/formative/selectors"

export type RedoFormValues = {
  instruction: { text: string; audio: string }
  members: number[]
  saveType: "startNow" | "save"
  id: number
}

type Props = {
  testName: string
  testId: number
  onCancel: () => void
  onRedoTest: (values: RedoFormValues) => void
  students: Members
  session: FormativeSession
  test: FormativeTest
  numberOfSessions: number
}

const NOOP = () => {}

const RedoTestDialog = ({
  testName,
  testId,
  onCancel,
  onRedoTest,
  students,
  test,
  session,

  numberOfSessions
}: Props) => {
  const getInitialValues = () => {
    return {
      instruction: { text: "", audio: "" },
      members: students.map(student => student.studliId),
      saveType: "save",
      id: testId
    } as RedoFormValues
  }

  return (
    <>
      <DialogTitle id="redo-dialog-title">Gör om {testName}</DialogTitle>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={Yup.object({
          instruction: Yup.mixed().test(
            "instruction",
            "Det saknas en beskrivning. Skriv och/eller spela in frågan.",
            function (value) {
              const { path, createError } = this

              return testForAudioOrText(path, createError, value)
            }
          ),
          members: Yup.array().required("Testet måste ha elever"),
          id: Yup.number(),
          saveType: Yup.string()
        })}
        validateOnBlur={true}
        onSubmit={onRedoTest}
      >
        {formik => (
          <RedoTestForm
            onCancel={onCancel}
            {...formik}
            students={students}
            test={test}
            session={session}
            numberOfSessions={numberOfSessions}
          />
        )}
      </Formik>
    </>
  )
}

export default RedoTestDialog

type FormProps = {
  numberOfSessions: number
  students: Members
  test: FormativeTest
  session: FormativeSession
  onCancel: () => void
}
type RedoFormProps = FormProps & FormikProps<FormikValues>

const RedoTestForm = ({
  numberOfSessions,
  students,
  values,
  setFieldValue,
  onCancel,
  test,
  session,
  submitForm
}: RedoFormProps) => {
  const answers = useSelector(selectAllProgressionAnswers)

  const onTextEditorBlur = (name: string, value: any) => {
    setFieldValue("instruction", { ...values.instruction, text: value })
  }

  const setSubmitTypeAndSubmit = (type: "save" | "startNow") => {
    setFieldValue("saveType", type, false)
    submitForm()
  }

  const toggleChecked = (studentId: number, isChecked: boolean) => {
    if (isChecked) {
      setFieldValue(
        "members",
        values.members.filter((checked: number) => checked !== studentId)
      )
      return
    }
    setFieldValue("members", [...values.members, studentId])
  }

  const toggleAllChecked = () => {
    if (values.members.length === students.length) {
      setFieldValue("members", [])
      return
    }
    setFieldValue(
      "members",
      students.map(student => student.studliId)
    )
  }

  const setAudioForForm = async (
    filePath: string | undefined,
    name: string
  ) => {
    if (filePath) {
      setFieldValue("instruction", { ...values.instruction, audio: filePath })

      return
    }
    setFieldValue("instruction", { ...values.instruction, audio: "" })
  }

  return (
    <Form>
      <StyledDialogContent id="redo-dialog-description">
        <StyledInstructions>
          <StyledInstructionsLabel>
            Beskrivning - testtillfälle {numberOfSessions + 1}
          </StyledInstructionsLabel>
          <FormikTextEditor
            name="instruction"
            value={values.instruction.text}
            placeholder="Beskriv testet för eleverna"
            onBlurHandler={onTextEditorBlur}
            aria-label="Beskrivning"
          />
          <StyledRecorderContainer>
            <AudioRecorder
              type="standalone"
              formType="instruction"
              setAudioForForm={setAudioForForm}
              editAudio={values.instruction.audio}
              conversation={false}
            />
          </StyledRecorderContainer>
          <StyledErrorMessage>
            <ErrorMessage name="instruction" />
          </StyledErrorMessage>
          <StyledErrorMessage>
            <ErrorMessage name="members" />
          </StyledErrorMessage>
        </StyledInstructions>
        <Table>
          <TableHead>
            <TableRow>
              <StyledCheckboxCell>
                <Checkbox
                  aria-label={
                    values.members.length === students.length
                      ? "Avmarkera alla"
                      : "Markera alla"
                  }
                  checked={values.members.length === students.length}
                  onChange={toggleAllChecked}
                />
              </StyledCheckboxCell>
              <TableCell>Namn</TableCell>
              <TableCell>Resultat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map(student => {
              const isChecked = values.members.some(
                (checked: number) => checked === student.studliId
              )
              const answersForStudent = answers.filter(
                answer => answer.memberId === student.studliId
              )
              const progressForBar = getProgressForBar(
                session.id,
                test,
                answersForStudent as unknown as ParsedFormativeProgressionAnswers
              )
              return (
                <StyledSelectStudentsTableRow
                  key={student.studliId}
                  is_checked={isChecked ? 1 : 0}
                >
                  <StyledCheckboxCell>
                    <Checkbox
                      aria-label={isChecked ? "Avmarkera" : "Markera"}
                      onChange={() =>
                        toggleChecked(student.studliId, isChecked)
                      }
                      checked={isChecked}
                    />
                  </StyledCheckboxCell>
                  <StyledNameTableCell>
                    <Avatar
                      firstName={student.firstName}
                      lastName={student.lastName}
                      picture={student.picture}
                      studliId={student.studliId}
                    />
                    <StyledNameContainer>
                      {buildName(student.firstName, student.lastName)}
                    </StyledNameContainer>
                  </StyledNameTableCell>
                  <TableCell>
                    <FormativeResultProgress
                      wrong={progressForBar.wrong}
                      correct={progressForBar.correct}
                      notAnswered={progressForBar.notAnswered}
                      rating={progressForBar.rating}
                      current
                      endTime=""
                      onOpenProgressDialog={NOOP}
                      type="multiple"
                      displayType="student"
                    />
                  </TableCell>
                </StyledSelectStudentsTableRow>
              )
            })}
          </TableBody>
        </Table>
      </StyledDialogContent>
      <DialogActions>
        <Button aria-label="Avbryt" onClick={onCancel}>
          Avbryt
        </Button>
        <Button
          aria-label="Starta nu"
          onClick={() => setSubmitTypeAndSubmit("startNow")}
          color="primary"
        >
          Starta nu
        </Button>
        <Button
          aria-label="Spara"
          onClick={() => setSubmitTypeAndSubmit("save")}
          color="primary"
        >
          Spara
        </Button>
      </DialogActions>
    </Form>
  )
}
const testForAudioOrText = (
  path: string,
  createError: Function,
  value: any
) => {
  if ((value.text && value.text.length > 7) || value.audio) {
    return true
  }

  return createError({
    path,
    message:
      "Det saknas en beskrivning. Skriv och/eller spela in en beskrivning."
  })
}
