import React, { useCallback, useEffect, useMemo } from "react"
import {
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Skeleton,
  Stack
} from "@mui/material"
import {
  Assignment,
  AssignmentWithStatusAndProgression,
  AssignmentsWithStatusAndProgression,
  AssignmentMenuOptions
} from "../../modules/assignments/types"
import TableToolbar from "../TableToolbar"
import StyledList from "../StyledList"
import AssignmentsListItem from "../AssignmentsListItem"
import { getMenuOptions } from "../../pages/Assignments/common/helpers"
import { Stickers, Features } from "../../modules/classroom/types"
import { useSelector } from "react-redux"
import { selectLoadingImportAssignment } from "../../modules/assignments/selectors"
import { FilterValue } from "../TableFilter/types"
import { getStatusString } from "../AssignmentsTable/helpers"
import {
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import TableFilter from "../TableFilter/TableFilter"
import { buildName } from "../../shared/tools/strings"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import { tableFilter } from "../../shared/tools/filter"
import { Members } from "../../modules/members/types"

type Props = {
  assignments: AssignmentsWithStatusAndProgression
  onCheckboxClick: (
    event: any,
    assignment: AssignmentWithStatusAndProgression
  ) => void
  checkedAssignments: AssignmentsWithStatusAndProgression
  toggleAllAssignments: (
    event: React.ChangeEvent<HTMLInputElement>,
    status: Assignment["status"]
  ) => void
  tableTitle?: string
  onMenuClick: (type: AssignmentMenuOptions, id: Assignment["id"]) => void
  onAssignmentClick: (assignmentId: Assignment["id"]) => void
  onProgressClick: (assignment: AssignmentWithStatusAndProgression) => void
  type: "start" | "assignmentsList" | "studentCard"
  stickers: Stickers
  classroomFeatures: Features
  assignmentsThatNeedManualModification?: number[]
  members: Members
  showSkeleton?: boolean
  showFilter?: boolean
}

const AssignmentsList = ({
  assignments,
  checkedAssignments,
  onCheckboxClick,
  toggleAllAssignments,
  tableTitle,
  onMenuClick,
  onAssignmentClick,
  onProgressClick,
  type,
  stickers,
  classroomFeatures,
  showSkeleton,
  showFilter,
  members,
  assignmentsThatNeedManualModification = []
}: Props) => {
  const user = useSelector(selectAuthenticatedUser)
  const menuItemClick = (
    type: AssignmentMenuOptions,
    assignmentId: AssignmentWithStatusAndProgression["id"]
  ) => {
    onMenuClick(type, assignmentId)
  }
  const [sortedAssignments, setSortedAssignments] = React.useState(assignments)
  const [currentFilters, setCurrentFilters] = React.useState<FilterValue[]>([])

  const isLoadingImport = useSelector(selectLoadingImportAssignment)

  const getAssignmentStatusFilterOptions = () => {
    return [
      ...Array.from(
        new Set(
          assignments.map(assignment => assignment.calculatedStatus as string)
        )
      )
    ].map((status: string) => ({
      label: getStatusString(status as any),
      val: status,
      key: "status",
      filterFunction: (a: AssignmentWithStatusAndProgression) => {
        return a.calculatedStatus === status
      }
    }))
  }

  const assignmentsCreatedBy = useMemo(() => {
    return [
      ...Array.from(
        new Set(
          assignments.map(a => ({
            createdBy: a.createdBy,
            createdByFirstName: a.createdByFirstName,
            createdByLastName: a.createdByLastName,
            createdByPictureUrl: a.createdByPictureUrl
          }))
        )
      ).filter(
        (createdBy, index, self) =>
          index === self.findIndex(c => c.createdBy === createdBy.createdBy)
      )
    ]
  }, [assignments])

  const groups = useSelector(selectAllGroups)
  const activeGroups = useSelector(selectAllActiveGroups)

  const getAssignmentGroups = useCallback(
    (a: Assignment) => {
      const assignmentMembers = members.filter(m =>
        a.participants.includes(m.studliId)
      )
      const groupIds = Array.from(
        new Set(assignmentMembers.map(m => m.groups).flat())
      )
      return groups.filter(g => groupIds.includes(g.id))
    },
    [members, groups]
  )

  const initialGroupFilter = useMemo(() => {
    if (groups.length !== activeGroups.length) {
      return activeGroups.map(group => ({
        label: group.name,
        val: group.id,
        key: "groups",
        filterFunction: (a: Assignment) =>
          getAssignmentGroups(a).some(g => g.id === group.id)
      }))
    }
  }, [groups, activeGroups, getAssignmentGroups])

  const getFilters = () => {
    return [
      {
        label: "Grupper",
        key: "groups",
        options: groups.map(group => ({
          label: group.name,
          val: group.id,
          key: "groups",
          filterFunction: (a: Assignment) =>
            getAssignmentGroups(a).some(g => g.id === group.id)
        }))
      },
      {
        label: "Status",
        key: "status",
        options: getAssignmentStatusFilterOptions()
      },
      {
        label: "Skapat av",
        key: "createdBy",
        options: assignmentsCreatedBy.map(createdBy => ({
          label: buildName(
            createdBy.createdByFirstName,
            createdBy.createdByLastName
          ),
          val: createdBy,
          key: "createdBy",
          filterFunction: (a: Assignment) => a.createdBy === createdBy.createdBy
        }))
      }
    ]
  }

  const onFilter = useCallback(
    (filterValues: FilterValue[]) => {
      return tableFilter(filterValues, assignments)
    },
    [assignments]
  )

  useEffect(() => {
    const filteredAssignments = onFilter(currentFilters)
    setSortedAssignments(filteredAssignments)
  }, [assignments, currentFilters, onFilter])

  const setFilters = (filterValues: FilterValue[]) => {
    setCurrentFilters(filterValues)
  }

  const searchFunction = (a: Assignment, searchText: string) =>
    a.title?.toLowerCase().includes(searchText.toLowerCase())

  return (
    <>
      {type !== "studentCard" && (
        <TableToolbar
          title={tableTitle as string}
          selectedTitle="uppdrag valda"
          numSelected={checkedAssignments.length}
          actions={[]}
          totalItems={sortedAssignments.length}
          filterComponent={
            showFilter ? (
              <TableFilter
                filters={getFilters()}
                onFilter={setFilters}
                searchFunction={searchFunction}
                searchLabel="Sök titel"
                results={sortedAssignments.length}
                initialFilters={initialGroupFilter}
              />
            ) : null
          }
        />
      )}
      <StyledList>
        {showSkeleton &&
          isLoadingImport !== 0 &&
          Array.from(Array(isLoadingImport)).map((_, i) => (
            <LoadingSkeleton key={i} />
          ))}
        {sortedAssignments.map((assignment, index) => {
          return (
            <React.Fragment key={assignment.id}>
              <AssignmentsListItem
                onCheckboxClick={onCheckboxClick}
                checkedAssignments={checkedAssignments}
                key={assignment.id}
                type={type}
                assignment={assignment}
                onAssignmentClick={onAssignmentClick}
                onProgressClick={onProgressClick}
                stickers={stickers}
                moreMenuOptions={getMenuOptions(
                  menuItemClick,
                  assignment.id,
                  assignment.calculatedStatus,
                  assignment.isPublic,
                  assignment.createdBy === user?.studliId
                )}
                needManualModification={
                  assignmentsThatNeedManualModification.indexOf(assignment.id) >
                  -1
                }
              />
              {assignments.length - 1 !== index && <Divider light />}
            </React.Fragment>
          )
        })}
      </StyledList>
      {(type === "start" || type === "studentCard") && !assignments.length && (
        <>
          <Divider />
          <p>Det finns inga aktiva uppdrag i klassrummet</p>
          <Divider />
        </>
      )}
    </>
  )
}

const LoadingSkeleton = () => (
  <>
    <ListItem>
      <ListItemButton sx={{ height: "97px" }}>
        <ListItemAvatar>
          <Skeleton variant="circular" height="40px" width="40px" />
        </ListItemAvatar>
        <Stack sx={{ width: "80%" }}>
          <Skeleton variant="text" height="1.5rem" width="4rem" />
          <Skeleton variant="text" height="2.5rem" width="100%" />
        </Stack>
      </ListItemButton>
    </ListItem>
    <Divider />
  </>
)

export default AssignmentsList
