import { FormativeQuestion } from "../../modules/formative/types"

export const isQuestion = (
  node: FormativeQuestion | string
): node is FormativeQuestion => {
  if (typeof node === "string") {
    return false
  }

  return true
}
