import React, { useState, useEffect } from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import { RootState } from "../../modules/store"
import * as assignmentsSelectors from "../../modules/assignments/selectors"
import * as routerSelectors from "../../modules/router/selectors"
import { Divider, Typography, List, ListItemIcon } from "@mui/material"
import { ReactComponent as ApprovedIcon } from "../../assets/images/task-check.svg"
import { ReactComponent as RejectedIcon } from "../../assets/images/task-returned.svg"
import {
  StyledPaper,
  StyledAssignmentsHeaderWrapper,
  StyledContentWrapper,
  StyledStudentInfo,
  StyledStudentInfoElementWrapper,
  StyledIconContainer,
  StyledStatusTagWrapper,
  StyledTaskContent,
  StyledListItem,
  StyledCurrentSticker,
  StyledStatusTag,
  StyledListColumnWrapper,
  StyledTitle,
  StyledTaskTitle,
  StyledApproveDeclineWrapper,
  StyledButtonWrapper,
  Wrapper,
  StyledFeedbackTitle,
  FeedbackWrapper,
  StyledProgressHistoryMessage,
  StyledErrorMsg,
  StyledAdaptStudentInfo
} from "./StyledAssignmentStudentCard"
import StyledButton from "../../components/Button"
import {
  Assignments,
  Assignment,
  AssignmentWithStatus,
  AssignmentTask,
  ApproveOrDeclineAssignmentAction
} from "../../modules/assignments/types"
import Avatar from "../../components/Avatar"
import * as membersSelectors from "../../modules/members/selectors"
import { Members, Member } from "../../modules/members/types"
import { buildName } from "../../shared/tools/strings"
import { calculateStatus } from "../Assignments/common/helpers"
import * as routerActions from "../../modules/router/actions"
import { ReactComponent as OpenQuestionIcon } from "../../assets/images/question-mark.svg"
import { ReactComponent as FromBookIcon } from "../../assets/images/page-flip.svg"
import { Create } from "@mui/icons-material"
import { Sticker, Stickers, Features } from "../../modules/classroom/types"
import * as classroomSelectors from "../../modules/classroom/selectors"
import moment from "moment"
import {
  AssignmentsTaskProgress,
  AssignmentTaskProgress,
  AssignmentsProgress,
  AssignmentProgress
} from "../../modules/assignmentsProgress/types"
import {
  selectAllAssignmentsTaskProgress,
  selectAllAssignmentsProgress
} from "../../modules/assignmentsProgress/selectors"
import {
  selectAllExercisesProgress,
  selectAllExerciseStates
} from "../../modules/exercisesProgress/selectors"
import {
  ExercisesProgress,
  ExerciseProgress
} from "../../modules/exercisesProgress/types"
import {
  approveOrDeclineAssignment,
  getAssignment
} from "../../modules/assignments/actions"
import ProgressHistory from "../../components/ProgressHistory"
import ProgressHistoryTeacherAction from "../../components/ProgressHistoryTeacherAction"
import HtmlRenderer from "../../components/HtmlRenderer"
import FeedbackMessages from "../../containers/FeedbackMessages"
import AssignmentStatusTags from "../../components/AssignmentStatusAndApprovedTags"
import Pagination from "./Pagination"
import * as assignmentStudentCardActions from "./store/actions"
import ExerciseTypeIcon from "../../components/ExerciseTypeIcon"
import ExerciseResultWithHistory from "../../components/ExerciseResultWithHistory"
import OpenQuestion from "./OpenQuestion"
import { ExerciseProgressWithHistory } from "../../components/ExerciseResultWithHistory/ExerciseResultWithHistory"
import AdaptStudentCard from "./AdaptStudentCard"
import AdaptAssignmentProgressBar from "../../components/AdaptAssignmentProgressBar"
import AdaptAssignmentStatusTag from "../../components/AssignmentStatusAndApprovedTags/AdaptAssignmentStatusTag"

const ICONS = {
  "open-question": <OpenQuestionIcon aria-label="Öppen fråga" />,
  exercise: <Create aria-label="Övning" />,
  book: <FromBookIcon aria-label="Från boken" />,
  link: <FromBookIcon aria-label="Länk" />,
  goal: <div />
}

interface AssignmentTaskWithProgress extends AssignmentTask {
  exerciseProgress: ExerciseProgress
  taskProgress: AssignmentTaskProgress
}

type StateProps = {
  currentAssignmentId: number
  classroomId: number
  assignments: Assignments
  currentMemberId: number
  students: Members
  assignmentsProgress: AssignmentsProgress
  assignmentsTaskProgress: AssignmentsTaskProgress
  stickers: Stickers
  classroomFeatures: Features
  exercisesProgress: ExercisesProgress
}

type DispatchProps = {
  goToRoute: (type: string, payload: object) => void
  approveOrDecline: (
    payload: ApproveOrDeclineAssignmentAction["payload"]
  ) => ApproveOrDeclineAssignmentAction
  setHasSentFeedback: (hasSentFeedback: boolean) => void
  setHasSentBackAssignment: (hasSentBackAssignment: boolean) => void
}

type Props = StateProps & DispatchProps

const AssignmentStudentCard = ({
  currentAssignmentId,
  classroomId,
  assignments,
  currentMemberId,
  students,
  goToRoute,
  assignmentsTaskProgress,
  assignmentsProgress,
  stickers,
  exercisesProgress,
  approveOrDecline,
  classroomFeatures,
  setHasSentFeedback,
  setHasSentBackAssignment
}: Props) => {
  const [currentAssignment, setCurrentAssignment] =
    useState<AssignmentWithStatus | null>(null)
  const [currentMember, setCurrentMember] = useState<Member | null>(null)
  const [progressForAssignment, setProgressForAssignment] =
    useState<AssignmentsProgress | null>(null)
  const [nextStudentId, setNextStudentId] = useState<number | null>(null)
  const [prevStudentId, setPrevStudentId] = useState<number | null>(null)
  const [currentMemberIndex, setCurrentMemberIndex] = useState<number>(0)
  const [currentSticker, setCurrentSticker] = useState<Sticker | null>(null)
  const [showApproveRejectMessage, setShowApproveRejectMessage] =
    useState<boolean>(false)
  const classroomType = useSelector(classroomSelectors.selectTypeOfClassroom)
  const [
    assignmentTasksWithExerciseAndTaskProgress,
    setAssignmentTasksWithExerciseAndTaskProgress
  ] = useState<AssignmentTaskWithProgress[] | null>(null)
  const [, updateTimers] = useState()
  const dispatch = useDispatch()
  const exerciseProgressHistory = useSelector(selectAllExerciseStates)
  useEffect(() => {
    const interval = setInterval(() => {
      updateTimers(undefined)
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!assignments.some(a => a.id === currentAssignmentId)) {
      dispatch(getAssignment(currentAssignmentId))
    }
  }, [currentAssignmentId, assignments, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setHasSentFeedback(false)
    setHasSentBackAssignment(false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const foundMember = students.filter(
      (student: Member) => student.studliId === currentMemberId
    )
    setCurrentMember(foundMember[0] || null)

    const currAssignment = getCurrentAssignment(assignments)
    const assignmentWithStatus = addStatusToAssignment(
      currAssignment,
      classroomType === "adapt"
    )
    setCurrentAssignment(assignmentWithStatus)
    const progress = assignmentsProgress.filter(
      (assignmentProgress: AssignmentProgress) =>
        assignmentProgress.assignmentId === currentAssignmentId &&
        assignmentProgress.studliId === currentMemberId
    )
    setProgressForAssignment(progress.reverse())
    addProgressToAssignmentTasks(
      assignmentWithStatus,
      foundMember[0],
      exercisesProgress,
      assignmentsTaskProgress
    )

    if (currAssignment && stickers) {
      const currentSticker = stickers.find(
        (sticker: Sticker) => currAssignment.stickerId === sticker.id
      )
      setCurrentSticker(currentSticker || null)
    }

    if (currAssignment) {
      findAndSetNextAndPrevStudents(currAssignment)
    }
    // eslint-disable-next-line
  }, [
    assignments,
    stickers,
    currentMemberId,
    exercisesProgress,
    assignmentsTaskProgress,
    assignmentsProgress,
    students,
    classroomType
  ])

  moment.locale("sv")

  const getCurrentAssignment = (assignments: Assignments) => {
    const foundAssignment = assignments.filter(
      (assignment: Assignment) => assignment.id === currentAssignmentId
    )

    return foundAssignment[0] || null
  }

  const getExerciseStatusText = (status: string) => {
    switch (status) {
      case "notStarted":
        return "Ej påbörjat"
      case "inProgress":
        return "Påbörjat"
      case "finished":
        return "Klar"
      default:
        return "Ej påbörjat"
    }
  }

  const addProgressToAssignmentTasks = (
    currentAssignment: AssignmentWithStatus | null,
    currentMember: Member | null,
    exercisesProgress: ExercisesProgress,
    assignmentsTaskProgress: AssignmentsTaskProgress
  ) => {
    if (
      currentAssignment &&
      exercisesProgress &&
      assignmentsTaskProgress &&
      currentMember
    ) {
      const currentAssignmentTasks = currentAssignment.assignmentTasks
      const tasks = currentAssignmentTasks.map((task: AssignmentTask) => {
        let exerciseProgressWithHistory: any = {}
        if (task.type === "exercise") {
          const exeProgress = exercisesProgress.filter(
            (exerciseProgress: ExerciseProgress) =>
              exerciseProgress.exerciseId === task.link &&
              exerciseProgress.studliId === currentMember.studliId
          )
          exerciseProgressWithHistory = {
            ...exeProgress[0],
            moreExercises: null
          }
          if (exerciseProgressWithHistory) {
            const progressHistory = exerciseProgressHistory.filter(
              progress =>
                progress.studliId === exerciseProgressWithHistory.studliId &&
                progress.exerciseId ===
                  exerciseProgressWithHistory.exerciseId &&
                progress.status === "finished"
            )
            exerciseProgressWithHistory = {
              ...exerciseProgressWithHistory,
              moreExercises: progressHistory.length > 1 ? progressHistory : null
            }
          }
        }
        const taskProgressForTask = assignmentsTaskProgress.filter(
          (taskProgress: AssignmentTaskProgress) =>
            taskProgress.assignmentId === currentAssignmentId &&
            taskProgress.studliId === currentMemberId &&
            taskProgress.assignmentTaskId === task.id
        )

        return {
          ...task,
          exerciseProgress: exerciseProgressWithHistory,
          taskProgress: taskProgressForTask[0] || {}
        }
      })
      setAssignmentTasksWithExerciseAndTaskProgress(tasks)
    }
  }

  /**
   * Find next and previous member in current assignment member list
   * @param assignment current assignment
   */
  const findAndSetNextAndPrevStudents = (assignment: Assignment) => {
    const sortedMembers = assignment.participants
      .reduce((res: Members, currentMember: number) => {
        const memberIndex: number = students.findIndex(
          student => student.studliId === currentMember
        )
        if (memberIndex !== -1) {
          res.push(students[memberIndex])
        }

        return res
      }, [])
      .sort((first, second) => first.lastName.localeCompare(second.lastName))
    const memberIndex = sortedMembers.findIndex(
      (student: Member) => student.studliId === currentMemberId
    )
    setCurrentMemberIndex(memberIndex)
    const memberLength = sortedMembers.length

    if (memberIndex >= 0) {
      const nextIndex =
        memberIndex + 1 < memberLength
          ? sortedMembers[memberIndex + 1].studliId
          : null
      setNextStudentId(nextIndex)
      const prevIndex =
        memberIndex - 1 >= 0 ? sortedMembers[memberIndex - 1].studliId : null

      setPrevStudentId(prevIndex)
    }
  }

  const prevStudent = () =>
    goToRoute(routerActions.ROUTE_UPPDRAG_STUDENT_INFO, {
      classroomId,
      assignmentId: currentAssignmentId,
      memberId: prevStudentId
    })
  const nextStudent = () =>
    goToRoute(routerActions.ROUTE_UPPDRAG_STUDENT_INFO, {
      classroomId,
      assignmentId: currentAssignmentId,
      memberId: nextStudentId
    })

  const addStatusToAssignment = (assignment: Assignment, isAdapt: boolean) => {
    if (assignment) {
      const calculatedStatus = calculateStatus(
        assignment.status,
        assignment.startDate,
        assignment.endDate,
        isAdapt
      )

      return {
        ...assignment,
        calculatedStatus
      }
    }

    return null
  }

  const isDone = () =>
    assignmentsProgress.find(
      progress =>
        progress.assignmentId === currentAssignmentId &&
        progress.studliId === currentMemberId
    )

  const renderStudentInfo = () => {
    return (
      <StyledStudentInfo>
        <StyledStudentInfoElementWrapper>
          {currentSticker && (
            <StyledCurrentSticker
              src={currentSticker.path}
              alt={currentSticker.name}
            />
          )}
        </StyledStudentInfoElementWrapper>
        <StyledStudentInfoElementWrapper>
          {currentMember && (
            <Avatar
              picture={currentMember.picture}
              firstName={currentMember.firstName}
              lastName={currentMember.lastName}
              studliId={currentMember.studliId}
            />
          )}
        </StyledStudentInfoElementWrapper>
        <StyledStudentInfoElementWrapper>
          {currentMember &&
            buildName(currentMember.firstName, currentMember.lastName)}
        </StyledStudentInfoElementWrapper>
        <StyledStudentInfoElementWrapper>
          {classroomType !== "adapt" ? (
            <AssignmentStatusTags
              progress={{ assignmentsTaskProgress, assignmentsProgress }}
              memberId={currentMemberId}
              assignmentId={currentAssignmentId}
            />
          ) : (
            <AdaptAssignmentStatusTag
              progress={progressForAssignment?.find(
                p => p.studliId === currentMemberId
              )}
              assignment={getCurrentAssignment(assignments)}
            />
          )}
        </StyledStudentInfoElementWrapper>
      </StyledStudentInfo>
    )
  }

  const renderHeader = () => {
    const progress = assignmentsProgress.find(
      p =>
        p.assignmentId === currentAssignmentId && p.studliId === currentMemberId
    )
    const total =
      (currentAssignment &&
        currentAssignment.participants.filter(
          p => students.findIndex(s => s.studliId === p) !== -1
        ).length) ||
      0
    return (
      <React.Fragment>
        <Pagination
          current={currentMemberIndex + 1}
          total={total}
          disableBack={!prevStudentId}
          disableForward={!nextStudentId}
          back={prevStudent}
          forward={nextStudent}
        />
        <StyledAssignmentsHeaderWrapper>
          {renderStudentInfo()}
        </StyledAssignmentsHeaderWrapper>
        {classroomType === "adapt" && currentAssignment && progress && (
          <>
            <StyledAdaptStudentInfo>
              <AdaptAssignmentProgressBar
                progress={progress ? progress.progress : 0}
                threshold={currentAssignment.threshold}
              />
              <StyledStudentInfoElementWrapper>
                Arbetat{" "}
                <b>
                  {progress.progress > currentAssignment.threshold
                    ? currentAssignment.threshold
                    : progress.progress}
                </b>{" "}
                av <b>{currentAssignment.threshold}</b> min{" "}
                {progress.progress > currentAssignment.threshold && (
                  <>
                    +<b> {progress.progress - currentAssignment.threshold} </b>{" "}
                    min extra
                  </>
                )}
              </StyledStudentInfoElementWrapper>
            </StyledAdaptStudentInfo>
            <Divider />
          </>
        )}
      </React.Fragment>
    )
  }

  const renderContent = () => {
    return (
      <StyledPaper>
        <StyledTitle>
          {currentAssignment &&
            `Innehåll (${currentAssignment.assignmentTasks.length})`}
        </StyledTitle>
        <List>
          {assignmentTasksWithExerciseAndTaskProgress &&
            assignmentTasksWithExerciseAndTaskProgress.map(
              (task: AssignmentTaskWithProgress) => {
                return (
                  <StyledListItem key={task.id}>
                    <Divider />
                    <StyledTaskContent>
                      <ListItemIcon>
                        {task.type === "exercise" ? (
                          <ExerciseTypeIcon
                            iconColor="#4f93d5"
                            type={task.icon || "exercise"}
                          />
                        ) : (
                          <StyledIconContainer iconColor="#4f93d5">
                            {ICONS[task.type]}
                          </StyledIconContainer>
                        )}
                      </ListItemIcon>
                      <StyledListColumnWrapper>
                        <StyledTaskTitle
                          primary={<HtmlRenderer html={task.title} />}
                          secondary={
                            task.type !== "open-question" ? (
                              <>
                                {task.exercisePath && `(${task.exercisePath})`}
                                {task.needsManualModification && (
                                  <StyledErrorMsg>
                                    Deluppgiften är borttagen ur läromedlet.
                                  </StyledErrorMsg>
                                )}
                              </>
                            ) : (
                              <OpenQuestion
                                title={task.title}
                                answer={task.taskProgress.answer}
                                exampleAnswer={task.answer}
                                question={task.question as string}
                              />
                            )
                          }
                        />
                        <ExerciseResultWithHistory
                          type="bestScore"
                          exercisesForHistory={
                            task.exerciseProgress as ExerciseProgressWithHistory
                          }
                        />
                      </StyledListColumnWrapper>
                    </StyledTaskContent>
                    <StyledStatusTagWrapper>
                      <StyledStatusTag status={getStatusForTask(task)}>
                        {getExerciseStatusText(getStatusForTask(task))}
                      </StyledStatusTag>
                    </StyledStatusTagWrapper>
                  </StyledListItem>
                )
              }
            )}
        </List>
      </StyledPaper>
    )
  }

  const setFeedbackSent = (isSent: boolean) => {
    if (!isAssignmentApprovedOrRejected()) {
      setHasSentFeedback(isSent)
    }
  }

  const getStatusForTask = (task: AssignmentTaskWithProgress) => {
    if (task.taskProgress.updatedAt) {
      return task.taskProgress.done ? "finished" : "inProgress"
    } else {
      return "notStarted"
    }
  }

  const renderCommunication = () => {
    return (
      <>
        <StyledFeedbackTitle variant="h2">
          Meddelande / feedback
        </StyledFeedbackTitle>
        <FeedbackMessages
          to={currentMember}
          assignmentId={currentAssignmentId}
          setHasSentFeedback={setFeedbackSent}
          isAssignmentDone={!!isDone()}
        />
      </>
    )
  }

  const approveOrDeclineAssignmentHandler = (approved: boolean) => {
    setHasSentBackAssignment(true)
    approveOrDecline({
      approved: approved,
      studliId: currentMemberId,
      assignmentId: currentAssignmentId
    })
    setShowApproveRejectMessage(true)
  }

  const isAssignmentApprovedOrRejected = () =>
    progressForAssignment &&
    progressForAssignment[0] &&
    (progressForAssignment[0].approvedAt || progressForAssignment[0].rejectedAt)

  const renderApproveAssignment = () => {
    return (
      <React.Fragment>
        <Divider />
        <StyledContentWrapper>
          <StyledTitle>{"Inlämnat uppdrag"}</StyledTitle>
          <StyledApproveDeclineWrapper>
            {!isAssignmentApprovedOrRejected() && (
              <Typography variant="subtitle1">Är uppdraget klart?</Typography>
            )}
            <StyledContentWrapper>
              {progressForAssignment && progressForAssignment[0] && (
                <StyledProgressHistoryMessage>
                  {progressForAssignment[0].approvedAt && <ApprovedIcon />}
                  {progressForAssignment[0].rejectedAt && <RejectedIcon />}
                  <ProgressHistoryTeacherAction
                    progress={progressForAssignment[0]}
                    withFeedbackMessage={true}
                    strong={true}
                  />
                </StyledProgressHistoryMessage>
              )}
            </StyledContentWrapper>
            {progressForAssignment && currentMember && (
              <ProgressHistory
                progressForAssignment={progressForAssignment}
                student={currentMember}
                showTeacherRespons={!showApproveRejectMessage}
              />
            )}
            {!isAssignmentApprovedOrRejected() && (
              <StyledButtonWrapper>
                <StyledButton
                  onClick={() => approveOrDeclineAssignmentHandler(true)}
                  variant="outlined"
                  color="primary"
                >
                  Ja, det är klart
                </StyledButton>
                <StyledButton
                  onClick={() => approveOrDeclineAssignmentHandler(false)}
                  variant="outlined"
                  color="primary"
                >
                  Nej, skicka tillbaka
                </StyledButton>
              </StyledButtonWrapper>
            )}
          </StyledApproveDeclineWrapper>
        </StyledContentWrapper>
      </React.Fragment>
    )
  }

  return (
    <Wrapper>
      {renderHeader()}
      {classroomType === "adapt" ? (
        <AdaptStudentCard />
      ) : (
        <>
          {isDone() && renderApproveAssignment()}

          <Divider />
          <StyledContentWrapper>{renderContent()}</StyledContentWrapper>
          <FeedbackWrapper>{renderCommunication()}</FeedbackWrapper>
        </>
      )}
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    assignments: assignmentsSelectors.selectAllAssignments(state),
    classroomId: routerSelectors.selectCurrentClassroomId(state),
    currentAssignmentId: routerSelectors.selectCurrentAssignmentId(state),
    currentMemberId: routerSelectors.selectCurrentMemberId(state),
    students: membersSelectors.selectStudents(state),
    assignmentsProgress: selectAllAssignmentsProgress(state),
    assignmentsTaskProgress: selectAllAssignmentsTaskProgress(state),
    stickers: classroomSelectors.selectClassroomStickers(state),
    classroomFeatures: classroomSelectors.selectClassroomFeatures(state),
    exercisesProgress: selectAllExercisesProgress(state)
  }
}

const mapDispatchToProps: DispatchProps = {
  goToRoute: routerActions.gotoRoute,
  approveOrDecline: approveOrDeclineAssignment,
  setHasSentFeedback: assignmentStudentCardActions.setHasSentFeedback,
  setHasSentBackAssignment:
    assignmentStudentCardActions.setHasSentBackAssignment
}

const ConnectedAssignmentStudentCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentStudentCard)

export default ConnectedAssignmentStudentCard
