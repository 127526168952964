import React, { useEffect } from "react"

import { LOGOUT_URL, PROFILE_URL } from "./constants"

import { StyledAvatarContainer } from "./StyledAuthUser"
import { GetAuthUserInfoRequestAction } from "../../modules/users/types"
import * as usersActions from "../../modules/users/actions"
import { RootState } from "../../modules/store"
import * as usersSelectors from "../../modules/users/selectors"
import { StateProps } from "./types"
import Avatar from "../../components/Avatar"
import { connect } from "react-redux"

import MoreMenu, { MenuItems } from "../../components/PopupMenu/PopupMenu"

type OwnProps = {
  color?: string
}

type DispatchProps = {
  getAuthUserInfo: () => GetAuthUserInfoRequestAction
}

type Props = OwnProps & StateProps & DispatchProps

const AuthUser = ({ authenticatedUser, color, getAuthUserInfo }: Props) => {
  useEffect(() => {
    !authenticatedUser && getAuthUserInfo()
  }, [authenticatedUser, getAuthUserInfo])

  const firstName = authenticatedUser ? authenticatedUser.firstName : ""
  const lastName = authenticatedUser ? authenticatedUser.lastName : ""
  const picture = authenticatedUser ? authenticatedUser.picture : ""

  const goToProfile = () => {
    const profileUrl = process.env.REACT_APP_PROFILE_URL || PROFILE_URL
    window.open(profileUrl)
  }

  const logout = () => {
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL || LOGOUT_URL
    const redirectUrl = window.location.href
    window.location.href = `${logoutUrl}?redirect_url=${redirectUrl}`
  }

  const avatar = (
    <Avatar
      firstName={firstName}
      lastName={lastName}
      picture={picture}
      color={color}
      studliId={0}
    />
  )

  const menuItems: MenuItems = [
    { option: "Min profil", action: goToProfile },
    { option: "Logga ut", action: logout }
  ]

  return (
    <StyledAvatarContainer authenticatedUser={authenticatedUser}>
      <MoreMenu menuItems={menuItems} icon={avatar} />
    </StyledAvatarContainer>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  authenticatedUser: usersSelectors.selectAuthenticatedUser(state)
})

const mapDispatchToProps: DispatchProps = {
  getAuthUserInfo: usersActions.getAuthUserInfoRequest
}

const ConnectedAuthUser = connect(mapStateToProps, mapDispatchToProps)(AuthUser)

export default ConnectedAuthUser
