import { styled } from "@mui/material"
import { Theme } from "@mui/material/styles"

import TableCell, { TableCellProps } from "@mui/material/TableCell"
import TableRow, { TableRowProps } from "@mui/material/TableRow"

export const StyledTableCell = styled(TableCell as React.FC<TableCellProps>)`
  color: ${props => (props.theme as Theme).palette.text.primary};
  background-color: ${props =>
    (props.theme as Theme).palette.background.default};
`

export const StyledTableRowProductNotActive = styled(TableRow)``

export const StyledTableRow = styled(TableRow as React.FC<TableRowProps>)`
  cursor: pointer;
`
