import * as actions from "./actions"

import {
  State,
  UsersActionTypes,
  ReceiveExistingUsersAction,
  ReceiveActivateProductKeysResponseAction,
  RemoveProductKeyAction,
  ProductKeyStatus,
  ProductKeysStatuses,
  ReceiveGetActiveProductsResponseAction,
  UpdateActiveProductsAction,
  ActiveProduct,
  UsersActionTypesWithoutPayload,
  GetAuthUserInfoResponseAction,
  ReceiveGetUsersWithProductResponseAction,
  ReceiveGetUsersOnlineResponseAction,
  ReceiveUsersOnlineUpdatedAction,
  ReceiveUsersWithProductUpdatedAction,
  UpdateUserSettingResponseAction
} from "./types"

const initialState: State = {
  foundUsers: [],
  activeProducts: [],
  authenticatedUser: undefined,
  usersWithProduct: null,
  usersOnline: null,
  foundUserClassrooms: []
}

const reducer = (
  state = initialState,
  action: UsersActionTypes | UsersActionTypesWithoutPayload
): State => {
  switch (action.type) {
    case actions.RESPONSE.SEARCH_EXISTING_USERS_RESPONSE:
      const receivedAnswer: ReceiveExistingUsersAction["payload"] =
        (action as ReceiveExistingUsersAction).payload || {}
      const receivedUsers = receivedAnswer.users || []

      return { ...state, foundUsers: receivedUsers }

    case actions.RESPONSE.ACTIVATE_PRODUCT_KEYS_RESPONSE:
      const activeProductKeys = (
        action as ReceiveActivateProductKeysResponseAction
      ).payload.keys

      return { ...state, productKeys: activeProductKeys }

    case actions.RESPONSE.GET_ACTIVE_PRODUCTS_RESPONSE:
      const activeProductsResponsePayload = (
        action as ReceiveGetActiveProductsResponseAction
      ).payload
      const activeProducts =
        activeProductsResponsePayload &&
        activeProductsResponsePayload.activeProducts

      return { ...state, activeProducts }

    case actions.RESPONSE.GET_USERS_WITH_PRODUCT_RESPONSE:
      const usersWithProductPayload = (
        action as ReceiveGetUsersWithProductResponseAction
      ).payload
      const usersWithProduct =
        usersWithProductPayload && usersWithProductPayload.usersWithProduct

      return { ...state, usersWithProduct }

    case actions.UI.RESET_USERS_WITH_PRODUCT:
      return { ...state, usersWithProduct: null }

    case actions.RESPONSE.GET_USERS_ONLINE_RESPONSE:
      const usersOnlinePayload = (action as ReceiveGetUsersOnlineResponseAction)
        .payload
      const usersOnline = usersOnlinePayload && usersOnlinePayload.usersOnline

      return { ...state, usersOnline }

    case actions.UI.RESET_USERS_ONLINE:
      return { ...state, usersOnline: null }

    case actions.RESPONSE.AUTH_USER_INFO_RESPONSE:
      const authUserResponsePayload = (action as GetAuthUserInfoResponseAction)
        .payload

      return { ...state, authenticatedUser: authUserResponsePayload.user }

    case actions.UI.UPDATE_ACTIVE_PRODUCTS:
      const newProductInfo: {
        studliId: number
        productName: string
      } = (action as UpdateActiveProductsAction).payload

      if (state.activeProducts) {
        const concernedProductInfo = state.activeProducts.find(
          (productInfo: ActiveProduct) =>
            productInfo.studliId === newProductInfo.studliId
        )

        if (concernedProductInfo) {
          const updatedProducts = state.activeProducts.map(
            (productInfo: ActiveProduct) => {
              if (concernedProductInfo.studliId === productInfo.studliId) {
                const updatedProductInfo: ActiveProduct = {
                  ...productInfo,
                  products: [
                    ...productInfo.products,
                    newProductInfo.productName
                  ]
                }

                return updatedProductInfo
              } else {
                return productInfo
              }
            }
          )

          return { ...state, activeProducts: updatedProducts }
        }
      }

      return state

    case actions.UI.REMOVE_PRODUCT_KEY:
      const keyToRemove = (action as RemoveProductKeyAction).payload
      let purgedValidatedProductKeys: ProductKeysStatuses = []

      if (state.productKeys) {
        purgedValidatedProductKeys = state.productKeys.filter(
          (keyStatus: ProductKeyStatus) => {
            return keyStatus.studliId !== keyToRemove
          }
        )
      }

      return { ...state, productKeys: purgedValidatedProductKeys }

    case actions.UI.RESET_FOUND_USERS:
      return { ...state, foundUsers: [] }

    case actions.UI.RESET_FOUND_CLASSROOMS:
      return { ...state, foundUserClassrooms: [] }

    case actions.UI.RESET_ACTIVE_PRODUCTS:
      return { ...state, activeProducts: [] }

    case actions.EVENT.USERS_ONLINE_UPDATED_EVENT:
      return updateUsersOnline(
        state,
        (action as ReceiveUsersOnlineUpdatedAction).payload
      )

    case actions.EVENT.WITH_PRODUCT_UPDATED_EVENT:
      return {
        ...state,
        usersWithProduct: updateWithProduct(
          (action as ReceiveUsersWithProductUpdatedAction).payload,
          state.usersWithProduct
        )
      }

    case actions.RESPONSE.UPDATE_USER_SETTINGS_RESPONSE:
      const settings = (action as UpdateUserSettingResponseAction).payload

      if (state.authenticatedUser) {
        return {
          ...state,
          authenticatedUser: {
            ...state.authenticatedUser,
            settings: settings.settings
          }
        }
      }

      return state

    default:
      return state
  }
}

export default reducer

const updateUsersOnline = (
  state: State,
  { studliId, isOnline }: ReceiveUsersOnlineUpdatedAction["payload"]
): State => {
  const currentUsersOnline = [...(state.usersOnline || [])]
  let updatedUsersOnline = [...currentUsersOnline]
  const existsInCurrent = currentUsersOnline.includes(studliId)

  if (isOnline && !existsInCurrent) {
    updatedUsersOnline.push(studliId)
  }

  if (!isOnline && existsInCurrent) {
    updatedUsersOnline = currentUsersOnline.filter(user => user !== studliId)
  }

  return { ...state, usersOnline: updatedUsersOnline }
}

const updateWithProduct = (
  {
    isProductActivated,
    studliId
  }: ReceiveUsersWithProductUpdatedAction["payload"],
  usersWithProduct: State["usersWithProduct"]
) => {
  if (usersWithProduct) {
    const currentIndex = usersWithProduct.findIndex(id => id === studliId)

    if (isProductActivated && currentIndex === -1) {
      return [...usersWithProduct, studliId]
    } else if (!isProductActivated && currentIndex !== -1) {
      return usersWithProduct.filter(id => id !== studliId)
    }

    return usersWithProduct
  } else {
    return isProductActivated ? [studliId] : []
  }
}
