import { styled } from "@mui/material"

type IconContainerProps = {
  iconColor: string
  marginTop: boolean
}

export const StyledIconContainer = styled("div")<IconContainerProps>`
  ${props => props.marginTop && "margin-top: 9px;"}
  margin-right: 10px;
  color: ${props => props.iconColor};
`
