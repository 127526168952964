import StarOutlined from "@mui/icons-material/StarOutlined"
import { styled } from "@mui/material"
import { IconProps } from "@mui/material/Icon"

const abilityStarMaxSize = 48

const getAbilityStarSize = (grade?: number, size?: number): number => {
  let retValue = 0

  switch (grade) {
    case 0:
    case undefined:
      retValue = 0
      break
    case 1:
      retValue = size ? size * 0.2 : 14
      break
    case 2:
      retValue = size ? size * 0.4 : 20
      break
    case 3:
      retValue = size ? size * 0.6 : 28
      break
    case 4:
      retValue = size ? size * 0.8 : 36
      break
    case 5:
      retValue = size ? size : abilityStarMaxSize
      break
    default:
      window.console.error(
        `Got unexpected goal ability grade of value ${grade}`
      )
  }

  return retValue
}

export const StyledAbilityIndicatorContainer = styled("div")<{
  size: number | undefined
}>`
  display: flex;
  width: ${props => (props.size ? props.size : abilityStarMaxSize)}px;
  min-width: ${props => (props.size ? props.size : abilityStarMaxSize)}px;
  height: ${props => (props.size ? props.size : abilityStarMaxSize)}px;
  position: relative;
`

export const StyledStar = styled(
  StarOutlined as React.FC<IconProps & { grade?: number; size?: number }>
)`
  margin: auto;

  color: ${props => {
    return props.theme.studli.palette.abilityGradeBase[500]
  }};

  font-size: ${props => getAbilityStarSize(props.grade, props.size)}px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: +1;
`

export const StyledStarBackground = styled(
  StarOutlined as React.FC<
    IconProps & { size?: number; backgroundcolor?: string }
  >
)`
  margin: auto;
  color: ${props => {
    return props.backgroundcolor
      ? props.backgroundcolor
      : props.theme.studli.palette.abilityGradeBase[100]
  }};

  font-size: ${props => (props.size ? props.size : abilityStarMaxSize)}px;
  position: absolute;
`

export const StyledToolTipText = styled("p")`
  font-size: 14px;
`
