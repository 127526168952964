import { styled } from "@mui/material"
import Typography, { TypographyProps } from "@mui/material/Typography"
import { teal } from "@mui/material/colors"

export const StyledInvalidClassroomError = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledStyledInvalidClassroomError = styled(
  Typography as React.FC<TypographyProps & { component: string }>
)`
  margin: 0.9em 0;
  text-align: center;
  color: ${teal[50]};
`

export const StyledNavigationButton = styled("a")`
  text-decoration: none;
  color: ${teal[50]};
`
