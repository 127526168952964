import { styled } from "@mui/material"
import Table from "@mui/material/Table"
import TableCell, { TableCellProps } from "@mui/material/TableCell"
import TableHead, { TableHeadProps } from "@mui/material/TableHead"
import { Theme } from "@mui/material/styles"

type HeadCellTextAlign = {
  aligntext?: "right" | "left"
}

export const StyledMembers = styled(Table as React.FC<TableCellProps>)`
  position: relative;
`

export const StyledHeadCell = styled(
  TableCell as React.FC<TableCellProps & HeadCellTextAlign>
)`
  text-align: ${props => (props.aligntext ? props.aligntext : "left")};
  color: ${props => (props.theme as Theme).palette.text.secondary};
  span {
    padding-left: 0;
  }
`

export const StyledSmallCell = styled(TableCell as React.FC<TableCellProps>)`
  padding-right: 0;
  width: 1px;
`

export const StyledMoreCell = styled(TableCell as React.FC<TableCellProps>)`
  padding-left: 0;
  width: 1px;
`

export const StyledTableHead = styled(TableHead as React.FC<TableHeadProps>)`
  white-space: nowrap;
  th:first-child {
    padding-left: 1rem;
  }
  th:last-child {
    padding-right: 1.5rem;
  }
`

export const StyledAbilityStarWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
export const StyledAbilityStarContent = styled("div")`
  display: inline-block;
`
export const StyledAbilityStars = styled("div")`
  display: flex
  justify-content: space-between;
  margin-top: 1em;
`
