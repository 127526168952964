import React, { useState, useEffect } from "react"
import StyledTextField from "../../components/StyledTextField"
import {
  StyledMessageFormWrapper,
  StyledArrowButton
} from "./StyledFeedbackMessages"
import { Member, Members } from "../../modules/members/types"
import {
  Assignment,
  SendAssignmentMessageAction,
  AssignmentMessages,
  AssignmentMessage,
  MarkMessageAsReadAction
} from "../../modules/assignments/types"
import {
  markMessageAsRead,
  sendAssignmentMessage
} from "../../modules/assignments/actions"
import { RootState } from "../../modules/store"
import { AssignmentMessageWithSenderData } from "./types"
import { connect } from "react-redux"
import { selectAllAssignmentsMessages } from "../../modules/assignments/selectors"
import { selectAllMembers } from "../../modules/members/selectors"
import FeedbackMessageList from "../../components/FeedbackMessageList"
import { InputAdornment } from "@mui/material"
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"
import AudioRecorder from "../../components/AudioRecorder"

const ENTER = 13

type OwnProps = {
  to: Member | null
  assignmentId: Assignment["id"]
  setHasSentFeedback: (hasSentFeedback: boolean) => void
  isAssignmentDone: boolean
}

type StateProps = { feedbackMessages: AssignmentMessages; members: Members }

type DispatchProps = {
  sendMessage: (
    message: SendAssignmentMessageAction["payload"]
  ) => SendAssignmentMessageAction
  markMessageAsRead: (
    payload: MarkMessageAsReadAction["payload"]
  ) => MarkMessageAsReadAction
}

type Props = OwnProps & StateProps & DispatchProps

const FeedbackMessages = ({
  to,
  assignmentId,
  sendMessage,
  feedbackMessages,
  members,
  setHasSentFeedback,
  markMessageAsRead,
  isAssignmentDone
}: Props) => {
  const [messagesForMember, setMessagesForMember] = useState<
    AssignmentMessageWithSenderData[]
  >([])
  const [currentMessage, setCurrentMessage] = useState<string>("")
  const [currentAudio, setCurrentAudio] = useState<string | undefined>(
    undefined
  )
  const [showSendButton, setShowSendButton] = useState<boolean>(false)
  useEffect(() => {
    if (to && feedbackMessages) {
      const messagesForCurrentAssignmentAndStudent = feedbackMessages.filter(
        messages => {
          return (
            messages.assignmentId === assignmentId &&
            messages.studliId === to.studliId
          )
        }
      )

      if (members) {
        const messagesWithSenderData =
          messagesForCurrentAssignmentAndStudent.map(
            (message: AssignmentMessage): AssignmentMessageWithSenderData => {
              const [sender] = members.filter(
                member => member.studliId === message.studliIdSender
              )
              const messageWithSenderData = { ...message, sender }

              return messageWithSenderData
            }
          )
        setMessagesForMember(messagesWithSenderData)
      }
    }
  }, [feedbackMessages, to, assignmentId, members])

  useEffect(() => {
    const unreadMessages = messagesForMember.filter(
      m => m.readByTeacherAt === null
    )

    if (unreadMessages.length > 0) {
      unreadMessages.forEach(message => {
        markMessageAsRead({ messageId: message.id })
      })
    }
  }, [messagesForMember, markMessageAsRead])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== ENTER) {
      return
    }

    handleSendMessage()
  }

  const handleSendMessage = async () => {
    if (!to) {
      return
    }
    if (currentAudio) {
      sendMessage({
        assignmentMessage: {
          studliId: to.studliId,
          type: "audio",
          data: currentAudio,
          assignmentId: assignmentId
        }
      })
    } else {
      if (!currentMessage.replace(/ /g, "")) {
        return
      }
      sendMessage({
        assignmentMessage: {
          studliId: to.studliId,
          type: "text",
          data: currentMessage,
          assignmentId: assignmentId
        }
      })
    }
    isAssignmentDone && setHasSentFeedback(true)

    setCurrentMessage("")
    setCurrentAudio(undefined)
    setShowSendButton(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const canSendMessage = e.target.value.replace(/ /g, "").length > 0
    setCurrentMessage(e.target.value)
    setShowSendButton(canSendMessage)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setShowSendButton(e.target.value.replace(/ /g, "") !== "")
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.replace(/ /g, "") !== "") {
      setShowSendButton(true)
    }
  }

  const renderInputAdornment = () => (
    <InputAdornment position="end">
      <StyledArrowButton
        aria-label="skicka meddelande"
        onClick={handleSendMessage}
      >
        <ArrowForwardRoundedIcon />
      </StyledArrowButton>
    </InputAdornment>
  )

  const renderAudioAdornment = () => (
    <InputAdornment position="end">
      <AudioRecorder
        onSend={handleSendMessage}
        setAudio={setCurrentAudio}
        type="input"
        conversation
      />
    </InputAdornment>
  )

  const renderAdornment = () => {
    return {
      endAdornment: showSendButton
        ? renderInputAdornment()
        : renderAudioAdornment()
    }
  }

  return (
    <>
      <StyledMessageFormWrapper>
        <StyledTextField
          id="messageInput"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={currentMessage}
          disabled={currentAudio}
          type="text"
          fullWidth
          height="6em"
          variant="outlined"
          label={
            currentAudio
              ? ""
              : `Skriv till ${to && to.firstName} ${to && to.lastName}`
          }
          InputProps={renderAdornment()}
        />
      </StyledMessageFormWrapper>
      <FeedbackMessageList messagesForMember={messagesForMember} />
    </>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    feedbackMessages: selectAllAssignmentsMessages(state),
    members: selectAllMembers(state)
  }
}

const mapDispatchToProps: DispatchProps = {
  sendMessage: sendAssignmentMessage,
  markMessageAsRead: markMessageAsRead
}

const ConnectedFeedbackMessages = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackMessages)

export default ConnectedFeedbackMessages
