import { Theme } from "@mui/material";

type Breakpoints = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

export const getPadding = () => {
  return {
    xs: "0 1rem 0 1rem",
    sm: "0 2rem 0 2rem",
    md: "0 2rem 0 2rem",
    lg: "0 3.125rem 0 3.125rem",
    xl: "0 3.125rem 0 3.125rem",
  };
};

export const getMargins = () => {
  return {
    xs: "0 1rem 0 1rem",
    sm: "0 2rem 0 2rem",
    md: "0 2rem 0 2rem",
    lg: "0 3.125rem 0 3.125rem",
    xl: "0 3.125rem 0 3.125rem",
  };
};

export const getCenteredMargins = (
  theme: Theme,
  closed: boolean,
  breakpoints: Breakpoints
) => {
  const verticalMargin = 6;

  if (breakpoints.xl || breakpoints.md) {
    return theme.spacing(verticalMargin, "auto");
  }
  if (breakpoints.lg) {
    return theme.spacing(verticalMargin, closed ? 37.5 : 12.5);
  }
  if (breakpoints.sm) return theme.spacing(verticalMargin, 8);
  if (breakpoints.xs) return theme.spacing(verticalMargin, 4);
};

export const getCenteredWidth = (breakpoints: Breakpoints) => {
  if (breakpoints.lg || breakpoints.sm || breakpoints.xs) {
    return { width: "auto" };
  }
  if (breakpoints.xl) return { width: "65rem" };
  if (breakpoints.md) return { width: "52.5rem" };
};
