import React from "react"
import LockIcon from "@mui/icons-material/Lock"
import { Tooltip } from "@mui/material"

type Props = {
  isPublic?: boolean
  createdBy?: number
}

const IsPublicIcon = ({ isPublic }: Props) => {
  return (
    <>
      {!isPublic && (
        <Tooltip title="Endast tillgängligt för mig">
          <LockIcon />
        </Tooltip>
      )}
    </>
  )
}

export default IsPublicIcon
