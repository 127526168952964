import React, { useEffect, useCallback, useState, useMemo } from "react"
import { Stack, TableCell, Typography } from "@mui/material"
import {
  FormativeTest,
  FormativeProgressionArr,
  FormativeProgression
} from "../../modules/formative/types"
import {
  StyledTitleCell,
  StyledTitle,
  StyledStatusCell,
  StyledMenuTableCell
} from "../AssignmentsTableRow/StyledAssignmentsTableRow"
import Progress, { SimpleProgress } from "../Progress"
import MoreMenu, { MenuItems } from "../PopupMenu/PopupMenu"
import {
  StyledActionTableCell,
  StyledStatusIconButton,
  StyledTableRow,
  StyledNoMembers
} from "../FormativeQuestionsListItem/StyledFormativeQuestionsListItem"
import { ReactComponent as PlayIcon } from "../../assets/images/play.svg"
import { ReactComponent as StopIcon } from "../../assets/images/stop.svg"
import { ReactComponent as RedoIcon } from "../../assets/images/redo.svg"
import { PossibleStatusActions } from "../../pages/FormativeQuestions/types"
import FormativeStatusTag from "../../pages/FormativeQuestions/FormativeStatusTag"
import {
  getLatestSession,
  getProgressForBar
} from "../../pages/FormativeQuestions/helpers"
import moment from "moment"
import LastActive from "../LastActive"
import { StyledTestIcon } from "../StudentHistoryList/StyledHistoryList"
import { ProgressForBar } from "./types"
import { useDispatch, useSelector } from "react-redux"
import { stopFormativeTest } from "../../modules/formative/actions"
import Avatar from "../Avatar"
import GroupChip from "../GroupChip/GroupChip"
import IsPublicIcon from "../IsPublicIcon/IsPublicIcon"
import {
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import { selectAllMembers } from "../../modules/members/selectors"
import { sortGroupsOnActive } from "../GroupChip/utils"
import ErrorIcon from "@mui/icons-material/Error"

type Props = {
  test: FormativeTest
  onActionStatusClicked: (
    event: any,
    status: PossibleStatusActions,
    testName: string,
    testId: number
  ) => void
  showIcon?: boolean
  moreMenuOptions: MenuItems
  progress: FormativeProgressionArr
  onProgressDialogClick: (progress: ProgressForBar, title: string) => void
  onRowClick: (testId: number) => void
  type: "studentCard" | "class"
  testGroups?: number[]
}

export const showIconButtonStatuses = ["ongoing", "published", "stopped"]
export const iconButtonAriaLabels = {
  ongoing: "Pågående",
  published: "Publicerad",
  stopped: "Stoppad"
}

const FormativeQuestionsTableRow = ({
  test,
  onActionStatusClicked,
  moreMenuOptions,
  progress,
  onProgressDialogClick,
  onRowClick,
  type,
  showIcon,
  testGroups
}: Props) => {
  const dispatch = useDispatch()
  const groups = useSelector(selectAllGroups)
  const user = useSelector(selectAuthenticatedUser)
  const members = useSelector(selectAllMembers)
  const allActiveGroups = useSelector(selectAllActiveGroups)

  const [, updateState] = useState<{}>({})
  const forceUpdate = useCallback(() => updateState({}), [])
  const [progressForTest, setProgressForTest] = useState<ProgressForBar | null>(
    null
  )

  const getTestGroups = () => {
    if (testGroups && groups) {
      return groups.filter(g => testGroups.includes(g.id))
    }
    return []
  }
  const session = getLatestSession(test.sessions)
  useEffect(() => {
    if (progress && session) {
      const filteredProgress = progress.filter(p =>
        members.some(m => m.studliId === p.memberId)
      )
      const progressForBar = getProgressForBar(session, filteredProgress)
      setProgressForTest(progressForBar)
    }
  }, [progress, test, session, members])

  const renderItems = () => {
    const stoppedAt = moment(session.endedAt)
    const action = () =>
      dispatch(
        stopFormativeTest({
          testId: test.id,
          stopAt: moment().add(0, "minutes")
        })
      )
    const filteredMenuItems = [
      ...moreMenuOptions,
      { option: "Avsluta nu", action: action }
    ].filter(item => item.option !== "Gör igen" && item.option !== "Arkivera")

    return test.status === "stopped" && stoppedAt.isAfter()
      ? filteredMenuItems
      : moreMenuOptions
  }

  const renderIcon = () => {
    switch (test.status) {
      case "published":
        return <PlayIcon />
      case "ongoing":
        return <StopIcon />
      case "stopped":
        return <RedoIcon />
      default:
        return ""
    }
  }

  const getStatus = (p: FormativeProgression) => {
    if (p.submittedAt) {
      return "done"
    }
    if (p.startedAt) {
      return "inProgress"
    }
    return "notStarted"
  }

  const renderIconButton = () => {
    switch (test.status) {
      case "published":
      case "ongoing":
      case "stopped":
        if (test.status === "stopped") {
          const latestSession = getLatestSession(test.sessions)
          const stoppedAt = moment(latestSession.endedAt)
          if (stoppedAt.isAfter()) {
            return (
              <StyledStatusIconButton
                onClick={event =>
                  onActionStatusClicked(
                    event,
                    "ongoing" as PossibleStatusActions,
                    test.title,
                    test.id
                  )
                }
                aria-label={
                  iconButtonAriaLabels[test.status as PossibleStatusActions]
                }
              >
                <StopIcon />
              </StyledStatusIconButton>
            )
          }
        }
        return (
          <StyledStatusIconButton
            onClick={event =>
              onActionStatusClicked(
                event,
                test.status as PossibleStatusActions,
                test.title,
                test.id
              )
            }
            aria-label={
              iconButtonAriaLabels[test.status as PossibleStatusActions]
            }
          >
            {renderIcon()}
          </StyledStatusIconButton>
        )
      default:
        return ""
    }
  }

  const latestSession = getLatestSession(test.sessions)

  const showMissingGroups = useMemo(() => {
    if (type === "studentCard") return false
    const sessionParticipants = test.participantsInfo.filter(p =>
      latestSession.members.includes(p.studliId)
    )

    return (
      sessionParticipants.some(
        p => !groups.some(g => p.groups.includes(g.id))
      ) && test.createdBy === user?.studliId
    )
  }, [test, latestSession, groups, user])

  const studentProgress = latestSession
    ? progress.find(p => p.sessionId === latestSession.id)
    : null
  return (
    <StyledTableRow
      hasonclick={test.status === "draft" ? 0 : 1}
      onClick={() => onRowClick(test.id)}
      missingGroups={showMissingGroups}
    >
      {type === "class" && (
        <StyledActionTableCell>
          {showIconButtonStatuses.some(
            buttonStatus => buttonStatus === test.status
          ) && renderIconButton()}
        </StyledActionTableCell>
      )}
      {showIcon && (
        <StyledActionTableCell>
          <StyledTestIcon />
        </StyledActionTableCell>
      )}
      <StyledTitleCell>
        <StyledTitle>{test.title}</StyledTitle>
        {(latestSession === undefined ||
          latestSession.members.length === 0) && (
          <StyledNoMembers>Testet saknar elever</StyledNoMembers>
        )}
      </StyledTitleCell>
      <StyledStatusCell>
        <FormativeStatusTag
          status={test.status}
          numberOfSessions={test.sessions.length}
          endsAt={(latestSession && latestSession.endedAt) || ""}
          onEndedCounter={forceUpdate}
        />
      </StyledStatusCell>
      {type === "class" && (
        <TableCell>
          <Stack direction="row" spacing={1}>
            {showMissingGroups ? (
              <Stack direction="row" spacing={1} sx={{ color: "#DA1E28" }}>
                <ErrorIcon /> <Typography>Elever saknar grupp</Typography>
              </Stack>
            ) : (
              <GroupChip
                groups={sortGroupsOnActive(getTestGroups(), allActiveGroups)}
                truncate
              />
            )}
          </Stack>
        </TableCell>
      )}
      {type === "class" && (
        <StyledStatusCell>
          <LastActive date={test.updatedAt} />
        </StyledStatusCell>
      )}
      <TableCell>
        {progressForTest && test.status !== "draft" && type === "class" && (
          <Progress
            onOpenProgressDialog={() =>
              onProgressDialogClick(progressForTest, test.title)
            }
            done={progressForTest.done.length}
            type="formativeQuestions"
            inProgress={progressForTest.inProgress.length}
            notStarted={progressForTest.notStarted.length}
          />
        )}
        {type === "studentCard" &&
          test.status !== "draft" &&
          studentProgress && (
            <SimpleProgress
              type="assignment"
              status={getStatus(studentProgress)}
            />
          )}
      </TableCell>
      {type === "class" && (
        <StyledMenuTableCell>
          {test.createdByFirstName && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                studliId={test.createdBy}
                firstName={test.createdByFirstName}
                lastName={test.createdByLastName}
                picture={test.createdByPictureUrl}
              />
              <IsPublicIcon
                isPublic={test.isPublic}
                createdBy={test.createdBy}
              />
            </Stack>
          )}
        </StyledMenuTableCell>
      )}
      {type === "class" && (
        <StyledMenuTableCell>
          <MoreMenu menuItems={renderItems()} />
        </StyledMenuTableCell>
      )}
    </StyledTableRow>
  )
}

export default FormativeQuestionsTableRow
