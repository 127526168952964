export enum SocketState {
  closed = "closed",
  closing = "closing",
  opening = "opening",
  opened = "opened",
  error = "error",
  reconnecting = "reconnecting"
}

export type State = {
  socketState: SocketState
}

export type OpenSocketAction = {
  type: string
}

export type SocketOpenedAction = {
  type: string
}

export type SocketErrorAction = {
  type: string
  payload: Event | Error
}

export type SocketClosedAction = {
  type: string
  payload: Event | Error
}

export type SocketCloseAction = {
  type: string
  payload: Event | Error
}

export type SocketReconnectAction = {
  type: string
  payload: Event | Error
}

export type SocketActionTypes =
  | OpenSocketAction
  | SocketOpenedAction
  | SocketErrorAction
  | SocketCloseAction
  | SocketClosedAction
  | SocketReconnectAction
