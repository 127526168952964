import { styled } from "@mui/material"
import TableCell, { TableCellProps } from "@mui/material/TableCell"
import TableRow from "./TableRow"
import { getColor, getBackgroundColor } from "../../pages/Members/common/styles"

export const StyledTableCell = styled(TableCell as React.FC<TableCellProps>)``

export const StyledTableRowProductNotActive = styled(TableRow as any)`
  color: ${props => getColor(props)};
  background-color: #f9fbff;
  &:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }
  cursor: ${props => (props.hasonclick ? "pointer" : "default")};
`

export const StyledTableRow = styled(TableRow as any)`
  background-color: ${props => getBackgroundColor(props)};
  cursor: ${props => (props.hasonclick ? "pointer" : "default")};

  &:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }

  td:first-child {
    padding-left: 1rem;
  }
  td:last-child {
    padding-right: 1rem;
  }
`
