import { map } from "fp-ts/lib/Array"
import { fromPredicate, fold } from "fp-ts/lib/Option"
import { pipe } from "fp-ts/lib/pipeable"
import { Lens } from "monocle-ts"

import { Goal } from "./types"

const goalLens = Lens.fromProps<Goal>()

const moveUserDependingOnReason =
  (studliId: number, reason: "started" | "completed") => (goal: Goal) =>
    pipe(
      goal,
      fromPredicate(() => reason === "completed"),
      fold(
        () =>
          goalLens(["inProgress"]).modify(({ inProgress }) => ({
            inProgress: [...inProgress.filter(id => id !== studliId), studliId]
          }))(goal),
        goalLens(["inProgress", "done"]).modify(({ inProgress, done }) => ({
          inProgress: inProgress.filter(id => id !== studliId),
          done: [...done, studliId]
        }))
      )
    )

/**
 *  Update specified goals list with specified goal data.
 *  When reason is "started" inProgress shall get the studliId,
 *  when it's "completed" specified studliId will be moved from inProgress to done.
 */
const updateGoal =
  (
    {
      goalRef,
      studliId
    }: {
      studliId: number
      goalRef: string
    },
    reason: "started" | "completed"
  ) =>
  (goals: Goal[]) =>
    map<Goal, Goal>(goal =>
      pipe(
        goal,
        fromPredicate(g => g.goalRef === goalRef),
        fold(() => goal, moveUserDependingOnReason(studliId, reason))
      )
    )(goals)

export default updateGoal
