import * as actions from "./actions"
import { State } from "./types"
import { createReducer } from "../../shared/tools/redux"
import { setField } from "../../shared/tools/monocle"

const initialState: State = { shouldReload: false }

const setShouldReload = setField<State>("shouldReload", () => true)

const reducer = createReducer<State>(initialState, builder =>
  builder.case(actions.toggleShouldReload, setShouldReload)
)

export default reducer
