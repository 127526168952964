import { takeLatest, put, all, race, select } from "redux-saga/effects"
import { sendRequest } from "../communication/actions"
import * as assignmentsActions from "./actions"
import * as communicationActions from "../communication/actions"
import * as assignmentsTypes from "./types"
import * as assignmentsTaskProgressActions from "../assignmentsProgress/actions"
import { MODULE_NAME, CLASS_VALIDATION_ERRORS } from "./constants"
import * as routerActions from "../router/actions"
import {
  selectClassroomFeatures,
  selectClassroomId
} from "../classroom/selectors"
import * as applicationActions from "../application/actions"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import { SnackbarData } from "../../containers/Snackbar/types"
import {
  selectActiveProductId,
  selectActiveProductWithTwins
} from "../products/selectors"
import { changeActiveProduct } from "../products/actions"
import { selectAllAssignmentsMessages } from "./selectors"
import moment from "moment"
import { Products } from "../products/types"
import {
  selectAuthenticatedUser,
  selectUserActiveProducts
} from "../users/selectors"
import { ActiveProduct, User } from "../users/types"
import { Features } from "../classroom/types"

function* getAllAssignmentsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.GET_ALL_ASSIGNMENTS_REQUEST
    )
  )
}

function* watchGetAllAssignmentsSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.GET_ALL_ASSIGNMENTS_REQUEST,
    getAllAssignmentsSaga
  )
}

function* getAllClassroomRelatedAssignmentsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_REQUEST
    )
  )
}

function* watchAllClassroomRelatedAssignmentsSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_REQUEST,
    getAllClassroomRelatedAssignmentsSaga
  )
}

function* getAllPreparedAssignmentsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_PREPARED_ASSIGNMENTS_REQUEST
    )
  )
}

function* watchAllPreparedAssignmentsSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.GET_ALL_PREPARED_ASSIGNMENTS_REQUEST,
    getAllPreparedAssignmentsSaga
  )
}

export function* handleReceiveAssignmentsResponseSaga(
  message: assignmentsTypes.ReceiveAssignmentsResponseMessagesTypes
) {
  const { type, action, payload, error, echo } = message.payload
  const classroomId = yield select(selectClassroomId)

  if (type === MODULE_NAME) {
    switch (action) {
      case assignmentsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_ASSIGNMENTS_RESPONSE:
        if (error) {
          return
        }
        const { assignments } =
          payload as assignmentsTypes.ReceiveAssignmentsAction["payload"]
        yield put(assignmentsActions.setAssignments(assignments))
        break

      case assignmentsActions.SERVER_MESSAGE_ACTION.IMPORT_ASSIGNMENT_RESPONSE:
        yield put(assignmentsActions.setLoadingImportAssignment(0))
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.IMPORT_ASSIGNMENT_RESPONSE_FAILURE
            )
          )

          return
        }
        yield put(assignmentsActions.getAllAssignments())
        yield put(assignmentsActions.getAllClassroomRelatedAssignments())
        yield put(assignmentsTaskProgressActions.getAllAssignmentsProgress())
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.IMPORT_ASSIGNMENT_RESPONSE_SUCCESS
          )
        )
        break
      case assignmentsActions.SERVER_MESSAGE_ACTION.GET_ASSIGNMENT_RESPONSE:
        if (error) {
          return
        }

        const assignment =
          payload as assignmentsTypes.GetAssignmentResponseAction["payload"]

        if (!assignment || !assignment.productId) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.ASSIGNMENT_NOT_FOUND
            )
          )

          yield put(
            routerActions.gotoRoute(routerActions.ROUTE_UPPDRAG, {
              classroomId
            })
          )

          return
        }

        const activeProduct = yield select(selectActiveProductId)

        if (assignment.productId !== activeProduct) {
          yield put(changeActiveProduct(assignment.productId))
        }

        break

      case assignmentsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_RESPONSE:
        if (error) {
          return
        }
        const classroomAssignments =
          payload as assignmentsTypes.GetAllClassroomRelatedAssignmentsResponseAction["payload"]
        yield put(
          assignmentsActions.setImportableAssignments(classroomAssignments)
        )
        break

      case assignmentsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_PREPARED_ASSIGNMENTS_RESPONSE:
        if (error) {
          return
        }
        yield put(
          assignmentsActions.setPreparedAssignments(
            (
              payload as assignmentsTypes.GetAllPreparedAssignmentsResponseAction["payload"]
            ).preparedAssignments
          )
        )
        break

      case assignmentsActions.SERVER_MESSAGE_ACTION.CREATE_ASSIGNMENT_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.CREATE_ASSIGNMENT_RESPONSE_FAILURE
            )
          )

          return
        }
        const assignmentPayload =
          payload as assignmentsTypes.ReceiveCreateAssignmentResponseAction["payload"]
        if (assignmentPayload.valid && assignmentPayload.assignment) {
          assignmentsActions.addAssignment({
            assignment: assignmentPayload.assignment
          })
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.CREATE_ASSIGNMENT_RESPONSE_SUCCESS
            )
          )
          yield put(
            routerActions.gotoRoute(routerActions.ROUTE_UPPDRAG, {
              classroomId: classroomId
            })
          )
        }
        if (assignmentPayload.validationData) {
          yield handleCreateEditValidationError(
            assignmentPayload.validationData
          )
        }
        break

      case assignmentsActions.SERVER_MESSAGE_ACTION.EDIT_ASSIGNMENT_RESPONSE:
        const editAction =
          echo as assignmentsTypes.ReceiveEditAssignmentResponseAction["echo"]

        if (error) {
          yield handleEditActionToaster(
            editAction
              ? (editAction as assignmentsTypes.EditEchoActionTypes)
              : null,
            false
          )

          return
        }
        const editAssignment =
          payload as assignmentsTypes.ReceiveEditAssignmentResponseAction["payload"]
        if (editAssignment.valid && editAssignment.assignment) {
          yield handleEditActionToaster(
            editAction
              ? (editAction as assignmentsTypes.EditEchoActionTypes)
              : null,
            true
          )

          yield put(
            assignmentsActions.modifyAssignment(
              payload as assignmentsTypes.ReceiveEditAssignmentResponseAction["payload"]
            )
          )
          if (echo !== "removeMembers" && echo !== "togglePrivate") {
            yield put(
              routerActions.gotoRoute(routerActions.ROUTE_UPPDRAG, {
                classroomId: classroomId
              })
            )
          }
        }
        if (editAssignment.validationData) {
          yield handleCreateEditValidationError(editAssignment.validationData)
        }
        break

      case assignmentsActions.SERVER_MESSAGE_ACTION.REMOVE_ASSIGNMENT_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.REMOVE_ASSIGNMENT_RESPONSE_FAILURE
            )
          )

          return
        }
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.REMOVE_ASSIGNMENT_RESPONSE_SUCCESS
          )
        )
        yield put(
          assignmentsActions.deleteAssignment(
            payload as assignmentsTypes.ReceiveRemoveAssignmentResponseAction["payload"]
          )
        )
        break
      case assignmentsActions.SERVER_MESSAGE_ACTION
        .APPROVE_OR_DECLINE_ASSIGNMENT_RESPONSE:
        if (error) {
          return
        }
        const { approved, assignmentId, studliId } =
          payload as assignmentsTypes.ReceiveApproveOrDeclineAssignmentAction["payload"]

        if (!approved) {
          yield put(
            assignmentsTaskProgressActions.clearAssignmentsTaskForAssignment({
              assignmentId,
              studliId
            })
          )
        }
        const snackbarMessage = approved
          ? snackbarMessages.ASSIGNMENT_APPROVED_RESPONSE
          : snackbarMessages.ASSIGNMENT_REJECTED_RESPONSE
        yield put(applicationActions.setSnackbar(snackbarMessage))
        break
      case assignmentsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_ASSIGNMENTS_MESSAGES_RESPONSE:
        if (error) {
          return
        }
        const messagesPayload =
          payload as assignmentsTypes.ReceiveAllAssignmentsMessagesResponseAction["payload"]
        yield put(
          assignmentsActions.setAssignmentMessages(
            messagesPayload.assignmentMessages
          )
        )
        break
      case assignmentsActions.SERVER_MESSAGE_ACTION
        .SEND_ASSIGNMENT_MESSAGE_RESPONSE:
        if (error) {
          return
        }
        break
      case assignmentsActions.SERVER_MESSAGE_ACTION
        .MARK_MESSAGE_AS_READ_RESPONSE:
        if (error) {
          return
        }

        const allMessages = yield select(selectAllAssignmentsMessages)
        const { messageId } =
          payload as assignmentsTypes.ReceiveMarkMessageAsReadAction["payload"]

        const newMessages = allMessages.map(
          (m: assignmentsTypes.AssignmentMessage) => {
            if (m.id === messageId) {
              m.readByTeacherAt = moment()
            }

            return m
          }
        )

        yield put(assignmentsActions.setAssignmentMessages(newMessages))
        break
      case assignmentsActions.SERVER_MESSAGE_ACTION.STORE_PREVIEW_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.FAILED_TO_STORE_PREVIEW
            )
          )

          return
        }

        const user: User = yield select(selectAuthenticatedUser)
        const userProds: ActiveProduct[] = yield select(
          selectUserActiveProducts
        )
        const products: Products = yield select(selectActiveProductWithTwins)
        const classroomFeatures: Features = yield select(
          selectClassroomFeatures
        )

        if (!user) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.FAILED_TO_STORE_PREVIEW
            )
          )

          return
        }

        const activeProducts = userProds.find(u => u.studliId === user.studliId)

        if (!activeProducts) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.FAILED_TO_STORE_PREVIEW
            )
          )

          return
        }

        const [product] = products.filter(
          p => activeProducts.products.indexOf(p.sesamName) > -1
        )

        if (!product) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.FAILED_TO_STORE_PREVIEW_DUE_NO_ACTIVE_PRODUCT
            )
          )

          return
        }

        const { previewId } =
          payload as assignmentsTypes.ReceiveStorePreviewResponseAction["payload"]

        const url = process.env.REACT_APP_BOOKSHELF_URL

        let path = ""

        if (classroomFeatures.indexOf("FN") < 0) {
          path = `${url}/dill/app/#/task/${product.sesamName}/${previewId}?classroomType=tecla&p`
        } else {
          path = `${url}/dill/app/#/assignment/${product.sesamName}/tecla/${previewId}?classroomType=tecla&p`
        }

        yield put(assignmentsActions.setPreviewUrl(path))

        break
      default:
        return
    }
  }
}

function* watchStorePreviewSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.STORE_PREVIEW_REQUEST,
    storePreview
  )
}

function* storePreview(message: assignmentsTypes.StorePreviewRequestAction) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.STORE_PREVIEW_REQUEST,
      { data: message.payload }
    )
  )
}

function* getAssignmentSaga(
  message: assignmentsTypes.GetAssignmentRequestAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.GET_ASSIGNMENT_REQUEST,
      message.payload
    )
  )
}

function* handleEditActionToaster(
  action: assignmentsTypes.EditEchoActionTypes | null,
  success: boolean
) {
  switch (action) {
    case "archive":
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.ARCHIVE_ASSIGNMENT_RESPONSE_SUCCESS
            : snackbarMessages.ARCHIVE_ASSIGNMENT_RESPONSE_FAILURE
        )
      )
      break
    case "activate":
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.ACTIVATE_ASSIGNMENT_RESPONSE_SUCCESS
            : snackbarMessages.ACTIVATE_ASSIGNMENT_RESPONSE_FAILURE
        )
      )
      break
    case "publish":
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.PUBLISH_ASSIGNMENT_RESPONSE_SUCCESS
            : snackbarMessages.PUBLISH_ASSIGNMENT_RESPONSE_FAILURE
        )
      )
      break
    case "finish":
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.FINISH_ASSIGNMENT_RESPONSE_SUCCESS
            : snackbarMessages.FINISH_ASSIGNMENT_RESPONSE_FAILURE
        )
      )
      break
    default:
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.EDIT_ASSIGNMENT_RESPONSE_SUCCESS
            : snackbarMessages.EDIT_ASSIGNMENT_RESPONSE_FAILURE
        )
      )
  }
}

function* watchGetAssignmentSaga() {
  yield takeLatest(assignmentsActions.REQUEST.GET_ASSIGNMENT, getAssignmentSaga)
}

function* approveOrDeclineAssignmentSaga(
  message: assignmentsTypes.ApproveOrDeclineAssignmentAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION
        .APPROVE_OR_DECLINE_ASSIGNMENT_REQUEST,
      message.payload
    )
  )
}

function* watchApproveOrDeclineAssignmentSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.APPROVE_OR_DECLINE_ASSIGNMENT_REQUEST,
    approveOrDeclineAssignmentSaga
  )
}

function* createAssignmentSaga(
  message: assignmentsTypes.CreateAssignmentAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.CREATE_ASSIGNMENT_REQUEST,
      message.payload
    )
  )
}

function* watchCreateAssignmentSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.CREATE_ASSIGNMENT_REQUEST,
    createAssignmentSaga
  )
}

function* removeAssignmentSaga(
  message: assignmentsTypes.RemoveAssignmentAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.REMOVE_ASSIGNMENT_REQUEST,
      message.payload
    )
  )
}

function* watchRemoveAssignmentSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.REMOVE_ASSIGNMENT_REQUEST,
    removeAssignmentSaga
  )
}

function* handleCreateEditValidationError(validationError: {
  [key: number]: string
}) {
  yield put(
    assignmentsActions.createEditAssignmentValidationError(validationError)
  )
  const first = Object.keys(validationError)[0]
  const errorMessage = CLASS_VALIDATION_ERRORS[validationError[+first]]

  yield put(
    applicationActions.setSnackbar({
      severity: "error",
      message: errorMessage
    } as SnackbarData)
  )
}

export function* handleAssignmentsEventSaga(
  message: assignmentsTypes.ReceiveAssignmentEventMessagesTypes
) {
  const { type, action, payload } = message.payload
  const ASSIGNMENT_EVENT_TYPE = "assignment"
  const currentUser = yield select(selectAuthenticatedUser)
  if (type === ASSIGNMENT_EVENT_TYPE) {
    switch (action) {
      case assignmentsActions.SERVER_MESSAGE_ACTION.ASSIGNMENT_ADDED_EVENT:
        const pl =
          payload as assignmentsTypes.RecieveAssignmentAddedEvent["payload"]
        if (!pl.assignment.isPublic) {
          if (pl.assignment.createdBy !== currentUser.studliId) {
            break
          }
        }

        yield put(
          assignmentsActions.addAssignment({ assignment: pl.assignment })
        )
        break

      case assignmentsActions.SERVER_MESSAGE_ACTION.ASSIGNMENT_UPDATE_EVENT:
        const updatePayload =
          payload as assignmentsTypes.RecieveAssignmentUpdatedEvent["payload"]
        if (!updatePayload.assignment.isPublic) {
          if (updatePayload.assignment.createdBy !== currentUser.studliId) {
            yield put(
              assignmentsActions.deleteAssignment({
                id: updatePayload.assignment.id
              })
            )
            break
          }
        }
        yield put(
          assignmentsActions.addAssignment({
            assignment: updatePayload.assignment
          })
        )
        break
    }
  }
}

function* importAssignmentSaga(
  message: assignmentsTypes.ImportAssignmentAction
) {
  yield put(
    assignmentsActions.setLoadingImportAssignment(
      message.payload.assignmentIds.length
    )
  )
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.IMPORT_ASSIGNMENT_REQUEST,
      message.payload
    )
  )
}

function* editAssignmentSaga(message: assignmentsTypes.EditAssignmentAction) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.EDIT_ASSIGNMENT_REQUEST,
      message.payload,
      message.echo
    )
  )
}

function* watchEditAssignmentSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.EDIT_ASSIGNMENT_REQUEST,
    editAssignmentSaga
  )
}

function* getAllAssignmentsMessagesSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_ASSIGNMENTS_MESSAGES_REQUEST
    )
  )
}

function* watchGetAllAssignmentsMessagesSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.GET_ALL_ASSIGNMENTS_MESSAGES_REQUEST,
    getAllAssignmentsMessagesSaga
  )
}

function* sendAssignmentMessageSaga(
  message: assignmentsTypes.SendAssignmentMessageAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.SEND_ASSIGNMENT_MESSAGE_REQUEST,
      message.payload
    )
  )
}

function* watchSendAssignmentMessageSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.SEND_ASSIGNMENT_MESSAGE_REQUEST,
    sendAssignmentMessageSaga
  )
}

function* markMessageAsReadSaga(
  message: assignmentsTypes.MarkMessageAsReadAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsActions.SERVER_MESSAGE_ACTION.MARK_MESSAGE_AS_READ_REQUEST,
      message.payload
    )
  )
}

function watchImportAssignmentSaga() {
  return takeLatest(
    assignmentsActions.REQUEST.IMPORT_ASSIGNMENT_REQUEST,
    importAssignmentSaga
  )
}

function* watchMarkMessageAsReadSaga() {
  yield takeLatest(
    assignmentsActions.REQUEST.MARK_MESSAGE_AS_READ_REQUEST,
    markMessageAsReadSaga
  )
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceiveAssignmentsResponseSaga
    ),
    yield takeLatest(
      communicationActions.RECEIVE_EVENT,
      handleAssignmentsEventSaga
    )
  ])
}

export function* rootSaga() {
  yield all([
    watchGetAllAssignmentsSaga(),
    watchReceiverSaga(),
    watchCreateAssignmentSaga(),
    watchEditAssignmentSaga(),
    watchRemoveAssignmentSaga(),
    watchAllClassroomRelatedAssignmentsSaga(),
    watchAllPreparedAssignmentsSaga(),
    watchApproveOrDeclineAssignmentSaga(),
    watchGetAllAssignmentsMessagesSaga(),
    watchSendAssignmentMessageSaga(),
    watchGetAssignmentSaga(),
    watchMarkMessageAsReadSaga(),
    watchStorePreviewSaga(),
    watchImportAssignmentSaga()
  ])
}
