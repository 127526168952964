import { Lens } from "monocle-ts"
import { filter, map } from "fp-ts/lib/Array"
import { flow } from "fp-ts/lib/function"

import * as actions from "./actions"
import { State, Notification } from "./types"
import { createReducer } from "../../shared/tools/redux"
import { concat, push } from "../../shared/tools/pipeables"

const initialState: State = {
  notifications: []
}

const notifications = Lens.fromProp<State>()("notifications")
const readByMembers = Lens.fromProp<Notification>()("readByMembers")

const setNotifications = (ns: Notification[]) =>
  notifications.modify(concat(ns))
const setReadByMemberOnNotifications = (payload: {
  memberId: number
  notificationIds: number[]
}) =>
  notifications.modify(markAsReadBy(payload.memberId, payload.notificationIds))
const replaceNotification = (notification: Notification) =>
  notifications.modify(
    flow(
      filter(n => n.id !== notification.id),
      push(notification)
    )
  )
const pushNotification = (notification: Notification) =>
  notifications.modify(push(notification))

const markAsReadBy = (memberId: number, notificationToMark: number[]) =>
  map<Notification, Notification>(n =>
    notificationToMark.includes(n.id) ? addMemberToReadBy(memberId)(n) : n
  )

const addMemberToReadBy = (memberId: number) =>
  readByMembers.modify(ids => [...ids.filter(id => id !== memberId), memberId])

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.addNotifications, setNotifications)
    .case(actions.markNotificationsAsRead, setReadByMemberOnNotifications)
    .case(actions.updateNotification, replaceNotification)
    .case(actions.addNotification, pushNotification)
)

export default reducer
