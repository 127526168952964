import React, { useMemo } from "react"

import {
  StyledInfoContainer,
  StyledInfoBox,
  StyledInfoSegment,
  StyledInfoLabel,
  StyledInfoValue,
  StyledName,
  StyledInfoColumnContainer,
  StyledLabelAndValue
} from "./StyledStudentInfo"
import useStudentInfo from "./useStudentInfo"
import { Member } from "../../modules/members/types"
import Avatar from "../Avatar"
import LastActive from "../LastActive"
import { useSelector } from "react-redux"
import { selectAllGroups } from "../../modules/classroom/selectors"
import GroupChip from "../GroupChip/GroupChip"

const StudentInfo = () => {
  const { student, lastActive } = useStudentInfo()
  const groups = useSelector(selectAllGroups)

  const userGroups = useMemo(() => {
    if (!student || !groups || !groups.length) {
      return []
    }
    return groups.filter(group => student?.groups?.includes(group.id))
  }, [groups, student])

  const renderAboutStudent = (student: Member) => {
    return (
      <>
        <StyledLabelAndValue>
          <StyledInfoLabel>ANVÄNDARNAMN</StyledInfoLabel>
          <StyledInfoValue>{student.username}</StyledInfoValue>
        </StyledLabelAndValue>

        <StyledLabelAndValue>
          <StyledInfoLabel>E-POST</StyledInfoLabel>
          <StyledInfoValue>{student.email}</StyledInfoValue>
        </StyledLabelAndValue>

        {!!student.birthYear && (
          <StyledLabelAndValue>
            <StyledInfoLabel>FÖDELSEÅR</StyledInfoLabel>
            <StyledInfoValue>{student.birthYear}</StyledInfoValue>
          </StyledLabelAndValue>
        )}

        <StyledLabelAndValue>
          <StyledInfoLabel>SENAST ARBETAT</StyledInfoLabel>
          <StyledInfoValue>
            {lastActive ? <LastActive date={lastActive} /> : "Aldrig"}
          </StyledInfoValue>
        </StyledLabelAndValue>
        <StyledLabelAndValue>
          <StyledInfoLabel>GRUPPER</StyledInfoLabel>
          <StyledInfoValue sx={{ pt: 1 }}>
            <GroupChip groups={userGroups} />
          </StyledInfoValue>
        </StyledLabelAndValue>
      </>
    )
  }

  return (
    <StyledInfoContainer>
      {student && (
        <StyledInfoSegment>
          <Avatar
            firstName={student.firstName}
            lastName={student.lastName}
            picture={student.picture}
            studliId={student.studliId}
          />
          <StyledName variant="h1">
            {student.firstName} {student.lastName}
          </StyledName>
        </StyledInfoSegment>
      )}
      <StyledInfoColumnContainer>
        <StyledInfoBox>{student && renderAboutStudent(student)}</StyledInfoBox>
      </StyledInfoColumnContainer>
    </StyledInfoContainer>
  )
}

export default StudentInfo
