import { styled } from "@mui/material"
import Typography, { TypographyProps } from "@mui/material/Typography"

export const StyledTitleAndDescription = styled("div")`
  margin-bottom: 1em;
`

export const StyledTitle = styled(
  Typography as React.FC<TypographyProps & { component: string }>
)`
  padding-top: 1em;
  padding-bottom: 1em;
`

export const StyledDescription = styled(
  Typography as React.FC<TypographyProps>
)`
  max-width: 43em;
  padding-bottom: 0.5em;
`
