import { createAction } from "../../shared/tools/redux"
import * as types from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_EXERCISES_REQUEST = "GET_ALL_EXERCISES_REQUEST",
  GET_TEMATOC_REQUEST = "GET_TEMATOC_REQUEST"
}

export enum RESPONSE {
  GET_ALL_EXERCISES_RESPONSE = "GET_ALL_EXERCISES_RESPONSE",
  GET_TEMATOC_RESPONSE = "GET_TEMATOC_RESPONSE"
}

export enum UI {
  RESET_EXERCISES = "RESET_EXERCISES"
}

export enum SERVER_MESSAGE_ACTION {
  GET_ALL_EXERCISES_REQUEST = "toc",
  GET_ALL_EXERCISES_RESPONSE = "toc",
  GET_TEMATOC_REQUEST = "tematoc",
  GET_TEMATOC_RESPONSE = "tematoc"
}

export const getAllExercises = createAction("GET_ALL_EXERCISES_REQUEST")
export const getTemaToc = createAction("GET_TEMATOC_REQUEST")
export const setExercises = createAction<{ toc: types.Exercises }>(
  "GET_ALL_EXERCISES_RESPONSE"
)
export const setTemaToc = createAction<{ temaToc: types.Exercises }>(
  "GET_TEMATOC_RESPONSE"
)
export const resetExercises = createAction("RESET_EXERCISES")
