import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Container, Grid, Menu, MenuItem, Stack } from "@mui/material"
import * as goalsPageTypes from "./store/types"
import * as goalsActions from "../../modules/goals/actions"
import * as goalsPageActions from "./store/actions"
import TitleAndDescription from "../../components/TitleAndDescription"
import {
  selectGoalFilter,
  selectIsGettingGoals,
  selectGoalsForGoalsList
} from "./store/selectors"

import { selectStudentsWithProductLength } from "../../modules/members/selectors"
import { RootState } from "../../modules/store"
import {
  StyledLoaderWrapper,
  StyledFilterWrapper,
  StyledSelectPdfWrapper
} from "./StyledGoals"
import Select from "../../components/Select"

import GoalsList from "../../components/GoalsList"
import GoalsTable from "../../components/GoalsTable"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import Loader from "../../components/Loader"
import * as mockData from "../../modules/goals/mockData"
import { SelectOptions } from "../../components/Select/Select"
import * as miscTools from "../../shared/tools/misc"
import Button from "../../components/Button"

type OwnProps = {
  children?: never
}

type StateProps = {
  goalsInGoalsList: goalsPageTypes.GoalsInGoalsList
  isGettingGoals: boolean
  gradeFilterValue: goalsPageTypes.FilterBy
  numberOfStudentsWithProductInClassroom: number
}

type DispatchProps = {
  getAllGoals: typeof goalsActions.getAllGoalsRequest
  setGoalFilter: (payload: {
    value: goalsPageTypes.FilterBy
  }) => goalsPageTypes.SetGoalFilterAction
}

type Props = OwnProps & StateProps & DispatchProps

export const Goals = ({
  goalsInGoalsList = [],
  isGettingGoals,
  gradeFilterValue,
  setGoalFilter,
  numberOfStudentsWithProductInClassroom
}: Props) => {
  useEffect(() => {
    const filteredGoals = miscTools.filterObjectOnAttribute(
      "grade",
      gradeFilterValue,
      goalsInGoalsList
    )

    setFilteredGoals(filteredGoals)
  }, [goalsInGoalsList, gradeFilterValue])

  useEffect(() => {
    setGradeFilterOptions(getGradesFromGoalList(goalsInGoalsList))
  }, [goalsInGoalsList])

  const [filteredGoals, setFilteredGoals] =
    useState<goalsPageTypes.GoalsInGoalsList>([])
  const [downloadPdfButtonAnchor, setDownloadPdfButtonAnchor] =
    React.useState<null | HTMLElement>(null)

  const handlePdfDownloadOpen = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDownloadPdfButtonAnchor(event.currentTarget)
  }

  const handlePdfDownloadClose = () => {
    setDownloadPdfButtonAnchor(null)
  }

  const [gradeFilterOptions, setGradeFilterOptions] = useState<SelectOptions>(
    []
  )

  const handleFilterChange = (value: goalsPageTypes.FilterBy) => {
    setGoalFilter({ value })
  }

  const handleDownloadPdfChange = (value: string) => {
    window.open(value)
  }

  const renderTitleAndDescription = () => {
    const paragraphs = [
      `Övningarna är sorterade i mål. Välj vilken årskurs du vill se målen för.`,
      `När eleverna har börjat räkna ser du deras aktivitet på respektive mål i översikten. Gå in på målet om du vill se fördjupad information.`
    ]

    return (
      <TitleAndDescription title="Målen i Tomoyo" paragraphs={paragraphs} />
    )
  }

  const getGradesFromGoalList = (goals: goalsPageTypes.GoalsInGoalsList) => {
    const oneGoalPerGrade = goals.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj["grade"]).indexOf(obj["grade"]) === pos
    })

    return oneGoalPerGrade.map((goal: goalsPageTypes.GoalInGoalsList) => {
      return {
        id: `grade${goal.grade}`,
        label: goal.gradeLabel,
        value: goal.grade
      }
    })
  }

  const renderGoalList = () => (
    <React.Fragment>
      <Container maxWidth="lg">{renderTitleAndDescription()}</Container>

      <Grid item>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <StyledFilterWrapper>
            <Select
              selectLabel="Visa"
              selected={gradeFilterValue}
              options={gradeFilterOptions}
              onChangeCallback={handleFilterChange}
            />
          </StyledFilterWrapper>

          <StyledSelectPdfWrapper>
            <Button
              sx={{ mt: 1 }}
              onClick={handlePdfDownloadOpen}
              color="primary"
              variant="outlined"
            >
              Ladda ner målöversikt
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={downloadPdfButtonAnchor}
              keepMounted
              open={Boolean(downloadPdfButtonAnchor)}
              onClose={handlePdfDownloadClose}
            >
              {mockData.pdfOptions.map(option => (
                <MenuItem
                  onClick={() =>
                    handleDownloadPdfChange(option.value as string)
                  }
                  key={option.id}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </StyledSelectPdfWrapper>
        </Stack>
        <MobileOrDesktop>
          {{
            desktop: (
              <GoalsTable
                goals={filteredGoals}
                tableHeader={["Titel", "Status"]}
              />
            ),
            mobile: <GoalsList goals={filteredGoals} />
          }}
        </MobileOrDesktop>
      </Grid>
    </React.Fragment>
  )

  const renderLoader = () => (
    <StyledLoaderWrapper>
      <Loader color="green" delay={600} text="Hämtar mål" />
    </StyledLoaderWrapper>
  )

  return (
    <React.Fragment>
      {isGettingGoals ? renderLoader() : renderGoalList()}
    </React.Fragment>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => {
  return {
    goalsInGoalsList: selectGoalsForGoalsList(state),
    isGettingGoals: selectIsGettingGoals(state),
    gradeFilterValue: selectGoalFilter(state),
    numberOfStudentsWithProductInClassroom:
      selectStudentsWithProductLength(state)
  }
}

const mapDispatchToProps: DispatchProps = {
  getAllGoals: goalsActions.getAllGoalsRequest,
  setGoalFilter: goalsPageActions.setGoalFilter
}

const ConnectedGoals = connect(mapStateToProps, mapDispatchToProps)(Goals)

export default ConnectedGoals
