export const MODULE_NAME = "communication"
// export const MAX_AGE_OF_PENDING_REQUEST = 17500 //ms, sum of timeouts on backend is 15s
export const MAX_AGE_OF_PENDING_REQUEST = 2 * 60 * 1000 // (2 minutes), temporary fix to allow longer timeouts in backend
export const MAX_AGE_OF_PENDING_REQUEST_BEFORE_WARN = 1000 //if pending is older than this, warning will be logged to Sentry
export const CHECK_PENDING_REQUEST_AGE_INTERVAL = 200 //ms
export const SOCKET_CONNECTED_MESSAGE = "connected"

export enum communicationErrors {
  ErrorInternal,
  ErrorAddMemberFailed,
  ErrorDuplicateMember,
  ErrorPasswordEmpty,
  ErrorRemoveMemberFailed,
  ErrorInvalidPayload,
  ErrorClassroomNameMissing,
  ErrorUnauthorizedPasswordChange,
  ErrorAccessDenied,
  ErrorProductNotActivated,
  ErrorUserFoundButNotWithTeacherRole,
  ErrorClassroomMissing,
  ErrorRefreshTokenFailed
}
