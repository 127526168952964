import Paper, { PaperProps } from "@mui/material/Paper"
import Button from "../../components/Button"
import { Typography, IconButton, styled } from "@mui/material"

export const StyledPaper = styled(Paper as React.FC<PaperProps>)`
  margin-bottom: 1.5em;
`

export const StyledIncrementerButton = styled(IconButton)`
  color: black;
`

export const StyledPaperContent = styled("div")`
  padding: 1.5em;
  width: 100%;
`

export const StyledThresholdWrapper = styled("div")`
  padding: 21px;
`

export const StyledDescriptionText = styled(Typography)`
  margin: 1em 0 1em 0;
`

export const StyledIncrementerAmount = styled("div")`
  width: 6em;
  padding: 1em;
  text-align: center;
  border-radius: 5px;
  border: 1px solid gray;
`

export const StyledIncrementerWrapper = styled("div")`
  display: flex;
`

export const StyledPaperHeader = styled(Typography)`
  font-size: 1.5em;
`

export const StyledAudioRecorderContainer = styled("div")`
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const StyledRadioWrapper = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledRadioText = styled("span")`
  margin-left: 0.5em;
`

export const StyledPaperActions = styled("div")``

export const StyledButton = styled(Button)`
  ${props => props.theme.breakpoints.up("sm")} {
    margin-right: 0.5em;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    margin-bottom: 0.5em;
    margin-right: 0;
  }
`
export const StyledButtonContainer = styled("div")`
  display: flex;
  margin-top: 2em;
  margin-bottom: 4em;
  ${props => props.theme.breakpoints.down("xs")} {
    flex-direction: column;
  }
`
export const StyledErrorMessage = styled("div")`
  padding: 1.5em 0;
  color: ${props => props.theme.studli.status.error};
`
export const StyledForm = styled("div")``

export const StyledStickerWrapper = styled("div")`
  display: flex;
`

export const StyledSticker = styled(
  Paper as React.FC<PaperProps> & { color?: string }
)`
  width: 9em;
  padding: 0.5em;
  text-align: center;

  ${props =>
    props.color &&
    `
  border-radius: 50%;
  padding: 10px;
  width: 100px;
  height: 100px;
  background-color: ${props.color};
  margin-top: 1em;
  > img {
    width:100%;
    height:100%;
  }
  `}
`

export const StyledCurrentSticker = styled("img")`
  width: 7.5em;
  flex-basis: 7.5em;
`
export const StyledButtonWrapper = styled("div")`
  padding-left: 0.5em;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
`
export const StyledStickerLabel = styled("div")`
  text-align: center;
`
