/**
 * "Send", here means to server and "Receive" means from server.
 * Every Request expects a corresponding Response (requestId is used to match them).
 * Until a Response is received the Request is saved "pending".
 * An Event can be received at any time, as a result of some other client's action
 * (or as a result of a successful Request from this client).
 * Events are in past tense (updated, removed, added) informing that something happened to the data.
 * Responses can indicate Request failure, with an error.
 */

import shortid from "shortid"
import {
  ReceivePayload,
  PendingRequest,
  Response,
  CommunicationError,
  LogCommunicationWarning
} from "./types"
import { MODULE_NAME as MEMBERS_MODULE_NAME } from "../../pages/Members/constants"
import { MODULE_NAME as USERS_MODULE_NAME } from "../users/constants"
import { MODULE_NAME as CLASSROOM_MODULE_NAME } from "../classroom/constants"
import { MODULE_NAME as PRODUCTS_MODULE_NAME } from "../products/constants"
import { MODULE_NAME as GOALS_MODULE_NAME } from "../goals/constants"
import { MODULE_NAME as EXERCISES_MODULE_NAME } from "../exercises/constants"
import { MODULE_NAME as GOALS_PROGRESS_MODULE_NAME } from "../goalsProgress/constants"
import { MODULE_NAME as EXERCISES_PROGRESS_MODULE_NAME } from "../exercisesProgress/constants"
import { MODULE_NAME as ASSIGNMENTS_MODULE_NAME } from "../assignments/constants"
import { MODULE_NAME as BOOKS_MODULE_NAME } from "../book/constants"
import { MODULE_NAME as ASSIGNMENTS_PROGRESS_MODULE_NAME } from "../assignmentsProgress/constants"
import { MODULE_NAME as NOTIFICATIONS_MODULE_NAME } from "../notifications/constants"
import { MODULE_NAME as FORMATIVE_MODULE_NAME } from "../formative/constants"

import { SERVER_MESSAGE_ACTION as USERS_SERVER_MESSAGE_ACTION } from "../users/actions"
import { SERVER_MESSAGE_ACTION as MEMBERS_SERVER_MESSAGE_ACTION } from "../members/actions"
import { SERVER_MESSAGE_ACTION as CLASSROOM_SERVER_MESSAGE_ACTION } from "../classroom/actions"
import { SERVER_MESSAGE_ACTION as PRODUCTS_SERVER_MESSAGE_ACTION } from "../products/actions"
import { SERVER_MESSAGE_ACTION as GOALS_SERVER_MESSAGE_ACTION } from "../goals/actions"
import { SERVER_MESSAGE_ACTION as EXERCISES_SERVER_MESSAGE_ACTION } from "../exercises/actions"
import { SERVER_MESSAGE_ACTION as GOALS_PROGRESS_SERVER_MESSAGE_ACTION } from "../goalsProgress/actions"
import { SERVER_MESSAGE_ACTION as EXERCISES_PROGRESS_SERVER_MESSAGE_ACTION } from "../exercisesProgress/actions"
import { SERVER_MESSAGE_ACTION as ASSIGNMENTS_SERVER_MESSAGE_ACTION } from "../assignments/actions"
import { SERVER_MESSAGE_ACTION as BOOKS_SERVER_MESSAGE_ACTION } from "../book/actions"
import { SERVER_MESSAGE_ACTION as ASSIGNMENTS_PROGRESS_SERVER_MESSAGE_ACTION } from "../assignmentsProgress/actions"
import { SERVER_MESSAGE_ACTION as NOTIFICATIONS_SERVER_MESSAGE_ACTION } from "../notifications/actions"
import { SERVER_MESSAGE_ACTION as FORMATIVE_SERVER_MESSAGE_ACTION } from "../formative/actions"
import { createAction } from "../../shared/tools/redux"

export const SAVE_REQUEST = "SAVE_REQUEST"
export const REMOVE_REQUEST = "REMOVE_REQUEST"

export const SET_COMMUNICATION_ERROR = "SET_COMMUNICATION_ERROR"
export const RESET_COMMUNICATION_ERROR = "RESET_COMMUNICATION_ERROR"

export enum UI {
  LOG_COMMUNICATION_WARNING = "LOG_COMMUNICATION_WARNING"
}

export const RECEIVE_DATA = "RECEIVE_DATA"

export const SEND_REQUEST = "SEND_REQUEST"
export const RECEIVE_RESPONSE = "RECEIVE_RESPONSE"
export const RECEIVE_EVENT = "RECEIVE_EVENT"

export const receiveResponse = createAction<Response>("RECEIVE_RESPONSE")

export const receiveEvent = createAction<ReceivePayload>("RECEIVE_EVENT")

export const receiveOnSocketChannel =
  createAction<ReceivePayload>("RECEIVE_DATA")

const getTimestamp = () => Date.now()
const createRequestId = () => shortid.generate()

/**
 * Send request to server. Action creator for action SEND_REQUEST,
 * its payload will have the "type", "action" and "requestId" according to the frontend-backend API.
 * The type and action are specified, requestId is created by this function.
 * The payload is optional and used for transporting data to the server.
 *
 * @param type {string} - members, users, classroom, etc.
 * @param action {string} - list, add, remove, update, etc.
 * @param data {object} - Any optional data if needed to be sent to server. (search-criteria in a search request for instance)
 */
export const sendRequest = (
  type:
    | typeof MEMBERS_MODULE_NAME
    | typeof USERS_MODULE_NAME
    | typeof PRODUCTS_MODULE_NAME
    | typeof CLASSROOM_MODULE_NAME
    | typeof GOALS_MODULE_NAME
    | typeof EXERCISES_MODULE_NAME
    | typeof GOALS_PROGRESS_MODULE_NAME
    | typeof EXERCISES_PROGRESS_MODULE_NAME
    | typeof ASSIGNMENTS_MODULE_NAME
    | typeof BOOKS_MODULE_NAME
    | typeof ASSIGNMENTS_PROGRESS_MODULE_NAME
    | typeof NOTIFICATIONS_MODULE_NAME
    | typeof FORMATIVE_MODULE_NAME,
  action:
    | MEMBERS_SERVER_MESSAGE_ACTION
    | USERS_SERVER_MESSAGE_ACTION
    | CLASSROOM_SERVER_MESSAGE_ACTION
    | PRODUCTS_SERVER_MESSAGE_ACTION
    | GOALS_SERVER_MESSAGE_ACTION
    | EXERCISES_SERVER_MESSAGE_ACTION
    | GOALS_PROGRESS_SERVER_MESSAGE_ACTION
    | EXERCISES_PROGRESS_SERVER_MESSAGE_ACTION
    | ASSIGNMENTS_SERVER_MESSAGE_ACTION
    | BOOKS_SERVER_MESSAGE_ACTION
    | ASSIGNMENTS_PROGRESS_SERVER_MESSAGE_ACTION
    | NOTIFICATIONS_SERVER_MESSAGE_ACTION
    | FORMATIVE_SERVER_MESSAGE_ACTION,

  data?: any,
  echo?: any
) => {
  const payload: PendingRequest = {
    type,
    action,
    requestedAt: getTimestamp(),
    requestId: createRequestId(),
    payload: data,
    echo: echo
  }

  return {
    type: SEND_REQUEST,
    payload
  }
}

/**
 * Saves the pending request in the state for use when receiving a response.
 * RequestId is used to match, payload describes the request, its type and action,
 * used when dispatching the received response so that the corresponding module can react.
 *
 * @param pendingRequest {object} - the pending request.
 */
export const saveRequest = createAction<PendingRequest>("SAVE_REQUEST")

/**
 * Removes a pending request from the "pending requests list".
 *
 * @param requestId {number} - Id of the pending request to be removed.
 */
export const removeRequest = createAction<string>("REMOVE_REQUEST")

/**
 * When backend responds with an error, or an error is detected related to communication with the backend this function updates the state with it.
 *
 * @param error {object} - Object describing the error
 */
export const setCommunicationError = createAction<CommunicationError>(
  "SET_COMMUNICATION_ERROR"
)

/**
 * Log to Sentry when it takes too long between a request and a response.
 *
 * @param error {object} - Object describing the error/warning
 */
export const logCommunicationWarning = (
  error: CommunicationError
): LogCommunicationWarning => {
  error.reporter = "Frontend"

  return {
    type: UI.LOG_COMMUNICATION_WARNING,
    payload: error
  }
}

/**
 * Resets the communication error
 */
export const resetCommunicationError = createAction("RESET_COMMUNICATION_ERROR")
