import RLink from "redux-first-router-link"
import ListItem from "@mui/material/ListItem"
import ListItemIcon, { ListItemIconProps } from "@mui/material/ListItemIcon"
import { Theme } from "@mui/material/styles"
import { Typography, styled } from "@mui/material"

export const StyledListItem = styled(ListItem)`
  padding: 0.25rem 0 0.25rem 1.25rem;
  border-radius: 4px;

  &.selected,
  &.Mui-selected,
  &.Mui-selected:hover {
    color: white;
    background: black;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
    color: rgb(35, 39, 42);
  }
`

export const StyledNavigationListItem = styled(RLink)`
  text-decoration: none;
  color: ${props => (props.theme as Theme).palette.text.primary};
`

export const StyledDisablingSpan = styled("li")`
  display: block;
  pointer-events: ${(props: { disabled?: boolean }) =>
    props.disabled ? "none" : "auto"};
  padding: 0.25rem 0;
`

// TODO:
// Unfortunately the below doesn't work.
// export const StyledListItem = styled(ListItem)`
//   color: green;

//   & .mui-selected: {
//     color: ${props => props.theme.palette.action.selected};
//   }
// `
// So...:
// Yet another way of doing this stuff, apparently new to MUI v4. Found here:
// https://github.com/mui-org/material-ui/issues/13672
//
// To use theme color, import it:
// import theme from "../../../shared/styles/theme"
// and use like so:
// ,

export const StyledListItemIcon = styled(
  ListItemIcon as React.FC<ListItemIconProps>
)`
  & > svg {
    width: 32px;
  }
  color: inherit;
`

export const ListText = styled(Typography)`
  font-weight: inherit;
`
