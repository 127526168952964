import React from "react"
import { StyledFormativeEndQuestionContainer } from "./StyledFormativeEndQuestion"
import { StyledChapterText } from "../FormativeQuestionsListItem/StyledFormativeQuestionsListItem"
import AudioPlayer from "../AudioPlayer"
import HtmlRenderer from "../HtmlRenderer"

type Props = {
  endQuestion: { text: string; audio: string }
}

const FormativeEndQuestion = ({ endQuestion }: Props) => {
  return (
    <StyledFormativeEndQuestionContainer>
      <StyledChapterText>Slutfråga</StyledChapterText>
      {endQuestion.text && <HtmlRenderer html={endQuestion.text} />}
      {endQuestion.audio && (
        <AudioPlayer src={endQuestion.audio} label="Ljudklipp" />
      )}
    </StyledFormativeEndQuestionContainer>
  )
}

export default FormativeEndQuestion
