import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import StyledTextField from "../StyledTextField"
import FormikTextEditor from "../FormikTextEditor"
import StyledButton from "../Button"
import {
  StyledForm,
  StyledDialogActions,
  StyledErrorMessage,
  StyledSelectBookContainer,
  HelpText,
  StyledRecorderContainer,
  StyledSubHeader,
  StyledPagesTextField,
  StyledCloseIcon,
  StyledTableOfContentTitle,
  StyledDialogTitle,
  StyledSingleBookName,
  StyledTextSpan,
  StyledSelect
} from "./StyledAssignmentContentForm"
import { ContentAdd } from "../CreateAssignmentForm/types"
import { FormikProps, FormikValues, ErrorMessage } from "formik"
import { MenuItem, IconButton, SelectChangeEvent } from "@mui/material"
import CustomTreeView from "../TreeView"
import ExerciseList from "../TreeViewList"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import { isParent } from "../TreeView/common/helpers"
import sanitizeHtml from "sanitize-html"
import {
  BookTocId,
  ChapterOrPage,
  BookPageNode,
  BookChapterNode
} from "../../modules/book/types"
import { Product } from "../../modules/products/types"
import { selectClassroomFeatures } from "../../modules/classroom/selectors"
import { Features } from "../../modules/classroom/types"
import AudioRecorder from "../AudioRecorder"
import { buildPathToPage } from "../../pages/CreateEditAssignment/common/helpers"
import { selectBooksToc } from "../../modules/book/selectors"
import { Nodes } from "../TreeView/TreeTypes"
import { StyledNumberOfPagesText } from "../BookPagesListItem/StyledBookPagesListItem"

type OwnProps = {
  contentType: ContentAdd
  handleCancel: Function
  setIsDirty: Function
  getEmptyAssignmentTask: Function
  addMultipleAssignmentTasks: Function
  activeProduct: Product
  isEditingTask: boolean
  currentBookSelected: BookChapterNode | null
  setCurrentBookSelected: (book: BookChapterNode) => void
}

type Props = OwnProps & FormikProps<FormikValues>

const AssignmentContentForm = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  touched,
  contentType,
  setFieldValue,
  handleCancel,
  dirty,
  setIsDirty,
  isEditingTask,
  currentBookSelected,
  setCurrentBookSelected
}: Props) => {
  const onTextEditorBlur = (name: string, value: any) => {
    if (typeof values[name] === "string") {
      setFieldValue(name, value)

      return
    }
    setFieldValue(name, { ...values[name], text: value })
  }

  const setAudioForForm = async (
    filePath: string | undefined,
    name: string
  ) => {
    if (filePath) {
      setFieldValue(name, { ...values[name], audio: filePath })

      return
    }
    setFieldValue(name, { ...values[name], audio: "" })
  }

  const renderAudioRecorder = (type: string) => {
    const current = values[type]
    const currentAudio = current.audio ? current.audio : ""

    return (
      <AudioRecorder
        type="standalone"
        setAudioForForm={setAudioForForm}
        formType={type}
        editAudio={currentAudio}
        conversation={false}
      />
    )
  }
  const [expandedNodes, setExpandedNodes] = useState<BookTocId[]>([])
  const [checkedNodes, setCheckedNodes] = useState<BookTocId[]>([])
  const [currentMobileNodeChosen, setCurrentMobileNodeChosen] = useState<any>(
    []
  )
  const [currentMobileBreadcrumbs, setCurrentMobileBreadcrumbs] = useState<any>(
    []
  )
  const [selectFromBook, setSelectFromBook] = useState<boolean>(false)

  const classroomFeatures: Features = useSelector(selectClassroomFeatures)
  const booksToc = useSelector(selectBooksToc)

  useEffect(() => {
    if (isEditingTask && contentType === "pagesInBook" && booksToc.length) {
      const bookIdWithPage = values.link
        .split("/")
        .find((splitValue: string) => splitValue.includes("#"))
      if (bookIdWithPage) {
        const bookId = bookIdWithPage.split("#")[0]
        const selectedBook = booksToc.find(toc => toc.bookId === bookId)
        setCurrentBookSelected(selectedBook ? selectedBook : booksToc[0])
        return
      }
    }

    if (!currentBookSelected && booksToc.length) {
      setCurrentBookSelected(booksToc[0])
    }

    if (!currentMobileNodeChosen.length && booksToc.length) {
      setCurrentMobileNodeChosen(booksToc)
    }
    // eslint-disable-next-line
  }, [booksToc])

  useEffect(() => {
    if (dirty) {
      setIsDirty(true)
    }
  }, [dirty, setIsDirty])

  useEffect(() => {
    if (values.type !== "book") {
      return
    }

    if (!values.link) {
      return
    }
    const arr = values.link.split("/")
    const idAndPage = arr[arr.length - 1]
    const [id] = idAndPage.split("#")

    setFieldValue("book", id)
  }, [values.link, values.type, setFieldValue])

  const onCheckboxClicked = (event: Event, node: ChapterOrPage) => {
    event.stopPropagation()
    const nodeIsAlreadyChecked = checkedNodes.some(
      nodeId => node.uniqueId === nodeId
    )
    const nodeIds = traverseForIdsBelowNode(node)
    let newCheckedNodes = [...checkedNodes]
    if (nodeIsAlreadyChecked) {
      newCheckedNodes = newCheckedNodes.filter(
        exerciseId => !nodeIds.some(nodeId => nodeId === exerciseId)
      )
    } else {
      newCheckedNodes = [...newCheckedNodes, ...nodeIds]
    }
    setCheckedNodes(newCheckedNodes)
  }

  const traverseForIdsBelowNode = (node: ChapterOrPage) => {
    const nodeIds: BookTocId[] = []
    traverse(node, nodeIds)

    return nodeIds
  }

  const traverse = (node: ChapterOrPage, nodeIds: BookTocId[]) => {
    nodeIds.push(node.uniqueId)
    if (isParent(node) && node.children) {
      node.children.forEach((child: any) => {
        traverse(child, nodeIds)
      })
    }
  }

  const onMobileRowClick = (node: ChapterOrPage) => {
    if (isParent(node)) {
      const newBreadcrumbs = [...currentMobileBreadcrumbs, node]

      setCurrentMobileNodeChosen(node.children)
      setCurrentMobileBreadcrumbs(newBreadcrumbs)
    } else {
      onBookPageClick(node as BookPageNode)
    }
  }

  const onBreadcrumbClick = (node: any) => {
    if (currentMobileBreadcrumbs.length === 1) {
      setCurrentMobileNodeChosen(booksToc)
      setCurrentMobileBreadcrumbs([])

      return
    }
    const newBreadcrumbs = [...currentMobileBreadcrumbs]
    newBreadcrumbs.splice(currentMobileBreadcrumbs.length - 1)
    setCurrentMobileNodeChosen(node.children)
    setCurrentMobileBreadcrumbs(newBreadcrumbs)
  }

  const onBookPageClick = (page: ChapterOrPage) => {
    const firstPage = findFirstPage(page)
    const lastPage = findLastPage(page)
    const pages =
      firstPage !== lastPage ? `${firstPage}-${lastPage}` : firstPage
    setFieldValue("pages", pages)

    const sanitizedTitle = sanitizeHtml(page.title)
    setFieldValue("title", sanitizedTitle.replace(/<\/?[^>]+(>|$)/g, ""))
    setFieldValue("book", page.bookId)
    setFieldValue("exercisePath", getPathForPage(page.uniqueId))

    setSelectFromBook(false)
  }

  const handleChangeBook = (event: SelectChangeEvent<unknown>) => {
    const selectedBook = booksToc.find(toc => toc.bookId === event.target.value)
    if (selectedBook && selectedBook.children) {
      if (selectedBook.children.length > 1) {
        setFieldValue("pages", "")
        setFieldValue("title", "")
        setFieldValue("book", "")
        setFieldValue("exercisePath", "")
        setCurrentBookSelected(selectedBook)
      } else {
        const [chapterNode] = selectedBook.children as unknown as BookPageNode[]
        setIsDirty(true) // Needed to trigger the formik validation or some other black magic
        setFieldValue(
          "pages",
          chapterNode.range ? `1-${chapterNode.range}` : "1"
        )
        setFieldValue("title", chapterNode.title)
        setFieldValue("book", "")
        setFieldValue("exercisePath", "")
        setCurrentBookSelected(selectedBook)
      }
    }

    if (selectedBook && !selectedBook.children) {
      setFieldValue(
        "pages",
        selectedBook.lastPage > 1 ? `1-${selectedBook.lastPage}` : "1"
      )
      setFieldValue("title", selectedBook.title)
      setFieldValue("book", "")
      setFieldValue("exercisePath", "")
      setCurrentBookSelected(selectedBook)
    }
  }

  const renderPagesInBook = () => {
    return (
      <>
        <>
          {selectFromBook && (
            <MobileOrDesktop>
              {{
                desktop: (
                  <CustomTreeView
                    type={"pages"}
                    nodes={
                      currentBookSelected
                        ? (currentBookSelected.children as Nodes)
                        : []
                    }
                    expandedNodes={expandedNodes}
                    setExpandedNodes={setExpandedNodes}
                    topNodesNotExpanded={[]}
                    setTopNodesNotExpanded={() => null}
                    checkedNodes={checkedNodes}
                    onCheckboxClicked={onCheckboxClicked}
                    exercisesProgress={[]}
                    onProgressDialogOpen={() => null}
                    selectable={false}
                    hideStatus={true}
                    onItemClick={onBookPageClick}
                  />
                ),
                mobile: (
                  <ExerciseList
                    nodes={currentMobileNodeChosen}
                    onMobileRowClick={onMobileRowClick}
                    checkedNodes={checkedNodes}
                    onCheckboxClicked={onCheckboxClicked}
                    currentMobileBreadcrumbs={currentMobileBreadcrumbs}
                    onBreadcrumbClick={onBreadcrumbClick}
                    exercisesProgress={[]}
                    onProgressDialogOpen={() => null}
                    hideStatus={true}
                    type="pages"
                  />
                )
              }}
            </MobileOrDesktop>
          )}
          {!selectFromBook && renderPagesInBookForm()}
        </>
      </>
    )
  }

  const renderPagesInBookForm = () => {
    return (
      <StyledForm>
        {/*  <Typography variant="subtitle1">
          Välj själv sidor i boken. Ange en sida genom att skriva sidnumret, t
          ex 12. Ange flera sidor genom att skriva t ex 12-14.
        </Typography> */}
        <StyledSelectBookContainer>
          {booksToc.length > 1 ? (
            <StyledSelect
              id="book-menu"
              value={currentBookSelected ? currentBookSelected.bookId : ""}
              onChange={handleChangeBook}
            >
              {booksToc.map(toc => (
                <MenuItem
                  key={toc.bookId}
                  value={toc.bookId}
                  sx={{ whiteSpace: "normal", justifyContent: "space-between" }}
                >
                  {toc.title}{" "}
                  {!toc.children && (
                    <StyledNumberOfPagesText>
                      ({toc.lastPage} sidor)
                    </StyledNumberOfPagesText>
                  )}
                </MenuItem>
              ))}
            </StyledSelect>
          ) : (
            <>
              {currentBookSelected && (
                <StyledSingleBookName variant="body1">
                  {currentBookSelected.title}{" "}
                  {!currentBookSelected.children && (
                    <StyledNumberOfPagesText>
                      ({currentBookSelected.lastPage} sidor)
                    </StyledNumberOfPagesText>
                  )}
                </StyledSingleBookName>
              )}
            </>
          )}

          {currentBookSelected &&
            currentBookSelected.children &&
            currentBookSelected.children.length > 1 && (
              <StyledButton
                onClick={() => setSelectFromBook(true)}
                variant="outlined"
                color="primary"
              >
                Innehållsförteckning
              </StyledButton>
            )}
        </StyledSelectBookContainer>
        <StyledTextField
          name="title"
          margin="normal"
          variant="outlined"
          inputProps={{ "aria-label": "Titel" }}
          fullWidth
          value={values.title}
          label={isEditingTask ? "Ändra titel" : "Ange titel"}
          autoComplete="off"
          onChange={handleChange}
          helperText={touched["title"] && errors["title"]}
          error={Boolean(errors["title"] && touched["title"])}
        />
        <StyledPagesTextField
          name="pages"
          margin="normal"
          variant="outlined"
          fullWidth
          value={values.pages}
          label={isEditingTask ? "Ändra sidor" : "Ange sidor"}
          autoComplete="off"
          onChange={handleChange}
          helperText={touched["pages"] && errors["pages"]}
          error={Boolean(errors["pages"] && touched["pages"])}
        />
        {!classroomFeatures.some(feature => feature === "FN") && (
          <>
            <FormikTextEditor
              name="instruction"
              value={values.instruction.text}
              ariaLabel="Instruktion"
              marginTop
              placeholder={"Här kan du skriva en instruktion till eleverna"}
              onBlurHandler={onTextEditorBlur}
            />
            <StyledRecorderContainer>
              {renderAudioRecorder("instruction")}
            </StyledRecorderContainer>
            <StyledErrorMessage>
              <ErrorMessage name="instruction">
                {msg => <div>{msg}</div>}
              </ErrorMessage>
            </StyledErrorMessage>
          </>
        )}
      </StyledForm>
    )
  }

  const renderFromBookForm = () => {
    return (
      <StyledForm>
        <StyledTextField
          name="title"
          margin="normal"
          variant="outlined"
          inputProps={{ "aria-label": "Titel" }}
          fullWidth
          value={values.title}
          label={isEditingTask ? "Titel" : "Ange titel"}
          autoComplete="off"
          onChange={handleChange}
          helperText={touched["title"] && errors["title"]}
          error={Boolean(errors["title"] && touched["title"])}
        />
        {!classroomFeatures.some(feature => feature === "FN") && (
          <>
            <FormikTextEditor
              name="instruction"
              ariaLabel="Instruktion"
              value={values.instruction.text}
              placeholder={"Här kan du skriva en instruktion till eleverna"}
              onBlurHandler={onTextEditorBlur}
            />
            <StyledRecorderContainer>
              {renderAudioRecorder("instruction")}
            </StyledRecorderContainer>
            {errors["instruction"] && (
              <StyledErrorMessage>
                <ErrorMessage name="instruction" />
              </StyledErrorMessage>
            )}
          </>
        )}
      </StyledForm>
    )
  }

  const renderExternalLinkForm = () => {
    return (
      <StyledForm>
        <StyledTextField
          name="title"
          margin="normal"
          variant="outlined"
          inputProps={{ "aria-label": "Titel" }}
          fullWidth
          value={values.title}
          autoComplete="off"
          label={isEditingTask ? "Ändra titel" : "Ange titel"}
          placeholder="Ange länkens titel"
          onChange={handleChange}
          helperText={touched["title"] && errors["title"]}
          error={Boolean(errors["title"] && touched["title"])}
        />
        <FormikTextEditor
          name="instruction"
          ariaLabel="Instruktion"
          value={values.instruction.text}
          placeholder={"Här kan du skriva en instruktion till eleverna"}
          onBlurHandler={onTextEditorBlur}
        />
        <StyledRecorderContainer>
          {renderAudioRecorder("instruction")}
        </StyledRecorderContainer>
        <StyledErrorMessage>
          <ErrorMessage name="instruction" />
        </StyledErrorMessage>
        <StyledTextField
          name="link"
          margin="normal"
          inputProps={{ "aria-label": "Länk" }}
          variant="outlined"
          fullWidth
          value={values.link}
          autoComplete="off"
          label="Länk"
          placeholder={"Klistra in länken från din webbläsare här."}
          onChange={handleChange}
          helperText={touched["link"] && errors["link"]}
          error={Boolean(errors["link"] && touched["link"])}
        />
      </StyledForm>
    )
  }

  const renderOpenQuestionForm = () => {
    return (
      <StyledForm>
        <StyledTextField
          name="title"
          margin="normal"
          variant="outlined"
          fullWidth
          inputProps={{ "aria-label": "Titel" }}
          value={values.title}
          label={isEditingTask ? "Ändra titel" : "Titel"}
          autoComplete="off"
          placeholder="Skriv in frågans titel"
          onChange={handleChange}
          helperText={touched["title"] && errors["title"]}
          error={Boolean(errors["title"] && touched["title"])}
        />
        <FormikTextEditor
          name="question"
          ariaLabel="Instruktion"
          value={values.question.text}
          placeholder={"Här kan du skriva in frågan till eleverna"}
          onBlurHandler={onTextEditorBlur}
        />
        <StyledRecorderContainer>
          {renderAudioRecorder("question")}
        </StyledRecorderContainer>
        <StyledErrorMessage>
          <ErrorMessage name="question" />
        </StyledErrorMessage>
        <FormikTextEditor
          name="answer"
          ariaLabel="Svar"
          value={values.answer}
          placeholder={
            "Exempelsvar – Här kan du skriva ett exempelsvar som du kan ha hjälp av när du ska rätta frågan. Exempelsvaret visas inte för eleven, bara för läraren i klassrummet."
          }
          onBlurHandler={onTextEditorBlur}
        />
        <StyledRecorderContainer>
          <HelpText variant="body2">
            Bara den som är lärare i klassrummet kan se exempelsvaret.
          </HelpText>
        </StyledRecorderContainer>
        <StyledErrorMessage>
          <ErrorMessage name="answer" />
        </StyledErrorMessage>
      </StyledForm>
    )
  }

  const getPathForPage = (pageId: BookPageNode["uniqueId"]) => {
    let pagePath = ""
    for (let i = 0; i < booksToc.length; i++) {
      const path = buildPathToPage(booksToc[i], pageId)
      if (path.length) {
        path.splice(path.length - 1, 1)
        pagePath = path.map((page: ChapterOrPage) => page.title).join(" / ")
        break
      }
    }

    return pagePath
  }

  const submitFormHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  const cancelFormHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleCancel()
  }

  const getDialogTitle = (contentType: ContentAdd) => {
    switch (contentType) {
      case "fromBook":
        return isEditingTask ? "Redigera uppgift" : "Innehåll från boken"
      case "openQuestion":
        return isEditingTask ? "Redigera öppen fråga" : "Öppen fråga"
      case "pagesInBook":
        if (selectFromBook) {
          return (
            <StyledTableOfContentTitle>
              <IconButton
                aria-label="Stäng sidomenyn"
                onClick={() => setSelectFromBook(false)}
                style={{ padding: "0" }}
              >
                <StyledCloseIcon />
              </IconButton>
              <StyledTextSpan variant="h6">
                Välj från innehållsförteckning
              </StyledTextSpan>
            </StyledTableOfContentTitle>
          )
        }

        return isEditingTask
          ? "Redigera sidor i läromedlet"
          : "Sidor i läromedlet"
      case "externalLink":
        return isEditingTask ? "Redigera extern länk" : "Extern länk"
      default:
        return "Lägg till innehåll"
    }
  }

  const getDialogSubTitle = (contentType: ContentAdd) => {
    switch (contentType) {
      case "fromBook":
        return isEditingTask
          ? ""
          : "Öppna mapparna för att se innehållet i läromedlet. Markera en eller flera delar för att lägga till dem till uppdraget."
      case "openQuestion":
        return isEditingTask
          ? "Vill du ändra något i titel, fråga eller exempelsvar?"
          : "Lägg till en egen fråga till uppdraget. Eleverna kan svara på din fråga genom att skriva eller spela in sitt svar. Om det är viktigt, ange i frågan vilket sätt du vill att de svarar."
      case "pagesInBook":
        if (selectFromBook) {
          return ""
        }

        return isEditingTask
          ? "Vill du ändra titel? Vill du ändra vilka sidor i läromedlet som ska ingå? Du kan antingen välja ur läromedlets innehållsförteckning eller ange sidor själv."
          : "Välj mellan att lägga till innehåll från läromedlets innehållsförteckning eller ange sidor själv. "
      default:
        return ""
    }
  }

  return (
    <>
      <StyledDialogTitle id="choose-tasks-dialog-title">
        {getDialogTitle(contentType)}
        <StyledSubHeader>{getDialogSubTitle(contentType)}</StyledSubHeader>
      </StyledDialogTitle>
      {contentType === "pagesInBook" && renderPagesInBook()}
      {contentType === "fromBook" && renderFromBookForm()}
      {contentType === "externalLink" && renderExternalLinkForm()}
      {contentType === "openQuestion" && renderOpenQuestionForm()}

      {!selectFromBook && (
        <StyledDialogActions>
          <StyledButton onClick={cancelFormHandler}>Avbryt</StyledButton>
          <StyledButton onClick={submitFormHandler} color="primary">
            {isEditingTask ? "Spara" : "Lägg till"}
          </StyledButton>
        </StyledDialogActions>
      )}
    </>
  )
}

export const isChapter = (node: ChapterOrPage): node is BookChapterNode => {
  if ((node as BookChapterNode).children) {
    return true
  }

  return false
}

const findFirstPage = (page: ChapterOrPage): any => {
  if (!isChapter(page)) {
    return page.page
  }
  return findFirstPage(page.children[0])
}

const findLastPage = (page: ChapterOrPage): any => {
  if (!isChapter(page)) {
    if (page.range) {
      return page.page + page.range - 1
    }
    return page.page
  }
  return findLastPage(page.children[page.children.length - 1])
}

export default AssignmentContentForm
