import moment from "moment"
import { RootState } from "../store"
import { Notifications } from "./types"

export const selectAllNotifications = (state: RootState): Notifications =>
  state.notifications.notifications.sort((a, b) => {
    const first = moment(a.updatedAt)
    const second = moment(b.updatedAt)

    if (first.isBefore(second)) {
      return 1
    }

    if (first.isAfter(second)) {
      return -1
    }

    return 0
  })
