import {
  AssignmentWithStatus,
  Assignment,
  CalculatedAssignmentStatuses,
  Assignments
} from "../../../modules/assignments/types"
import moment, { Moment } from "moment"
import { statuses, menuItemActions } from "../constants"
import { Members, Member } from "../../../modules/members/types"
import {
  AssignmentsProgress,
  AssignmentsTaskProgress
} from "../../../modules/assignmentsProgress/types"

type Progress = {
  done: Members
  notStarted: Members
  inProgress: Members
  approved: Members
}

export const calculatedStatusTag = (
  calculatedStatus: AssignmentWithStatus["calculatedStatus"],
  startDate: Moment,
  endDate: Moment | null
) => {
  const currentDate = moment()
  switch (calculatedStatus) {
    case "ongoing":
      if (endDate) {
        const ongoingFor = moment(endDate).diff(currentDate, "days")
        if (ongoingFor === 0) {
          return `Pågående - Slutar idag`
        }

        return `Pågående - ${Math.abs(ongoingFor)} ${
          ongoingFor > 1 ? "dagar" : "dag"
        } kvar`
      } else {
        return `Pågående - Inget slutdatum`
      }
    case "finished":
      return "Avslutat"
    case "coming":
      let startsIn = 0

      startsIn = moment(startDate).diff(moment(currentDate), "days")
      if (startsIn === 0) {
        return `Startar imorgon`
      }

      return `Startar om ${startsIn} ${startsIn > 1 ? "dagar" : "dag"}`
    case "passed":
      let passedFor = 0
      if (endDate) {
        passedFor = currentDate.diff(moment(endDate), "days")
      }
      return passedFor === 0
        ? "Försenat"
        : `${passedFor} ${passedFor > 1 ? "dagar" : "dag"} försenat`
    default:
      return statuses[calculatedStatus]
  }
}

const OPTIONS_PER_STATUS = {
  [menuItemActions.START]: ["coming"],
  [menuItemActions.FINISH]: ["ongoing", "passed"],
  [menuItemActions.EDIT]: ["coming", "passed", "draft", "ongoing"],
  [menuItemActions.CREATE_COPY]: [
    "coming",
    "ongoing",
    "passed",
    "finished",
    "draft"
  ],
  [menuItemActions.ARCHIVE]: ["finished"],
  [menuItemActions.ACTIVATE]: ["archived"],
  [menuItemActions.REMOVE]: ["coming", "archived", "draft"]
}

export const getMenuOptions = (
  menuItemClick: Function,
  assignmentId: AssignmentWithStatus["id"],
  calculatedStatus: AssignmentWithStatus["calculatedStatus"],
  isPublic: boolean,
  isCreator: boolean
) => {
  const OPTIONS: { [key: string]: any } = {
    start: {
      action: () => menuItemClick(menuItemActions.START, assignmentId),
      option: "Starta"
    },
    edit: {
      action: () => menuItemClick(menuItemActions.EDIT, assignmentId),
      option: "Redigera"
    },
    createCopy: {
      action: () => menuItemClick(menuItemActions.CREATE_COPY, assignmentId),
      option: "Skapa kopia"
    },
    archive: {
      action: () => menuItemClick(menuItemActions.ARCHIVE, assignmentId),
      option: "Arkivera"
    },
    remove: {
      action: () => menuItemClick(menuItemActions.REMOVE, assignmentId),
      option: "Ta bort"
    },
    finish: {
      action: () => menuItemClick(menuItemActions.FINISH, assignmentId),
      option: "Avsluta"
    },
    activate: {
      action: () => menuItemClick(menuItemActions.ACTIVATE, assignmentId),
      option: "Aktivera"
    }
  } as const

  const whichOptions = Object.keys(OPTIONS_PER_STATUS).filter(perStatus =>
    OPTIONS_PER_STATUS[perStatus].some(status => status === calculatedStatus)
  )
  let filteredOptions = whichOptions.map(option => OPTIONS[option])

  if (!isCreator) {
    filteredOptions = filteredOptions.filter(
      option => option.option !== "Ta bort"
    )
  }

  if (isCreator) {
    filteredOptions.unshift({
      action: () => menuItemClick(menuItemActions.SHARE, assignmentId),
      option: isPublic ? "Gör privat" : "Dela"
    })
  }

  return filteredOptions
}

export const calculateStatus = (
  status: Assignment["status"],
  startDate: Moment,
  endDate: Moment | null,
  isAdapt: boolean
): CalculatedAssignmentStatuses => {
  switch (status) {
    case "archived":
      return status
    case "published":
      const compareDate = moment()
      if (!endDate) {
        endDate = moment().add(100000, "days")
      }
      if (compareDate.isBetween(moment(startDate), moment(endDate))) {
        return "ongoing"
      }
      if (compareDate.isBefore(moment(startDate))) {
        return "coming"
      }
      if (compareDate.isAfter(moment(endDate))) {
        return isAdapt ? "finished" : "passed"
      }

      return "ongoing"
    case "draft":
      return "draft"
    case "finished":
      return "finished"
  }
}

export const getProgressAndMemberForAdapt = (
  assignment: Assignment,
  assignmentsProgress: AssignmentsProgress,
  members: Members
) => {
  const membersFromId = filterUndefinedMembers(members, assignment.participants)
  return membersFromId.reduce(
    (acc: Progress, curr) => {
      const progress = assignmentsProgress.find(
        p => p.assignmentId === assignment.id && p.studliId === curr.studliId
      )
      if (progress && progress.submittedAt) {
        return { ...acc, done: [...acc.done, curr] }
      }
      if (progress && !progress.submittedAt) {
        return { ...acc, inProgress: [...acc.inProgress, curr] }
      }
      if (!progress) {
        return { ...acc, notStarted: [...acc.notStarted, curr] }
      }
      return acc
    },
    { done: [], inProgress: [], notStarted: [], approved: [] }
  )
}

const filterUndefinedMembers = (members: Members, memberIds: number[]) => {
  return memberIds
    .map(member => getMemberById(member, members))
    .filter(possiblyUndefined => possiblyUndefined !== undefined) as Members
}

const getProgressionAndMember = (
  assignment: Assignment,
  assignmentsProgress: AssignmentsProgress,
  assignmentsTaskProgress: AssignmentsTaskProgress,
  members: Members
) => {
  const membersFromId = filterUndefinedMembers(members, assignment.participants)
  const done: Members = []
  const inProgress: Members = []
  const notStarted: Members = []
  const approved: Members = []

  membersFromId.forEach(member => {
    if (member) {
      if (
        assignmentsProgress.some(
          assignmentProgress =>
            assignmentProgress.studliId === member.studliId &&
            assignmentProgress.assignmentId === assignment.id &&
            assignmentProgress.rejectedAt === null &&
            !!assignmentProgress.approvedAt
        )
      ) {
        approved.push(member)
      } else if (
        assignmentsProgress.some(
          assignmentProgress =>
            assignmentProgress.studliId === member.studliId &&
            assignmentProgress.assignmentId === assignment.id &&
            assignmentProgress.rejectedAt === null
        )
      ) {
        done.push(member)
      } else if (
        assignmentsTaskProgress.some(
          assignmentTaskProgress =>
            assignmentTaskProgress.studliId === member.studliId &&
            assignmentTaskProgress.assignmentId === assignment.id
        )
      ) {
        inProgress.push(member)
      } else {
        notStarted.push(member)
      }
    }
  })

  return { done, inProgress, notStarted, approved }
}

export const createAssignmentsArrayWithStatusAndProgress = (
  assignmentsToCalculate: Assignments,
  assignmentsProgress: AssignmentsProgress,
  assignmentsTaskProgress: AssignmentsTaskProgress,
  members: Members,
  isAdapt: boolean
) => {
  return assignmentsToCalculate.map(assignment => {
    const progress = isAdapt
      ? getProgressAndMemberForAdapt(assignment, assignmentsProgress, members)
      : getProgressionAndMember(
          assignment,
          assignmentsProgress,
          assignmentsTaskProgress,
          members
        )

    const calculatedStatus = calculateStatus(
      assignment.status,
      assignment.startDate,
      assignment.endDate,
      isAdapt
    )

    return {
      ...assignment,
      calculatedStatus,
      progress
    }
  })
}

const getMemberById = (memberId: Member["studliId"], members: Members) =>
  members.find(member => member.studliId === memberId)
