import React from "react"
import * as Yup from "yup"
import { DialogTitle } from "@mui/material"
import { Formik } from "formik"
import AddEndQuestionForm from "./AddEndQuestionForm"
import { testForAudioOrText } from "../AssignmentDialogContent/shared"
type Props = {
  onClose: () => void
  onSubmit: (values: any) => void
  endQuestion: { text?: ""; audio?: "" }
}

const validationSchema = Yup.object({
  question: Yup.mixed().test(
    "open-question",
    "Det saknas en beskrivning. Skriv och/eller spela in frågan.",
    function (value) {
      const { path, createError } = this
      return testForAudioOrText(path, createError, value)
    }
  )
})

const FormativeEndQuestionDialog = ({
  onClose,
  onSubmit,
  endQuestion
}: Props) => {
  const getInitialValues = () => {
    if (endQuestion.audio || endQuestion.text) {
      return {
        question: {
          text: endQuestion.text || "",
          audio: endQuestion.audio || ""
        }
      }
    }
    return {
      question: { text: "", audio: "" }
    }
  }

  return (
    <>
      <DialogTitle>Slutfråga</DialogTitle>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnBlur={true}
      >
        {formikProps => (
          <AddEndQuestionForm {...formikProps} onClose={onClose} />
        )}
      </Formik>
    </>
  )
}

export default FormativeEndQuestionDialog
