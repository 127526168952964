import { FormativeTestStatuses } from "../../modules/formative/types"

export const filterMenuItems: {
  value: FormativeTestStatuses | "alla"
  title: string
}[] = [
  {
    value: "alla",
    title: "Alla"
  },
  {
    value: "draft",
    title: "Utkast"
  },
  {
    value: "published",
    title: "Kommande"
  },
  {
    value: "ongoing",
    title: "Pågående"
  },
  {
    value: "stopped",
    title: "Avslutat"
  },
  {
    value: "archived",
    title: "Arkiverat"
  }
]

export const formativeStatusSortWeight = [
  "ongoing",
  "published",
  "draft",
  "stopped"
]
