import { styled } from "@mui/material"
import Avatar from "../Avatar"

export const StyledNameTableCellContent = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledAvatar = styled(Avatar)`
  margin-right: 1em;
`

export const StyledToGoalText = styled("span")`
  color: black;
  font-weight: bold;
`

export const StyledToGoalTextWrapper = styled("div")`
  align-items: center;
  display: flex;
`

export const StyledMovingBackwardsText = styled("span")`
  color: red;
`
