import { useSelector } from "react-redux"
import {
  selectProgressionByStudent,
  selectTestsByStudent
} from "../../modules/formative/selectors"
import {
  FormativeProgressionArr,
  FormativeTests
} from "../../modules/formative/types"
import { selectStudentByPayload } from "../../modules/members/selectors"
import { Member } from "../../modules/members/types"
import { selectActiveProductName } from "../../modules/products/selectors"
import { selectClassroomId } from "../../modules/classroom/selectors"

type ReturnProps = {
  tests: FormativeTests
  progress: FormativeProgressionArr
  member: Member
  activeProduct: string
  classroomId: number
}

function useStudentFormativeList(): ReturnProps {
  const tests = useSelector(selectTestsByStudent)
  const progress = useSelector(selectProgressionByStudent)
  const member = useSelector(selectStudentByPayload)
  const activeProduct = useSelector(selectActiveProductName)
  const classroomId = useSelector(selectClassroomId)
  return { tests, progress, member, activeProduct, classroomId }
}

export default useStudentFormativeList
