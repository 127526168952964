import {
  DialogActions,
  DialogContent,
  Button,
  Stack,
  FormControl,
  OutlinedInput,
  InputAdornment,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Checkbox,
  IconButton,
  Chip
} from "@mui/material"
import React from "react"
import Dialog from "../Dialog"
import { FilterValue, Filters } from "./types"
import SearchIcon from "@mui/icons-material/Search"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { FilterChip, FilterPrimaryButton, StyledFilterCount } from "./shared"
import { ArrowBack } from "@mui/icons-material"

type Props = {
  activeFilters: FilterValue[]
  onToggleFilter: (filter: FilterValue) => void
  filters: Filters[]
  onRemoveFilter: (filter: FilterValue) => void
  clearFilters: () => void
  addSearchText: (event: React.FormEvent<HTMLFormElement>) => void
  setSearchText: (text: string) => void
  searchText: string
  searchLabel: string
  results: number
  sortingComponent?: React.ReactNode
}
const MobileFilterButton = ({
  setSearchText,
  searchLabel,
  searchText,
  addSearchText,
  activeFilters,
  filters,
  onToggleFilter,
  results,
  clearFilters,
  onRemoveFilter,
  sortingComponent
}: Props) => {
  const [open, setOpen] = React.useState(false)
  const [selectedFilters, setSelectedFilters] = React.useState<{
    label: string
    options: FilterValue[]
  } | null>(null)

  const openDialog = () => {
    setOpen(true)
    setSelectedFilters(null)
  }

  const renderNoFilterOption = () => {
    return (
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none"
            }
          }}
        >
          {activeFilters.length > 0 && (
            <Chip
              label="Rensa allt"
              variant="outlined"
              onClick={clearFilters}
              sx={{
                backgroundColor: "white",
                color: "black",
                cursor: "pointer"
              }}
            />
          )}
          {activeFilters.map(filter => (
            <FilterChip
              key={filter.val + filter.key}
              label={filter.label}
              onDelete={() => onRemoveFilter(filter)}
            />
          ))}
        </Stack>
        <Stack component="form" onSubmit={addSearchText}>
          <FormControl variant="outlined">
            <OutlinedInput
              size="small"
              onChange={e => setSearchText(e.target.value)}
              value={searchText}
              placeholder={searchLabel}
              id="search-filter"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Stack>
        {sortingComponent && sortingComponent}
        <List>
          <Divider />
          {filters.map(filter => {
            const checked = activeFilters.filter(
              f => f.key === filter.key
            ).length
            return (
              <ListItem disablePadding key={filter.label}>
                <ListItemButton
                  sx={{ padding: 2 }}
                  onClick={() =>
                    setSelectedFilters({
                      label: filter.label,
                      options: filter.options
                    })
                  }
                >
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      {filter.label}
                      {checked > 0 && (
                        <StyledFilterCount>{checked}</StyledFilterCount>
                      )}
                    </Stack>
                    <ArrowForwardIosIcon />
                  </Stack>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Stack>
    )
  }

  const renderFilterOptions = () => {
    return (
      <List>
        {selectedFilters?.options.map(filter => (
          <ListItem disablePadding key={filter.label}>
            <ListItemButton onClick={() => onToggleFilter(filter)}>
              <Checkbox
                checked={activeFilters.some(
                  a => a.key === filter.key && a.val === filter.val
                )}
              />
              {filter.label}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <>
      <FilterPrimaryButton onClick={openDialog}>
        {<SearchIcon />}
        Sök och filtrera{" "}
        {activeFilters.length > 0 && (
          <StyledFilterCount sx={{ mr: 0.5 }}>
            {activeFilters.length}
          </StyledFilterCount>
        )}
      </FilterPrimaryButton>
      <Dialog fullScreen open={open}>
        <DialogTitle>
          {selectedFilters && (
            <IconButton
              sx={{ color: "black" }}
              onClick={() => setSelectedFilters(null)}
            >
              <ArrowBack />
            </IconButton>
          )}
          {selectedFilters ? selectedFilters.label : "Sök och filtrera"} (
          {results})
        </DialogTitle>
        <DialogContent>
          {selectedFilters ? renderFilterOptions() : renderNoFilterOption()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Visa</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MobileFilterButton
