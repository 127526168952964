import React from "react"
import {
  StyledMessageListItem,
  StyledMessageContent,
  StyledMessage
} from "./StyledFeedbackMessageListItem"
import Avatar from "../Avatar"
import { Typography } from "@mui/material"
import { buildName } from "../../shared/tools/strings"
import { AssignmentMessageWithSenderData } from "../../containers/FeedbackMessages/types"
import moment, { Moment } from "moment"
import AudioPlayer from "../AudioPlayer"

type Props = {
  message: AssignmentMessageWithSenderData
}

const FeedbackMessageListItem = ({ message }: Props) => {
  const getMessageExpires = (createdAt: Moment) => {
    return moment(createdAt).add(1, "year").add(6, "months")
  }

  return (
    <StyledMessageListItem key={message.id}>
      {message.sender ? (
        <Avatar
          picture={message.sender.picture}
          firstName={message.sender.firstName}
          lastName={message.sender.lastName}
          studliId={message.sender.studliId}
        />
      ) : (
        <Avatar firstName="Borttagen" lastName="Användare" studliId={0} />
      )}
      <StyledMessageContent>
        {message.sender ? (
          <div>
            {`${buildName(
              message.sender.firstName,
              message.sender.lastName
            )} (${moment(message.createdAt).fromNow()})`}
          </div>
        ) : (
          "Borttagen användare"
        )}
        <StyledMessage>
          {message.type === "text" && (
            <Typography variant="body1">{message.data}</Typography>
          )}
          {message.type === "audio" && (
            <>
              <AudioPlayer label="Ljudklipp" src={message.data} />
              {getMessageExpires(message.createdAt).diff(moment()) > 0 ? (
                <div>
                  Tas bort {getMessageExpires(message.createdAt).from(moment())}
                </div>
              ) : (
                <div>
                  Togs bort{" "}
                  {getMessageExpires(message.createdAt).from(moment())}
                </div>
              )}
            </>
          )}
        </StyledMessage>
      </StyledMessageContent>
    </StyledMessageListItem>
  )
}

export default FeedbackMessageListItem
