import { styled } from "@mui/material"

export const StyledButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const StyledButtonContainer = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledQuestionWrapper = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledButtonPadder = styled("div")`
  margin-left: 10px;
`

export const StyledQuestionNumber = styled("p")`
  font-size: 1.3em;
  margin: 0;
`
