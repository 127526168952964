import { createAction } from "../../shared/tools/redux"
import {
  ReceiveClassroomInfoPayload,
  ReceiveClassroomInfoUpdatePayload,
  ReceiveClassroomGroupsPayload,
  ChangeClassroomGroupPayload,
  ReceiveSchoolUnitGroupsPayload,
  Groups
} from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  CLASSROOM_INFO_REQUEST = "CLASSROOM_INFO_REQUEST",
  CLASSROOM_INFO_UPDATE_REQUEST = "CLASSROOM_INFO_UPDATE_REQUEST",
  CLASSROOM_GROUPS_REQUEST = "CLASSROOM_GROUPS_REQUEST",
  CLASSROOM_CHANGE_GROUP_REQUEST = "CLASSROOM_CHANGE_GROUP_REQUEST",
  CLASSROOM_ALL_GROUPS_REQUEST = "CLASSROOM_ALL_GROUPS_REQUEST"
}
/**
 * Redux-actions, originating from server. As result from a client-request.
 * Here, both CLASSROOM_INFO_RESPONSE action and the CLASSROOM_INFO_UPDATE_RESPONSE action will give us the same
 * payload from the server, so it might be tempting to refactor away (remove) one of them. But don't!
 * having both lets us distinguish what initiated the answer thus giving the ability to react differently in the reducer.
 */
export enum RESPONSE {
  /**
   * Response when just requesting classroom info
   */
  CLASSROOM_INFO_RESPONSE = "CLASSROOM_INFO_RESPONSE",
  /**
   * Response when requesting an update of classroom info
   */
  CLASSROOM_INFO_UPDATE_RESPONSE = "CLASSROOM_INFO_UPDATE_RESPONSE",
  /**
   * Response when requesting classroom groups
   */
  CLASSROOM_GROUPS_RESPONSE = "CLASSROOM_GROUPS_RESPONSE",
  /**
   * Response when requesting to change group
   */
  CLASSROOM_CHANGE_GROUP_RESPONSE = "CLASSROOM_CHANGE_GROUP_RESPONSE",

  CLASSROOM_ALL_GROUPS_RESPONSE = "CLASSROOM_ALL_GROUPS_RESPONSE"
}
/**
 * Redux-actions originating from server. Because of this or another client's actions.
 */
export enum EVENT {
  CLASSROOM_INFO_UPDATED_EVENT = "CLASSROOM_INFO_UPDATED_EVENT",
  CLASSROOM_GROUP_REMOVED_EVENT = "CLASSROOM_GROUP_REMOVED_EVENT",
  CLASSROOM_GROUP_UPDATED_EVENT = "CLASSROOM_GROUP_UPDATED_EVENT",
  CLASSROOM_MEMBER_REMOVER_EVENT = "CLASSROOM_MEMBER_REMOVED_EVENT"
}
/**
 * Strings for "action" property when communicating with server.
 * Request and Response use the same string.
 * (The "type" property is this module's name).
 */
export enum SERVER_MESSAGE_ACTION {
  CLASSROOM_INFO_REQUEST = "info",
  CLASSROOM_INFO_RESPONSE = "info",
  CLASSROOM_INFO_UPDATE_REQUEST = "update",
  CLASSROOM_INFO_UPDATE_RESPONSE = "update",
  CLASSROOM_INFO_UPDATED_EVENT = "updated",
  CLASSROOM_GROUPS_REQUEST = "groups",
  CLASSROOM_GROUPS_RESPONSE = "groups",
  CLASSROOM_CHANGE_GROUP_REQUEST = "groupchange",
  CLASSROOM_CHANGE_GROUP_RESPONSE = "groupchange",
  CLASSROOM_GROUP_REMOVED_EVENT = "group_removed",
  CLASSROOM_ALL_GROUPS_REQUEST = "schoolunit_groups",
  CLASSROOM_ALL_GROUPS_RESPONSE = "schoolunit_groups",
  CLASSROOM_GROUP_UPDATED = "group_updated",
  CLASSROOM_MEMBER_REMOVED = "member_removed"
}

export const getClassroomInfo = createAction("CLASSROOM_INFO_REQUEST")

export const changeClassroomGroup = createAction<number[]>(
  "CLASSROOM_CHANGE_GROUP_REQUEST"
)

export const getClassroomGroups = createAction("CLASSROOM_GROUPS_REQUEST")

export const getSchoolUnitGroups = createAction("CLASSROOM_ALL_GROUPS_REQUEST")

export const setClassroomName = createAction<string>(
  "CLASSROOM_INFO_UPDATE_REQUEST"
)

export const addClassroomInfo = createAction<ReceiveClassroomInfoPayload>(
  "CLASSROOM_INFO_RESPONSE"
)

export const addClassroomGroups = createAction<ReceiveClassroomGroupsPayload>(
  "CLASSROOM_GROUPS_RESPONSE"
)

export const addSchoolUnitGroups = createAction<ReceiveSchoolUnitGroupsPayload>(
  "CLASSROOM_ALL_GROUPS_RESPONSE"
)

export const changeClassroomGroups = createAction<ChangeClassroomGroupPayload>(
  "CLASSROOM_CHANGE_GROUP_RESPONSE"
)

export const updateClassroomInfoResponse =
  createAction<ReceiveClassroomInfoUpdatePayload>(
    "CLASSROOM_INFO_UPDATE_RESPONSE"
  )

export const classroomGroupsRemoved = createAction<number[]>(
  "CLASSROOM_GROUP_REMOVED_EVENT"
)

export const classroomGroupsUpdated = createAction<Groups>(
  "CLASSROOM_GROUP_UPDATED_EVENT"
)
