import React, { useState } from "react"
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Popover
} from "@mui/material"
import AbilityIndicator from "../../components/AbilityIndicator"
import {
  AssignmentGoalProgress,
  AssignmentsGoalProgress
} from "../../modules/assignmentsProgress/types"
import {
  StyledPaper,
  StyledArrowIcon,
  SmallTableCell,
  StyledTitleWrapper,
  StyledCollapseTableCell,
  StyledTableRow,
  StyledResults,
  StyledTitle,
  StyledResultsTitle,
  StyledChartWrapper,
  StyledChartContainer,
  StyledChart,
  StyledPopoverInfoWrapper,
  StyledPopoverChartIndicator,
  StyledPopoverText,
  StyledChartLabel,
  StyledChartResultText,
  StyledAdaptCard
} from "./StyledAssignmentStudentCard"
import useAdaptStudentCard, { ProgressWithTitle } from "./useAdaptStudentCard"
import { StyledPopoverContent } from "../../components/InfoPopover/StyledInfoPopover"
import Duration from "../../components/Duration"

const DIFFICULTIES: { [key: number]: string } = {
  1: "Lätt",
  2: "Medel",
  3: "Svår"
}

const AdaptStudentCard = () => {
  const { progressWithTitle } = useAdaptStudentCard()

  const sortData = (progress: ProgressWithTitle[]) => {
    const repetitionIndex = progress.findIndex(p => p.goalRef === "0")
    const sortedProgress = progress.sort((a, b) => a.order - b.order)
    if (repetitionIndex !== -1) {
      const repetition = sortedProgress.splice(repetitionIndex, 1)
      sortedProgress.push(...repetition)
    }
    return sortedProgress
  }
  return (
    <StyledAdaptCard>
      {progressWithTitle.specific.length !== 0 && (
        <StyledPaper>
          <StyledTitle variant="h1">Uppdragsmål</StyledTitle>
          <GoalResultTable data={sortData(progressWithTitle.specific)} />
        </StyledPaper>
      )}
      {progressWithTitle.unspecific.length !== 0 && (
        <StyledPaper>
          <StyledTitle>
            {progressWithTitle.specific.length === 0 ? "Mål" : "Övriga mål"}{" "}
            eleven arbetat med
          </StyledTitle>
          <GoalResultTable data={sortData(progressWithTitle.unspecific)} />
        </StyledPaper>
      )}
    </StyledAdaptCard>
  )
}

export default AdaptStudentCard

type GoalTableData = {
  data: ProgressWithTitle[]
}

const GoalResultTable = ({ data }: GoalTableData) => {
  const [open, setOpen] = useState<number | null>(null)
  const getNumberOfExercises = (grades: AssignmentsGoalProgress) => {
    return grades.reduce((acc: number, curr) => {
      return acc + curr.nbrExercises
    }, 0)
  }

  const getActivetime = (grades: AssignmentsGoalProgress) => {
    const accTime = grades.reduce((acc: number, curr) => {
      return acc + curr.activeTimeMillis
    }, 0)
    return accTime < 60000 ? "< 1 min" : <Duration duration={accTime} />
  }

  const getAbilityGrade = (grades: AssignmentsGoalProgress) => {
    const accGrades = grades.reduce((acc, curr) => {
      return acc + curr.abilityGrade
    }, 0)
    return Math.round(accGrades / grades.length)
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <SmallTableCell />
          <TableCell>Mål</TableCell>
          <TableCell align="right">Antal övningar</TableCell>
          <TableCell align="right">Aktiv tid</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <React.Fragment key={d.goalRef}>
            <StyledTableRow>
              <SmallTableCell>
                {getNumberOfExercises(d.grades) > 0 && (
                  <IconButton
                    aria-label={open === i ? "stäng" : "öppna"}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    <StyledArrowIcon />
                  </IconButton>
                )}
              </SmallTableCell>
              <TableCell>
                <StyledTitleWrapper>
                  {d.goalRef !== "0" && (
                    <AbilityIndicator grade={getAbilityGrade(d.grades)} />
                  )}
                  {d.goalRef !== "0"
                    ? `${d.order}. ${d.title}`
                    : "Repetition av tidigare mål"}
                </StyledTitleWrapper>
              </TableCell>
              <TableCell align="right">
                {getNumberOfExercises(d.grades)}
              </TableCell>
              <TableCell align="right">
                {getNumberOfExercises(d.grades) > 0 && getActivetime(d.grades)}
              </TableCell>
            </StyledTableRow>
            <TableRow>
              <StyledCollapseTableCell colSpan={4}>
                <Collapse in={open === i} timeout="auto" unmountOnExit>
                  <StyledResults>
                    <StyledResultsTitle variant="h2">
                      Resultat
                    </StyledResultsTitle>
                    <StyledChartWrapper>
                      {d.grades.map(grade =>
                        grade.nbrExercises > 0 ? (
                          <StyledChartContainer key={grade.difficulty}>
                            <Chart grade={grade} />
                          </StyledChartContainer>
                        ) : null
                      )}
                    </StyledChartWrapper>
                  </StyledResults>
                </Collapse>
              </StyledCollapseTableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  )
}

const Chart = ({ grade }: { grade: AssignmentGoalProgress }) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

  const handleClick = (event: React.MouseEvent<Element>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<Element>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const getPercentageCorrect = () => {
    const total =
      grade.nbrIncorrect + grade.nbrCorrectFirst + grade.nbrCorrectSecond

    const correct = grade.nbrCorrectFirst + grade.nbrCorrectSecond

    return Math.round((correct / total) * 100)
  }

  const getPercentageOfAnswer = (type: "correct" | "wrong" | "second") => {
    const total =
      grade.nbrIncorrect + grade.nbrCorrectFirst + grade.nbrCorrectSecond
    switch (type) {
      case "correct":
        return Math.round((grade.nbrCorrectFirst / total) * 100)
      case "second":
        return Math.round((grade.nbrCorrectSecond / total) * 100)
      case "wrong":
        return Math.round((grade.nbrIncorrect / total) * 100)
    }
  }

  return (
    <>
      <StyledChartLabel>{DIFFICULTIES[grade.difficulty]}</StyledChartLabel>
      <StyledChart
        radius={30}
        segmentsShift={anchorEl ? 2 : 0}
        segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
        onClick={e => handleClick(e)}
        data={[
          { title: "Rätt", value: grade.nbrCorrectFirst, color: "#038763" },
          {
            title: "Rätt andra försöket",
            value: grade.nbrCorrectSecond,
            color: "#66b5a0"
          },
          {
            title: "Fel",
            value: grade.nbrIncorrect,
            color: "url(#gradient1)"
          }
        ]}
      >
        <defs>
          <pattern
            id="gradient1"
            width="6"
            height="6"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect
              width="6"
              height="6"
              transform="translate(0,0)"
              fill="#fadbd8"
            ></rect>
            <rect
              width="1"
              height="6"
              transform="translate(4,0)"
              fill="#d7513e"
            ></rect>
          </pattern>
        </defs>
      </StyledChart>
      <StyledChartResultText>
        <b>{getPercentageCorrect()}</b>% rätt <br />
        {grade.activeTimeMillis < 60000 ? (
          "< 1 min"
        ) : (
          <Duration duration={grade.activeTimeMillis} />
        )}
      </StyledChartResultText>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <StyledPopoverContent>
          <StyledPopoverInfoWrapper>
            <StyledPopoverChartIndicator type="correct" />
            <StyledPopoverText variant="body2">
              {getPercentageOfAnswer("correct")}% rätt på första försöket
            </StyledPopoverText>
          </StyledPopoverInfoWrapper>
          <StyledPopoverInfoWrapper>
            <StyledPopoverChartIndicator type="second" />
            <StyledPopoverText>
              {getPercentageOfAnswer("second")}% rätt på andra försöket
            </StyledPopoverText>
          </StyledPopoverInfoWrapper>
          <StyledPopoverInfoWrapper>
            <StyledPopoverChartIndicator type="wrong" />
            <StyledPopoverText>
              {getPercentageOfAnswer("wrong")}% fel
            </StyledPopoverText>
          </StyledPopoverInfoWrapper>
        </StyledPopoverContent>
      </Popover>
    </>
  )
}
