import { SnackbarData } from "../../../containers/Snackbar/types"

export const createAssignmentMessages: { [name: string]: SnackbarData } = {
  TITLE_MISSING: {
    severity: "error",
    message:
      "Det saknas en titel på uppdraget. Skriv en titel som ska visas för eleverna."
  },
  STICKER_MISSING: {
    severity: "error",
    message: "Uppdraget måste ha ett klistermärke"
  },
  STUDENTS_MISSING: {
    severity: "error",
    message:
      "Det saknas elever i uppdraget. Välj elever för att kunna publicera."
  },
  ASSIGNMENT_TASKS_MISSING: {
    severity: "error",
    message:
      "Det saknas innehåll i uppdraget. Lägg till innehåll för att kunna publicera."
  },
  GOALS_MISSING: {
    severity: "error",
    message:
      "Det saknas mål i uppdraget. Lägg till mål för att kunna publicera."
  },
  START_DATE_MISSING: {
    severity: "error",
    message: "Uppdraget måste ha ett startdatum"
  },
  ACCESS_MISSING: {
    severity: "error",
    message:
      "Det saknas åtkomst i uppdraget. Lägg till åtkomst för att kunna publicera"
  }
}
