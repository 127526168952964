import React from "react"
import {
  StyledStudliLogo,
  StyledStudliLogoWithText,
  StyledLogo
} from "./StyledLogo"
import LoadingDots from "../LoadingDots"
import theme from "../../shared/styles/theme"
import { Theme, useMediaQuery } from "@mui/material"

type Props = {
  showLoadingDots?: boolean
  footerLogo?: boolean
}

/**
 * Show logo without text for small screen sizes
 *
 * @return {boolean}
 */

const Logo = ({ showLoadingDots, footerLogo }: Props) => {
  const xs = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"))

  const renderStyledStudliLogo = () => {
    if (showLoadingDots) {
      return (
        <StyledLogo>
          <StyledStudliLogo
            style={{
              marginBottom: "0.5em",
              fill: theme.studli.classroom.themeTextColor
            }}
          />
          <LoadingDots numberOfDots={3} />
        </StyledLogo>
      )
    } else {
      return (
        <StyledStudliLogo
          style={{
            fill: footerLogo ? "white" : theme.studli.classroom.themeTextColor
          }}
        />
      )
    }
  }
  const renderStyledStudliLogoWithText = () => {
    if (showLoadingDots) {
      return (
        <StyledLogo>
          <StyledStudliLogoWithText
            style={{ fill: theme.studli.classroom.themeTextColor }}
          />
          <LoadingDots />
        </StyledLogo>
      )
    } else {
      return (
        <StyledStudliLogoWithText
          style={{
            fill: footerLogo ? "white" : theme.studli.classroom.themeTextColor
          }}
        />
      )
    }
  }

  return (
    <React.Fragment>
      {xs ? renderStyledStudliLogo() : renderStyledStudliLogoWithText()}
    </React.Fragment>
  )
}

export default Logo
