import React, { useCallback, useEffect, useMemo } from "react"
import { connect, useSelector } from "react-redux"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"

import * as membersTypes from "../../modules/members/types"
import * as membersPagesTypes from "../../pages/Members/store/types"
import * as membersPageActions from "../../pages/Members/store/actions"
import { RootState } from "../../modules/store"
import Row from "../MembersTableRow"
import {
  StyledHeadCell,
  StyledMembers,
  StyledTableHead
} from "./StyledMembersTable"
import {
  selectMemberListOrderBy,
  selectMemberListOrder
} from "../../pages/Members/store/selectors"
import { sortMembers } from "../../pages/Members/common/TableAndListSort"
import InfoPopover from "../InfoPopover"
import TableToolbar from "../TableToolbar"
import { Product } from "../../modules/products/types"
import {
  SortableProps,
  VisibleMemberProp
} from "../../pages/Members/store/types"
import TableFilter from "../TableFilter/TableFilter"
import {
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import { FilterValue } from "../TableFilter/types"
import { Box, Checkbox, Stack, TableCell } from "@mui/material"
import TableActionBar from "../TableActionBar/TableActionBar"
import { tableFilter } from "../../shared/tools/filter"

type OwnProps = {
  members: membersPagesTypes.StudentWithMoreMenu[]
  children?: never
  tableHeader: membersPagesTypes.VisibleMemberProp[]
  onCheckboxClick: (student: membersPagesTypes.StudentInStudentList) => void
  checkedMembers: membersPagesTypes.StudentInStudentList[]
  toggleAllMembers: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  activeProduct: Product
  toolbarActions: any[]
  unselectedToolbarActions: any[]
  recentlyAddedMembers: membersTypes.Member["studliId"][]
  goToStudentCard?: (memberId: number) => void
  hasExercises?: boolean
  hasFormativeTests?: boolean
}

type StateProps = {
  memberListOrderBy: keyof membersPagesTypes.StudentInStudentList
  memberListOrder: membersPagesTypes.Order
}

type DispatchProps = {
  setMemberListOrder: (
    order: membersPagesTypes.Order,
    orderBy: keyof membersPagesTypes.SortableProps
  ) => membersPagesTypes.SetMemberListOrder
}

type Props = OwnProps & DispatchProps & StateProps

const MembersTable = ({
  members,
  tableHeader,
  memberListOrderBy,
  memberListOrder,
  setMemberListOrder,
  checkedMembers,
  toolbarActions,
  unselectedToolbarActions,
  activeProduct,
  recentlyAddedMembers,
  goToStudentCard,
  hasExercises,
  hasFormativeTests,
  onCheckboxClick,
  toggleAllMembers
}: Props) => {
  /**
   * Click handler for header
   * @param id name of column to be sorted
   */
  const groups = useSelector(selectAllGroups)
  const [sortedMembers, setSortedMembers] = React.useState(
    sortMembers(members, memberListOrder, memberListOrderBy)
  )
  const allActiveGroups = useSelector(selectAllActiveGroups)

  const [currentFilter, setCurrentFilter] = React.useState<FilterValue[]>([])
  const createSortHandler =
    (id: keyof SortableProps) => (event: React.MouseEvent<unknown>) => {
      let newOrder: membersTypes.Order = "desc"
      if (id === memberListOrderBy) {
        newOrder = memberListOrder === "desc" ? "asc" : "desc"
      }
      setMemberListOrder(newOrder, id)
    }

  const onFilter = useCallback(
    (filterValues: FilterValue[]) => {
      const filteredMembers = tableFilter(filterValues, members)
      setSortedMembers(
        sortMembers(filteredMembers, memberListOrder, memberListOrderBy)
      )
    },
    [memberListOrder, memberListOrderBy, members]
  )

  useEffect(() => {
    onFilter(currentFilter)
  }, [members, currentFilter, onFilter])

  const setFilters = (filterValues: FilterValue[]) => {
    setCurrentFilter(filterValues)
  }

  const getFilters = () => {
    const memberGroups = groups.filter(g => {
      return members.some(m => m.groups?.some(gm => gm.id === g.id))
    })
    return [
      {
        label: "Grupper",
        key: "groups",
        options: memberGroups.map(group => ({
          label: group.name,
          val: group.id,
          key: "groups",
          filterFunction: (m: membersPagesTypes.StudentInStudentList) =>
            m.groups?.some(g => g.id === group.id)
        }))
      }
    ]
  }

  const searchFunction = (
    m: membersPagesTypes.StudentInStudentList,
    searchText: string
  ) =>
    m.email?.toLowerCase().includes(searchText.toLowerCase()) ||
    m.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
    m.lastName?.toLowerCase().includes(searchText.toLowerCase())

  /**
   * Renders table column label sortable or not
   *
   * @param row
   */
  const renderColumnLabel = (row: VisibleMemberProp) =>
    row.isSortable ? (
      <>
        <TableSortLabel
          aria-label={`Sortera i ${
            memberListOrder === "desc" ? "fallande" : "stigande"
          } ordning`}
          active={memberListOrderBy === row.sortKey}
          direction={memberListOrder}
          onClick={createSortHandler(row.sortKey)}
        >
          {row.label}
          {row.info && <InfoPopover {...row.info} />}
        </TableSortLabel>
      </>
    ) : (
      <span>
        {row.label} {row.info && <InfoPopover {...row.info} />}
      </span>
    )

  /**
   * Render Table Head
   */

  const allSelected = useMemo(() => {
    return (
      checkedMembers.length === sortedMembers.filter(m => m.hasProduct).length
    )
  }, [checkedMembers, sortedMembers])

  const showCheckbox = useMemo(
    () =>
      activeProduct.type === "adapt" && sortedMembers.some(m => m.hasProduct),
    [sortedMembers, activeProduct]
  )

  const renderTableHead = () => (
    <StyledTableHead>
      <TableRow>
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={e =>
                toggleAllMembers(e as any, allSelected ? false : true)
              }
              checked={allSelected}
            />
          </TableCell>
        )}
        {tableHeader.map(col => (
          <StyledHeadCell
            key={col.id}
            aligntext={col.alignRight ? "right" : "left"}
            sortDirection={
              memberListOrderBy === col.sortKey ? memberListOrder : false
            }
          >
            {renderColumnLabel(col)}
          </StyledHeadCell>
        ))}
        {activeProduct.type === "adapt" && <StyledHeadCell />}
      </TableRow>
    </StyledTableHead>
  )

  /**
   * Render Table Body
   */
  const renderTableBody = () => (
    <TableBody>
      {sortedMembers.map(member => {
        const isRecentlyAddedMember = recentlyAddedMembers.some(
          memberId => member.studliId === memberId
        )

        return (
          <Row
            key={member.studliId}
            member={member}
            moreMenuItems={member.moreMenuItems}
            checkedMembers={checkedMembers}
            onCheckboxClick={onCheckboxClick}
            activeProduct={activeProduct}
            isRecentlyAddedMember={isRecentlyAddedMember}
            goToStudentCard={
              goToStudentCard
                ? () => goToStudentCard(member.studliId)
                : undefined
            }
            hasExercises={hasExercises}
            hasFormativeTests={hasFormativeTests}
            allActiveGroups={allActiveGroups}
          />
        )
      })}
    </TableBody>
  )

  return (
    <>
      <TableToolbar
        title="Elever"
        selectedTitle="elever valda"
        numSelected={0}
        actions={[]}
        unselectedActions={[]}
        totalItems={sortedMembers.length}
        filterComponent={
          <TableFilter
            filters={getFilters()}
            onFilter={setFilters}
            searchFunction={searchFunction}
            searchLabel="Sök elever"
            results={sortedMembers.length}
          />
        }
      />
      <Box sx={{ overflow: "auto" }}>
        <Box
          sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
        ></Box>
        <StyledMembers>
          {renderTableHead()}
          {renderTableBody()}
        </StyledMembers>
      </Box>
      {checkedMembers.length > 0 && activeProduct.type === "adapt" && (
        <TableActionBar
          actions={
            <Stack
              direction="row"
              textAlign="center"
              justifyContent="space-between"
            >
              {toolbarActions.map(t => t)}
            </Stack>
          }
          numSelected={checkedMembers.length}
          onClose={e => toggleAllMembers(e, false)}
          position="sticky"
          bottom="2rem"
          width="25rem"
        />
      )}
    </>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => {
  return {
    memberListOrderBy: selectMemberListOrderBy(state),
    memberListOrder: selectMemberListOrder(state)
  }
}

/**
 * Map Dispatch To Props.
 */
const mapDispatchToProps: DispatchProps = {
  setMemberListOrder: membersPageActions.setMemberListOrder
}

const ConnectedMembersTable = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(MembersTable)

export default ConnectedMembersTable
