import React, { useState, useEffect } from "react"

import Playback from "./Playback"
import Remove from "./Remove"
import Recorder from "./Recorder"
import { StyledArrowButton } from "../../containers/FeedbackMessages/StyledFeedbackMessages"
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"

type Props = {
  maxRecordingTime?: number
  type?: "input" | "standalone"
  setAudio?: (src: string | undefined) => void
  setAudioForForm?: (filePath: string | undefined, type: string) => void
  formType?: string
  onSend?: () => void
  editAudio?: Blob
  conversation: boolean
}

const AudioRecorder = ({
  maxRecordingTime = 120,
  type = "standalone",
  setAudio,
  onSend,
  formType = "",
  setAudioForForm,
  editAudio,
  conversation
}: Props) => {
  const [savedAudio, setSavedAudio] = useState<string | undefined>()
  const [isRemoving, setIsRemoving] = useState(false)

  useEffect(() => {
    if (editAudio) {
      if (typeof editAudio === "string") {
        setSavedAudio(editAudio)
        return
      }
    }
  }, [editAudio])

  const saveAudio = (filePath: string) => {
    setSavedAudio(filePath)
    if (setAudio) {
      setAudio(filePath)
    }
    if (setAudioForForm) {
      setAudioForForm(filePath, formType)
    }
  }

  const onSave = (filePath: string) => saveAudio(filePath)
  const resetRecording = () => {
    setSavedAudio(undefined)
    if (setAudio) {
      setAudio(undefined)
    }
    if (setAudioForForm) {
      setAudioForForm(undefined, formType)
    }
    setIsRemoving(false)
  }

  const submitAudio = () => {
    if (onSend) {
      onSend()
      setSavedAudio(undefined)
      if (setAudio) {
        setAudio(undefined)
      }
      setIsRemoving(false)
    }
  }

  return (
    <>
      {!savedAudio && (
        <Recorder
          editAudio={editAudio}
          onSave={onSave}
          maxLength={maxRecordingTime}
          button={type === "standalone"}
          conversation={conversation}
        />
      )}
      {savedAudio && !isRemoving && (
        <Playback
          src={savedAudio}
          onCancel={() => setIsRemoving(true)}
          resetRecording={resetRecording}
        />
      )}
      {type === "input" && savedAudio && !isRemoving && (
        <StyledArrowButton aria-label="Skicka meddelande" onClick={submitAudio}>
          <ArrowForwardRoundedIcon />
        </StyledArrowButton>
      )}
      {isRemoving && (
        <Remove
          onRemove={resetRecording}
          onCancel={() => setIsRemoving(false)}
        />
      )}
    </>
  )
}

export default AudioRecorder
