import { RootState } from "../store"
import {
  FormativeQuestions,
  FormativeTests,
  PartialFormativeTest,
  FormativeProgressionArr,
  FormativeProgressionAnswers,
  PreparedTests,
  ImportableTestClassroom
} from "./types"
import { createSelector } from "reselect"
import { selectCurrentTestId, selectCurrentMemberId } from "../router/selectors"

export const selectAllQuestions = (state: RootState): FormativeQuestions =>
  state.formative.questions

export const selectAllProgression = (
  state: RootState
): FormativeProgressionArr => state.formative.progression

export const selectAllProgressionAnswers = (
  state: RootState
): FormativeProgressionAnswers => state.formative.progressionAnswers

export const selectAllTests = (state: RootState): FormativeTests =>
  state.formative.tests

export const selectTestsLength = (state: RootState): number =>
  state.formative.tests.length

export const selectAllImportableTests = (
  state: RootState
): ImportableTestClassroom[] => state.formative.importableTests

export const selectImportedTestsLength = (state: RootState): number =>
  state.formative.importableTests.length

export const selectAllPreparedTests = (state: RootState): PreparedTests =>
  state.formative.preparedTests

export const selectPreparedTestsLength = (state: RootState): number =>
  state.formative.preparedTests.length

export const selectPendingImportFormativeTest = (
  state: RootState
): PartialFormativeTest | undefined => state.formative.pendingImport

export const selectQuestionsWithChapters = createSelector(
  selectAllQuestions,
  questions => {
    return questions.reduce((acc: any, question) => {
      const folder = `${question.folder} / ${question.category}`
      if (!acc[folder]) {
        acc[folder] = { children: [] }
        acc[folder].children = []
      }
      acc[folder].children.push(question)
      return acc
    }, {})
  }
)

export const selectTestInRoute = createSelector(
  selectCurrentTestId,
  selectAllTests,
  (testId, tests) => {
    const currentTest = tests.find(test => test.id === testId)
    if (currentTest) {
      return currentTest
    }
  }
)

export const selectTestInRouteByStudent = createSelector(
  selectCurrentTestId,
  selectAllTests,
  selectCurrentMemberId,
  (testId, tests, memberId) => {
    const currentTest = tests.find(test => test.id === testId)
    if (currentTest) {
      return {
        ...currentTest,
        sessions: currentTest.sessions.filter(s => s.members.includes(memberId))
      }
    }
  }
)

export const selectProgressForTestInRoute = createSelector(
  selectTestInRoute,
  selectAllProgression,
  (testInRoute, progress) => {
    if (testInRoute) {
      return progress.filter(
        progressPost => progressPost.testId === testInRoute.id
      )
    }
  }
)

export const selectTestsByStudent = createSelector(
  selectAllTests,
  selectCurrentMemberId,
  (tests, memberId) =>
    tests
      .filter(t => t.sessions.some(s => s.members.includes(memberId)))
      .map(t => ({
        ...t,
        sessions: t.sessions.filter(s => s.members.includes(memberId))
      }))
)

export const selectProgressionByStudent = createSelector(
  selectAllProgression,
  selectCurrentMemberId,
  (progress, memberId) => progress.filter(p => p.memberId === memberId)
)

export const selectAnswersForTestInRoute = createSelector(
  selectTestInRoute,
  selectAllProgressionAnswers,
  (testInRoute, answers) => {
    if (testInRoute) {
      return answers
        .filter(answer => answer.testId === testInRoute.id)
        .map(testAnswer => {
          return { ...testAnswer, answer: JSON.parse(testAnswer.answer) }
        })
    }
  }
)

export const selectAnswersForTestInRouteForMember = createSelector(
  selectTestInRoute,
  selectCurrentMemberId,
  selectAllProgressionAnswers,
  (testInRoute, memberId, answers) => {
    if (testInRoute) {
      return answers
        .filter(
          answer =>
            answer.testId === testInRoute.id && answer.memberId === memberId
        )
        .map(testAnswer => {
          return { ...testAnswer, answer: JSON.parse(testAnswer.answer) }
        })
    }
  }
)
