import React, { useState } from "react"
import { ContentAdd } from "../CreateAssignmentForm/types"
import { StyledDialogContent } from "./StyledAssignmentDialogContent"
import AssignmentContentForm from "../AssignmentContentForm"
import { Formik, FormikActions } from "formik"
import * as Yup from "yup"
import {
  AssignmentTask,
  AssignmentTaskTypes
} from "../../modules/assignments/types"
import { Product } from "../../modules/products/types"
import { parseTextAndAudio } from "../../shared/tools/parse"
import { BookChapterNode } from "../../modules/book/types"
import { testForAudioOrText } from "./shared"

type Props = {
  contentType: ContentAdd
  handleCancel: () => void
  addAssignmentTask: Function
  assignmentTask?: AssignmentTask | null
  getEmptyAssignmentTask: Function
  addMultipleAssignmentTasks: Function
  activeProduct: Product
}

const toTextAndAudio = parseTextAndAudio(
  () => ({ text: "", audio: "" }),
  d => ({ text: d.text || "", audio: d.audio || "" })
)

const AssignmentDialogContent = ({
  contentType,
  handleCancel,
  addAssignmentTask,
  assignmentTask,
  getEmptyAssignmentTask,
  addMultipleAssignmentTasks,
  activeProduct
}: Props) => {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [currentBookSelected, setCurrentBookSelected] =
    useState<BookChapterNode | null>(null)

  const getInitialValues = (): AssignmentTask => {
    if (assignmentTask) {
      if (assignmentTask.type === "book") {
        assignmentTask.pages =
          assignmentTask.link && assignmentTask.link.split("page=")[1]
      }
      const parsedAssignmentTask = { ...assignmentTask }
      parsedAssignmentTask.instruction = toTextAndAudio(
        parsedAssignmentTask.instruction as string
      )
      parsedAssignmentTask.question = toTextAndAudio(
        parsedAssignmentTask.question as string
      )

      return parsedAssignmentTask
    }
    const task: AssignmentTask = {
      id: (Math.floor(Math.random() * 1000000000) + 1).toString(),
      type: getTaskTypeFromContentType(contentType),
      title: "",
      instruction: { text: "", audio: "" },
      answer: "",
      question: { text: "", audio: "" },
      pages: "",
      link: ""
    }

    if (currentBookSelected && !currentBookSelected.children) {
      task.title = currentBookSelected.title
      task.pages =
        currentBookSelected.lastPage > 1
          ? `1-${currentBookSelected.lastPage.toString()}`
          : "1"

      return task
    }

    return task
  }

  const getValidationSchema = (contentType: ContentAdd) => {
    switch (contentType) {
      case "fromBook":
        return Yup.object({
          title: Yup.string().required(
            "Det saknas en titel. Skriv en titel som ska visas för eleverna."
          ),
          instruction: Yup.string().notRequired()
        })
      case "openQuestion":
        return Yup.object({
          title: Yup.string().required(
            "Det saknas en titel på frågan. Skriv en titel som ska visas för eleverna."
          ),
          question: Yup.mixed().test(
            "open-question",
            "Det saknas en fråga. Skriv och/eller spela in frågan.",
            function (value) {
              const { path, createError } = this

              return testForAudioOrText(path, createError, value)
            }
          ),
          answer: Yup.string().notRequired()
        })
      case "pagesInBook":
        return Yup.object({
          title: Yup.string().required(
            "Det saknas en titel. Skriv en titel som ska visas för eleverna."
          ),
          pages: Yup.string()

            .test(
              "test-pages",
              "Det saknas sidhänvisning till boken. Skriv eller välj vilka sidor som ska läggas till i uppdraget.",
              function (value) {
                const { path, createError } = this
                if (!value) {
                  return false
                }
                if (!currentBookSelected) {
                  return false
                }
                if (
                  currentBookSelected.stackPages &&
                  !value.match(/^[0-9]+$/)
                ) {
                  return createError({
                    path,
                    message:
                      "Boken tillåter ingen intervall, skriv endast ett sidnummer"
                  })
                }
                if (!value.match(/(^[0-9]+$)|(^[0-9]+-[0-9]+$)/)) {
                  return createError({
                    path,
                    message:
                      "Skriv in sidnummer enligt ex 1 eller ett intervall 7-13"
                  })
                }

                const pages = value.split("-")
                if (pages.length === 2) {
                  const startPage = +pages[0]
                  const endPage = +pages[1]
                  if (startPage >= endPage) {
                    return createError({
                      path,
                      message:
                        "Startsidan kan inte vara efter eller samma som sista sidan  "
                    })
                  }

                  if (!currentBookSelected.lastPage) {
                    return true
                  }

                  if (endPage > +currentBookSelected.lastPage) {
                    return createError({
                      path,
                      message: `Boken har endast ${currentBookSelected.lastPage} sidor`
                    })
                  }
                } else {
                  if (value > +currentBookSelected.lastPage) {
                    return createError({
                      path,
                      message: `Boken har endast ${currentBookSelected.lastPage} sidor`
                    })
                  }
                }

                return true
              }
            )
            .required(
              "Det saknas sidhänvisning till boken. Skriv eller välj vilka sidor som ska läggas till i uppdraget."
            ),
          instruction: Yup.string().notRequired()
        })
      case "externalLink":
        return Yup.object({
          title: Yup.string().required(
            "Det saknas en titel på länken. Skriv en titel som ska visas för eleverna."
          ),
          instruction: Yup.string().notRequired(),
          link: Yup.string()
            .matches(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
              { message: "Ange en giltig länk", excludeEmptyString: true }
            )
            .required(
              "Det saknas länk. Kopiera och klistra in den länk som eleverna ska använda."
            )
        })
      default:
        return null
    }
  }

  const getTaskTypeFromContentType = (
    contentType: ContentAdd
  ): AssignmentTaskTypes => {
    switch (contentType) {
      case "pagesInBook":
        return "book"
      case "openQuestion":
        return "open-question"
      case "externalLink":
        return "link"
      default:
        return "exercise"
    }
  }

  const handleSubmit = (
    values: AssignmentTask & { [key: string]: any },
    actions: FormikActions<AssignmentTask>
  ) => {
    actions.setSubmitting(true)
    const taskType: AssignmentTaskTypes =
      getTaskTypeFromContentType(contentType)

    Object.keys(values).forEach(key => {
      if (values[key]) {
        if (values[key].text || values[key].text === "" || values[key].audio) {
          values[key] = JSON.stringify(values[key])
        }
      }
    })
    if (taskType === "book") {
      if (values.pages) {
        values.pages = values.pages.toString()
        const splitPages = values.pages.split("-")
        values.book = currentBookSelected ? currentBookSelected.bookId : ""
        values.link = `${values.book}#page=${
          splitPages.length > 1
            ? `${splitPages[0]}-${splitPages[1]}`
            : splitPages[0]
        }`
        if (!values.exercisePath) {
          values.exercisePath = currentBookSelected
            ? currentBookSelected.title
            : ""
        }
      }
    }
    addAssignmentTask({ ...values, type: taskType })
  }
  const isEditingTask = assignmentTask ? true : false

  return (
    <>
      <StyledDialogContent>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(contentType)}
          validateOnBlur={true}
          enableReinitialize={!isDirty}
        >
          {formikProps => (
            <AssignmentContentForm
              {...formikProps}
              contentType={contentType}
              handleCancel={handleCancel}
              setIsDirty={setIsDirty}
              addMultipleAssignmentTasks={addMultipleAssignmentTasks}
              getEmptyAssignmentTask={getEmptyAssignmentTask}
              activeProduct={activeProduct}
              isEditingTask={isEditingTask}
              currentBookSelected={currentBookSelected}
              setCurrentBookSelected={setCurrentBookSelected}
            />
          )}
        </Formik>
      </StyledDialogContent>
    </>
  )
}

export default AssignmentDialogContent
