import React, { useState, useEffect } from "react"
import { connect, useSelector } from "react-redux"
import ClassCodeSvg from "./ClasscodeSvg"
import * as routerActions from "../../modules/router/actions"
import * as routerSelectors from "../../modules/router/selectors"
import tomoyoBg from "../../assets/images/mr_tile.jpg"
import { ReactComponent as SupportSVG } from "../../assets/images/card-support.svg"
import { ReactComponent as TomoyoSVG } from "../../assets/images/Tomoyo_bokhyllan_2022.svg"
import { ReactComponent as NewsSVG } from "../../assets/images/classroom.svg"
import { ReactComponent as AssignmentsSVG } from "../../assets/images/card-assignments.svg"
import { ReactComponent as ExercisesSVG } from "../../assets/images/card-exec.svg"
import { ReactComponent as TipsSVG } from "../../assets/images/card-tips.svg"
import { ReactComponent as GoalSVG } from "../../assets/images/card-target.svg"
import { ReactComponent as TestSVG } from "../../assets/images/card-test.svg"
import { ReactComponent as AddStudentSVG } from "../../assets/images/card-add-user.svg"
// import { ReactComponent as WelcomeSummerSVG } from "../../assets/images/welcome-summer.svg"
import { ReactComponent as IdeasSVG } from "../../assets/images/ideas.svg"
import { CardProps } from "../../components/Card/types"
import Card from "../../components/Card/Card"

import { StyledTomoyoBackgroundSvg } from "./StyledStart"
import { RootState } from "../../modules/store"
import {
  selectStudentsLength,
  selectMembersWithActiveProduct
} from "../../modules/members/selectors"
import {
  selectClassroomCode,
  selectClassroomStickers,
  selectClassroomTip,
  selectClassroomName,
  selectClassroomFeatures,
  selectTypeOfClassroom,
  selectClassroomSchoolUnitCode
} from "../../modules/classroom/selectors"
import {
  selectClassroomActiveProductInfo,
  selectActiveProductName
} from "../../modules/products/selectors"
import { Product } from "../../modules/products/types"
import {
  selectAllAssignmentsProgress,
  selectAllAssignmentsTaskProgress
} from "../../modules/assignmentsProgress/selectors"
import { selectAllAssignments } from "../../modules/assignments/selectors"
import {
  Assignments,
  AssignmentsWithStatusAndProgression,
  AssignmentWithStatusAndProgression
} from "../../modules/assignments/types"
import {
  AssignmentsProgress,
  AssignmentsTaskProgress
} from "../../modules/assignmentsProgress/types"
import { createAssignmentsArrayWithStatusAndProgress } from "../Assignments/common/helpers"
import { Members } from "../../modules/members/types"
import { Stickers, ClassroomTip } from "../../modules/classroom/types"
import Dialog from "../../components/Dialog"
import ExercisesProgressDialog from "../../components/ExercisesProgressDialog"
import { MembersWithOnlineStatus } from "../Exercises/store/types"
import { statusSortWeight } from "../../pages/Assignments/Assignments"
import moment from "moment"
import ClassroomCodeDialog from "../../components/ClassroomCodeDialog"
import { convertURIToRoute } from "../../routes"
import { Grid, Box } from "@mui/material"
import AssignmentAndTestList from "../../components/AssignmentAndTestList/AssignmentAndTestList"
import { selectAllTests } from "../../modules/formative/selectors"
import { isDillProduct } from "../../shared/tools/is_dill_product"
import { doesCurrentUserHasATeacherGuide } from "../../modules/users/selectors"
import ChooseGroupDialog from "../../components/ChooseGroupButton/ChooseGroupDialog"

type StateProps = {
  classroomId: number
  studentsInClassroomCount: number
  classroomCode: string
  classroomName: string
  productInfo: Product
  assignments: Assignments
  assignmentsProgress: AssignmentsProgress
  assignmentsTaskProgress: AssignmentsTaskProgress
  members: Members
  stickers: Stickers
  tip?: ClassroomTip
}

type DispatchProps = {
  goToRoute: (type: string, payload: object) => void
}

type Props = StateProps & DispatchProps

const ASSIGNMENTS_TO_SHOW = ["coming", "ongoing", "passed"]

export const StartCards = ({
  classroomId,
  studentsInClassroomCount,
  classroomCode,
  classroomName,
  productInfo,
  assignments,
  assignmentsProgress,
  assignmentsTaskProgress,
  members,
  stickers,
  tip,
  goToRoute
}: Props) => {
  const [assignmentsWithStatus, setAssignmentsWithStatus] =
    useState<AssignmentsWithStatusAndProgression | null>(null)

  const [progressDialogData, setProgressDialogData] =
    useState<AssignmentWithStatusAndProgression | null>(null)

  const [openChooseGroupDialog, setOpenChooseGroupDialog] =
    React.useState(false)
  const productName = useSelector(selectActiveProductName)
  const tests = useSelector(selectAllTests).filter(t => t.status === "ongoing")
  const classroomSchoolUnitCode = useSelector(selectClassroomSchoolUnitCode)
  const hasTeacherGuide = useSelector(doesCurrentUserHasATeacherGuide)
  const goToGroups = () => {
    window.open(
      `${process.env.REACT_APP_BOOKSHELF_URL}/${classroomSchoolUnitCode}/admin/grupper`,
      "_blank",
      "noopener noreferrer"
    )
  }
  const [
    hasStudentsOrAssignmentsInClassroom,
    setHasStudentsOrAssignmentsInClassroom
  ] = useState<boolean | null>(null)

  const [classCodeDialogOpen, setClassCodeDialogOpen] = useState<boolean>(false)
  const classroomFeatures = useSelector(selectClassroomFeatures)
  const classroomtype = useSelector(selectTypeOfClassroom)
  useEffect(() => {
    if (assignments.length === 0 && assignmentsWithStatus) {
      setAssignmentsWithStatus(null)
    }

    if (assignments.length) {
      let newAssignmentsWithStatus =
        createAssignmentsArrayWithStatusAndProgress(
          assignments,
          assignmentsProgress,
          assignmentsTaskProgress,
          members,
          classroomtype === "adapt"
        )

      newAssignmentsWithStatus = newAssignmentsWithStatus
        .filter(assignment =>
          ASSIGNMENTS_TO_SHOW.some(
            status => status === assignment.calculatedStatus
          )
        )
        .sort(
          (a, b) =>
            statusSortWeight.indexOf(a.calculatedStatus) -
              statusSortWeight.indexOf(b.calculatedStatus) ||
            moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
        )
      setHasStudentsOrAssignmentsInClassroom(true)
      setAssignmentsWithStatus(newAssignmentsWithStatus)
    }

    if (studentsInClassroomCount === 0) {
      setHasStudentsOrAssignmentsInClassroom(
        assignments.length > 0 ? true : false
      )
    }

    if (studentsInClassroomCount > 0) {
      setHasStudentsOrAssignmentsInClassroom(true)
    }

    // eslint-disable-next-line
  }, [
    assignments,
    assignmentsTaskProgress,
    assignmentsProgress,
    members,
    studentsInClassroomCount
  ])

  const goToChooseGroupDialog = () => {
    setOpenChooseGroupDialog(true)
  }
  const goToAssignmentCard = (assignmentId: number) => {
    goToRoute(routerActions.ROUTE_UPPDRAG_INFO, { classroomId, assignmentId })
  }

  const getContactLink = () => {
    const url = process.env.REACT_APP_BOOKSHELF_URL
    const encodedURI = encodeURIComponent(window.location.href)
    return `${url}/kontakta-oss?url=${encodedURI}`
  }

  /* const welcomeBackFromSummerContent: CardProps = {
    id: "welcomeBackFromSummer",
    title:
      productInfo.type === "adapt"
        ? "Välkommen tillbaka till Tomoyo!"
        : "Välkommen tillbaka till ditt klassrum!",
    content: [
      productInfo.type === "adapt"
        ? "Nu efter sommaren kommer du och dina elever tillbaka till ett Tomoyo med flera efterlängtade funktioner. Möjlighet att: <ul><li>eleven själv kan välja äventyr</li><li>du som lärare kan flytta elever till valfritt startmål</li><li>du som lärare kan skicka ut uppdrag</li><li>följa elevernas arbete närmare i klassrummet</li><li>fortsätta med Tomoyo i åk 4 och 5</li></ul>"
        : "Du vet väl att du kan hantera elevernas användaruppgifter och aktivera deras digitala läromedel här i klassrummet? För dig som har köpt en klasslicens finns nu ett ännu smidigare sätt att aktivera den för dina elever, här i klassrummet. "
    ],
    cardImage: <WelcomeSummerSVG />,
    actions:
      productInfo.type === "adapt"
        ? []
        : [
            {
              caption: "Gå till elever",
              to: {
                type: routerActions.ROUTE_LAGGTILL,
                payload: { classroomId }
              }
            },
            {
              caption: "Gå till aktiverade läromedel",
              to: {
                type: routerActions.ROUTE_AKTIVERADE,
                payload: { classroomId }
              }
            }
          ],
    fullWidth: true
  } */
  const studentInClassroomContent: CardProps = {
    id: "students",
    title: "Visa elever i klassrummet",
    content: [
      `Välj den eller de grupper med elever som du vill visa och följa upp i klassrummet. Du måste själv vara lärare i gruppen för att kunna välja den. Hanterar dina grupper gör du i Min bokhylla.`
    ],
    cardImage: <AddStudentSVG />,
    actions: [
      {
        caption: "Välj grupper",
        clickHandler: goToChooseGroupDialog
      },
      {
        caption: "Gå till Grupper",
        clickHandler: goToGroups
      }
    ]
  }
  const newsCardContent: CardProps = {
    id: "newsCard",
    title: "Nyheter i klassrummet",
    content: [
      `Det har blivit ännu enklare att följa dina elever i klassrummet.`,
      `<br/>`,
      `<ul>
      <li>Välj vilket läromedel och vilka grupper du vill visa.</li>
      <li>Skicka ut ett uppdrag till flera grupper samtidigt.</li>
      <li>Du kan också dela uppdrag med dina kollegor på skolan.</li>
      </ul>`
    ],
    cardImage: <NewsSVG />,
    background: <Box width="100%" height="100%" sx={{ bgcolor: "#B7C5F9" }} />,
    links: [
      {
        href: `${process.env.REACT_APP_BOOKSHELF_URL}/info`,
        caption: "Läs mer om nyheterna här"
      }
    ]
  }

  /**
   * Intro Tomoyo card
   */
  const adaptUrl = process.env.REACT_APP_ADAPT_URL
  const tomoyoCardContent: CardProps = {
    id: "tomoyo",
    title: "Så kommer eleverna igång med Tomoyo",
    content: [
      `Eleverna går till minbokhylla.studentlitteratur.se och loggar in. I deras bokhylla finns <i>Tomoyo</i> tillsammans med <i>Favorit matematik</i> och <i>Matteblixt</i>.`,
      `<br/>`,
      `<i>Tomoyo</i> startar med en introduktion. I introduktionen får eleverna:
      <ul>
      <li>veta mer om karaktärerna i berättelserna</li>
      <li>vad Tomoyo går ut på</li>
      <li>välja årskurs och äventyr</li>
      </ul>`,
      `Därefter kan eleverna direkt börja räkna.`,
      `<br/>`,
      `Eleverna kan gå igenom introduktionen själva – all text är inläst. Du som lärare kan också gå igenom introduktionen i helklass. Du kan visa introduktionen hur många gånger du vill.`
    ],
    background: <StyledTomoyoBackgroundSvg />,
    image: tomoyoBg,
    cardImage: <TomoyoSVG />,
    links: [
      {
        href: `${adaptUrl}?onboarding=true&theme=primary-school`,
        caption: "Introduktion för lågstadiet"
      },
      {
        href: `${adaptUrl}?onboarding=true&theme=middle-school`,
        caption: "Introduktion för mellanstadiet"
      }
    ]
  }

  /**
   * Goal Card
   */

  const goalCardContent: CardProps = {
    id: "goal",
    title: "Målen i Tomoyo",
    content: [
      `Övningarna i Tomoyo är sorterade i mål. Eleverna arbetar med målen i tur och ordning. När de är klara med ett mål går de vidare till nästa. Här i klassrummet kan du se målen och följa upp elevernas progression.`
    ],
    cardImage: <GoalSVG />,
    actions: [
      {
        caption: "Gå till mål",
        to: {
          type: routerActions.ROUTE_MAL,
          payload: { classroomId }
        }
      }
    ]
  }
  /**
   * Contact card
   */

  const contactCardContent: CardProps = {
    id: "contact",
    title: "Kontakta oss",
    content: [
      `Är det något du undrar över när ni arbetar i ${productName} eller i klassrummet? Kontakta oss gärna så hjälper vi dig.`
    ],
    cardImage: <SupportSVG />,
    links: [
      {
        href: getContactLink(),
        caption: "Kontakta oss"
      }
    ]
  }

  const testCardContent: CardProps = {
    id: "test",
    title: "Få koll med formativa frågor",
    content: [
      `Du kan skicka formativa frågor i test till dina elever. Använd testerna för att få återkoppling på vad eleverna kan inom ett område. Enkelt och snabbt väljer du bland förberedda frågor och skickar ut. Du får direkt en översikt över elevernas resultat och svar.`
    ],
    cardImage: <TestSVG />,
    actions: [
      {
        caption: "Gå till formativa frågor",
        to: {
          type: routerActions.ROUTE_FORMATIVA_FRAGOR,
          payload: { classroomId }
        }
      }
    ]
  }

  /**
   * ClassCode card
   */
  const classCodeCardContent: CardProps = {
    id: "classCode",
    title: "Elever kan gå med i grupp på skolenhet med länk eller kod",
    content: [
      `Vill du att eleverna själva ska gå med i din grupp? Gör så här:`,
      `<br/>`,
      `<ol><li>Gå till grupper i <i>Min bokhylla</i></li><li>Kopiera grupplänken och skicka ut till eleverna eller visa gruppkoden i helskärm för klassen</li><li>Eleverna loggar in på sitt användarkonto i <i>Min bokhylla</i>
      <ol style="list-style-type:lower-alpha;"><li>Med länk: de går in på grupplänken som du skickat</li><li>Med kod: De väljer "Gå med i grupp" i <i>Min bokhylla</i> och skriver in koden till din grupp</li></ol></li>
      <li>Eleverna läggs till i gruppen och även skolenheten om de inte redan är tillagda där</li></ol>`
    ],
    cardImage: (
      <ClassCodeSvg classcode={classroomCode} height={"90%"} width={"90%"} />
    ),
    actions: [
      {
        caption: "Gå till Grupper",
        clickHandler: goToGroups
      }
    ]
  }

  /**
   * Exercise Card
   */
  const exercises: CardProps = {
    id: "exercises",
    title: "Översikt över läromedlets övningar",
    content: [
      'I övningsöversikten hittar du de olika övningarna i läromedlet. Mappstrukturen motsvarar den som eleverna ser i sitt läromedel under menyn "Övningar". Du kan se vilka övningar eleverna är klara med, vilka övningar de har påbörjat och vilka de inte påbörjat ännu.'
    ],
    cardImage: <ExercisesSVG />,
    actions: [
      {
        caption: "Gå till övningar",
        to: {
          type: routerActions.ROUTE_OVNINGAR,
          payload: { classroomId }
        }
      }
    ]
  }

  /**
   * Assignment Card
   */
  const assignmentsCard: CardProps = {
    id: "assignments",
    title: "Lägg till uppdrag",
    content: [
      `Uppdrag är tidsbegränsade uppgifter som du kan skicka till dina elever. Använd uppdrag för att hantera exempelvis läxor eller veckouppgifter. Det blir lättare för eleverna att veta vad de ska göra och enklare för dig att följa upp.`
    ],
    cardImage: <AssignmentsSVG />,
    actions: [
      {
        caption: "GÅ TILL UPPDRAG",
        to: {
          type: routerActions.ROUTE_UPPDRAG,
          payload: { classroomId }
        }
      }
    ]
  }

  /**
   * Adapt tips Card
   */
  const adaptTipsCardContent: CardProps = {
    id: "adaptTips",
    title: "3 Snabba kom igång-tips",
    content: [
      `<ul>
      <li>Lägg till nya elever i grupper så du kan följa dem i klassrummet.</li>
      <li>Kolla vilka mål dina elever arbetade på senast och ändra vid behov för någon/några elever. Kom ihåg att du och dina elever har tillgång till all matematik från årskurs 1 till 6. Du ändrar målen i elevlistan.</li>
      <li>När eleverna startar Tomoyo får de välja den årskurs de går i för att komma till de nya äventyren i spelet. I matematiken fortsätter de från sitt aktuella mål. Nya elever börjar på den valda årskursens startmål eller det startmål du valt åt dem.</li>
      </ul>`
    ],
    cardImage: <IdeasSVG />,
    actions: [
      {
        caption: "GÅ TILL ELEVER",
        to: {
          type: routerActions.ROUTE_ELEVER,
          payload: { classroomId }
        }
      }
    ]
  }

  /**
   * Tips Card
   */

  const tipsCard = (classroomTip: ClassroomTip) => {
    return {
      id: "tip",
      title: classroomTip.title,
      content: [classroomTip.content],
      cardImage: <TipsSVG />,
      actions: classroomTip.actions.map(action => {
        const { type, payload } = convertURIToRoute(
          action.uri,
          classroomId
        ) || {
          type: "",
          payload: {}
        }

        return {
          caption: action.caption,
          to: {
            type: type,
            payload: payload
          }
        }
      })
    }
  }

  /**
   * Kort för uppdrags-lista
   */
  const assignmentsList: CardProps = {
    id: "assignmentList",
    customContent: (
      <AssignmentAndTestList
        assignments={assignmentsWithStatus ? assignmentsWithStatus : []}
        onAssignmentClick={goToAssignmentCard}
        stickers={stickers}
        onProgressClick={setProgressDialogData}
      />
    ),
    fullWidth: true
  }
  const renderCard = (content: CardProps) => {
    return (
      <Grid key={content.id} item xs={12} sm={content.fullWidth ? 12 : 6}>
        <Card {...content} />
      </Grid>
    )
  }

  const renderCardList = (cardList: CardProps[]) => {
    return cardList.map((card: CardProps) => renderCard(card))
  }

  const cardListToRender = () => {
    let cardList: CardProps[] = [newsCardContent]

    //Classcode (allways visible)
    cardList = [...cardList, classCodeCardContent]

    // AddMember (allways visible)
    cardList = [...cardList, studentInClassroomContent]

    // tomoyo and goal
    if (productInfo.type && productInfo.type === "adapt") {
      cardList = [
        ...cardList,
        tomoyoCardContent,
        goalCardContent,
        adaptTipsCardContent
      ]

      if (!showAssignmentsList) {
        cardList = [...cardList, assignmentsCard]
      }
    }
    // exercises and assignments
    if (productInfo.type && isDillProduct(productInfo.type)) {
      cardList = [...cardList, exercises]

      if (!showAssignmentsList) {
        cardList = [...cardList, assignmentsCard]
      }
    }
    // tips
    if (hasStudentsOrAssignmentsInClassroom && tip) {
      cardList = [...cardList, tipsCard(tip)]
    }

    // Formativa frågor
    if (classroomFeatures.some(feature => feature === "FORMATIVE_TESTS")) {
      if (tests.length < 1 && hasTeacherGuide) {
        cardList = [...cardList, testCardContent]
      }
    }
    // Contact
    if (cardList.length % 2 === 0) {
      contactCardContent.fullWidth = true
    }
    cardList = [...cardList, contactCardContent]

    // assignmentsList
    if (tests.length > 0 || showAssignmentsList) {
      cardList = [assignmentsList, ...cardList]
    }

    return cardList
  }

  const renderClassCodeTextDialog = () => {
    return (
      <Dialog
        onClose={() => setClassCodeDialogOpen(false)}
        aria-labelledby="klassrumskod-modal"
        open={classCodeDialogOpen}
        maxWidth="sm"
      >
        <ClassroomCodeDialog
          onClose={() => setClassCodeDialogOpen(false)}
          classroomCode={classroomCode}
          classroomName={classroomName}
        />
      </Dialog>
    )
  }

  const renderProgressDialog = () => {
    return (
      progressDialogData !== null && (
        <Dialog
          onClose={() => setProgressDialogData(null)}
          open={progressDialogData !== null}
          maxWidth="sm"
          fullWidth
        >
          <ExercisesProgressDialog
            onClose={() => setProgressDialogData(null)}
            inProgress={
              progressDialogData.progress.inProgress as MembersWithOnlineStatus
            }
            done={progressDialogData.progress.done as MembersWithOnlineStatus}
            notStarted={
              progressDialogData.progress.notStarted as MembersWithOnlineStatus
            }
            type="assignments"
            title={progressDialogData.title}
          />
        </Dialog>
      )
    )
  }
  const showAssignmentsList = assignmentsWithStatus?.length

  return (
    <Grid container spacing={3}>
      {renderCardList(cardListToRender())}
      {renderProgressDialog()}
      {renderClassCodeTextDialog()}
      {openChooseGroupDialog && (
        <ChooseGroupDialog
          onClose={() => setOpenChooseGroupDialog(false)}
          open={openChooseGroupDialog}
        />
      )}
    </Grid>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => {
  return {
    classroomId: routerSelectors.selectCurrentClassroomId(state),
    studentsInClassroomCount: selectStudentsLength(state),
    classroomCode: selectClassroomCode(state),
    classroomName: selectClassroomName(state),
    productInfo: selectClassroomActiveProductInfo(state),
    assignments: selectAllAssignments(state),
    assignmentsProgress: selectAllAssignmentsProgress(state),
    assignmentsTaskProgress: selectAllAssignmentsTaskProgress(state),
    members: selectMembersWithActiveProduct(state),
    stickers: selectClassroomStickers(state),
    tip: selectClassroomTip(state)
  }
}

const mapDispatchToProps: DispatchProps = {
  goToRoute: routerActions.gotoRoute
}

const ConnectedStartCards = connect(
  mapStateToProps,
  mapDispatchToProps
)(StartCards)

export default ConnectedStartCards
