import { Box, BoxProps } from "@mui/material";
import { getMargins, getPadding } from "./getSxMargins";

type Props = {
  usePadding?: boolean;
  useMargin?: boolean;
  children: React.ReactNode;
};

const FullWidthContainer = ({
  children,
  usePadding,
  useMargin,
  ...rest
}: Props & BoxProps) => {
  return (
    <Box
      {...rest}
      sx={{
        ...(useMargin && { margin: getMargins() }),
        ...(usePadding && { padding: getPadding() }),
      }}
    >
      {children}
    </Box>
  );
};
export default FullWidthContainer;
