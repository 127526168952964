import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  useMediaQuery,
  Theme
} from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { selectGoalsWithProgress } from "../../modules/goals/selectors"
import { selectProgressForCurrentStudent } from "../../modules/goalsProgress/selectors"
import { selectStudentByPayload } from "../../modules/members/selectors"
import { selectActiveProductName } from "../../modules/products/selectors"
import AbilityIndicator from "../AbilityIndicator"
import { StyledStatusTag } from "../AssignmentStatusAndApprovedTags/StyledAssignmentStatusAndApprovedTags"
import useChooseGoalsDialog from "../SetStartGoalDialog/useChooseGoalsDialog"
import Duration from "../Duration"
import InfoPopover from "../InfoPopover"
import LastActive from "../LastActive"
import {
  StyledAbilityStarContent,
  StyledAbilityStars,
  StyledAbilityStarWrapper
} from "../MembersTable/StyledMembersTable"
import Select from "../Select"
import { StyledNoInfo } from "../StudentHistoryList/StyledHistoryList"

const StudentGoalList = () => {
  const goals = useSelector(selectGoalsWithProgress)
  const member = useSelector(selectStudentByPayload)
  const activeProduct = useSelector(selectActiveProductName)
  const goalProgressForMember = useSelector(selectProgressForCurrentStudent)
  const { currentGoals, filters, selectedFilter, onFilter } =
    useChooseGoalsDialog({
      goals,
      members: []
    })
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"))
  const hasProgress = (goalRef: string) => {
    return goalProgressForMember.some(p => p.goalRef === goalRef)
  }

  const getStatus = (goalRef: string) => {
    const progress = goalProgressForMember.find(p => p.goalRef === goalRef)
    if (progress) {
      return {
        value: progress.isInProgress ? "inProgress" : "done",
        label: progress.isInProgress ? "Påbörjat" : `Klar`,
        time: progress.lastActive,
        activeTime: progress.goalActiveTimeMilliseconds,
        abilityGrade: progress.goalAbilityGrade,
        nbrExercises: progress.goalExercisesCount
      }
    }
  }

  const filteredGoals = currentGoals
    .filter(g => hasProgress(g.goalRef))
    .reverse()

  return (
    <>
      {filters && (
        <Box sx={{ width: "8rem" }}>
          <Select
            selectLabel="Visa"
            selected={selectedFilter as number}
            options={Object.keys(filters).map((key, i) => ({
              label: filters[+key].title,
              value: filters[+key].value,
              id: filters[+key].title
            }))}
            onChangeCallback={onFilter}
          />
        </Box>
      )}

      {filteredGoals.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mål</TableCell>
              <TableCell>
                Aktiv tid
                <InfoPopover
                  type="Info"
                  content={
                    <Typography variant="body2">
                      Tid arbetat på målet
                    </Typography>
                  }
                />
              </TableCell>
              <TableCell>Antal övningar</TableCell>
              {!isMobile && (
                <>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    Svårighetsnivå
                    <InfoPopover
                      type="Info"
                      content={
                        <div>
                          <Typography variant="body2">
                            Svårighetsnivå på övningarna eleven arbetar på just
                            nu.
                          </Typography>
                          <Typography variant="body2">
                            Släckt stjärna = eleven har just börjat arbeta på
                            målet och systemet har för lite data för att räkna
                            ut svårighetsnivå.
                          </Typography>
                          <StyledAbilityStars>
                            {[
                              "Lätt",
                              "Lätt/Medel",
                              "Medel",
                              "Medel/Svår",
                              "Svår"
                            ].map((difficulty: string, index: number) => (
                              <StyledAbilityStarWrapper key={difficulty}>
                                <StyledAbilityStarContent>
                                  <AbilityIndicator grade={index + 1} />
                                </StyledAbilityStarContent>
                                <StyledAbilityStarContent>
                                  <Typography variant="caption">
                                    {difficulty}
                                  </Typography>
                                </StyledAbilityStarContent>
                              </StyledAbilityStarWrapper>
                            ))}
                          </StyledAbilityStars>
                        </div>
                      }
                    />
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          {isMobile ? (
            <TableBody>
              {filteredGoals.map(g => {
                const status = getStatus(g.goalRef)
                return (
                  <>
                    <TableRow key={g.goalRef}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {g.order}. {g.title}
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {status && status.activeTime < 60000 ? (
                          "< 1 min"
                        ) : (
                          <Duration duration={status?.activeTime} />
                        )}
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        {status?.nbrExercises}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {status && (
                          <StyledStatusTag
                            status={status.value as "done" | "inProgress"}
                          >
                            {status.label}
                            {status.time && <LastActive date={status.time} />}
                          </StyledStatusTag>
                        )}
                      </TableCell>
                      <TableCell colSpan={2}>
                        <AbilityIndicator grade={status?.abilityGrade} />
                      </TableCell>
                    </TableRow>
                  </>
                )
              })}
            </TableBody>
          ) : (
            <TableBody>
              {filteredGoals.map(g => {
                const status = getStatus(g.goalRef)
                return (
                  <TableRow key={g.goalRef}>
                    <TableCell>
                      {g.order}. {g.title}
                    </TableCell>
                    <TableCell>
                      {status && status.activeTime < 60000 ? (
                        "< 1 min"
                      ) : (
                        <Duration duration={status?.activeTime} />
                      )}
                    </TableCell>
                    <TableCell>{status?.nbrExercises}</TableCell>
                    <TableCell>
                      {status && (
                        <StyledStatusTag
                          status={status.value as "done" | "inProgress"}
                        >
                          {status.label}{" "}
                          {status.time && <LastActive date={status.time} />}
                        </StyledStatusTag>
                      )}
                    </TableCell>
                    <TableCell>
                      <AbilityIndicator grade={status?.abilityGrade} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      ) : (
        <StyledNoInfo>
          {member.firstName} har ännu inte påbörjat några mål i {activeProduct}
          Årskurs {selectedFilter}
        </StyledNoInfo>
      )}
    </>
  )
}

export default StudentGoalList
