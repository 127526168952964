import * as React from "react"

import { useSelector } from "react-redux"
import {
  selectProductLinks,
  selectClassroomActiveProductInfo
} from "../../modules/products/selectors"
import { StyledTitle } from "./StyledProductLinks"
import Link from "./Link"

const ProductLinks: React.FC = () => {
  const { studentLinks, teacherLinks } = useSelector(selectProductLinks)
  const { type } = useSelector(selectClassroomActiveProductInfo)

  return (
    <>
      <StyledTitle>Öppna klassrummets läromedel</StyledTitle>
      {studentLinks.length > 0 ? (
        <Link
          links={studentLinks}
          text={type === "adapt" ? "Tomoyo Matematik" : "Elevbok"}
          id="student-links"
        />
      ) : null}
      {teacherLinks.length > 0 ? (
        <Link links={teacherLinks} text="Lärarhandledning" id="teacher-links" />
      ) : null}
    </>
  )
}

export default ProductLinks
