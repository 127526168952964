import * as actions from "./actions"
import {
  ClassroomTip,
  ReceiveClassroomInfoPayload,
  ReceiveClassroomInfoUpdatePayload,
  State,
  ReceiveClassroomGroupsPayload,
  ChangeClassroomGroupPayload,
  ReceiveSchoolUnitGroupsPayload,
  Groups
} from "./types"
import { createReducer } from "../../shared/tools/redux"
import { pipe } from "fp-ts/lib/pipeable"
import { Lens } from "monocle-ts"

const initialState: State = {
  classroomCode: "",
  name: "",
  classroomId: 0,
  stickers: [],
  classroomFeatures: [],
  currentTip: undefined,
  hasClassCodes: false,
  isTestClassroom: false,
  groups: [],
  groupId: 0,
  activeGroupIds: [],
  schoolUnitGroups: [],
  schoolUnitCode: ""
}

const getCurrentOrRandomTip = (
  tips: ClassroomTip[],
  currentTip?: ClassroomTip
) => currentTip ? currentTip : tips[Math.floor(Math.random() * tips.length)];

const setClassroomInfo =
  (payload: ReceiveClassroomInfoPayload) => (state: State) => ({
    ...state,
    classroomCode: payload.classroomCode,
    name: payload.name,
    classroomId: payload.id,
    stickers: payload.stickers,
    classroomFeatures: payload.features,
    currentTip: getCurrentOrRandomTip(payload.tips || [], state.currentTip),
    hasClassCodes: payload.hasClassCodes,
    isTestClassroom: payload.isTestClassroom,
    groupId: payload.groupId,
    activeGroupIds: payload.activeGroupIds,
    schoolUnitCode: payload.schoolUnitCode
  })

const setGroups =
  (payload: ReceiveClassroomGroupsPayload) => (state: State) => ({
    ...state,
    groups: payload.groups
  })

const setSchoolUnitGroups =
  (payload: ReceiveSchoolUnitGroupsPayload) => (state: State) => ({
    ...state,
    schoolUnitGroups: payload.groups
  })

const changeClassroomGroups =
  (payload: ChangeClassroomGroupPayload) => (state: State) => ({
    ...state,
    activeGroupIds: payload.activeGroupIds
  })

const classroomGroupsRemoved = (payload: number[]) => (state: State) => ({
  ...state,
  groups: state.groups.filter(g => !payload.includes(g.id))
})

const modifyNameAndCode =
  (payload: ReceiveClassroomInfoUpdatePayload) => (state: State) => {
    const setName = Lens.fromProp<State>()("name").modify(_ => payload.name)
    const setCode = Lens.fromProp<State>()("classroomCode").modify(
      code => payload.classroomCode || code
    )

    return pipe(state, setName, setCode)
  }

const setUpdatedGroups =
  (payload: Groups) => (state: State) => ({
    ...state,
    groups: state.groups.map(g => payload.find(ug => ug.id === g.id) || g)
  })

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.addClassroomInfo, setClassroomInfo)
    .case(actions.addClassroomGroups, setGroups)
    .case(actions.addSchoolUnitGroups, setSchoolUnitGroups)

    .case(actions.updateClassroomInfoResponse, modifyNameAndCode)
    .case(actions.changeClassroomGroups, changeClassroomGroups)
    .case(actions.classroomGroupsRemoved, classroomGroupsRemoved)
    .case(actions.classroomGroupsUpdated, setUpdatedGroups)
)

export default reducer
