import { StudentWithMoreMenu } from "../store/types"

/**
 *  Sorting used in memberlist
 *
 */

import { Order } from "../../../modules/members/types"

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (typeof a[orderBy] === "string") {
    return (a[orderBy] as unknown as string).localeCompare(
      b[orderBy] as unknown as string
    )
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return -1
}

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

export function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]?: number | string | boolean | any },
  b: { [key in K]?: number | string | boolean | any }
) => number {
  return order === "asc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

export const sortMembers = (
  students: StudentWithMoreMenu[],
  memberListOrder: Order,
  memberListOrderBy: keyof StudentWithMoreMenu
): StudentWithMoreMenu[] => {
  const sortedMembersWithProduct = stableSort(
    students.filter(s => s.hasProduct),
    getSorting(memberListOrder, memberListOrderBy)
  )
  const sortedMembersWithOutProduct = stableSort(
    students.filter(s => !s.hasProduct),
    getSorting(memberListOrder, memberListOrderBy)
  )
  return [...sortedMembersWithProduct, ...sortedMembersWithOutProduct]
}
