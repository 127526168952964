import { styled } from "@mui/material"
import ListItem, { ListItemProps } from "@mui/material/ListItem"
import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText"

import Avatar from "../Avatar"
import { getColor, getBackgroundColor } from "../../pages/Members/common/styles"
import * as membersTypes from "../../modules/members/types"

export const StyledMemberListItem = styled(
  ListItem as React.FC<membersTypes.StyleProps & ListItemProps>
)`
  background-color: ${props => getBackgroundColor(props)};
  ${props =>
    props.button &&
    `
    cursor: pointer;
  `}
  ${props => props.theme.breakpoints.down("xs")} {
    padding-left: 1rem;
    span {
      padding-left: 0;
    }
  }
`

export const StyledListItemText = styled(
  ListItemText as React.FC<membersTypes.StyleProps & ListItemTextProps>
)`
  color: ${props => getColor(props)};
  flex-grow: 0;
`

export const StyledAvatar = styled(Avatar)`
  margin-right: 1em;
`

export const StyledAbilityIndicatorContainer = styled("div")`
  flex-grow: 1;
  margin-left: 1em;
`

export const StyledLink = styled("button")`
  color: #1776cc;
  background: transparent;
  border: 0;
  cursor: pointer;
`
