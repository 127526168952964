import { Breakpoint } from "@mui/material"
import * as types from "./types"

/**
 * UI actions.
 */
export enum UI {
  TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR",
  SET_VIEWPORT_WIDTH = "SET_VIEWPORT_WIDTH"
}

/**
 * Toggles Side Menu
 */
export const toggleSideBar = (): types.ToggleSideBarAction => {
  return { type: UI.TOGGLE_SIDE_BAR }
}

/**
 * Set viewport width
 */
export const setViewportWidth = (
  width: Breakpoint
): types.SetViewportWidthAction => {
  return { type: UI.SET_VIEWPORT_WIDTH, payload: width }
}
