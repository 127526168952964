import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  Button,
  styled,
  Tabs,
  Tab,
  useMediaQuery,
  Theme,
  ListItemText
} from "@mui/material"
import React, { useCallback, useState, useMemo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { editAssignment } from "../../modules/assignments/actions"
import {
  AssignmentParticipant,
  Assignment,
  AssignmentWithStatus
} from "../../modules/assignments/types"
import {
  selectAllSchoolUnitGroups,
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import { Group } from "../../modules/classroom/types"
import { FormativeTest } from "../../modules/formative/types"
import { addMemberToGroup } from "../../modules/members/actions"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import { buildName } from "../../shared/tools/strings"
import Avatar from "../Avatar"
import ConfirmationDialog from "../ConfirmationDialog"
import Dialog from "../Dialog"
import GroupChip from "../GroupChip/GroupChip"
import MoreMenu from "../PopupMenu"
import ErrorIcon from "@mui/icons-material/Error"
import GroupSelectorDialog from "../GroupSelectorDialog/GroupSelectorDialog"

const ErrorTab = styled(Tab)`
  color: #da1e28;
  display: flex;
  flex-direction: row;
  &.Mui-selected {
    color: #da1e28;
  }
`

type Props = {
  data: FormativeTest | AssignmentWithStatus
  resultTable: React.ReactNode
  type: "formative" | "assignment"
}

const ResultTabs = ({ data, resultTable, type }: Props) => {
  const dispatch = useDispatch()
  const user = useSelector(selectAuthenticatedUser)
  const schoolUnitGroups = useSelector(selectAllSchoolUnitGroups)
  const activeGroups = useSelector(selectAllActiveGroups)
  const allTeacherGroups = useSelector(selectAllGroups)
  const [membersToRemove, setMembersToRemove] = useState<
    AssignmentParticipant[] | null
  >(null)
  const [addToGroups, setAddToGroups] = useState<
    AssignmentParticipant[] | null
  >(null)

  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"))
  const [selectedTab, setSelectedTab] = React.useState<
    "inGroups" | "notInGroups" | "notInUnit" | "inSchoolUnit"
  >("inGroups")

  const onChangeTab = (
    _: React.SyntheticEvent,
    newValue: "inGroups" | "notInGroups" | "notInUnit" | "inSchoolUnit"
  ) => {
    setSelectedTab(newValue)
  }

  const membersNotInActiveGroups = useMemo(() => {
    if (!data || !activeGroups) return []
    return data?.participantsInfo.filter(p => {
      return (
        !activeGroups.some(g => p.groups.includes(g.id)) &&
        allTeacherGroups.some(g => p.groups.includes(g.id))
      )
    })
  }, [data, activeGroups, allTeacherGroups])

  const membersInOtherGroups = useMemo(() => {
    if (!data || !activeGroups) return []
    return data?.participantsInfo.filter(p => {
      return !activeGroups.some(g => p.groups.includes(g.id))
    })
  }, [data, activeGroups])

  const memberNotInAnyTeacherGroups = useMemo(() => {
    if (!data || !activeGroups) return []
    return data?.participantsInfo.filter(
      p => !allTeacherGroups.some(g => p.groups.includes(g.id))
    )
  }, [data, activeGroups, allTeacherGroups])

  const membersInActiveGroups = useMemo(() => {
    if (!data || !activeGroups) return []
    return data?.participantsInfo.filter(p => {
      return activeGroups.some(g => p.groups.includes(g.id))
    })
  }, [data, activeGroups])

  useEffect(() => {
    if (
      membersNotInActiveGroups.length === 0 &&
      selectedTab === "notInGroups"
    ) {
      setSelectedTab("inGroups")
      return
    }
    if (
      memberNotInAnyTeacherGroups.length === 0 &&
      selectedTab === "notInUnit"
    ) {
      setSelectedTab("inGroups")
      return
    }
    if (membersInOtherGroups.length === 0 && selectedTab === "inSchoolUnit") {
      setSelectedTab("inGroups")
      return
    }
  }, [
    membersNotInActiveGroups,
    memberNotInAnyTeacherGroups,
    selectedTab,
    membersInOtherGroups
  ])

  const addMembersToGroup = (groupId: number) => {
    if (!addToGroups || !data) return
    dispatch(
      addMemberToGroup(
        addToGroups.map(m => m.studliId),
        groupId
      )
    )
    setAddToGroups(null)
  }

  const getRemoveMemberName = () => {
    if (!membersToRemove) return ""
    if (membersToRemove.length > 1) {
      return "eleverna"
    }
    return buildName(membersToRemove[0].firstName, membersToRemove[0].lastName)
  }

  const removeFromAssignment = (removeMembers: AssignmentParticipant[]) => {
    if (!data) return
    const assignment = data as Assignment
    const newMembers = assignment.participants.filter(
      p => !removeMembers.some(m => m.studliId === p)
    )
    dispatch(
      editAssignment(
        { ...assignment, participants: newMembers },
        "removeMembers"
      )
    )
    setMembersToRemove(null)
  }

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={onChangeTab}
        aria-label="basic tabs example"
        scrollButtons
        allowScrollButtonsMobile
        variant={isMobile ? "scrollable" : "standard"}
      >
        <Tab
          label={`I dina valda grupper (${membersInActiveGroups.length})`}
          value="inGroups"
        />
        {membersNotInActiveGroups.length > 0 &&
          user?.studliId === data?.createdBy && (
            <Tab
              label={`I dina andra grupper (${membersNotInActiveGroups.length})`}
              value="notInGroups"
            />
          )}
        {membersInOtherGroups.length > 0 &&
          user?.studliId !== data?.createdBy && (
            <Tab
              label={`I dina andra grupper (${membersInOtherGroups.length})`}
              value="inSchoolUnit"
            />
          )}
        {memberNotInAnyTeacherGroups.length > 0 &&
          user?.studliId === data?.createdBy && (
            <ErrorTab
              value="notInUnit"
              icon={<ErrorIcon sx={{ mr: 1, mb: "0!important" }} />}
              label={`Som inte ingår i dina grupper (${memberNotInAnyTeacherGroups.length})`}
            />
          )}
      </Tabs>
      {selectedTab === "inGroups" && (
        <Stack>
          <Stack px={1} py={2}>
            <GroupChip groups={activeGroups} />
          </Stack>
          {membersInActiveGroups.length > 0 ? (
            resultTable
          ) : (
            <Stack p={2}>
              {data.participantsInfo.length === 0 && (
                <Typography>
                  För att lägga till elever till{" "}
                  {type === "assignment" ? "uppdraget" : "testet"} går du in på
                  Redigera. Du behöver ha minst 1 grupp vald för att kunna lägga
                  till elever.
                </Typography>
              )}
              {data.participantsInfo.length > 0 && (
                <Typography>
                  Ingen av eleverna i{" "}
                  {type === "assignment" ? "uppdraget" : "testet"} ingår i
                  den/de grupper du valt att visa i klassrummet.
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      )}
      {selectedTab === "notInGroups" && (
        <MembersWithGroupsTable
          members={membersNotInActiveGroups}
          groups={allTeacherGroups}
          type={type}
          isMobile={isMobile}
        />
      )}
      {selectedTab === "inSchoolUnit" && (
        <MembersWithGroupsTable
          members={membersInOtherGroups}
          groups={schoolUnitGroups}
          type={type}
          isMobile={isMobile}
        />
      )}
      {selectedTab === "notInUnit" && (
        <Stack>
          <Stack m={2} direction="row" spacing={2}>
            <Button
              onClick={() => setAddToGroups(memberNotInAnyTeacherGroups)}
              variant="outlined"
            >
              Lägg till i grupp
            </Button>
            {type === "assignment" && (
              <Button
                onClick={() => setMembersToRemove(memberNotInAnyTeacherGroups)}
                variant="outlined"
              >
                Ta bort elever
              </Button>
            )}
          </Stack>
          <MembersWithGroupsTable
            members={memberNotInAnyTeacherGroups}
            setMembersToRemove={setMembersToRemove}
            setAddToGroups={setAddToGroups}
            groups={schoolUnitGroups}
            type={type}
            hideGroups
            isMobile={isMobile}
          />
        </Stack>
      )}
      {addToGroups && (
        <GroupSelectorDialog
          title={`Välj grupp för att lägga till ${addToGroups.length} ${
            addToGroups.length > 1 ? "elever" : "elev"
          }`}
          onClose={() => setAddToGroups(null)}
          nbrOfStudents={addToGroups.length}
          onSelectGroups={addMembersToGroup}
          type={type}
        />
      )}
      {membersToRemove && (
        <Dialog
          onClose={() => setMembersToRemove(null)}
          maxWidth="xs"
          fullWidth
          open
          aria-modal="true"
          aria-labelledby="ta-bort-elever-dialog-title"
          aria-describedby="ta-bort-elever-dialog-description"
        >
          <ConfirmationDialog
            noDialogPadding
            title={`Ta bort ${
              membersToRemove.length > 1
                ? `${membersToRemove.length} elever`
                : "en elev"
            } från uppdraget`}
            bodyContent={
              <Stack>
                <Stack p={2}>
                  <Typography>
                    {membersToRemove.length > 1
                      ? `Vill du ta bort ${membersToRemove.length} elever från uppdraget? Det innebär att eleven/eleverna inte längre har tillgång till uppdraget i läromedlet. Eleven/eleverna tas inte bort från grupper utan bara från uppdraget.`
                      : `Vill du ta bort ${getRemoveMemberName()} från uppdraget? Det innebär att eleven inte längre har tillgång till uppdraget i läromedlet. Eleven tas inte bort från grupper utan bara från uppdraget.`}
                  </Typography>
                </Stack>
                <List>
                  {membersToRemove.map((m, i, arr) => (
                    <ListItem key={m.studliId} divider={i !== arr.length - 1}>
                      <ListItemAvatar>
                        <Avatar
                          studliId={m.studliId}
                          firstName={m.firstName}
                          lastName={m.lastName}
                          picture={m.pictureUrl}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={buildName(m.firstName, m.lastName)}
                        secondary={m.email}
                      />
                    </ListItem>
                  ))}
                </List>
              </Stack>
            }
            onCancel={() => setMembersToRemove(null)}
            confirmLabel={
              membersToRemove.length > 1 ? "Ta bort elever" : "Ta bort elev"
            }
            cancelLabel="Avbryt"
            onConfirm={() => removeFromAssignment(membersToRemove)}
            ariaTitleId="ta-bort-elever-dialog-title"
            ariaDescId="ta-bort-elever-dialog-description"
          />
        </Dialog>
      )}
    </>
  )
}

export default ResultTabs

const MembersWithGroupsTable = ({
  groups,
  members,
  setMembersToRemove,
  setAddToGroups,
  type,
  isMobile,
  hideGroups
}: {
  groups: Group[]
  members: AssignmentParticipant[]
  setMembersToRemove?: (members: AssignmentParticipant[]) => void
  setAddToGroups?: (members: AssignmentParticipant[]) => void
  type: "assignment" | "formative"
  isMobile?: boolean
  hideGroups?: boolean
}) => {
  const getGroups = useCallback(
    (member: AssignmentParticipant) => {
      return groups.filter(g => member.groups.includes(g.id))
    },
    [groups]
  )
  const getMenuItems = (m: AssignmentParticipant) => {
    if (!setMembersToRemove || !setAddToGroups) return []
    const menuItems = [
      {
        option: "Lägg till i grupp",
        action: () => setAddToGroups([m])
      }
    ]
    if (type === "assignment") {
      menuItems.unshift({
        option: "Ta bort från uppdrag",
        action: () => setMembersToRemove([m])
      })
    }
    return menuItems
  }
  return !isMobile ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="2em" />
          <TableCell>Namn</TableCell>
          {!hideGroups && <TableCell>Grupp</TableCell>}
          {setMembersToRemove && setAddToGroups && <TableCell />}
        </TableRow>
      </TableHead>
      <TableBody>
        {members.map(m => {
          const memberGroups = getGroups(m)
          return (
            <TableRow key={m.studliId}>
              <TableCell width="2em">
                <Avatar
                  firstName={m.firstName}
                  lastName={m.lastName}
                  studliId={m.studliId}
                  picture={m.pictureUrl}
                />
              </TableCell>
              <TableCell>
                <ListItemText
                  primary={buildName(m.firstName, m.lastName)}
                  secondary={m.email}
                />
              </TableCell>
              {!hideGroups && (
                <TableCell>
                  {memberGroups.length ? (
                    <GroupChip groups={getGroups(m)} />
                  ) : (
                    <Typography>Saknas i grupp</Typography>
                  )}
                </TableCell>
              )}
              {setMembersToRemove && setAddToGroups && (
                <TableCell align="right">
                  <MoreMenu menuItems={getMenuItems(m)} />
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  ) : (
    <List>
      {members.map((m, i) => {
        const memberGroups = getGroups(m)
        return (
          <ListItem
            key={m.studliId}
            divider={i < members.length - 1}
            secondaryAction={
              setMembersToRemove && setAddToGroups ? (
                <MoreMenu menuItems={getMenuItems(m)} />
              ) : null
            }
          >
            <Stack>
              <Stack direction="row" spacing={1}>
                <Avatar
                  firstName={m.firstName}
                  lastName={m.lastName}
                  studliId={m.studliId}
                  picture={m.pictureUrl}
                />
                <ListItemText
                  sx={{
                    wordWrap: "break-word",
                    maxWidth: "15rem"
                  }}
                  primary={buildName(m.firstName, m.lastName)}
                  secondary={m.email}
                />
              </Stack>
              {!hideGroups && (
                <>
                  {memberGroups.length ? (
                    <GroupChip groups={getGroups(m)} truncate />
                  ) : (
                    <Typography>Saknas i grupp</Typography>
                  )}
                </>
              )}
            </Stack>
          </ListItem>
        )
      })}
    </List>
  )
}
