import { CardMedia, Container, styled } from "@mui/material"

export const StyledOnBoardingLogo = styled(CardMedia)`
  width: 100%;
  height: 20rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;
  background-repeat: repeat;
  background-size: auto;
  svg {
    position: absolute;
    height: 100%;
    width: 100%;
    &:last-child {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
      height: 80%;
      max-width: 90%;
    }
  }
`
export const StyledOnBoardingContainer = styled(Container)`
  margin-bottom: 3rem;
`
export const StyledOnBoardingSection = styled("div")``
