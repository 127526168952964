import React from "react"
import ReactDomServer from "react-dom/server"

import {
  StyledWorkedLatestContainer,
  StyledWorked,
  StyledLatest,
  StyledLabel
} from "./StyledWorkedLatestContainer"

type Props = {
  children: {
    worked: React.ReactNode
    latest: React.ReactNode
  }
}

const WorkedLatestContainer: React.FC<Props> = ({
  children: { worked, latest }
}) => {
  return (
    <StyledWorkedLatestContainer>
      {shouldRenderWorked(worked) && renderWorked(worked)}
      {renderLatest(latest)}
    </StyledWorkedLatestContainer>
  )
}

export const shouldRenderWorked = (worked: React.ReactNode) => {
  return ReactDomServer.renderToString(worked as React.ReactElement).length > 0
}

const renderWorked = (worked: React.ReactNode) => {
  return (
    <StyledWorked>
      <StyledLabel>ARBETAT: </StyledLabel>
      {worked}
    </StyledWorked>
  )
}

const renderLatest = (latest: React.ReactNode) => {
  return (
    <StyledLatest>
      <StyledLabel>SENAST: </StyledLabel>
      {latest}
    </StyledLatest>
  )
}

export default WorkedLatestContainer
