import { useState, useEffect } from "react"
import { GoalWithProgress } from "../../modules/goals/types"
import { StudentInStudentList } from "../../pages/Members/store/types"
import { extractFiltersFromGoals } from "../ChooseGoalsDialog/helpers"
import { FilterOptions } from "../ChooseGoalsDialog/types"
type Props = {
  goals: GoalWithProgress[]
  members?: StudentInStudentList[]
}

type ReturnProps = {
  currentGoals: GoalWithProgress[]
  filters: FilterOptions | undefined
  selectedFilter: number | undefined
  onFilter: (val: number) => void
  currentGoalSingleUser: string
}

function useChooseGoalsDialog({ goals, members }: Props): ReturnProps {
  const [currentGoals, setCurrentGoals] = useState<GoalWithProgress[]>([])
  const [filters, setFilters] = useState<FilterOptions>()
  const [selectedFilter, setSelectedFilter] = useState<number>(1)
  const [currentGoalSingleUser, setCurrentGoalSingleUser] = useState<string>("")

  useEffect(() => {
    const filterOptions = extractFiltersFromGoals(goals)
    setFilters(filterOptions)
    const defaultFilter = filterOptions[+Object.keys(filterOptions)[0]]
    if (defaultFilter && !currentGoals.length) {
      filter(defaultFilter.value)
    }

    if (members && members.length === 1) {
      const currentGoal = goals.filter(g =>
        g.progress.some(
          p => p.studliId === members[0].studliId && p.isCurrentGoal
        )
      )
      if (currentGoal.length) {
        setSelectedFilter(currentGoal[0].grade)
        setCurrentGoals(goals.filter(g => g.grade === currentGoal[0].grade))
        setCurrentGoalSingleUser(currentGoal[0].goalRef)
      }
    }
    // flyttar vi in filter här så behöver vi skriva den även utanför
    //eslint-disable-next-line
  }, [goals, members])

  const onFilter = (val: number) => {
    filter(val)
  }

  const filter = (grade: number) => {
    setCurrentGoals(goals.filter(g => g.grade === grade))
    setSelectedFilter(grade)
  }

  return {
    filters,
    selectedFilter,
    currentGoals,
    onFilter,
    currentGoalSingleUser
  }
}

export default useChooseGoalsDialog
