import React, { useState } from "react"
import { DialogContent, DialogActions } from "@mui/material"
import { FormativeQuestionsWithStudent } from "../../modules/formative/types"
import Button from "../Button"
import {
  StyledButtonContainer,
  StyledButtonWrapper,
  StyledQuestionWrapper,
  StyledButtonPadder,
  StyledQuestionNumber
} from "./StyledFormativeResultsDialog"
import FormativeQuestionAnswerItem from "../FormativeQuestionsListItem/FormativeQuestionAnswerItem"
type Props = {
  questions: FormativeQuestionsWithStudent
  onClose: () => void
}

const NOOP = () => {}

const FormativeResultsDialog = ({ questions, onClose }: Props) => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0)
  return (
    <>
      <DialogContent>
        <StyledQuestionWrapper>
          <FormativeQuestionAnswerItem
            type="class"
            fullScreenMode
            randomizeAlternatives
            showAnswers={true}
            setProgressDialog={NOOP}
            question={questions[currentQuestion]}
            prevQuestion={null}
          />
        </StyledQuestionWrapper>
      </DialogContent>
      <DialogActions>
        <StyledButtonWrapper>
          <StyledButtonContainer>
            <Button onClick={onClose} variant="outlined" color="primary">
              Stäng
            </Button>
          </StyledButtonContainer>
          <StyledButtonContainer>
            <StyledQuestionNumber>{`${currentQuestion + 1} / ${
              questions.length
            }`}</StyledQuestionNumber>
            <StyledButtonPadder>
              <Button
                disabled={currentQuestion === 0}
                onClick={() => setCurrentQuestion(currentQuestion - 1)}
                variant="outlined"
                color="primary"
              >
                Föregående
              </Button>
            </StyledButtonPadder>
            <StyledButtonPadder>
              <Button
                disabled={currentQuestion + 1 === questions.length}
                onClick={() => setCurrentQuestion(currentQuestion + 1)}
                variant="outlined"
                color="primary"
              >
                Nästa
              </Button>
            </StyledButtonPadder>
          </StyledButtonContainer>
        </StyledButtonWrapper>
      </DialogActions>
    </>
  )
}

export default FormativeResultsDialog
