import * as types from "./types"

export enum UI {
  SET_CREATE_FORM_DIRTY = "SET_CREATE_FORM_DIRTY"
}

export const setIsCreateFormDirty = (
  isDirty: boolean
): types.SetEditFormAction => {
  return { type: UI.SET_CREATE_FORM_DIRTY, payload: { isDirty: isDirty } }
}
