import { Stack, Typography } from "@mui/material"
import React from "react"
import LightBulbIcon from "../../assets/images/LightBlubIcon"

type Props = {
  tipHeaderText: string
  tipContentText: string
  actions: React.ReactNode
}

const TipsBanner = ({ tipHeaderText, tipContentText, actions }: Props) => {
  return (
    <Stack
      mb={1}
      p={2}
      sx={{ bgcolor: "#E4DCF9", borderRadius: "4px" }}
      direction="row"
      spacing={2}
    >
      <LightBulbIcon sx={{ width: "55px", height: "70px" }} />
      <Stack
        direction="row"
        sx={{
          width: "100%",
          flexWrap: "wrap",
          justifyContent: { md: "space-between", xs: "flex-end" }
        }}
      >
        <Stack
          justifyContent="center"
          sx={{ maxWidth: { md: "70%", sm: "100%" } }}
        >
          <Typography fontWeight="500">{tipHeaderText}</Typography>
          <Typography>{tipContentText}</Typography>
        </Stack>
        <Stack direction="row" alignItems="flex-end">
          {actions}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TipsBanner
