import React from "react"

import { Typography } from "@mui/material"

import * as membersPagesTypes from "../../pages/Members/store/types"
import Duration from "../Duration"
import LastActive from "../LastActive"
import WorkedLatest from "../WorkedLatestContainer/WorkedLatestContainer"
import AbilityIndicator from "../AbilityIndicator"
import MoreMenu, { MenuItems } from "../PopupMenu/PopupMenu"
import * as subRenderFunctions from "../../shared/subRenderFunctions"
import { ProductNotActiveText } from "../../pages/Members/common/ProductNotActivated"
import {
  StyledMemberListItem,
  StyledListItemText,
  StyledAvatar,
  StyledAbilityIndicatorContainer
} from "./StyledMembersListItem"
import { Product } from "../../modules/products/types"
import { activeProductType } from "../../modules/products/constants"

type Props = {
  member: membersPagesTypes.StudentInStudentList
  moreMenuItems: MenuItems
  checkedMembers: membersPagesTypes.StudentInStudentList[]
  activeProduct: Product
  goToStudentCard?: () => void
  isRecentlyAdded: boolean
  hasFormativeTests?: boolean
}

export const MembersListItem = ({
  member,
  moreMenuItems,
  checkedMembers,
  activeProduct,
  isRecentlyAdded,
  goToStudentCard,
  hasFormativeTests
}: Props) => {
  const {
    firstName,
    lastName,
    goalTitle,
    lastActive,
    picture,
    studliId,
    hasProduct,
    goalAbilityGrade,
    goalActiveTimeMilliseconds
  } = member

  const renderPrimaryListItemText = () => (
    <Typography color="inherit">
      {subRenderFunctions.renderName(member)}
    </Typography>
  )

  const goToLicenses = () => {
    window.open(
      `${process.env.REACT_APP_BOOKSHELF_URL}/licenser`,
      "_blank",
      "noopener noreferrer"
    )
  }

  const goToHandleCodes = () => {
    window.open(
      `${process.env.REACT_APP_BOOKSHELF_URL}/kontrollerakod`,
      "_blank",
      "noopener noreferrer"
    )
  }

  const renderSecondaryListItemText = () =>
    hasProduct ? (
      <>
        {activeProduct.type === activeProductType.ADAPT ? (
          <>
            {goalTitle}
            <WorkedLatest>
              {{
                worked: <Duration duration={goalActiveTimeMilliseconds} />,
                latest: <LastActive date={lastActive} shortFormat={true} />
              }}
            </WorkedLatest>
          </>
        ) : (
          <div>
            <Typography>
              Färdiga övningar : {member.completedExercises || 0}
            </Typography>
            <Typography>
              Antal uppdrag klara : {member.completedAssignments || 0}
            </Typography>
            {hasFormativeTests && (
              <Typography>
                Antal test klara : {member.completedTests || 0}
              </Typography>
            )}
          </div>
        )}
      </>
    ) : (
      <Typography color="inherit">
        <ProductNotActiveText memberId={member.studliId} />
      </Typography>
    )
  const checked = checkedMembers.some(student => student.studliId === studliId)

  return (
    <StyledMemberListItem
      onClick={goToStudentCard}
      hasproduct={hasProduct ? 1 : 0}
      recentlyadded={isRecentlyAdded ? 1 : 0}
      active={checked ? 1 : 0}
      button={!!goToStudentCard}
    >
      <StyledAvatar
        firstName={firstName}
        lastName={lastName}
        picture={picture}
        studliId={studliId}
      />
      <StyledListItemText
        primary={renderPrimaryListItemText()}
        secondary={renderSecondaryListItemText()}
        hasproduct={hasProduct ? 1 : 0}
      />
      <StyledAbilityIndicatorContainer>
        {hasProduct && activeProduct.type === activeProductType.ADAPT && (
          <AbilityIndicator grade={goalAbilityGrade} />
        )}
      </StyledAbilityIndicatorContainer>
      {moreMenuItems.length > 0 && (
        <MoreMenu menuItems={moreMenuItems} id={studliId} />
      )}
      {!hasProduct && (
        <MoreMenu
          menuItems={[
            { option: "Gå till licenser", action: goToLicenses },
            { option: "Hantera aktiveringskod", action: goToHandleCodes }
          ]}
        />
      )}
    </StyledMemberListItem>
  )
}

export default MembersListItem
