import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TableSortLabel from "@mui/material/TableSortLabel"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import { sortBy } from "./common/sortableList"
import { TableProps } from "./common/types"
import { renderStatus, abilityIndicatorPopover } from "./common/helpers"
import { InfoPopover } from "../../components/InfoPopover/InfoPopover"
import Duration from "../../components/Duration/Duration"
import {
  StyledGoalWrapper,
  StyledTableHeader,
  StyledName,
  StyledAvatar,
  StyledCompletedTask,
  StyledUnCompletedTask,
  StyledTableHead,
  StyledTableCellStart,
  StyledTableCellMiddle,
  StyledTableCellEnd,
  StyledAvatarWrapper,
  StyledStarWrapper,
  StyledDataCellName,
  StyledDataCellMiddle
} from "./StyledGoalPageTable"
import { MobileGoalTable } from "./MobileGoalPageTable"
import AbilityIndicator from "../AbilityIndicator"
import LastActive from "../LastActive"

const GoalPageTable = (props: TableProps) => {
  const [toggleArrow, setToggleArrow] = useState<"asc" | "desc">("asc")

  const handleClick = (sortType: string) => {
    if (props.setData) {
      props.setData(sortBy(props.data, toggleArrow, sortType))
      setToggleArrow(toggleArrow === "asc" ? "desc" : "asc")
    }
  }

  return (
    <MobileOrDesktop breakAt={"md"}>
      {{
        desktop: (
          <StyledGoalWrapper>
            <TableContainer component={Paper}>
              <Table aria-label="Mål tabell">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <StyledTableHeader>
                        Elever ({props.amountOfStudents})
                      </StyledTableHeader>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCellStart>
                      Namn
                      <TableSortLabel
                        aria-label={`sortera efternamn ${
                          toggleArrow === "desc" ? "stigande" : "sjunkande"
                        }`}
                        direction={toggleArrow}
                        onClick={() => handleClick("lastName")}
                      ></TableSortLabel>
                    </StyledTableCellStart>
                    <StyledTableCellMiddle align="center">
                      Status
                      <TableSortLabel
                        aria-label={`sortera status ${
                          toggleArrow === "desc" ? "stigande" : "sjunkande"
                        }`}
                        direction={toggleArrow}
                        onClick={() => handleClick("lastActive")}
                      ></TableSortLabel>
                    </StyledTableCellMiddle>
                    <StyledTableCellMiddle align="center">
                      Antal övningar
                      <TableSortLabel
                        aria-label={`sortera antal övningar ${
                          toggleArrow === "desc" ? "stigande" : "sjunkande"
                        }`}
                        direction={toggleArrow}
                        onClick={() => handleClick("goalExercisesCount")}
                      ></TableSortLabel>
                    </StyledTableCellMiddle>
                    <StyledTableCellMiddle align="center">
                      Aktiv tid
                      <TableSortLabel
                        aria-label={`sortera aktiv tid ${
                          toggleArrow === "desc" ? "stigande" : "sjunkande"
                        }`}
                        direction={toggleArrow}
                        onClick={() =>
                          handleClick("goalActiveTimeMilliseconds")
                        }
                      ></TableSortLabel>
                    </StyledTableCellMiddle>
                    <StyledTableCellEnd align="right">
                      Svårighetsnivå
                      <InfoPopover
                        type="Info"
                        padding={false}
                        content={abilityIndicatorPopover()}
                      />
                      <TableSortLabel
                        aria-label={`sortera svårighetsnivå ${
                          toggleArrow === "desc" ? "stigande" : "sjunkande"
                        }`}
                        direction={toggleArrow}
                        onClick={() => handleClick("goalAbilityGrade")}
                      ></TableSortLabel>
                    </StyledTableCellEnd>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {props.data.map(row => (
                    <TableRow key={row.id}>
                      <StyledDataCellName>
                        <StyledAvatarWrapper>
                          <StyledAvatar
                            firstName={row.firstName}
                            lastName={row.lastName}
                            picture={row.picture}
                            studliId={row.studliId}
                          />
                          <StyledName>
                            {row.firstName} {row.lastName}
                          </StyledName>
                        </StyledAvatarWrapper>
                      </StyledDataCellName>
                      {row.studliId && (
                        <StyledDataCellMiddle align="center">
                          {row.isInProgress ? (
                            <StyledUnCompletedTask>
                              {renderStatus(row.lastActive)}
                            </StyledUnCompletedTask>
                          ) : (
                            <StyledCompletedTask>
                              Klar - {<LastActive date={row.lastActive} />}
                            </StyledCompletedTask>
                          )}
                        </StyledDataCellMiddle>
                      )}
                      <StyledDataCellMiddle align="center">
                        {row.goalExercisesCount !== 0 && row.goalExercisesCount}
                      </StyledDataCellMiddle>
                      <StyledDataCellMiddle align="center">
                        {row.goalActiveTimeMilliseconds !== 0 && (
                          <Duration duration={row.goalActiveTimeMilliseconds} />
                        )}
                      </StyledDataCellMiddle>
                      <TableCell>
                        <StyledStarWrapper>
                          <AbilityIndicator grade={row.goalAbilityGrade} />
                        </StyledStarWrapper>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledGoalWrapper>
        ),
        mobile: (
          <>
            <MobileGoalTable data={props.data} />
          </>
        )
      }}
    </MobileOrDesktop>
  )
}

export default GoalPageTable
