import React from "react"
import { connect, useSelector } from "react-redux"

import NavigationLinks from "../../components/NavigationLinks"
import AppVersion from "../../components/AppVersion"
import {
  selectClassroomActiveProductInfo,
  selectClassroomHasMultipleProducts
} from "../../modules/products/selectors"
import { Product } from "../../modules/products/types"
import { RootState } from "../../modules/store"
import ProductSelector from "../../containers/ProductSelector"

import {
  StyledContainer,
  StyledDivider,
  StyledAppVersion,
  StyledProductLinksContainer
} from "./StyledSideBar"
import ProductLinks from "../../components/ProductLinks"
import { selectClassroomFeatures } from "../../modules/classroom/selectors"
import { List, Stack } from "@mui/material"
import ChooseGroupButton from "../../components/ChooseGroupButton/ChooseGroupButton"

type StateProps = {
  activeProduct: Product
  classroomHasMultipleProducts: boolean
}

type OwnProps = {
  isMobile: boolean
  onCloseDrawer: () => void
}

type Props = StateProps & OwnProps

const SideBar = ({
  activeProduct,
  classroomHasMultipleProducts,
  onCloseDrawer,
  isMobile
}: Props) => {
  const classroomFeatures = useSelector(selectClassroomFeatures)
  return (
    <StyledContainer>
      <Stack>
        <List disablePadding>
          <ChooseGroupButton />
          {classroomHasMultipleProducts && <ProductSelector />}
        </List>
      </Stack>
      <StyledDivider />
      <section>
        <NavigationLinks
          isMobile={isMobile}
          onCloseDrawer={onCloseDrawer}
          classroomFeatures={classroomFeatures}
          productInfo={activeProduct}
        />
      </section>
      <StyledDivider />
      <StyledProductLinksContainer>
        <ProductLinks />
      </StyledProductLinksContainer>
      <StyledAppVersion>
        <AppVersion />
      </StyledAppVersion>
    </StyledContainer>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => ({
  activeProduct: selectClassroomActiveProductInfo(state),
  classroomHasMultipleProducts: selectClassroomHasMultipleProducts(state)
})

const ConnectedSideBar = connect(mapStateToProps)(SideBar)

export default ConnectedSideBar
