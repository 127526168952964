import TreeItem, { TreeItemProps } from "@mui/lab/TreeItem"
import TreeView from "@mui/lab/TreeView"
import { List, styled } from "@mui/material"

export const StyledTreeItem = styled(
  TreeItem as React.FC<TreeItemProps & { notSelectable?: boolean }>
)`
  & .MuiTreeItem-iconContainer {
  display: none;
  }
  
  & .MuiTreeItem-content {
    padding: 0;
    margin
  }

  & .MuiTreeItem-label {
    padding: 0;
  }

  & .MuiTreeItem-label:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }

  ${props =>
    props.notSelectable &&
    `
    &:focus > .MuiTreeItem-content,
    &:hover > .MuiTreeItem-content,
    &.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
      background: transparent;
    }

    & > .MuiTreeItem-content {
      cursor: default;
    }
  `}
`

export const StyledTreeView = styled(TreeView)`
  & .MuiTreeItem-iconContainer {
    display: none;
  }
`

export const StyledListWrapper = styled(List)`
  padding: 0;
`
