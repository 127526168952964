import React from "react"

import { StyledAppVersion } from "./StyledAppVersion"
import { getApplicationVersion } from "../../shared/tools/misc"

const AppVersion = () => {
  return (
    <StyledAppVersion>{`v. ${getApplicationVersion(true)}`}</StyledAppVersion>
  )
}

export default AppVersion
