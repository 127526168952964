export function merge<X extends Record<string, unknown>>(n: X) {
  return (n2: X): X => ({
    ...n,
    ...n2
  })
}

export function concat<X>(n2: X[]) {
  return (n: X[]) => [...n, ...n2]
}

export function push<X>(item: X) {
  return (n: X[]) => [...n, item]
}

export function unshift<X>(item: X) {
  return (n: X[]) => [item, ...n]
}

export function filter<X>(
  fn: (value: X, index: number, arr: X[]) => boolean
): (n: X[]) => X[] {
  return (n: X[]): X[] => n.filter(fn)
}

export function map<X>(fn: (item: X, index: number, arr: X[]) => X) {
  return (n: X[]) => n.map(fn)
}
