import ListItem, { ListItemProps } from "@mui/material/ListItem"
import { Typography, styled } from "@mui/material"
import { ReactComponent as TestIcon } from "../../assets/images/test-icon.svg"
import { ReactComponent as TargetIcon } from "../../assets/images/menu-goal.svg"

export const StyledAssignmentSticker = styled("img")`
  width: 3rem;
  height: 3rem;
`

export const StyledListItem = styled(ListItem as React.FC<ListItemProps>)`
  cursor: pointer;
  align-items: flex-start;
`
export const StyledHistoryListWrapper = styled("div")`
  margin-bottom: 6rem;
`
export const StyledPaginationWrapper = styled("div")`
  display: flex;
  justify-content: center;
`
export const StyledEmptyHistory = styled(Typography)`
  margin-left: 1rem;
`

export const StyledGoalIcon = styled(TargetIcon)`
  color: #4f93d5;
  margin-top: 5px;
`

export const StyledTestIcon = styled(TestIcon)`
  color: #4f93d5;
`

export const StyledAssignmentListItem = styled(
  ListItem as React.FC<ListItemProps>
)`
  cursor: pointer;
`

export const StyledResultWrapper = styled("div")`
  align-items: end;
  display: flex;
`

export const StyledListItemContent = styled("div")`
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const StyledNoInfo = styled("p")`
  text-align: center;
`
