import { Typography, styled } from "@mui/material"

import Button from "../Button"

export const StyledTitle = styled(Typography)`
  line-height: 1rem;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`

export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 5px;
  color: #006cc8;
  margin: 0.25rem 0;
`
