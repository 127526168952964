import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import {
  ExerciseId,
  ParentNode,
  Exercises,
  ParentOrExerciseNode
} from "../../modules/exercises/types"
import { selectAllExercises } from "../../modules/exercises/selectors"
import { selectStudentByPayload } from "../../modules/members/selectors"
import { selectExerciseProgressByStudentId } from "../../modules/exercisesProgress/selectors"
import { ExercisesProgress } from "../../modules/exercisesProgress/types"
import { Members, Member } from "../../modules/members/types"
import { isParent } from "../../pages/Exercises/common/helpers"
import { selectActiveProductName } from "../../modules/products/selectors"

type TreeStatus = {
  expandedNodes: ExerciseId[]
  topNodesNotExpanded: ExerciseId[]
  checkedExercises: ExerciseId[]
  currentMobileNodeChosen: any
  currentMobileBreadcrumbs: ParentNode[]
}

const defaultTreeStatus = {
  expandedNodes: [],
  topNodesNotExpanded: [],
  checkedExercises: [],
  currentMobileNodeChosen: [],
  currentMobileBreadcrumbs: []
}

export type Result = {
  exercises: Exercises
  members: Members
  progress: ExercisesProgress
  treeStatus: TreeStatus
  setExpandedNodes: (nodes: ExerciseId[]) => void
  setTopNodesNotExpanded: (nodes: ExerciseId[]) => void
  onMobileRowClick: (node: ParentOrExerciseNode) => void
  onBreadcrumbClick: (node: ParentNode) => void
  member: Member
  activeProduct: string
}

function useStudentExerciseList(): Result {
  const [treeStatus, setTreeStatus] = useState<TreeStatus>(defaultTreeStatus)
  const exercises = useSelector(selectAllExercises)
  const member = useSelector(selectStudentByPayload)
  const progress = useSelector(selectExerciseProgressByStudentId)
  const activeProduct = useSelector(selectActiveProductName)
  useEffect(() => {
    if (!treeStatus.currentMobileNodeChosen.length && exercises.length) {
      setTreeStatus({ ...treeStatus, currentMobileNodeChosen: exercises })
    }
  }, [exercises, setTreeStatus, treeStatus])

  const set = (val: Partial<TreeStatus>) =>
    setTreeStatus({ ...treeStatus, ...val })

  const setExpandedNodes = (nodes: ExerciseId[]) => {
    set({ expandedNodes: nodes })
  }

  const setTopNodesNotExpanded = (nodes: ExerciseId[]) => {
    set({ topNodesNotExpanded: nodes })
  }

  const onMobileRowClick = (node: ParentOrExerciseNode) => {
    if (!isParent(node)) {
      return
    }

    set({
      currentMobileNodeChosen: node.children,
      currentMobileBreadcrumbs: [...treeStatus.currentMobileBreadcrumbs, node]
    })
  }

  const onBreadcrumbClick = (node: ParentNode) => {
    if (treeStatus.currentMobileBreadcrumbs.length === 1) {
      set({
        currentMobileNodeChosen: exercises,
        currentMobileBreadcrumbs: []
      })

      return
    }

    const newBreadcrumbs = [...treeStatus.currentMobileBreadcrumbs]
    newBreadcrumbs.splice(treeStatus.currentMobileBreadcrumbs.length - 1)
    set({
      currentMobileNodeChosen: node.children,
      currentMobileBreadcrumbs: newBreadcrumbs
    })
  }

  return {
    exercises,
    progress,
    members: [member],
    treeStatus,
    setExpandedNodes,
    setTopNodesNotExpanded,
    onMobileRowClick,
    onBreadcrumbClick,
    member,
    activeProduct
  }
}

export default useStudentExerciseList
