import React from "react"

import { Checkbox, TableCell } from "@mui/material"
import * as membersPagesTypes from "../../pages/Members/store/types"
import Avatar from "../Avatar"
import MoreMenu, { MenuItems } from "../PopupMenu/PopupMenu"
import AbilityIndicator from "../AbilityIndicator"
import Duration from "../Duration"
import LastActive from "../LastActive"
import * as subRenderFunctions from "../../shared/subRenderFunctions"
import {
  StyledSmallCell,
  StyledMoreCell
} from "../MembersTable/StyledMembersTable"
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowProductNotActive
} from "./StyledMembersTableRow"

import { ProductNotActiveText } from "../../pages/Members/common/ProductNotActivated"
import { Product } from "../../modules/products/types"
import { activeProductType } from "../../modules/products/constants"
import { isDillProduct } from "../../shared/tools/is_dill_product"
import GroupChip from "../GroupChip/GroupChip"
import { Group } from "../../modules/classroom/types"
import { sortGroupsOnActive } from "../GroupChip/utils"

type Props = {
  member: membersPagesTypes.StudentInStudentList
  moreMenuItems: MenuItems
  children?: never
  checkedMembers: membersPagesTypes.StudentInStudentList[]
  activeProduct: Product
  goToStudentCard?: () => void
  isRecentlyAddedMember: boolean
  hasExercises?: boolean
  hasFormativeTests?: boolean
  onCheckboxClick: (student: membersPagesTypes.StudentInStudentList) => void
  allActiveGroups?: Group[]
}

export const Row = (props: Props) => {
  const {
    member: {
      hasProduct,
      firstName,
      lastName,
      goalTitle,
      studliId,
      goalAbilityGrade = 0,
      goalActiveTimeMilliseconds,
      lastActive,
      gradeLabel,
      picture,
      completedExercises,
      completedAssignments,
      completedTests,
      groups
    },
    member,
    moreMenuItems,
    checkedMembers,
    activeProduct,
    isRecentlyAddedMember,
    goToStudentCard,
    hasExercises,
    hasFormativeTests,
    onCheckboxClick,
    allActiveGroups
  } = props

  const getCollspan = () => {
    if (activeProduct.type === "adapt") {
      return 5
    }

    let dillCollspan = 3
    if (!hasFormativeTests) {
      dillCollspan--
    }
    if (!hasExercises) {
      dillCollspan--
    }
    return dillCollspan
  }
  const goToLicenses = () => {
    window.open(
      `${process.env.REACT_APP_BOOKSHELF_URL}/licenser`,
      "_blank",
      "noopener noreferrer"
    )
  }

  const goToHandleCodes = () => {
    window.open(
      `${process.env.REACT_APP_BOOKSHELF_URL}/kontrollerakod`,
      "_blank",
      "noopener noreferrer"
    )
  }

  const renderTableRowHasProduct = () => {
    const checked = checkedMembers.some(
      student => student.studliId === studliId
    )

    return (
      <StyledTableRow
        recentlyadded={isRecentlyAddedMember ? 1 : 0}
        active={checked ? 1 : 0}
        hasproduct={1}
        key={studliId}
        hasonclick={goToStudentCard ? 1 : 0}
        onClick={goToStudentCard}
      >
        {activeProduct.type === activeProductType.ADAPT && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={checkedMembers.some(c => c.studliId === studliId)}
              onClick={() => onCheckboxClick(member)}
            />
          </TableCell>
        )}
        <StyledSmallCell>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            picture={picture}
            studliId={studliId}
          />
        </StyledSmallCell>
        <StyledTableCell>
          {subRenderFunctions.renderName(member)}
        </StyledTableCell>
        <StyledTableCell>
          <GroupChip
            groups={sortGroupsOnActive(groups, allActiveGroups)}
            truncate
          />
        </StyledTableCell>
        {activeProduct.type === activeProductType.ADAPT && (
          <StyledTableCell>{goalTitle}</StyledTableCell>
        )}
        {activeProduct.type === activeProductType.ADAPT && (
          <StyledTableCell>{gradeLabel}</StyledTableCell>
        )}
        {activeProduct.type === activeProductType.ADAPT && (
          <StyledTableCell style={{ width: "1px" }}>
            <AbilityIndicator grade={goalAbilityGrade} />
          </StyledTableCell>
        )}
        {activeProduct.type === activeProductType.ADAPT && (
          <StyledTableCell>
            <Duration duration={goalActiveTimeMilliseconds} />
          </StyledTableCell>
        )}
        {isDillProduct(activeProduct.type) && hasExercises && (
          <StyledTableCell align="right">{completedExercises}</StyledTableCell>
        )}
        {isDillProduct(activeProduct.type) && (
          <>
            <StyledTableCell align="right">
              {completedAssignments}
            </StyledTableCell>
            {hasFormativeTests && (
              <StyledTableCell align="right">{completedTests}</StyledTableCell>
            )}
          </>
        )}
        <StyledTableCell>
          <LastActive date={lastActive} />
        </StyledTableCell>
        {moreMenuItems.length > 0 && (
          <StyledMoreCell>
            <MoreMenu menuItems={moreMenuItems} id={studliId} />
          </StyledMoreCell>
        )}
      </StyledTableRow>
    )
  }

  const renderTableRowProductNotActive = () => {
    const checked = checkedMembers.some(
      student => student.studliId === studliId
    )

    return (
      <StyledTableRowProductNotActive
        active={checked ? 1 : 0}
        key={studliId}
        hasproduct={0}
        recentlyadded={isRecentlyAddedMember ? 1 : 0}
        hasonclick={goToStudentCard ? 1 : 0}
        onClick={goToStudentCard}
      >
        {activeProduct.type === activeProductType.ADAPT && (
          <TableCell padding="checkbox" />
        )}
        <StyledSmallCell>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            picture={picture}
            studliId={studliId}
          />
        </StyledSmallCell>
        <StyledTableCell>
          {subRenderFunctions.renderName(member)}
        </StyledTableCell>
        <StyledSmallCell>
          <GroupChip
            groups={sortGroupsOnActive(groups, allActiveGroups)}
            truncate
          />
        </StyledSmallCell>
        <StyledTableCell colSpan={getCollspan()}>
          <ProductNotActiveText memberId={member.studliId} />
        </StyledTableCell>
        <StyledMoreCell align="right">
          <MoreMenu
            menuItems={[
              { option: "Gå till licenser", action: goToLicenses },
              { option: "Hantera aktiveringskod", action: goToHandleCodes }
            ]}
          />
        </StyledMoreCell>
      </StyledTableRowProductNotActive>
    )
  }

  return hasProduct
    ? renderTableRowHasProduct()
    : renderTableRowProductNotActive()
}

export default Row
