import { SetTreeStatusAction, TreeStatus } from "./types"

/**
 * Actions on UI only
 */
export enum UI {
  SET_TREE_STATUS = "SET_TREE_STATUS"
}

export const setTreeStatus = (payload: TreeStatus): SetTreeStatusAction => {
  return { type: UI.SET_TREE_STATUS, payload }
}
