import React, { useState, useEffect } from "react"
import { styled } from "@mui/material"
import { FormativeTest } from "../../modules/formative/types"
import moment from "moment"
import LastActive from "../../components/LastActive"

type Props = {
  status: FormativeTest["status"]
  endsAt: string
  numberOfSessions: number
  onEndedCounter: Function
}

export const StyledNumberOfSessions = styled("div")`
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 6px 2px 6px;
  display: inline-block;
  color: white;
  background-color: #666666;
`

export const StyledStatusTag = styled("div")`
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 6px 2px 6px;
  display: inline-block;
  color: white;
  margin-right: 0.5em;
  background-color: ${(props: { status: FormativeTest["status"] }) =>
    getBackgroundColor(props.status)};
`

const FormativeStatusTag = ({
  status,
  endsAt,
  numberOfSessions,
  onEndedCounter
}: Props) => {
  const [timeRemaining, setTimeRemaining] = useState<string>("")
  useEffect(() => {
    const hasCountdownClock = moment(endsAt).valueOf() - moment().valueOf() > 0

    if (!hasCountdownClock && timeRemaining) {
      setTimeRemaining("")
      onEndedCounter()
      return
    }
    if (status === "stopped" && hasCountdownClock) {
      const counter = setInterval(function () {
        var end = moment(endsAt)

        const timeLeft = moment(end.diff(moment()))
        if (timeLeft.valueOf() < 0) {
          setTimeRemaining("")
          onEndedCounter()
          return
        }
        const formatted = timeLeft.format("mm:ss")
        setTimeRemaining(formatted)
      }, 1000)
      return () => clearInterval(counter)
    }
    // eslint-disable-next-line
  }, [endsAt])

  const getStatusText = () => {
    switch (status) {
      case "draft":
        return <StyledStatusTag status={status}>Utkast</StyledStatusTag>
      case "published":
        return <StyledStatusTag status={status}>Kommande</StyledStatusTag>
      case "stopped":
        return (
          <StyledStatusTag status={timeRemaining ? "ongoing" : "stopped"}>
            {timeRemaining ? <>Slutar om {timeRemaining}</> : "Avslutat - "}
            {!timeRemaining && <LastActive date={endsAt} />}
          </StyledStatusTag>
        )
      case "ongoing":
        return <StyledStatusTag status={status}>Pågående</StyledStatusTag>
      case "archived":
        return <StyledStatusTag status={status}>Arkiverat</StyledStatusTag>
      default:
        return <></>
    }
  }
  return (
    <>
      {getStatusText()}
      {numberOfSessions > 1 && (
        <StyledNumberOfSessions>
          {numberOfSessions} Testtillfällen
        </StyledNumberOfSessions>
      )}{" "}
    </>
  )
}

export default FormativeStatusTag

const getBackgroundColor = (status: string): string => {
  switch (status) {
    case "draft":
      return "#35209e"
    case "ongoing":
      return "#038763"
    case "stopped":
      return "#006CC8"
    case "published":
      return "#43b9fe"
    case "archived":
      return "#2c3e51"
    default:
      return "#038763"
  }
}
