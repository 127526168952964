import React from "react"
import { ReactComponent as Hearing } from "../../assets/images/volume-control-full.svg"
import { ReactComponent as Videocam } from "../../assets/images/video-player.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/exercise.svg"
import { ReactComponent as LinkIcon } from "../../assets/images/link-external.svg"
import { StyledIconContainer } from "./StyledExerciseTypeIcon"
import { ReactComponent as TestIcon } from "../../assets/images/test-icon.svg"
import { ReactComponent as BoardIcon } from "../../assets/images/board.svg"
import { ReactComponent as PresentationIcon } from "../../assets/images/presentation.svg"
type Props = {
  type: "video" | "audio" | "exercise" | "link"
  iconColor: string
  marginTop?: boolean
}

export const ICONS: any = {
  audio: <Hearing aria-label="Lyssna" />,
  video: <Videocam aria-label="Titta" />,
  exercise: <ExerciseIcon aria-label="Övning" />,
  xcase: <ExerciseIcon aria-label="Övning" />,
  link: <LinkIcon aria-label="Länk" />,
  text: <ExerciseIcon aria-label="Övning" />,
  board: <BoardIcon aria-label="Tavla" />,
  presentation: <PresentationIcon aria-label="Presentation" />,
  test: <TestIcon aria-label="Test" />
}

const ExerciseTypeIcon = ({ type, iconColor, marginTop = true }: Props) => {
  return (
    <StyledIconContainer marginTop={marginTop} iconColor="#4f93d5">
      {ICONS[type]}
    </StyledIconContainer>
  )
}

export default ExerciseTypeIcon
