import React from "react"
import { AssignmentProgress } from "../../modules/assignmentsProgress/types"
import { Member } from "../../modules/members/types"
import moment from "moment"
import { StyledMessage } from "../ProgressHistory/StyledProgressHistory"

type Props = {
  progress: AssignmentProgress
  student: Member
  strong?: boolean
}

const ProgressHistoryStudentAction = ({ progress, student, strong }: Props) => {
  return (
    <StyledMessage variant="body2" strongTxt={strong}>{`${
      student && student.firstName
    } ${student && student.lastName} lämnade in uppdraget ${moment(
      progress.submittedAt
    ).fromNow()}
    `}</StyledMessage>
  )
}

export default ProgressHistoryStudentAction
