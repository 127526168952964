import { State, BooksPayload } from "./types"
import * as actions from "./actions"
import { createReducer } from "../../shared/tools/redux"
import { setField } from "../../shared/tools/monocle"

const initialState: State = {
  booksToc: []
}

const setBooks = setField<State, BooksPayload>("booksToc", p => p.toc || [])

const resetBooks = setField<State>("booksToc", () => initialState.booksToc)

const reducer = createReducer<State>(initialState, builder =>
  builder.case(actions.addBooks, setBooks).case(actions.resetBooks, resetBooks)
)

export default reducer
