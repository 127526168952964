import { RootState } from "../../../modules/store"

export const selectIsModalOpen = (store: RootState): boolean =>
  store.pages.confirmBeforeLeaveModal.isCreateModalOpen

export const selectModalMsg = (store: RootState): string =>
  store.pages.confirmBeforeLeaveModal.message

export const selectCanLeave = (store: RootState): Function =>
  store.pages.confirmBeforeLeaveModal.canLeave
