import { CalculatedAssignmentStatuses } from "../../modules/assignments/types"

export const getStatusString = (status: CalculatedAssignmentStatuses) => {
  switch (status) {
    case "ongoing":
      return "Pågående"
    case "finished":
      return "Avslutat"
    case "coming":
      return "Kommande"
    case "passed":
      return "Försenat"
    case "draft":
      return "Utkast"
    default:
      return status
  }
}

export const getAssignmentStatusWeight = (
  status: CalculatedAssignmentStatuses
) => {
  switch (status) {
    case "ongoing":
      return 0
    case "finished":
      return 1
    case "coming":
      return 2
    case "passed":
      return 3
    case "draft":
      return 4
    default:
      return 5
  }
}

export const sortByLastName = (order: "asc" | "desc", a = "", b = "") => {
  return order === "asc" ? a.localeCompare(b) : b.localeCompare(a)
}
