import theme from "../../../src/shared/styles/theme"
import packageJson from "../../../package.json"
import moment, { Moment } from "moment"

/**
 * Checks width of current screen / viewport.
 *
 * @returns {Boolean} true if if window viewport is xs according to Material-ui breakpoints else false.
 */
export const isWidthXs = (): boolean => {
  const xsBreakpoint = (theme && theme.breakpoints.values.sm) || 600
  const windowWidth =
    window.screen.width < window.outerWidth
      ? window.screen.width
      : window.outerWidth

  return windowWidth < xsBreakpoint
}

/**
 *  Checks if specified object is empty.
 *
 * @param {Object} - object to test.
 * @returns {Boolean} true if empty else false.
 */
export const isObjectEmpty = (object: any): boolean => {
  return Boolean(
    object && Object.keys(object).length === 0 && object.constructor === Object
  )
}

/**
 * Gets the application version and commit-id
 *
 * @returns {String} the version as specified in package.json and commit-id (if it can be gotten,
 *                   the commit-id is gotten from env-variables and has to be set at built time).
 */
export const getApplicationVersion = (showShort?: boolean) => {
  const version = process.env.REACT_APP_VERSION || packageJson.version
  const commitId = process.env.REACT_APP_COMMIT_ID
    ? `_${process.env.REACT_APP_COMMIT_ID}`
    : ""

  return showShort ? `${version}` : `${version}${commitId}`
}

/**
 * Helper function to help with TS "No index signature with a parameter of type * was found on type *."
 * // one could also use suppressImplicitAnyIndexErrors
 *
 * @param obj
 * @param key
 * 
 * @returns {Boolean} - True if key exists as attribute on obj.
 * 
 * @example     
 * if (hasKey(object, filterAttribute)) {
      return object[filterAttribute] === filterAttributeValue
    }
 */
function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

/**
 * Filter out objects from array of objects.
 *
 * @param filterAttribute {String} - Attribute on each object to filter on.
 * @param filterAttributeValue {any} - Value of specified attribute to test for.
 * @param objects {Array} - Array of objects to filter.
 *
 * @returns {Array} - Array of filtered objects.
 */
export function filterObjectOnAttribute<T>(
  filterAttribute: string,
  filterAttributeValue: T[string & keyof T],
  objects: T[]
): T[] {
  const filteredObjects = objects.filter((object: T) => {
    if (hasKey(object, filterAttribute)) {
      return object[filterAttribute] === filterAttributeValue
    } else {
      return false
    }
  })

  return filteredObjects || []
}

export const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min

export function getTime(time: number): string {
  const hr = Math.floor(time / 3600)
  const min = Math.floor((time - hr * 3600) / 60)
  const sec = Math.floor(time - hr * 3600 - min * 60)

  const minStr = min < 10 ? `0${min}` : `${min}`
  const secStr = sec < 10 ? `0${sec}` : `${sec}`

  return `${minStr}:${secStr}`
}

export const showTimeAgo = (from: Moment | string) =>
  moment.duration(moment().diff(from)).asSeconds() > 60
    ? moment(from).fromNow(true)
    : "mindre än en minut"
