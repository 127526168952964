import { routesMap } from "../../routes"
import { useEffect, useRef } from "react"
import { RESPONSE as productActions } from "../../modules/products/actions"
import { RESPONSE as classroomActions } from "../../modules/classroom/actions"

import { useDispatch, useSelector } from "react-redux"
import { selectCurrentRoute } from "../../modules/router/selectors"
import {
  selectIsApplicationReady,
  selectLatestAction
} from "../../modules/application/selectors"
import { AnyAction } from "redux"

const RETRIGGER_ACTIONS = [
  productActions.CHANGE_ACTIVE_PRODUCT_RESPONSE,
  classroomActions.CLASSROOM_CHANGE_GROUP_RESPONSE
] as string[]

function useRouteData() {
  const savedRoute = useRef("")
  const isApplicationReady = useSelector(selectIsApplicationReady)
  const latestAction = useSelector(selectLatestAction)
  const currentRoute = useSelector(selectCurrentRoute)
  const routesData: (() => AnyAction)[] = useSelector(
    routesMap[currentRoute].data ? routesMap[currentRoute].data : () => []
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      (routesData &&
        isApplicationReady &&
        savedRoute.current !== currentRoute) ||
      RETRIGGER_ACTIONS.includes(latestAction)
    ) {
      savedRoute.current = currentRoute
      routesData.forEach(d => dispatch(d()))
    }
  }, [
    currentRoute,
    dispatch,
    isApplicationReady,
    routesData,
    savedRoute,
    latestAction
  ])
}

export default useRouteData
