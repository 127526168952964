/**
 * Decides, upon view-width if to show specified desktop or list component
 */
import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Theme } from "@mui/material"

type Props = {
  breakAt?: "xs" | "sm" | "md" | "lg" | "xl"
  children: {
    desktop: React.ReactElement<any>
    mobile: React.ReactElement<any>
  }
}

const MobileOrDesktop: React.FC<Props> = ({
  breakAt,
  children: { desktop, mobile }
}) => {
  const matches = useMediaQuery<Theme>(theme =>
    theme.breakpoints.up(breakAt || "sm")
  )

  return matches ? desktop : mobile
}

export default MobileOrDesktop
