import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  selectTestInRoute,
  selectProgressForTestInRoute,
  selectAnswersForTestInRoute
} from "../../modules/formative/selectors"
import {
  Wrapper,
  StyledFinishedText,
  StyledFinishedTextWrapper,
  StyledPaperContent,
  StyledFormControl,
  StyledHeaderContainer,
  StyledPaper,
  StyledProgressWrapper,
  StyledResultsButtonsWrapper,
  StyledResultsTitle,
  StyledTestNameContainer,
  StyledTestTitleTagContainer,
  StyledTitle,
  StyledHeaderButtonsWrapper,
  StyledButtonWrapper,
  StyledDescription
} from "./StyledFormativeQuestionsResult"
import {
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Tabs,
  Tab,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material"
import {
  FormativeTest,
  FormativeSession,
  FormativeTestMenuOptions,
  EditFormativeTest,
  CreateFormativeTest,
  FormativeQuestionsWithStudent,
  ParsedFormativeProgressionAnswers
} from "../../modules/formative/types"
import {
  getLatestSession,
  getProgressForBar,
  menuOptions
} from "../FormativeQuestions/helpers"
import LastActive from "../../components/LastActive"
import FormativeStatusTag from "../FormativeQuestions/FormativeStatusTag"
import Progress from "../../components/Progress"
import HtmlRenderer from "../../components/HtmlRenderer"
import AudioPlayer from "../../components/AudioPlayer"
import FormativeStudentsResult from "./FormativeStudentsResult"
import useWindowSize from "../../hooks/useWindowSize"
import TabPanel from "../../components/Tabs/TabPanel"
import FormativeQuestionAnswers from "./FormativeQuestionAnswers"
import { Moment } from "moment"
import Dialog from "../../components/Dialog"
import ExercisesProgressDialog from "../../components/ExercisesProgressDialog"
import { MembersWithOnlineStatus } from "../Exercises/store/types"
import { Member, Members } from "../../modules/members/types"
import { ProgressForBar } from "../../components/FormativeQuestionsTable/types"
import {
  selectAllMembers,
  selectStudentsWithProduct
} from "../../modules/members/selectors"
import InfoPopover from "../../components/InfoPopover"
import {
  StyledLegendContainer,
  StyledLegendBox
} from "../../components/GoalsTable/StyledGoalsTable"
import { PROGRESS_SEGMENT } from "../../components/Progress/constants"
import Button from "../../components/Button"
import {
  editFormativeTest,
  createFormativeTest,
  stopFormativeTest,
  removeFormativeTest,
  startFormativeTest,
  redoFormativeTest,
  getTest
} from "../../modules/formative/actions"
import * as routerActions from "../../modules/router/actions"
import { selectClassroomId } from "../../modules/classroom/selectors"
import RedoTestDialog, {
  RedoFormValues
} from "../FormativeQuestions/RedoTestDialog"
import StopTestDialog from "../FormativeQuestions/StopTestDialog"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { ReactComponent as PlayIcon } from "../../assets/images/play.svg"
import { ReactComponent as StopIcon } from "../../assets/images/stop.svg"
import { ReactComponent as RedoIcon } from "../../assets/images/redo.svg"
import MoreMenu from "../../components/PopupMenu"
import {
  getPreviousSessionFromSelected,
  getMembersWithAnswers
} from "./helpers"
import FormativeResultsDialog from "../../components/FormativeResultsDialog"
import { selectCurrentTestId } from "../../modules/router/selectors"
import IsPublicIcon from "../../components/IsPublicIcon/IsPublicIcon"
import ResultTabs from "../../components/ResultTabs/ResultTabs"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import Avatar from "../../components/Avatar"
const NOOP = () => {}

const FormativeQuestionsResult = () => {
  const [selectedSession, setSelectedSession] =
    useState<FormativeSession | null>(null)

  const [currentTab, setCurrentTab] = useState(0)
  const [progressDialogData, setProgressDialogData] = useState<{
    done: Member[]
    notStarted: Member[]
    inProgress: Member[]
    title: string
  } | null>(null)
  const [showAnswers, setShowAnswers] = useState<boolean>(false)
  const [confirmStartDialog, setConfirmStartDialog] = useState<null | {
    testName: string
    testId: number
  }>()
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState<null | {
    testName: string
    testId: number
  }>()
  const [confirmStopDialog, setConfirmStopDialog] = useState<null | {
    testName: string
    testId: number
  }>()

  const [fullScreenResultDialog, setFullScreenResultDialog] = useState<null | {
    questions: FormativeQuestionsWithStudent
  }>(null)

  const [confirmRedoDialog, setConfirmRedoDialog] = useState<null | {
    testName: string
    testId: number
    students: Members
    numberOfSessions: number
    test: FormativeTest
    session: FormativeSession
  }>(null)
  const windowSize = useWindowSize()
  const dispatch = useDispatch()
  const students = useSelector(selectStudentsWithProduct)
  const test = useSelector(selectTestInRoute)
  const routeTestId = useSelector(selectCurrentTestId)
  const classroomId = useSelector(selectClassroomId)
  const user = useSelector(selectAuthenticatedUser)
  const progress = useSelector(selectProgressForTestInRoute)
  const answers = useSelector(selectAnswersForTestInRoute)
  const members = useSelector(selectAllMembers)

  useEffect(() => {
    if (test) {
      const latestSession = getLatestSession(test.sessions)
      setSelectedSession(latestSession)
    }
    // eslint-disable-next-line
  }, [test])

  useEffect(() => {
    if (routeTestId) {
      dispatch(getTest({ id: routeTestId }))
    }
  }, [routeTestId, dispatch])

  const onChangeSession = (event: SelectChangeEvent<number>) => {
    if (test) {
      const selected = test.sessions.find(
        session => session.id === event.target.value
      )
      setSelectedSession(selected ? selected : null)
    }
  }

  const getMenuItemName = (
    session: FormativeSession,
    renderTest: FormativeTest
  ) => {
    const latestSession = getLatestSession(renderTest.sessions)
    if (latestSession.id === session.id) {
      return renderTest.status === "ongoing" ? "Pågående" : "Senaste"
    }
    return (
      <StyledFinishedTextWrapper>
        <StyledFinishedText style={{ marginRight: "3px", display: "flex" }}>
          Avslutat
        </StyledFinishedText>
        <LastActive date={session.endedAt} />
      </StyledFinishedTextWrapper>
    )
  }

  const onProgressDialogClick = (
    progressToShow: ProgressForBar & { [key: string]: number[] },
    title: string
  ) => {
    const dialogData: any = {
      done: [],
      inProgress: [],
      notStarted: [],
      title
    }
    // eslint-disable-next-line
    Object.keys(progressToShow).map(key => {
      progressToShow[key].forEach(memberId => {
        const progressStudent = students.find(
          student => student.studliId === memberId
        )
        if (progressStudent) {
          dialogData[key].push(progressStudent)
        }
      })
    })
    setProgressDialogData(dialogData)
  }

  const onFormativeTestMenuClick = (
    type: FormativeTestMenuOptions,
    testId: FormativeTest["id"]
  ) => {
    const formativeTest = test

    if (formativeTest) {
      const latestSession = getLatestSession(formativeTest.sessions)
      switch (type) {
        case "togglePrivate":
          let status = "published"
          if (formativeTest.status === "draft") {
            status = "draft"
          }
          if (formativeTest.status === "archived") {
            status = "archived"
          }
          const togglePrivateTest: EditFormativeTest = {
            id: formativeTest.id,
            title: formativeTest.title,
            status: status as EditFormativeTest["status"],
            instruction: latestSession.instruction,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            members: latestSession.members,
            isPublic: !formativeTest.isPublic
          }
          dispatch(
            editFormativeTest({
              test: togglePrivateTest,
              echo: "togglePrivate"
            })
          )
          break
        case "activate":
          const activateTest: EditFormativeTest = {
            id: formativeTest.id,
            title: formativeTest.title,
            status: "published",
            instruction: latestSession.instruction,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            isPublic: formativeTest.isPublic,
            members: latestSession.members
          }
          dispatch(editFormativeTest({ test: activateTest, echo: "activate" }))
          break
        case "archive":
          const archiveTest: EditFormativeTest = {
            id: formativeTest.id,
            title: formativeTest.title,
            status: "archived",
            instruction: latestSession.instruction,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            members: latestSession.members,
            isPublic: formativeTest.isPublic
          }
          dispatch(editFormativeTest({ test: archiveTest, echo: "archive" }))
          break
        case "createCopy":
          const copyTest: CreateFormativeTest = {
            title: `Kopia av: ${formativeTest.title}`,
            status: formativeTest.status === "draft" ? "draft" : "published",
            instruction: formativeTest.instruction,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            members: []
          }

          dispatch(createFormativeTest(copyTest))
          dispatch(
            routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR, {
              classroomId,
              testId
            })
          )
          break
        case "edit":
          dispatch(
            routerActions.gotoRoute(
              routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA,
              { classroomId, testId }
            )
          )
          break
        case "fullscreen":
          break
        case "remove":
          setConfirmRemoveDialog({
            testName: formativeTest.title,
            testId: formativeTest.id
          })
          break
        case "start":
          setConfirmStartDialog({
            testName: formativeTest.title,
            testId: formativeTest.id
          })
          break
        case "stop":
          setConfirmStopDialog({
            testName: formativeTest.title,
            testId: formativeTest.id
          })
          break
        case "doAgain":
          const studentsOnTest = test?.participantsInfo
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
            .filter(student =>
              latestSession.members.some(
                memberInSession => memberInSession === student.studliId
              )
            ) as unknown as Members

          setConfirmRedoDialog({
            testName: formativeTest.title,
            testId: formativeTest.id,
            students: studentsOnTest,
            numberOfSessions: formativeTest.sessions.length,
            test: formativeTest,
            session: latestSession
          })
          break
      }
    }
  }

  const onStartTest = (id: number) => {
    setConfirmStartDialog(null)
    dispatch(startFormativeTest({ testId: id }))
  }

  const onRemoveTest = (id: number) => {
    setConfirmRemoveDialog(null)
    dispatch(removeFormativeTest({ testId: id }))
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR, {
        classroomId,
        testId: id
      })
    )
  }

  const onStopTest = (id: number, endTime: Moment) => {
    setConfirmStopDialog(null)
    dispatch(stopFormativeTest({ testId: id, stopAt: endTime }))
  }

  const onRedoTest = (values: RedoFormValues) => {
    setConfirmRedoDialog(null)
    dispatch(
      redoFormativeTest({
        testId: values.id,
        instruction: JSON.stringify(values.instruction),
        members: values.members,
        startNow: values.saveType === "startNow"
      })
    )
  }

  const renderHeaderButtonAndMenu = (renderTest: FormativeTest) => {
    const menuItems = menuOptions(
      renderTest,
      onFormativeTestMenuClick,
      "single",
      renderTest.createdBy === user?.studliId
    )
    return (
      <StyledHeaderButtonsWrapper>
        {(renderTest.status === "stopped" ||
          renderTest.status === "published" ||
          renderTest.status === "ongoing" ||
          renderTest.status === "draft") &&
          renderStatusChangeButton(renderTest.status, renderTest.id)}
        <MoreMenu menuItems={menuItems} />
      </StyledHeaderButtonsWrapper>
    )
  }
  const renderStatusChangeButton = (
    status: FormativeTest["status"],
    testId: number
  ) => {
    if (status === "ongoing") {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onFormativeTestMenuClick("stop", testId)}
        >
          <StopIcon /> Stoppa
        </Button>
      )
    }
    if (status === "stopped") {
      return (
        <Button
          onClick={() => onFormativeTestMenuClick("doAgain", testId)}
          variant="outlined"
          color="primary"
        >
          <RedoIcon /> Gör om
        </Button>
      )
    }
    if (status === "published") {
      return (
        <Button
          onClick={() => onFormativeTestMenuClick("start", testId)}
          variant="outlined"
          color="primary"
        >
          <PlayIcon /> Starta
        </Button>
      )
    }
    if (status === "draft") {
      return (
        <Button
          onClick={() => onFormativeTestMenuClick("edit", testId)}
          variant="outlined"
          color="primary"
        >
          Redigera
        </Button>
      )
    }
  }

  const showFullScreenResults = () => {
    if (selectedSession && test) {
      const membersWithAnswers = getMembersWithAnswers(
        selectedSession,
        test,
        answers as ParsedFormativeProgressionAnswers
      )
      setFullScreenResultDialog({ questions: membersWithAnswers })
    }
  }

  const renderHeader = (renderTest: FormativeTest) => {
    const latestSession = getLatestSession(renderTest.sessions)
    const filteredProgress = progress
      ? progress.filter(p => members.some(m => m.studliId === p.memberId))
      : []
    const progressForBar = getProgressForBar(
      selectedSession ? selectedSession : renderTest.sessions[0],
      filteredProgress
    )
    const sessionInstructions = selectedSession
      ? JSON.parse(selectedSession.instruction)
      : {}

    return (
      <StyledHeaderContainer>
        <StyledButtonWrapper>
          {renderTest.sessions.length > 1 && (
            <StyledFormControl variant="outlined">
              {selectedSession && (
                <>
                  <InputLabel id="testsession-label">
                    Välj testtillfälle
                  </InputLabel>
                  <Select
                    labelId="testsession-label"
                    label="Välj testtillfälle"
                    id="book-menu"
                    value={selectedSession.id}
                    onChange={onChangeSession}
                  >
                    {renderTest.sessions.map(session => {
                      return (
                        <MenuItem key={session.id} value={session.id}>
                          {getMenuItemName(session, renderTest)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </>
              )}
            </StyledFormControl>
          )}
          {selectedSession &&
            selectedSession.id === latestSession.id &&
            renderHeaderButtonAndMenu(renderTest)}
        </StyledButtonWrapper>

        <StyledTestNameContainer>
          <Stack>
            <StyledTestTitleTagContainer>
              <StyledTitle variant="h1">{renderTest.title}</StyledTitle>
              <FormativeStatusTag
                status={renderTest.status}
                numberOfSessions={renderTest.sessions.length}
                endsAt={latestSession.endedAt}
                onEndedCounter={NOOP}
              />
            </StyledTestTitleTagContainer>
            <Stack direction="row" alignItems="center" spacing={1.25} mt={1}>
              <Typography>Skapat av</Typography>
              <Avatar
                firstName={renderTest.createdByFirstName}
                lastName={renderTest.createdByLastName}
                studliId={renderTest.createdBy}
                picture={renderTest.createdByPictureUrl}
              />
              <IsPublicIcon
                isPublic={renderTest.isPublic}
                createdBy={renderTest.createdBy}
              />
            </Stack>
          </Stack>
          {progressForBar && (
            <StyledProgressWrapper>
              <InfoPopover
                type="Info"
                content={
                  <div>
                    <StyledLegendContainer>
                      <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                      Inlämnat / Klar
                    </StyledLegendContainer>
                    <StyledLegendContainer>
                      <StyledLegendBox segment={PROGRESS_SEGMENT.IN_PROGRESS} />
                      Påbörjad
                    </StyledLegendContainer>
                    <StyledLegendContainer>
                      <StyledLegendBox segment={PROGRESS_SEGMENT.NOT_STARTED} />
                      Ej påbörjad
                    </StyledLegendContainer>
                  </div>
                }
              />
              <Progress
                onOpenProgressDialog={() =>
                  onProgressDialogClick(progressForBar, renderTest.title)
                }
                done={progressForBar.done.length}
                type="formativeQuestions"
                inProgress={progressForBar.inProgress.length}
                notStarted={progressForBar.notStarted.length}
              />
            </StyledProgressWrapper>
          )}
        </StyledTestNameContainer>
        {sessionInstructions && sessionInstructions.text && (
          <StyledDescription>
            <HtmlRenderer html={sessionInstructions.text} />
          </StyledDescription>
        )}
        {sessionInstructions && sessionInstructions.audio && (
          <AudioPlayer src={sessionInstructions.audio} label="Beskrivning" />
        )}
      </StyledHeaderContainer>
    )
  }

  const renderResults = (renderTest: FormativeTest) => {
    const testWithFilteredParticipants = {
      ...renderTest,
      participantsInfo: renderTest.participantsInfo.filter(p =>
        selectedSession?.members.includes(p.studliId)
      )
    }
    return (
      <StyledPaper>
        <StyledPaperContent>
          <StyledResultsTitle variant="h2">Resultat</StyledResultsTitle>
        </StyledPaperContent>
        <Divider />
        <ResultTabs
          data={testWithFilteredParticipants}
          type="formative"
          resultTable={
            <>
              <Wrapper>
                <Tabs
                  centered={windowSize.md}
                  variant={!windowSize.md ? "fullWidth" : undefined}
                  value={currentTab}
                  onChange={onTabChange}
                  aria-label="Resultat översikt"
                >
                  <Tab value={0} label="Elever" {...a11yProps(0)} />
                  <Tab value={1} label="Frågor" {...a11yProps(1)} />
                </Tabs>
                <Divider />
              </Wrapper>
              <TabPanel>
                {currentTab === 0 && progress && selectedSession && (
                  <FormativeStudentsResult
                    progress={progress}
                    test={renderTest}
                    session={selectedSession}
                  />
                )}
                {currentTab === 1 && answers && selectedSession && (
                  <>
                    <StyledResultsButtonsWrapper>
                      <Button
                        onClick={() => setShowAnswers(!showAnswers)}
                        variant="outlined"
                        color="primary"
                      >
                        {showAnswers ? "Dölj alla svar" : "Visa alla svar"}
                      </Button>
                      <Button
                        onClick={showFullScreenResults}
                        variant="outlined"
                        color="primary"
                      >
                        Visa i helskärm
                      </Button>
                    </StyledResultsButtonsWrapper>
                    <FormativeQuestionAnswers
                      test={renderTest}
                      answers={answers}
                      type="class"
                      showAnswers={showAnswers}
                      session={selectedSession}
                      prevSession={getPreviousSessionFromSelected(
                        renderTest,
                        selectedSession
                      )}
                    />
                  </>
                )}
              </TabPanel>
            </>
          }
        />
      </StyledPaper>
    )
  }

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue)
  }

  return (
    <div>
      {test && renderHeader(test)}
      {test && renderResults(test)}
      {progressDialogData !== null && (
        <Dialog
          onClose={() => setProgressDialogData(null)}
          open={progressDialogData !== null}
          maxWidth="sm"
          fullWidth
        >
          <ExercisesProgressDialog
            onClose={() => setProgressDialogData(null)}
            inProgress={
              progressDialogData.inProgress as MembersWithOnlineStatus
            }
            done={progressDialogData.done as MembersWithOnlineStatus}
            notStarted={
              progressDialogData.notStarted as MembersWithOnlineStatus
            }
            type="formativeQuestions"
            title={progressDialogData.title}
          />
        </Dialog>
      )}
      {confirmStartDialog && (
        <Dialog
          onClose={() => setConfirmStartDialog(null)}
          maxWidth="xs"
          fullWidth
          open
          aria-modal="true"
          aria-labelledby="start-dialog-title"
          aria-describedby="start-dialog-description"
        >
          <ConfirmationDialog
            title={`Starta ${confirmStartDialog.testName}`}
            body="När testet startas blir det tillgängligt i elevernas produkter."
            onCancel={() => setConfirmStartDialog(null)}
            confirmLabel="Starta test"
            cancelLabel="Avbryt"
            onConfirm={() => onStartTest(confirmStartDialog.testId)}
            ariaTitleId="start-dialog-title"
            ariaDescId="start-dialog-description"
          />
        </Dialog>
      )}
      {confirmRemoveDialog && (
        <Dialog
          onClose={() => setConfirmRemoveDialog(null)}
          maxWidth="xs"
          fullWidth
          open
          aria-modal="true"
          aria-labelledby="ta-bort-dialog-title"
          aria-describedby="ta-bort-dialog-description"
        >
          <ConfirmationDialog
            title={`Ta bort ${confirmRemoveDialog.testName}?`}
            body="När testet tagits bort försvinner det helt från klassrummet. Om det skickats ut till elever tas det även bort från deras lista över tidigare test."
            onCancel={() => setConfirmRemoveDialog(null)}
            confirmLabel="Ta bort test"
            cancelLabel="Avbryt"
            onConfirm={() => onRemoveTest(confirmRemoveDialog.testId)}
            ariaTitleId="ta-bort-dialog-title"
            ariaDescId="ta-bort-dialog-description"
          />
        </Dialog>
      )}
      {confirmStopDialog && (
        <Dialog
          onClose={() => setConfirmStopDialog(null)}
          maxWidth="xs"
          fullWidth
          open
          aria-modal="true"
          aria-labelledby="stop-dialog-title"
          aria-describedby="stop-dialog-description"
        >
          <StopTestDialog
            testName={confirmStopDialog.testName}
            testId={confirmStopDialog.testId}
            onCancel={() => setConfirmStopDialog(null)}
            onSubmit={onStopTest}
          />
        </Dialog>
      )}
      {confirmRedoDialog && (
        <Dialog
          onClose={() => setConfirmRedoDialog(null)}
          maxWidth="md"
          fullWidth
          open
          aria-modal="true"
          aria-labelledby="redo-dialog-title"
          aria-describedby="redo-dialog-description"
        >
          <RedoTestDialog
            testName={confirmRedoDialog.testName}
            testId={confirmRedoDialog.testId}
            onCancel={() => setConfirmRedoDialog(null)}
            onRedoTest={onRedoTest}
            test={confirmRedoDialog.test}
            session={confirmRedoDialog.session}
            students={confirmRedoDialog.students}
            numberOfSessions={confirmRedoDialog.numberOfSessions}
          />
        </Dialog>
      )}
      {fullScreenResultDialog !== null && (
        <Dialog
          onClose={() => setFullScreenResultDialog(null)}
          open={fullScreenResultDialog !== null}
          fullScreen
          fullWidth
        >
          <FormativeResultsDialog
            onClose={() => setFullScreenResultDialog(null)}
            questions={fullScreenResultDialog.questions}
          />
        </Dialog>
      )}
    </div>
  )
}

export default FormativeQuestionsResult

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}
