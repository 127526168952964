import React, { useState, useEffect, useMemo } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Grid, Container, Menu, MenuItem, Button, Stack } from "@mui/material"

import MobileOrDesktop from "../../containers/MobileOrDesktop"
import TitleAndDescription from "../../components/TitleAndDescription"
import {
  Assignment,
  AssignmentsWithStatusAndProgression,
  AssignmentWithStatusAndProgression,
  Assignments,
  AssignmentMenuOptions,
  CreateAssignmentData,
  EditEchoActionTypes
} from "../../modules/assignments/types"
import { DownloadAssignments } from "../../modules/assignments/download"
import AssignmentsTable from "../../components/AssignmentsTable"
import AssignmentsList from "../../components/AssignmentsList"
import {
  StyledDivider,
  StyledButton,
  StyledDownloadButton
} from "./StyledAssignments"
import moment from "moment"
import * as assignmentsSelectors from "../../modules/assignments/selectors"
import * as routerSelectors from "../../modules/router/selectors"
import * as assignmentsActions from "../../modules/assignments/actions"
import { RootState } from "../../modules/store"
import * as routerActions from "../../modules/router/actions"
import { ReactComponent as AssignmentsSVG } from "../../assets/images/card-assignments.svg"
import { createAssignmentsArrayWithStatusAndProgress } from "./common/helpers"
import ClassroomAssignmentImportDialog from "../../components/ClassroomAssignmentImportDialog"
import PreparedAssignmentImportDialog from "../../components/PreparedAssignmentImportDialog"
import * as membersSelectors from "../../modules/members/selectors"
import { Members } from "../../modules/members/types"
import Dialog from "../../components/Dialog"
import ExercisesProgressDialog from "../../components/ExercisesProgressDialog"
import { MembersWithOnlineStatus } from "../Exercises/store/types"
import * as assignmentsProgressSelectors from "../../modules/assignmentsProgress/selectors"
import {
  AssignmentsTaskProgress,
  AssignmentsProgress
} from "../../modules/assignmentsProgress/types"
import { Stickers, Features } from "../../modules/classroom/types"
import {
  selectClassroomStickers,
  selectClassroomFeatures,
  selectClassroomName,
  selectTypeOfClassroom,
  selectClassroomIsTest,
  selectAllActiveGroups,
  selectAllGroups,
  selectClassroomSchoolUnitCode
} from "../../modules/classroom/selectors"
import {
  doesCurrentUserHasATeacherGuide,
  selectAuthenticatedUser
} from "../../modules/users/selectors"
import OnBoarding from "../../components/OnBoarding"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import TipsBanner from "../../components/Banners/TipsBanner"
import { SETTING } from "../../modules/users/constants"
import { updateUserSettingRequest } from "../../modules/users/actions"
import FullWidthContainer from "../../containers/PageContainers/FullWidthContainer"

type StateProps = {
  assignments: Assignments
  classroomId: number
  classroomName: string
  members: Members
  assignmentsTaskProgress: AssignmentsTaskProgress
  assignmentsProgress: AssignmentsProgress
  stickers: Stickers
  classroomFeatures: Features
  hasCurrentUserTeacherGuide: boolean
  isTestClassroom: boolean
}

type DispatchProps = {
  goToRoute: (type: string, payload: object) => void
  editAssignment: (assignment: Assignment, echo: EditEchoActionTypes) => void
  createAssignment: (assignment: CreateAssignmentData) => void
  removeAssignment: (assignmentId: Assignment["id"]) => void
}

type Props = StateProps & DispatchProps

const AssignmentsPage = ({
  assignments,
  classroomId,
  classroomName,
  goToRoute,
  editAssignment,
  createAssignment,
  removeAssignment,
  members,
  assignmentsProgress,
  assignmentsTaskProgress,
  stickers,
  classroomFeatures,
  isTestClassroom,
  hasCurrentUserTeacherGuide
}: Props) => {
  const [checkedArchivedAssignments, setCheckedArchivedAssignments] =
    useState<AssignmentsWithStatusAndProgression>([])
  const [checkedNotArchivedAssignments, setCheckedNotArchivedAssignments] =
    useState<AssignmentsWithStatusAndProgression>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [assignmentsWithStatus, setAssignmentsWithStatus] =
    useState<AssignmentsWithStatusAndProgression>([])
  const [openImportClassroomAssignment, toggleOpenImportClassroomAssignment] =
    useState<boolean>(false)
  const [openImportPreparedAssignment, toggleOpenImportPreparedAssignment] =
    useState<boolean>(false)

  const [progressDialogData, setProgressDialogData] =
    useState<AssignmentWithStatusAndProgression | null>(null)

  const [onConfirmRemoveDialog, setOnConfirmRemoveDialog] = useState<{
    assignment: Assignment
  } | null>(null)

  const classroomSchoolUnitCode = useSelector(selectClassroomSchoolUnitCode)
  const activeGroups = useSelector(selectAllActiveGroups)
  const groups = useSelector(selectAllGroups)
  const user = useSelector(selectAuthenticatedUser)
  const dispatch = useDispatch()

  const preparedAssignmentsLength = useSelector(
    assignmentsSelectors.selectAllPreparedAssignmentsLength
  )

  const isLoadingImport = useSelector(
    assignmentsSelectors.selectLoadingImportAssignment
  )

  const classroomType = useSelector(selectTypeOfClassroom)

  const classrooms = useSelector(
    assignmentsSelectors.selectAllImportableAssignments
  )

  useEffect(() => {
    const progressMembers = members.filter(m =>
      activeGroups.some(g => m.groups?.includes(g.id))
    )
    const newAssignmentsWithStatus =
      createAssignmentsArrayWithStatusAndProgress(
        assignments,
        assignmentsProgress,
        assignmentsTaskProgress,
        progressMembers,
        classroomType === "adapt"
      )
    setAssignmentsWithStatus(newAssignmentsWithStatus)

    // eslint-disable-next-line
  }, [assignments, assignmentsTaskProgress, assignmentsProgress, members])

  const shouldShowImportAssignments = useMemo(() => {
    return classrooms.some(c => c.assignments.length > 0)
  }, [classrooms])

  const onHideTip = () =>
    dispatch(
      updateUserSettingRequest(
        user?.studliId as number,
        SETTING.settingShowTipAssignment,
        true
      )
    )

  const renderTitleAndDescription = () => {
    const paragraphs = [
      `I uppdragsöversikten kan du följa gruppens aktivitet i uppdragen. För mer detaljerad information går du in i respektive uppdrag och vidare in på varje elevs resultat.`
    ]

    return (
      <>
        {!groups?.length && user?.settings.settingShowTipAssignment && (
          <TipsBanner
            tipHeaderText="För att kunna skicka uppdrag"
            tipContentText="Om du vill skicka uppdrag till elever i klassrummet behöver du ingå som lärare i en grupp med elever. Du kan gå med i en av skolans befintliga grupper eller skapa en helt ny grupp där du lägger till dig själv och de elever du vill följa."
            actions={
              <>
                <Button
                  component="a"
                  href={`${process.env.REACT_APP_BOOKSHELF_URL}/${classroomSchoolUnitCode}/admin/grupper`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Administrera grupper
                </Button>
                <Button onClick={onHideTip}>Dölj</Button>
              </>
            }
          />
        )}
        {renderCreateAssignmentsMenu(false)}
        <TitleAndDescription
          title="Följ upp dina uppdrag"
          paragraphs={paragraphs}
        />
      </>
    )
  }
  const onCheckboxClick = (
    event: any,
    assignment: AssignmentWithStatusAndProgression
  ) => {
    event.stopPropagation()

    const isArchived = assignment.status === "archived"

    if (isArchived) {
      const newCheckedArray = updateCheckedArray(
        checkedArchivedAssignments,
        assignment
      )
      setCheckedArchivedAssignments(newCheckedArray)
    } else {
      const newCheckedArray = updateCheckedArray(
        checkedNotArchivedAssignments,
        assignment
      )
      setCheckedNotArchivedAssignments(newCheckedArray)
    }
  }

  const updateCheckedArray = (
    checkedArray: AssignmentsWithStatusAndProgression,
    assignment: AssignmentWithStatusAndProgression
  ) => {
    const indexOfCheckedId = checkedArray.findIndex(
      checkedAssignment => checkedAssignment.id === assignment.id
    )
    const newCheckedArray = [...checkedArray]
    if (indexOfCheckedId !== -1) {
      newCheckedArray.splice(indexOfCheckedId, 1)
    } else {
      newCheckedArray.push(assignment)
    }

    return newCheckedArray
  }

  const toggleAllAssignments = (
    event: React.ChangeEvent<HTMLInputElement>,
    status: Assignment["status"]
  ) => {
    const isArchived = status === "archived"

    if (event.target.checked) {
      if (isArchived) {
        setCheckedArchivedAssignments(archivedAssignments)
      } else {
        setCheckedNotArchivedAssignments(notArchivedAssignments)
      }

      return
    }
    if (isArchived) {
      setCheckedArchivedAssignments([])
    } else {
      setCheckedNotArchivedAssignments([])
    }
  }

  const onMenuClick = (route: string, payload: object) => {
    goToRoute(route, payload)
  }

  const onAssignmentClick = (assignmentId: Assignment["id"]) => {
    goToRoute(routerActions.ROUTE_UPPDRAG_INFO, { assignmentId, classroomId })
  }

  const onListMenuClick = (
    type: AssignmentMenuOptions,
    assignmentId: Assignment["id"]
  ) => {
    const assignment: Assignment | undefined = assignments.find(
      assignment => assignment.id === assignmentId
    )
    if (assignment) {
      switch (type) {
        case "share":
          editAssignment({ ...assignment, isPublic: !assignment.isPublic }, "")
          break
        case "activate":
          editAssignment({ ...assignment, status: "published" }, "activate")
          break
        case "archive":
          editAssignment({ ...assignment, status: "archived" }, "archive")
          break
        case "createCopy":
          const assignmentToCopy: Partial<Assignment> = {
            ...assignment,
            participants: [],
            id: undefined
          }

          assignmentToCopy.title = `Kopia av: ${assignmentToCopy.title}`
          assignmentToCopy.status = "draft"
          assignmentToCopy.isPublic = false
          if (classroomType === "adapt" || classroomType === "fnDill") {
            assignmentToCopy.stickerId =
              stickers[Math.floor(Math.random() * stickers.length)].id
          }

          assignmentToCopy.assignmentTasks = (
            assignmentToCopy.assignmentTasks || []
          ).filter(t => !t.needsManualModification)

          createAssignment(assignmentToCopy as CreateAssignmentData)
          break
        case "edit":
          goToRoute(routerActions.ROUTE_UPPDRAG_REDIGERA, {
            classroomId,
            assignmentId
          })
          break
        case "finish":
          editAssignment({ ...assignment, status: "finished" }, "finish")
          break

        case "remove":
          setOnConfirmRemoveDialog({ assignment })
          break

        case "start":
          editAssignment({ ...assignment, startDate: moment() }, "start")
          break
      }
    }
  }

  const onRemoveAssignment = (assignmentId: number) => {
    removeAssignment(assignmentId)
    setOnConfirmRemoveDialog(null)
  }

  const renderOnBoarding = () => {
    return (
      <>
        {!groups?.length && user?.settings.settingShowTipAssignment && (
          <TipsBanner
            tipHeaderText="För att kunna skicka uppdrag"
            tipContentText="Om du vill skicka uppdrag till elever i klassrummet behöver du ingå som lärare i en grupp med elever. Du kan gå med i en av skolans befintliga grupper eller skapa en helt ny grupp där du lägger till dig själv och de elever du vill följa."
            actions={
              <>
                <Button
                  component="a"
                  href={`${process.env.REACT_APP_BOOKSHELF_URL}/${classroomSchoolUnitCode}/admin/grupper`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Administrera grupper
                </Button>
                <Button onClick={onHideTip}>Dölj</Button>
              </>
            }
          />
        )}
        <Container maxWidth="lg">{renderCreateAssignmentsMenu(true)}</Container>
        <OnBoarding {...renderCardContent()} />
      </>
    )
  }

  const renderCardContent = () => {
    return {
      title: "Lägg till uppdrag",
      body: [
        `Använd uppdrag för att hantera exempelvis läxor eller veckouppgifter. Det blir lättare för eleverna att veta vad de ska göra och enklare för dig att följa upp.`
      ],
      image: <AssignmentsSVG />
    }
  }

  const renderCreateAssignmentsMenu = (onBoarding: boolean) => {
    return (
      <>
        <StyledButton
          marginleft={0}
          aria-controls="simple-menu"
          aria-haspopup="true"
          variant={onBoarding ? "contained" : "outlined"}
          color="primary"
          sx={{ width: "15rem" }}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget)
          }
        >
          lägg till uppdrag
        </StyledButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() =>
              onMenuClick(routerActions.ROUTE_UPPDRAG_LAGGTILL_NYTT, {
                classroomId
              })
            }
          >
            Skapa nytt uppdrag
          </MenuItem>
          {hasCurrentUserTeacherGuide && preparedAssignmentsLength > 0 && (
            <MenuItem
              onClick={() => {
                toggleOpenImportPreparedAssignment(true)
                setAnchorEl(null)
              }}
            >
              Välj förberett uppdrag
            </MenuItem>
          )}
          {shouldShowImportAssignments && (
            <MenuItem
              onClick={() => {
                toggleOpenImportClassroomAssignment(true)
                setAnchorEl(null)
              }}
            >
              Importera uppdrag från ett klassrum
            </MenuItem>
          )}
        </Menu>
      </>
    )
  }

  const downloadSelectedAssignments = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    DownloadAssignments(
      "assingments-" + classroomName,
      ...checkedNotArchivedAssignments,
      ...checkedArchivedAssignments
    )
  }

  const renderDownloadButton = () => {
    return isTestClassroom ? (
      <StyledDownloadButton
        variant="contained"
        color="primary"
        onClick={downloadSelectedAssignments}
      >
        Ladda ner
      </StyledDownloadButton>
    ) : null
  }

  const archivedAssignments = assignmentsWithStatus.filter(
    assignment => assignment.status === "archived"
  )
  const notArchivedAssignments = assignmentsWithStatus
    .filter(assignment => assignment.status !== "archived")
    .sort(
      (a, b) =>
        statusSortWeight.indexOf(a.calculatedStatus) -
        statusSortWeight.indexOf(b.calculatedStatus) ||
        moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
    )

  const assignmentsThatNeedManualModification = notArchivedAssignments.reduce(
    (res, a) => {
      if (
        Array.isArray(a.assignmentTasks) &&
        a.assignmentTasks.some(t => !!t.needsManualModification)
      ) {
        return [...res, a.id]
      }

      return res
    },
    [] as number[]
  )

  return (
    <FullWidthContainer useMargin role="main">
      {assignments.length < 1 && !isLoadingImport ? (
        renderOnBoarding()
      ) : (
          <>
            <Stack maxWidth="lg"> {renderTitleAndDescription()}</Stack>
            <Grid item>
              <MobileOrDesktop>
                {{
                  desktop: (
                    <>
                      <AssignmentsTable
                        onCheckboxClick={onCheckboxClick}
                        checkedAssignments={checkedNotArchivedAssignments}
                        toggleAllAssignments={toggleAllAssignments}
                        assignments={notArchivedAssignments}
                        tableTitle="Uppdrag"
                        onMenuClick={onListMenuClick}
                        onAssignmentClick={onAssignmentClick}
                        onProgressClick={setProgressDialogData}
                        classroomFeatures={classroomFeatures}
                        isTestClassroom={isTestClassroom}
                        members={members}
                        assignmentsThatNeedManualModification={
                          assignmentsThatNeedManualModification
                        }
                        showSkeleton
                        showFilter
                      />
                      {renderDownloadButton()}
                      <StyledDivider />
                      <AssignmentsTable
                        onCheckboxClick={onCheckboxClick}
                        checkedAssignments={checkedArchivedAssignments}
                        toggleAllAssignments={toggleAllAssignments}
                        assignments={archivedAssignments}
                        tableTitle="Arkiverade uppdrag"
                        onMenuClick={onListMenuClick}
                        onAssignmentClick={onAssignmentClick}
                        onProgressClick={setProgressDialogData}
                        classroomFeatures={classroomFeatures}
                        isTestClassroom={false}
                        members={members}
                      />
                    </>
                  ),
                  mobile: (
                    <>
                      <AssignmentsList
                        onCheckboxClick={onCheckboxClick}
                        checkedAssignments={checkedNotArchivedAssignments}
                        toggleAllAssignments={toggleAllAssignments}
                        assignments={notArchivedAssignments}
                        tableTitle="Uppdrag"
                        onMenuClick={onListMenuClick}
                        onAssignmentClick={onAssignmentClick}
                        onProgressClick={setProgressDialogData}
                        type="assignmentsList"
                        stickers={stickers}
                        classroomFeatures={classroomFeatures}
                        members={members}
                        showSkeleton
                        assignmentsThatNeedManualModification={
                          assignmentsThatNeedManualModification
                        }
                        showFilter
                      />
                      <StyledDivider />
                      <AssignmentsList
                        onCheckboxClick={onCheckboxClick}
                        checkedAssignments={checkedArchivedAssignments}
                        toggleAllAssignments={toggleAllAssignments}
                        assignments={archivedAssignments}
                        tableTitle="Arkiverade uppdrag"
                        onMenuClick={onListMenuClick}
                        onAssignmentClick={onAssignmentClick}
                        onProgressClick={setProgressDialogData}
                        members={members}
                        type="assignmentsList"
                        stickers={stickers}
                        classroomFeatures={classroomFeatures}
                      />
                    </>
                  )
                }}
              </MobileOrDesktop>
            </Grid>
          </>
        )}
      {openImportClassroomAssignment && (
        <ClassroomAssignmentImportDialog
          onClose={() => toggleOpenImportClassroomAssignment(false)}
        />
      )}
      {openImportPreparedAssignment && (
        <PreparedAssignmentImportDialog
          open={openImportPreparedAssignment}
          onClose={() => toggleOpenImportPreparedAssignment(false)}
        />
      )}
      {onConfirmRemoveDialog && (
        <Dialog
          onClose={() => setOnConfirmRemoveDialog(null)}
          maxWidth="xs"
          fullWidth
          open
          role="dialog"
          aria-modal="true"
          aria-labelledby="ta-bort-dialog-title"
          aria-describedby="ta-bort-dialog-description"
        >
          <ConfirmationDialog
            title="Ta bort uppdrag"
            body={`Vill du ta bort ${onConfirmRemoveDialog.assignment.title}?  Uppdraget kommer inte längre synas i klassrummet. Det tas även bort från elevernas vy. `}
            onCancel={() => setOnConfirmRemoveDialog(null)}
            confirmLabel="Ta bort uppdrag"
            cancelLabel="Avbryt"
            onConfirm={() =>
              onRemoveAssignment(onConfirmRemoveDialog.assignment.id)
            }
            ariaTitleId="ta-bort-dialog-title"
            ariaDescId="ta-bort-dialog-description"
          />
        </Dialog>
      )}
      {progressDialogData !== null && (
        <Dialog
          onClose={() => setProgressDialogData(null)}
          open={progressDialogData !== null}
          maxWidth="sm"
          fullWidth
        >
          <ExercisesProgressDialog
            onClose={() => setProgressDialogData(null)}
            inProgress={
              progressDialogData.progress.inProgress as MembersWithOnlineStatus
            }
            done={progressDialogData.progress.done as MembersWithOnlineStatus}
            notStarted={
              progressDialogData.progress.notStarted as MembersWithOnlineStatus
            }
            approved={
              progressDialogData.progress.approved as MembersWithOnlineStatus
            }
            type={classroomType === "adapt" ? "adaptAssignment" : "assignments"}
            title={progressDialogData.title}
          />
        </Dialog>
      )}
    </FullWidthContainer>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    assignments: assignmentsSelectors.selectAllAssignments(state),
    classroomId: routerSelectors.selectCurrentClassroomId(state),
    classroomName: selectClassroomName(state),
    members: membersSelectors.selectStudents(state),
    assignmentsTaskProgress:
      assignmentsProgressSelectors.selectAllAssignmentsTaskProgress(state),
    assignmentsProgress:
      assignmentsProgressSelectors.selectAllAssignmentsProgress(state),
    stickers: selectClassroomStickers(state),
    classroomFeatures: selectClassroomFeatures(state),
    hasCurrentUserTeacherGuide: doesCurrentUserHasATeacherGuide(state),
    isTestClassroom: selectClassroomIsTest(state)
  }
}

const mapDispatchToProps: DispatchProps = {
  goToRoute: routerActions.gotoRoute,
  editAssignment: assignmentsActions.editAssignment,
  createAssignment: assignmentsActions.createAssignment,
  removeAssignment: assignmentsActions.removeAssignment
}

const ConnectedAssignments = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentsPage)

export default ConnectedAssignments

export const statusSortWeight = [
  "passed",
  "ongoing",
  "coming",
  "draft",
  "finished"
]
