import Toolbar from "@mui/material/Toolbar"
import { Typography, styled, TypographyProps } from "@mui/material"

export const StyledTableToolbar = styled(Toolbar as any)`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  align-items: flex-start;
  background-color: #f1f5f9;
  ${props => props.theme.breakpoints.down("xs")} {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
  }
  ${props => props.theme.breakpoints.up("xs")} {
    justify-content: space-between;
  }
  background-color: ${props =>
    props.theme &&
    props.active &&
    props.active === "true"};
`

export const StyledActionWrapper = styled("div")``

export const StyledAction = styled("span")`
  ${props => props.theme.breakpoints.down("sm")} {
    padding-left: 0px;
    padding-right: 10px;
  }
  ${props => props.theme.breakpoints.up("sm")} {
    padding: 5px;
  }

  & > svg {
    cursor: pointer;
  }
`
export const StyledTitle = styled(Typography)<
  TypographyProps & { component: string }
>`
  padding-top: 1rem;
`
