import { styled } from "@mui/material"
import Avatar from "../Avatar"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"

export const StyledGoalWrapper = styled("div")`
  margin: 0 auto;
  width: 90%;
`
export const StyledTableHeader = styled("header")`
  margin: 0;
  font-size: 25px;
`
export const StyledName = styled("p")`
  display: inline;
  position: relative;
  bottom: 12px;
`

export const StyledAvatar = styled(Avatar)`
  display: inline-block;
  margin-top: 5px;
  margin-right: 7px;
  border-radius: 50%;
`
export const StyledCompletedTask = styled("p")`
  border-radius: 5px;
  display: inline;
  color: white;
  background: #006cc8;
  padding: 5px;
`
export const StyledUnCompletedTask = styled("p")`
  border-radius: 5px;
  display: inline;
  color: #004383;
  background: #42b9fe;
  padding: 5px;
`
export const StyledTableHead = styled(TableHead)`
  border-bottom: 1px solid lightgrey;
`
export const StyledTableCellStart = styled(TableCell)`
  padding: 15px;
  border: none;
  margin-left: 10px;
  border-top: 1px solid lightgrey;
`
export const StyledTableCellMiddle = styled(TableCell)`
  padding: 15px;
  border: none;
  border-top: 1px solid lightgrey;
`
export const StyledTableCellEnd = styled(TableCell)`
  padding: 15px;
  border: none;
  padding-right: 0;
  border-top: 1px solid lightgrey;
`
export const StyledDataCellName = styled(TableCell)`
  margin: 0;
`
export const StyledDataCellMiddle = styled(TableCell)`
  margin-top: 14px;
`
export const StyledAvatarWrapper = styled("div")`
  display: inline;
`
export const StyledStarWrapper = styled("div")`
  float: right;
`
export const StyledTableCellTest = styled(TableCell)`
  margin: 0;
`
