import { AUTH_TOKEN_FAILURE } from "../auth/actions"
import { SET_SNACKBAR } from "../application/actions"
import { SOCKET_ERROR } from "../socket/actions"
import { SnackbarData } from "../../containers/Snackbar/types"
import { CommunicationError } from "../communication/types"
import * as Sentry from "@sentry/browser"
import {
  SET_COMMUNICATION_ERROR,
  UI as COMMUNICATION_UI
} from "../communication/actions"

type LogSeverity = {
  severity?: "error" | "warn" | "log"
}

const consoleLog = (message: string, severity?: LogSeverity) => {
  if (process.env.REACT_APP_ENV !== "production" && message) {
    switch (severity) {
      case "error":
        window.console.error(message)
        break
      case "warn":
        window.console.warn(message)
        break
      default:
        window.console.log(message)
    }
  }
}

/**
 * Log to Sentry
 *
 * @param sentryFunction {Function} - The Sentry function to use.
 * @param param {string | Error} - The stuff to log.
 * @param severity {Sentry.Severity}
 * @param alwaysLog {boolean} - Ignore environment if true, handy for debugging.
 */
const logToSentryIfInProduction = (
  sentryFunction: Function,
  param: string | Error,
  severity?: Sentry.Severity,
  alwaysLog?: boolean
) => {
  if (alwaysLog || process.env.REACT_APP_ENV === "production") {
    sentryFunction(param, severity)
  }
}

/**
 * Logg when actions of interest are passing redux.
 * Some actions log to Sentry some to both Sentry and console.
 *
 * @param store {Object} - The whole redux-store.
 * @param next {Function} - Callback function that needs to be called.
 * @param action - The action to be examined, if of interest stuff will be logged.
 */
const logMiddleware =
  (store: any) =>
  (next: Function) =>
  (action: { type: string; payload?: any }) => {
    switch (action.type) {
      case SET_SNACKBAR:
        const setSnackbarPayload = action.payload as SnackbarData | undefined

        if (setSnackbarPayload) {
          if (setSnackbarPayload.severity === "error") {
            logToSentryIfInProduction(
              Sentry.captureMessage,
              `Snackbar error shown: ${setSnackbarPayload.message}`,
              Sentry.Severity.Error
            )
          }
          if (setSnackbarPayload.severity === "warning") {
            logToSentryIfInProduction(
              Sentry.captureMessage,
              `Snackbar warning shown: ${setSnackbarPayload.message}`,
              Sentry.Severity.Warning,
              true
            )
          }
        }
        break

      case AUTH_TOKEN_FAILURE:
        const authTokenFailurePayload = action.payload as Error | undefined

        if (authTokenFailurePayload instanceof Error) {
          consoleLog(authTokenFailurePayload.message)
          logToSentryIfInProduction(
            Sentry.captureException,
            authTokenFailurePayload
          )
        } else {
          const message = `Authentication failed with AUTH_TOKEN_FAILURE`
          consoleLog(message)
          logToSentryIfInProduction(Sentry.captureMessage, message)
        }
        break

      case SOCKET_ERROR:
        const socketErrorPayload = action.payload

        if (socketErrorPayload instanceof Error) {
          logToSentryIfInProduction(Sentry.captureException, socketErrorPayload)
        } else {
          logToSentryIfInProduction(
            Sentry.captureMessage,
            `SOCKET_ERROR, WS event, socket closed by backend?`,
            Sentry.Severity.Error
          )
        }
        break

      case SET_COMMUNICATION_ERROR:
        const communicationError = action.payload as CommunicationError

        const sentryError = new Error()
        const errorMessage = `Communication errors from ${communicationError.reporter}: ${communicationError.message}`

        consoleLog(errorMessage)
        sentryError.message = `${errorMessage}`
        logToSentryIfInProduction(Sentry.captureException, sentryError)
        break

      case COMMUNICATION_UI.LOG_COMMUNICATION_WARNING:
        const communicationWarningPayload = action.payload as CommunicationError

        logToSentryIfInProduction(
          Sentry.captureMessage,
          communicationWarningPayload.message,
          Sentry.Severity.Warning
        )
        break

      default:
      // Do nothing
    }

    return next(action)
  }

export default logMiddleware
