import {
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
  Checkbox,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TableBody
} from "@mui/material"
import Dialog from "../Dialog"
import { useDispatch, useSelector } from "react-redux"
import {
  selectAllGroups,
  selectClassroomSchoolUnitCode,
  selectCurrentGroups,
  selectHasFormativeTests
} from "../../modules/classroom/selectors"
import * as routerActions from "../../modules/router/actions"
import { selectCurrentRoute } from "../../modules/router/selectors"
import React, { useMemo } from "react"
import { changeClassroomGroup } from "../../modules/classroom/actions"
import { doesCurrentUserHasATeacherGuide } from "../../modules/users/selectors"
import { styled } from "@mui/system"
import GroupNameColor from "../GroupNameColor/GroupNameColor"

type Props = {
  open: boolean
  onClose: () => void
}
const DISABLED_ROUTES = [
  routerActions.ROUTE_UPPDRAG_REDIGERA,
  routerActions.ROUTE_UPPDRAG_LAGGTILL_NYTT,
  routerActions.ROUTE_FORMATIVA_FRAGOR_LAGGTILL,
  routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA
]
const StyledTableCell = styled(TableCell)`
  border-bottom: none;
`

const ChooseGroupDialog = ({ onClose, open }: Props) => {
  const currentGroups = useSelector(selectCurrentGroups)
  const currentRoute = useSelector(selectCurrentRoute)
  const groups = useSelector(selectAllGroups)
  const dispatch = useDispatch()
  const [selectedGroups, setSelectedGroups] = React.useState<number[]>([])
  const [sortDirectionASC, setSortDirectionASC] = React.useState<boolean>(true)
  const isClassroomFormativeTests = useSelector(selectHasFormativeTests)
  const hasTeacherGuide = useSelector(doesCurrentUserHasATeacherGuide)
  const classroomSchoolUnitCode = useSelector(selectClassroomSchoolUnitCode)
  const hasFormative = isClassroomFormativeTests && hasTeacherGuide

  React.useEffect(() => {
    setSelectedGroups(currentGroups.map(g => g.id))
  }, [currentGroups])

  const isDisabled = useMemo(
    () => DISABLED_ROUTES.includes(currentRoute),
    [currentRoute]
  )

  const onCheckboxClick = (groupId: number) => {
    const isChecked = selectedGroups.includes(groupId)
    setSelectedGroups(
      isChecked
        ? selectedGroups.filter(selected => selected !== groupId)
        : [...selectedGroups, groupId]
    )
  }

  const onChangeGroup = () => {
    dispatch(changeClassroomGroup(selectedGroups))
    onClose()
  }

  const checkAllGroups = () => {
    if (selectedGroups.length === groups.length) {
      setSelectedGroups([])
      return
    }

    setSelectedGroups([...groups.map(g => g.id)])
  }

  const getIndeterminate = () => {
    if (!selectedGroups.length) {
      return false
    }

    return selectedGroups.length < groups.length
  }

  const sortOnGroupName = () => {
    if (sortDirectionASC) {
      setSortDirectionASC(false)
      return
    }

    setSortDirectionASC(true)
  }

  const resetGroupsOnClose = () => {
    setSelectedGroups(currentGroups.map(g => g.id))
    onClose()
  }

  return (
    <Dialog onClose={resetGroupsOnClose} open={open} maxWidth="xs" fullWidth>
      {Boolean(groups.length) ? (
        <>
          <DialogTitle>Visa grupp</DialogTitle>
          <DialogContent>
            {isDisabled ? (
              <Stack my={2}>
                <Typography>
                  Du kan inte välja grupp när du redigerar ett uppdrag
                  {hasFormative && "/alt ett test"}. Spara och stäng för att
                  ändra vald grupp.
                </Typography>
              </Stack>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={selectedGroups.length === groups.length}
                        indeterminate={getIndeterminate()}
                        onChange={checkAllGroups}
                        inputProps={{
                          "aria-label":
                            selectedGroups.length === groups.length
                              ? "Avmakera alla grupper"
                              : "Markera alla grupper"
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TableSortLabel
                        aria-label="Sortera på gruppnamn"
                        active
                        direction={sortDirectionASC ? "asc" : "desc"}
                        onClick={() => sortOnGroupName()}
                      >
                        Grupp
                      </TableSortLabel>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups
                    .sort((first, second) =>
                      sortDirectionASC
                        ? first.name.localeCompare(second.name, "sv", {
                            sensitivity: "base"
                          })
                        : -1 *
                          first.name.localeCompare(second.name, "sv", {
                            sensitivity: "base"
                          })
                    )
                    .map(g => (
                      <TableRow
                        hover
                        sx={{ cursor: "pointer" }}
                        onClick={() => onCheckboxClick(g.id)}
                        key={g.id}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            checked={selectedGroups.includes(g.id)}
                            inputProps={{
                              "aria-label": selectedGroups.includes(g.id)
                                ? `Avmarkera ${g.name}`
                                : `Markera ${g.name}`
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <GroupNameColor option={g.color} name={g.name} />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={resetGroupsOnClose}>Stäng</Button>
            {!isDisabled && (
              <Button
                disabled={selectedGroups.length === 0}
                onClick={onChangeGroup}
              >
                Visa grupp
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Du ingår inte i någon grupp</DialogTitle>
          <DialogContent>
            <Typography>
              Om du vill följa upp elever i klassrummet behöver du ingå som
              lärare i en grupp med elever. Du kan gå med i en av skolans
              befintliga grupper eller skapa en helt ny grupp där du lägger till
              dig själv och de elever du vill följa.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Avbryt</Button>
            <Button
              component="a"
              href={`${process.env.REACT_APP_BOOKSHELF_URL}/${classroomSchoolUnitCode}/admin/grupper`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Administrera grupper
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default ChooseGroupDialog
