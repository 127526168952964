/**
 * We need to handle: Responses, Requests and Events.
 * Here are defined Redux-actions and server-message-actions,
 */

import {
  Members,
  GetAllMembersAction,
  AddMemberAction,
  RemoveMemberAction,
  ReceiveMembersAction,
  ReceiveRemoveMemberResponseAction,
  ReceiveMemberUpdatedAction,
  ReceiveMemberAddedAction,
  MemberRemovedEventAction,
  ReceiveNewAnswerAction,
  ChangeMemberPasswordAction,
  SetMembersAction,
  MemberType,
  ResetMembersAction,
  SetRecentlyAddedMembersAction,
  Member,
  ReceiveChangeClassPasswordAction,
  AddMembersToGroupAction
} from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  ADD_MEMBER_REQUEST = "ADD_MEMBER_REQUEST",
  REMOVE_MEMBER_REQUEST = "REMOVE_MEMBER_REQUEST",
  GET_ALL_MEMBERS_REQUEST = "GET_ALL_MEMBERS_REQUEST",
  UPDATE_MEMBER_REQUEST = "UPDATE_MEMBER_REQUEST",
  CHANGE_CLASS_PASSWORD_REQUEST = "CHANGE_CLASS_PASSWORD_REQUEST",
  ADD_MEMBER_TO_GROUP_REQUEST = "ADD_MEMBER_TO_GROUP_REQUEST"
}
/**
 * Redux-actions, originating from server. As result from a client-request.
 */
export enum RESPONSE {
  ADD_MEMBER_RESPONSE = "ADD_MEMBER_RESPONSE",
  REMOVE_MEMBER_RESPONSE = "REMOVE_MEMBER_RESPONSE",
  GET_ALL_MEMBERS_RESPONSE = "GET_ALL_MEMBERS_RESPONSE",
  UPDATE_MEMBER_RESPONSE = "UPDATE_MEMBER_RESPONSE",
  CHANGE_CLASS_PASSWORD_RESPONSE = "CHANGE_CLASS_PASSWORD_RESPONSE",
  ADD_MEMBER_TO_GROUP_RESPONSE = "ADD_MEMBER_TO_GROUP_RESPONSE"
}
/**
 * Redux-actions originating from server. Because of this or another client's actions.
 */
export enum EVENT {
  MEMBER_ADDED_EVENT = "MEMBER_ADDED_EVENT",
  MEMBER_REMOVED_EVENT = "MEMBER_REMOVED_EVENT",
  NEW_ANSWER_EVENT = "NEW_ANSWER_EVENT",
  MEMBER_UPDATED_EVENT = "MEMBER_UPDATED_EVENT",
  GOAL_COMPLETED_EVENT = "GOAL_COMPLETED_EVENT",
  ADD_PASSWORDS_TO_MEMBERS_EVENT = "ADD_PASSWORDS_TO_MEMBERS_EVENT"
}
//TODO: There's no need to separate request and response, this could be renamed to only ADD_MEMBER or
/**
 * Strings for "action" property when communicating with server.
 * Request and Response use the same string.
 * (The "type" property is this module's name).
 */
export enum SERVER_MESSAGE_ACTION {
  ADD_MEMBER_REQUEST = "add",
  ADD_MEMBER_RESPONSE = "add",
  REMOVE_MEMBER_REQUEST = "remove",
  REMOVE_MEMBER_RESPONSE = "remove",
  GET_ALL_MEMBERS_REQUEST = "list",
  GET_ALL_MEMBERS_RESPONSE = "list",
  UPDATE_MEMBER_REQUEST = "update",
  UPDATE_MEMBER_RESPONSE = "update",

  MEMBER_ADDED_EVENT = "added",
  MEMBER_REMOVED_EVENT = "removed",
  NEW_ANSWER_EVENT = "new_answer",
  MEMBER_UPDATED_EVENT = "updated",
  GOAL_COMPLETED_EVENT = "goal_completed",
  GOAL_UPDATED_EVENT = "goal_updated",
  ADD_MEMBER_TO_GROUP_REQUEST = "join_group",
  ADD_MEMBER_TO_GROUP_RESPONSE = "join_group",
  CHANGE_CLASS_PASSWORD_REQUEST = "updatePasswords",
  CHANGE_CLASS_PASSWORD_RESPONSE = "updatePasswords"
}

/**
 * Redux-actions for UI (internal UI usage, inter-component-communication).
 */
export enum UI {
  SET_MEMBERS = "SET_MEMBERS",
  RESET_MEMBERS = "RESET_MEMBERS",
  SET_RECENTLY_ADDED_MEMBERS = "SET_RECENTLY_ADDED_MEMBERS"
}

export const getAllMembers = (): GetAllMembersAction => ({
  type: REQUEST.GET_ALL_MEMBERS_REQUEST
})

export const addMember = (
  studliIds: number[],
  memberType: MemberType
): AddMemberAction => {
  return {
    type: REQUEST.ADD_MEMBER_REQUEST,
    payload: { studliIds, type: memberType }
  }
}

export const setRecentlyAddedMembers = (
  ids: Member["studliId"][]
): SetRecentlyAddedMembersAction => {
  return {
    type: UI.SET_RECENTLY_ADDED_MEMBERS,
    payload: { ids }
  }
}

export const addMemberToGroup = (
  studentStudliIds: number[],
  groupId: number
): AddMembersToGroupAction => {
  return {
    type: REQUEST.ADD_MEMBER_TO_GROUP_REQUEST,
    payload: { studentStudliIds, groupId }
  }
}

export const changeClassPassword = (
  members: Member["studliId"][],
  password: string
) => {
  return {
    type: REQUEST.CHANGE_CLASS_PASSWORD_REQUEST,
    payload: { memberIds: members, password }
  }
}

export const receiveMembers = (members: Members): ReceiveMembersAction => {
  return { type: RESPONSE.GET_ALL_MEMBERS_RESPONSE, payload: { members } }
}

export const setMembers = (members: Members): SetMembersAction => {
  return {
    type: UI.SET_MEMBERS,
    payload: { members }
  }
}

export const removeMemberResponse = (
  id: number
): ReceiveRemoveMemberResponseAction => {
  return { type: RESPONSE.REMOVE_MEMBER_RESPONSE, payload: { id } }
}

export const receiveMemberUpdatedAction = (
  payload: ReceiveMemberUpdatedAction["payload"]
): ReceiveMemberUpdatedAction => {
  return { type: EVENT.MEMBER_UPDATED_EVENT, payload }
}

export const removeMember = (id: Member["studliId"]): RemoveMemberAction => {
  return { type: REQUEST.REMOVE_MEMBER_REQUEST, payload: { id } }
}

export const receiveMemberAdded = (
  memberAddedMessage: ReceiveMemberAddedAction["payload"]
): ReceiveMemberAddedAction => {
  return { type: EVENT.MEMBER_ADDED_EVENT, payload: memberAddedMessage }
}

export const setPasswordForMembers = (
  payload: ReceiveChangeClassPasswordAction["payload"]
): ReceiveChangeClassPasswordAction => {
  return {
    type: EVENT.ADD_PASSWORDS_TO_MEMBERS_EVENT,
    payload: payload
  }
}

export const memberRemovedEventAction = (
  memberRemovedMessage: MemberRemovedEventAction["payload"]
): MemberRemovedEventAction => {
  return { type: EVENT.MEMBER_REMOVED_EVENT, payload: memberRemovedMessage }
}

export const receiveNewAnswer = (
  answer: ReceiveNewAnswerAction["payload"]
): ReceiveNewAnswerAction => {
  return { type: EVENT.NEW_ANSWER_EVENT, payload: answer }
}

export const changeMemberPassword = (
  id: Member["studliId"],
  password: string
): ChangeMemberPasswordAction => {
  return {
    type: REQUEST.UPDATE_MEMBER_REQUEST,
    payload: { id, password }
  }
}

export const resetMembers = (): ResetMembersAction => {
  return { type: UI.RESET_MEMBERS }
}
