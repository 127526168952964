import TextField from "@mui/material/TextField"
import IconDone from "@mui/icons-material/Done"
import IconError from "@mui/icons-material/Error"
import { SvgIconProps } from "@mui/material/SvgIcon"
import { styled } from "@mui/material"

export const StyledTextField = styled(TextField as any)`
  height: 76px;
`
export const StyledIconDone = styled(IconDone as React.FC<SvgIconProps>)`
  fill: ${props => props.theme.studli.status.ok};
`
export const StyledIconError = styled(IconError as React.FC<SvgIconProps>)`
  fill: ${props => props.theme.studli.status.error};
`
