import { filter } from "fp-ts/lib/Array"
import { fromPredicate, getOrElse } from "fp-ts/lib/Option"
import { pipe } from "fp-ts/lib/pipeable"
import { Lens } from "monocle-ts"
import { setField } from "../../shared/tools/monocle"
import { createReducer } from "../../shared/tools/redux"
import * as actions from "./actions"
import {
  CommunicationError,
  PendingRequest,
  ReceivePayload,
  State
} from "./types"

const initialState: State = {
  pendingRequests: [],
  lastReceivedEventId: undefined
}

const extendPendingRequest = (request: PendingRequest) =>
  Lens.fromProp<State>()("pendingRequests").modify(requests => [
    ...requests,
    request
  ])

const removePendingRequest = (requestId: string) =>
  Lens.fromProp<State>()("pendingRequests").modify(
    filter(req => req.requestId !== requestId)
  )

const setError = setField<State, CommunicationError | unknown>(
  "error",
  p => p as CommunicationError | undefined
)

const setLastReceivedEventId = (event: ReceivePayload) =>
  Lens.fromProp<State>()("lastReceivedEventId").modify(lastId =>
    pipe(
      event.eventId,
      fromPredicate(id => !!id),
      getOrElse(() => lastId)
    )
  )

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.saveRequest, extendPendingRequest)
    .case(actions.removeRequest, removePendingRequest)
    .case(actions.setCommunicationError, setError)
    .case(actions.resetCommunicationError, setError)
    .case(actions.receiveEvent, setLastReceivedEventId)
)

export default reducer
