import * as membersTypes from "../modules/members/types"
import * as membersPagesTypes from "../pages/Members/store/types"
import * as usersTypes from "../modules/users/types"
import * as stringTools from "./tools/strings"

/**
 * Concatenates first and last name separating them by one space character.
 * Also sets first letter of each name to upper case.
 *
 * @param {Member} person - Object with firstName and lastName.
 */
export const renderName = (
  person:
    | membersTypes.Member
    | usersTypes.User
    | membersPagesTypes.StudentInStudentList
) => {
  return stringTools.buildName(person.firstName, person.lastName)
}

/**
 * Concatenates first and last name separating them by one space character and adds username.
 * Also sets first letter of first and last name to upper case.
 *
 * @param {User} person - Object with firstName, lastName and username.
 */
export const renderNameAndUserName = (person: usersTypes.User) => {
  return stringTools.buildNameAndUserName(
    person.firstName,
    person.lastName,
    person.username
  )
}
