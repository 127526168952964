import { SnackbarData } from "../../containers/Snackbar/types"

export enum ApplicationState {
  idle = "idle", //unmounted
  mounting = "mounting", //mounting
  ready = "ready", //mounted
  error = "error",
  reconnecting = "reconnecting" //a state in which the socket-layer will close and open socket, until success.
}

export type State = {
  applicationState: ApplicationState
  errorMessageReceivedFromServer: string
  snackbar?: SnackbarData
  banners: Banners
  latestAction: string
}

export type Banner = {
  severity: "error" | "warning" | "info"
  actions: BannerAction[]
  message: JSX.Element
  title: string
}
export type BannerAction = { icon: JSX.Element; action: Function }
export type Banners = Banner[]

export type ApplicationMountAction = {
  type: string
}

export type ApplicationReadyAction = {
  type: string
}

export type ApplicationIdleAction = {
  type: string
}

export type ApplicationReconnectingAction = {
  type: string
}

export type SetSnackbarAction = {
  type: string
  payload?: SnackbarData
}

export type SetBannersAction = {
  type: string
  payload: Banners
}

export type ApplicationActionTypes =
  | ApplicationReadyAction
  | SetSnackbarAction
  | ApplicationReconnectingAction
  | SetBannersAction
  | ApplicationIdleAction
