import { List } from "immutable"
import { all, fork, ForkEffect } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

let sagas = List<ForkEffect>()

/**
 * One place to register and retrieve all sagas
 *
 * @export
 * @class SagasService
 */
export class SagasService {
  public static set(saga: () => IterableIterator<any>): void {
    sagas = sagas.push(fork(saga))
  }

  public static root(): () => SagaIterator {
    return function* () {
      return yield all(sagas.toArray())
    }
  }
}
