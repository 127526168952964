import React, { useState } from "react"

import {
  StyledAvatarContainer,
  StyledInitialsAvatar,
  StyledPictureAvatar,
  StyledOnlineIndicator
} from "./StyledAvatar"
import * as stringTools from "../../shared/tools/strings"
import Tooltip from "@mui/material/Tooltip"
import { buildName } from "../../shared/tools/strings"
import { useSelector } from "react-redux"
import { selectUsersOnline } from "../../modules/users/selectors"
import { selectTypeOfClassroom } from "../../modules/classroom/selectors"

type OwnProps = {
  firstName: string
  lastName: string
  studliId: number
  className?: string
  picture?: string
  color?: string
}

const Avatar = ({
  firstName,
  lastName,
  picture,
  studliId,
  color,
  className
}: OwnProps) => {
  const [failedToLoadSpecifiedPicture, setfailedToLoadSpecifiedPicture] =
    useState(false)

  const classroomType = useSelector(selectTypeOfClassroom)
  const studentsOnline = useSelector(selectUsersOnline)
  const handleGetImgError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    window.console.error(
      `Failed to get avatar picture, specified url: ${picture}. Falling back to showing initials.`
    )
    setfailedToLoadSpecifiedPicture(true)
  }

  /**
   * Renders an avatar showing initials or picture.
   */
  const renderInitialsOrPictureAvatar = () => {
    return (
      <Tooltip title={`${buildName(firstName, lastName)}`}>
        <StyledAvatarContainer className={className}>
          {picture && !failedToLoadSpecifiedPicture ? (
            <StyledPictureAvatar
              alt={stringTools.buildName(firstName, lastName)}
              src={picture}
              onError={handleGetImgError}
              color={color}
            />
          ) : (
            <StyledInitialsAvatar color={color}>
              {renderInitials(firstName, lastName)}
            </StyledInitialsAvatar>
          )}
          {classroomType === "adapt" &&
            studentsOnline &&
            studentsOnline.some(s => s === studliId) && (
              <StyledOnlineIndicator />
            )}
        </StyledAvatarContainer>
      </Tooltip>
    )
  }

  return renderInitialsOrPictureAvatar()
}

/**
 * Renders initials, two capital letters with no space between.
 *
 * @param firstName {string} - First name.
 * @param lastName {string} - Last name.
 *
 * @returns {string} Initials.
 */
export const renderInitials = (firstName: string, lastName: string): string => {
  return stringTools.buildInitials(firstName, lastName)
}

export default Avatar
