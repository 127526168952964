import LastActive from "../../LastActive"
import { VISIBLE_ADAPT_MEMBER_PROPS } from "../../../pages/Members/constants"

export const checkStatus = (status: string) => {
  return status === undefined || status === "0"
    ? "Påbörjat"
    : LastActive.renderLastActive(status)
}

export const renderStatus = (data: string) => {
  const status = checkStatus(data)
  return status !== "Påbörjat" ? `Påbörjat -  ${status}` : status
}

export const abilityIndicatorPopover = () => {
  return VISIBLE_ADAPT_MEMBER_PROPS.find(
    popInfo => popInfo.id === "goalAbilityGrade"
  )?.info?.content
}
