import React, { useState, useEffect } from "react"
import {
  StyledPaper,
  StyledPaperContent,
  StyledAudioRecorderContainer,
  StyledButtonContainer,
  StyledButton,
  StyledErrorMessage
} from "./StyledFormativeQuestionsForm"
import FormikTextEditor from "../FormikTextEditor"
import {
  Form,
  FormikProps,
  FormikValues,
  FormikTouched,
  FormikErrors,
  ErrorMessage
} from "formik"
import AudioRecorder from "../AudioRecorder"
import { FormValues } from "../../pages/CreateFormativeQuestion/types"
import {
  Typography,
  Divider,
  FormControlLabel,
  Stack,
  FormControl,
  RadioGroup,
  Radio
} from "@mui/material"
import ChooseUsersDialogContent from "../ChooseUsersDialogContent"
import Dialog from "../../components/Dialog"
import { useSelector } from "react-redux"
import { selectStudentsWithProduct } from "../../modules/members/selectors"
import { Users } from "../../modules/users/types"
import { USER_ROLE } from "../../modules/users/constants"
import { Members } from "../../modules/members/types"
import FormativeQuestionsList from "../FormativeQuestionsList"
import {
  FormativeQuestions,
  FormativeTest
} from "../../modules/formative/types"
import FormativeQuestionsDialog from "../FormativeQuestionsDialog"
import AssignmentsStudentsList from "../AssignmentStudentsList"
import FormativeEndQuestionDialog from "../FormativeEndQuestionDialog"
import FormativeEndQuestion from "../FormativeEndQuestion"
import {
  selectActiveProductName,
  selectActiveProductTitle
} from "../../modules/products/selectors"
import LimitedTextField from "./LimitedTextField"
import { StyledPaperHeader } from "../CreateAssignmentForm/StyledCreateAssignmentForm"
import InfoPopover from "../InfoPopover"
type OwnProps = {
  saveAsDraft: (values: FormValues) => void
  setIsDirty: (dirty: boolean) => void
  goBack: () => void
  isCreator: boolean
  test: FormativeTest | undefined
}

type Props = OwnProps & FormikProps<FormikValues>

const FormativeQuestionsForm = ({
  values,
  setFieldValue,
  errors,
  handleChange,
  touched,
  saveAsDraft,
  setIsDirty,
  dirty,
  goBack,
  isCreator,
  test
}: Props) => {
  const onTextEditorBlur = (name: string, value: any) => {
    setFieldValue(name, { ...values[name], text: value })
  }
  const [selectStudentsDialogOpen, setSelectStudentsDialogOpen] =
    useState(false)
  const [selectQuestionsDialogOpen, setSelectQuestionsDialogOpen] =
    useState(false)
  const [addEndQuestionDialogOpen, setAddEndQuestionDialogOpen] =
    useState(false)
  useEffect(() => {
    if (dirty) {
      setIsDirty(true)
    }
  }, [dirty, setIsDirty])

  const productTitle = useSelector(selectActiveProductTitle)
  const productName = useSelector(selectActiveProductName)
  const students = useSelector(selectStudentsWithProduct)
  const setAudioForForm = async (
    filePath: string | undefined,
    name: string
  ) => {
    if (filePath) {
      setFieldValue(name, { ...values[name], audio: filePath })

      return
    }
    setFieldValue(name, { ...values[name], audio: "" })
  }

  const renderIsPublic = () => {
    const handleToggleIsPublic = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFieldValue("isPublic", event.target.value === "true")
    }
    if (!isCreator) {
      return null
    }
    return (
      <StyledPaper>
        <StyledPaperContent>
          <Stack direction="row" alignItems="center">
            <StyledPaperHeader variant="h2" display="block">
              Inställning för åtkomst
            </StyledPaperHeader>
            <InfoPopover
              type="Info"
              content={
                <Stack>
                  <Typography fontWeight="bold">Ett delat test:</Typography>
                  <ul>
                    <li>{`visas för alla de lärare på skolan som har tillgång till läromedel som ingår i ${productTitle}`}</li>
                    <li>kan startas, avslutas etc av andra lärare på skolan</li>
                    <li>
                      kan bara den lärare som skapat testet redigera innehållet
                      i
                    </li>
                  </ul>
                  <Typography fontWeight="bold">Ett privat test:</Typography>
                  <ul>
                    <li>
                      visas bara för den <i>lärare som skapat testet</i>
                    </li>
                    <li>
                      kan bara den <i>lärare som skapat testet</i> starta,
                      avsluta etc samt redigera innehållet i
                    </li>
                  </ul>
                </Stack>
              }
            />
          </Stack>
        </StyledPaperContent>
        <Divider />
        <StyledPaperContent>
          <Stack mt={2}>
            <FormControl>
              <RadioGroup
                value={values.isPublic}
                onChange={handleToggleIsPublic}
                aria-labelledby="choose-private-assignment"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Delat - tillgängligt även för andra lärare på skolan"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Privat - endast synligt/möjligt att hantera för mig själv"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const renderAudioRecorder = (type: string) => {
    const current = values[type]
    const currentAudio = current.audio ? current.audio : ""

    return isCreator ? (
      <AudioRecorder
        type="standalone"
        setAudioForForm={setAudioForForm}
        formType={type}
        editAudio={currentAudio}
        conversation={false}
      />
    ) : null
  }

  const buildInputHelperText = (
    formErrors: FormikErrors<FormValues>,
    formTouched: FormikTouched<FormValues>,
    inputField: keyof FormValues,
    helpText?: string
  ) => {
    if (formTouched[inputField] && formErrors[inputField]) {
      return formErrors[inputField]
    } else {
      return helpText
    }
  }

  const showInputError = (
    formErrors: FormikErrors<FormValues>,
    formTouched: FormikTouched<FormValues>,
    inputField: keyof FormValues
  ) => formErrors[inputField] && formTouched[inputField]

  const endQuestionNotEmpty = () => {
    return (
      values.endQuestion &&
      (values.endQuestion.text || values.endQuestion.audio)
    )
  }

  const renderTitleAndInstructions = () => {
    return (
      <StyledPaper>
        <StyledPaperContent>
          <LimitedTextField
            name="title"
            disabled={!isCreator}
            label="Titel"
            value={values.title}
            onChange={handleChange}
            maxLength={30}
            helperText={buildInputHelperText(errors, touched, "title")}
            error={Boolean(showInputError(errors, touched, "title"))}
          />
          {isCreator && (
            <FormikTextEditor
              name="instruction"
              ariaLabel="Instruktion"
              value={values.instruction.text}
              onBlurHandler={onTextEditorBlur}
              maxLength={500}
              placeholder={"Här kan du skriva en instruktion till eleverna"}
            />
          )}
          <StyledAudioRecorderContainer>
            {renderAudioRecorder("instruction")}
          </StyledAudioRecorderContainer>
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const renderSelectContent = () => {
    return (
      <StyledPaper>
        <StyledPaperContent>
          <Typography variant="h6" display="block">
            Innehåll
          </Typography>
        </StyledPaperContent>
        <Divider />
        {values.questions && (
          <div>
            <FormativeQuestionsList
              setFieldValue={setFieldValue}
              editMode
              onQuestionCheck={() => {}}
              checkedQuestions={[]}
              isEditDisabled={!isCreator}
              questions={values.questions}
              selectable={isCreator}
              collapse={false}
              isOpen={false}
            />
          </div>
        )}
        {endQuestionNotEmpty() && (
          <>
            <FormativeEndQuestion endQuestion={values.endQuestion} />
            <Divider />
          </>
        )}
        {errors["questions"] && (
          <StyledErrorMessage>
            <ErrorMessage name="questions">
              {msg => <div>{msg}</div>}
            </ErrorMessage>
          </StyledErrorMessage>
        )}
        <StyledPaperContent>
          <StyledButton
            onClick={() => setSelectQuestionsDialogOpen(true)}
            variant="outlined"
            color="primary"
            disabled={!isCreator}
          >
            Välj innehåll
          </StyledButton>
          <StyledButton
            onClick={() => setAddEndQuestionDialogOpen(true)}
            variant="outlined"
            color="primary"
            disabled={!isCreator}
          >
            {values.endQuestion.text || values.endQuestion.audio
              ? "Ändra slutfråga"
              : "Lägg till slutfråga"}
          </StyledButton>
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const addSelectedStudents = (studentsToAdd: Users) => {
    const convertedStudents = studentsToAdd as Members

    setFieldValue(
      "members",
      convertedStudents
        .sort((first, second) => first.lastName.localeCompare(second.lastName))
        .map(student => student.studliId)
    )
    setSelectStudentsDialogOpen(false)
  }

  const renderSelectMembersDialog = () => {
    const participants = test?.participantsInfo as unknown as Users
    let testMembers
    if (participants) {
      testMembers = [...students, ...participants].filter(
        (user, i, arr) =>
          user &&
          user.studliId &&
          i === arr.findIndex(u => u.studliId === user.studliId)
      )
    } else {
      testMembers = students
    }
    const alreadyCheckedMembers = testMembers.filter(member =>
      values.members.some(
        (participant: number) => participant === member.studliId
      )
    )
    return (
      <Dialog
        open={selectStudentsDialogOpen}
        onClose={() => setSelectStudentsDialogOpen(false)}
        aria-labelledby="choose-users-from-search-results"
      >
        <ChooseUsersDialogContent
          handleAdd={addSelectedStudents}
          handleCancel={() => setSelectStudentsDialogOpen(false)}
          userRole={USER_ROLE.STUDENT}
          foundUsers={testMembers as Users}
          type="addToTest"
          alreadyCheckedUsers={alreadyCheckedMembers as Users}
        />
      </Dialog>
    )
  }

  const onSubmitQuestions = (questions: FormativeQuestions) => {
    const newQuestions = [...questions]
    setFieldValue("questions", newQuestions)
    setSelectQuestionsDialogOpen(false)
  }

  const renderSelectQuestionsDialog = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="paper"
        open={selectQuestionsDialogOpen}
        onClose={() => setSelectQuestionsDialogOpen(false)}
        aria-labelledby="choose-users-from-search-results"
      >
        <FormativeQuestionsDialog
          onCancel={() => setSelectQuestionsDialogOpen(false)}
          selectedQuestions={values.questions}
          onSubmitQuestions={onSubmitQuestions}
        />
      </Dialog>
    )
  }

  const onAddEndQuestion = (endQuestion: {
    question: { text: string; audio: string }
  }) => {
    setFieldValue("endQuestion", endQuestion.question)
    setAddEndQuestionDialogOpen(false)
  }

  const renderAddEndQuestionDialog = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="paper"
        open={addEndQuestionDialogOpen}
        onClose={() => setAddEndQuestionDialogOpen(false)}
      >
        <FormativeEndQuestionDialog
          onClose={() => setAddEndQuestionDialogOpen(false)}
          onSubmit={onAddEndQuestion}
          endQuestion={values.endQuestion}
        />
      </Dialog>
    )
  }

  const removeStudentFromTest = (id: number) => {
    setFieldValue(
      "members",
      values.members.filter((studentId: number) => studentId !== id)
    )
  }

  const renderSelectStudents = () => {
    const participants = test?.participantsInfo as unknown as Users
    let testMembers
    if (participants) {
      testMembers = [...students, ...participants].filter(
        (user, i, arr) =>
          user &&
          user.studliId &&
          i === arr.findIndex(u => u.studliId === user.studliId)
      )
    } else {
      testMembers = students
    }
    return (
      <StyledPaper>
        <StyledPaperContent>
          <Typography variant="h6" display="block">
            Elever
          </Typography>
        </StyledPaperContent>
        <Divider />
        {values.members && (
          <div>
            <AssignmentsStudentsList
              members={testMembers}
              memberIds={values.members}
              removeStudentFromAssignment={removeStudentFromTest}
              editMode={isCreator}
              hasOnClick={false}
              assignmentId={0}
              progress={{
                assignmentsProgress: [],
                assignmentsTaskProgress: []
              }}
              onAssignmentStudentClick={() => null}
            />
          </div>
        )}
        {errors["members"] && (
          <StyledErrorMessage>
            <ErrorMessage name="members">
              {msg => <div>{msg}</div>}
            </ErrorMessage>
          </StyledErrorMessage>
        )}
        <StyledPaperContent>
          {(participants && participants.length > 0) || students.length > 0 ? (
            <StyledButton
              onClick={() => setSelectStudentsDialogOpen(true)}
              variant="outlined"
              color="primary"
            >
              Välj elever
            </StyledButton>
          ) : (
            <Typography variant="subtitle1">{`Det finns inga elever i klassrummet med ${productName} aktiverad. Det går bra att spara testet som utkast och välja elever senare.`}</Typography>
          )}
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const renderButtons = () => {
    return (
      <StyledButtonContainer>
        <StyledButton onClick={goBack} variant="outlined" color="primary">
          Avbryt
        </StyledButton>
        <StyledButton
          onClick={() => saveAsDraft(values as FormValues)}
          variant="outlined"
          color="primary"
          disabled={values.status === "published"}
        >
          Spara som utkast
        </StyledButton>
        <StyledButton type="submit" variant="contained" color="primary">
          Spara
        </StyledButton>
      </StyledButtonContainer>
    )
  }
  return (
    <Form>
      {renderTitleAndInstructions()}
      {renderSelectContent()}
      {renderSelectStudents()}
      {renderIsPublic()}
      {renderButtons()}
      {renderSelectMembersDialog()}
      {renderAddEndQuestionDialog()}
      {renderSelectQuestionsDialog()}
    </Form>
  )
}

export default FormativeQuestionsForm
