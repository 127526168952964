import * as types from "./types"
import { createAction } from "../../shared/tools/redux"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_NOTIFICATIONS_REQUEST = "GET_ALL_NOTIFICATIONS_REQUEST",
  MARK_NOTIFICATION_AS_READ_REQUEST = "MARK_NOTIFICATION_AS_READ_REQUEST"
}

export enum RESPONSE {
  GET_ALL_NOTIFICATIONS_RESPONSE = "GET_ALL_NOTIFICATIONS_REQUEST_RESPONSE",
  MARK_NOTIFICATION_AS_READ_RESPONSE = "MARK_NOTIFICATION_AS_READ_RESPONSE"
}

export enum SERVER_MESSAGE_ACTION {
  GET_ALL_NOTIFICATIONS_REQUEST = "list",
  GET_ALL_NOTIFICATIONS_RESPONSE = "list",
  MARK_NOTIFICATION_AS_READ_REQUEST = "read",
  MARK_NOTIFICATION_AS_READ_RESPONSE = "read",
  NOTIFICATIONS_UPDATE_EVENT = "incoming_notification"
}

export enum EVENT {
  NOTIFICATIONS_UPDATE_EVENT = "NOTIFICATIONS_UPDATE_EVENT"
}
export enum UI {
  ADD_NOTIFICATION = "ADD_NOTIFICATION"
}

export const getAllNotifications = createAction("GET_ALL_NOTIFICATIONS_REQUEST")

export const markNotificationAsRead = createAction<{
  memberId: number
  notificationIds: number[]
}>("MARK_NOTIFICATION_AS_READ_REQUEST")

export const addNotifications = createAction<types.Notifications>(
  "GET_ALL_NOTIFICATIONS_RESPONSE"
)
export const markNotificationsAsRead = createAction<{
  memberId: number
  notificationIds: number[]
}>("MARK_NOTIFICATION_AS_READ_RESPONSE")
export const updateNotification = createAction<types.Notification>(
  "incoming_notification"
)
export const addNotification =
  createAction<types.Notification>("ADD_NOTIFICATION")
