import jsPDF from "jspdf"
import { CreateUserData } from "../../modules/users/types"
import { buildInitials, buildName } from "../tools/strings"
import studliLogo from "../../components/Logo/studliLogoBlob"
import { StudentInStudentList } from "../../pages/Members/store/types"

class PDFGenerator {
  static studliLogoAspect = 720 / 100

  static margin = 20 // Margin on page, good to keep to ensure all printer can print.
  static spacing = 10 // No space between cards, uglier but less to cut.

  static padding = 0 // Around each card
  static height = 79 // 3 rows
  static width = 80 // 2 cols

  static maxNameLength = 30 // Characters
  static maxUsernameAndPasswordLength = 23 // Characters

  static getMobileOperatingSystem() {
    // https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
    // var userAgent = navigator.userAgent || navigator.vendor || window.opera  //TODO:
    const userAgent = navigator.userAgent || navigator.vendor

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone"
    }

    if (/android/i.test(userAgent)) {
      return "Android"
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      //TODO:
      return "iOS"
    }

    return "unknown"
  }

  static isIOS = () => PDFGenerator.getMobileOperatingSystem() === "iOS"
  static isAndroid = () => PDFGenerator.getMobileOperatingSystem() === "Android"

  static drawCircleWithText(
    doc: jsPDF,
    top: number,
    left: number,
    radius: number,
    text: string
  ) {
    doc.setTextColor(0)
    doc.setFontSize(radius * 2.5)
    doc.setDrawColor(0)
    doc.setLineWidth(radius / 8)
    // doc.circle(left, top, radius)   //TODO:
    doc.circle(left, top, radius, "S")
    doc.text(`${text}`, left, top + radius / 3.5, "center")
  }

  static drawInputField(
    doc: jsPDF,
    top: number,
    left: number,
    width: number,
    height: number,
    id: string,
    label: string,
    value: string
  ) {
    PDFGenerator.drawCircleWithText(doc, top + 15, left + 10, 4, id)
    doc.setFontSize(8)
    doc.setTextColor(128)
    doc.text(`${label}`, left + 17, top + 7)
    doc.setLineWidth(0.2)
    doc.setDrawColor(128)
    doc.setFillColor(240)
    doc.roundedRect(left + 17, top + 10, width - 27, 10, 1, 1, "FD")
    doc.setFontSize(12)
    doc.setTextColor(0)
    doc.text(`${value}`, left + 20, top + 17)
  }

  static drawLabelValuePair(
    doc: jsPDF,
    top: number,
    left: number,
    label: string,
    value: string
  ) {
    doc.setFontSize(8)
    doc.setTextColor(128)
    doc.text(`${label}`, left, top + 7)

    // decrease font-size for long values so the fit into card.
    if (value.length < PDFGenerator.maxUsernameAndPasswordLength) {
      doc.setFontSize(16)
    } else if (value.length < PDFGenerator.maxUsernameAndPasswordLength + 7) {
      doc.setFontSize(12)
    } else {
      doc.setFontSize(9)
    }

    doc.setTextColor(0)
    doc.text(`${value}`, left + 3, top + 17)
  }

  static drawCard(
    doc: jsPDF,
    top: number,
    left: number,
    width: number,
    height: number,
    student: CreateUserData | StudentInStudentList
  ) {
    let password = ""
    if (isCreateUserData(student)) {
      password = student.password
    }
    const { firstName, lastName, username } = student
    const logoWidth = width - 40
    const logoHeight = logoWidth / PDFGenerator.studliLogoAspect
    PDFGenerator.drawCircleWithText(
      doc,
      top + 10,
      left + 10,
      5,
      buildInitials(firstName, lastName)
    )
    doc.setTextColor(0)
    doc.setFontSize(12)
    doc.text(
      `${buildName(firstName, lastName, PDFGenerator.maxNameLength)}`,
      left + 17,
      top + 11.5
    )

    doc.setLineWidth(0.2)
    doc.setDrawColor(0)
    doc.line(left + 5, top + 18, left + width - 5, top + 18)

    PDFGenerator.drawLabelValuePair(
      doc,
      top + 20,
      left + 5,
      "ANVÄNDARNAMN",
      username
    )
    PDFGenerator.drawLabelValuePair(
      doc,
      top + 40,
      left + 5,
      "LÖSENORD",
      password || ""
    )
    doc.addImage(
      studliLogo,
      "SVG",
      left + 5,
      top + height - logoHeight - 5,
      logoWidth,
      logoHeight
    )
  }

  static generateJSPDF = (
    students: CreateUserData[] | StudentInStudentList[]
  ) => {
    const doc = new jsPDF()

    const margin = PDFGenerator.margin
    const spacing = PDFGenerator.spacing
    const padding = PDFGenerator.padding
    const height = PDFGenerator.height
    const width = PDFGenerator.width

    const rowsPerPage = Math.floor(
      (297 - margin * 2 + spacing) / (height + spacing)
    )
    const columnsPerPage = Math.floor(
      (210 - margin * 2 + spacing) / (width + spacing)
    )

    students.forEach(
      (student: CreateUserData | StudentInStudentList, i: number) => {
        const pos = i % (columnsPerPage * rowsPerPage)
        if (pos === 0 && i !== 0) {
          doc.addPage()
        }
        const top = Math.floor(pos / columnsPerPage) * (height + padding) + 20
        const left = (pos % columnsPerPage) * (width + padding) + 20
        doc.setDrawColor(204)
        doc.setLineWidth(1)
        doc.rect(left, top, width, height, "S")

        PDFGenerator.drawCard(doc, top, left, width, height, student)
      }
    )

    return doc
  }
}

function isCreateUserData(student: any): student is CreateUserData {
  return student.password !== undefined
}

export default PDFGenerator
