import * as L from "monocle-ts/lib/Lens"
import { Lens } from "monocle-ts"
import { flow, pipe } from "fp-ts/lib/function"
import { map } from "fp-ts/lib/Array"
import * as T from "fp-ts/lib/Either"

import { createReducer } from "../../shared/tools/redux"
import { push, filter, unshift } from "../../shared/tools/pipeables"
import {
  State,
  Assignments,
  Assignment,
  PreparedAssignment,
  AssignmentMessage,
  PartialAssignment,
  ImportableAssignmentClassroom
} from "./types"
import * as actions from "./actions"
import { setField } from "../../shared/tools/monocle"

const initialState: State = {
  assignments: [],
  importableAssignments: [],
  preparedAssignments: [],
  feedbackMessages: [],
  previewUrl: undefined,
  createEditValidationError: null,
  loadingImportAssignment: 0
}

const setAssignments = setField<State, Assignments>(
  "assignments",
  flow(
    map(a =>
      Lens.fromProp<Assignment>()("assignmentTasks").modify(t => t || [])(a)
    )
  )
)
const setPreparedAssignments = setField<State, PreparedAssignment[]>(
  "preparedAssignments",
  p => p
)
const setImportableAssignments = setField<
  State,
  ImportableAssignmentClassroom[]
>("importableAssignments", p => p)

const emptyAssignments = () => (state: State) => ({
  ...state,
  assignments: [],
  preparedAssignments: []
})

const setPendingImport = setField<State, PartialAssignment>(
  "pendingImport",
  p => p
)
const emptyPendingImport = setField<State>("pendingImport", () => undefined)
const setFeedbackMessages = setField<State, AssignmentMessage[]>(
  "feedbackMessages",
  p => p
)
const emptyFeedbackMessages = setField<State>(
  "feedbackMessages",
  () => initialState.feedbackMessages
)

const addAssignment = ({ assignment }: { assignment: Assignment }) =>
  pipe(
    L.id<State>(),
    L.prop("assignments"),
    L.modify(
      flow(
        filter(a => a.id !== assignment.id),
        push(
          Lens.fromProp<Assignment>()("assignmentTasks").modify(t => t || [])(
            assignment
          )
        )
      )
    )
  )

const updateAssignment = ({ assignment }: { assignment: Assignment }) =>
  pipe(
    L.id<State>(),
    L.prop("assignments"),
    L.modify(
      flow(
        a =>
          !!a.find(assign => assign.id === assignment.id)
            ? T.right(a)
            : T.left(a),
        T.fold(push(assignment), assignments =>
          assignments.map(a => {
            if (a.id === assignment.id) {
              return {
                ...a,
                ...assignment
              }
            }

            return a
          })
        )
      )
    )
  )

const removeImportableAssignments = ({
  assignments
}: {
  assignments: number[]
}) =>
  pipe(
    L.id<State>(),
    L.prop("importableAssignments"),
    L.modify(filter(a => !assignments.includes(a.id)))
  )

const filterAssignment = ({ id }: { id: number }) =>
  pipe(L.id<State>(), L.prop("assignments"), L.modify(filter(a => a.id !== id)))

const addFeedbackMessage = ({ message }: { message: AssignmentMessage }) =>
  pipe(L.id<State>(), L.prop("feedbackMessages"), L.modify(unshift(message)))

const setPreviewUrl = setField<State, string | undefined>(
  "previewUrl",
  url => url
)

const setCreateEditValidationError = setField<State, { [key: number]: string }>(
  "createEditValidationError",
  error => error
)

const setLoadingImportAssignment = setField<State, number>(
  "loadingImportAssignment",
  loading => loading
)

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.setAssignments, setAssignments)
    .case(actions.addAssignment, addAssignment)
    .case(actions.deleteAssignment, filterAssignment)
    .case(actions.setPreparedAssignments, setPreparedAssignments)
    .case(actions.setImportableAssignments, setImportableAssignments)
    .case(actions.addAssignmentMessage, addFeedbackMessage)
    .case(actions.resetAssignments, emptyAssignments)
    .case(actions.setImportedAssignment, setPendingImport)
    .case(actions.resetImportedAssignment, emptyPendingImport)
    .case(actions.setAssignmentMessages, setFeedbackMessages)
    .case(actions.resetAssignmentMessages, emptyFeedbackMessages)
    .case(actions.assignmentUpdatedEvent, updateAssignment)
    .case(actions.assignmentRemovedEvent, filterAssignment)
    .case(actions.assignmentMessageCreatedEvent, addFeedbackMessage)
    .case(actions.setPreviewUrl, setPreviewUrl)
    .case(actions.removeImportableAssignments, removeImportableAssignments)
    .case(
      actions.createEditAssignmentValidationError,
      setCreateEditValidationError
    )
    .case(actions.setLoadingImportAssignment, setLoadingImportAssignment)
)

export default reducer
