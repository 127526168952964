import { useSelector } from "react-redux"
import { Member } from "../../modules/members/types"
import { selectStudentCardMember } from "../../modules/members/selectors"
import {
  ActiveProduct,
  Code,
  ActiveProductWithNiceName
} from "../../modules/users/types"
import { selectActiveProductsForMemberInRoute } from "../../modules/users/selectors"
import { selectProducts } from "../../modules/products/selectors"
import { selectLastActiveForCurrentMember } from "../../pages/Members/store/selectors"

export type ActiveProductWithNiceNames = ActiveProduct

export type Info = {
  student: Member | undefined
  lastActive: string | undefined
  activeProduct: ActiveProductWithNiceName | undefined
}

function useStudentExerciseList(): Info {
  const student = useSelector(selectStudentCardMember)
  const lastActive = useSelector(selectLastActiveForCurrentMember)
  const activeProduct = useSelector(selectActiveProductsForMemberInRoute)
  const products = useSelector(selectProducts)

  const getNameFromGroup = (group: Code) => {
    type TempNameObj = { niceName: string; title: string }

    const names = group.sesamGroups
      .map(group => {
        const product = products.find(product => product.sesamName === group)
        return product
          ? { niceName: product.niceName, title: product.title }
          : null
      })
      .filter(Boolean) as TempNameObj[]

    const niceNameOrTitle = names.map(name =>
      names.filter(findDupe => findDupe.niceName === name.niceName).length > 1
        ? name.title
        : name.niceName || name.title
    )
    return {
      ...group,
      name: niceNameOrTitle.join(", ")
    }
  }

  return {
    student,
    lastActive,
    activeProduct: activeProduct
      ? {
          ...activeProduct,
          codes: activeProduct.codes
            .map(getNameFromGroup)
            .filter(product => !!product.name)
        }
      : undefined
  }
}

export default useStudentExerciseList
