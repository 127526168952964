import { takeLatest, put, all, race } from "redux-saga/effects"

import * as actions from "./actions"
import * as communicationActions from "../communication/actions"
import * as membersActions from "../members/actions"
import * as memberTypes from "../members/types"

import { MODULE_NAME } from "./constants"
import * as communicationTypes from "../communication/types"
import { sendRequest } from "../communication/actions"

import * as types from "./types"

function* getAllGoalsProgressSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_ALL_GOALS_PROGRESS_REQUEST
    )
  )
}

function* watchGetAllGoalsProgressSaga() {
  yield takeLatest(
    actions.REQUEST.GET_ALL_GOALS_PROGRESS_REQUEST,
    getAllGoalsProgressSaga
  )
}

function* handleReceivedGoalsProgressResponseSaga(
  message: types.ReceiveGoalsProgressResponseMessagesTypes
) {
  const { type, action, payload, error } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.GET_ALL_GOALS_PROGRESS_RESPONSE:
        const getAllGoalsProgressResponsePayload =
          payload as types.ReceiveGetAllGoalsProgressResponseAction["payload"]
        yield receiveGetAllGoalsProgressResponse(
          getAllGoalsProgressResponsePayload,
          error
        )
        break

      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in response`
        )
    }
  }
}

export function* handleReceiveGoalsProgressEventSaga(
  message: types.ReceiveGoalsProgressEventMessagesTypes
) {
  const { type, action, payload } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.GOAL_PROGRESS_UPDATED_EVENT:
        yield put(
          actions.receiveGoalsProgressUpdated(
            payload as types.ReceiveGoalsProgressUpdatedAction["payload"]
          )
        )
        break

      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in event`
        )
    }
  }
}

export function* receiveGetAllGoalsProgressResponse(
  payload: types.ReceiveGetAllGoalsProgressResponseAction["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    // Action not initiated by user, so no snackbar needed. Rely on lower layer to handle this error...
  } else {
    yield put(actions.receiveGetAllGoalsProgressResponse(payload))
  }
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceivedGoalsProgressResponseSaga
    ),
    yield takeLatest(
      communicationActions.RECEIVE_EVENT,
      handleReceiveGoalsProgressEventSaga
    )
  ])
}

export function* handleRemovedMemberSaga(
  message: memberTypes.MemberRemovedEventAction
) {
  yield put(
    actions.removeGoalsProgressForMember({ studliIds: message.payload })
  )
}

function* watchRemovedMemberSaga() {
  yield takeLatest(
    membersActions.EVENT.MEMBER_REMOVED_EVENT,
    handleRemovedMemberSaga
  )
}

export function* rootSaga() {
  yield all([
    watchGetAllGoalsProgressSaga(),
    watchReceiverSaga(),
    watchRemovedMemberSaga()
  ])
}
