import { Theme } from "@mui/material/styles"
export const getColor = (props: any) => {
  if (props.hasproduct) {
    return (props.theme as Theme).palette.text.primary
  } else {
    return props.theme.studli.palette.disabledColor
  }
}

export const getBackgroundColor = (props: any) => {
  if (props.active) {
    return props.theme.studli.table.selected
  }
  if (props.recentlyadded) {
    return "#ebf9e5"
  }
  if (props.hasproduct) {
    return (props.theme as Theme).palette.background.default
  } else {
    return props.theme.studli.palette.disabledBackgroundColor
  }
}
