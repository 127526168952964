import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Close from "@mui/icons-material/Close"

import {
  StyledClassroomCode,
  StyledClassroomCodeText,
  StyledClassroomInfo,
  StyledClassroomTextContainer,
  StyledIconButton
} from "./StyledClassroomCode"
import { selectClassroomCode } from "../../modules/classroom/selectors"
import { gotoRoute } from "../../modules/router/actions"
import {
  selectCurrentClassroomId,
  selectPreviousRoute
} from "../../modules/router/selectors"

const ClassroomCode: React.FC = () => {
  const dispatch = useDispatch()
  const classroomId = useSelector(selectCurrentClassroomId)
  const classroomCode = useSelector(selectClassroomCode)
  const previousRoute = useSelector(selectPreviousRoute)

  return (
    <StyledClassroomCode>
      <StyledIconButton
        color="inherit"
        onClick={() => dispatch(gotoRoute(previousRoute, { classroomId }))}
        aria-label="Stäng"
      >
        <Close />
      </StyledIconButton>
      <StyledClassroomTextContainer>
        <StyledClassroomInfo>
          Logga in i din bokhylla och tryck på ditt namn.
          <br /> Välj &quot;Gå med i klassrum&quot;. <br /> Skriv sedan följande
          kod:
        </StyledClassroomInfo>
        <StyledClassroomCodeText>{classroomCode}</StyledClassroomCodeText>
      </StyledClassroomTextContainer>
    </StyledClassroomCode>
  )
}

export default ClassroomCode
