/**
 * These select* functions are  for redux select(), they expose parts of the state.
 */

import { RootState } from "../../../modules/store"
import * as appShellTypes from "./types"

export const selectIsSideBarOpen = (store: RootState): boolean =>
  store.pages.appShell.isSideBarOpen

export const selectViewportWidth = (
  store: RootState
): appShellTypes.ViewportWidth | undefined => store.pages.appShell.viewportWidth
