import { takeLatest, put, all, select, race } from "redux-saga/effects"
import {
  sendRequest,
  RECEIVE_RESPONSE,
  RECEIVE_EVENT
} from "../communication/actions"
import * as formativeActions from "./actions"
import { MODULE_NAME } from "./constants"
import * as types from "./types"
import * as routerActions from "../router/actions"
import { selectClassroomId } from "../classroom/selectors"
import * as applicationActions from "../application/actions"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import { getLatestSession } from "../../pages/FormativeQuestions/helpers"
import moment from "moment"
import { selectAuthenticatedUser } from "../users/selectors"

function* watchGetAllQuestionsSaga() {
  yield takeLatest(
    formativeActions.REQUEST.GET_ALL_QUESTIONS_REQUEST,
    getAllQuestionsSaga
  )
}

function* getAllQuestionsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.GET_ALL_QUESTIONS_REQUEST
    )
  )
}

function* watchGetTestSaga() {
  yield takeLatest(
    formativeActions.REQUEST.GET_FORMATIVE_TEST_REQUEST,
    getTestSaga
  )
}

function* getTestSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.GET_FORMATIVE_TEST_REQUEST,
      message.payload
    )
  )
}

function* watchGetAllPreparedTestsSaga() {
  yield takeLatest(
    formativeActions.REQUEST.GET_PREPARED_FORMATIVE_TESTS_REQUEST,
    getAllPreparedTestsSaga
  )
}

function* getAllPreparedTestsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION
        .GET_PREPARED_FORMATIVE_TESTS_REQUEST
    )
  )
}

function* watchGetProgressionSaga() {
  yield takeLatest(
    formativeActions.REQUEST.GET_FORMATIVE_PROGRESSION_REQUEST,
    getProgressionSaga
  )
}

function* getProgressionSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.GET_FORMATIVE_PROGRESSION_REQUEST
    )
  )
}

function* watchGetAllTestsSaga() {
  yield takeLatest(
    formativeActions.REQUEST.GET_ALL_TESTS_REQUEST,
    getAllTestsSaga
  )
}

function* getAllTestsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.GET_ALL_TESTS_REQUEST
    )
  )
}

function* watchGetAllImportableTestsSaga() {
  yield takeLatest(
    formativeActions.REQUEST.GET_ALL_IMPORTABLE_TESTS_REQUEST,
    getAllImportableTestsSaga
  )
}

function* getAllImportableTestsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.GET_ALL_IMPORTABLE_TESTS_REQUEST
    )
  )
}

function* watchCreateTestSaga() {
  yield takeLatest(
    formativeActions.createFormativeTest,
    createFormativeTestSaga
  )
}

function* createFormativeTestSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.CREATE_FORMATIVE_TEST_REQUEST,
      message.payload
    )
  )
}

function* watchEditTestSaga() {
  yield takeLatest(formativeActions.editFormativeTest, editFormativeTestSaga)
}

function* editFormativeTestSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.EDIT_FORMATIVE_TEST_REQUEST,
      message.payload.test,
      message.payload.echo
    )
  )
}

function* watchStartTestSaga() {
  yield takeLatest(formativeActions.startFormativeTest, startFormativeTestSaga)
}

function* startFormativeTestSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.START_FORMATIVE_TEST_REQUEST,
      message.payload
    )
  )
}

function* watchRemoveTestSaga() {
  yield takeLatest(
    formativeActions.removeFormativeTest,
    removeFormativeTestSaga
  )
}

function* removeFormativeTestSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.REMOVE_FORMATIVE_TEST_REQUEST,
      message.payload
    )
  )
}

function* watchRedoTestSaga() {
  yield takeLatest(formativeActions.redoFormativeTest, redoFormativeTestSaga)
}

function* redoFormativeTestSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.REDO_FORMATIVE_TEST_REQUEST,
      message.payload
    )
  )
}

function* importTestsSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.IMPORT_TESTS_REQUEST,
      message.payload
    )
  )
}

function* watchStopTestSaga() {
  yield takeLatest(formativeActions.stopFormativeTest, stopFormativeTestSaga)
}

function* stopFormativeTestSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      formativeActions.SERVER_MESSAGE_ACTION.STOP_FORMATIVE_TEST_REQUEST,
      message.payload
    )
  )
}

function* handleReceivedFormativeEventSaga(
  message: types.ReceiveFormativeEventMessagesTypes
) {
  const { type, action, payload } = message.payload
  const currentUser = yield select(selectAuthenticatedUser)
  if (type === MODULE_NAME) {
    switch (action) {
      case formativeActions.SERVER_MESSAGE_ACTION.FORMATIVE_TEST_UPDATED_EVENT:
        const updatedTest = payload as types.ReceiveTestAction["payload"]
        if (!updatedTest.isPublic) {
          if (updatedTest.createdBy !== currentUser.studliId) {
            yield put(
              formativeActions.removeFormativeTestFromList({
                testId: updatedTest.id
              })
            )
            break
          }
        }

        yield put(formativeActions.addFormativeTest(updatedTest))
        break

      case formativeActions.SERVER_MESSAGE_ACTION.FORMATIVE_TEST_REMOVED_EVENT:
        const removedTest =
          payload as types.ReceiveRemovedTestEventAction["payload"]
        yield put(
          formativeActions.removeFormativeTestFromList({ testId: removedTest })
        )
        break
      case formativeActions.SERVER_MESSAGE_ACTION
        .FORMATIVE_PROGRESS_UPDATE_EVENT:
        const progress =
          payload as types.ReceiveProgressUpdateEventAction["payload"]
        yield put(formativeActions.addProgressPost(progress))
        break

      case formativeActions.SERVER_MESSAGE_ACTION.FORMATIVE_ANSWER_UPDATE_EVENT:
        const answer =
          payload as types.ReceiveAnswerUpdateEventAction["payload"]
        yield put(formativeActions.addAnswerPost(answer))
        break
    }
  }
}

function* handleReceivedFormativeResponseSaga(
  message: types.ReceiveFormativeResponseMessagesTypes
) {
  const { type, action, payload, error, echo } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case formativeActions.SERVER_MESSAGE_ACTION.GET_ALL_QUESTIONS_RESPONSE:
        if (error) {
          break
        }
        const questions = payload as types.ReceiveQuestionsAction["payload"]
        yield put(formativeActions.setQuestions(questions))
        break
      case formativeActions.SERVER_MESSAGE_ACTION
        .CREATE_FORMATIVE_TEST_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.CREATE_TEST_RESPONSE_FAILURE
            )
          )
          return
        }
        const createdTest = payload as types.ReceiveTestAction["payload"]
        yield put(formativeActions.addFormativeTest(createdTest))
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.CREATE_TEST_RESPONSE_SUCCESS
          )
        )
        const classroomId = yield select(selectClassroomId)

        yield put(
          routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR, {
            classroomId
          })
        )
        break
      case formativeActions.SERVER_MESSAGE_ACTION.GET_ALL_TESTS_RESPONSE:
        if (error) {
          return
        }
        const tests = payload as types.ReceiveAllTestsAction["payload"]
        yield put(formativeActions.setTests(tests))

        break
      case formativeActions.SERVER_MESSAGE_ACTION
        .GET_ALL_IMPORTABLE_TESTS_RESPONSE:
        if (error) {
          return
        }
        const importableTests =
          payload as types.ReceiveAllImportableTestsAction["payload"]
        yield put(formativeActions.setImportableTests(importableTests))

        break
      case formativeActions.SERVER_MESSAGE_ACTION
        .GET_PREPARED_FORMATIVE_TESTS_RESPONSE:
        if (error) {
          return
        }
        const preparedTests =
          payload as types.ReceivePreparedTestsAction["payload"]
        yield put(formativeActions.setPreparedTests(preparedTests))

        break
      case formativeActions.SERVER_MESSAGE_ACTION.GET_FORMATIVE_TEST_RESPONSE:
        const test = payload as types.ReceiveGetTestAction["payload"]
        if (!test || !test.ProductID) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.GET_TEST_RESPONSE_FAILURE
            )
          )
          const classrId = yield select(selectClassroomId)

          yield put(
            routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR, {
              classroomId: classrId
            })
          )

          return
        }
        break
      case formativeActions.SERVER_MESSAGE_ACTION.START_FORMATIVE_TEST_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.START_TEST_RESPONSE_FAILURE
            )
          )
          return
        }
        const startedTest = payload as types.ReceiveTestAction["payload"]
        yield put(formativeActions.addFormativeTest(startedTest))
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.START_TEST_RESPONSE_SUCCESS
          )
        )
        break

      case formativeActions.SERVER_MESSAGE_ACTION.STOP_FORMATIVE_TEST_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.STOP_TEST_RESPONSE_FAILURE
            )
          )
          return
        }
        const stoppedTest = payload as types.ReceiveTestAction["payload"]
        const latestSession = getLatestSession(stoppedTest.sessions)

        yield put(formativeActions.addFormativeTest(stoppedTest))
        if (moment(latestSession.endedAt).isAfter(moment().add(1, "minute"))) {
          yield put(
            applicationActions.setSnackbar({
              severity: "success",
              message: `Testet avslutas ${moment(
                latestSession.endedAt
              ).fromNow()}`
            })
          )
          break
        }
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.STOP_TEST_RESPONSE_SUCCESS
          )
        )
        break

      case formativeActions.SERVER_MESSAGE_ACTION
        .GET_FORMATIVE_PROGRESSION_RESPONSE:
        if (error) {
          return
        }
        const progression = payload as types.ReceiveProgressionAction["payload"]
        yield put(formativeActions.setProgression(progression))
        break

      case formativeActions.SERVER_MESSAGE_ACTION
        .REMOVE_FORMATIVE_TEST_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.REMOVE_TEST_RESPONSE_FAILURE
            )
          )
          return
        }
        const removeTest = payload as types.ReceiveRemovedTestAction["payload"]
        yield put(
          formativeActions.removeFormativeTestFromList({
            testId: removeTest.testId
          })
        )
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.REMOVE_TEST_RESPONSE_SUCCESS
          )
        )
        break

      case formativeActions.SERVER_MESSAGE_ACTION.EDIT_FORMATIVE_TEST_RESPONSE:
        const editAction = echo as types.ReceiveTestAction["echo"]

        if (error) {
          yield handleEditActionToaster(
            editAction ? (editAction as types.EditTestEchoActions) : null,
            false
          )

          return
        }
        const editedTest = payload as types.ReceiveTestAction["payload"]
        yield put(formativeActions.addFormativeTest(editedTest))
        yield handleEditActionToaster(
          editAction ? (editAction as types.EditTestEchoActions) : null,
          true
        )
        const cId = yield select(selectClassroomId)

        if (echo !== "togglePrivate") {
          yield put(
            routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR, {
              classroomId: cId
            })
          )
        }
        break
      case formativeActions.SERVER_MESSAGE_ACTION.REDO_FORMATIVE_TEST_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.SAVE_TEST_RESPONSE_FAILURE
            )
          )
          return
        }
        const redoTest = payload as types.ReceiveTestAction["payload"]
        yield put(formativeActions.addFormativeTest(redoTest))
        if (redoTest.status === "published") {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.SAVE_TEST_RESPONSE_SUCCESS
            )
          )
        }

        if (redoTest.status === "ongoing") {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.START_TEST_RESPONSE_SUCCESS
            )
          )
        }
        break

      case formativeActions.SERVER_MESSAGE_ACTION.IMPORT_TESTS_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.IMPORT_TEST_RESPONSE_FAILURE
            )
          )
          return
        }
        yield put(formativeActions.getFormativeProgression())
        yield put(formativeActions.getAllImportableTests())
        yield put(formativeActions.getAllTests())
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.IMPORT_TEST_RESPONSE_SUCCESS
          )
        )
        break
      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in response`
        )
    }
  }
}

function* watchImportTests() {
  yield takeLatest(formativeActions.importTests, importTestsSaga)
}

function* handleEditActionToaster(
  action: types.EditTestEchoActions | null,
  success: boolean
) {
  switch (action) {
    case "activate":
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.ACTIVATE_TEST_RESPONSE_SUCCESS
            : snackbarMessages.ACTIVATE_TEST_RESPONSE_FAILURE
        )
      )
      break
    case "archive":
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.ARCHIVE_TEST_RESPONSE_SUCCESS
            : snackbarMessages.ARCHIVE_TEST_RESPONSE_FAILURE
        )
      )
      break
    default:
      yield put(
        applicationActions.setSnackbar(
          success
            ? snackbarMessages.EDIT_TEST_RESPONSE_SUCCESS
            : snackbarMessages.EDIT_TEST_RESPONSE_FAILURE
        )
      )
  }
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(RECEIVE_RESPONSE, handleReceivedFormativeResponseSaga),
    yield takeLatest(RECEIVE_EVENT, handleReceivedFormativeEventSaga)
  ])
}

export function* rootSaga() {
  yield all([
    watchGetAllQuestionsSaga(),
    watchCreateTestSaga(),
    watchEditTestSaga(),
    watchReceiverSaga(),
    watchStartTestSaga(),
    watchGetAllTestsSaga(),
    watchGetAllImportableTestsSaga(),
    watchStopTestSaga(),
    watchRedoTestSaga(),
    watchRemoveTestSaga(),
    watchGetProgressionSaga(),
    watchGetAllPreparedTestsSaga(),
    watchImportTests(),
    watchGetTestSaga()
  ])
}
