import { RootState } from "../store"
import {
  AssignmentsGoalProgress,
  AssignmentsProgress,
  AssignmentsTaskProgress
} from "./types"
import { createSelector } from "reselect"

export const selectAllAssignmentsProgress = (
  state: RootState
): AssignmentsProgress => state.assignmentsProgress.assignmentsProgress

export const selectAllGoalsAssignmentsProgress = (
  state: RootState
): AssignmentsGoalProgress => state.assignmentsProgress.assignmentsGoalProgress

export const selectAllAssignmentsTaskProgress = (
  state: RootState
): AssignmentsTaskProgress => state.assignmentsProgress.assignmentsTaskProgress

export const selectAssignmentProgressByStudent = createSelector(
  selectAllAssignmentsProgress,
  ({ location }: RootState) => +location.payload.memberId,
  (progress, memberId) => progress.filter(p => p.studliId === memberId)
)

export const selectAssignmentTaskProgressByStudent = createSelector(
  selectAllAssignmentsTaskProgress,
  ({ location }: RootState) => +location.payload.memberId,

  (progress, memberId) => progress.filter(p => p.studliId === memberId)
)

export const selectGoalAssignmentByStudentAndAssignment = createSelector(
  ({ location }: RootState) => +location.payload.assignmentId,
  ({ location }: RootState) => +location.payload.memberId,
  selectAllGoalsAssignmentsProgress,
  (assignmentId, memberId, progress) => {
    if (assignmentId) {
      return progress.filter(
        p => p.studliId === memberId && p.assignmentId === assignmentId
      )
    }
    return []
  }
)
