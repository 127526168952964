import { useEffect, useState } from "react"
import { useStore } from "react-redux"

type Props = {
  init: string
  mod: string
}

const useLoader = ({ init, mod }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const store = useStore()

  const handleChange = () => {
    const state = store.getState()
    const pendingRequests = state.communication.pendingRequests
    const loading = pendingRequests.some(
      (request: any) => request.type === mod && request.action === init
    )
    setIsLoading(loading)
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(handleChange)
    return () => unsubscribe()
    // eslint-disable-next-line
  }, [init, mod])
  return isLoading
}

export default useLoader
