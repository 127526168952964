import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"

import Snackbar from "../../containers/Snackbar"
import CssBaseline from "@mui/material/CssBaseline"
import ContentWrapper from "../../containers/ContentByRoute"
import Banner from "../../components/Banners"

import * as productActions from "../../modules/products/actions"
import * as classroomActions from "../../modules/classroom/actions"

import { StyledContainer } from "./StyledAppShell"
import ErrorBoundary from "../ErrorBoundary"
import Breadcrumbs from "../../components/Breadcrumbs"
import HelpButton from "../../components/HelpButton"
import useRouteData from "./useRouteData"
import { MODULE_NAME as productMod } from "../../modules/products/constants"
import { MODULE_NAME as classroomMod } from "../../modules/classroom/constants"

import useLoader from "../../hooks/useLoader"
import {
  resetAssignmentMessages,
  resetAssignments
} from "../../modules/assignments/actions"
import { resetAssignmentsProgress } from "../../modules/assignmentsProgress/actions"
import { resetBooks } from "../../modules/book/actions"
import { resetExercises } from "../../modules/exercises/actions"
import { resetExercisesProgress } from "../../modules/exercisesProgress/actions"
import { resetFormativeQuestions } from "../../modules/formative/actions"
import { resetGoals } from "../../modules/goals/actions"
import { resetGoalsProgress } from "../../modules/goalsProgress/actions"
import { allProductRelatedDataReset } from "../../modules/products/actions"
import {
  resetUsersOnline,
  resetUsersWithProduct,
  resetActiveProducts
} from "../../modules/users/actions"
import { ActionCreator } from "redux"
import useOnLoadData from "./useOnLoadData"
import PageContainer from "../../components/PageContainer/PageContainer"
import { Box, Breakpoint } from "@mui/material"
import { resetMembers } from "../../modules/members/actions"
import WelcomeBackBanner from "../../components/Banners/WelcomeBackBanner"

export type OwnProps = {
  width: Breakpoint
  children: {
    topBar: React.ReactNode
    sideBar?: React.ReactNode
    content: React.ReactNode
  }
}

const resetProductActions: ActionCreator<unknown>[] = [
  resetExercises,
  resetExercisesProgress,
  resetGoals,
  resetGoalsProgress,
  resetUsersWithProduct,
  resetUsersOnline,
  resetAssignments,
  resetAssignmentsProgress,
  resetAssignmentMessages,
  resetBooks,
  resetFormativeQuestions
]

const resetGroupChangeActions: ActionCreator<unknown>[] = [
  resetExercises,
  resetExercisesProgress,
  resetGoals,
  resetGoalsProgress,
  resetUsersWithProduct,
  resetUsersOnline,
  resetAssignments,
  resetAssignmentsProgress,
  resetAssignmentMessages,
  resetBooks,
  resetFormativeQuestions,
  resetMembers,
  resetActiveProducts
]

type Props = OwnProps

const AppShell = ({ children: { content }, width: muiWidth }: Props) => {
  const dispatch = useDispatch()
  useRouteData()
  const hasProductChanged = useLoader({
    init: productActions.SERVER_MESSAGE_ACTION.CHANGE_ACTIVE_PRODUCT_REQUEST,
    mod: productMod
  })

  const hasGroupChanged = useLoader({
    init: classroomActions.SERVER_MESSAGE_ACTION.CLASSROOM_CHANGE_GROUP_REQUEST,
    mod: classroomMod
  })
  useOnLoadData()
  useEffect(() => {
    if (hasProductChanged) {
      resetProductActions.forEach(a => dispatch(a()))

      dispatch(allProductRelatedDataReset())
    }
    if (hasGroupChanged) {
      resetGroupChangeActions.forEach(a => dispatch(a()))
      dispatch(allProductRelatedDataReset())
    }
  }, [hasProductChanged, dispatch, hasGroupChanged])

  return (
    <React.Fragment>
      <CssBaseline />
      <ErrorBoundary>
        <StyledContainer>
          <PageContainer
            Component={
              <Box mb="4rem">
                <WelcomeBackBanner />
                <Breadcrumbs />
                <Banner />
                <ContentWrapper>{content}</ContentWrapper>
              </Box>
            }
          />
        </StyledContainer>
      </ErrorBoundary>
      <HelpButton />
      <Snackbar />
    </React.Fragment>
  )
}

export default connect()(AppShell)
