import { takeLatest, put, all, select, race } from "redux-saga/effects"

import * as actions from "./actions"
import * as applicationActions from "../application/actions"
import * as communicationActions from "../communication/actions"
import * as memberActions from "../members/actions"
import * as memberTypes from "../members/types"

import { sendRequest, setCommunicationError } from "../communication/actions"
import * as types from "./types"
import { MODULE_NAME } from "./constants"
import * as communicationTypes from "../communication/types"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import { setTheme } from "../../shared/styles/setTheme"
import { selectAllActiveGroups } from "./selectors"
import { selectAllMembers } from "../members/selectors"

function* getClassroomInfo() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.CLASSROOM_INFO_REQUEST
    )
  )
}

function* watchGetClassroomInfo() {
  yield takeLatest(actions.getClassroomInfo, getClassroomInfo)
}

function* setClassroomName(
  message: ReturnType<typeof actions.setClassroomName>
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.CLASSROOM_INFO_UPDATE_REQUEST,
      { name: message.payload }
    )
  )
}

function* watchSetClassroomName() {
  yield takeLatest(actions.setClassroomName, setClassroomName)
}

function* handleReceivedClassroomInfoResponseSaga(
  message: types.ReceiveClassroomInfoMessageTypes
) {
  const { type, action, payload, error } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_INFO_RESPONSE:
        yield receiveClassroomInfoResponse(
          payload as types.ReceiveClassroomInfoPayload,
          error
        )
        break

      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_INFO_UPDATE_RESPONSE:
        yield receiveClassroomInfoUpdateResponse(
          payload as types.ReceiveClassroomInfoUpdatePayload,
          error
        )
        break

      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_GROUPS_RESPONSE:
        yield receiveClassroomGroupsResponse(
          payload as types.ReceiveClassroomGroupsPayload,
          error
        )
        break

      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_ALL_GROUPS_RESPONSE:
        yield put(
          actions.addSchoolUnitGroups(
            payload as types.ReceiveSchoolUnitGroupsPayload
          )
        )
        break
      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_CHANGE_GROUP_RESPONSE:
        yield receiveClassroomChangeGroupsResponse(
          payload as types.ChangeClassroomGroupPayload,
          error
        )
        break
      default:
        yield put(
          setCommunicationError({
            id: 0,
            message: `Received unhandled message action ${message.action} for message type ${message.action}`,
            reporter: "Backend"
          })
        )
    }
  }
}

export function* receiveClassroomGroupsResponse(
  payload: types.ReceiveClassroomGroupsResponse["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.CLASSROOM_GROUPS_RESPONSE_FAILURE
      )
    )

    return
  }

  yield put(actions.addClassroomGroups(payload))
}

export function* receiveClassroomChangeGroupsResponse(
  payload: types.ReceiveChangeClassroomGroupsResponse["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.CLASSROOM_GROUPS_RESPONSE_FAILURE
      )
    )

    return
  }

  yield put(actions.changeClassroomGroups(payload))
}

export function* receiveClassroomInfoUpdateResponse(
  payload: types.ReceiveClassroomInfoUpdateAction["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.CLASSROOM_INFO_UPDATE_RESPONSE_FAILURE
      )
    )
  } else {
    yield put(actions.updateClassroomInfoResponse(payload))
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.CLASSROOM_INFO_UPDATE_RESPONSE
      )
    )
  }
}
export function* receiveClassroomInfoResponse(
  payload: types.ReceiveClassroomInfoResponse["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.CLASSROOM_INFO_RESPONSE_FAILURE
      )
    )

    return
  }

  setTheme(payload)
  yield put(actions.addClassroomInfo(payload))
}

function* getClassroomGroups() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.CLASSROOM_GROUPS_REQUEST
    )
  )
}

function* getSchoolUnitGroups() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.CLASSROOM_ALL_GROUPS_REQUEST
    )
  )
}

function* watchGetClassroomGroups() {
  yield takeLatest(actions.getClassroomGroups, getClassroomGroups)
}

export function* handleReceivedGroupEventSaga(
  message: types.ReceiveClassroomEventMessageTypes
) {
  const { type, action, payload } = message.payload
  let members

  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_GROUP_REMOVED_EVENT:
        const removedGroups = payload as number[]

        const activeGroups = yield select(selectAllActiveGroups)

        const newActiveGroupIds = activeGroups
          .filter((g: types.Group) => !removedGroups.includes(g.id))
          .map((g: types.Group) => g.id)

        if (activeGroups.length !== newActiveGroupIds.length) {
          const p: types.ChangeClassroomGroupPayload = {
            activeGroupIds: newActiveGroupIds
          }

          yield put(actions.changeClassroomGroups(p))
        }

        yield put(actions.classroomGroupsRemoved(removedGroups))

        members = yield select(selectAllMembers)
        members.forEach((m: memberTypes.Member) => {
          if (m.groups) {
            m.groups = m.groups.filter(id => !removedGroups.includes(id))
          }
        })

        members = members.filter((m: memberTypes.Member) =>
          m.groups ? m.groups.some(id => newActiveGroupIds.includes(id)) : false
        )

        yield put(memberActions.setMembers(members))

        break
      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_GROUP_UPDATED:
        const groups = payload as types.Groups

        yield put(actions.classroomGroupsUpdated(groups))

        break
      case actions.SERVER_MESSAGE_ACTION.CLASSROOM_MEMBER_REMOVED:
        const removedMemberIds = payload as number[]

        members = yield select(selectAllMembers)
        const newMembers = members.filter(
          (m: memberTypes.Member) => !removedMemberIds.includes(m.studliId)
        )

        yield put(memberActions.setMembers(newMembers))

        break
      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in event`
        )
    }
  }
}

function* watchGetSchoolUnitGroups() {
  yield takeLatest(actions.getSchoolUnitGroups, getSchoolUnitGroups)
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceivedClassroomInfoResponseSaga
    ),
    yield takeLatest(
      communicationActions.RECEIVE_EVENT,
      handleReceivedGroupEventSaga
    )
  ])
}

function* changeGroupSaga(
  message: ReturnType<typeof actions.changeClassroomGroup>
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.CLASSROOM_CHANGE_GROUP_REQUEST,
      { groupIds: message.payload }
    )
  )
}

function* watchChangeGroupSaga() {
  yield takeLatest(actions.changeClassroomGroup, changeGroupSaga)
}

export function* rootSaga() {
  yield all([
    watchGetClassroomInfo(),
    watchSetClassroomName(),
    watchReceiverSaga(),
    watchGetClassroomGroups(),
    watchChangeGroupSaga(),
    watchGetSchoolUnitGroups()
  ])
}
