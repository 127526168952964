import { Typography, Divider, styled } from "@mui/material"

type ProductInfoContainerProps = {
  should_margin_top: boolean
}

export const StyledInfoContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
`

export const StyledInfoBox = styled("div")`
  flex: 1;
  margin: 1rem;
`

export const StyledInfoSegment = styled("div")`
  display: flex;
  width: 100%;
  margin: 1rem;
  align-items: center;
`
export const StyledLabelAndValue = styled("div")`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

export const StyledInfoLabel = styled(Typography)`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 16px;
  font-size: 0.75rem;
  flex: 1;
  margin-top: 5px;
`
export const StyledInfoValue = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 28px;
  word-break: break-word;
  flex: 1;
`

export const StyledName = styled(Typography)`
  font-weight: 350;
  font-size: 1.5rem;
  margin-left: 1rem;
`

export const StyledProductInfoContainer = styled(
  "div"
)<ProductInfoContainerProps>`
  ${props => props.should_margin_top && "margin-top: 2rem"}
`

export const StyledDivider = styled(Divider)`
  display: block;
  width: 100%;
  margin: 1rem 0;
`
export const StyledInfoColumnContainer = styled("div")`
  flex-direction: column;
  width: 100%;
  ${props => props.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: row;
  }
`
