import React from "react"
import Menu from "@mui/material/Menu"
import Button from "@mui/material/Button"
import { StyledMenuItem } from "./StyledDropDownMenu"
import { MenuItem, Props } from "./types/types"

const DifficultyDropDownMenu = ({
  menuItems,
  callback,
  buttonLabel
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCallback = (difficulty: number) => {
    callback(difficulty)
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderMenuItems = () =>
    menuItems.map((item: MenuItem) => (
      <StyledMenuItem
        aria-label={item.label}
        key={item.difficulty}
        onClick={() => handleCallback(item.difficulty)}
      >
        {item.label}
      </StyledMenuItem>
    ))

  return (
    <>
      <Button
        aria-label="Visa exempelövningar"
        color="primary"
        variant="outlined"
        onClick={handleClick}
      >
        {buttonLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItems()}
      </Menu>
    </>
  )
}

export default DifficultyDropDownMenu
