import { createAction } from "../../shared/tools/redux"
import { Goals } from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_GOALS_REQUEST = "GET_ALL_GOALS_REQUEST",
  SET_START_GOAL_REQUEST = "SET_START_GOAL_REQUEST"
}

/**
 * Redux-actions, originating from server. As result from a client-request.
 */
export enum RESPONSE {
  GET_ALL_GOALS_RESPONSE = "GET_ALL_GOALS_RESPONSE",
  SET_START_GOAL_RESPONSE = "SET_START_GOAL_RESPONSE"
}
/**
 * Redux-actions originating from server. Because of this or another client's actions.
 */
export enum EVENT {
  GOAL_UPDATED_EVENT = "GOAL_UPDATED_EVENT",
  GOAL_COMPLETED_EVENT = "GOAL_COMPLETED_EVENT",
  SET_GOALS = "SET_GOALS"
}

/**
 * Strings for "action" property when communicating with server.
 * Request and Response use the same string.
 * (The "type" property is this module's name).
 */
export enum SERVER_MESSAGE_ACTION {
  GET_ALL_GOALS_REQUEST = "list",
  GET_ALL_GOALS_RESPONSE = "list",
  SET_START_GOAL_REQUEST = "set_goal",
  SET_START_GOAL_RESPONSE = "set_goal"
}

export enum UI {
  RESET_GOALS = "RESET_GOALS"
}
/**
 * Get all goals for this classroom's students.
 */
export const getAllGoalsRequest = createAction("GET_ALL_GOALS_REQUEST")

export const addGoals = createAction<{
  goals: Goals
}>("GET_ALL_GOALS_RESPONSE")

export const addGoalsEvent = createAction<{
  goals: Goals
}>("SET_GOALS")

export const setStartGoal = createAction<{
  studliIds: number[]
  goalRef: string
}>("SET_START_GOAL_REQUEST")

export const markGoalCompleted = createAction<{
  studliId: number
  goalRef: string
  goalAbilityGrade: number
}>("GOAL_COMPLETED_EVENT")

export const goalUpdatedEvent = createAction<{
  studliId: number
  goalRef: string
  goalAbilityGrade: number
}>("GOAL_UPDATED_EVENT")

export const resetGoals = createAction("RESET_GOALS")
