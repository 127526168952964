import * as React from "react"

import useStudentAssignmentList from "./useStudentAssignmentList"
import AssignmentsList from "../AssignmentsList"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import AssignmentsTable from "../AssignmentsTable"
import { StyledNoInfo } from "../StudentHistoryList/StyledHistoryList"
// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = () => {}

const StudentAssignmentList = () => {
  const {
    assignments,
    stickers,
    features,
    goToAssignment,
    member,
    activeProduct
  } = useStudentAssignmentList()
  return (
    <>
      {assignments.length > 0 ? (
        <MobileOrDesktop>
          {{
            desktop: (
              <>
                <AssignmentsTable
                  assignments={assignments}
                  classroomFeatures={features}
                  onMenuClick={NOOP}
                  onAssignmentClick={goToAssignment}
                  onProgressClick={NOOP}
                  onCheckboxClick={NOOP}
                  checkedAssignments={[]}
                  toggleAllAssignments={NOOP}
                  type="studentCard"
                  stickers={stickers}
                  isTestClassroom={false}
                  members={[]}
                />
              </>
            ),
            mobile: (
              <>
                <AssignmentsList
                  assignments={assignments}
                  stickers={stickers}
                  classroomFeatures={features}
                  onMenuClick={NOOP}
                  onAssignmentClick={goToAssignment}
                  onProgressClick={NOOP}
                  type="studentCard"
                  onCheckboxClick={NOOP}
                  checkedAssignments={[]}
                  toggleAllAssignments={NOOP}
                />
              </>
            )
          }}
        </MobileOrDesktop>
      ) : (
        <StyledNoInfo>
          {member.firstName} har ännu inte fått några uppdrag i {activeProduct}
        </StyledNoInfo>
      )}
    </>
  )
}

export default StudentAssignmentList
