export type ModuleName = "users"
export const MODULE_NAME: ModuleName = "users"
export const SESAM_GROUP = "sesam:40676-WB01_tomoyo_matematik"

export enum USER_ROLE {
  STUDENT = "student",
  TEACHER = "teacher"
}

export const SETTING = {
  settingShowWelcomeBackBanner: "setting-show-welcome-back-banner",
  settingShowTipAssignment: "setting-show-tip-assignment",
  settingShowTipFormativeTest: "setting-show-tip-formative-test"
} as const
