function getDuration(src: string): Promise<number> {
  const temp = document.createElement("audio")

  return new Promise<number>(resolve => {
    const onLoadedMetadata = () => {
      const { duration } = temp

      // Chrome bug https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (duration === Infinity) {
        temp.currentTime = Number.MAX_SAFE_INTEGER

        temp.ontimeupdate = () => {
          temp.currentTime = 0
          temp.ontimeupdate = null

          resolve(temp.duration)
        }

        return
      }

      resolve(duration)
    }

    temp.addEventListener("loadedmetadata", () => {
      onLoadedMetadata()

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      temp.removeEventListener("loadedmetadata", () => {})
    })
    temp.src = src
  })
}

export default getDuration
