import React from "react"
import { AssignmentProgress } from "../../modules/assignmentsProgress/types"
import moment from "moment"
import { StyledMessage } from "../ProgressHistory/StyledProgressHistory"

type Props = {
  progress: AssignmentProgress
  withFeedbackMessage: boolean
  strong?: boolean
}

const ProgressHistoryTeacherAction = ({
  progress,
  withFeedbackMessage,
  strong
}: Props) => {
  let message = ""
  if (progress.approvedAt) {
    message = `Uppdraget markerades som klart ${moment(
      progress.approvedAt
    ).fromNow()}${
      withFeedbackMessage ? ". Glöm inte att skicka feedback!" : ""
    }`
  }
  if (progress.rejectedAt) {
    message = `Uppdraget skickades tillbaka ${moment(
      progress.rejectedAt
    ).fromNow()}${
      withFeedbackMessage ? ". Glöm inte att skicka feedback!" : ""
    }`
  }

  return message !== "" ? (
    <StyledMessage variant="body2" strongTxt={strong}>
      {message}
    </StyledMessage>
  ) : null
}

export default ProgressHistoryTeacherAction
