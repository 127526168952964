import React, { useState, useEffect } from "react"
import {
  FormativeQuestionWithStudent,
  QuestionAlternativeWithStudents
} from "../../modules/formative/types"
import {
  StyledQuestionListItem,
  StyledQuestionText,
  StyledQuestionAnswer,
  StyledCommonErrorsButton,
  StyledHideAnswerButton,
  StyledInfoIcon,
  StyledCommonErrorContainer,
  StyledAnswerGrid,
  StyledFormativeTaskImage,
  StyledFormativeAnswerImage,
  StyledCorrectBadge,
  StyledWrongBadge,
  StyledBadgeContainer,
  StyledChapterText,
  StyledChapterProgressGrid,
  StyledProgressContainer,
  StyledRatingBadge,
  FormativePrevAnswerWrapper,
  StyledProgressWrapper
} from "./StyledFormativeQuestionsListItem"
import { Grid, Popover } from "@mui/material"
import HtmlRenderer from "../HtmlRenderer"
import MathJax from "react-mathjax"
import AudioPlayer from "../AudioPlayer"
import FormativeResultProgress from "../FormativeResultProgress"
import FormativeQuestionAnswerTag from "../../pages/FormativeQuestionsResult/FormativeQuestionAnswerTag"
import { AnswerTypes } from "../../pages/FormativeQuestionsResult/types"
import { StyledLastActiveContainer } from "../FormativeResultProgress/StyledFormativeResultProgress"
import LastActive from "../LastActive"
import InfoPopover from "../InfoPopover"
import { StyledLegendContainer } from "../../pages/FormativeQuestionsResult/StyledFormativeQuestionsResult"

type Props = {
  question: FormativeQuestionWithStudent
  prevQuestion: FormativeQuestionWithStudent | null
  setProgressDialog: (progress: ProgressItem) => void
  showAnswers: boolean
  type: "student" | "class"
  showProgressInfo?: boolean
  fullScreenMode?: boolean
  randomizeAlternatives?: boolean
}

const NOOP = () => {}

export type ProgressItem = {
  correct: number[]
  wrong: number[]
  notAnswered: number[]
  type: "multiple" | "rating"
  endTime: string
}

const FormativeQuestionAnswerItem = ({
  question,
  prevQuestion,
  setProgressDialog,
  showAnswers,
  type,
  fullScreenMode = false,
  showProgressInfo = false,
  randomizeAlternatives = false
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showAnswer, setShowAnswer] = useState<boolean>(true)

  useEffect(() => {
    setShowAnswer(showAnswers)
  }, [showAnswers])

  const renderMathBlock = (block: string, index: number) => {
    const parts = block
      .replace("$$", "$")
      .split("$")
      .map((part, i) =>
        i % 2 === 0 ? (
          part === "" ? null : (
            <HtmlRenderer key={i} html={part} />
          )
        ) : (
          <MathJax.Node key={i} inline formula={part} />
        )
      )
    return <MathJax.Provider key={index}>{parts}</MathJax.Provider>
  }
  const getTypeOfAnswer = (
    answer: QuestionAlternativeWithStudents
  ): { isRating: boolean; isWrong: boolean; isCorrect: boolean } => {
    const answers = {
      isRating: false,
      isWrong: false,
      isCorrect: false
    }
    if (fullScreenMode) {
      answers.isRating = answer.members.length > 0
      return answers
    }
    if (
      answer.points === 0 &&
      answer.members.length > 0 &&
      question.module === "multiple"
    ) {
      answers.isWrong = true
      return answers
    }
    if (answer.points > 0 && answer.members.length > 0) {
      answers.isCorrect = true
      return answers
    }
    if (question.module === "rating" && answer.members.length > 0) {
      answers.isRating = true
      return answers
    }
    return answers
  }
  const renderAnswer = (answer: QuestionAlternativeWithStudents) => {
    const typeOfAnswer = getTypeOfAnswer(answer)
    const showCorrectAnswer =
      question.module === "multiple" &&
      !fullScreenMode &&
      answer.points > 0 &&
      answer.members.length === 0
    return (
      <StyledQuestionAnswer
        variant="body2"
        rating={typeOfAnswer.isRating ? 1 : 0}
        wrong={typeOfAnswer.isWrong ? 1 : 0}
        correct={typeOfAnswer.isCorrect ? 1 : 0}
        showCorrectAnswer={showCorrectAnswer ? 1 : 0}
        onClick={
          answer.members.length > 0
            ? () =>
                setProgressDialogForAnswer(
                  answer,
                  typeOfAnswer.isCorrect,
                  typeOfAnswer.isWrong,
                  typeOfAnswer.isRating
                )
            : NOOP
        }
      >
        {type === "class" && (
          <StyledBadgeContainer>
            {typeOfAnswer.isCorrect && (
              <StyledCorrectBadge>{answer.members.length}</StyledCorrectBadge>
            )}
            {typeOfAnswer.isWrong && (
              <StyledWrongBadge>{answer.members.length}</StyledWrongBadge>
            )}
            {typeOfAnswer.isRating && (
              <StyledRatingBadge>{answer.members.length}</StyledRatingBadge>
            )}
          </StyledBadgeContainer>
        )}
        {answer.main.map((answerText: any, index: number) => (
          <>
            {answerText.text && (
              <HtmlRenderer key={answerText.text} html={answerText.text} />
            )}
            {answerText.image && (
              <StyledFormativeAnswerImage
                key={answerText.image}
                src={answerText.image}
                alt="fråga-svar"
              />
            )}
            {answerText.mediaplayer &&
              answerText.mediaplayer.type === "audio" && (
                <AudioPlayer
                  key={answerText.mediaplayer.src}
                  src={answerText.mediaplayer.src}
                  label="Fråga-svar-ljud"
                />
              )}
            {answerText.math && renderMathBlock(answerText.math, index)}
          </>
        ))}
      </StyledQuestionAnswer>
    )
  }

  const renderCommonErrors = () => {
    return (
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        anchorEl={anchorEl}
      >
        <StyledCommonErrorContainer>
          {question.content.commonErrors.map((error, index) => (
            <>
              {error.text && (
                <HtmlRenderer key={error.text} html={error.text} />
              )}
              {error.math && renderMathBlock(error.math, index)}
            </>
          ))}
        </StyledCommonErrorContainer>
      </Popover>
    )
  }

  const setProgressDialogForAnswer = (
    answer: QuestionAlternativeWithStudents,
    isCorrect: boolean,
    isWrong: boolean,
    isRating: boolean
  ) => {
    const progressData: ProgressItem = {
      correct: [],
      wrong: [],
      notAnswered: [],
      type: "multiple",
      endTime: ""
    }
    if (isWrong) {
      progressData.wrong = answer.members
    }
    if (isCorrect) {
      progressData.correct = answer.members
    }
    if (isRating) {
      progressData.correct = answer.members
      progressData.type = "rating"
    }
    setProgressDialog(progressData)
  }

  const handleOpenCommonError = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const getAnswerType = (progress: ProgressItem): AnswerTypes => {
    if (progress.correct.length && progress.type === "multiple") {
      return "correct"
    }
    if (progress.correct.length && progress.type === "rating") {
      return "rating"
    }
    if (progress.wrong.length && progress.type === "multiple") {
      return "wrong"
    }
    return "notAnswered"
  }

  const getProgress = (progressQuestion: FormativeQuestionWithStudent) => {
    const progress: ProgressItem = {
      correct: [],
      wrong: [],
      notAnswered: progressQuestion.notAnswered,
      type: "multiple",
      endTime: progressQuestion.endTime
    }

    progress.type =
      progressQuestion.module === "multiple" ? "multiple" : "rating"
    progressQuestion.content.alternatives.forEach(alt => {
      if (!alt.correct && progress.type === "multiple") {
        progress.wrong = [...progress.wrong, ...alt.members]

        return
      }
      progress.correct = [...progress.correct, ...alt.members]
    })
    return progress
  }
  const mainProgress = getProgress(question)
  const prevProgress = prevQuestion ? getProgress(prevQuestion) : null
  return (
    <StyledQuestionListItem divider={fullScreenMode ? false : true}>
      <Grid container spacing={3}>
        {!fullScreenMode && (
          <StyledChapterProgressGrid item xs={12}>
            <StyledChapterText>{`${question.category} / ${question.folder}`}</StyledChapterText>
            <StyledProgressContainer>
              {type === "class" && (
                <>
                  <StyledProgressWrapper>
                    {showProgressInfo && (
                      <InfoPopover
                        type="Info"
                        padding={false}
                        content={
                          <div>
                            <StyledLegendContainer>
                              <FormativeQuestionAnswerTag answerType="correct" />
                              Rätt svar
                            </StyledLegendContainer>
                            <StyledLegendContainer>
                              <FormativeQuestionAnswerTag answerType="wrong" />
                              Fel svar
                            </StyledLegendContainer>
                            <StyledLegendContainer>
                              <FormativeQuestionAnswerTag answerType="rating" />
                              Angivet svar som inte rättas
                            </StyledLegendContainer>
                            <StyledLegendContainer>
                              <FormativeQuestionAnswerTag answerType="notAnswered" />
                              Inget svar finns registrerat
                            </StyledLegendContainer>
                          </div>
                        }
                      />
                    )}
                    <FormativeResultProgress
                      correct={mainProgress.correct.length}
                      wrong={mainProgress.wrong.length}
                      notAnswered={mainProgress.notAnswered.length}
                      type={mainProgress.type}
                      endTime={mainProgress.endTime}
                      displayType="class"
                      onOpenProgressDialog={() =>
                        setProgressDialog(mainProgress)
                      }
                      current={true}
                    />
                  </StyledProgressWrapper>
                  {prevProgress && (
                    <FormativeResultProgress
                      correct={prevProgress.correct.length}
                      wrong={prevProgress.wrong.length}
                      notAnswered={prevProgress.notAnswered.length}
                      type={prevProgress.type}
                      endTime={prevProgress.endTime}
                      displayType="class"
                      onOpenProgressDialog={() =>
                        setProgressDialog(prevProgress)
                      }
                      current={false}
                    />
                  )}
                </>
              )}
              {type === "student" && (
                <>
                  <FormativeQuestionAnswerTag
                    answerType={getAnswerType(mainProgress)}
                  />
                  {prevProgress && (
                    <FormativePrevAnswerWrapper>
                      <StyledLastActiveContainer>
                        <LastActive date={prevProgress.endTime} />:
                      </StyledLastActiveContainer>
                      <FormativeQuestionAnswerTag
                        answerType={getAnswerType(prevProgress)}
                        opacity
                      />
                    </FormativePrevAnswerWrapper>
                  )}
                </>
              )}
            </StyledProgressContainer>
          </StyledChapterProgressGrid>
        )}
        <Grid item xs={12} sm={fullScreenMode ? 10 : 9}>
          {question.content.task.map((task, index) => (
            <React.Fragment key={index}>
              {task.text && (
                <StyledQuestionText variant="body1" key={task.text}>
                  <HtmlRenderer html={task.text} />
                </StyledQuestionText>
              )}
              {task.image && (
                <StyledFormativeTaskImage src={task.image} alt="fråga-bild" />
              )}
              {task.mediaplayer && task.mediaplayer.type === "audio" && (
                <AudioPlayer
                  src={task.mediaplayer.src}
                  label="Fråga-svar-ljud"
                />
              )}
              {task.math && (
                <StyledQuestionText variant="body1" key={task.math}>
                  {renderMathBlock(task.math, index)}
                </StyledQuestionText>
              )}
            </React.Fragment>
          ))}
        </Grid>
        {!fullScreenMode && (
          <Grid item xs={12}>
            {" "}
            <StyledHideAnswerButton onClick={() => setShowAnswer(!showAnswer)}>
              {showAnswer ? "Dölj svar" : "Visa svar"}
            </StyledHideAnswerButton>
          </Grid>
        )}
        {randomizeAlternatives &&
          question.content.alternatives
            .sort(() => 0.5 - Math.random())
            .map((answer, answerIndex) => (
              <StyledAnswerGrid
                show={showAnswer ? 1 : 0}
                key={answerIndex}
                item
                xs={12}
                sm={3}
              >
                {renderAnswer(answer)}
              </StyledAnswerGrid>
            ))}
        {!randomizeAlternatives &&
          question.content.alternatives.map((answer, answerIndex) => {
            return (
              <StyledAnswerGrid
                show={showAnswer ? 1 : 0}
                key={answerIndex}
                item
                xs={12}
                sm={3}
              >
                {renderAnswer(answer)}
              </StyledAnswerGrid>
            )
          })}
        {question.content.commonErrors && showAnswer && !fullScreenMode && (
          <Grid item xs={12}>
            <StyledCommonErrorsButton onClick={handleOpenCommonError}>
              <StyledInfoIcon />
              {question.content.settings.nameError ?? "Vanliga fel"}
            </StyledCommonErrorsButton>
            {renderCommonErrors()}
          </Grid>
        )}
      </Grid>
    </StyledQuestionListItem>
  )
}

export default FormativeQuestionAnswerItem
