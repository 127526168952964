import { ReactComponent as NotificationsIcon } from "../../assets/images/notification-default.svg"
import { List, IconButton, styled } from "@mui/material"
import Badge from "@mui/material/Badge"

type ListItemTextCustomProps = {
  unread: boolean
}

export const StyledNotificationsIcon = styled(NotificationsIcon)`
  cursor: pointer;
`
export const StyledListItemText = styled("p")<ListItemTextCustomProps>`
  font-weight: ${props => props.unread && "bold"};
  margin: 0;
`

export const StyledListItemSecondaryText = styled("p")`
  margin: 0;
  color: gray;
`

export const StyledNotificationsList = styled(List)`
  max-width: 600px;
`
export const StyledNotificationText = styled("div")`
  display: flex;
  flex-direction: column;
`

export const StyledListItemUnreadDot = styled("span")`
  height: 13px;
  width: 13px;
  background-color: rgb(0, 108, 200);
  border-radius: 50%;
  display: inline-block;
`

export const StyledNotificationsListHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5em;
`

export const StyledNotificationsHeaderText = styled("p")`
  font-size: 1rem;
`

export const StyledNoNotificationsText = styled("p")`
  padding: 1.5em;
`

export const StyledBadge = styled(Badge)`
  > span {
    top: 8px;
    right: 5px;
    background-color: red;
    color: #FFFFFF;
  }
`

export const StyledIconButton = styled(IconButton)`
  color: currentColor;
`
