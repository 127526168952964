import { styled } from "@mui/material"

import { ReactComponent as StudliLogo } from "./studli.svg"
import { ReactComponent as StudliLogoWithText } from "./studliWithText.svg"
import { ReactComponent as TomoyoLogo } from "./tomoyoLogo.svg"

export const StyledLogo = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledLogoText = styled("div")`
  margin-left: 0.5em;
`

export const StyledStudliLogo = styled(StudliLogo)`
  height: 25px;
`

export const StyledStudliLogoWithText = styled(StudliLogoWithText)`
  position: relative;
  top: 5.56px;
  height: 33px;
`
export const StyledTomoyoLogo = styled(TomoyoLogo)`
  padding: 1rem;
  height: 2rem;
  width: inherit;
`
