import * as actions from "./actions"
import * as types from "./types"
import { Member, Members, State } from "./types"

const initialState: State = {
  members: [],
  recentlyAddedMembers: []
}

const reducer = (
  state = initialState,
  action: types.MembersActionTypes | types.MemberActionTypesWithoutPayload
): State => {
  const currentMembers = state.members

  switch (action.type) {
    case actions.EVENT.MEMBER_ADDED_EVENT:
      const addedMembers: Member[] = (action as types.ReceiveMemberAddedAction)
        .payload

      const newMembers = addedMembers.filter(
        m =>
          currentMembers.findIndex(curr => curr.studliId === m.studliId) === -1
      )

      return {
        ...state,
        recentlyAddedMembers: newMembers.map(member => member.studliId),
        members: [
          ...currentMembers.map(m => {
            const member = addedMembers.find(i => i.studliId === m.studliId)

            if (!member) {
              return m
            }

            return {
              ...m,
              ...member
            }
          }),
          ...newMembers
        ]
      }

    case actions.EVENT.ADD_PASSWORDS_TO_MEMBERS_EVENT:
      const recievedPassword = (
        action as types.ReceiveChangeClassPasswordAction
      ).payload
      const membersWithPassword = [...state.members].map(member => ({
        ...member,
        password: recievedPassword.memberIds.some(
          memberId => memberId === member.studliId
        )
          ? recievedPassword.password
          : undefined
      }))
      return { ...state, members: membersWithPassword }
    case actions.UI.SET_RECENTLY_ADDED_MEMBERS:
      const recentlyAdded = (action as types.SetRecentlyAddedMembersAction)
        .payload.ids
      return { ...state, recentlyAddedMembers: recentlyAdded }
    case actions.RESPONSE.GET_ALL_MEMBERS_RESPONSE:
      const receivedMembers = (action as types.ReceiveMembersAction).payload
        .members

      return { ...state, members: receivedMembers }
    case actions.EVENT.MEMBER_UPDATED_EVENT:
      const newMember = (action as types.ReceiveMemberUpdatedAction).payload
      const updatedMembersWhenMemberUpdatedEvent = updateMembersArray(
        currentMembers,
        newMember as Member
      )

      logIfUpdateFailed(
        Boolean(updatedMembersWhenMemberUpdatedEvent),
        actions.EVENT.MEMBER_UPDATED_EVENT,
        (newMember as Member).studliId
      )

      return updatedMembersWhenMemberUpdatedEvent
        ? { ...state, members: updatedMembersWhenMemberUpdatedEvent }
        : state

    case actions.RESPONSE.REMOVE_MEMBER_RESPONSE:
      const removeMemberResponsePayload = (
        action as types.ReceiveRemoveMemberResponseAction
      ).payload

      return {
        ...state,
        members: currentMembers.filter(
          (member: Member) => member.studliId !== removeMemberResponsePayload.id
        )
      }

    case actions.EVENT.MEMBER_REMOVED_EVENT:
      const memberRemovedEventPayload = (
        action as types.MemberRemovedEventAction
      ).payload

      return {
        ...state,
        members: currentMembers.filter(
          member => !memberRemovedEventPayload.studliIds.includes(member.studliId)
        )
      }
    case actions.UI.SET_MEMBERS:
      const { members } = (action as types.SetMembersAction).payload

      return {
        ...state,
        members: members
      }

    case actions.UI.RESET_MEMBERS:
      return { ...state, members: [] }
    default:
      return state
  }
}

export default reducer

/**
 * If flag is false will log that update failed since memberId in payload from server did not match any in members list
 *
 * @param isMemberFound {boolean} - true if member is found, else false. If true nothing should be logged
 * @param action {string} - the action that was reducer is handling to build new state
 * @param memberId {number} - the id that indicates which member to update, received in payload from server
 */
export const logIfUpdateFailed = (
  isMemberFound: boolean,
  action: string,
  memberId: number
) => {
  isMemberFound ||
    window.console.warn(
      `Action ${action} failed to update member. Member with id/studliId ${memberId} not found in list of members.`
    )
}

/**
 *  Update specified array with specified member.
 *  If member already existed it will be updated and the new array will be returned else null will be returned.
 *
 * @param array {Members} - Array to update.
 * @param newMember {Member} - New member to update array with.
 *
 * @returns {Object} - The updated array if member found that corresponds to newMember, else null.
 */
const updateMembersArray = (array: Members, newMember: Member) => {
  let newMemberFoundInCurrent = false

  const updated = array.map((member: Member) => {
    if (member.studliId === newMember.studliId) {
      newMemberFoundInCurrent = true

      return { ...member, ...newMember }
    } else {
      return member
    }
  })

  return newMemberFoundInCurrent ? updated : null
}
