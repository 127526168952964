import { styled } from "@mui/material"
import ListItem, { ListItemProps } from "@mui/material/ListItem"

type CustomListItemProps = {
  hasonclick: number
}

export const StyledListItem = styled(
  ListItem as React.FC<ListItemProps & CustomListItemProps>
)`
  ${props => props.hasonclick && "cursor: pointer;"}

  &.Mui-selected:hover,
  &:hover {
    background: ${props => props.theme && props.theme.studli.table.hover};
  }
`
export const StyledTextProgressWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`

export const StyledValidationErrorMessage = styled("div")`
  color: red;
  font-size: 14px;
`
