import { ListItem, ListItemProps, styled } from "@mui/material"

export const StyledListItem = styled(ListItem as React.FC<ListItemProps | any>)`
  border-left: 5px solid transparent;
  background: white;

  &:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }

  ${({ redbar }) =>
    redbar === 1 &&
    `
    border-left: 5px solid #CC0000;
  `}
`
export const StyledNumberOfExercisesText = styled("span")`
  color: #656565;
  margin-left: 10px;
`

export const StyledPrimaryTextContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const StyledTextContainer = styled("div")`
  width: 100%;
`

export const StyledTextSegment = styled("div")`
  display: flex;
  justify-content: column;
`
