import { createAction } from "../../shared/tools/redux"

export const AUTH_STORE_TOKEN = "AUTH_STORE_TOKEN"
export const AUTH_TOKEN_REQUEST = "AUTH_TOKEN_REQUEST"
export const AUTH_TOKEN_SUCCESS = "AUTH_TOKEN_SUCCESS"
export const AUTH_TOKEN_FAILURE = "AUTH_TOKEN_FAILURE"
export const AUTH_TOKEN_UPDATED_EVENT = "updated_token"
export const AUTH_SSO_TOKEN_INVALIDATED_EVENT = "sso_token_invalidated"

export const authTokenReady = createAction("AUTH_TOKEN_SUCCESS")

export const requestAuth = createAction<{
  tokenUrl: string
  code: string
  state: string
  redirectUri: string
}>("AUTH_TOKEN_REQUEST")

export const authTokenFailure = createAction<Error | undefined>(
  "AUTH_TOKEN_FAILURE"
)

export const authTokenSuccess = createAction<any>("AUTH_TOKEN_SUCCESS")

export const authTokenUpdatedEvent =
  createAction<{ access_token: string }>("updated_token")

export const authSSOTokenInvalidatedEvent = createAction<{ token: string }>(
  AUTH_SSO_TOKEN_INVALIDATED_EVENT
)
