import { BoxProps, Box, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { StudliTheme } from "../../shared/styles/theme";

const StyledCircleIcon = styled(Box)<
  BoxProps & { option: keyof StudliTheme["studli"]["palette"]["colorPicker"] }
>`
  background-color: ${({ theme, option }) =>
    theme?.studli?.palette?.colorPicker[option]?.main};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

type Props = {
  option: keyof StudliTheme["studli"]["palette"]["colorPicker"];
  name: string;
};

const GroupNameColor = ({ option, name }: Props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <StyledCircleIcon option={option} />
      <Typography>{name}</Typography>
    </Stack>
  );
};
export default GroupNameColor;
