/**
 * These select* functions play nicely with redux select(), they expose parts of the state.
 */

import { RootState } from "../store"
import { PendingRequest, CommunicationError } from "./types"

export const selectPendingRequests = (store: RootState): PendingRequest[] =>
  store.communication.pendingRequests

export const selectCommunicationError = (
  store: RootState
): CommunicationError | undefined => store.communication.error

export const selectLastReceivedEventId = (
  store: RootState
): string | undefined => store.communication.lastReceivedEventId
