import * as goalsPageActions from "./actions"
import * as goalsActions from "../../../modules/goals/actions"
import { State, SetGoalFilterAction } from "./types"
import {
  Goal,
  Goals,
  ReceiveGetAllGoalsResponseAction,
  GetAllGoalsAction
} from "../../../modules/goals/types"

const initialState: State = {
  isGettingGoals: false,
  goalFilter: 1
}

const reducer = (
  state = initialState,
  action:
    | SetGoalFilterAction
    | ReceiveGetAllGoalsResponseAction
    | GetAllGoalsAction
): State => {
  switch (action.type) {
    case goalsPageActions.UI.SET_GOAL_FILTER:
      const goalFilter = (action as SetGoalFilterAction).payload.value

      return {
        ...state,
        goalFilter: goalFilter
      }

    case goalsActions.REQUEST.GET_ALL_GOALS_REQUEST:
      return { ...state, isGettingGoals: true }

    case goalsActions.RESPONSE.GET_ALL_GOALS_RESPONSE:
      const receivedGoals: Goals = (action as ReceiveGetAllGoalsResponseAction)
        .payload.goals
      const gradeWhereMostStudentsAre = 1
      findGradeWhereMostStudentAre(receivedGoals)

      return {
        ...state,
        isGettingGoals: false,
        goalFilter: gradeWhereMostStudentsAre
      }

    default:
      return state
  }
}

/**
 * Find which grade in goals has the most student
 * @param goals
 * @returns {number} - the grade that
 */
export const findGradeWhereMostStudentAre = (goals: Goals = []) => {
  // Local type that is necessary so typescript don't complain.
  type inProgressPerGradeObject = {
    [grade: number]: number
  }

  // reduce goals to an object with grade as key and number of students in grade as value
  const objectWithStudentCountPerGrade: inProgressPerGradeObject = goals.reduce(
    (inProgressPerGrade: inProgressPerGradeObject, goal: Goal) => {
      if (inProgressPerGrade[goal["grade"]] === undefined) {
        inProgressPerGrade[goal["grade"]] = 0
      }
      if (goal && goal["inProgress"]) {
        inProgressPerGrade[goal["grade"]] += goal["inProgress"].length
      }

      return inProgressPerGrade
    },
    {}
  )

  // Make an array of the keys in objectWithStudentCountPerGrade (change from string to number)
  const gradeKeys = Object.keys(objectWithStudentCountPerGrade).map(grade =>
    Number(grade)
  )

  // reduce gradeKeys to a single grade.
  const gradeWithMostStudents = gradeKeys.reduce((acc, key) => {
    acc =
      objectWithStudentCountPerGrade[key] > objectWithStudentCountPerGrade[acc]
        ? key
        : acc

    return acc
  })

  return gradeWithMostStudents
}

export default reducer
