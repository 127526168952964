import React from "react"
import { useSelector } from "react-redux"
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List
} from "@mui/material"
import Button from "../Button"
import { buildName } from "../../shared/tools/strings"
import { ProgressItem } from "../FormativeQuestionsListItem/FormativeQuestionAnswerItem"
import { selectStudentsWithProduct } from "../../modules/members/selectors"
import { StyledLabelText } from "../ProgressDialog/StyledProgressDialog"
import Avatar from "../Avatar"

type Props = {
  ariaTitleId: string
  ariaDescId: string
  onCancel: () => void
  progress: ProgressItem
}

const FormativeResultDialog = ({
  ariaTitleId,
  ariaDescId,
  onCancel,
  progress
}: Props) => {
  const students = useSelector(selectStudentsWithProduct)

  const renderListItem = (memberId: number) => {
    const foundStudent = students.find(student => student.studliId === memberId)
    return (
      foundStudent && (
        <ListItem divider={true} key={foundStudent.studliId}>
          <ListItemAvatar>
            <Avatar
              picture={foundStudent.picture}
              firstName={foundStudent.firstName}
              lastName={foundStudent.lastName}
              studliId={foundStudent.studliId}
            />
          </ListItemAvatar>
          <ListItemText>
            {buildName(foundStudent.firstName, foundStudent.lastName)}
          </ListItemText>
        </ListItem>
      )
    )
  }
  return (
    <>
      <DialogTitle id={ariaTitleId}></DialogTitle>
      <DialogContent id={ariaDescId}>
        {progress.notAnswered && progress.notAnswered.length > 0 && (
          <>
            <StyledLabelText>
              EJ SVARAT ({progress.notAnswered.length})
            </StyledLabelText>
            <List>
              {progress.notAnswered.map(member => renderListItem(member))}
            </List>
          </>
        )}
        {progress.wrong.length > 0 && (
          <>
            <StyledLabelText>FEL ({progress.wrong.length})</StyledLabelText>
            <List>{progress.wrong.map(member => renderListItem(member))}</List>
          </>
        )}
        {progress.correct.length > 0 && (
          <>
            <StyledLabelText>
              {progress.type === "rating" ? "SVARAT" : "RÄTT"} (
              {progress.correct.length})
            </StyledLabelText>
            <List>
              {progress.correct.map(member => renderListItem(member))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Stäng
        </Button>
      </DialogActions>
    </>
  )
}

export default FormativeResultDialog
