import { List, ListItem, ListItemIcon, ListProps, styled } from "@mui/material"

export const StyledFormativeQuestionsList = styled(List)<
  ListProps & { component: string }
>`
  padding-top: 0px;
  padding-bottom: 0px;
`

export const StyledFormativeQuestionsListItem = styled(ListItem)`
  padding: 10px;
`

export const StyledListItemIcon = styled(ListItemIcon)`
  align-items: center;
  margin-right: 10px;
`
