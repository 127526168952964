import * as exercisesPagesAction from "./actions"
import { State, SetTreeStatusAction } from "./types"
import { ResetExercisesAction } from "../../../modules/exercises/types"
import * as exercisesActions from "../../../modules/exercises/actions"
const initialState: State = {
  treeStatus: {
    expandedNodes: [],
    topNodesNotExpanded: [],
    checkedExercises: [],
    currentMobileNodeChosen: [],
    currentMobileBreadcrumbs: []
  }
}

const reducer = (
  state = initialState,
  action: SetTreeStatusAction | ResetExercisesAction
): State => {
  switch (action.type) {
    case exercisesPagesAction.UI.SET_TREE_STATUS:
      const val = (action as SetTreeStatusAction).payload

      return {
        ...state,
        treeStatus: { ...state.treeStatus, ...val }
      }
    case exercisesActions.UI.RESET_EXERCISES:
      return {
        ...state,
        treeStatus: {
          expandedNodes: [],
          topNodesNotExpanded: [],
          checkedExercises: [],
          currentMobileNodeChosen: [],
          currentMobileBreadcrumbs: []
        }
      }
    default:
      return state
  }
}

export default reducer
