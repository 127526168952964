export type ModuleName = "assignments"
export const MODULE_NAME: ModuleName = "assignments"

export const VALIDATION_ERRORS: { [key: string]: string } = {
  HAS_PUBLISHED_ASSIGNMENT:
    "Eleven har redan ett aktivt uppdrag i Tomoyo under den angivna tidsperioden"
}

export const CLASS_VALIDATION_ERRORS: { [key: string]: string } = {
  HAS_PUBLISHED_ASSIGNMENT:
    "En eller flera elever har redan ett aktivt uppdrag i Tomoyo under den angivna tidsperioden"
}
