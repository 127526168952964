/**
 * Simple presentational component showing title and description of a page
 */

import React from "react"
import {
  StyledTitleAndDescription,
  StyledTitle,
  StyledDescription
} from "./StyledTitleAndDescription"
import HtmlRenderer from "../HtmlRenderer"

type Props = {
  title: string
  paragraphs: string[]
  children?: never
}

const TitleAndDescription = ({ title, paragraphs }: Props) => {
  return (
    <StyledTitleAndDescription>
      <StyledTitle variant="h4" component="h1">
        {title}
      </StyledTitle>
      {paragraphs.map((paragraph: string, index) => (
        <HtmlRenderer
          component={StyledDescription}
          html={paragraph}
          key={index}
        />
      ))}
    </StyledTitleAndDescription>
  )
}

export default TitleAndDescription
