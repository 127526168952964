import * as React from "react"
import {
  Typography,
  DialogContent,
  DialogTitle,
  IconButton,
  TypographyProps,
  Button,
  styled
} from "@mui/material"
import { Close } from "@mui/icons-material"

import HtmlRenderer from "../../components/HtmlRenderer"
import * as P from "../../shared/tools/parse"
import Dialog from "../../components/Dialog"
import { StyledAudioPlayer } from "./StyledAssignmentStudentCard"

type Props = {
  title: string
  question?: string
  answer?: string
  exampleAnswer?: string
}

const Link = styled(Button)`
  background: transparent;
  border: 0;
  color: #006cc8;
  padding: 0;
  cursor: pointer;
  texttransform: none;
`

const SIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  width: 48px;
`

const Body2Text = styled(Typography)<TypographyProps & { component: string }>`
    margin-bottom: 1.5rem;
    &: div:nth-child(2) {
      margin: .25rem 0 0 0;
    }

    &: p {
      margin: 0;
    } 
`

const renderText = P.getText(P.nuller, html => <HtmlRenderer html={html} />)
const renderAudio = P.getAudio(P.nuller, src =>
  Array.isArray(src) ? (
    src.map((s, i) => (
      <StyledAudioPlayer src={s} key={s} label={`Ljudklipp ${i + 1}`} />
    ))
  ) : (
    <StyledAudioPlayer src={src} label="Ljudklipp" />
  )
)

const renderTextAndAudio =
  (onLeft: () => React.ReactNode) =>
  ({ text, audio, useraudio }: P.TextAndAudio) => {
    const t = renderText(text)
    const a = renderAudio(audio || useraudio)

    if (!t && !a) {
      return onLeft()
    }

    return (
      <>
        {t}
        {a}
      </>
    )
  }

const renderNoAnswer = () => (
  <div>
    <Typography variant="body2">
      Det finns inget svar att visa på den här deluppgiften.
    </Typography>
  </div>
)

const getTextAndAudio = (onLeft: () => React.ReactNode) =>
  P.parseTextAndAudio<React.ReactNode>(onLeft, renderTextAndAudio(onLeft))

const getQuestion = getTextAndAudio(P.nuller)
const getAnswer = getTextAndAudio(renderNoAnswer)

const OpenQuestion: React.FC<Props> = ({
  title,
  question: questionStr,
  answer: answerStr,
  exampleAnswer: exampleAnswerStr
}) => {
  const [open, toggleOpen] = React.useState(false)
  const question = React.useMemo(() => getQuestion(questionStr), [questionStr])
  const answer = React.useMemo(() => getAnswer(answerStr), [answerStr])
  const exampleAnswer = React.useMemo(
    () => renderText(exampleAnswerStr),
    [exampleAnswerStr]
  )

  return (
    <>
      <Link onClick={() => toggleOpen(true)}>Visa fråga och svar</Link>
      <Dialog
        open={open}
        onClose={() => toggleOpen(false)}
        maxWidth="sm"
        aria-labelledby="open-question-title"
      >
        <SIconButton
          onClick={() => toggleOpen(false)}
          aria-label={`Stäng ${title}`}
        >
          <Close />
        </SIconButton>
        <DialogTitle id="open-question-title">{title}</DialogTitle>
        <DialogContent>
          <Body2Text color="textSecondary" variant="body2" component="div">
            <Typography variant="body1">Fråga:</Typography>
            {question}
          </Body2Text>
          <Body2Text variant="body2" component="div">
            <Typography variant="body1">Svar:</Typography>
            {answer}
          </Body2Text>
          {exampleAnswer && (
            <Body2Text color="textSecondary" variant="body2" component="div">
              <Typography variant="body1">Exempelsvar:</Typography>
              {exampleAnswer}
            </Body2Text>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OpenQuestion
