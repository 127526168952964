import React, { useEffect } from "react"

import { CommunicationError } from "../../modules/communication/types"
import { StyledSplashScreen } from "./StyledSplashScreen"
import Logo from "../../components/Logo"
import InvalidClassroomError from "../../components/InvalidClassroomError"
import { ErrorPage } from "../ErrorBoundary/ErrorPage"

type Props = {
  communicationError?: CommunicationError
}

const SplashScreen = ({ communicationError }: Props) => {
  const [show, setShow] = React.useState(false)

  useEffect(() => {
    const setTimeoutId = window.setTimeout(() => setShow(true), 0)

    return () => window.clearTimeout(setTimeoutId)
    // eslint-disable-next-line
  }, [])

  const renderContent = () => {
    if (communicationError) {
      let message = ""

      switch (communicationError.id) {
        case 8:
        case 11:
        case 13:
          message =
            "Du har inte tillgång till klassrummet eller klassrummet finns inte."
          break
        case 9:
        case 12:
          message = "Produkten för valt klassrum är inte aktiverad."
          break
        case 99:
          message =
            "Något gick fel när vi laddade sidan. Försök igen, genom att ladda om sidan."
          break
        default:
          message = communicationError.id
            ? `Okänt fel ${communicationError.id}.`
            : `Okänt fel.`
      }

      return (
        <InvalidClassroomError
          message={message}
          reload={communicationError.id === 99}
        />
      )
    } else if (communicationError) {
      return <ErrorPage />
    } else {
      return <Logo showLoadingDots />
    }
  }

  return show ? (
    <StyledSplashScreen>{renderContent()}</StyledSplashScreen>
  ) : (
    <React.Fragment></React.Fragment>
  )
}

export default SplashScreen
