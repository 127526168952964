import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import {
  selectAllGroups,
  selectClassroomSchoolUnitCode
} from "../../modules/classroom/selectors"
import { Group } from "../../modules/classroom/types"
import Dialog from "../Dialog"
import GroupNameColor from "../GroupNameColor/GroupNameColor"

type Props = {
  onClose: () => void
  onSelectGroups: (groupId: number) => void
  title: string
  nbrOfStudents: number
  type: "assignment" | "formative"
}

const GroupSelectorDialog = ({
  onClose,
  onSelectGroups,
  title,
  nbrOfStudents,
  type
}: Props) => {
  const [selectedGroup, setSelectedGroup] = React.useState<Group | null>(null)
  const groups = useSelector(selectAllGroups)
  const [groupId, setGroupId] = React.useState<number | null>(null)
  const classroomSchoolUnitCode = useSelector(selectClassroomSchoolUnitCode)

  const onSubmitGroups = () => {
    if (selectedGroup) {
      onSelectGroups(selectedGroup.id)
      onClose()
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupId(Number(event.target.value))
  }

  const onConfirm = () => {
    const selected = groups.find(g => g.id === groupId)
    if (selected) {
      setSelectedGroup(selected)
    }
  }

  return (
    <Dialog onClose={onClose} maxWidth="xs" fullWidth open aria-modal="true">
      {Boolean(groups.length) ? (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Stack>
              {selectedGroup ? (
                <Stack px="1.5rem">
                  <Typography>
                    Vill du lägga till{" "}
                    {nbrOfStudents > 1 ? "eleverna" : "eleven"} i gruppen{" "}
                    {selectedGroup.name}?
                  </Typography>
                </Stack>
              ) : (
                <>
                  <Typography px="1.5rem">
                    För att kunna följa{" "}
                    {nbrOfStudents > 1 ? "elevernas" : "elevens"} arbete i
                    uppdraget behöver du lägga till{" "}
                    {nbrOfStudents > 1 ? "eleverna" : "eleven"} i en av dina
                    grupper.
                  </Typography>
                  <List disablePadding>
                    <FormControl fullWidth>
                      <RadioGroup
                        value={groupId}
                        aria-labelledby="choose-group"
                        onChange={handleChange}
                      >
                        {groups.map(group => (
                          <ListItem
                            key={group.id}
                            disablePadding
                            disableGutters
                            divider
                          >
                            <ListItemButton disableGutters>
                              <FormControlLabel
                                sx={{ width: "100%", pl: "1.5rem" }}
                                value={group.id}
                                control={
                                  <Radio
                                    disableFocusRipple
                                    disableTouchRipple
                                    disableRipple
                                  />
                                }
                                label={
                                  <GroupNameColor
                                    option={group.color}
                                    name={group.name}
                                  />
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </List>
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            {selectedGroup ? (
              <Stack direction="row" spacing={2}>
                <Button onClick={() => setSelectedGroup(null)}>
                  Gå tillbaka
                </Button>
                <Button onClick={onSubmitGroups}>Lägg till</Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button onClick={onClose}>Avbryt</Button>
                <Button disabled={!groupId} onClick={onConfirm}>
                  Lägg till
                </Button>
              </Stack>
            )}
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Du ingår inte i någon grupp</DialogTitle>
          <DialogContent>
            <Typography>
              För att kunna följa elevernas arbete i uppdraget behöver du ingå
              som lärare i en grupp med elever. Du kan gå med i en av skolans
              befintliga grupper eller skapa en helt nu grupp där du lägger till
              dig själv och de elever du vill följa.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={2}>
              <Button onClick={onClose}>Avbryt</Button>
              <Button
                component="a"
                href={`${process.env.REACT_APP_BOOKSHELF_URL}/${classroomSchoolUnitCode}/admin/grupper`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Administrera grupper
              </Button>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default GroupSelectorDialog
