import { Goals } from "../../modules/goals/types"

export const howManyGoalsBack = (
  newGoal: string,
  oldGoal: string = "",
  goals: Goals
) => {
  const newGoalIndex = goals.findIndex(g => g.goalRef === newGoal)
  const oldGoalIndex = goals.findIndex(g => g.goalRef === oldGoal)

  return oldGoalIndex - newGoalIndex
}
