import MuiButton, { ButtonProps } from "@mui/material/Button"
import { styled } from "@mui/material"
import { Props } from "./types"
import { Theme } from "@mui/material/styles"

const boxShadowOfFocusedButton =
  "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"

export const StyledMuiButton = styled(
  MuiButton as React.FC<ButtonProps & Props>
)`
  &:focus {
    ${props => {
      let propertyAndValue = ""

      switch (props.variant) {
        case "outlined":
          propertyAndValue = `box-shadow: ${boxShadowOfFocusedButton}`
          break
        case "text":
          propertyAndValue = `background: ${
            (props.theme as Theme).palette.grey[200]
          }`
      }

      return propertyAndValue
    }}
  }
`
