import { styled } from "@mui/material"

export const StyledResultWrapper = styled("div")`
  align-items: end;
  display: flex;
`

export const StyledListItemContent = styled("div")`
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const StyledExtraListWrapper = styled("div")`
  margin-left: 2rem;
  margin-top: 0.5rem;
`

export const StyledTextWrapper = styled("div")`
  display: flex;
`
