import { GoalsProgress } from "../../modules/goalsProgress/types"

export const getAverageGrade = (progress: GoalsProgress) => {
  const done = progress.filter(p => !p.isInProgress)
  const average = done.reduce((acc, curr) => {
    return curr.goalAbilityGrade + acc
  }, 0)

  return average ? Math.round(average / done.length) : average
}
