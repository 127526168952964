import { Theme, useMediaQuery } from "@mui/material";

const useBreakpoints = () => {
  const xs = useMediaQuery<Theme>((theme) => theme.breakpoints.only("xs"));
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.only("sm"));
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.only("md"));
  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.only("lg"));
  const xl = useMediaQuery<Theme>((theme) => theme.breakpoints.only("xl"));

  return { xs, sm, md, lg, xl };
};

export default useBreakpoints;
