import * as actions from "./actions"
import { State, AssignmentStudentCardActions } from "./types"

const initialState: State = {
  hasSentFeedback: false,
  hasSentBackAssignment: false
}

const reducer = (
  state = initialState,
  action: AssignmentStudentCardActions
): State => {
  switch (action.type) {
    case actions.UI.SET_HAS_SENT_FEEDBACK:
      return { ...state, hasSentFeedback: action.payload }
    case actions.UI.SET_HAS_SENT_BACK_ASSIGNMENT:
      return { ...state, hasSentBackAssignment: action.payload }
    default:
      return state
  }
}

export default reducer
