import { createReducer } from "../../shared/tools/redux"
import * as actions from "./actions"
import * as types from "./types"
import { setField } from "../../shared/tools/monocle"

const initialState: types.State = {
  applicationState: types.ApplicationState.idle,
  errorMessageReceivedFromServer: "",
  banners: [],
  latestAction: ""
}

const setApplicationStatus = (state: types.ApplicationState) =>
  setField<types.State>("applicationState", () => state)
const modifySnackbar = setField<
  types.State,
  types.SetSnackbarAction["payload"]
>("snackbar", p => p)
const modifyBanners = setField<types.State, types.SetBannersAction["payload"]>(
  "banners",
  p => p
)

const reducer = createReducer<types.State>(initialState, builder =>
  builder
    .case(
      actions.setApplicationReady,
      setApplicationStatus(types.ApplicationState.ready)
    )
    .case(
      actions.setApplicationReconnecting,
      setApplicationStatus(types.ApplicationState.reconnecting)
    )
    .case(
      actions.setApplicationIdle,
      setApplicationStatus(types.ApplicationState.idle)
    )
    .case(
      actions.setApplicationMount,
      setApplicationStatus(types.ApplicationState.mounting)
    )
    .case(
      actions.setApplicationError,
      setApplicationStatus(types.ApplicationState.error)
    )
    .case(actions.setSnackbar, modifySnackbar)
    .case(actions.setBanners, modifyBanners)
)

export default reducer
