import React, { useState } from "react"
import { ListItemIcon, ListItemText, Checkbox, IconButton } from "@mui/material"
import { AssignmentTask } from "../../modules/assignments/types"
import {
  StyledListItemTextContainer,
  StyledDraggableIcon,
  StyledTextWrapper,
  StyledProgressWrapper,
  StyledListItemWrapper,
  StyledListItem,
  StyledErrorMsg,
  StyledTaskHeader,
  StyledCheckboxTitle,
  StyledTasksHeaderTitle,
  StyledToggleAll
} from "./StyledAssignmentTaskList"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { ReactComponent as OpenQuestionIcon } from "../../assets/images/open-question.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/exercise.svg"
import { ReactComponent as FromBookIcon } from "../../assets/images/page-flip.svg"
import { ReactComponent as ExternalLinkIcon } from "../../assets/images/link-external.svg"
import MoreMenu from "../PopupMenu"
import Progress from "../Progress"
import { AssignmentsTaskProgress } from "../../modules/assignmentsProgress/types"
import { Member, Members } from "../../modules/members/types"
import Dialog from "../Dialog"
import ExercisesProgressDialog from "../ExercisesProgressDialog"
import { MembersWithOnlineStatus } from "../../pages/Exercises/store/types"
import { StyledIconContainer } from "../ExerciseTypeIcon/StyledExerciseTypeIcon"
import ExerciseTypeIcon from "../ExerciseTypeIcon"
import HtmlRenderer from "../HtmlRenderer"
import Button from "../Button"
import sanitizeHtml from "sanitize-html"
import { Delete } from "@mui/icons-material"
import { Features } from "../../modules/classroom/types"

type OwnProps = {
  assignmentTasks: AssignmentTask[]
  setFieldValue: (type: string, value: AssignmentTask[]) => void
  removeTask: (index: number) => void
  editTask: (index: number) => void
  editMode: boolean
  assignmentsTaskProgress: AssignmentsTaskProgress
  assignmentMembers: Member["studliId"][]
  members: Members
  handleRemoveTasks?: (tasks: AssignmentTask[]) => void
  classroomFeatures: Features
}

const ICONS = {
  "open-question": <OpenQuestionIcon aria-label="Öppen fråga" />,
  exercise: <ExerciseIcon aria-label="Övning" />,
  book: <FromBookIcon aria-label="Från boken" />,
  link: <ExternalLinkIcon aria-label="Länk" />,
  goal: <OpenQuestionIcon aria-label="Öppen fråga" />
}

const reorder = (
  list: AssignmentTask[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
const AssignmentTaskList = ({
  assignmentTasks,
  setFieldValue,
  removeTask,
  editTask,
  editMode,
  assignmentsTaskProgress,
  assignmentMembers,
  members,
  handleRemoveTasks,
  classroomFeatures
}: OwnProps) => {
  const [progressDialogData, setProgressDialogData] = useState<{
    done: Members
    inProgress: Members
    notStarted: Members
    title: string
  } | null>(null)

  const [selectedTasks, setSelectedTasks] = useState<AssignmentTask[]>([])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      assignmentTasks,
      result.source.index,
      result.destination.index
    )

    setFieldValue("assignmentTasks", items)
  }

  const handleRemoveClick = () => {
    if (handleRemoveTasks) {
      handleRemoveTasks(selectedTasks)
      setSelectedTasks([])
    }
  }

  const toggleAllTasks = () => {
    if (selectedTasks.length !== assignmentTasks.length) {
      setSelectedTasks([...assignmentTasks])
      return
    }
    setSelectedTasks([])
  }

  const toggleCheckbox = (event: any, checkedTask: AssignmentTask) => {
    event.stopPropagation()
    if (selectedTasks.some(task => task.id === checkedTask.id)) {
      setSelectedTasks([
        ...selectedTasks.filter(task => task.id !== checkedTask.id)
      ])
      return
    }
    setSelectedTasks([...selectedTasks, checkedTask])
  }

  const onOpenProgressDialog = (
    done: Member["studliId"][],
    inProgress: Member["studliId"][],
    notStarted: Member["studliId"][],
    title: string
  ) => {
    const doneWithMember: Members = getMembersByIds(done) as Members
    const inProgressWithMember: Members = getMembersByIds(inProgress) as Members
    const notStartedWithMember: Members = getMembersByIds(notStarted) as Members

    setProgressDialogData({
      done: doneWithMember,
      inProgress: inProgressWithMember,
      notStarted: notStartedWithMember,
      title
    })
  }

  const getMembersByIds = (ids: Member["studliId"][]) =>
    ids.map(getMemberById).filter(Boolean)

  const getMemberById = (memberId: Member["studliId"]) =>
    members.find(member => member.studliId === memberId)

  const getId = (id: number | undefined | string, title: string) => {
    if (id) {
      return id.toString() + title
    }

    return title
  }

  const getMenuItems = (assignmentTask: AssignmentTask, index: number) => {
    const menuItems = [
      {
        action: () => removeTask(index),
        option: "Ta bort"
      }
    ]
    if (!assignmentTask.needsManualModification) {
      menuItems.push({
        action: () => editTask(index),
        option: "Redigera"
      })
    }
    return menuItems
  }

  const findFNflag = classroomFeatures.some(flag => flag === "FN")

  return (
    <>
      {editMode && (
        <StyledTaskHeader selected={selectedTasks.length > 0}>
          <StyledCheckboxTitle>
            <StyledToggleAll
              checked={selectedTasks.length === assignmentTasks.length}
              onClick={toggleAllTasks}
            />
            <StyledTasksHeaderTitle variant="h2">
              {selectedTasks.length
                ? `${selectedTasks.length} uppgifter valda`
                : `Uppgifter (${assignmentTasks.length})`}
            </StyledTasksHeaderTitle>
          </StyledCheckboxTitle>
          {selectedTasks.length > 0 && (
            <IconButton
              aria-label="Ta bort"
              key="delete"
              onClick={handleRemoveClick}
            >
              <Delete />
            </IconButton>
          )}
        </StyledTaskHeader>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any, snapshot: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {assignmentTasks.map((assignmentTask, index) => {
                const done: Member["studliId"][] = []
                const inProgress: Member["studliId"][] = []
                const notStarted: Member["studliId"][] = []
                assignmentMembers.forEach(member => {
                  if (
                    assignmentsTaskProgress.some(
                      progress =>
                        progress.studliId === member &&
                        progress.assignmentTaskId === assignmentTask.id &&
                        progress.done
                    )
                  ) {
                    done.push(member)
                  } else if (
                    assignmentsTaskProgress.some(
                      progress =>
                        progress.studliId === member &&
                        progress.assignmentTaskId === assignmentTask.id &&
                        !progress.done
                    )
                  ) {
                    inProgress.push(member)
                  } else {
                    notStarted.push(member)
                  }
                })

                return (
                  <Draggable
                    isDragDisabled={!editMode}
                    key={assignmentTask.id || index}
                    draggableId={getId(assignmentTask.id, assignmentTask.title)}
                    index={index}
                  >
                    {(provided: any, snapshot: any) => (
                      <StyledListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        component={"div" as any}
                        divider={true}
                        hover={editMode ? 1 : 0}
                        redbar={assignmentTask.needsManualModification ? 1 : 0}
                      >
                        <ListItemIcon>
                          {editMode && (
                            <Checkbox
                              checked={selectedTasks.some(
                                task => task.id === assignmentTask.id
                              )}
                              onClick={event =>
                                toggleCheckbox(event, assignmentTask)
                              }
                            />
                          )}
                          {editMode && (
                            <StyledDraggableIcon aria-label="Drag and drop" />
                          )}
                          <>
                            {assignmentTask.type === "exercise" ? (
                              <ExerciseTypeIcon
                                iconColor="#4f93d5"
                                type={assignmentTask.icon || "exercise"}
                              />
                            ) : (
                              <StyledIconContainer
                                marginTop
                                iconColor="#4f93d5"
                              >
                                {ICONS[assignmentTask.type]}
                              </StyledIconContainer>
                            )}
                          </>
                        </ListItemIcon>
                        <StyledListItemWrapper editMode={editMode}>
                          <ListItemText
                            secondary={
                              assignmentTask.type === "link"
                                ? assignmentTask.link
                                : assignmentTask.exercisePath &&
                                  `(${sanitizeHtml(
                                    assignmentTask.exercisePath
                                  )})`
                            }
                          >
                            <StyledListItemTextContainer>
                              <StyledTextWrapper>
                                <HtmlRenderer html={assignmentTask.title} />
                              </StyledTextWrapper>
                              {assignmentTask.needsManualModification && (
                                <StyledErrorMsg>
                                  Deluppgiften är borttagen ur läromedlet.
                                </StyledErrorMsg>
                              )}
                            </StyledListItemTextContainer>
                          </ListItemText>
                          {editMode && !assignmentTask.needsManualModification && (
                            <>
                              {!findFNflag && (
                                <Button
                                  variant="text"
                                  color="primary"
                                  onClick={() => editTask(index)}
                                >
                                  LÄGG TILL INSTRUKTION
                                </Button>
                              )}
                              <MoreMenu
                                menuItems={getMenuItems(assignmentTask, index)}
                              />
                            </>
                          )}
                          {editMode && assignmentTask.needsManualModification && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => removeTask(index)}
                            >
                              Ta bort
                            </Button>
                          )}
                          {!editMode && (
                            <StyledProgressWrapper>
                              <Progress
                                type="assignmentsTask"
                                onOpenProgressDialog={() =>
                                  onOpenProgressDialog(
                                    done,
                                    inProgress,
                                    notStarted,
                                    assignmentTask.title
                                  )
                                }
                                done={done.length}
                                inProgress={inProgress.length}
                                notStarted={notStarted.length}
                              />
                            </StyledProgressWrapper>
                          )}
                        </StyledListItemWrapper>
                      </StyledListItem>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {progressDialogData !== null && (
        <Dialog
          onClose={() => setProgressDialogData(null)}
          open={progressDialogData !== null}
          maxWidth="sm"
          fullWidth
        >
          <ExercisesProgressDialog
            onClose={() => setProgressDialogData(null)}
            inProgress={
              progressDialogData.inProgress as MembersWithOnlineStatus
            }
            done={progressDialogData.done as MembersWithOnlineStatus}
            notStarted={
              progressDialogData.notStarted as MembersWithOnlineStatus
            }
            type="assignmentsTask"
            title={progressDialogData.title}
          />
        </Dialog>
      )}
    </>
  )
}

export default AssignmentTaskList
