import React from "react"
import { connect } from "react-redux"
import { StyledBannerContainer, StyledIconContainer } from "./StyledBanners"
import { RootState } from "../../modules/store"
import { Banners, BannerAction } from "../../modules/application/types"
import { selectBanners } from "../../modules/application/selectors"
import * as applicationActions from "../../modules/application/actions"
import { Alert, AlertTitle } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

type StateProps = {
  banners: Banners
}

type DispatchProps = {
  setBanners: (banners: Banners) => void
}

type Props = DispatchProps & StateProps

const Banner = ({ banners, setBanners }: Props) => {
  const handleActionClick = (action: BannerAction, bannerIndex: number) => {
    const newBanners = [...banners]
    newBanners.splice(bannerIndex, 1)
    setBanners(newBanners)
    action.action()
  }

  const handleClose = (bannerIndex: number) => {
    const newBanners = [...banners]
    newBanners.splice(bannerIndex, 1)
    setBanners(newBanners)
  }

  if (banners.length === 0) {
    return null
  }

  return (
    <StyledBannerContainer>
      {banners.map((banner, bannerIndex) => (
        <Alert
          severity={banner.severity}
          key={bannerIndex}
          action={
            <>
              {banner.actions.map((action, index) => (
                <StyledIconContainer
                  key={index}
                  onClick={() => handleActionClick(action, bannerIndex)}
                >
                  {action.icon}
                </StyledIconContainer>
              ))}
              <StyledIconContainer>
                <CloseIcon onClick={() => handleClose(bannerIndex)} />
              </StyledIconContainer>
            </>
          }
        >
          {banner.title && <AlertTitle>{banner.title}</AlertTitle>}
          {banner.message}
        </Alert>
      ))}
    </StyledBannerContainer>
  )
}

const mapDispatchToProps = {
  setBanners: applicationActions.setBanners
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    banners: selectBanners(state)
  }
}
const ConnectedBanner = connect(mapStateToProps, mapDispatchToProps)(Banner)

export default ConnectedBanner
