import { RootState } from "../store"
import { Exercises } from "./types"
import { createSelector } from "reselect"
import {
  findById,
  pathsForExercises
} from "../../pages/Exercises/common/helpers"

export const selectAllExercises = (state: RootState): Exercises =>
  state.exercises.exercises

export const selectTemaToc = (state: RootState): Exercises =>
  state.exercises.temaToc

export const selectExercisesLength = (state: RootState): number =>
  state.exercises.exercises.length

export const selectTemaTocLength = (state: RootState): number =>
  state.exercises.temaToc.length

export const selectExerciseFromRoute = createSelector(
  [
    selectAllExercises,
    ({ location }: RootState) => location.payload.exerciseId
  ],
  (exercises, exerciseId) => {
    const exercise = findById(exercises, exerciseId, "id")

    return exercise
  }
)
export const selectExercisePaths = createSelector(
  [selectAllExercises],
  exercises => pathsForExercises(exercises, [])
)
