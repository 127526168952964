import * as types from "./types"
import { ReceiveActivateProductKeysResponseAction } from "../users/types"
import * as userActions from "../users/actions"
import { USER_ROLE } from "../users/constants"
import * as communicationTypes from "../communication/types"

export const products: ReceiveActivateProductKeysResponseAction = {
  echo: "redirect",
  type: userActions.RESPONSE.ACTIVATE_PRODUCT_KEYS_RESPONSE,
  payload: {
    keys: [],
    activeProducts: [
      {
        studliId: 1,
        products: ["Tomoyo Matematik"],
        codes: [
          {
            code: "972D-NMzg",
            activationType: "SingleUser",
            activationsLeft: 0,
            expiresAt: "2028-05-11",
            sesamGroups: [
              "35983-02_FavoritMatematik_1A",
              "35984-02_FavoritMatematik_1B",
              "35985-02_FavoritMatematik_1A_L",
              "35989-02_FavoritMatematik_1B_L",
              "37197-02_FavoritMatematik_1A_Mera",
              "37291-02_FavoritMatematik_1B_Mera"
            ],
            active: true,
            activatedByOtherStudliId: 1
          }
        ]
      },
      {
        studliId: 2,
        products: [],
        codes: [
          {
            code: "972D-NMzg",
            activationType: "SingleUser",
            activationsLeft: 0,
            expiresAt: "2028-05-11",
            sesamGroups: [
              "35983-02_FavoritMatematik_1A",
              "35984-02_FavoritMatematik_1B",
              "35985-02_FavoritMatematik_1A_L",
              "35989-02_FavoritMatematik_1B_L",
              "37197-02_FavoritMatematik_1A_Mera",
              "37291-02_FavoritMatematik_1B_Mera"
            ],
            active: true,
            activatedByOtherStudliId: 1
          }
        ]
      },
      {
        studliId: 3,
        products: ["Tomoyo Matematik"],
        codes: [
          {
            code: "972D-NMzg",
            activationType: "SingleUser",
            activationsLeft: 0,
            expiresAt: "2028-05-11",
            sesamGroups: [
              "35983-02_FavoritMatematik_1A",
              "35984-02_FavoritMatematik_1B",
              "35985-02_FavoritMatematik_1A_L",
              "35989-02_FavoritMatematik_1B_L",
              "37197-02_FavoritMatematik_1A_Mera",
              "37291-02_FavoritMatematik_1B_Mera"
            ],
            active: true,
            activatedByOtherStudliId: 1
          }
        ]
      },
      {
        studliId: 4,
        products: [],
        codes: [
          {
            code: "972D-NMzg",
            activationType: "SingleUser",
            activationsLeft: 0,
            expiresAt: "2028-05-11",
            sesamGroups: [
              "35983-02_FavoritMatematik_1A",
              "35984-02_FavoritMatematik_1B",
              "35985-02_FavoritMatematik_1A_L",
              "35989-02_FavoritMatematik_1B_L",
              "37197-02_FavoritMatematik_1A_Mera",
              "37291-02_FavoritMatematik_1B_Mera"
            ],
            active: true,
            activatedByOtherStudliId: 1
          }
        ]
      },
      {
        studliId: 5,
        products: ["Tomoyo Matematik"],
        codes: [
          {
            code: "972D-NMzg",
            activationType: "SingleUser",
            activationsLeft: 0,
            expiresAt: "2028-05-11",
            sesamGroups: [
              "35983-02_FavoritMatematik_1A",
              "35984-02_FavoritMatematik_1B",
              "35985-02_FavoritMatematik_1A_L",
              "35989-02_FavoritMatematik_1B_L",
              "37197-02_FavoritMatematik_1A_Mera",
              "37291-02_FavoritMatematik_1B_Mera"
            ],
            active: true,
            activatedByOtherStudliId: 1
          }
        ]
      }
    ]
  }
}
export const members: types.Member[] = [
  {
    studliId: 1,
    firstName: "john",
    lastName: "doe",
    username: "",
    isPasswordChangeable: true,
    type: USER_ROLE.STUDENT
  },
  {
    studliId: 2,
    firstName: "leonardo",
    lastName: "davinci",
    username: "",
    isPasswordChangeable: true,
    type: USER_ROLE.STUDENT
  },
  {
    studliId: 3,
    firstName: "adam",
    lastName: "persson",
    username: "",
    isPasswordChangeable: true,
    type: USER_ROLE.STUDENT
  },
  {
    studliId: 4,
    firstName: "test",
    lastName: "testsson",
    username: "",
    isPasswordChangeable: true,
    type: USER_ROLE.STUDENT
  },
  {
    studliId: 5,
    firstName: "luciano",
    lastName: "pavarotti",
    username: "",
    isPasswordChangeable: true,
    type: USER_ROLE.STUDENT
  }
]

export const updatedMembers: { members: types.Members } = {
  members: [
    {
      studliId: 1,
      firstName: "john",
      lastName: "doe",
      username: "",
      isPasswordChangeable: true,
      type: USER_ROLE.STUDENT
    },
    {
      studliId: 2,
      firstName: "leonardo",
      lastName: "davinci",
      username: "",
      isPasswordChangeable: true,
      type: USER_ROLE.STUDENT
    },
    {
      studliId: 3,
      firstName: "adam",
      lastName: "persson",
      username: "",
      isPasswordChangeable: true,
      type: USER_ROLE.STUDENT
    },
    {
      studliId: 4,
      firstName: "test",
      lastName: "testsson",
      username: "",
      isPasswordChangeable: true,
      type: USER_ROLE.STUDENT
    },
    {
      studliId: 5,
      firstName: "luciano",
      lastName: "pavarotti",
      username: "",
      isPasswordChangeable: true,
      type: USER_ROLE.STUDENT
    }
  ]
}

export const updatedMember: types.Member = {
  studliId: 1,
  firstName: "john",
  lastName: "doe",
  username: "",
  isPasswordChangeable: true,
  type: USER_ROLE.STUDENT
}

export const updateMemberError: communicationTypes.CommunicationError = {
  id: 123,
  message: "something went wrong",
  reporter: "Frontend"
}

export const addedMember = {
  isOnline: false,
  lastName: "Nisse",
  goalAbilityGrade: 0,
  isPasswordChangeable: false,
  goalActiveTimeMilliseconds: 0,
  hasProduct: true,
  picture: "",
  studliId: 12,
  firstName: "Nisse",
  type: USER_ROLE.STUDENT,
  goalTitle: "",
  goalRef: "4",
  username: "ArnoldSchwarzenegger",
  completedGoals: [{ goalRef: "1", goalAbilityGrade: 1 }],
  id: 707
}

export const student: types.Member = {
  studliId: 10,
  firstName: "Donald",
  lastName: "Duck",
  username: "dd",
  type: USER_ROLE.STUDENT,
  picture:
    "https://previews.123rf.com/images/chutimakuanamon/chutimakuanamon1804/chutimakuanamon180400084/104747300-donald-duck-illustration-cartoon-head-donald-happy.jpg",
  email: "wojtek.jasinski@studentlitteratur.se",
  isPasswordChangeable: true
}
