import * as types from "./types"

export enum UI {
  SET_HAS_SENT_FEEDBACK = "SET_HAS_SENT_FEEDBACK",
  SET_HAS_SENT_BACK_ASSIGNMENT = "SET_HAS_SENT_BACK_ASSIGNMENT"
}

export const setHasSentFeedback = (
  hasSentFeedback: boolean
): types.SetHasSentFeedbackAction => {
  return { type: UI.SET_HAS_SENT_FEEDBACK, payload: hasSentFeedback }
}

export const setHasSentBackAssignment = (
  hasSentBackAssignment: boolean
): types.SetHasSentBackAssignmentAction => {
  return {
    type: UI.SET_HAS_SENT_BACK_ASSIGNMENT,
    payload: hasSentBackAssignment
  }
}
