import React from "react"
import { DialogTitle, DialogContent, DialogActions } from "@mui/material"
import Button from "../Button"

type Props = {
  title: string
  body?: string
  bodyContent?: React.ReactNode
  confirmLabel: string
  cancelLabel: string
  onCancel: () => void
  onConfirm: any
  ariaTitleId: string
  ariaDescId: string
  noDialogPadding?: boolean
}

const ConfirmationDialog = ({
  title,
  body,
  confirmLabel,
  cancelLabel,
  onCancel,
  onConfirm,
  ariaDescId,
  ariaTitleId,
  bodyContent,
  noDialogPadding
}: Props) => {
  return (
    <>
      <DialogTitle id={ariaTitleId}>{title}</DialogTitle>
      <DialogContent
        sx={{ px: noDialogPadding ? "0" : "1.5rem" }}
        id={ariaDescId}
      >
        {Boolean(body) && body}
        {Boolean(bodyContent) && bodyContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {confirmLabel}
        </Button>
      </DialogActions>
    </>
  )
}

export default ConfirmationDialog
