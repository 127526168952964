import { IconButton, Typography, CircularProgress, styled } from "@mui/material"
import { ReactComponent as StopRecordingIcon } from "../../assets/images/recorder-stop.svg"

export const StyledButton = styled(IconButton)`
  height: 32px;
  width: 32px;
  border-radius: 100%;
  border: 0;
  background: transparent;
  padding: 0;
  color: black;
  z-index: 1;
  margin-left: 0.25rem;
`

export const StyledErrorMessage = styled(Typography)`
  color: red;
`

export const StyledButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledUploadingText = styled("div")`
  text-align: center;
  width: 100%;
`

export const StyledCircularProgress = styled(CircularProgress)`
  width: 30px !important;
  height: 30px !important;
`

export const Wrapper = styled("div")<{
  to?: number
  from?: number
  fullWidth?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e6e6e6;
  position: relative;
  border-radius: 1.25rem;
  padding: 0.5rem;
  height: 40px;
  z-index: 2;
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "min-width: 200px;")};
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #d3d3d3;
    width: ${({ to, from }) => (to && from ? (from / to) * 100 : 0)}%;
  }
`

export const Time = styled(Typography)`
  margin: 0 auto;
  z-index: 1;
`

export const StyledStopRecordingIcon = styled(StopRecordingIcon)`
  color: #d0021b;
`
