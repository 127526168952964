import { styled } from "@mui/material"
import { StateProps } from "./types"

/**
 * If authenticatedUser is undefined set visibility: hidden so it takes up it's space and prevents stuff from "jumping around"
 * as soon as authenticatedUser gets updated with a value set visibility: visible.
 */
export const StyledAvatarContainer = styled("div")`
  visibility: ${(props: StateProps) => {
    return props.authenticatedUser ? "visible" : "hidden"
  }};
`
