import { takeLatest, put, all, take, select } from "redux-saga/effects"
import { sendRequest } from "../communication/actions"
import * as notificationsActions from "./actions"
import * as communicationActions from "../communication/actions"
import * as notificationsTypes from "./types"
import { MODULE_NAME } from "./constants"
import * as exerciseActions from "../exercises/actions"
import * as assignmentActions from "../assignments/actions"
import { selectAllNotifications } from "./selectors"
import { selectClassroomActiveProductInfo } from "../products/selectors"
import { generateNotificationsForExercisesThatAreReplaced } from "./helper"
import { Product } from "../products/types"

function* getAllNotificationsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      notificationsActions.SERVER_MESSAGE_ACTION.GET_ALL_NOTIFICATIONS_REQUEST
    )
  )
}

function* watchGetAllNotificationsSaga() {
  yield takeLatest(
    notificationsActions.getAllNotifications,
    getAllNotificationsSaga
  )
}

function* markNotificationAsReadSaga(
  message: notificationsTypes.MarkNotificationAsReadAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      notificationsActions.SERVER_MESSAGE_ACTION
        .MARK_NOTIFICATION_AS_READ_REQUEST,
      message.payload
    )
  )
}

function* watchMarkNotificationAsReadSaga() {
  yield takeLatest(
    notificationsActions.markNotificationAsRead,
    markNotificationAsReadSaga
  )
}

export function* handleReceiveNotificationsResponseSaga(
  message: notificationsTypes.ReceiveNotificationsResponseMessagesTypes
) {
  const { type, action, payload, error } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case notificationsActions.SERVER_MESSAGE_ACTION
        .GET_ALL_NOTIFICATIONS_RESPONSE:
        if (error) {
          return
        }
        yield put(
          notificationsActions.addNotifications(
            payload as notificationsTypes.ReceiveNotificationsAction["payload"]
          )
        )
        break
      case notificationsActions.SERVER_MESSAGE_ACTION
        .MARK_NOTIFICATION_AS_READ_RESPONSE:
        if (error) {
          return
        }
        yield put(
          notificationsActions.markNotificationsAsRead(
            payload as notificationsTypes.ReceiveReadNotificationsAction["payload"]
          )
        )
        break
    }
  }
}

function* watchExercises() {
  const {
    exercises,
    assignments
  }: {
    exercises: ReturnType<typeof exerciseActions.setExercises>
    assignments: ReturnType<typeof assignmentActions.setAssignments>
  } = yield all({
    exercises: take(exerciseActions.setExercises),
    assignments: take(assignmentActions.setAssignments)
  })

  const notifications = yield select(selectAllNotifications)
  const activeProduct: Product = yield select(selectClassroomActiveProductInfo)

  yield all(
    generateNotificationsForExercisesThatAreReplaced(
      exercises.payload.toc,
      assignments.payload,
      notifications,
      activeProduct
    ).map(n => put(notificationsActions.addNotification(n)))
  )
}

function* watchReceiverSaga() {
  yield takeLatest(
    communicationActions.RECEIVE_RESPONSE,
    handleReceiveNotificationsResponseSaga
  )
}

export function* rootSaga() {
  yield all([
    watchGetAllNotificationsSaga(),
    watchMarkNotificationAsReadSaga(),
    watchReceiverSaga(),
    watchExercises()
  ])
}
