import { ParentOrLeafNode, ParentNode } from "../TreeTypes"

export const countNumberOfNodesBelowNode = (node: ParentOrLeafNode) => {
  let count = 0
  const tra = (item: ParentOrLeafNode) => {
    if (isParent(item) && item.children) {
      item.children.forEach((entity: ParentOrLeafNode) => {
        tra(entity)
      })
    } else {
      return (count += 1)
    }
  }
  tra(node)

  return count
}

export const isParent = (node: ParentOrLeafNode): node is ParentNode => {
  if ((node as ParentNode).children) {
    return true
  }

  return false
}
