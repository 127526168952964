import { styled } from "@mui/material"

export const StyledLoaderWrapper = styled("div")`
  display: flex;
  height: 85vh;
`

export const StyledFilterWrapper = styled("div")`
  width: 8rem;
  margin-left: 1rem;
  ${props => props.theme.breakpoints.up("sm")} {
    margin-left: 1.5rem;
  }
  margin-bottom: 1rem;
  display: inline-block;
`
export const StyledSelectPdfWrapper = styled("div")`
  width: 14rem;
  margin-bottom: 1rem;
  display: inline-block;
`
