import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectClassroomId } from "../../modules/classroom/selectors"
import { setImportedTest } from "../../modules/formative/actions"
import { selectAllPreparedTests } from "../../modules/formative/selectors"
import {
  FormativeQuestion,
  PartialFormativeTest
} from "../../modules/formative/types"
import {
  gotoRoute,
  ROUTE_FORMATIVA_FRAGOR_LAGGTILL
} from "../../modules/router/actions"

export interface Folder {
  id: any
  title: string
  tests: PartialFormativeTest[]
}

type Props = "prepared" | "import"

function useImportFormativeTests(
  type: Props
): [Folder[], (test: PartialFormativeTest) => void] {
  const preparedTests = useSelector(selectAllPreparedTests)
  const dispatch = useDispatch()
  const classroomId = useSelector(selectClassroomId)
  const [folders, setFolders] = useState<Folder[]>([])
  useEffect(() => {
    if (type === "prepared") {
      const test = getFoldersFromPreparedTests()
      setFolders(test)
    }
    // eslint-disable-next-line
  }, [type])

  const onClickTest = (test: PartialFormativeTest) => {
    dispatch(
      setImportedTest({
        title: test.title,
        instruction: test.instruction,
        endQuestion: test.endQuestion,
        questions: (test.questions || []).map(q => ({
          ...q
        })) as Partial<FormativeQuestion>[]
      })
    )

    dispatch(
      gotoRoute(ROUTE_FORMATIVA_FRAGOR_LAGGTILL, {
        classroomId
      })
    )
  }

  const getFoldersFromPreparedTests = () => {
    return preparedTests.reduce((res, item) => {
      const existingFolder = res.findIndex(i => i.id === item.folder)

      if (existingFolder > -1) {
        const folder = res[existingFolder]

        return Object.assign([], res, {
          [existingFolder]: {
            ...folder,
            tests: [...folder.tests, item]
          }
        })
      }

      return [...res, { id: item.folder, title: item.folder, tests: [item] }]
    }, [] as Folder[])
  }

  return [folders, onClickTest]
}

export default useImportFormativeTests
