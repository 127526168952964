import React from "react"
import { DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { setPreviewUrl } from "../../modules/assignments/actions"
import { selectPreviewUrl } from "../../modules/assignments/selectors"

import Dialog from "../Dialog"
import Button from "../Button"

const PreviewModal: React.FC<{}> = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const previewUrl = useSelector(selectPreviewUrl)

  if (!previewUrl) {
    return null
  }

  return (
    <Dialog
      aria-labelledby="modal-title"
      open={!!previewUrl}
      onClose={() => dispatch(setPreviewUrl())}
    >
      <DialogTitle id="modal-title">Förhandsgranska</DialogTitle>
      <DialogContent>
        Öppna förhandsgranskningen i en ny flik genom att trycka på knappen
        nedan.
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(setPreviewUrl())}>Avbryt</Button>
        <Button
          color="primary"
          onClick={() => {
            dispatch(setPreviewUrl())
            window.open(previewUrl, "_blank", "noreferrer noopener")
          }}
        >
          Öppna i ny flik
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewModal
