import React from "react"
import { StyledStartContent } from "./StyledStart"
import StartCards from "./StartCards"

const Start: React.FC = () => {
  return (
    <StyledStartContent>
      <StartCards />
    </StyledStartContent>
  )
}

export default Start
