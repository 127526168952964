import DialogContent, { DialogContentProps } from "@mui/material/DialogContent"
import DialogActions, { DialogActionsProps } from "@mui/material/DialogActions"
import { styled } from "@mui/material"

export const StyledDialogContent = styled(
  DialogContent as React.FC<DialogContentProps>
)`
  min-width: 100%;
`
export const StyledDialogActions = styled(
  DialogActions as React.FC<DialogActionsProps>
)`
  margin-top: 3em;
`
