import React, { useState, useEffect, useCallback } from "react"
import {
  FormativeTest,
  FormativeProgressionArr,
  FormativeProgression
} from "../../modules/formative/types"
import { PossibleStatusActions } from "../../pages/FormativeQuestions/types"
import {
  StyledStatusIconButton,
  StyledNoMembers
} from "../FormativeQuestionsListItem/StyledFormativeQuestionsListItem"
import {
  StyledListItem,
  StyledListItemContent,
  StyledPrimaryText,
  StyledPrimaryTextContainer,
  StyledListItemMoreMenu,
  StyledListItemActionStatus
} from "./StyledFormativeQuestionsTableAsList"
import { ReactComponent as PlayIcon } from "../../assets/images/play.svg"
import { ReactComponent as StopIcon } from "../../assets/images/stop.svg"
import { ReactComponent as RedoIcon } from "../../assets/images/redo.svg"
import FormativeStatusTag from "../../pages/FormativeQuestions/FormativeStatusTag"
import Progress, { SimpleProgress } from "../Progress"
import MoreMenu from "../PopupMenu"
import {
  showIconButtonStatuses,
  iconButtonAriaLabels
} from "../FormativeQuestionsTable/FormativeQuestionsTableRow"
import { ProgressForBar } from "../FormativeQuestionsTable/types"
import {
  getLatestSession,
  getProgressForBar
} from "../../pages/FormativeQuestions/helpers"
import { MenuItems } from "../PopupMenu/PopupMenu"
import moment from "moment"
import { ListItemButton } from "@mui/material"
import { useSelector } from "react-redux"
import { selectAllMembers } from "../../modules/members/selectors"
type Props = {
  test: FormativeTest
  onActionStatusClicked: (
    event: any,
    status: PossibleStatusActions,
    testName: string,
    testId: number
  ) => void
  moreMenuOptions: MenuItems
  progress: FormativeProgressionArr
  onProgressDialogClick: (progress: ProgressForBar, title: string) => void
  onRowClick: (testId: number) => void
  type: "studentCard" | "class"
}
const FormativeQuestionsTableAsListItem = ({
  test,
  onActionStatusClicked,
  moreMenuOptions,
  progress,
  onProgressDialogClick,
  onRowClick,
  type
}: Props) => {
  const [, updateState] = useState<{}>({})
  const forceUpdate = useCallback(() => updateState({}), [])
  const members = useSelector(selectAllMembers)
  const [progressForTest, setProgressForTest] = useState<ProgressForBar | null>(
    null
  )

  useEffect(() => {
    const session = getLatestSession(test.sessions)
    if (progress && session) {
      const filteredProgress = progress.filter(p =>
        members.some(m => m.studliId === p.memberId)
      )
      const progressForBar = getProgressForBar(session, filteredProgress)
      setProgressForTest(progressForBar)
    }
  }, [progress, test, members])

  const renderIcon = () => {
    switch (test.status) {
      case "published":
        return <PlayIcon />
      case "ongoing":
        return <StopIcon />
      case "stopped":
        return <RedoIcon />
      default:
        return ""
    }
  }

  const renderIconButton = () => {
    switch (test.status) {
      case "published":
      case "ongoing":
      case "stopped":
        if (test.status === "stopped") {
          const latestSession = getLatestSession(test.sessions)
          const stoppedAt = moment(latestSession.endedAt)
          if (stoppedAt.isAfter()) {
            return (
              <StyledStatusIconButton
                onClick={event =>
                  onActionStatusClicked(
                    event,
                    "ongoing" as PossibleStatusActions,
                    test.title,
                    test.id
                  )
                }
                aria-label={
                  iconButtonAriaLabels[test.status as PossibleStatusActions]
                }
              >
                <StopIcon />
              </StyledStatusIconButton>
            )
          }
        }
        return (
          <StyledStatusIconButton
            onClick={event =>
              onActionStatusClicked(
                event,
                test.status as PossibleStatusActions,
                test.title,
                test.id
              )
            }
            aria-label={
              iconButtonAriaLabels[test.status as PossibleStatusActions]
            }
          >
            {renderIcon()}
          </StyledStatusIconButton>
        )
      default:
        return ""
    }
  }

  const getStatus = (p: FormativeProgression) => {
    if (p.submittedAt) {
      return "done"
    }
    if (p.startedAt) {
      return "inProgress"
    }
    return "notStarted"
  }

  const latestSession = getLatestSession(test.sessions)
  const studentProgress = latestSession
    ? progress.find(p => p.sessionId === latestSession.id)
    : null
  return (
    <>
      <StyledListItem hasonclick={test.status === "draft" ? 0 : 1}>
        <ListItemButton onClick={() => onRowClick(test.id)}>
          <StyledListItemActionStatus>
            {showIconButtonStatuses.some(
              buttonStatus => buttonStatus === test.status
            ) &&
              type === "class" &&
              renderIconButton()}
          </StyledListItemActionStatus>
          <StyledListItemContent>
            <StyledPrimaryTextContainer>
              <StyledPrimaryText>{test.title} </StyledPrimaryText>
              {(latestSession === undefined ||
                latestSession.members.length === 0) && (
                <StyledNoMembers>Testet saknar elever</StyledNoMembers>
              )}
            </StyledPrimaryTextContainer>
            <FormativeStatusTag
              endsAt={(latestSession && latestSession.endedAt) || ""}
              status={test.status}
              numberOfSessions={test.sessions.length}
              onEndedCounter={forceUpdate}
            />
            {progressForTest && test.status !== "draft" && type === "class" && (
              <Progress
                onOpenProgressDialog={() =>
                  onProgressDialogClick(progressForTest, test.title)
                }
                done={progressForTest.done.length}
                type="formativeQuestions"
                inProgress={progressForTest.inProgress.length}
                notStarted={progressForTest.notStarted.length}
              />
            )}
            {type === "studentCard" &&
              test.status !== "draft" &&
              studentProgress && (
                <SimpleProgress
                  type="assignment"
                  status={getStatus(studentProgress)}
                />
              )}
          </StyledListItemContent>
          {type === "class" && (
            <StyledListItemMoreMenu>
              <MoreMenu menuItems={moreMenuOptions} />
            </StyledListItemMoreMenu>
          )}
        </ListItemButton>
      </StyledListItem>
    </>
  )
}

export default FormativeQuestionsTableAsListItem
