import * as types from "./types"
import * as memberMockData from "../members/mockdata"
import { SelectOptions } from "../../components/Select/Select"

export const goals: types.Goals = [
  {
    title: "Mål nummer ett, i ettan",
    description: "Beskrivning av mål ett",
    goalRef: "1",
    order: 0,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [2, 3],
    done: [1, 4, 5, 6]
  },
  {
    title: "Mål nummer två, i ettan X",
    description: "",
    goalRef: "122333444455555",
    order: 1,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [1, 123456, 3],
    done: [4, 5]
  },
  {
    title: "Mål nummer ett, i tvåan",
    description: "",
    goalRef: "3",
    order: 2,
    grade: 2,
    gradeLabel: "Åk 2",
    inProgress: [1, 2],
    done: [4, 5]
  },
  {
    title: "Mål nummer ett, i trean",
    description: "",
    goalRef: "4",
    order: 3,
    grade: 3,
    gradeLabel: "Åk 3",
    inProgress: [1],
    done: [6]
  }
]
export const goalsWithMembers: types.GoalsWithMembers = [
  {
    title: "Mål nummer ett, i ettan",
    goalRef: "1",
    order: 0,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: memberMockData.members,
    done: memberMockData.members
  },
  {
    title: "Mål nummer två, i ettan",
    goalRef: "2",
    order: 1,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: memberMockData.members,
    done: memberMockData.members
  },
  {
    title: "Mål nummer ett, i tvåan",
    goalRef: "3",
    order: 2,
    grade: 2,
    gradeLabel: "Åk 2",
    inProgress: memberMockData.members,
    done: memberMockData.members
  },
  {
    title: "Mål nummer ett, i trean",
    goalRef: "4",
    order: 3,
    grade: 3,
    gradeLabel: "Åk 3",
    inProgress: memberMockData.members,
    done: memberMockData.members
  }
]
export const options: SelectOptions = [
  {
    id: "g1",
    value: 1,
    label: "Årskurs 1"
  },
  {
    id: "g2",
    value: 2,
    label: "Årskurs 2"
  },
  {
    id: "g3",
    value: 3,
    label: "Årskurs 3"
  }
]
export const pdfOptions: SelectOptions = [
  {
    id: "p1",
    value:
      "https://storage.googleapis.com/studli-prod-tecla-media/teacher-material/Tomoyo_innehall_ak_1.pdf",
    label: "Målöversikt Årskurs 1"
  },
  {
    id: "p2",
    value:
      "https://storage.googleapis.com/studli-prod-tecla-media/teacher-material/Tomoyo_innehall_ak_2.pdf",
    label: "Målöversikt Årskurs 2"
  },
  {
    id: "p3",
    value:
      "https://storage.googleapis.com/studli-prod-tecla-media/teacher-material/Tomoyo_innehall_ak_3.pdf",
    label: "Målöversikt Årskurs 3"
  },
  {
    id: "p4",
    value:
      "https://storage.googleapis.com/studli-prod-tecla-media/teacher-material/Tomoyo_innehall_ak_4.pdf",
    label: "Målöversikt Årskurs 4"
  },
  {
    id: "p5",
    value:
      "https://storage.googleapis.com/studli-prod-tecla-media/teacher-material/Tomoyo_innehall_ak_5.pdf",
    label: "Målöversikt Årskurs 5"
  },
  {
    id: "p6",
    value:
      "https://storage.googleapis.com/studli-prod-tecla-media/teacher-material/Tomoyo_innehall_ak_6.pdf",
    label: "Målöversikt Årskurs 6"
  }
]

export const goalsWithUpdatedAddedMember = [
  {
    title: "Mål nummer ett, i ettan",
    goalRef: "1",
    order: 0,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [2, 3],
    done: [1, 4, 5, 6, 12]
  },
  {
    title: "Mål nummer två, i ettan X",
    goalRef: "122333444455555",
    order: 1,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [1, 123456, 3],
    done: [4, 5]
  },
  {
    title: "Mål nummer ett, i tvåan",
    goalRef: "3",
    order: 2,
    grade: 2,
    gradeLabel: "Åk 2",
    inProgress: [1, 2],
    done: [4, 5, 12]
  },
  {
    title: "Mål nummer ett, i trean",
    goalRef: "4",
    order: 3,
    grade: 3,
    gradeLabel: "Åk 3",
    inProgress: [1, 12],
    done: [6]
  }
]

export const goalsWithTwoGradesWithSameInProgressCount = [
  {
    title: "Mål nummer ett, i ettan",
    goalRef: "1",
    order: 0,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [2, 3],
    done: [1, 4, 5, 6, 12]
  },
  {
    title: "Mål nummer två, i ettan X",
    goalRef: "122333444455555",
    order: 1,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [1, 123456, 3],
    done: [4, 5]
  },
  {
    title: "Mål nummer ett, i tvåan",
    goalRef: "3",
    order: 2,
    grade: 2,
    gradeLabel: "Åk 2",
    inProgress: [1, 2, 234, 7, 10],
    done: [4, 5, 12]
  },
  {
    title: "Mål nummer ett, i trean",
    goalRef: "4",
    order: 3,
    grade: 3,
    gradeLabel: "Åk 3",
    inProgress: [1, 12],
    done: [6]
  }
]

export const goalsWithGradeMoreStudentsInGrade2 = [
  {
    title: "Mål nummer ett, i ettan",
    goalRef: "1",
    order: 0,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [2, 3],
    done: [1, 4, 5, 6, 12]
  },
  {
    title: "Mål nummer två, i ettan X",
    goalRef: "122333444455555",
    order: 1,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [1, 123456, 3],
    done: [4, 5]
  },
  {
    title: "Mål nummer ett, i tvåan",
    goalRef: "3",
    order: 2,
    grade: 2,
    gradeLabel: "Åk 2",
    inProgress: [1, 2, 234, 7, 10, 23],
    done: [4, 5, 12]
  },
  {
    title: "Mål nummer ett, i trean",
    goalRef: "4",
    order: 3,
    grade: 3,
    gradeLabel: "Åk 3",
    inProgress: [1, 12],
    done: [6]
  }
]

export const goalsWithNoStudentsInProgress = [
  {
    title: "Mål nummer ett, i ettan",
    goalRef: "1",
    order: 0,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [],
    done: []
  },
  {
    title: "Mål nummer två, i ettan X",
    goalRef: "122333444455555",
    order: 1,
    grade: 1,
    gradeLabel: "Åk 1",
    inProgress: [],
    done: []
  },
  {
    title: "Mål nummer ett, i tvåan",
    goalRef: "3",
    order: 2,
    grade: 2,
    gradeLabel: "Åk 2",
    inProgress: [],
    done: []
  },
  {
    title: "Mål nummer ett, i trean",
    goalRef: "4",
    order: 3,
    grade: 3,
    gradeLabel: "Åk 3",
    inProgress: [],
    done: []
  }
]
