import * as types from "./types"

export enum UI {
  OPEN_MODAL = "OPEN_MODAL",
  SET_IS_LEAVING = "SET_IS_LEAVING",
  CLOSE_MODAL = "CLOSE_MODAL"
}

export const setIsModalOpen = (
  message: string,
  canLeave: Function
): types.SetEditFormAction => {
  return { type: UI.OPEN_MODAL, payload: { message, canLeave } }
}

export const setCloseModal = () => {
  return { type: UI.CLOSE_MODAL }
}
