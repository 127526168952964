/**
 * These select* functions expose parts of the state. Play nice with redux select() and inside mapStateToProps etc.
 */

import { RootState } from "../store"
import { Stickers, Features, ClassroomTip, Group } from "./types"
import { createSelector } from "reselect"
import { selectClassroomActiveProductInfo } from "../products/selectors"

export const selectClassroomName = (store: RootState): string =>
  store.classroom.name

export const selectClassroomCode = (store: RootState): string =>
  store.classroom.classroomCode

export const selectClassroomId = (store: RootState): number =>
  store.classroom.classroomId

export const selectClassroomSchoolUnitCode = (store: RootState): string =>
  store.classroom.schoolUnitCode

export const selectClassroomStickers = (store: RootState): Stickers =>
  store.classroom.stickers

export const selectClassroomFeatures = (store: RootState): Features =>
  store.classroom.classroomFeatures

export const selectHasFormativeTests = (store: RootState): Boolean =>
  store.classroom.classroomFeatures.some(
    feature => feature === "FORMATIVE_TESTS"
  )

export const selectCurrentGroups = (store: RootState): Group[] => {
  const groupIds = store.classroom.activeGroupIds
  const groups = store.classroom.groups
  const currentGroups = groups.filter(group => groupIds.includes(group.id))
  return currentGroups
}

export const selectAllGroups = (store: RootState): Group[] =>
  store.classroom.groups.sort((a, b) => a.name.localeCompare(b.name))

export const selectAllSchoolUnitGroups = (store: RootState): Group[] =>
  store.classroom.schoolUnitGroups.sort((a, b) => a.name.localeCompare(b.name))

export const selectAllActiveGroups = (store: RootState): Group[] => {
  if (store.classroom.activeGroupIds.length === 0) {
    return store.classroom.groups
  }
  const groupIds = store.classroom.activeGroupIds
  const groups = store.classroom.groups
  const currentGroups = groups
    .filter(group => groupIds.includes(group.id))
    .sort((a, b) => a.name.localeCompare(b.name))
  return currentGroups
}

export const selectClassroomIsTest = (store: RootState): boolean =>
  store.classroom.isTestClassroom

export const selectClassroomTip = (
  store: RootState
): ClassroomTip | undefined => store.classroom.currentTip

export const selectClassroomHasClassCodes = (store: RootState): boolean =>
  store.classroom.hasClassCodes

export const selectTypeOfClassroom = createSelector(
  selectClassroomFeatures,
  selectClassroomActiveProductInfo,
  (classroomFeatures, activeProduct): "fnDill" | "dill" | "adapt" => {
    if (activeProduct.type === "adapt") {
      return "adapt"
    } else {
      return classroomFeatures.some(feature => feature === "FN")
        ? "fnDill"
        : "dill"
    }
  }
)

export const selectClassroomHelpTags = createSelector(
  selectTypeOfClassroom,
  classroomType => {
    switch (classroomType) {
      case "adapt":
        return {
          excludedTags: ["Tecla/Dill", "Tecla/FN"],
          activeTag: "Tecla/Adapt"
        }
      case "fnDill":
        return {
          excludedTags: ["Tecla/Adapt", "Tecla/Dill"],
          activeTag: "Tecla/FN"
        }
      case "dill":
        return {
          excludedTags: ["Tecla/FN", "Tecla/Adapt"],
          activeTag: "Tecla/Dill"
        }
      default:
        return null
    }
  }
)
