import {
  Button,
  ButtonProps,
  styled,
  FormControl,
  DialogContent,
  TableCell,
  TableRow,
  TableRowProps
} from "@mui/material"
export const StyledButton = styled(
  Button as React.FC<ButtonProps & { marginleft?: number }>
)`
  ${props => props.theme.breakpoints.up("sm")} {
    margin-right: 0.5em;
  }
  ${props => props.marginleft && "margin-left:1.5rem"}
  ${props => props.theme.breakpoints.down("xs")} {
    margin-bottom: 0.5em;
    margin-right: 0;
  }
`
export const StyledDivider = styled("div")`
  height: 50px;
`
export const StyledFormControl = styled(FormControl)`
  min-width: 11rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  ${props => props.theme.breakpoints.down("xs")} {
    margin-left: 1rem;
  }
`

export const StyledRecorderContainer = styled("div")`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`

export const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`

export const StyledInstructions = styled("div")`
  padding: 1em;
`

export const StyledCheckboxCell = styled(TableCell)`
  width: 70px;
`

export const StyledInstructionsLabel = styled("div")`
  margin-bottom: 5px;
  margin-left: 5px;
  color: #666666;
  font-weight: bold;
`

export const StyledSelectStudentsTableRow = styled(
  TableRow as React.FC<TableRowProps & { is_checked: number }>
)`
  ${props => props.is_checked && "background-color: #d6e7f6"}
`

export const StyledNameTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
`

export const StyledNameContainer = styled("div")`
  margin-left: 1em;
`

export const StyledErrorMessage = styled("div")`
  color: ${props => props.theme.studli.status.error};
`
