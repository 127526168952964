/**
 * Handles data to and from socket.
 * "Send", here means to server via socket and "Receive" means from server through the same socket
 */

import { END, EventChannel, eventChannel } from "redux-saga"
import {
  takeLatest,
  take,
  put,
  select,
  all,
  call,
  takeEvery
} from "redux-saga/effects"

import * as actions from "./actions"
import * as types from "./types"
import * as constants from "./constants"
import { selectPendingRequests } from "./selectors"
import { setCommunicationError } from "./actions"
import {
  socketConnected,
  SOCKET_CLOSED,
  SOCKET_CONNECTED
} from "../socket/actions"
import { setApplicationIdle } from "../application/actions"

/**
 * Helper function for eventChannel.
 *
 * @param emit Emit
 * @param event Event, onmessage event
 */
export const parseReceivedDataAndEmitAction = (
  emit: (input: {} | END) => void,
  event: MessageEvent
) => {
  let message: {
    type: string
    action: string
    payload: any
    eventId: string
  } = {
    type: "",
    action: "",
    payload: "",
    eventId: ""
  }

  try {
    message = JSON.parse(event.data)
  } catch (e) {
    emit(
      setCommunicationError({
        id: 0,
        message: `Failed to parse data from socket ` + e.message,
        reporter: "Backend"
      })
    )
  }

  const { type } = message
  if (type) {
    if (type === constants.SOCKET_CONNECTED_MESSAGE) {
      emit(socketConnected())
    } else {
      emit(actions.receiveOnSocketChannel(message))
    }
  } else {
    emit(END)

    emit(
      setCommunicationError({
        id: 0,
        message: `Received unhandled message action ${message.action} for message type ${message.action}`,
        reporter: "Backend"
      })
    )
  }
}

export function* logWarningIfWaitedForResponseForTooLong(
  concernedRequest: types.PendingRequest
) {
  const ageOfPendingRequest = Date.now() - concernedRequest.requestedAt

  if (ageOfPendingRequest > constants.MAX_AGE_OF_PENDING_REQUEST_BEFORE_WARN) {
    const communicationWarning = {
      id: 0,
      message: `Request ${concernedRequest.type}->${concernedRequest.action} waited ${ageOfPendingRequest} ms for the received response.`
    }

    yield put(actions.logCommunicationWarning(communicationWarning))
  }
}

/**
 * Handles received server message with type "response".
 * The action RECEIVE_RESPONSE will always be dispatched,
 * regardless if the response indicates failure or success.
 * This is so that te individual modules can do the specific error handling.
 * The saved request, corresponding to the response will be removed by this function.
 *
 * @param messagePayload {Response} the payload of the message from server
 */
export function* handleReceivedResponse(messagePayload: types.Response) {
  const requestId = messagePayload.requestId
  const pendingRequests = yield select(selectPendingRequests)

  const concernedRequest = pendingRequests.find(
    (request: types.PendingRequest) => request.requestId === requestId
  )

  yield call(logWarningIfWaitedForResponseForTooLong, concernedRequest)

  const { payload, error } = messagePayload
  // Let the payload from server, if there is one, override the saved payload of the request.
  if (payload) {
    concernedRequest.payload = payload
  }

  // Let the error from server, if there is one, enhance the pending request so that concerned module can take proper action when receiving the response.
  if (error) {
    concernedRequest.error = error
  }

  // A faulty response
  if (error) {
    error.reporter = "Backend"
    yield put(actions.setCommunicationError(error))
  }

  yield put(actions.receiveResponse(concernedRequest as types.Response))
  yield put(actions.removeRequest(requestId))
}

/**
 * Each time an action gets put into the specified channel this saga will dispatch an
 * RECEIVE_RESPONSE or RECEIVE_EVENT action for the corresponding module-sagas to handle.
 *
 * @param socketChannel socketChannel, this channel is filled with "actions" from incoming messages on the socket
 */
export function* serverListener(socketChannel: EventChannel<any>) {
  while (true) {
    const action = yield take(socketChannel)
    const actionPayload: types.Response = action.payload

    if (action.type === actions.RECEIVE_DATA) {
      // A response
      if (actionPayload && actionPayload.type === "response") {
        yield handleReceivedResponse(actionPayload)
      }
      // An Event
      else if (actionPayload && actionPayload.payload) {
        yield put({
          type: actionPayload.action,
          payload: actionPayload.payload
        })
        yield put(actions.receiveEvent(actionPayload as types.ReceivePayload))
      } else if (actionPayload && actionPayload.error) {
        if (
          actionPayload.error.id ===
            constants.communicationErrors.ErrorClassroomMissing ||
          actionPayload.error.id ===
            constants.communicationErrors.ErrorProductNotActivated
        ) {
          yield put(setApplicationIdle())
        } else if (
          actionPayload.error.id ===
          constants.communicationErrors.ErrorRefreshTokenFailed
        ) {
          // If refresh token fails, we force window to be reloaded to prevent looping.
          window.console.error("Refresh token has failed! Reloading page...")
          window.location.reload()
        }

        yield put(setCommunicationError(actionPayload.error))
      } else {
        window.console.error("Payload expected but missing")
      }
    } else if (
      action.type === SOCKET_CLOSED ||
      action.type === SOCKET_CONNECTED
    ) {
      // do not treat socket closed or socket connected as errors
      yield put(action)
    } else {
      // Neither Response nor Event, rather an socket-event, closed/opened/error/...
      yield put(
        setCommunicationError({
          id: 0,
          message: `Communication received action ${action.type} on socket-channel, dispatching it.`,
          reporter: "Backend"
        })
      )
      yield put(action)
    }
  }
}

/**
 * Sends payload to socket on latest SEND_REQUEST action
 *
 * @param socket socket, to send on
 */
export function* sendRequestToServer(socket: WebSocket) {
  yield takeEvery(
    actions.SEND_REQUEST,
    function* (msg: { type: string; payload: any }) {
      yield put(actions.saveRequest(msg.payload))
      yield socket.send(JSON.stringify(msg.payload))
    }
  )
}

/**
 * The setInterval fires events, this design-pattern uses saga's eventChannel to handle these events and emit empty objects.
 * Whatever listens to this channel "does its thing" on each emitted empty object.
 * Note also that the setInterval is cleared here.
 *
 * @param interval - the time in ms between emits (action initiations).
 */
const createActionInitiatorChannel = (interval: number) => {
  return eventChannel(emitter => {
    const setIntervalId = setInterval(() => emitter({}), interval)

    return () => {
      clearInterval(setIntervalId)
    }
  })
}

/**
 * Removes all requests that are in pending state
 */
function* removeAllPendingRequests() {
  const pendingRequests: types.PendingRequest[] = yield select(
    selectPendingRequests
  )

  yield all(
    pendingRequests.map(({ requestId }) =>
      put(actions.removeRequest(requestId))
    )
  )
}

/**
 * Takes SOCKET_CLOSED and removes all pending requests
 */
function* watchSocketClosed() {
  yield takeLatest(SOCKET_CLOSED, removeAllPendingRequests)
}

/**
 * Checks if specified pending request is too old (has been waiting too long for a response).
 * If so, actions are dispatched to log an error message about this and also remove the request.
 *
 * @param request {PendingRequest} - the pending request who's age is to be checked
 */
export function* checkPendingRequestAndHandleIfTooOldSaga(
  request: types.PendingRequest
) {
  const now = Date.now()
  const ageOfPendingRequest = now - request.requestedAt

  if (ageOfPendingRequest > constants.MAX_AGE_OF_PENDING_REQUEST) {
    yield put(
      actions.setCommunicationError({
        id: 99,
        message: `Pending request timed out, removing it. Request: ${JSON.stringify(
          request
        )}`
      })
    )
    yield put(actions.removeRequest(request.requestId))
  }
}

/**
 * Starts an infinite, periodical check and handling of pending requests.
 */
function* startPeriodicalCheckAndHandligOfPendingRequestsSaga() {
  const checkPendingRequestsChannel = yield call(
    createActionInitiatorChannel,
    constants.CHECK_PENDING_REQUEST_AGE_INTERVAL
  )

  yield takeLatest(checkPendingRequestsChannel, function* () {
    const pendingRequests = yield select(selectPendingRequests)

    if (pendingRequests.length > 0) {
      yield all(
        pendingRequests.map((request: types.PendingRequest) =>
          call(checkPendingRequestAndHandleIfTooOldSaga, request)
        )
      )
    }
  })
}

export function* rootSaga() {
  yield all([
    startPeriodicalCheckAndHandligOfPendingRequestsSaga(),
    watchSocketClosed()
  ])
}
