import { ReactComponent as DraggableIcon } from "../../assets/images/draggable.svg"
import ListItem, { ListItemProps } from "@mui/material/ListItem"
import { Checkbox, styled, Typography } from "@mui/material"

type Props = {
  editMode: boolean
}

type CustomListItemProps = {
  hover: number
}

export const StyledToggleAll = styled(Checkbox)`
  margin-left: 17px;
`

export const StyledTasksHeaderTitle = styled(Typography)`
  font-size: 1.5em;
  margin-top: 8px;
`

export const StyledTaskHeader = styled("div")<{ selected: boolean }>`
  ${props => props.selected && "background-color: rgb(214, 231, 247);"}
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledCheckboxTitle = styled("div")`
  display: flex;
`

export const StyledListItemTextContainer = styled("div")``
export const StyledDraggableIcon = styled(DraggableIcon)`
  margin-top: 8px;
  margin-right: 1rem;
`
export const StyledTextWrapper = styled("div")``
export const StyledProgressWrapper = styled("div")`
  min-width: 200px;
`
export const StyledListItemWrapper = styled("div")<Props>`
  ${props => props.theme.breakpoints.up("sm")} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  ${props => props.theme.breakpoints.down("sm")} {
    display: ${props => (props.editMode ? "flex" : "block")};
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

export const StyledListItem = styled(
  ListItem as React.FC<ListItemProps & CustomListItemProps>
)`
  ${props =>
    props.hover &&
    `&:hover {
    background-color: ${props.theme && props.theme.studli.table.hover};
  }`}
`

export const StyledErrorMsg = styled("div")`
  height: 20px;
  color: #cc0000;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.23px;
  line-height: 20px;
  margin-top: 6px;
`
