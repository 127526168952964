import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  selectExerciseFromRoute,
  selectAllExercises
} from "../../modules/exercises/selectors"
import { selectExercisesProgressForExerciseInRouteWithStudents } from "../../modules/exercisesProgress/selectors"
import { buildPathToExercise } from "../Exercises/common/helpers"
import { ParentOrExerciseNode } from "../../modules/exercises/types"
import {
  StyledHeaderContainer,
  StyledPathContainer,
  StyledIcon,
  StyledPath,
  StyledStatusTag,
  StyledNameAndResult,
  StyledTableCell,
  StyledPaper,
  StyledHeadCell,
  StyledPathText,
  StyledTitle
} from "./StyledExercisesCard"
import Button from "../../components/Button"
import { ICONS } from "../../components/ExerciseListItem/ExerciseListItem"
import {
  Divider,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from "@mui/material"
import Avatar from "../../components/Avatar"
import { renderName } from "../../shared/subRenderFunctions"
import { Member } from "../../modules/members/types"
import { ExerciseProgress } from "../../modules/exercisesProgress/types"

import {
  StyledLegendContainer,
  StyledLegendBox
} from "../../components/GoalsTable/StyledGoalsTable"
import InfoPopover from "../../components/InfoPopover"
import { PROGRESS_SEGMENT } from "../../components/Progress/constants"
import { selectClassroomActiveProductToOpen } from "../../modules/products/selectors"
import ExerciseResultWithHistory from "../../components/ExerciseResultWithHistory"
import { ExerciseProgressWithHistory } from "../../components/ExerciseResultWithHistory/ExerciseResultWithHistory"
import HtmlRenderer from "../../components/HtmlRenderer"
import { selectStudentsWithActiveProductLength } from "../../modules/members/selectors"

type StudentWithExerciseProgress = Member & ExerciseProgress

type SortKeys = "name" | "status"

const ExercisesCard = () => {
  const exercise = useSelector(selectExerciseFromRoute)
  const exercises = useSelector(selectAllExercises)
  const activeProduct = useSelector(selectClassroomActiveProductToOpen)
  const hasActiveProduct = Boolean(activeProduct)
  const students = useSelector(selectStudentsWithActiveProductLength)
  const progressWithStudent: StudentWithExerciseProgress | Member[] =
    useSelector(selectExercisesProgressForExerciseInRouteWithStudents)
  const [pathToExercise, setPathToExercise] = useState<
    ParentOrExerciseNode[] | null
  >(null)

  const [tableSort, setTableSort] = useState<{
    direction: "desc" | "asc"
    column: SortKeys
  }>({ direction: "desc", column: "name" })
  useEffect(() => {
    if (exercise && exercises) {
      for (let i = 0; i < exercises.length; i++) {
        const path = buildPathToExercise(exercises[i], exercise.id)
        if (path.length) {
          path.splice(path.length - 1, 1)
          setPathToExercise(path)

          return
        }
      }
    }
  }, [exercise, exercises])
  const getStatusTagText = (
    progressStatus: "done" | "notStarted" | "inProgress"
  ) => {
    switch (progressStatus) {
      case "done":
        return "Klar"
      case "inProgress":
        return "Påbörjat"
      case "notStarted":
        return "Ej påbörjat"
    }
  }

  const sortTable = (sortKey: SortKeys) => {
    const sortOrder =
      sortKey === tableSort.column
        ? reverseSortOrder(tableSort.direction)
        : "desc"
    setTableSort({ column: sortKey, direction: sortOrder })
  }

  const sortByStatus = (
    first: StudentWithExerciseProgress | Member,
    second: StudentWithExerciseProgress | Member
  ) => {
    const statusWeight = [
      { status: "done" },
      { status: "inProgress" },
      { status: "notStarted" }
    ]
    const statusOfFirst = statusWeight.findIndex(
      status => status.status === getProgressStatus(first)
    )
    const statusOfSecond = statusWeight.findIndex(
      status => status.status === getProgressStatus(second)
    )
    if (tableSort.direction === "desc") {
      return statusOfFirst - statusOfSecond
    }

    return statusOfSecond - statusOfFirst
  }

  const getProgressStatus = (student: any) => {
    if (typeof student.isInProgress !== "undefined" && !student.isInProgress) {
      return "done"
    } else if (student.isInProgress) {
      return "inProgress"
    }

    return "notStarted"
  }

  const goToStudentProduct = () => {
    if (activeProduct) {
      const url = process.env.REACT_APP_BOOKSHELF_URL
      const redirectURL = `${url}/dill/app/#/start/${activeProduct.sesamName}/${exercise.id}`
      window.open(redirectURL, "_blank", "noopener")
    }
  }

  const renderCardHeader = () => {
    return (
      <StyledHeaderContainer>
        <Button
          onClick={goToStudentProduct}
          color="primary"
          variant="outlined"
          disabled={!hasActiveProduct}
        >
          Öppna i elevvyn
        </Button>
        <StyledPathContainer>
          <StyledIcon>{ICONS[exercise.type]}</StyledIcon>
          <StyledPath>
            <StyledTitle variant="h1">
              <HtmlRenderer html={exercise.title} />
            </StyledTitle>
            {pathToExercise && (
              <StyledPathText variant="h2">
                <HtmlRenderer
                  html={`( ${pathToExercise
                    .map(node => node.title)
                    .join(" / ")} )`}
                />
              </StyledPathText>
            )}
          </StyledPath>
        </StyledPathContainer>
      </StyledHeaderContainer>
    )
  }

  const renderTableSortIcon = (
    sortKey: SortKeys,
    label: string,
    info: boolean
  ) => {
    return (
      <TableSortLabel
        active={tableSort.column === sortKey}
        direction={tableSort.direction}
        onClick={() => sortTable(sortKey)}
      >
        {label}
        {info && (
          <InfoPopover
            type="Info"
            content={
              <>
                <StyledLegendContainer>
                  <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                  Klar
                </StyledLegendContainer>
                <StyledLegendContainer>
                  <StyledLegendBox segment={PROGRESS_SEGMENT.IN_PROGRESS} />
                  Påbörjad
                </StyledLegendContainer>
                <StyledLegendContainer>
                  <StyledLegendBox segment={PROGRESS_SEGMENT.NOT_STARTED} />
                  Ej påbörjad
                </StyledLegendContainer>
              </>
            }
          />
        )}
      </TableSortLabel>
    )
  }
  const renderStudentTable = () => {
    const sortedMembers = progressWithStudent.sort((a, b) => {
      if (tableSort.column === "name") {
        return tableSort.direction === "desc"
          ? a.lastName.localeCompare(b.lastName)
          : b.lastName.localeCompare(a.lastName)
      }
      const sort = sortByStatus(a, b)

      return sort
    })

    return (
      <StyledPaper>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadCell
                align="left"
                sortDirection={
                  tableSort.column === "name" ? tableSort.direction : false
                }
              >
                {renderTableSortIcon("name", "Namn", false)}
              </StyledHeadCell>
              <StyledHeadCell
                sortDirection={
                  tableSort.column === "status" ? tableSort.direction : false
                }
              >
                {renderTableSortIcon("status", "Status", true)}
              </StyledHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedMembers.map(progress => {
              const progressStatus = getProgressStatus(progress)

              return (
                <TableRow key={progress.studliId}>
                  <StyledTableCell>
                    <Avatar
                      firstName={progress.firstName}
                      lastName={progress.lastName}
                      picture={progress.picture}
                      studliId={progress.studliId}
                    />
                    <StyledNameAndResult>
                      {renderName(progress)}
                      {progressStatus === "done" && (
                        <ExerciseResultWithHistory
                          type="bestScore"
                          exercisesForHistory={
                            progress as unknown as ExerciseProgressWithHistory
                          }
                        />
                      )}
                    </StyledNameAndResult>
                  </StyledTableCell>
                  <TableCell>
                    <StyledStatusTag status={progressStatus}>
                      {getStatusTagText(progressStatus)}
                    </StyledStatusTag>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </StyledPaper>
    )
  }

  return (
    <>
      {exercise && (
        <>
          {renderCardHeader()}
          <Divider />
          {students > 0 && renderStudentTable()}
        </>
      )}
    </>
  )
}

export default ExercisesCard

const reverseSortOrder = (sortOrder: "desc" | "asc") => {
  return sortOrder === "desc" ? "asc" : "desc"
}
