/**
 * We need to handle: Responses, Requests and Events.
 * Here are defined Redux-actions and server-message-actions,
 */

import { Order, SetMemberListOrder, SortableProps } from "./types"

/**
 * Redux-actions for UI (internal UI usage, inter-component-communication).
 */
export enum UI {
  SET_MEMBERLIST_ORDER = "SET_MEMBERLIST_ORDER",
  RESET_UPDATE_MEMBER_ERROR = "RESET_UPDATE_MEMBER_ERROR",
  SET_MEMBERS = "SET_MEMBERS"
}

export const setMemberListOrder = (
  order: Order,
  orderBy: keyof SortableProps
): SetMemberListOrder => {
  return { type: UI.SET_MEMBERLIST_ORDER, payload: { order, orderBy } }
}
