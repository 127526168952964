/**
 * These select* functions are  for redux select(), they expose parts of the state.
 */

import {
  Users,
  User,
  ProductKeysStatuses,
  ActiveProducts,
  Classrooms,
  ActiveProduct
} from "./types"
import { RootState } from "../store"
import { createSelector } from "reselect"
import { Products, Product } from "../products/types"
import { hasSameNiceNameAsAnotherProduct } from "../products/selectors"
import { USER_ROLE } from "./constants"

export const selectValidatedProductKeys = (
  store: RootState
): ProductKeysStatuses => store.users.productKeys || []

export const selectProductKeys = (store: RootState): ProductKeysStatuses =>
  store.users.productKeys || []

export const selectUserActiveProducts = (store: RootState): ActiveProduct[] =>
  store.users.activeProducts || []

export const selectActiveProducts = createSelector(
  ({ users }: RootState) => users.activeProducts || [],
  ({ products }: RootState) => products.products,
  ({ products }: RootState) =>
    products.products.find(p => p.id === products.activeProductId) ||
    ({} as Product),
  (activeProducts, products, activeProduct) =>
    activeProducts.map(
      item =>
        ({
          ...item,
          products: item.products
            .map(sesamName => {
              const product = products.find(p => p.sesamName === sesamName)
              if (!product) {
                return null
              }

              return hasSameNiceNameAsAnotherProduct(
                products.filter(p => p.id !== product.id),
                product.niceName
              )
                ? product.title
                : product.niceName
            })
            .filter(Boolean)
            .reduce((res, name) => {
              if (
                name === activeProduct.niceName ||
                name === activeProduct.title
              ) {
                return [name as string, ...res]
              }

              return [...res, name as string]
            }, [] as string[])
        } as ActiveProduct)
    )
)

export const selectUsersWithProduct = (
  store: RootState
): User["studliId"][] | null => store.users.usersWithProduct

export const selectUsersOnline = (
  store: RootState
): User["studliId"][] | null => {
  return store.users.usersOnline
}

export const selectAuthenticatedUser = (store: RootState): User | undefined =>
  store.users.authenticatedUser

export const selectFoundUsers = (store: RootState): Users =>
  store.users.foundUsers || []

export const selectFoundClassrooms = (store: RootState): Classrooms =>
  store.users.foundUserClassrooms || []

export const doesCurrentUserHasATeacherGuide = createSelector(
  ({ users }: RootState) => users.activeProducts || [],
  ({ products }: RootState) => products.products,
  ({ users }: RootState) => (users.authenticatedUser || {}).studliId || 0,
  (activeProducts: ActiveProducts, products: Products, currentUser) => {
    const item = activeProducts.find(ap => ap.studliId === currentUser)

    if (!item) {
      return false
    }

    return item.products.reduce((res, sesamName) => {
      if (res) {
        return res
      }

      return (
        products.findIndex(p => p.sesamName === sesamName && p.isTeacherGuide) >
        -1
      )
    }, false)
  }
)

export const selectActiveProductsForMemberInRoute = createSelector(
  selectActiveProducts,
  ({ members }: RootState) =>
    members.members.filter(m => m.type === USER_ROLE.STUDENT),
  ({ location }: RootState) => +location.payload.memberId,
  (activeProducts, students, memberId) => {
    const currentStudent = students.find(
      student => student.studliId === memberId
    )
    if (currentStudent) {
      const productForStudent = activeProducts.find(
        product => product.studliId === currentStudent.studliId
      )

      return productForStudent
    }
  }
)
