import * as actions from "./actions"
import { State, CreateEditAssignmentActions } from "./types"

const initialState: State = {
  isCreateFormDirty: false
}

const reducer = (
  state = initialState,
  action: CreateEditAssignmentActions
): State => {
  switch (action.type) {
    case actions.UI.SET_CREATE_FORM_DIRTY:
      const { isDirty } = action.payload
      return isDirty !== undefined
        ? { ...state, isCreateFormDirty: isDirty }
        : { ...state }
    default:
      return state
  }
}

export default reducer
