import { createAction } from "../../shared/tools/redux"
import * as types from "./types"

// Main
export const ROUTE_ELEVER = "ROUTE_ELEVER" //ROUTE_MEMBERS
export const ROUTE_START = "ROUTE_START"
export const ROUTE_MAL = "ROUTE_MAL"
export const ROUTE_HJALP = "ROUTE_HJALP"

//Teachers
export const ROUTE_LARARE = "ROUTE_LARARE"
export const ROUTE_LAGGTILL_LARARE = "ROUTE_LAGGTILL_LARARE"

// Members
export const ROUTE_LAGGTILL = "ROUTE_LAGGTILL" //ROUTE_ADD_MEMBERS
export const ROUTE_AKTIVERADE = "ROUTE_AKTIVERADE" //ROUTE_ACTIVATE_PRODUCT
export const ROUTE_AKTIVERA_KLASSLICENS = "ROUTE_AKTIVERA_KLASSLICENS"
export const ROUTE_SKAPAKONTO = "ROUTE_SKAPAKONTO" //ROUTE_CREATE_USERS
export const ROUTE_ELEVKORT = "ROUTE_ELEVKORT"

// Exercises
export const ROUTE_OVNINGAR = "ROUTE_OVNINGAR"
export const ROUTE_OVNING_INFO = "ROUTE_OVNING_INFO"

//Assignments
export const ROUTE_UPPDRAG = "ROUTE_UPPDRAG"
export const ROUTE_UPPDRAG_INFO = "ROUTE_UPPDRAG_INFO"
export const ROUTE_UPPDRAG_STUDENT_INFO = "ROUTE_UPPDRAG_STUDENT_INFO"
export const ROUTE_UPPDRAG_REDIGERA = "ROUTE_UPPDRAG_REDIGERA"
export const ROUTE_UPPDRAG_LAGGTILL = "ROUTE_UPPDRAG_LAGGTILL"
export const ROUTE_UPPDRAG_LAGGTILL_NYTT = "ROUTE_UPPDRAG_LAGGTILL_NYTT"

export const ROUTE_KLASSRUMSKOD = "ROUTE_KLASSRUMSKOD"

//Formative question
export const ROUTE_FORMATIVA_FRAGOR = "ROUTE_FORMATIVA_FRAGOR"
export const ROUTE_FORMATIVA_FRAGOR_LAGGTILL = "ROUTE_FORMATIVA_FRAGOR_LAGGTILL"
export const ROUTE_FORMATIVA_FRAGOR_REDIGERA = "ROUTE_FORMATIVA_FRAGOR_REDIGERA"
export const ROUTE_FORMATIVA_FRAGOR_INFO = "ROUTE_FORMATIVA_FRAGOR_INFO"
export const ROUTE_FORMATIVA_FRAGOR_ELEV_INFO =
  "ROUTE_FORMATIVA_FRAGOR_ELEV_INFO"

//Goals
export const ROUTE_GOALS_DETAILS = "ROUTE_GOALS_DETAILS"

export const NOT_FOUND = "@@redux-first-router/NOT_FOUND" //takes care of all routes that aren't found...

/**
 * Redirects to Members view
 *
 * @param classroomId {number} - Id of current classroom.
 */
export const gotoRouteMembers = (payload: {
  classroomId: number
}): types.GotoRouteMembers => {
  return { type: ROUTE_ELEVER, payload: payload }
}

/**
 * Redirects to Teachers view
 *
 * @param classroomId {number} - Id of current classroom.
 */
export const gotoRouteTeachers = (payload: {
  classroomId: number
}): types.GotoRouteTeachers => {
  return { type: ROUTE_LARARE, payload: payload }
}

export const gotoRoute = (type: string, payload: object): types.GotoRoute => {
  return { type, payload }
}

export const toggleShouldReload = createAction("TOGGLE_SHOULD_RELOAD")
