import { setField } from "../../shared/tools/monocle"
import { createReducer } from "../../shared/tools/redux"
import * as actions from "./actions"
import { State, Exercises } from "./types"

const initialState: State = {
  exercises: [],
  temaToc: []
}

const setToc = setField<State, { toc: Exercises }>("exercises", p => p.toc)
const setTemaToc = setField<State, { temaToc: Exercises }>(
  "temaToc",
  p => p.temaToc
)
const setInitialState = () => (state: State) => initialState

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.setExercises, setToc)
    .case(actions.setTemaToc, setTemaToc)
    .case(actions.resetExercises, setInitialState)
)

export default reducer
