import React, { useState, useEffect } from "react"
import { AssignmentMessageWithSenderData } from "../../containers/FeedbackMessages/types"
import FeedbackMessageListItem from "../FeedbackMessageListItem"
import StyledButton from "../Button"
import { StyledButtonWrapper } from "./StyledFeedbackMessageList"

type Props = {
  messagesForMember: AssignmentMessageWithSenderData[]
}

const ADD_MORE_NUMBER = 5
const INIT_NUMBER_TO_SHOW = 6

const FeedbackMessageList = ({ messagesForMember }: Props) => {
  const [numberOfMessagesToShow, setNumberOfMessagesToShow] =
    useState<number>(INIT_NUMBER_TO_SHOW)
  const [messagesToList, setMessagesToList] = useState<
    AssignmentMessageWithSenderData[]
  >([])

  useEffect(() => {
    setMessagesToList(messagesForMember.slice(0, numberOfMessagesToShow))
  }, [messagesForMember, numberOfMessagesToShow])

  const handleAddMoreClick = () => {
    setNumberOfMessagesToShow(numberOfMessagesToShow + ADD_MORE_NUMBER)
  }

  return (
    <>
      {messagesToList.map((message: AssignmentMessageWithSenderData) => (
        <div key={message.id}>
          <FeedbackMessageListItem message={message} />
        </div>
      ))}
      {messagesToList.length < messagesForMember.length && (
        <StyledButtonWrapper>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={handleAddMoreClick}
          >
            Ladda mer
          </StyledButton>
        </StyledButtonWrapper>
      )}
    </>
  )
}

export default FeedbackMessageList
