import * as React from "react"
import sanitize from "sanitize-html"

export interface Props {
  html: string
  component?: React.FC | string
  componentProps?: any
}

const HtmlRenderer: React.FC<Props> = ({ html, component, componentProps }) => {
  const props = React.useMemo(() => {
    const sanitized = sanitize(html, {
      allowedTags: sanitize.defaults.allowedTags.concat(["img", "span"]),
      allowedAttributes: {
        "*": ["style"],
        ...sanitize.defaults.allowedAttributes
      }
    })

    return {
      dangerouslySetInnerHTML: {
        __html: sanitized
      }
    }
  }, [html])

  return React.createElement<{ dangerouslySetInnerHTML: { __html: string } }>(
    component as React.FC | string,
    { ...props, ...componentProps }
  )
}

HtmlRenderer.defaultProps = {
  component: "div"
}

export default HtmlRenderer
