import { Button, Stack, Typography, ListItem } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { selectCurrentGroups } from "../../modules/classroom/selectors"
import ChooseGroupDialog from "./ChooseGroupDialog"

const LIMIT = 3

const ChooseGroupButton = () => {
  const currentGroups = useSelector(selectCurrentGroups)
  const [showAll, setShowAll] = React.useState(false)
  const [openChooseGroupDialog, setOpenChooseGroupDialog] =
    React.useState(false)

  return (
    <ListItem disablePadding sx={{ background: "rgba(48, 101, 98, 0.13)" }}>
      <Stack my={1} direction="row" spacing={2} width="100%">
        <Stack direction="column" px={2} width="100%">
          <Typography variant="body2" color="textSecondary">
            {currentGroups.length > 1 ? "Valda grupper" : "Vald grupp"}
          </Typography>
          <Stack>
            {currentGroups
              .slice(0, showAll ? currentGroups.length : LIMIT)
              .map(g => (
                <Typography key={g.id}>{g.name}</Typography>
              ))}
            {currentGroups.length > LIMIT && (
              <Typography
                sx={{
                  backgroundColor: "inherit",
                  textAlign: "start",
                  border: "none",
                  cursor: "pointer",
                  textDecoration: "underline",
                  mt: 2
                }}
                onClick={() => setShowAll(!showAll)}
                component="button"
              >
                {showAll ? "Visa mindre" : "Visa mer"}
              </Typography>
            )}
          </Stack>
          <Button
            sx={{ my: 2 }}
            variant="outlined"
            onClick={() => setOpenChooseGroupDialog(true)}
          >
            Välj grupp
          </Button>
        </Stack>
      </Stack>
      <ChooseGroupDialog
        onClose={() => setOpenChooseGroupDialog(false)}
        open={openChooseGroupDialog}
      />
    </ListItem>
  )
}
export default ChooseGroupButton
