import React, { useState, useEffect, useMemo } from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import { Formik, FormikActions } from "formik"
import { RootState } from "../../modules/store"
import {
  selectCurrentAssignmentId,
  selectCurrentRoute
} from "../../modules/router/selectors"
import TitleAndDescription from "../../components/TitleAndDescription"
import * as routerActions from "../../modules/router/actions"
import { Members } from "../../modules/members/types"
import CreateAssignmentForm from "../../components/CreateAssignmentForm"
import * as Yup from "yup"

import { Exercise } from "../../modules/exercises/types"
import * as membersSelector from "../../modules/members/selectors"
import * as exercisesSelector from "../../modules/exercises/selectors"
import moment from "moment"
import * as assignmentsActions from "../../modules/assignments/actions"
import {
  CreateAssignmentData,
  Assignments,
  Assignment,
  PartialAssignment,
  EditEchoActionTypes
} from "../../modules/assignments/types"
import { Stickers, Features } from "../../modules/classroom/types"
import * as assignmentsSelectors from "../../modules/assignments/selectors"
import {
  selectClassroomStickers,
  selectClassroomFeatures,
  selectClassroomId,
  selectTypeOfClassroom
} from "../../modules/classroom/selectors"
import { getRandomNumber } from "../../shared/tools/misc"
import * as classroomSelectors from "../../modules/products/selectors"
import { Product } from "../../modules/products/types"
import * as createEditAssignmentActions from "./store/actions"
import { createAssignmentMessages } from "./common/errorMessages"
import { toTextAndAudio } from "../../shared/tools/parse"
import { FormValues, PreviewValues } from "./store/types"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
type StateProps = {
  assignmentId: number
  currentRoute: string
  exercises: Exercise
  temaToc: Exercise
  members: Members
  assignments: Assignments
  stickers: Stickers
  pendingImport?: PartialAssignment
  activeProduct: Product
  classroomFeatures: Features
  classroomId: number
  shouldUseTemaToc?: boolean
}

type DispatchProps = {
  createAssignment: (assignment: CreateAssignmentData) => void
  editAssignment: (assignment: Assignment, echo: EditEchoActionTypes) => void
  resetImportedAssignment: () => void
  setIsCreateFormDirty: (isDirty: boolean) => void
  storePreview: (data: string) => void
}

type Props = StateProps & DispatchProps

export const CreateEditAssignment = ({
  currentRoute,
  exercises,
  temaToc,
  shouldUseTemaToc,
  members,
  createAssignment,
  assignmentId,
  assignments,
  editAssignment,
  stickers,
  pendingImport,
  resetImportedAssignment,
  activeProduct,
  classroomFeatures,
  classroomId,
  setIsCreateFormDirty,
  storePreview
}: Props) => {
  const [initStickerValue, setInitStickerValue] = useState<number | null>(null)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [selectGoalsForAdapt, setSelectGoalsForAdapt] = useState(false)
  const [importedAssignment, setImportedAssignment] =
    useState<PartialAssignment>()
  const classroomType = useSelector(selectTypeOfClassroom)
  const user = useSelector(selectAuthenticatedUser)

  const dispatch = useDispatch()

  useEffect(() => {
    if (currentRoute === routerActions.ROUTE_UPPDRAG_REDIGERA && assignmentId) {
      dispatch(assignmentsActions.getAssignment(assignmentId))
    }
  }, [assignmentId, dispatch, currentRoute])

  useEffect(() => {
    return () => {
      dispatch(
        assignmentsActions.createEditAssignmentValidationError(undefined)
      )
    }
  }, [dispatch])
  useEffect(() => {
    if (pendingImport) {
      setImportedAssignment(pendingImport)
      resetImportedAssignment()
    }
  }, [pendingImport, setImportedAssignment, resetImportedAssignment])

  useEffect(() => {
    setIsCreateFormDirty(false)
  }, [setIsCreateFormDirty])

  const getASticker = () => {
    if (initStickerValue === null) {
      const randomIndex = getRandomNumber(0, stickers.length)
      setInitStickerValue(stickers[randomIndex].id)

      return stickers[randomIndex].id
    }

    return initStickerValue
  }

  const isEditingMode =
    !isNaN(assignmentId) &&
    currentRoute === routerActions.ROUTE_UPPDRAG_REDIGERA

  const isCreator = useMemo(() => {
    if (!isEditingMode) {
      return true
    }
    return (
      assignments.find(assignment => assignment.id === assignmentId)
        ?.createdBy === user?.studliId
    )
  }, [assignmentId, assignments, isEditingMode, user])

  const initialValues = {
    title: "",
    stickerId: classroomType === "dill" ? null : getASticker(),
    instruction: { text: "", audio: "" },
    status: "draft",
    startDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    endDate:
      classroomType === "adapt"
        ? moment()
            .add(1, "week")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        : null,
    assignmentTasks: [],
    participants: [],
    threshold: 20,
    repetitionAmount: 20,
    isPublic: false
  } as FormValues

  const getInitialValues = () => {
    if (isEditingMode) {
      const editAssignmentIndex = assignments.findIndex(
        assignment => assignment.id === assignmentId
      )
      if (editAssignmentIndex !== -1) {
        const assignment = assignments[editAssignmentIndex]

        return {
          title: assignment.title,
          stickerId:
            assignment.stickerId === null
              ? initialValues.stickerId
              : assignment.stickerId,
          instruction: toTextAndAudio(assignment.instruction as string),
          status: assignment.status,
          startDate: moment(assignment.startDate),
          endDate: assignment.endDate ? moment(assignment.endDate) : null,
          assignmentTasks: assignment.assignmentTasks,
          participants: assignment.participants,
          threshold: assignment.threshold,
          repetitionAmount: assignment.repetitionAmount,
          isPublic: assignment.isPublic
        } as FormValues
      }
    }

    if (importedAssignment) {
      return {
        ...initialValues,
        ...importedAssignment,
        instruction: toTextAndAudio(importedAssignment.instruction as string),
        stickerId: importedAssignment.stickerId || initialValues.stickerId
      } as FormValues
    }

    return initialValues
  }

  const renderTitleAndDescription = () => {
    let pageTitle = ""
    switch (currentRoute) {
      case routerActions.ROUTE_UPPDRAG_LAGGTILL_NYTT:
        pageTitle = "Nytt uppdrag"
        break
      case routerActions.ROUTE_UPPDRAG_REDIGERA:
        pageTitle = "Redigera uppdrag"
        break
      default:
        pageTitle = "Uppdrag"
    }

    return <TitleAndDescription title={pageTitle} paragraphs={[]} />
  }

  const validationSchema = Yup.object({
    title: Yup.string().required(
      createAssignmentMessages.TITLE_MISSING.message
    ),
    instruction: Yup.string().notRequired(),
    stickerId:
      classroomType === "dill"
        ? Yup.mixed().notRequired()
        : Yup.string().required(
            createAssignmentMessages.STICKER_MISSING.message
          ),
    participants: Yup.array().required(
      createAssignmentMessages.STUDENTS_MISSING.message
    ),
    assignmentTasks: Yup.mixed().test(
      "test-assignmentTasks",
      createAssignmentMessages.ASSIGNMENT_TASKS_MISSING.message,
      function (value) {
        const { path, createError } = this
        if (classroomType === "adapt") {
          if (!value.length && selectGoalsForAdapt) {
            return createError({
              path,
              message: createAssignmentMessages.GOALS_MISSING.message
            })
          }
          return true
        }
        if (!value.length) {
          return createError({
            path,
            message: createAssignmentMessages.ASSIGNMENT_TASKS_MISSING.message
          })
        }

        return true
      }
    ),
    startDate: Yup.mixed()
      .test(
        "test-startDate",
        "Det saknas startdatum. Ange när uppdraget ska stängas för eleverna.",
        function (value) {
          const { path, createError } = this
          if (!value) {
            return false
          }
          if (isEditingMode) {
            return true
          }

          const diff = moment().diff(moment(value), "days")
          if (diff > 0) {
            return createError({
              path,
              message: "Start datumet kan inte vara innan idag."
            })
          }

          return true
        }
      )
      .required(createAssignmentMessages.START_DATE_MISSING.message),
    endDate: Yup.mixed().test(
      "test-endDate",
      "Det saknas slutdatum. Ange när uppdraget ska stängas för eleverna.",
      function (value) {
        const { path, createError } = this

        if (!value) {
          return classroomType !== "adapt"
        }

        if (
          moment().set({ hour: 0, minute: 0, second: 0 }).isAfter(moment(value))
        ) {
          return createError({
            path,
            message: "Slutdatumet kan inte vara innan idag."
          })
        }
        return true
      }
    )
  })

  /**
   * Handles submit of the form. I has access to Formik's properties and actions.
   *
   * @param values {FormValues} - Form's values.
   * @param actions {FormikActions<FormValues>} - Formik's actions.
   */
  const handleSubmit = (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    setIsCreateFormDirty(false)
    actions.setSubmitting(true)
    const submitValues = { ...values }
    submitValues.assignmentTasks = submitValues.assignmentTasks.map(
      assignmentTask => {
        if (typeof assignmentTask.id === "string") {
          const taskWithoutId: any = { ...assignmentTask }
          delete taskWithoutId.id

          return taskWithoutId
        }

        return assignmentTask
      }
    )

    submitValues.status = "published"
    if (
      typeof submitValues.instruction !== "string" &&
      submitValues.instruction
    ) {
      submitValues.instruction = JSON.stringify(submitValues.instruction)
    }

    if (isEditingMode) {
      findAndSaveEditedAssignment(submitValues)
    } else {
      createAssignment(submitValues as CreateAssignmentData)
    }
  }

  const findAndSaveEditedAssignment = (values: FormValues) => {
    const editAssignmentIndex = assignments.findIndex(
      assignment => assignment.id === assignmentId
    )

    if (editAssignmentIndex !== -1) {
      const assignment = assignments[editAssignmentIndex]
      const editedAssignment: Assignment = {
        ...assignment,
        ...values
      }
      editAssignment(editedAssignment, "")
    }
  }

  const handleSaveAsDraft = (values: FormValues) => {
    setIsCreateFormDirty(false)
    values.title = values.title === "" ? "Namnlös" : values.title
    values.assignmentTasks =
      values.assignmentTasks !== undefined ? values.assignmentTasks : []
    if (typeof values.instruction !== "string" && values.instruction) {
      values.instruction = JSON.stringify(values.instruction)
    }
    values.assignmentTasks = values.assignmentTasks.map(assignmentTask => {
      if (typeof assignmentTask.id === "string") {
        const taskWithoutId: any = { ...assignmentTask }
        delete taskWithoutId.id

        return taskWithoutId
      }

      return assignmentTask
    })

    if (isEditingMode) {
      findAndSaveEditedAssignment(values)
    } else {
      createAssignment(values as CreateAssignmentData)
    }
  }

  const handlePreview = (values: PreviewValues) => {
    storePreview(
      JSON.stringify({
        ...values,
        assignmentTasks: values.assignmentTasks.map((t, i) => ({
          ...t,
          id: i
        }))
      })
    )
  }

  const setFormIsDirty = (isFormDirty: boolean) => {
    if (isDirty) {
      return
    }
    setIsDirty(isFormDirty)
    setIsCreateFormDirty(isFormDirty)
  }

  return (
    <React.Fragment>
      {renderTitleAndDescription()}
      <Formik
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={true}
        enableReinitialize={!isDirty}
        isInitialValid={validationSchema.isValidSync(initialValues)}
      >
        {formikProps => (
          <CreateAssignmentForm
            members={members}
            exercises={shouldUseTemaToc ? temaToc : exercises}
            setIsDirty={setFormIsDirty}
            stickers={stickers}
            classroomFeatures={classroomFeatures}
            activeProduct={activeProduct}
            handleSaveAsDraft={handleSaveAsDraft}
            selectGoalsForAdapt={selectGoalsForAdapt}
            setSelectGoalsForAdapt={setSelectGoalsForAdapt}
            handlePreview={handlePreview}
            isCreator={isCreator}
            noStudentsMessage={`Det finns inga elever i klassrummet med ${
              activeProduct.niceName || activeProduct.title
            } aktiverad. Det går bra att spara uppdraget som utkast och välja elever senare.`}
            {...formikProps}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  assignmentId: selectCurrentAssignmentId(state),
  currentRoute: selectCurrentRoute(state),
  members: membersSelector.selectStudentsWithProduct(state),
  exercises: exercisesSelector.selectAllExercises(state),
  temaToc: exercisesSelector.selectTemaToc(state),
  assignments: assignmentsSelectors.selectAllAssignments(state),
  stickers: selectClassroomStickers(state),
  classroomFeatures: selectClassroomFeatures(state),
  classroomId: selectClassroomId(state),
  pendingImport: assignmentsSelectors.selectPendingImportAssignment(state),
  activeProduct: classroomSelectors.selectClassroomActiveProductInfo(state),
  shouldUseTemaToc:
    classroomSelectors.selectActiveProductShouldUseTemaToc(state)
})

const mapDispatchToProps: DispatchProps = {
  createAssignment: assignmentsActions.createAssignment,
  editAssignment: assignmentsActions.editAssignment,
  resetImportedAssignment: assignmentsActions.resetImportedAssignment,
  setIsCreateFormDirty: createEditAssignmentActions.setIsCreateFormDirty,
  storePreview: assignmentsActions.storePreview
}

const ConnectedCreateEditAssignment = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditAssignment)

export default ConnectedCreateEditAssignment
