import * as React from "react"
import { useSelector } from "react-redux"

import { RootState } from "../../../modules/store"
import { selectMemberHasAnyProducts } from "../../../modules/members/selectors"
import { Typography } from "@mui/material"

type Props = {
  memberId: number
}

export const ProductNotActiveText: React.FC<Props> = props => {
  const inactiveString = useSelector((store: RootState) =>
    selectMemberHasAnyProducts(store, props)
  )

  return <Typography>{inactiveString}</Typography>
}
