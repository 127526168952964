import React, { useState, useEffect } from "react"
import {
  FormativeSession,
  FormativeTest,
  ParsedFormativeProgressionAnswers
} from "../../modules/formative/types"
import { useSelector, useDispatch } from "react-redux"
import {
  selectTestInRouteByStudent,
  selectAnswersForTestInRouteForMember
} from "../../modules/formative/selectors"
import { getLatestSession } from "../FormativeQuestions/helpers"
import {
  StyledFinishedTextWrapper,
  StyledFinishedText,
  StyledHeaderContainer,
  StyledFormControl,
  StyledTestNameContainer,
  StyledTestTitleTagContainer,
  StyledPaper,
  StyledPaperContent,
  StyledResultsTitle,
  StyledResultsButtonsWrapper,
  StyledProgressWrapper,
  StyledLegendContainer
} from "../FormativeQuestionsResult/StyledFormativeQuestionsResult"
import { StyledTitle } from "./StyledFormativeQuestionsStudentResult"
import LastActive from "../../components/LastActive"
import {
  InputLabel,
  Select,
  MenuItem,
  Divider,
  SelectChangeEvent
} from "@mui/material"
import FormativeStatusTag from "../FormativeQuestions/FormativeStatusTag"
import {
  selectAllMembers,
  selectStudentByPayload
} from "../../modules/members/selectors"
import { Member } from "../../modules/members/types"
import Avatar from "../../components/Avatar"
import { buildName } from "../../shared/tools/strings"
import Button from "../../components/Button"
import FormativeQuestionAnswers from "../FormativeQuestionsResult/FormativeQuestionAnswers"
import { getPreviousSessionFromSelected } from "../FormativeQuestionsResult/helpers"
import FormativeResultProgress from "../../components/FormativeResultProgress"
import { getProgressForBar } from "./helpers"
import InfoPopover from "../../components/InfoPopover"
import FormativeQuestionAnswerTag from "../FormativeQuestionsResult/FormativeQuestionAnswerTag"
import Pagination from "../AssignmentStudentCard/Pagination"
import * as routerActions from "../../modules/router/actions"
import { selectClassroomId } from "../../modules/classroom/selectors"
import { selectCurrentTestId } from "../../modules/router/selectors"
import { getTest } from "../../modules/formative/actions"
const NOOP = () => {}

const FormativeQuestionsStudentResult = () => {
  const [selectedSession, setSelectedSession] =
    useState<FormativeSession | null>(null)
  const [showAnswers, setShowAnswers] = useState<boolean>(false)
  const test = useSelector(selectTestInRouteByStudent)
  const routeTestId = useSelector(selectCurrentTestId)
  const student = useSelector(selectStudentByPayload)
  const students = useSelector(selectAllMembers)
  const classroomId = useSelector(selectClassroomId)
  const answers = useSelector(selectAnswersForTestInRouteForMember)

  const dispatch = useDispatch()
  useEffect(() => {
    if (test && !selectedSession) {
      const latestSession = getLatestSession(test.sessions)
      setSelectedSession(latestSession)
    }
    // eslint-disable-next-line
  }, [test])

  useEffect(() => {
    if (routeTestId) {
      dispatch(getTest({ id: routeTestId }))
    }
  }, [routeTestId, dispatch])

  const onChangeSession = (event: SelectChangeEvent<number>) => {
    if (test) {
      const selected = test.sessions.find(
        session => session.id === event.target.value
      )
      setSelectedSession(selected ? selected : null)
    }
  }

  const getMenuItemName = (
    session: FormativeSession,
    renderTest: FormativeTest
  ) => {
    const latestSession = getLatestSession(renderTest.sessions)
    if (latestSession.id === session.id) {
      return renderTest.status === "ongoing" ? "Pågående" : "Senaste"
    }
    return (
      <StyledFinishedTextWrapper>
        <StyledFinishedText style={{ marginRight: "3px", display: "flex" }}>
          Avslutat
        </StyledFinishedText>
        <LastActive date={session.endedAt} />
      </StyledFinishedTextWrapper>
    )
  }

  const renderHeader = (
    renderTest: FormativeTest,
    renderStudent: Member,
    renderSession: FormativeSession,
    renderAnswers: ParsedFormativeProgressionAnswers
  ) => {
    const latestSession = getLatestSession(renderTest.sessions)
    const progressForBar = getProgressForBar(
      renderSession.id,
      renderTest,
      renderAnswers
    )
    return (
      <StyledHeaderContainer>
        {renderTest.sessions.length > 1 && (
          <StyledFormControl variant="outlined">
            {selectedSession && (
              <>
                <InputLabel id="testsession-label">
                  Välj testtillfälle
                </InputLabel>
                <Select
                  labelId="testsession-label"
                  label="Välj testtillfälle"
                  id="book-menu"
                  value={selectedSession.id}
                  onChange={onChangeSession}
                >
                  {renderTest.sessions.map(session => {
                    return (
                      <MenuItem key={session.id} value={session.id}>
                        {getMenuItemName(session, renderTest)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </>
            )}
          </StyledFormControl>
        )}

        <StyledTestNameContainer>
          <StyledTestTitleTagContainer>
            <Avatar
              firstName={renderStudent.firstName}
              lastName={renderStudent.lastName}
              picture={renderStudent.picture}
              studliId={renderStudent.studliId}
            />
            <StyledTitle variant="h1">
              {buildName(renderStudent.firstName, renderStudent.lastName)}
            </StyledTitle>
            <FormativeStatusTag
              status={renderTest.status}
              numberOfSessions={renderTest.sessions.length}
              endsAt={latestSession.endedAt}
              onEndedCounter={NOOP}
            />
          </StyledTestTitleTagContainer>
          {progressForBar && (
            <StyledProgressWrapper>
              <InfoPopover
                type="Info"
                content={
                  <div>
                    <StyledLegendContainer>
                      <FormativeQuestionAnswerTag answerType="correct" />
                      Rätt svar
                    </StyledLegendContainer>
                    <StyledLegendContainer>
                      <FormativeQuestionAnswerTag answerType="wrong" />
                      Fel svar
                    </StyledLegendContainer>
                    <StyledLegendContainer>
                      <FormativeQuestionAnswerTag answerType="rating" />
                      Angivet svar som inte rättas
                    </StyledLegendContainer>
                    <StyledLegendContainer>
                      <FormativeQuestionAnswerTag answerType="notAnswered" />
                      Inget svar finns registrerat
                    </StyledLegendContainer>
                  </div>
                }
              />
              <FormativeResultProgress
                endTime=""
                current
                type="multiple"
                displayType="student"
                onOpenProgressDialog={NOOP}
                correct={progressForBar.correct}
                wrong={progressForBar.wrong}
                rating={progressForBar.rating}
                notAnswered={progressForBar.notAnswered}
              />
            </StyledProgressWrapper>
          )}
        </StyledTestNameContainer>
      </StyledHeaderContainer>
    )
  }
  const renderResults = (renderTest: FormativeTest) => {
    return (
      <StyledPaper>
        <StyledPaperContent>
          <StyledResultsTitle variant="h2">Resultat</StyledResultsTitle>
        </StyledPaperContent>
        <Divider />
        {answers && selectedSession && (
          <>
            <StyledResultsButtonsWrapper>
              <Button
                onClick={() => setShowAnswers(!showAnswers)}
                variant="outlined"
                color="primary"
              >
                {showAnswers ? "Dölj alla svar" : "Visa alla svar"}
              </Button>
            </StyledResultsButtonsWrapper>
            <FormativeQuestionAnswers
              test={renderTest}
              answers={answers}
              type="student"
              showAnswers={showAnswers}
              session={selectedSession}
              prevSession={getPreviousSessionFromSelected(
                renderTest,
                selectedSession
              )}
            />
          </>
        )}
      </StyledPaper>
    )
  }

  const back = (testId: number, memberId: number) => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR_ELEV_INFO, {
        classroomId,
        testId,
        memberId
      })
    )
  }

  const forward = (testId: number, memberId: number) => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR_ELEV_INFO, {
        classroomId,
        testId,
        memberId
      })
    )
  }

  const renderPagination = (
    renderStudent: Member,
    renderSession: FormativeSession
  ) => {
    const currentTestMembers = renderSession.members.filter(
      m => students.findIndex(s => s.studliId === m) !== -1
    )
    const currentIndex = currentTestMembers.findIndex(
      member => member === renderStudent.studliId
    )
    return (
      <Pagination
        current={currentIndex + 1}
        total={currentTestMembers.length}
        disableBack={currentIndex === 0}
        disableForward={currentTestMembers.length === currentIndex + 1}
        back={() =>
          back(renderSession.testId, currentTestMembers[currentIndex - 1])
        }
        forward={() =>
          forward(renderSession.testId, currentTestMembers[currentIndex + 1])
        }
      />
    )
  }

  return (
    <>
      {student && selectedSession && renderPagination(student, selectedSession)}
      {test &&
        student &&
        selectedSession &&
        answers &&
        renderHeader(test, student, selectedSession, answers)}
      {test && renderResults(test)}
    </>
  )
}

export default FormativeQuestionsStudentResult
