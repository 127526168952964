export const getTime = (time: number, includeString: boolean) => {
  const hr = Math.floor(time / 3600)
  const min = Math.floor((time - hr * 3600) / 60)
  const sec = Math.floor(time - hr * 3600 - min * 60)

  if (hr > 0) {
    return `${includeString ? "Tid: " : ""}${hr} tim ${min} min.`
  }

  if (!min) {
    return `${includeString ? "Tid: " : ""}${sec} sek.`
  }

  return `${includeString ? "Tid: " : ""}${min} min ${sec} sek.`
}
