import React from "react"
import { connect } from "react-redux"
import { ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { RootState } from "../../modules/store"
import { Members, Member } from "../../modules/members/types"
import StyledList from "./StyledTeachersList"
import Avatar from "../Avatar"
import { buildName } from "../../shared/tools/strings"
import { User } from "../../modules/users/types"
import * as userSelectors from "../../modules/users/selectors"

type OwnProps = {
  teachers: Members
}

type StateProps = {
  authenticatedUser: User | undefined
}

type Props = OwnProps & StateProps

const TeachersList = ({ teachers }: Props) => {
  const renderListItem = (teacher: Member) => {
    return (
      <ListItem divider={true} key={teacher.studliId}>
        <ListItemAvatar>
          <Avatar
            firstName={teacher.firstName}
            lastName={teacher.lastName}
            picture={teacher.picture}
            studliId={teacher.studliId}
          />
        </ListItemAvatar>
        <ListItemText
          primary={buildName(teacher.firstName, teacher.lastName)}
          secondary={teacher.email}
        />
      </ListItem>
    )
  }

  return (
    <StyledList>{teachers.map(teacher => renderListItem(teacher))}</StyledList>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    authenticatedUser: userSelectors.selectAuthenticatedUser(state)
  }
}

const ConnectedTeachersList = connect(mapStateToProps)(TeachersList)

export default ConnectedTeachersList
