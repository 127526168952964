import React, { useMemo } from "react"
import { TableCell, Checkbox, Stack, Typography } from "@mui/material"
import {
  AssignmentWithStatusAndProgression,
  AssignmentsWithStatusAndProgression,
  Assignment
} from "../../modules/assignments/types"
import Progress, { SimpleProgress } from "../Progress"
import { StyledStatusTag } from "../../pages/Assignments/StyledAssignments"
import { booleanToString } from "../../shared/tools/strings"
import MoreMenu, { MenuItems } from "../PopupMenu/PopupMenu"
import { calculatedStatusTag } from "../../pages/Assignments/common/helpers"
import {
  StyledMenuTableCell,
  StyledTableRow,
  StyledTitleCell,
  StyledStatusCell,
  StyledSmallCell,
  StyledSticker,
  StyledTitle,
  StyledErrorMsg
} from "./StyledAssignmentsTableRow"
import { Group, Stickers } from "../../modules/classroom/types"
import { StyledAssignmentIcon } from "../AssignmentsListItem/StyledAssignmentsListItem"
import MessageIndicator from "../MessageIndicator"
import GroupChip from "../GroupChip/GroupChip"
import Avatar from "../Avatar"
import IsPublicIcon from "../IsPublicIcon/IsPublicIcon"
import { useSelector } from "react-redux"
import {
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import ErrorIcon from "@mui/icons-material/Error"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import { sortGroupsOnActive } from "../GroupChip/utils"

type Props = {
  assignment: AssignmentWithStatusAndProgression
  onCheckboxClick: (
    event: any,
    assignment: AssignmentWithStatusAndProgression
  ) => void
  checkedAssignments: AssignmentsWithStatusAndProgression
  moreMenuOptions: MenuItems
  onAssignmentClick: (assignmentId: Assignment["id"]) => void
  onProgressClick: (assignment: AssignmentWithStatusAndProgression) => void
  type: "list" | "studentCard"
  stickers?: Stickers
  needManualModification?: boolean
  isTestClassroom: boolean
  assignmentGroups?: Group[]
}

const AssignmentsTableRow = ({
  assignment,
  onCheckboxClick,
  checkedAssignments,
  moreMenuOptions,
  onAssignmentClick,
  onProgressClick,
  type,
  stickers,
  needManualModification,
  isTestClassroom,
  assignmentGroups
}: Props) => {
  const groups = useSelector(selectAllGroups)
  const user = useSelector(selectAuthenticatedUser)
  const allActiveGroups = useSelector(selectAllActiveGroups)

  const checked = checkedAssignments.some(
    checkedAssignment => checkedAssignment.id === assignment.id
  )
  let status = "notStarted"
  const inProgress = assignment.progress.inProgress.length
  const done = assignment.progress.done.length
  const notStarted = assignment.progress.notStarted.length
  const approved = assignment.progress.approved.length
  if (approved > done) {
    status = "approved"
  } else if (done > inProgress) {
    status = "done"
  } else if (inProgress > notStarted) {
    status = "inProgress"
  }
  const showMissingGroups = useMemo(() => {
    if (type === "studentCard") return false
    return (
      assignment.participantsInfo.some(
        p => !groups.some(g => p.groups.includes(g.id))
      ) && assignment.createdBy === user?.studliId
    )
  }, [assignment, groups, user])

  const hasProgress = useMemo(() => {
    return Boolean(inProgress || done || notStarted || approved)
  }, [inProgress, done, notStarted, approved])

  const sticker = (stickers || []).find(s => s.id === assignment.stickerId)

  const renderProgress = () =>
    hasProgress ? (
      <Progress
        onOpenProgressDialog={() => onProgressClick(assignment)}
        done={done + approved}
        type="assignments"
        inProgress={inProgress}
        notStarted={notStarted}
      />
    ) : (
      <></>
    )

  return (
    <StyledTableRow
      onClick={() => onAssignmentClick(assignment.id)}
      active={booleanToString(checked)}
      redbar={needManualModification ? 1 : 0}
      missingGroups={showMissingGroups}
    >
      {isTestClassroom && (
        <StyledSmallCell>
          <Checkbox
            onClick={event => onCheckboxClick(event, assignment)}
            checked={checked}
          />
        </StyledSmallCell>
      )}

      {type === "studentCard" && (
        <TableCell>
          {sticker ? (
            <StyledSticker alt={sticker.name} src={sticker.path} />
          ) : (
            <StyledAssignmentIcon />
          )}
        </TableCell>
      )}
      <StyledTitleCell>
        <StyledTitle>
          {assignment.title}
          {needManualModification && (
            <>
              <br />
              <StyledErrorMsg>
                Innehållet i uppdraget är förändrat. Se över deluppgifterna.
              </StyledErrorMsg>
            </>
          )}
        </StyledTitle>
        {assignment.participants.length > 0 && (
          <MessageIndicator assignmentId={assignment.id} />
        )}
      </StyledTitleCell>
      <StyledStatusCell>
        <StyledStatusTag status={assignment.calculatedStatus}>
          {calculatedStatusTag(
            assignment.calculatedStatus,
            assignment.startDate,
            assignment.endDate
          )}
        </StyledStatusTag>
      </StyledStatusCell>
      {type === "list" && (
        <TableCell>
          <Stack direction="row" spacing={1}>
            {showMissingGroups ? (
              <Stack direction="row" spacing={1} sx={{ color: "#DA1E28" }}>
                <ErrorIcon /> <Typography>Elever saknar grupp</Typography>
              </Stack>
            ) : (
              <GroupChip
                groups={sortGroupsOnActive(assignmentGroups, allActiveGroups)}
                truncate
              />
            )}
          </Stack>
        </TableCell>
      )}
      <TableCell>
        {type === "studentCard" ? (
          <SimpleProgress status={status} type="assignment" />
        ) : (
          renderProgress()
        )}
      </TableCell>
      {type === "list" && (
        <StyledMenuTableCell>
          {assignment.createdByFirstName && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                studliId={assignment.createdBy}
                firstName={assignment.createdByFirstName}
                lastName={assignment.createdByLastName}
                picture={assignment.createdByPictureUrl}
              />
              <IsPublicIcon
                isPublic={assignment.isPublic}
                createdBy={assignment.createdBy}
              />
            </Stack>
          )}
        </StyledMenuTableCell>
      )}
      {type === "list" && (
        <StyledMenuTableCell>
          <MoreMenu menuItems={moreMenuOptions} />
        </StyledMenuTableCell>
      )}
    </StyledTableRow>
  )
}

export default AssignmentsTableRow
