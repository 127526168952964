import React from "react"
import { connect } from "react-redux"

import { RootState } from "../../modules/store"
import * as routerSelectors from "../../modules/router/selectors"

import { ListItemProps } from "@mui/material/ListItem"
import { LinkProps } from "redux-first-router-link"

import {
  StyledNavigationListItem,
  StyledListItem,
  StyledListItemIcon,
  ListText
} from "./StyledNavigationListItem"

type OwnProps = {
  to: { type: string; payload?: { classroomId: number; helpPage?: string } }
  text: string
  selected?: boolean
  disabled?: boolean
  icon?: React.ReactElement
  onClick?: () => void
}

type StateProps = {
  classroomId: number
}

type Props = OwnProps & StateProps & ListItemProps & LinkProps

/**
 * This component combines Redux-first-router's Link (RLink) with Material-ui's ListItem
 * The former takes of the routing, the latter handles the styling
 *
 * @param to - {type: string; payload?: object} - for the RLink component
 * @param text {String} - Label text
 * @param icon {ReactElement} - The icon to show in front of the label-text
 * @param disabled {boolean?} - If true the whole component will be disabled
 * @param classroomId {number} - Id of the current classroom
 */
const NavigationListItem = ({
  to,
  text,
  icon,
  selected,
  disabled,
  classroomId,
  onClick
}: Props) => {
  const toWithPayloadGuaranteed = Object.assign({}, to)
  toWithPayloadGuaranteed.payload = toWithPayloadGuaranteed.payload || {
    classroomId: classroomId
  }

  return (
    <StyledNavigationListItem to={toWithPayloadGuaranteed} tabIndex={0}>
      <StyledListItem
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 1,
          paddingRight: 1,
          height: "3.5rem"
        }}
        color="black"
        button
        disabled={disabled}
        selected={!disabled && selected}
        tabIndex={-1}
        onClick={onClick}
      >
        {icon && <StyledListItemIcon>{icon}</StyledListItemIcon>}
        <ListText>{text}</ListText>
      </StyledListItem>
    </StyledNavigationListItem>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => ({
  classroomId: routerSelectors.selectCurrentClassroomId(state)
})

const ConnectedNavigationListItem = connect(mapStateToProps)(NavigationListItem)

export default ConnectedNavigationListItem
