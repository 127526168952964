import { SetGoalFilterAction, FilterBy } from "./types"

/**
 * Actions on UI only
 */
export enum UI {
  SET_GOAL_FILTER = "SET_GOAL_FILTER"
}

export const setGoalFilter = (payload: {
  value: FilterBy
}): SetGoalFilterAction => {
  return { type: UI.SET_GOAL_FILTER, payload }
}
