import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Grid } from "@mui/material"
import {
  StyledQuestionListItem,
  StyledChapterProgressGrid,
  StyledChapterText,
  StyledHideAnswerButton,
  StyledProgressContainer,
  FormativePrevAnswerWrapper
} from "../../components/FormativeQuestionsListItem/StyledFormativeQuestionsListItem"
import {
  StyledAnswerGrid,
  StyledStudentName,
  StyledAnswerStudentGrid,
  StyledEndQuestion,
  StyledAnswerWrapperGrid,
  StyledQuestionNumber,
  StyledEndQuestionTagWrapper
} from "./StyledFormativeQuestionsResult"
import FormativeResultProgress from "../../components/FormativeResultProgress"
import {
  FormativeTest,
  ParsedFormativeProgressionAnswers,
  FormativeSession
} from "../../modules/formative/types"
import AudioPlayer from "../../components/AudioPlayer"
import HtmlRenderer from "../../components/HtmlRenderer"
import { selectStudentsWithProduct } from "../../modules/members/selectors"
import Avatar from "../../components/Avatar"
import { buildName } from "../../shared/tools/strings"
import { ProgressItem } from "../../components/FormativeQuestionsListItem/FormativeQuestionAnswerItem"
import LastActive from "../../components/LastActive"
import FormativeQuestionAnswerTag from "./FormativeQuestionAnswerTag"
import { StyledLastActiveContainer } from "../../components/FormativeResultProgress/StyledFormativeResultProgress"

type Props = {
  test: FormativeTest
  showAnswers: boolean
  endQuestionAnswers: ParsedFormativeProgressionAnswers
  prevEndQuestionAnswers: ParsedFormativeProgressionAnswers | null
  session: FormativeSession
  prevSession: FormativeSession | null
  setProgressDialog: (progress: ProgressItem) => void
  type: "student" | "class"
}

type EndQuestionParsedAnswer = {
  answer: { text?: string; useraudio?: string[] }
}

const FormativeEndQuestionResult = ({
  showAnswers,
  test,
  endQuestionAnswers,
  prevEndQuestionAnswers,
  session,
  prevSession,
  setProgressDialog,
  type
}: Props) => {
  const [showAnswer, setShowAnswer] = useState<boolean>(true)
  const parsedEndQuestion: { text: string; audio: string } = test.endQuestion
    ? JSON.parse(test.endQuestion)
    : { text: "", audio: "" }
  const students = useSelector(selectStudentsWithProduct)

  useEffect(() => {
    setShowAnswer(showAnswers)
  }, [showAnswers])

  const renderProgressDialogs = () => {
    const mainProgress: ProgressItem = {
      correct: endQuestionAnswers.map(answer => answer.memberId),
      wrong: [],
      notAnswered: session.members.filter(
        member => !endQuestionAnswers.some(answer => answer.memberId === member)
      ),
      type: "rating",
      endTime: ""
    }
    let prevProgress: ProgressItem | null = null
    if (prevSession) {
      prevProgress = {
        correct: prevEndQuestionAnswers
          ? prevEndQuestionAnswers.map(answer => answer.memberId)
          : [],
        wrong: [],
        notAnswered: prevEndQuestionAnswers
          ? prevSession.members.filter(
              member =>
                !prevEndQuestionAnswers.some(
                  answer => answer.memberId === member
                )
            )
          : [...prevSession.members],
        type: "rating",
        endTime: ""
      }
    }
    return (
      <StyledProgressContainer>
        <FormativeResultProgress
          correct={mainProgress.correct.length}
          wrong={mainProgress.wrong.length}
          notAnswered={mainProgress.notAnswered.length}
          type={mainProgress.type}
          displayType="class"
          endTime={mainProgress.endTime}
          onOpenProgressDialog={() => setProgressDialog(mainProgress)}
          current={true}
        />
        {prevProgress && (
          <FormativeResultProgress
            correct={prevProgress.correct.length}
            wrong={prevProgress.wrong.length}
            notAnswered={prevProgress.notAnswered.length}
            type={prevProgress.type}
            displayType="class"
            endTime={prevProgress.endTime}
            onOpenProgressDialog={() =>
              setProgressDialog(prevProgress as ProgressItem)
            }
            current={false}
          />
        )}
      </StyledProgressContainer>
    )
  }

  const renderAnswer = (
    questionAnswers: ParsedFormativeProgressionAnswers,
    current: boolean
  ) => {
    return questionAnswers.map(answer => {
      const foundStudent = students.find(
        student => student.studliId === answer.memberId
      )
      const parsedAnswer = answer.answer
        .questions[0] as unknown as EndQuestionParsedAnswer
      return (
        <React.Fragment key={answer.questionId}>
          <StyledAnswerStudentGrid
            opacity={current ? 0 : 1}
            sm={4}
            xs={12}
            item
          >
            {foundStudent && type === "class" && (
              <>
                <Avatar
                  firstName={foundStudent.firstName}
                  lastName={foundStudent.lastName}
                  picture={foundStudent.picture}
                  studliId={foundStudent.studliId}
                />
                <StyledStudentName>
                  {buildName(foundStudent.firstName, foundStudent.lastName)}
                </StyledStudentName>
              </>
            )}
            {type === "student" && prevSession && (
              <>
                {current ? (
                  "Pågående"
                ) : (
                  <LastActive date={prevSession.endedAt} />
                )}
              </>
            )}
          </StyledAnswerStudentGrid>
          <StyledAnswerWrapperGrid
            opacity={current ? 0 : 1}
            sm={6}
            xs={12}
            item
          >
            {parsedAnswer.answer.text && (
              <HtmlRenderer html={parsedAnswer.answer.text} />
            )}
            {parsedAnswer.answer.useraudio &&
              parsedAnswer.answer.useraudio.map(audio => (
                <AudioPlayer label="Slutfråga svar" src={audio} />
              ))}
          </StyledAnswerWrapperGrid>
        </React.Fragment>
      )
    })
  }
  return (
    <StyledQuestionListItem divider>
      <Grid container spacing={3}>
        <StyledChapterProgressGrid item xs={12}>
          <StyledChapterText>Slutfråga</StyledChapterText>
          {type === "class" && renderProgressDialogs()}
          {type === "student" && (
            <StyledEndQuestionTagWrapper>
              <FormativeQuestionAnswerTag
                answerType={
                  endQuestionAnswers.length ? "rating" : "notAnswered"
                }
              />
              {prevSession && (
                <FormativePrevAnswerWrapper>
                  <StyledLastActiveContainer>
                    <LastActive date={prevSession.endedAt} />:
                  </StyledLastActiveContainer>
                  <FormativeQuestionAnswerTag
                    answerType={
                      prevEndQuestionAnswers && prevEndQuestionAnswers.length
                        ? "rating"
                        : "notAnswered"
                    }
                    opacity
                  />
                </FormativePrevAnswerWrapper>
              )}
            </StyledEndQuestionTagWrapper>
          )}
        </StyledChapterProgressGrid>
        <Grid item xs={12}>
          {parsedEndQuestion.text && (
            <StyledEndQuestion variant="body1">
              <StyledQuestionNumber>
                {test.questions.length + 1 + "."}
              </StyledQuestionNumber>
              <HtmlRenderer html={parsedEndQuestion.text} />
            </StyledEndQuestion>
          )}
          {parsedEndQuestion.audio && (
            <AudioPlayer label="slutfråga" src={parsedEndQuestion.audio} />
          )}
        </Grid>
        <Grid item xs={12}>
          <StyledHideAnswerButton onClick={() => setShowAnswer(!showAnswer)}>
            {showAnswer ? "Dölj svar" : "Visa svar"}
          </StyledHideAnswerButton>
        </Grid>
        <StyledAnswerGrid show={showAnswer ? 1 : 0} container xs={12}>
          {renderAnswer(endQuestionAnswers, true)}
          {type === "student" &&
            prevEndQuestionAnswers &&
            renderAnswer(prevEndQuestionAnswers, false)}
        </StyledAnswerGrid>
      </Grid>
    </StyledQuestionListItem>
  )
}

export default FormativeEndQuestionResult
