import { styled } from "@mui/material"

export const StyledProductImageWrapper = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const StyledCoverImageWrapper = styled("div")`
  display: inline-block;
  max-height: 3rem;
`

export const StyledCoverImage = styled("img")`
  height: auto;
  width: auto;
  max-width: 1.5rem;
  max-height: 2.5rem;
`
