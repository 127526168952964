import { Assignment } from "../../modules/assignments/types"
import { ExerciseNode } from "../../modules/exercises/types"
import { useSelector, useDispatch } from "react-redux"
import {
  selectClassroomStickers,
  selectClassroomId
} from "../../modules/classroom/selectors"
import { selectAllAssignments } from "../../modules/assignments/selectors"
import { useState, useMemo, useEffect } from "react"
import { selectAssignmentProgressByStudent } from "../../modules/assignmentsProgress/selectors"
import moment, { Moment } from "moment"
import { AssignmentProgress } from "../../modules/assignmentsProgress/types"
import { Stickers } from "../../modules/classroom/types"
import { selectExercisePaths } from "../../modules/exercises/selectors"
import { selectExerciseStatesByStudentId } from "../../modules/exercisesProgress/selectors"
import {
  selectStudentCardMember,
  selectStudentByPayload
} from "../../modules/members/selectors"
import {
  ExerciseProgress,
  ExerciseState
} from "../../modules/exercisesProgress/types"

import {
  gotoRoute,
  ROUTE_UPPDRAG_STUDENT_INFO,
  ROUTE_FORMATIVA_FRAGOR_ELEV_INFO,
  ROUTE_GOALS_DETAILS
} from "../../modules/router/actions"
import { selectActiveProductName } from "../../modules/products/selectors"
import { Member } from "../../modules/members/types"
import {
  selectTestsByStudent,
  selectProgressionByStudent
} from "../../modules/formative/selectors"
import { FormativeTest } from "../../modules/formative/types"
import { selectGoals } from "../../modules/goals/selectors"
import { selectFinishedProgressForCurrentStudent } from "../../modules/goalsProgress/selectors"
import { Goal } from "../../modules/goals/types"
import { GoalProgress } from "../../modules/goalsProgress/types"

export type StudentHistoryItems = StudentHistoryItem[]
export type StudentHistoryItem = {
  type: "assignment" | "exercise" | "formativeTest" | "goal"
  content:
    | AssignmentProgressWithAssignment
    | ExerciseProgress
    | ExerciseStateWithExerciseData
    | FormativeTest
    | GoalProgressWithGoal
  status?: string
  sortTime: Moment
}

export interface AssignmentProgressWithAssignment extends AssignmentProgress {
  assignmentData?: Assignment
}

export type GoalProgressWithGoal = Goal & GoalProgress
export interface ExerciseStateWithExerciseData extends ExerciseState {
  exerciseData?: ExerciseNode
  path?: string[]
  moreExercises?: ExerciseStateWithExerciseData[]
}

export type Result = {
  studentHistoryList: {
    historyItems: StudentHistoryItems
    totalNumberOfItems: number
  }
  stickers: Stickers
  goToStudentAssignment: Function
  incrementNumberOfItemsToShow: () => void
  member: Member
  activeProduct: string
  goToFormativeTest: (testId: number) => void
  goToGoal: (goalRef: string) => void
}

const defaultValues = {
  numberOfItems: 25,
  incrementValue: 10
}

function useStudentHistoryList(): Result {
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState<number>(
    defaultValues.numberOfItems
  )
  const currentMember = useSelector(selectStudentCardMember)
  const formativeTests = useSelector(selectTestsByStudent)
  const formativeProgress = useSelector(selectProgressionByStudent)
  const stickers = useSelector(selectClassroomStickers)
  const assignments = useSelector(selectAllAssignments)
  const assignmentsProgressForStudent = useSelector(
    selectAssignmentProgressByStudent
  )
  const activeProduct = useSelector(selectActiveProductName)
  const goalsProgress = useSelector(selectFinishedProgressForCurrentStudent)
  const goals = useSelector(selectGoals)
  const member = useSelector(selectStudentByPayload)
  const exerciseStatesForStudent = useSelector(selectExerciseStatesByStudentId)
  const exercisePaths = useSelector(selectExercisePaths)
  const classroomId = useSelector(selectClassroomId)
  const dispatch = useDispatch()

  useEffect(() => {
    setNumberOfItemsToShow(defaultValues.numberOfItems)
  }, [currentMember])

  const assignmentProgressWithData: AssignmentProgressWithAssignment[] =
    useMemo(() => {
      const assignmentsProgressWithAssignmentData: AssignmentProgressWithAssignment[] =
        assignmentsProgressForStudent
          .filter(progress =>
            assignments.find(a => a.id === progress.assignmentId)
          )
          .map(aProgress => {
            const assignmentId = aProgress.assignmentId
            const assignment = assignments.find(a => a.id === assignmentId)

            return assignment
              ? { ...aProgress, assignmentData: assignment }
              : aProgress
          })

      return assignmentsProgressWithAssignmentData || []
    }, [assignments, assignmentsProgressForStudent])

  const goalsHistoryItems: StudentHistoryItems = useMemo(() => {
    return goalsProgress.reduce((acc: any, curr) => {
      const goal = goals.find(goal => goal.goalRef === curr.goalRef)
      if (goal) {
        return [
          ...acc,
          {
            type: "goal",
            content: { ...curr, ...goal },
            status: "submitted",
            sortTime: curr.lastActive
          }
        ]
      }
      return acc
    }, [])
  }, [goals, goalsProgress])

  const assignmentsHistoryItems: StudentHistoryItems = useMemo(() => {
    return assignmentProgressWithData.reduce((res, p) => {
      // One assignment progress can generate up to two history entries
      if (p.submittedAt) {
        res = [
          ...res,
          {
            type: "assignment",
            content: p,
            status: "submitted",
            sortTime: p.submittedAt
          }
        ]
      }

      if (p.rejectedAt) {
        return [
          ...res,
          {
            type: "assignment",
            content: p,
            status: "rejected",
            sortTime: p.rejectedAt
          }
        ]
      }
      if (p.approvedAt) {
        return [
          ...res,
          {
            type: "assignment",
            content: p,
            status: "approved",
            sortTime: p.approvedAt
          }
        ]
      }

      return res
    }, [] as StudentHistoryItems)
  }, [assignmentProgressWithData])

  const formativeTestsHistory: StudentHistoryItems = useMemo(() => {
    return formativeProgress
      .filter(p => p.submittedAt && formativeTests.some(t => t.id === p.testId))
      .map(p => {
        const test = formativeTests.find(t => t.id === p.testId)
        return {
          type: "formativeTest",
          content: test as FormativeTest,
          status: "submitted",
          sortTime: moment(p.submittedAt as unknown as string)
        }
      })
  }, [formativeTests, formativeProgress])

  const exerciseStateHistoryItems: StudentHistoryItems = useMemo(() => {
    return exerciseStatesForStudent.map(
      (exerciseState: ExerciseStateWithExerciseData) => {
        const exe = exercisePaths.find(
          exercisePath => exercisePath.exerciseId === exerciseState.exerciseId
        )
        exerciseState.exerciseData = exe && exe.exercise
        exerciseState.path = exe && exe.path

        return {
          type: "exercise",
          content: exerciseState,
          status: "",
          sortTime: moment(exerciseState.modifiedAt)
        }
      }
    )
  }, [exercisePaths, exerciseStatesForStudent])

  const combineItemsToMakeHistoryList = () => {
    const assignmentsItems = assignmentsHistoryItems
    const exerciseItems = exerciseStateHistoryItems
    const goalsItems = goalsHistoryItems
    const historyItems = [
      ...assignmentsItems,
      ...exerciseItems,
      ...formativeTestsHistory,
      ...goalsItems
    ].sort(
      (a, b) => moment(b.sortTime).valueOf() - moment(a.sortTime).valueOf()
    )
    const compactHistoryList =
      groupExerciseItemsIfDirectlyRepeated(historyItems)

    return {
      historyItems: compactHistoryList.slice(0, numberOfItemsToShow),
      totalNumberOfItems: compactHistoryList.length
    }
  }

  const groupExerciseItemsIfDirectlyRepeated = (
    historyItems: StudentHistoryItems
  ) => {
    let groupItems = []

    const groupedHistoryList: StudentHistoryItems[] = []
    let compactHistoryList: StudentHistoryItems = []

    for (let i = 0; i < historyItems.length; i++) {
      const item = historyItems[i]
      const nextItem = historyItems[i + 1]

      if (item.type === "exercise") {
        const itemContent = item.content as ExerciseStateWithExerciseData
        const nextItemContent =
          nextItem && (nextItem.content as ExerciseStateWithExerciseData)

        groupItems.push(item)

        if (
          !nextItem ||
          nextItem.type !== "exercise" ||
          nextItemContent.exerciseId !== itemContent.exerciseId
        ) {
          groupedHistoryList.push(groupItems)
          groupItems = []
        }
      } else {
        groupedHistoryList.push([item])
      }
    }

    compactHistoryList = groupedHistoryList.map(historyItemArr => {
      if (historyItemArr.length === 1) {
        return historyItemArr[0]
      }

      const [parentItem, ...restOfItems] = historyItemArr
      const moreExercises = restOfItems.map(item => item.content)

      const parentWithMoreExercises =
        parentItem.content as ExerciseStateWithExerciseData
      parentWithMoreExercises.moreExercises =
        moreExercises as ExerciseStateWithExerciseData[]

      return parentItem
    })

    return compactHistoryList
  }

  const goToStudentAssignment = (assignmentId: Assignment["id"]) => {
    if (currentMember) {
      dispatch(
        gotoRoute(ROUTE_UPPDRAG_STUDENT_INFO, {
          assignmentId,
          classroomId,
          memberId: currentMember.studliId
        })
      )
    }
  }

  const goToFormativeTest = (testId: number) => {
    if (currentMember) {
      dispatch(
        gotoRoute(ROUTE_FORMATIVA_FRAGOR_ELEV_INFO, {
          testId,
          classroomId,
          memberId: currentMember.studliId
        })
      )
    }
  }

  const goToGoal = (goalRef: string) => {
    dispatch(
      gotoRoute(ROUTE_GOALS_DETAILS, {
        goalId: goalRef,
        classroomId
      })
    )
  }

  const incrementNumberOfItemsToShow = () =>
    setNumberOfItemsToShow(numberOfItemsToShow + defaultValues.incrementValue)

  return {
    studentHistoryList: combineItemsToMakeHistoryList(),
    stickers: stickers || [],
    goToStudentAssignment,
    incrementNumberOfItemsToShow,
    member,
    activeProduct,
    goToFormativeTest,
    goToGoal
  }
}

export default useStudentHistoryList
