import { createAction } from "../../shared/tools/redux"
import * as types from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_EXERCISES_PROGRESS_REQUEST = "GET_ALL_EXERCISES_PROGRESS_REQUEST"
}

export enum RESPONSE {
  GET_ALL_EXERCISES_PROGRESS_REQUEST_RESPONSE = "GET_ALL_EXERCISES_PROGRESS_REQUEST_RESPONSE"
}

export enum EVENT {
  EXERCISES_PROGRESS_UPDATE_EVENT = "EXERCISES_PROGRESS_UPDATE_EVENT",
  EXERCISES_PROGRESS_UPDATE = "EXERCISES_PROGRESS_UPDATE",
  READING_STATE_UPDATE = "READING_STATE_UPDATE",
  EXERCISE_STATE_UPDATE = "EXERCISE_STATE_UPDATE"
}

export enum SERVER_MESSAGE_ACTION {
  GET_ALL_EXERCISES_PROGRESS_REQUEST_REQUEST = "progress",
  GET_ALL_EXERCISES_PROGRESS_REQUEST_RESPONSE = "progress",
  EXERCISES_PROGRESS_UPDATE_EVENT = "progress_updated"
}

export enum UI {
  RESET_EXERCISES_PROGRESS = "RESET_EXERCISES_PROGRESS",
  SET_EXERCISES_PROGRESS = "SET_EXERCISES_PROGRESS"
}

export const getAllExercisesProgress = createAction(
  "GET_ALL_EXERCISES_PROGRESS_REQUEST"
)

export const resetExercisesProgress = createAction("RESET_EXERCISES_PROGRESS")
export const updateTemporaryProgress = createAction<
  types.ExercisesProgress | undefined
>("EXERCISES_PROGRESS_UPDATE_EVENT")
export const updateExerciseProgress = createAction<
  types.ExercisesProgress | undefined
>("EXERCISES_PROGRESS_UPDATE")
export const updateReadingState = createAction<types.ReadingStates | undefined>(
  "READING_STATE_UPDATE"
)
export const updateExerciseStates = createAction<types.ExercisesProgress>(
  "EXERCISE_STATE_UPDATE"
)
export const setExerciseProgress = createAction<{
  progress?: types.ExercisesProgress
  readingState?: types.ReadingStates
  history?: types.ExerciseStates
}>("SET_EXERCISES_PROGRESS")
