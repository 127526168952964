import * as types from "./types"
import * as actions from "./actions"
import { createReducer } from "../../shared/tools/redux"
import { setField } from "../../shared/tools/monocle"

const initialState: types.State = {
  jwt: ""
}

const setJwt = setField<types.State, { access_token: string }>(
  "jwt",
  p => ((p || {}) as { access_token: string }).access_token
)

const reducer = createReducer<types.State>(initialState, builder =>
  builder
    .case(actions.authTokenUpdatedEvent, setJwt)
    .case(actions.authTokenSuccess, setJwt)
)

export default reducer
