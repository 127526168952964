import React, { useCallback, useEffect, useMemo } from "react"
import {
  AssignmentsWithStatusAndProgression,
  AssignmentWithStatusAndProgression,
  Assignment,
  AssignmentMenuOptions
} from "../../modules/assignments/types"

import {
  Table,
  TableRow,
  TableBody,
  Checkbox,
  TableCell,
  Skeleton,
  TableSortLabel
} from "@mui/material"
import {
  StyledTableHead,
  StyledHeadCell
} from "../MembersTable/StyledMembersTable"
import {
  StyledLegendContainer,
  StyledLegendBox
} from "../GoalsTable/StyledGoalsTable"
import { PROGRESS_SEGMENT } from "../Progress/constants"
import TableToolbar from "../TableToolbar"
import AssignmentsTableRow from "../AssignmentsTableRow"
import { getMenuOptions } from "../../pages/Assignments/common/helpers"
import InfoPopover from "../InfoPopover"
import { Features, Stickers } from "../../modules/classroom/types"
import { useSelector } from "react-redux"
import { selectLoadingImportAssignment } from "../../modules/assignments/selectors"
import {
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import { FilterValue } from "../TableFilter/types"
import TableFilter from "../TableFilter/TableFilter"
import {
  getAssignmentStatusWeight,
  getStatusString,
  sortByLastName
} from "./helpers"
import { Members } from "../../modules/members/types"
import { buildName } from "../../shared/tools/strings"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import { tableFilter } from "../../shared/tools/filter"

type Sort = {
  orderBy: string
  order: "asc" | "desc"
}

type Props = {
  assignments: AssignmentsWithStatusAndProgression
  onCheckboxClick: (
    event: any,
    assignment: AssignmentWithStatusAndProgression
  ) => void
  checkedAssignments: AssignmentsWithStatusAndProgression
  toggleAllAssignments: (
    event: React.ChangeEvent<HTMLInputElement>,
    status: AssignmentWithStatusAndProgression["status"]
  ) => void
  tableTitle?: string
  onMenuClick: (type: AssignmentMenuOptions, id: Assignment["id"]) => void
  onAssignmentClick: (assignmentId: Assignment["id"]) => void
  onProgressClick: (assignment: AssignmentWithStatusAndProgression) => void
  classroomFeatures: Features
  isTestClassroom: boolean
  type?: "list" | "studentCard"
  stickers?: Stickers
  assignmentsThatNeedManualModification?: number[]
  showSkeleton?: boolean
  showFilter?: boolean
  members: Members
}

const AssignmentsTable = ({
  assignments,
  onCheckboxClick,
  checkedAssignments,
  toggleAllAssignments,
  tableTitle,
  onMenuClick,
  onAssignmentClick,
  onProgressClick,
  classroomFeatures,
  isTestClassroom,
  type = "list",
  stickers,
  assignmentsThatNeedManualModification = [],
  showSkeleton,
  showFilter,
  members
}: Props) => {
  const user = useSelector(selectAuthenticatedUser)

  const [sortedAssignments, setSortedAssignments] = React.useState(assignments)
  const [sortVal, setSortVal] = React.useState<Sort>({
    orderBy: "status",
    order: "asc"
  })
  const [currentFilters, setCurrentFilters] = React.useState<FilterValue[]>([])
  const isLoadingImport = useSelector(selectLoadingImportAssignment)
  const menuItemClick = (
    type: AssignmentMenuOptions,
    assignmentId: AssignmentWithStatusAndProgression["id"]
  ) => {
    onMenuClick(type, assignmentId)
  }
  const groups = useSelector(selectAllGroups)
  const activeGroups = useSelector(selectAllActiveGroups)

  const getAssignmentStatusFilterOptions = () => {
    return [
      ...Array.from(
        new Set(
          assignments.map(assignment => assignment.calculatedStatus as string)
        )
      )
    ].map((status: string) => ({
      label: getStatusString(status as any),
      val: status,
      key: "status",
      filterFunction: (a: AssignmentWithStatusAndProgression) => {
        return a.calculatedStatus === status
      }
    }))
  }

  const getAssignmentGroups = useCallback(
    (a: Assignment) => {
      const assignmentMembers = members.filter(m =>
        a.participants.includes(m.studliId)
      )
      const groupIds = Array.from(
        new Set(assignmentMembers.map(m => m.groups).flat())
      )
      return groups.filter(g => groupIds.includes(g.id))
    },
    [members, groups]
  )

  const initialGroupFilter = useMemo(() => {
    if (groups.length !== activeGroups.length) {
      return activeGroups.map(group => ({
        label: group.name,
        val: group.id,
        key: "groups",
        filterFunction: (a: Assignment) =>
          getAssignmentGroups(a).some(g => g.id === group.id)
      }))
    }
  }, [groups, activeGroups, getAssignmentGroups])

  const assignmentsCreatedBy = useMemo(() => {
    return [
      ...Array.from(
        new Set(
          assignments.map(a => ({
            createdBy: a.createdBy,
            createdByFirstName: a.createdByFirstName,
            createdByLastName: a.createdByLastName,
            createdByPictureUrl: a.createdByPictureUrl
          }))
        )
      ).filter(
        (createdBy, index, self) =>
          index === self.findIndex(c => c.createdBy === createdBy.createdBy)
      )
    ]
  }, [assignments])

  const getFilters = () => {
    return [
      {
        label: "Grupper",
        key: "groups",
        options: groups.map(group => ({
          label: group.name,
          val: group.id,
          key: "groups",
          filterFunction: (a: Assignment) =>
            getAssignmentGroups(a).some(g => g.id === group.id)
        }))
      },
      {
        label: "Status",
        key: "status",
        options: getAssignmentStatusFilterOptions()
      },
      {
        label: "Skapat av",
        key: "createdBy",
        options: assignmentsCreatedBy.map(createdBy => ({
          label: buildName(
            createdBy.createdByFirstName,
            createdBy.createdByLastName
          ),
          val: createdBy,
          key: "createdBy",
          filterFunction: (a: Assignment) => a.createdBy === createdBy.createdBy
        }))
      }
    ]
  }

  const onFilter = useCallback(
    (filterValues: FilterValue[]) => {
      return tableFilter(filterValues, assignments)
    },
    [assignments]
  )

  const onSort = (
    sort: Sort,
    filteredAssignments: AssignmentsWithStatusAndProgression
  ) => {
    const { orderBy, order } = sort
    const newOrder = order === "asc" ? "desc" : "asc"
    const sorted = [...filteredAssignments].sort((a, b) => {
      if (orderBy === "title") {
        return newOrder === "asc"
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title)
      }
      if (orderBy === "status") {
        return newOrder === "asc"
          ? getAssignmentStatusWeight(a.calculatedStatus) -
              getAssignmentStatusWeight(b.calculatedStatus)
          : getAssignmentStatusWeight(b.calculatedStatus) -
              getAssignmentStatusWeight(a.calculatedStatus)
      }
      return sortByLastName(newOrder, a.createdByLastName, b.createdByLastName)
    })
    return sorted
  }

  useEffect(() => {
    const filteredAssignments = onFilter(currentFilters)
    const sorted = onSort(sortVal, filteredAssignments)
    setSortedAssignments(sorted)
  }, [assignments, currentFilters, onFilter, sortVal])

  const setFilters = (filterValues: FilterValue[]) => {
    setCurrentFilters(filterValues)
  }

  const setSort = (orderBy: string) => {
    setSortVal(prev => ({
      orderBy,
      order:
        prev.orderBy === orderBy
          ? prev.order === "asc"
            ? "desc"
            : "asc"
          : "asc"
    }))
  }

  const searchFunction = (a: Assignment, searchText: string) =>
    a.title?.toLowerCase().includes(searchText.toLowerCase())

  const renderTableHeader = () => {
    return (
      <StyledTableHead>
        <TableRow>
          {isTestClassroom && (
            <StyledHeadCell>
              <Checkbox
                indeterminate={
                  checkedAssignments.length > 0 &&
                  checkedAssignments.length < assignments.length
                }
                onChange={e =>
                  toggleAllAssignments(
                    e,
                    assignments[0] && assignments[0].status
                  )
                }
                checked={
                  assignments.length > 0 &&
                  checkedAssignments.length === assignments.length
                }
              />
            </StyledHeadCell>
          )}
          {type === "studentCard" && <StyledHeadCell />}
          <StyledHeadCell>
            {type === "list" ? (
              <TableSortLabel
                active={sortVal.orderBy === "title"}
                direction={sortVal.orderBy === "title" ? sortVal.order : "asc"}
                onClick={() => setSort("title")}
              >
                Titel
              </TableSortLabel>
            ) : (
              "Titel"
            )}
          </StyledHeadCell>
          <StyledHeadCell>
            {type === "list" ? (
              <TableSortLabel
                active={sortVal.orderBy === "status"}
                direction={sortVal.orderBy === "status" ? sortVal.order : "asc"}
                onClick={() => setSort("status")}
              >
                Status
              </TableSortLabel>
            ) : (
              "Status"
            )}
          </StyledHeadCell>
          {type === "list" && <StyledHeadCell>Grupper</StyledHeadCell>}
          <StyledHeadCell>
            Elevernas aktivitet
            <InfoPopover
              type="Info"
              content={
                <div>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                    Inlämnat / Klar
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.IN_PROGRESS} />
                    Påbörjad
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.NOT_STARTED} />
                    Ej påbörjad
                  </StyledLegendContainer>
                </div>
              }
            />
          </StyledHeadCell>
          {type === "list" && (
            <StyledHeadCell>
              <TableSortLabel
                active={sortVal.orderBy === "createdBy"}
                direction={
                  sortVal.orderBy === "createdBy" ? sortVal.order : "asc"
                }
                onClick={() => setSort("createdBy")}
              >
                Skapat av
              </TableSortLabel>
            </StyledHeadCell>
          )}
          {type === "list" && <StyledHeadCell />}
        </TableRow>
      </StyledTableHead>
    )
  }

  const renderTableBody = () => {
    return (
      <TableBody>
        {isLoadingImport !== 0 &&
          showSkeleton &&
          Array.from(Array(isLoadingImport)).map((_, i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton
                  variant="text"
                  width="6rem"
                  sx={{ fontSize: "1rem" }}
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width="4rem"
                  sx={{ fontSize: "2rem" }}
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        {sortedAssignments.map(assignment => {
          return (
            <AssignmentsTableRow
              onCheckboxClick={onCheckboxClick}
              isTestClassroom={isTestClassroom}
              checkedAssignments={checkedAssignments}
              key={assignment.id}
              onAssignmentClick={onAssignmentClick}
              assignment={assignment}
              assignmentGroups={getAssignmentGroups(assignment)}
              onProgressClick={onProgressClick}
              moreMenuOptions={getMenuOptions(
                menuItemClick,
                assignment.id,
                assignment.calculatedStatus,
                assignment.isPublic,
                assignment.createdBy === user?.studliId
              )}
              type={type}
              stickers={stickers}
              needManualModification={
                assignmentsThatNeedManualModification.indexOf(assignment.id) >
                -1
              }
            />
          )
        })}
      </TableBody>
    )
  }

  return (
    <>
      {type === "list" && (
        <TableToolbar
          title={tableTitle as string}
          selectedTitle="uppdrag valda"
          numSelected={checkedAssignments.length}
          actions={[]}
          totalItems={sortedAssignments.length}
          filterComponent={
            showFilter ? (
              <TableFilter
                filters={getFilters()}
                onFilter={setFilters}
                searchFunction={searchFunction}
                searchLabel="Sök titel"
                results={sortedAssignments.length}
                initialFilters={initialGroupFilter}
              />
            ) : null
          }
        />
      )}
      <Table>
        {renderTableHeader()}
        {renderTableBody()}
      </Table>
    </>
  )
}

export default AssignmentsTable
