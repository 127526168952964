import { Goals } from "../../../modules/goals/types"

/** Capitalize the first letter in goal-title and prefix it with specified number.
 *
 * @param goals {Array} - List of goals
 *
 * @returns {Array} - List of goals with capitalized titles
 */
export const buildGoalTitles = (goals: Goals): Goals => {
  return goals.map(goal => {
    return { ...goal, title: buildGoalTitle(goal.title, goal.order) }
  })
}

/**
 * Capitalize first letter and prefix with specified number.
 *
 * @param title {String} - Goal's title.
 * @param order {number} - Number to prefix title with.
 *
 * @returns {String} - Capitalized title.
 */
export const buildGoalTitle = (title: string, order: number): string => {
  let capitalizedTitle = ""
  const tmp = title.split(" ")

  tmp[0] = tmp[0].charAt(0).toUpperCase() + tmp[0].slice(1)
  capitalizedTitle = tmp.join(" ")

  return prefixGoalTitleWithOrder(capitalizedTitle, order)
}

/**
 * Build goal title from order and title. Prefix title with the specified number.
 *
 * @param order {number} - Number of value "0" will NOT be prefixed.
 * @param title {string} - Goal title.
 *
 * @returns {string} Goal title prefixed with a number, the specified order.
 */
export const prefixGoalTitleWithOrder = (title: string, order: number) =>
  order ? `${order}. ${title}` : `${title}`
