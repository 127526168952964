import { takeLatest, put, all, race } from "redux-saga/effects"
import { sendRequest } from "../communication/actions"
import * as booksActions from "./actions"
import * as communicationActions from "../communication/actions"
import * as booksTypes from "./types"
import { MODULE_NAME } from "./constants"

function* getAllBooksSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      booksActions.SERVER_MESSAGE_ACTION.GET_BOOKS_REQUEST
    )
  )
}

function* watchGetBooksSaga() {
  yield takeLatest(booksActions.REQUEST.GET_BOOKS_REQUEST, getAllBooksSaga)
}

export function* handleReceiveBooksResponseSaga(
  message: booksTypes.ReceiveBooksResponseMessagesTypes
) {
  const { type, action, payload, error } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case booksActions.SERVER_MESSAGE_ACTION.GET_BOOKS_RESPONSE:
        if (error) {
          return
        }
        yield put(
          booksActions.addBooks(
            payload as booksTypes.ReceiveBooksAction["payload"]
          )
        )
        break

      default:
        return
    }
  }
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceiveBooksResponseSaga
    )
  ])
}

export function* rootSaga() {
  yield all([watchGetBooksSaga(), watchReceiverSaga()])
}
