import * as actions from "./actions"
import { State, OpenModalAction } from "./types"

const initialState: State = {
  isCreateModalOpen: false,
  message: "",
  canLeave: (can: boolean) => () => {}
}

const reducer = (state = initialState, action: OpenModalAction): State => {
  switch (action.type) {
    case actions.UI.OPEN_MODAL:
      const { message, canLeave } = action.payload
      if (message && canLeave) {
        return {
          ...state,
          isCreateModalOpen: true,
          message: message,
          canLeave: canLeave
        }
      }
      return { ...state }
    case actions.UI.CLOSE_MODAL:
      return { ...state, isCreateModalOpen: false }
    default:
      return state
  }
}

export default reducer
