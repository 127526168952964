import React, { useState } from "react"
import moment, { Moment } from "moment"
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  InputLabel,
  Select
} from "@mui/material"
import { StyledFormControl } from "./StyledFormativeQuestions"
import Button from "../../components/Button"
type Props = {
  testName: string
  testId: number
  onCancel: () => void
  onSubmit: (testId: number, time: Moment) => void
}

const StopTestDialog = ({ testName, testId, onCancel, onSubmit }: Props) => {
  const [endTime, setEndTime] = useState<number>(0)

  const onChangeEndTime = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEndTime(event.target.value as number)
  }

  const handleOnSubmit = () => {
    const time = moment().add(endTime, "minutes")
    onSubmit(testId, time)
  }

  return (
    <>
      <DialogTitle id="stop-dialog-title">Avsluta {testName}</DialogTitle>
      <DialogContent id="stop-dialog-description">
        När testet avslutas så försvinner det från elevernas produkter. <br />{" "}
        Du kan välja att avsluta testet direkt eller om en stund.
        <br />
        <StyledFormControl variant="outlined">
          <InputLabel id="endtime-label">Sluttid</InputLabel>
          <Select
            labelId="endtime-label"
            label="Sluttid"
            id="book-menu"
            value={endTime}
            onChange={onChangeEndTime}
          >
            <MenuItem value={0}>Direkt</MenuItem>
            <MenuItem value={2}>om 2 minuter</MenuItem>
            <MenuItem value={5}>om 5 minuter</MenuItem>
            <MenuItem value={10}>om 10 minuter</MenuItem>
            <MenuItem value={30}>om 30 minuter</MenuItem>
            <MenuItem value={60}>om 60 minuter</MenuItem>
          </Select>
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Avbryt</Button>
        <Button onClick={handleOnSubmit} color="primary">
          Avsluta test
        </Button>
      </DialogActions>
    </>
  )
}

export default StopTestDialog
