import Button from "../Button"
import { IconButton, IconButtonProps, styled } from "@mui/material"

export const StyledPopoverContent = styled("div")`
  max-width: 26em;
  padding: 1em;
`
export const StyledButton = styled(Button)`
  :hover {
    background: transparent;
    fill: ${props => props.theme.palette.primary.main};
  }
`

export const StyledIconButton = styled(
  IconButton as React.FC<
    IconButtonProps & { padding: number; custom_color: string }
  >
)`
  ${props => props.padding === 0 && "padding:0px"}
  ${props => props.custom_color && `color:${props.custom_color}`}
`
