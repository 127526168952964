import ListItem from "@mui/material/ListItem"
import { styled } from "@mui/material"

export const StyledListItem = styled(ListItem as any)`
  padding-left: 0;
  ${props => !props.hasonclick && "cursor: default"}
`

export const StyledPrimaryTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${props => props.theme.breakpoints.up("sm")} {
    width: 35%;
    align-items: center;
  }
`

export const StyledPrimaryText = styled("div")`
  ${props => props.theme.breakpoints.up("sm")} {
    margin-right: 10px;
  }
`

export const StyledListItemActionStatus = styled("div")`
  width: 15%;
`

export const StyledListItemContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-right: 1rem;

  & > * {
    margin: 0.25rem 0;
  }

  ${props => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      margin: inherit;
    }
  }
`

export const StyledListItemMoreMenu = styled("div")`
  width: 10%;
`
