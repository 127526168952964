import { RootState } from "../store"
import { ExercisesProgress, ReadingStates, ExerciseStates } from "./types"
import { createSelector } from "reselect"
import { selectExerciseFromRoute } from "../exercises/selectors"
import { selectStudentsWithProduct, selectStudents } from "../members/selectors"
import { selectCurrentMemberId } from "../router/selectors"

export const selectAllExercisesProgress = (
  state: RootState
): ExercisesProgress => state.exercisesProgress.exercisesProgress

export const selectAllExerciseStates = (state: RootState): ExerciseStates =>
  state.exercisesProgress.exerciseStates

export const selectTemporaryProgress = (state: RootState) =>
  state.exercisesProgress.temporaryProgress

export const selectExerciseProgressWithTemporaryProgress = createSelector(
  ({ exercisesProgress }: RootState) => exercisesProgress.exercisesProgress,
  ({ exercisesProgress }: RootState) => exercisesProgress.temporaryProgress,
  (progress, temporaryProgress) => [
    ...progress,
    ...temporaryProgress
      // make sure we do not get any temporary progress that already have a progress
      .filter(
        tp =>
          !progress.find(
            p => p.exerciseId === tp.exerciseId && p.studliId === tp.studliId
          )
      )
  ]
)

export const selectExercisesProgressForExerciseInRouteWithStudents =
  createSelector(
    [
      selectExerciseProgressWithTemporaryProgress,
      selectExerciseFromRoute,
      selectStudentsWithProduct,
      selectAllExerciseStates
    ],
    (exercisesProgress, exercise, students, exercisesStates) => {
      const progressForExercise = exercisesProgress.filter(
        exerciseProcess => exerciseProcess.exerciseId === exercise.id
      )

      return students.map(student => {
        const progressForStudent = progressForExercise.findIndex(
          progress => progress.studliId === student.studliId
        )
        if (progressForStudent === -1) {
          return { ...student }
        }
        const progressHistory = exercisesStates.filter(
          progress =>
            progress.studliId === student.studliId &&
            progressForExercise[progressForStudent].exerciseId ===
              progress.exerciseId
        )
        return {
          ...student,
          ...progressForExercise[progressForStudent],
          moreExercises: progressHistory.length > 1 ? progressHistory : null
        }
      })
    }
  )

export const selectExerciseProgressByStudentId = createSelector(
  selectCurrentMemberId,
  selectStudents,
  selectExerciseProgressWithTemporaryProgress,
  (memberId, students, progress) => {
    const member = students.find(s => s.studliId === memberId)

    if (!member) {
      return []
    }

    return progress.filter(p => p.studliId === member.studliId)
  }
)

export const selectAllReadingStates = (state: RootState): ReadingStates =>
  state.exercisesProgress.readingStates

export const selectExerciseStatesByStudentId = createSelector(
  selectCurrentMemberId,
  selectStudents,
  selectAllExerciseStates,
  (memberId, students, exerciseStates) => {
    const member = students.find(s => s.studliId === memberId)

    if (!member) {
      return []
    }

    return exerciseStates.filter(
      p => p.studliId === member.studliId && p.isInProgress === false
    )
  }
)
