import CloseIcon from "@mui/icons-material/Close"
import {
  Grid,
  IconButton,
  Paper,
  PaperProps,
  styled,
  Typography
} from "@mui/material"
import React from "react"

const ActionBar = styled(Paper, {
  shouldForwardProp: props =>
    ![
      "position" as PropertyKey,
      "bottom" as PropertyKey,
      "width" as PropertyKey,
      "minWidth" as PropertyKey
    ].includes(props)
})<
  PaperProps & {
    position: React.CSSProperties["position"]
    bottom: React.CSSProperties["bottom"]
    width: React.CSSProperties["width"]
    minWidth: React.CSSProperties["minWidth"]
  }
>`
  position: ${props => props.position};
  bottom: ${props => props.bottom};
  color: white;
  background: black;
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.width};
  min-width: ${props => props.minWidth};
  left: 0;
  right: 0;
  text-align: center;
  align-items: center;
  padding: ${props => props.theme.spacing(2)};
`

const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`

type Props = {
  actions: React.ReactNode
  numSelected: number
  position: React.CSSProperties["position"]
  bottom: React.CSSProperties["bottom"]
  width?: React.CSSProperties["width"]
  minWidth?: React.CSSProperties["minWidth"]
  onClose: (e: any) => void
}

const TableActionBar = ({
  actions,
  numSelected,
  position,
  bottom,
  width,
  minWidth,
  onClose
}: Props) => {
  return (
    <ActionBar
      position={position}
      bottom={bottom}
      width={width}
      minWidth={minWidth}
    >
      <Grid container>
        <StyledGrid item xs={3}>
          <Typography fontWeight={500}>{numSelected} valda</Typography>
        </StyledGrid>
        <Grid item xs={7}>
          {actions}
        </Grid>
        <Grid item xs={2}>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ActionBar>
  )
}

export default TableActionBar
