import {
  DialogContent,
  DialogContentProps,
  DialogTitle,
  styled
} from "@mui/material"

export const StyledLabelText = styled("p")`
  font-size: 14px;
  color: gray;
`

export const StyledDialogContent = styled(
  DialogContent as React.FC<DialogContentProps>
)`
  margin-bottom: 2em;
`
export const StyledDialogTitle = styled(DialogTitle)`
  & > h2 > div {
    display: inline;
  }
`
