import { useState, useEffect, useRef } from "react"
import RecordRTC, { StereoAudioRecorder } from "recordrtc"
import axios from "axios"
export type Recorder = {
  audioRecorder: any | undefined
  startRecording: () => void
  stopRecording: () => Promise<string>
  timeRecorded: number
  isRecording: boolean
  recorderInitialized: boolean
  cantUseRecorder: boolean
  initializationError: string
  isUploading: boolean
}

type Props = {
  conversation: boolean
  studliID: number | undefined
}
const isSafari = /^((?!chrome|android).)*(safari|iPad|iPhone)/i.test(
  navigator.userAgent
)

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

const UNSUPPORTED_ERRORS = [
  "SecurityError",
  "NotFoundError",
  "AbortError",
  "NotReadableError"
]

function useAudioRecorder({ conversation, studliID }: Props): Recorder {
  const [timeRecorded, setTimeRecorded] = useState(0)
  const [isRecording, setIsRecording] = useState(false)
  const [recorderInitialized, setRecorderInitialized] = useState(false)
  const [cantUseRecorder, setCantUseRecorder] = useState(false)
  const [initializationError, setInitializationError] = useState("")
  const [isUploading, setIsUploading] = useState(false)
  const audioRecorder = useRef<any | undefined>()
  const audioStream = useRef<MediaStream | undefined>()

  useEffect(() => {
    if (isIOS11()) {
      getAudioRecorder()
    }
  }, [])

  const isIOS11 = (): boolean => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v: any = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      const ver = [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || 0, 10)
      ]
      return ver && ver[0] === 11
    }
    return false
  }

  const getAudioRecorder = async () => {
    setInitializationError("")
    if (audioRecorder.current) {
      return
    }

    let stream
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      })
    } catch (e) {
      if (UNSUPPORTED_ERRORS.some(error => error === e.name)) {
        setCantUseRecorder(true)
      }
      setInitializationError(e.name)
      return
    }

    audioStream.current = stream
    let recorder
    if (isSafari || iOS()) {
      recorder = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/wav",
        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,
        disableLogs: true
      })
    } else {
      recorder = new RecordRTC(stream, {
        type: "audio",
        disableLogs: true
      })
    }
    if (recorder) {
      audioRecorder.current = recorder
      setRecorderInitialized(true)
      return
    }
  }

  useEffect(() => {
    if (isRecording) {
      const timer = setInterval(() => {
        setTimeRecorded(time => time + 1000)
      }, 1000)

      return () => clearInterval(timer)
    } else {
      setTimeRecorded(0)
    }
  }, [isRecording, timeRecorded])

  const startRecording = async () => {
    await getAudioRecorder()
    if (audioRecorder.current) {
      setIsRecording(true)
      audioRecorder.current.name === "StereoAudioRecorder"
        ? audioRecorder.current.record()
        : audioRecorder.current.startRecording()
    }
  }

  const sendAudio = async (blob: any) => {
    const formData = new FormData()
    formData.append("file", blob)
    formData.append("conversation", conversation.toString())
    setIsUploading(true)
    formData.append("studliID", String(studliID))

    const res = await axios.post(`/api/upload/audio`, formData, {
      headers: {
        "X-ClientName": "tecla-frontend",
        "X-ClientSecret": "43q5rgawfsdtr6u6w4thegdft6u65jyrsgfg"
      }
    })

    setIsUploading(false)
    if (res.data && res.data.path) {
      return res.data.path
    }
    return undefined
  }

  const stopRecording = async (): Promise<string> => {
    if (!audioRecorder.current) {
      return Promise.reject()
    }

    setIsRecording(false)
    return new Promise<string>(resolve => {
      if (audioRecorder.current.name === "StereoAudioRecorder") {
        audioRecorder.current.stop(async (blob: any) => {
          if (!audioRecorder.current) {
            return
          }
          const file = await sendAudio(blob)
          resolve(file)
        })

        return
      }

      audioRecorder.current.stopRecording(async () => {
        if (!audioRecorder.current) {
          return
        }
        const blob = audioRecorder.current.getBlob()
        const file = sendAudio(blob)

        resolve(file)
      })
    })
  }

  const unmountRecorder = () => {
    if (audioRecorder.current && audioStream.current) {
      audioRecorder.current.destroy()
      audioStream.current.getTracks().forEach(track => track.stop())
    }
  }

  useEffect(() => {
    return unmountRecorder
  }, [])

  return {
    audioRecorder: audioRecorder.current,
    startRecording,
    stopRecording,
    timeRecorded,
    isRecording,
    recorderInitialized,
    cantUseRecorder,
    initializationError,
    isUploading
  }
}

export default useAudioRecorder
