import { styled } from "@mui/material"
import Avatar from "../Avatar"

export const StyledGoalWrapper = styled("div")`
  display: flex;
`

export const StyledInfoWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`

export const StyledGoalTable = styled("div")`
  margin: 0 auto;
  width: 90%;
`
export const StyledTableHeader = styled("p")`
  margin: 0;
  font-size: 25px;
  margin-top: 15px;
  padding-left: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
`
export const StyledNameSpan = styled("p")`
  margin: 0;
`

export const StyledAvatar = styled(Avatar)`
  margin-top: 5px;
  margin-right: 7px;
  border-radius: 50%;
`
export const StyledCompletedTask = styled("p")`
  width: fit-content;
  margin: 0;
  border-radius: 5px;
  color: white;
  background: #006cc8;
  padding: 2px;
`
export const StyledUnCompletedTask = styled("p")`
  width: fit-content;
  margin: 0;
  border-radius: 5px;
  color: #004383;
  background: #42b9fe;
  padding: 2px;
`
export const StyledAvatarAndAbilityWrapper = styled("div")`
  align-self: center;
`
