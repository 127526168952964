import { styled } from "@mui/material"

export const StyledWrapper = styled("div")`
  margin-top: 25px;
`
export const StyledDetailsHeader = styled("div")`
  margin-top: 50px;
`
export const StyledHeaderBlock = styled("div")`
  display: flex;
  flex: 60px 0;
`

export const StyledTitle = styled("h1")`
  font-family: "georgia";
  margin: 0;
  margin-top: 5px;

  @media (max-width: 743px) {
    margin-top: 0;
    font-size: 19px;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    font-size: 15px;
  }
`

export const StyledBread = styled("p")`
  margin: 0;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.54);
  ${props => props.theme.breakpoints.down("xs")} {
    font-size: 11px;
  }
`

export const StyledDescription = styled("p")`
  margin-top: 10px;
  width: 40%;
  ${props => props.theme.breakpoints.down("md")} {
    width: 60%;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    width: 80%;
  }
`
export const StyledProgressWrapper = styled("div")`
  display: flex;
  flex: 60px 0;
`

export const StyledProgress = styled("div")`
  align-self: center;
`

export const StyledLine = styled("div")`
  width: 100%;
  margin-top: 3%;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
`

export const StyledMessage = styled("p")`
  font-size: 25px;
`

export const StyledSelectionBar = styled("div")`
  display: none;
  @media (max-width: 959px) {
    display: flex;
    flex: 80% auto;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }
`

export const StyledStudentCount = styled("p")`
  display: inline;
  font-size: 20px;
`

export const StyledSelectBarWrapper = styled("div")`
  box-sizing: content-box;
  float: right;
  width: 225px;
  margin-left: 5px;
`
