import {
  FormativeSession,
  FormativeTest,
  FormativeQuestionsWithStudent,
  AnswerAlternatives,
  ParsedFormativeProgressionAnswers
} from "../../modules/formative/types"
import moment from "moment"

export const getPreviousSessionFromSelected = (
  renderTest: FormativeTest,
  session: FormativeSession
) => {
  if (renderTest.sessions.length <= 1) {
    return null
  }
  const sortedSessions = renderTest.sessions.sort(
    (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
  )
  const indexOfSelectedSession = sortedSessions.findIndex(
    sortedSession => sortedSession.id === session.id
  )
  if (indexOfSelectedSession + 1 === renderTest.sessions.length) {
    return null
  }
  return sortedSessions[indexOfSelectedSession + 1]
}

export const getMembersWithAnswers = (
  filterSession: FormativeSession,
  test: FormativeTest,
  answers: ParsedFormativeProgressionAnswers
) => {
  return test.questions.map(question => {
    const answersForQuestionAndSession = answers.filter(
      answer =>
        answer.sessionId === filterSession.id &&
        answer.testId === test.id &&
        answer.questionId === question.id
    )
    const notAnswered = filterSession.members.filter(
      member =>
        !answersForQuestionAndSession.some(answer => answer.memberId === member)
    )
    const endTime = filterSession.endedAt || ""
    const altsWithAnswers = [...question.content.alternatives].map(
      alternative => ({ ...alternative, members: [] as number[] })
    )
    answersForQuestionAndSession.forEach(answer => {
      const answerIndex = findCorrectIndex(
        answer.answer.questions[0].alternatives
      )
      if (answerIndex !== -1) {
        altsWithAnswers[answerIndex].members.push(answer.memberId)
      }
    })
    return {
      ...question,
      notAnswered,
      endTime,
      content: { ...question.content, alternatives: altsWithAnswers }
    }
  }) as FormativeQuestionsWithStudent
}

const findCorrectIndex = (alternatives: AnswerAlternatives) => {
  return alternatives.findIndex(alternative => alternative.answer_status > 0)
}
