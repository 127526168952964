import {
  GetAllGoalsProgressAction,
  ReceiveGetAllGoalsProgressResponseAction,
  ReceiveGoalsProgressUpdatedAction,
  RemoveGoalsProgressForMember,
  GoalsProgress,
  ResetGoalsProgressAction
} from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_GOALS_PROGRESS_REQUEST = "GET_ALL_GOALS_PROGRESS_REQUEST"
}
/**
 * Redux-actions, originating from server. As result from a client-request.
 */
export enum RESPONSE {
  GET_ALL_GOALS_PROGRESS_RESPONSE = "GET_ALL_GOALS_PROGRESS_RESPONSE"
}

/**
 * Redux-actions originating from server. Because of this or another client's actions.
 */
export enum EVENT {
  GOALS_PROGRESS_UPDATED_EVENT = "GOALS_PROGRESS_UPDATED_EVENT"
}

/**
 * Strings for "action" property when communicating with server.
 * Request and Response use the same string.
 * (The "type" property is this module's name).
 */
export enum SERVER_MESSAGE_ACTION {
  GET_ALL_GOALS_PROGRESS_REQUEST = "list",
  GET_ALL_GOALS_PROGRESS_RESPONSE = "list",

  GOAL_PROGRESS_UPDATED_EVENT = "updated"
}

/**
 * Redux-actions for UI (internal UI usage, inter-component-communication).
 */
export enum UI {
  REMOVE_GOALS_PROGRESS_FOR_MEMBER = "REMOVE_GOALS_PROGRESS_FOR_MEMBER",
  RESET_GOALS_PROGRESS = "RESET_GOALS_PROGRESS"
}

/**
 * Get all goals-progress for this classroom's students.
 */
export const getAllGoalsProgressRequest = (): GetAllGoalsProgressAction => ({
  type: REQUEST.GET_ALL_GOALS_PROGRESS_REQUEST
})
/**
 * Remove all goal progress for specified member.
 *
 * @param payload {memberId} - id of member to remove info about.
 */
export const removeGoalsProgressForMember = (payload: {
  studliIds: number[]
}): RemoveGoalsProgressForMember => ({
  type: UI.REMOVE_GOALS_PROGRESS_FOR_MEMBER,
  payload
})

/**
 * Receive response to get all goals.
 *
 * @param payload {GoalsProgress} - list of objects with goal-progress-info
 */
export const receiveGetAllGoalsProgressResponse = (payload: {
  goalsProgress: GoalsProgress
}): ReceiveGetAllGoalsProgressResponseAction => ({
  type: RESPONSE.GET_ALL_GOALS_PROGRESS_RESPONSE,
  payload
})

/**
 * Receives event about updated goal.
 *
 * @param payload {Object} -
 */
export const receiveGoalsProgressUpdated = (
  payload: ReceiveGoalsProgressUpdatedAction["payload"]
): ReceiveGoalsProgressUpdatedAction => {
  return { type: EVENT.GOALS_PROGRESS_UPDATED_EVENT, payload }
}

export const resetGoalsProgress = (): ResetGoalsProgressAction => {
  return { type: UI.RESET_GOALS_PROGRESS }
}
