import React from "react"
import {
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from "@mui/material"
import {
  MemberWithOnlineStatus,
  MembersWithOnlineStatus
} from "../../pages/Exercises/store/types"

import Button from "../Button/Button"
import Avatar from "../Avatar"
import { buildName } from "../../shared/tools/strings"
import {
  StyledLabelText,
  StyledDialogContent,
  StyledDialogTitle
} from "../ProgressDialog/StyledProgressDialog"
import HtmlRenderer from "../HtmlRenderer"

type Props = {
  done: MembersWithOnlineStatus
  inProgress: MembersWithOnlineStatus
  notStarted?: MembersWithOnlineStatus
  approved?: MembersWithOnlineStatus
  title: string
  onClose: Function
  type:
    | "exercises"
    | "assignments"
    | "adaptAssignment"
    | "assignmentsTask"
    | "formativeQuestions"
}

const ExercisesProgressDialog = ({
  done,
  inProgress,
  title,
  onClose,
  type,
  approved,
  notStarted
}: Props) => {
  const renderListItem = (member: MemberWithOnlineStatus) => {
    return (
      <ListItem divider={true} key={member.studliId}>
        <ListItemAvatar>
          <Avatar
            picture={member.picture}
            firstName={member.firstName}
            lastName={member.lastName}
            studliId={member.studliId}
          />
        </ListItemAvatar>
        <ListItemText>
          {buildName(member.firstName, member.lastName)}
        </ListItemText>
      </ListItem>
    )
  }

  const getHeaderType = () => {
    switch (type) {
      case "exercises":
        return "övning"
      case "assignments":
        return "uppdrag"
      case "assignmentsTask":
        return "deluppgift"
      case "formativeQuestions":
        return "testet"
    }
  }

  return (
    <>
      <StyledDialogTitle style={{ display: "flex" }}>
        Status för {getHeaderType()} &quot;{<HtmlRenderer html={title} />}
        &quot;
      </StyledDialogTitle>
      <StyledDialogContent>
        {(type === "assignments" ||
          type === "assignmentsTask" ||
          type === "adaptAssignment" ||
          type === "formativeQuestions") &&
          notStarted &&
          notStarted.length > 0 && (
            <>
              <StyledLabelText>
                EJ PÅBÖRJAT ({notStarted.length})
              </StyledLabelText>
              <List>{notStarted.map(member => renderListItem(member))}</List>
            </>
          )}
        {inProgress.length > 0 && (
          <>
            <StyledLabelText>PÅBÖRJAT ({inProgress.length})</StyledLabelText>
            <List>{inProgress.map(member => renderListItem(member))}</List>
          </>
        )}
        {done.length > 0 && (
          <>
            <StyledLabelText>
              {type === "assignments" ? "INLÄMNAT" : "KLAR"} ({done.length})
            </StyledLabelText>
            <List>{done.map(member => renderListItem(member))}</List>
          </>
        )}
        {approved && approved.length > 0 && (
          <>
            <StyledLabelText>GODKÄNDA ({approved.length})</StyledLabelText>
            <List>{approved.map(member => renderListItem(member))}</List>
          </>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          STÄNG
        </Button>
      </DialogActions>
    </>
  )
}

export default ExercisesProgressDialog
