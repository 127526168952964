import * as actions from "./actions"
import { State, SocketActionTypes, SocketState } from "./types"

const initialState: State = { socketState: SocketState.closed }

const reducer = (state = initialState, action: SocketActionTypes): State => {
  switch (action.type) {
    case actions.OPEN_SOCKET:
      if (state.socketState === SocketState.opened) {
        return state
      }

      return { socketState: SocketState.opening }
    case actions.SOCKET_OPENED:
      return { socketState: SocketState.opened }
    case actions.SOCKET_ERROR:
      return { socketState: SocketState.error }
    case actions.CLOSE_SOCKET:
      if (state.socketState === SocketState.closed) {
        return state
      }

      return { socketState: SocketState.closing }
    case actions.SOCKET_CLOSED:
      return { socketState: SocketState.closed }
    case actions.RECONNECT_SOCKET:
      return { socketState: SocketState.reconnecting }
    default:
      return state
  }
}

export default reducer
