import { styled } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { StudliTheme } from "../../shared/styles/theme"

export const StyledSplashScreen = styled("div")`
  background-color: ${props =>
    (props.theme as StudliTheme).studli.palette.appBarBackground.toString()};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

export const StyledErrorContainer = styled("div")`
  background: ${props => (props.theme as Theme).palette.background.paper};
  color: ${props => (props.theme as Theme).palette.error.main};
`
