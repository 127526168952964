import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

const LightBulbIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
      viewBox="0 0 144 200"
    >
      <path
        d="M48.5979 169.354L34.4808 166.061C32.0118 165.485 29.5434 167.02 28.9675 169.489L24.1478 190.151C23.5719 192.62 25.1065 195.088 27.5755 195.664L41.6926 198.957C44.1617 199.533 46.6301 197.998 47.206 195.529L52.0256 174.867C52.6016 172.398 51.0669 169.93 48.5979 169.354Z"
        fill="#9184EF"
      />
      <path
        d="M104.944 102.644C105.03 75.4453 83.0455 53.3246 55.8465 53.2385C28.6475 53.1525 6.52678 75.1369 6.4407 102.336C6.39767 115.103 11.4401 128.38 18.8567 138.666C21.195 141.908 23.8848 145.021 25.1615 148.808C26.467 152.674 26.1801 156.87 26.5961 160.93C27.299 167.859 31.3659 172.593 38.0222 174.666C43.0503 176.229 48.5088 175.455 52.7837 172.356C56.7216 169.501 58.0055 165.298 60.81 161.54C62.4095 159.402 64.5398 157.724 66.6917 156.146C67.825 155.314 68.9798 154.496 70.1346 153.693C79.0072 147.488 87.6145 141.664 94.321 133.028C101.085 124.313 104.908 113.712 104.937 102.651L104.944 102.644Z"
        fill="#A8B6FF"
      />
      <path
        d="M48.0639 175.067L46.9951 174.974L48.8385 153.951C49.233 149.396 49.649 144.691 50.3448 140.086C50.9975 135.768 51.9156 131.479 53.0776 127.268C51.3346 127.469 49.6634 127.369 48.1571 126.967C47.2533 132.347 45.8618 137.855 44.248 143.952C41.9455 152.645 39.0908 163.469 34.2922 173.045L33.3311 172.564C38.0794 163.089 40.927 152.323 43.2151 143.679C44.8361 137.554 46.2348 132.024 47.1242 126.644C45.0943 125.891 43.3012 124.55 42.0029 122.814C40.5397 120.856 39.6144 118.288 39.1625 114.967C38.9617 113.497 38.8325 111.768 39.3633 110.125C39.9515 108.311 41.3071 106.969 42.9067 106.639C45.1445 106.173 47.2677 107.751 48.2145 109.552C49.2043 111.438 49.2689 113.597 49.2473 115.469C49.2115 118.984 48.8815 122.427 48.3364 125.905C49.8714 126.329 51.5928 126.407 53.4004 126.149C54.2037 123.345 55.1218 120.569 56.1475 117.843C57.2737 114.852 58.9951 113.317 60.8887 113.662C62.4739 113.941 63.4494 115.512 63.7148 116.939C64.1308 119.156 63.2916 121.681 61.5271 123.524C60.071 125.052 58.0268 126.164 55.4733 126.831C55.0645 126.938 54.6556 127.032 54.2467 127.103C53.0346 131.428 52.0878 135.825 51.4135 140.258C50.7178 144.834 50.3089 149.525 49.9144 154.058L48.071 175.082L48.0639 175.067ZM60.3794 114.673C58.6149 114.673 57.4673 117.348 57.1446 118.202C56.1906 120.741 55.337 123.309 54.5767 125.913C54.7847 125.87 54.9855 125.819 55.1935 125.762C57.5606 125.145 59.4255 124.134 60.7452 122.749C62.273 121.157 63.0047 118.991 62.6532 117.104C62.4524 116.036 61.7709 114.881 60.695 114.687C60.5874 114.666 60.487 114.659 60.3794 114.659V114.673ZM43.7028 107.622C43.5092 107.622 43.3083 107.644 43.1147 107.679C41.9168 107.931 40.8409 109.014 40.3747 110.448C39.9085 111.883 40.0304 113.461 40.2169 114.809C40.6401 117.944 41.5008 120.347 42.8564 122.154C43.9897 123.667 45.5391 124.851 47.2892 125.54C47.8056 122.161 48.1212 118.833 48.1571 115.44C48.1786 113.697 48.1212 111.703 47.2462 110.039C46.5719 108.763 45.1948 107.622 43.7028 107.622Z"
        fill="white"
      />
      <path
        d="M88.196 77.3965C95.1105 86.054 97.3197 97.6666 96.3371 108.512C95.9713 112.55 95.462 117.019 93.8768 120.748C93.6688 121.243 93.4823 122.097 92.9516 122.305C92.2199 120.978 91.9689 119.142 91.4955 117.679C90.9575 116.014 90.3837 114.365 89.7167 112.751C88.4327 109.631 86.7973 106.769 84.6886 104.129C80.0119 98.5776 73.4848 94.6469 68.3706 89.4825C64.0885 85.1646 65.5015 76.4927 70.2355 73.0211C74.9122 69.5926 81.2887 71.0343 85.456 74.5561C86.4674 75.4096 87.3855 76.3708 88.2104 77.3965H88.196Z"
        fill="white"
      />
      <path
        d="M79.8742 -5.66741e-05L72.5283 31.0028L73.5752 31.2509L80.9212 0.248004L79.8742 -5.66741e-05Z"
        fill="#A8B6FF"
      />
      <path
        d="M142.8 53.7539L114.404 68.2037L114.892 69.1626L143.288 54.7128L142.8 53.7539Z"
        fill="#A8B6FF"
      />
      <path
        d="M1.54989 18.6447L0.631348 19.205L17.2219 46.406L18.1404 45.8457L1.54989 18.6447Z"
        fill="#A8B6FF"
      />
      <path
        d="M49.614 192.662C40.9708 192.081 32.3277 189.664 24.6313 185.683L25.1263 184.729C32.6935 188.645 41.186 191.019 49.6857 191.593L49.614 192.669V192.662Z"
        fill="#231F20"
      />
      <path
        d="M52.0954 183.625C43.4523 183.044 34.8091 180.627 27.1128 176.646L27.6077 175.692C35.1749 179.608 43.6675 181.982 52.1672 182.556L52.0954 183.632V183.625Z"
        fill="#231F20"
      />
    </SvgIcon>
  )
}

export default LightBulbIcon
