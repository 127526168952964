import * as types from "./types"
import { createAction } from "../../shared/tools/redux"

// Application
export const APPLICATION_MOUNT = "APPLICATION_MOUNT"
export const APPLICATION_READY = "APPLICATION_READY"
export const APPLICATION_IDLE = "APPLICATION_IDLE"
export const APPLICATION_RECONNECTING = "APPLICATION_RECONNECTING"
export const APPLICATION_RECEIVE_ERROR = "APPLICATION_RECEIVE_ERROR" //data on socket received with "type: error"
export const SET_SNACKBAR = "SET_SNACKBAR" //sets snackbar object that will result in showing/hiding a snackbar
export const SET_BANNERS = "SET_BANNERS"

export const setApplicationReady = createAction("APPLICATION_READY")
export const setApplicationReconnecting = createAction(
  "APPLICATION_RECONNECTING"
)
export const setApplicationIdle = createAction("APPLICATION_IDLE")
export const setApplicationMount = createAction("APPLICATION_MOUNT")
export const setApplicationError = createAction("APPLICATION_RECEIVE_ERROR")

export const setSnackbar =
  createAction<types.SetSnackbarAction["payload"]>("SET_SNACKBAR")
export const setBanners =
  createAction<types.SetBannersAction["payload"]>("SET_BANNERS")
