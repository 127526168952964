// Gist for https://medium.com/@ebakhtarov/bidirectional-websockets-with-redux-saga

import { eventChannel, Channel } from "redux-saga"
import {
  all,
  call,
  put,
  take,
  race,
  select,
  takeEvery,
  delay
} from "redux-saga/effects"

import * as actions from "./actions"
import { WS_URL } from "./constants"
import {
  socketOpened,
  socketError,
  socketClosed,
  openSocket,
  closeSocket
} from "./actions"
import { selectCurrentClassroomId } from "../router/selectors"

import {
  parseReceivedDataAndEmitAction,
  serverListener,
  sendRequestToServer
} from "../communication/sagas"
import { selectJwt } from "../auth/selectors"
import * as communicationActions from "../communication/actions"
import { selectLastReceivedEventId } from "../communication/selectors"

let socketChannel: Channel<{}>
let socket: WebSocket
let reconnectAttempts = 0
const MAX_RECONNECT_INTERVAL_MS = 15000
const RECONNECT_INCREASE_STEP_MS = 250

const generateReconnectInterval = (attempt: number) => {
  const newReconnectInterval =
    (Math.pow(2, attempt) - 1) * RECONNECT_INCREASE_STEP_MS

  return newReconnectInterval <= MAX_RECONNECT_INTERVAL_MS
    ? newReconnectInterval
    : MAX_RECONNECT_INTERVAL_MS
}

/**
 * Handles opening of websocket
 * @param s the WebSocket object
 */
const open = (s: WebSocket) => {
  return new Promise((resolve, reject) => {
    s.onopen = () => {
      reconnectAttempts = 0
      resolve(s)
    }
    s.onerror = err => {
      window.console.error("Failed to open socket", err)
      reject(err)
    }
  })
}

/**
 * Creates the websocket eventChannel and listens for socket closed events
 * @param s the WebSocket object
 */
const createSocketChannel = (s: WebSocket) => {
  return eventChannel(emit => {
    s.onmessage = event => {
      parseReceivedDataAndEmitAction(emit, event)
    }

    s.onclose = () => {
      emit(socketClosed())
    }

    return () => {
      s.close()
    }
  })
}

/**
 * Worker saga to connect websocket
 */
export function* connectSocketSaga() {
  const classroomId = yield select(selectCurrentClassroomId)
  const token = yield select(selectJwt)
  const lastReceivedEventId = yield select(selectLastReceivedEventId)

  if (!classroomId || !token) {
    return
  }

  try {
    let url = WS_URL + `?classroomId=${classroomId}&accessToken=${token}`
    if (lastReceivedEventId) {
      url += `&eventId=${lastReceivedEventId}`
    }
    socket = new WebSocket(url)
    yield open(socket)
    socketChannel = yield call(createSocketChannel, socket)
    yield put(socketOpened())
  } catch (e) {
    yield put(socketError(e))
  }
}

/**
 * Watcher saga for the socket eventChannel
 */
export function* watchSocketChannelSaga() {
  while (true) {
    yield take(actions.SOCKET_OPENED)

    try {
      const { cancel } = yield race({
        task: all([
          call(serverListener, socketChannel),
          call(sendRequestToServer, socket)
        ]),
        cancel: take(actions.CLOSE_SOCKET)
      })
      if (cancel) {
        socketChannel.close()
        socket.close()
      }
    } catch (e) {
      yield put(socketError(e))
    }
  }
}

/**
 * Watcher saga to handle open socket actions
 */
export function* watchOpenSocketSaga() {
  yield takeEvery(actions.OPEN_SOCKET, connectSocketSaga)
}

/**
 * Watcher saga to handle reconnect socket actions
 */
export function* watchReconnectSocketSaga() {
  yield takeEvery(actions.RECONNECT_SOCKET, reconnectSocketSaga)
}

/**
 * Watcher saga to handle socket error actions
 */
export function* watchSocketErrorSaga() {
  yield takeEvery(actions.SOCKET_ERROR, reconnectSocketSaga)
}

/**
 * Worker saga to reconnect websocket
 */
export function* reconnectSocketSaga() {
  const interval = generateReconnectInterval(reconnectAttempts)

  if (reconnectAttempts > 0) {
    yield put(
      communicationActions.setCommunicationError({
        id: 12,
        message: `Will try to reconnect to socket in ${interval} ms (attempt ${reconnectAttempts})`
      })
    )
  }

  yield delay(interval)
  reconnectAttempts++
  yield put(closeSocket())
  yield put(openSocket())
}

export function* rootSaga() {
  yield all([
    watchOpenSocketSaga(),
    watchSocketErrorSaga(),
    watchReconnectSocketSaga(),
    watchSocketChannelSaga()
  ])
}
