import { takeLatest, call, put, all } from "redux-saga/effects"
import axios from "axios"
import { AUTH_TOKEN_REQUEST, AUTH_SSO_TOKEN_INVALIDATED_EVENT } from "./actions"
import * as actions from "./actions"
import * as applicationActions from "../application/actions"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"

function* watchGetTokenRequestSaga() {
  yield takeLatest(AUTH_TOKEN_REQUEST, getTokenSaga)
}

function fetchToken(payload: any) {
  const body = {
    code: payload.code,
    state: payload.state,
    redirect_uri: payload.redirectUri
  }
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }

  return axios.post(payload.tokenUrl, body, config)
}

export function* getTokenSaga(action: any) {
  try {
    const response = yield call(fetchToken, action.payload)

    if (response.data) {
      yield put(actions.authTokenSuccess(response.data))
    } else {
      yield put(actions.authTokenFailure())
    }
  } catch (error) {
    yield put(actions.authTokenFailure(error))
  }
}

function* watchSSOTokenInvalidatedSaga() {
  yield takeLatest(
    AUTH_SSO_TOKEN_INVALIDATED_EVENT,
    handleSSOTokenInvalidatedSaga
  )
}

export function* handleSSOTokenInvalidatedSaga() {
  yield put(
    applicationActions.setSnackbar(snackbarMessages.AUTH_SSO_TOKEN_INVALIDATED)
  )
  yield call(setTimeout, reloadWindow, 5000)
}

const reloadWindow = () => {
  window.location.reload()
}

export function* rootSaga() {
  yield all([watchGetTokenRequestSaga(), watchSSOTokenInvalidatedSaga()])
}
