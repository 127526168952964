/* eslint-disable import/no-cycle */

import { createSelector } from "reselect"
import {
  getAllAssignments,
  getAllAssignmentsMessages,
  getAllPreparedAssignments,
  getAllClassroomRelatedAssignments
} from "../assignments/actions"
import { getAllAssignmentsProgress } from "../assignmentsProgress/actions"
import { getBooks } from "../book/actions"
import { getAllExercises, getTemaToc } from "../exercises/actions"
import { getAllExercisesProgress } from "../exercisesProgress/actions"
import {
  getAllImportableTests,
  getAllPreparedTests,
  getAllQuestions,
  getAllTests,
  getFormativeProgression
} from "../formative/actions"
import { getAllGoalsRequest } from "../goals/actions"
import { getAllGoalsProgressRequest } from "../goalsProgress/actions"
import { getAllMembers } from "../members/actions"
import { getAllNotifications } from "../notifications/actions"
import { RootState } from "../store"
import {
  getActiveProducts,
  getUsersOnline,
  getUsersWithProduct
} from "../users/actions"

export const selectActiveProductShouldUseTemaToc = createSelector(
  ({ products }: RootState) =>
    products.products.find(p => p.id === products.activeProductId),
  product => {
    if (!product) {
      return false
    }

    return product.useTemaToc
  }
)

export const selectIsAdapt = createSelector(
  ({ products }: RootState) => products.activeProductId,
  ({ products }: RootState) => products.products || [],
  (productId, products) => {
    const activeProduct = products
      .filter(p => !p.isTeacherGuide)
      .find(p => p.id === productId)
    return activeProduct?.type === "adapt"
  }
)

export const selectAssignmentsCacheOrFetch = createSelector(
  ({ assignments }: RootState) => assignments.assignments.length,
  assignments => (assignments ? null : getAllAssignments)
)

export const selectImportableAssignmentsCacheOrFetch = createSelector(
  ({ assignments }: RootState) => assignments.importableAssignments.length,
  importable => (importable ? null : getAllClassroomRelatedAssignments)
)

export const selectPreparedAssignmentsCacheOrFetch = createSelector(
  ({ assignments }: RootState) => assignments.preparedAssignments.length,
  prepared => (prepared ? null : getAllPreparedAssignments)
)

export const selectMessagesCacheOrFetch = createSelector(
  ({ assignments }: RootState) => assignments.feedbackMessages.length,
  messages => (messages ? null : getAllAssignmentsMessages)
)

export const selectAssignmentProgressCacheOrFetch = createSelector(
  ({ assignmentsProgress }: RootState) =>
    assignmentsProgress.assignmentsProgress.length,
  progress => (progress ? null : getAllAssignmentsProgress)
)

export const selectBooksCacheOrFetch = createSelector(
  ({ books }: RootState) => books.booksToc.length,
  books => (books ? null : getBooks)
)

export const selectExercisesCacheOrFetch = createSelector(
  ({ exercises }: RootState) => exercises.exercises.length,

  exercises => (exercises ? null : getAllExercises)
)

export const selectTocCacheOrFetch = createSelector(
  ({ exercises }: RootState) => exercises.temaToc.length,
  selectActiveProductShouldUseTemaToc,
  (temaToc, shouldUse) => (!temaToc && shouldUse ? getTemaToc : null)
)

export const selectExercisesProgressCacheOrFetch = createSelector(
  ({ exercisesProgress }: RootState) =>
    exercisesProgress.exercisesProgress.length,
  selectIsAdapt,
  (progressLength, isAdapt) =>
    !progressLength && !isAdapt ? getAllExercisesProgress : null
)

export const selectTestProgressionCacheOrFetch = createSelector(
  ({ formative }: RootState) => formative.progression.length,

  progress => (progress ? null : getFormativeProgression)
)

export const selectTestsCacheOrFetch = createSelector(
  ({ formative }: RootState) => formative.tests.length,
  tests => (tests ? null : getAllTests)
)

export const selectImportableTestsCacheOrFetch = createSelector(
  ({ formative }: RootState) => formative.importableTests.length,
  importable => (importable ? null : getAllImportableTests)
)

export const selectPreparedTestsCacheOrFetch = createSelector(
  ({ formative }: RootState) => formative.preparedTests.length,
  prepared => (prepared ? null : getAllPreparedTests)
)

export const selectGoalsCacheOrFetch = createSelector(
  ({ goals }: RootState) => goals.goals.length,
  selectIsAdapt,
  (goals, isAdapt) => (!goals && isAdapt ? getAllGoalsRequest : null)
)

export const selectGoalsProgressCacheOrFetch = createSelector(
  ({ goalsProgress }: RootState) => goalsProgress.goalsProgress.length,
  selectIsAdapt,
  (goalsProgress, isAdapt) =>
    !goalsProgress && isAdapt ? getAllGoalsProgressRequest : null
)

export const selectMembersCacheOrFetch = createSelector(
  ({ members }: RootState) => members.members.length,
  membersLength => (membersLength ? null : getAllMembers)
)

export const selectNotificationsCacheOrFetch = createSelector(
  ({ notifications }: RootState) => notifications.notifications.length,
  notifications => (notifications ? null : getAllNotifications)
)

export const selectUsersWithProductCacheOrFetch = createSelector(
  ({ users }: RootState) => users.usersWithProduct,
  usersWithProduct => (usersWithProduct ? null : getUsersWithProduct)
)

export const selectUsersOnlineCacheOrFetch = createSelector(
  ({ users }: RootState) => users.usersOnline,
  selectIsAdapt,
  (usersOnline, isAdapt) => (!usersOnline && isAdapt ? getUsersOnline : null)
)

export const selectFormativeQuestionsCacheOrFetch = createSelector(
  ({ formative }: RootState) => formative.questions.length,
  questions => (questions ? null : getAllQuestions)
)

export const selectActiveProductsCacheOrFetch = createSelector(
  ({ users }: RootState) => users.activeProducts || [],
  activeProducts => (activeProducts.length ? null : getActiveProducts)
)
