import React, { useEffect, useState } from "react"
import {
  AssignmentsTaskProgress,
  AssignmentsProgress,
  AssignmentProgress
} from "../../modules/assignmentsProgress/types"
import { Member } from "../../modules/members/types"
import { Assignment } from "../../modules/assignments/types"
import { getStatusTag } from "./helper"
import { StyledStatusTag } from "./StyledAssignmentStatusAndApprovedTags"

type Props = {
  memberId: Member["studliId"]
  progress: {
    assignmentsTaskProgress: AssignmentsTaskProgress
    assignmentsProgress: AssignmentsProgress
  }
  assignmentId: Assignment["id"]
}

const AssignmentStatusAndApprovedTags = ({
  progress,
  memberId,
  assignmentId
}: Props) => {
  const [progressForAssignment, setProgressForAssignment] =
    useState<AssignmentProgress | null>(null)

  useEffect(() => {
    const filteredProgress = progress.assignmentsProgress.filter(
      (assignmentProgress: AssignmentProgress) =>
        assignmentProgress.assignmentId === assignmentId &&
        assignmentProgress.studliId === memberId
    )
    setProgressForAssignment(filteredProgress[filteredProgress.length - 1])
  }, [progress.assignmentsProgress, assignmentId, memberId])

  return (
    <>
      {getStatusTag(
        memberId,
        progress.assignmentsProgress,
        progress.assignmentsTaskProgress,
        assignmentId
      )}
      {progressForAssignment && progressForAssignment.approvedAt && (
        <StyledStatusTag status="approved">Klart</StyledStatusTag>
      )}
    </>
  )
}

export default AssignmentStatusAndApprovedTags
