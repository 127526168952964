import { styled } from "@mui/material"
import Paper, { PaperProps } from "@mui/material/Paper"

export const StyledStickerWrapper = styled("div")`
  display: flex;
  flex-flow: row wrap;
`

export const StyledSticker = styled(
  Paper as React.FC<PaperProps & { selected?: number; color?: string }>
)`
  width: 9em;
  padding: 0.5em;
  margin: 1rem;
  cursor: pointer;
  text-align: center;
  ${props => props.selected === 1 && "border: 3px solid #0169CC"}

  ${props =>
    props.color &&
    `
    border-radius: 50%;
    padding: 10px;
    width: 100px;
    height: 100px;
    background-color: ${props.color};
    margin-top: 1em;
    > img {
      width:100%;
      height:100%;
    }
  `}
`

export const StyledCurrentSticker = styled("img")`
  width: 7.5em;
  flex-basis: 7.5em;
`
export const StyledButtonWrapper = styled("div")`
  padding-left: 0.5em;
  align-self: flex-end;
`
export const StyledStickerLabel = styled("div")`
  text-align: center;
`
