import { IconButton, styled } from "@mui/material"

const ITEM_HEIGHT = 48

type StyledIconButtonProps = {
  iconcolor?: string
}

export const menuProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: 200
  }
}

export const StyledIconButton = styled(IconButton)`
  ${(props: StyledIconButtonProps) =>
    props.iconcolor && `color: ${props.iconcolor}`}
`
