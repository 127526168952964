import {
  ListItem,
  Typography,
  Checkbox,
  TypographyProps,
  CheckboxProps,
  Button,
  Paper,
  Grid,
  GridProps,
  TableCell,
  IconButton,
  TableRow,
  styled
} from "@mui/material"
import { ReactComponent as InfoOutlinedIcon } from "../../assets/images/menu-information.svg"
import { ReactComponent as DraggableIcon } from "../../assets/images/draggable.svg"

type StatusTagProps = {
  status: "draft" | "published" | "ongoing" | "stopped" | "archived"
}

export const StyledNoMembers = styled("div")`
  color: red;
`

export const StyledActionTableCell = styled(TableCell)`
  padding-right: 0;
`

export const StyledStatusIconButton = styled(IconButton)`
  color: #006cc8;
`

export const StyledQuestionListItem = styled(ListItem as any)`
  &:hover {
    background-color: white;
  }
`

export const StyledFormativeTaskImage = styled("img")`
  max-width: 100%;
`

export const FormativePrevAnswerWrapper = styled("div")`
  display: flex;
  align-items: center;
  color: gray;
`
export const StyledTableRow = styled(TableRow as any)`
  &:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }

  ${props => props.hasonclick && "cursor:pointer"}

  td:first-child {
    padding-left: 1.5rem;
  }
  td:last-child {
    padding-right: 1.5rem;
  }
  ${({ missingGroups }) =>
    missingGroups &&
    `background-color: rgba(218, 30, 40, 0.1);
    &:hover {
      background-color: rgba(218, 30, 40, 0.2);
    }
    `}
`

export const StyledFormativeAnswerImage = styled("img")`
  max-width: 150px;
`

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  width: 22px;
  margin-right: 3px;
`

export const StyledQuestionText = styled(Typography)`
  display: flex;
  font-weight: bold;
  column-gap: 2px;
  > span {
    > span {
      white-space: normal;
    }
  }
`

export const StyledChapterGrid = styled(Grid)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  margin-top: 10px;
`

export const StyledDraggableIcon = styled(DraggableIcon)``

export const StyledChapterText = styled("div")`
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: 0.9rem;
  padding: 2px 6px 2px 6px;
  display: inline-block;
  color: white;
  background-color: #666666;
`

export const StyledCommonErrorContainer = styled(Paper)`
  max-width: 300px;
  padding: 10px;

  > span {
    > span {
      white-space: normal;
    }
  }
`

export const StyledQuestionAnswer = styled(
  Typography as React.FC<
    TypographyProps & {
      correct?: number
      wrong?: number
      rating?: number
      showCorrectAnswer?: number
    }
  >
)`
  position: relative;
  display: inline-flex;
  padding: 10px 7px;
  min-width: 60px;
  border-radius: 4px;
  white-space: pre;
  ${props =>
    (props.correct === 1 || props.wrong === 1 || props.rating === 1) &&
    "cursor:pointer;"}
  ${props =>
    props.correct === 1 &&
    `border: 2.5px solid #038763;
    background: #f3fbf9;
  `}
  ${props =>
    props.wrong === 1 &&
    `border: 2.5px dotted #e84d3c;
    background: #fef6f5;
  `}
  ${props =>
    props.rating === 1 &&
    `border: 2.5px solid #006CC8;
    background: #f2f7fc;
  `}
  ${props =>
    props.showCorrectAnswer === 1 && `border-bottom: 4px solid #038763;`}
     > div {
      white-space:normal;  
    }
    > span {
      > span {
        white-space:normal;  
      }
    }
  }
`

export const StyledCheckbox = styled(
  Checkbox as React.FC<CheckboxProps & { margin_left?: number }>
)`
  ${props => props.margin_left && "margin-left: 1em;"}
`

export const StyledCommonErrorsButton = styled(Button)`
  color: #016dc8;
`

export const StyledHideAnswerButton = styled(Button)`
  color: #016dc8;
  margin-top: -20px;
`

export const StyledProgressWrapper = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledAnswerGrid = styled(
  Grid as React.FC<GridProps & { show?: number }>
)`
  display: flex;
  flex-direction: column;
  position: relative;
  display: ${props => (props.show ? "inline-block" : "none")};
`

export const StyledProgressContainer = styled("div")`
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledStatusTag = styled("div")`
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 6px 2px 6px;
  display: inline-block;
  color: white;
  background-color: ${(props: StatusTagProps) =>
    getBackgroundColor(props.status)};
`

const getBackgroundColor = (status: string): string => {
  switch (status) {
    case "draft":
      return "#35209e"
    case "ongoing":
      return "#05b887"
    case "stopped":
      return "#006CC8"
    case "published":
      return "#43b9fe"
    default:
      return "#05b887"
  }
}

export const StyledBadgeContainer = styled("div")`
  width: 100%;
  position: absolute;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: -16px;
  margin-left: -7px;
`

export const StyledCorrectBadge = styled("div")`
  width: 27px;
  height: 27px;
  text-align: center;
  border: 2.5px solid #038763;
  background: #038763;
  color: white;
  border-radius: 50%;
`

export const StyledRatingBadge = styled("div")`
  width: 27px;
  height: 27px;
  text-align: center;
  border: 2.5px solid #006cc8;
  background: #006cc8;
  color: white;
  border-radius: 50%;
`

export const StyledWrongBadge = styled("div")`
  width: 27px;
  height: 27px;
  text-align: center;
  border: 2.5px dotted #e84d3c;
  background: #fef6f5;
  color: #e84d3c;
  z-index: 2;
  border-radius: 50%;
`

export const StyledChapterProgressGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
