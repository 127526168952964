import React from "react"
import { DialogContent, DialogActions } from "@mui/material"
import {
  StyledClassroomInfoText,
  StyledDialogTitle
} from "./StyledClassroomCodeDialog"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Button from "../Button"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import { setSnackbar } from "../../modules/application/actions"
import { useDispatch } from "react-redux"
type Props = {
  onClose: () => void
  classroomCode: string
  classroomName: string
}

const ClassroomCodeDialog = ({
  onClose,
  classroomCode,
  classroomName
}: Props) => {
  const dispatch = useDispatch()
  const classroomInfoText = [
    `Du har blivit inbjuden att gå med i klassrummet '${classroomName}'`,
    `Gå till https://minbokhylla.studentlitteratur.se och logga in.`,
    `Tryck på ditt namn och välj "Gå med som elev i klassrum". Skriv sedan
in följande kod:`,
    classroomCode,
    `För att kunna gå med i ett klassrum måste du ha aktiverat din
digitala produkt. Välj "Aktivera produkt" i Bokhyllan och skriv in
produktens kod först (om du inte redan har gjort det).`,
    `Lycka till!`
  ]

  const onCopyClick = () => {
    dispatch(
      setSnackbar(snackbarMessages.COPY_INVITE_STUDENTS_TO_CLASSROOM_CARD)
    )
    onClose()
  }

  return (
    <>
      <StyledDialogTitle id="informationstext-modal">
        Bjud in elever - text att kopieras till e-post, m.m
      </StyledDialogTitle>
      <DialogContent>
        <StyledClassroomInfoText>
          {classroomInfoText.map(text => (
            <React.Fragment key={text}>
              {text}
              <br />
              <br />
            </React.Fragment>
          ))}
        </StyledClassroomInfoText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Avbryt
        </Button>
        <CopyToClipboard text={classroomInfoText.join("\n \n")}>
          <Button color="primary" onClick={onCopyClick}>
            Kopiera texten
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </>
  )
}

export default ClassroomCodeDialog
