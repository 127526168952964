import React from "react"

import { StyledGoalsList } from "./StyledGoalsList"
import GoalsListItem from "../GoalsListItem"
import StyledList from "../StyledList"
import { GoalsInGoalsList } from "../../pages/Goals/store/types"

type Props = {
  goals: GoalsInGoalsList
  children?: never
}

const GoalsList = ({ goals }: Props) => {
  return (
    <StyledGoalsList>
      <StyledList>
        {goals.map(goal => (
          <div key={goal.goalRef}>
            <GoalsListItem goal={goal} />
          </div>
        ))}
      </StyledList>
    </StyledGoalsList>
  )
}

export default GoalsList
