/**
 * These select* functions are for redux select(), they expose parts of the state.
 */

import { RootState } from "../../../modules/store"
import { createSelector } from "reselect"

import {
  selectStudents,
  selectStudentsOnline
} from "../../../modules/members/selectors"
import { selectGoalsProgress } from "../../../modules/goalsProgress/selectors"
import { selectGoals } from "../../../modules/goals/selectors"
import { GoalsInGoalsList, MemberInGoalsList, FilterBy } from "./types"
import { Goals } from "../../../modules/goals/types"
import { Members } from "../../../modules/members/types"
import { GoalsProgress } from "../../../modules/goalsProgress/types"
import { buildGoalTitle } from "../../Goals/store/tools"

export const selectGoalFilter = (store: RootState): FilterBy =>
  store.pages.goalsPage.goalFilter

export const selectIsGettingGoals = (store: RootState): boolean =>
  store.pages.goalsPage.isGettingGoals

/**
 * selects a list of objects specially built for the Goals list and table.
 */
export const selectGoalsForGoalsList = createSelector(
  selectStudents,
  selectStudentsOnline,
  selectGoals,
  selectGoalsProgress,
  (
    students: Members,
    studentsOnline: Members,
    goals: Goals = [],
    goalsProgress: GoalsProgress = []
  ) => {
    const goalsInGoalsList: GoalsInGoalsList = goals.map(goal => {
      const membersThatDoneThisGoal: MemberInGoalsList[] = []
      const membersInProgressWithThisGoal: MemberInGoalsList[] = []
      const memberOnGoal: MemberInGoalsList[] = []

      goalsProgress.forEach(goalProgress => {
        if (goal.goalRef === goalProgress.goalRef) {
          const student = students.find(
            student => student.studliId === goalProgress.studliId
          )

          if (student) {
            const studentWithGoalProgress: MemberInGoalsList = {
              ...student,
              abilityGrade: goalProgress.goalAbilityGrade,
              activeTimeMilliseconds: goalProgress.goalActiveTimeMilliseconds,
              isOnline: studentsOnline.includes(student)
            }

            if (goalProgress.isInProgress) {
              membersInProgressWithThisGoal.push(studentWithGoalProgress)
              if (goalProgress.isCurrentGoal) {
                memberOnGoal.push(studentWithGoalProgress)
              }
            } else {
              membersThatDoneThisGoal.push(studentWithGoalProgress)
            }
          }
        }
      })

      return {
        title: buildGoalTitle(goal.title, goal.order),
        goalRef: goal.goalRef,
        grade: goal.grade,
        gradeLabel: goal.gradeLabel,
        done: membersThatDoneThisGoal,
        inProgress: membersInProgressWithThisGoal,
        current: memberOnGoal
      }
    })

    return goalsInGoalsList
  }
)
