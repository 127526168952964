import React, { useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import * as routerActions from "../../modules/router/actions"
import { selectClassroomId } from "../../modules/classroom/selectors"
import TableBody from "@mui/material/TableBody"
import { RootState } from "../../modules/store"
import { GoalsInGoalsList } from "../../pages/Goals/store/types"
import Row from "../GoalsTableRow"
import {
  StyledHeadCell,
  StyledTable,
  StyledTableHead,
  StyledLegendBox,
  StyledLegendContainer,
  StyledTableRow
} from "./StyledGoalsTable"
import { Dialog } from "@mui/material"
import { selectStudentsWithProductLength } from "../../modules/members/selectors"
import InfoPopover from "../InfoPopover"
import { PROGRESS_SEGMENT } from "../Progress/constants"
import ProgressDialog from "../ProgressDialog"

type OwnProps = {
  goals: GoalsInGoalsList
  tableHeader: string[]
  children?: never
}

type StateProps = {
  numberOfStudentsWithProduct: number
}
type Props = OwnProps & StateProps

const GoalsTable = ({
  goals,
  tableHeader,
  numberOfStudentsWithProduct
}: Props) => {
  /**
   * Render Table Head
   */
  const [progressDialogGoal, setProgressDialogGoal] = useState(-1)

  const closeProgressDialog = () => {
    setProgressDialogGoal(-1)
  }

  const openProgressDialog = (index: number) => {
    setProgressDialogGoal(index)
  }

  const renderTableHead = () => (
    <StyledTableHead>
      <StyledTableRow>
        {tableHeader.map((col, index) => (
          <StyledHeadCell key={index} align="right" component="th" scope="row">
            {col}
            {col === "Status" && (
              <InfoPopover
                type="Info"
                content={
                  <div>
                    <StyledLegendContainer>
                      <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                      Klar
                    </StyledLegendContainer>
                    <StyledLegendContainer>
                      <StyledLegendBox segment={PROGRESS_SEGMENT.IN_PROGRESS} />
                      Påbörjad
                    </StyledLegendContainer>
                    <StyledLegendContainer>
                      <StyledLegendBox segment={PROGRESS_SEGMENT.NOT_STARTED} />
                      Ej påbörjad
                    </StyledLegendContainer>
                  </div>
                }
              />
            )}
          </StyledHeadCell>
        ))}
      </StyledTableRow>
    </StyledTableHead>
  )

  const classroomId = useSelector(selectClassroomId)
  const dispatch = useDispatch()

  const goToGoalDetails = (goalId: string) => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_GOALS_DETAILS, {
        classroomId,
        goalId
      })
    )
  }

  /**
   * Render Table Body
   */
  const renderTableBody = () => {
    return (
      <TableBody>
        {goals.map((goal, index: number) => (
          <Row
            goToDetails={goToGoalDetails}
            onOpenProgressDialog={openProgressDialog}
            key={goal.goalRef}
            goal={goal}
            index={index}
            totalMembers={numberOfStudentsWithProduct}
          />
        ))}
      </TableBody>
    )
  }

  return (
    <StyledTable>
      {renderTableHead()}
      {renderTableBody()}
      {progressDialogGoal >= 0 && (
        <Dialog
          maxWidth="lg"
          onClose={closeProgressDialog}
          open={progressDialogGoal >= 0}
        >
          <ProgressDialog
            onClose={closeProgressDialog}
            goal={goals[progressDialogGoal]}
          />
        </Dialog>
      )}
    </StyledTable>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    numberOfStudentsWithProduct: selectStudentsWithProductLength(state)
  }
}

const ConnectedGoalsTable = connect(mapStateToProps)(GoalsTable)

export default ConnectedGoalsTable
