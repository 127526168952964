import { styled } from "@mui/material"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"

export const StyledGoalsList = styled("span")`
  white-space: nowrap;
`

export const StyledList = styled(List as React.FC)`
  width: 100%;
`

export const StyledListItem = styled(ListItem as React.FC)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #e0e0e0;
`

export const StyledListItemText = styled("div")`
  font-size: 14px;
  max-width: 100%;
  word-wrap: break-word;
  white-space: normal;
`
