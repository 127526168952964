import { EventEmitter } from "events"
import shortid from "shortid"

const audioEventEmitter = new EventEmitter()

let clients: string[] = []

export interface AudioClient {
  id: string
  playing: () => void
  destroy: () => undefined
}

function Client(id: string, onStop: () => void): AudioClient {
  audioEventEmitter.addListener(`stop:${id}`, onStop)

  const playing = () => {
    clients
      .filter(clientId => clientId !== id)
      .forEach(clientId => audioEventEmitter.emit(`stop:${clientId}`))
  }

  const destroy = () => {
    audioEventEmitter.removeListener(`stop:${id}`, onStop)
    clients = clients.filter(clientId => clientId !== id)

    return undefined
  }

  return Object.freeze({
    id,
    destroy,
    playing
  })
}

function createClient(onStop: () => void): AudioClient {
  const id = shortid()
  clients = [...clients, id]

  return Client(id, onStop)
}

export default createClient
