import {
  BookPageNode,
  ChapterOrPage,
  BookChapterNode
} from "../../../modules/book/types"

export const buildPathToPage = (
  pages: ChapterOrPage,
  pageId: BookPageNode["uniqueId"],
  currentPath: any = []
) => {
  const findPath = (node: ChapterOrPage, pageId: BookPageNode["uniqueId"]) => {
    if (node.uniqueId === pageId) {
      return [node]
    } else if (isParent(node)) {
      for (const child of node.children) {
        const childPath: any = findPath(child, pageId)
        if (Array.isArray(childPath)) {
          childPath.unshift(child)

          return childPath
        }
      }
    }
  }

  const foundPath = findPath(pages, pageId)

  if (Array.isArray(foundPath)) {
    currentPath.push(pages)
    currentPath.push(...foundPath)
  }
  currentPath.splice(currentPath.length - 1, 1)

  return currentPath
}

export const buildPathToPageFromPageNr = (
  pages: ChapterOrPage,
  page: BookPageNode["page"],
  currentPath: any = []
) => {
  const findPath = (node: ChapterOrPage, page: BookPageNode["page"]) => {
    if (isPageNode(node)) {
      if (node.page === page) {
        return [node]
      }
    } else if (isParent(node)) {
      for (const child of node.children) {
        const childPath: any = findPath(child, page)
        if (Array.isArray(childPath)) {
          childPath.unshift(child)

          return childPath
        }
      }
    }
  }

  const foundPath = findPath(pages, page)

  if (Array.isArray(foundPath)) {
    currentPath.push(pages)
    currentPath.push(...foundPath)
  }
  currentPath.splice(currentPath.length - 1, 1)

  return currentPath
}

const isParent = (node: ChapterOrPage): node is BookChapterNode => {
  if (node && (node as BookChapterNode).children) {
    return true
  }

  return false
}

const isPageNode = (node: ChapterOrPage): node is BookPageNode => {
  if (node && (node as BookPageNode).page) {
    return true
  }
  return false
}
