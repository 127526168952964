import { Chip, Stack, Theme, useMediaQuery } from "@mui/material"
import React, { useEffect, useMemo } from "react"
import DesktopFilter from "./DesktopFilter"
import MobileFilterButton from "./MobileFilter"
import { FilterChip } from "./shared"
import { Filters, FilterValue } from "./types"

type Props = {
  searchLabel: string
  filters: Filters[]
  onFilter: (filters: FilterValue[]) => void
  searchFunction: Function
  results: number
  sortingComponent?: React.ReactNode
  initialFilters?: FilterValue[]
}

const TableFilter = ({
  filters,
  onFilter,
  searchFunction,
  searchLabel,
  results,
  sortingComponent,
  initialFilters
}: Props) => {
  const [searchText, setSearchText] = React.useState("")
  const [activeFilters, setActiveFilters] = React.useState<FilterValue[]>([])
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("lg"))

  useEffect(() => {
    if (initialFilters) {
      setActiveFilters(initialFilters)
      onFilter(initialFilters)
    }
  }, [initialFilters])
  const onToggleFilter = (filter: FilterValue) => {
    const foundFilter = activeFilters.find(
      f => f.key === filter.key && f.val === filter.val
    )
    if (foundFilter) {
      const newFilters = activeFilters.filter(
        f => f.key !== filter.key || f.val !== filter.val
      )
      setActiveFilters(newFilters)
      onFilter(newFilters)
      return
    }
    setActiveFilters([...activeFilters, filter])
    onFilter([...activeFilters, filter])
  }

  const onRemoveFilter = (filter: FilterValue) => {
    const newFilters = activeFilters.filter(
      f => f.key !== filter.key || f.val !== filter.val
    )
    setActiveFilters(newFilters)
    onFilter(newFilters)
  }

  const removedEmptyFilters = useMemo(() => {
    return filters.filter(f => f.options.length > 0)
  }, [filters])

  const addSearchText = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const newFilter = [
      ...activeFilters,
      {
        key: "search",
        label: `"${searchText}"`,
        val: searchText,
        filterFunction: (m: any) => searchFunction(m, searchText)
      }
    ]
    setActiveFilters(newFilter)
    onFilter(newFilter)
    setSearchText("")
  }

  const clearFilters = () => {
    setActiveFilters([])
    onFilter([])
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      my={2}
      sx={{ width: "100%", alignItems: "flex-start" }}
    >
      {isMobile ? (
        <MobileFilterButton
          activeFilters={activeFilters}
          addSearchText={addSearchText}
          searchText={searchText}
          searchLabel={searchLabel}
          clearFilters={clearFilters}
          filters={removedEmptyFilters}
          onRemoveFilter={onRemoveFilter}
          onToggleFilter={onToggleFilter}
          setSearchText={setSearchText}
          results={results}
          sortingComponent={sortingComponent}
        />
      ) : (
        <DesktopFilter
          activeFilters={activeFilters}
          addSearchText={addSearchText}
          searchText={searchText}
          searchLabel={searchLabel}
          clearFilters={clearFilters}
          filters={removedEmptyFilters}
          onRemoveFilter={onRemoveFilter}
          onToggleFilter={onToggleFilter}
          setSearchText={setSearchText}
        />
      )}
      <Stack
        direction="row"
        gap={1}
        sx={{
          width: "100%",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none"
          },
          ...(!isMobile && {
            flexWrap: "wrap"
          })
        }}
      >
        {activeFilters.length > 0 && (
          <Chip
            label="Rensa allt"
            variant="outlined"
            onClick={clearFilters}
            sx={{
              backgroundColor: "white",
              color: "black",
              cursor: "pointer"
            }}
          />
        )}
        {activeFilters.map(filter => (
          <FilterChip
            key={filter.val + filter.key}
            label={filter.label}
            onDelete={() => onRemoveFilter(filter)}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default TableFilter
