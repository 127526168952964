import { takeLatest, put, all } from "redux-saga/effects"
import { sendRequest } from "../communication/actions"
import * as assignmentsProgressActions from "./actions"
import * as communicationActions from "../communication/actions"
import * as assignmentsProgressTypes from "./types"
import { MODULE_NAME } from "./constants"

function* getAllAssignmentsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      assignmentsProgressActions.SERVER_MESSAGE_ACTION
        .GET_ALL_ASSIGNMENTS_PROGRESS_REQUEST
    )
  )
}

function* watchGetAllAssignmentsProgressSaga() {
  yield takeLatest(
    assignmentsProgressActions.REQUEST.GET_ALL_ASSIGNMENTS_PROGRESS_REQUEST,
    getAllAssignmentsSaga
  )
}

export function* handleReceiveAssignmentsProgressResponseSaga(
  message: assignmentsProgressTypes.ReceiveAssignmentsResponseMessagesTypes
) {
  const { type, action, payload, error } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case assignmentsProgressActions.SERVER_MESSAGE_ACTION
        .GET_ALL_ASSIGNMENTS_PROGRESS_RESPONSE:
        if (error) {
          return
        }
        yield put(
          assignmentsProgressActions.receiveAssignmentsProgress(
            payload as assignmentsProgressTypes.ReceiveProgressAction["payload"]
          )
        )
        break
    }
  }
}

function* watchReceiverSaga() {
  yield takeLatest(
    communicationActions.RECEIVE_RESPONSE,
    handleReceiveAssignmentsProgressResponseSaga
  )
}

export function* rootSaga() {
  yield all([watchGetAllAssignmentsProgressSaga(), watchReceiverSaga()])
}
