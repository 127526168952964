import * as types from "./types"

export const OPEN_SOCKET = "OPEN_SOCKET"
export const SOCKET_OPENED = "SOCKET_OPENED"
export const SOCKET_ERROR = "SOCKET_ERROR"
export const CLOSE_SOCKET = "CLOSE_SOCKET"
export const SOCKET_CLOSED = "SOCKET_CLOSED"
export const SOCKET_CONNECTED = "SOCKET_CONNECTED"
export const RECONNECT_SOCKET = "RECONNECT_SOCKET" //UI's reconnect procedure

export const openSocket = (): types.OpenSocketAction => ({
  type: OPEN_SOCKET
})

export const closeSocket = () => ({
  type: CLOSE_SOCKET
})

export const socketClosed = () => ({
  type: SOCKET_CLOSED
})

export const socketConnected = () => ({
  type: SOCKET_CONNECTED
})

export const socketOpened = (): types.SocketOpenedAction => ({
  type: SOCKET_OPENED
})

export const socketError = (e: Event | Error): types.SocketErrorAction => ({
  type: SOCKET_ERROR,
  payload: e
})

export const reconnectSocket = () => ({
  type: RECONNECT_SOCKET
})
