import * as actions from "./actions"
import * as types from "./types"

import { State } from "./types"

const initialState: State = {
  memberListOrderBy: "lastName",
  memberListOrder: "desc"
}

const reducer = (
  state = initialState,
  action: types.MembersPageActionTypes
): State => {
  switch (action.type) {
    case actions.UI.SET_MEMBERLIST_ORDER:
      const memberListOrder = (action as types.SetMemberListOrder).payload

      return {
        ...state,
        memberListOrderBy: memberListOrder.orderBy,
        memberListOrder: memberListOrder.order
      }

    default:
      return state
  }
}

export default reducer
