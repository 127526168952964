import React from "react"

import DialogTitle from "@mui/material/DialogTitle"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "../Button"
import {
  StyledTextField,
  StyledIconDone,
  StyledIconError
} from "./StyledChangeMemberPasswordDialog"
import InputAdornment from "@mui/material/InputAdornment"

import {
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  FormikProps,
  FormikActions,
  FormikValues
} from "formik"
import * as Yup from "yup"

import { removeEmojis } from "../../shared/tools/strings"

type Props = {
  closeMethod: () => void
  changeMethod: (password: string) => void
  name: string
  children?: never
}

const ChangeMemberPasswordDialog = ({
  closeMethod,
  changeMethod,
  name
}: Props) => {
  const nameOrDefault = name === "" ? "vald elev" : name

  type FormValues = {
    newPassword: string
    confirmPassword: string
  }
  const initialFormValues: FormValues = {
    newPassword: "",
    confirmPassword: ""
  }

  const isSubmitDisabled = (props: FormikProps<FormikValues>) => {
    return !props.isValid || props.isSubmitting
  }

  const showInputHelperText = (
    formikProps: {
      errors: FormikErrors<FormValues>
      touched: FormikTouched<FormValues>
    },
    inputField: keyof FormValues
  ) =>
    formikProps.errors[inputField] && formikProps.touched[inputField]
      ? formikProps.errors[inputField]
      : !formikProps.touched[inputField]

  const showInputError = (
    formikProps: {
      errors: FormikErrors<FormValues>
      touched: FormikTouched<FormValues>
    },
    inputField: keyof FormValues
  ) => formikProps.errors[inputField] && formikProps.touched[inputField]

  const renderAdornment = function (
    formikProps: {
      errors: FormikErrors<FormValues>
      touched: FormikTouched<FormValues>
      values: FormValues
    },
    inputField: keyof FormValues
  ) {
    const { errors, touched, values } = formikProps

    if (
      (inputField === "newPassword" &&
        !errors.newPassword &&
        values.newPassword.length > 0) ||
      (inputField === "confirmPassword" &&
        !errors.confirmPassword &&
        values.confirmPassword.length > 0 &&
        !errors.newPassword)
    ) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <StyledIconDone />
          </InputAdornment>
        )
      }
    }
    if (
      (inputField === "confirmPassword" &&
        errors.confirmPassword &&
        touched.confirmPassword) ||
      (inputField === "newPassword" &&
        errors.newPassword &&
        touched.newPassword)
    ) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <StyledIconError />
          </InputAdornment>
        )
      }
    }

    return {}
  }

  /**
   *  Renders Formik's Form.
   *
   * @param formikProps {Object}
   */
  const renderForm = (formikProps: any) => {
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.value = removeEmojis(e.target.value)

      return formikProps.handleChange(e)
    }

    return (
      <Form>
        <StyledTextField
          name="newPassword"
          label="Nytt lösenord"
          type="text"
          autoComplete="off"
          margin="normal"
          variant="outlined"
          fullWidth
          value={formikProps.values.newPassword}
          onChange={handlePasswordChange}
          onBlur={formikProps.handleBlur}
          helperText={
            showInputHelperText(formikProps, "newPassword") &&
            "Ange minst sex tecken."
          }
          error={
            showInputError(formikProps, "newPassword") &&
            Boolean(formikProps.errors.newPassword)
          }
          InputProps={renderAdornment(formikProps, "newPassword")}
        />
        <StyledTextField
          name="confirmPassword"
          label="Bekräfta lösenord"
          type="text"
          margin="normal"
          variant="outlined"
          fullWidth
          value={formikProps.values.confirmPassword}
          onChange={handlePasswordChange}
          onBlur={formikProps.handleBlur}
          helperText={
            showInputHelperText(formikProps, "confirmPassword") &&
            "Ange samma lösenord som ovan."
          }
          error={
            showInputError(formikProps, "confirmPassword") &&
            Boolean(formikProps.errors.confirmPassword)
          }
          InputProps={renderAdornment(formikProps, "confirmPassword")}
        />
        {formikProps.status && formikProps.status.msg && (
          <div>{formikProps.status.msg}</div>
        )}
        <DialogActions>
          <Button onClick={closeMethod}>Avbryt</Button>
          <Button
            type="submit"
            color="primary"
            disabled={isSubmitDisabled(formikProps)}
          >
            Ändra
          </Button>
        </DialogActions>
      </Form>
    )
  }

  /**
   * Validation schema for form fields, for the Formik element.
   */
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(6, "Lösenordet är för kort, det måste bestå av minst 6 tecken.")
      .required("Vänligen ange ett nytt lösenord."),
    confirmPassword: Yup.string()
      .required(
        "Vänligen bekräfta det nya lösenordet genom att skriva det igen."
      )
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Lösenorden skiljer sig åt. Ändra så att det är samma i båda fälten."
      )
  })

  /**
   * Handles submit of the form. I has access to Formik's properties and actions.
   *
   * @param values {FormValues} - Form's values.
   * @param actions {FormikActions<FormValues>} - Formik's actions.
   */
  const handleSubmit = (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    actions.setSubmitting(true)

    changeMethod(values.newPassword)
    closeMethod()
  }

  return (
    <React.Fragment>
      <DialogTitle id="simple-dialog-title">Ändra lösenord</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ändra lösenord för {nameOrDefault}.
        </DialogContentText>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={validationSchema}
        >
          {formikProps => {
            return renderForm(formikProps)
          }}
        </Formik>
      </DialogContent>
    </React.Fragment>
  )
}

export default ChangeMemberPasswordDialog
