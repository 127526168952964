import * as React from "react"

import { StyledButton } from "./StyledProductLinks"
import { Menu, MenuItem } from "@mui/material"

type ProductLink = {
  href: string
  title: string
}

type Props = {
  links: ProductLink[]
  text: string
  id: string
}

const openLink = (href: string) =>
  window.open(href, "_blank", "noreferrer noopener")

const Link: React.FC<Props> = ({ links, text, id }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  if (!links.length) {
    return null
  }

  if (links.length === 1) {
    const [{ href }] = links

    return (
      <StyledButton variant="outlined" onClick={() => openLink(href)}>
        {text}
      </StyledButton>
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StyledButton
        variant="outlined"
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {text}
      </StyledButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {links.map(({ href, title }) => (
          <MenuItem
            key={href}
            onClick={() => {
              handleClose()
              openLink(href)
            }}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Link
