import * as React from "react"

import useImportAssignments from "./useImportAssignments"
import AssignmentImportDialog from "../AssignmentImportDialog"

export interface Props {
  open: boolean
  onClose: () => void
}

const PreparedAssignmentImportDialog: React.FC<Props> = ({
  open,
  onClose
}): JSX.Element => {
  const [preparedAssignments, importAssignment] = useImportAssignments()

  const numberOfItems = preparedAssignments.reduce(
    (res, item) => res + item.count,
    0
  )

  return (
    <AssignmentImportDialog
      secondTitle="Välj i listan nedan för att skapa ett uppdrag med förberett innehåll."
      title={`Förberedda uppdrag (${numberOfItems})`}
      elementId="import-prepared-assignment"
      folders={preparedAssignments}
      open={open}
      onClose={onClose}
      onSelect={importAssignment}
      notFoundText="Vi kunde inte hitta några förberedda uppdrag som går att importera."
    />
  )
}

export default PreparedAssignmentImportDialog
