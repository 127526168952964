import { pipe } from "fp-ts/lib/pipeable"

import * as actions from "./actions"
import * as classroomActions from "../classroom/actions"
import * as classroomTypes from "../classroom/types"
import { State, Products } from "./types"
import { createReducer } from "../../shared/tools/redux"
import { setField } from "../../shared/tools/monocle"

const initialState: State = { products: [], activeProductId: 0 }

const setActiveProduct = setField<State, number>("activeProductId", p => p)
const setProducts = setField<State, Products>("products", p => p)
const setActiveAndProducts =
  (payload: classroomTypes.ReceiveClassroomInfoPayload) => (state: State) =>
    pipe(
      state,
      setActiveProduct(payload.activeProductId),
      setProducts(payload.products)
    )

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.addProducts, setProducts)
    .case(classroomActions.addClassroomInfo, setActiveAndProducts)
    .case(actions.changeActiveProductResponse, setActiveProduct)
)

export default reducer
