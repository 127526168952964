import React, { useMemo } from "react"
import {
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction
} from "@mui/material"
import {
  GoalInGoalsList,
  MemberInGoalsList
} from "../../pages/Goals/store/types"
import Button from "../Button/Button"
import Avatar from "../Avatar"
import AbilityIndicator from "../AbilityIndicator"
import { buildName } from "../../shared/tools/strings"
import { StyledLabelText, StyledDialogContent } from "./StyledProgressDialog"
import { useSelector } from "react-redux"
import { selectMembersWithActiveProduct } from "../../modules/members/selectors"

type Props = {
  goal: GoalInGoalsList
  onClose: Function
}

const ProgressDialog = ({ goal, onClose }: Props) => {
  const classroomMembers = useSelector(selectMembersWithActiveProduct)
  const renderInProgressListItem = (member: MemberInGoalsList) => {
    return renderListItem(member, member.abilityGrade || 0)
  }

  const renderDoneListItem = (member: MemberInGoalsList) => {
    return renderListItem(member, member.abilityGrade)
  }

  const membersNotStartedGoal = useMemo(
    () =>
      classroomMembers.filter(
        m =>
          !goal.inProgress.find(g => g.studliId === m.studliId) &&
          !goal.done.find(g => g.studliId === m.studliId) &&
          m.type === "student"
      ),
    [goal, classroomMembers]
  )
  const renderListItem = (member: MemberInGoalsList, abilityGrade: number) => {
    return (
      <ListItem divider={true} key={member.studliId}>
        <ListItemAvatar>
          <Avatar
            picture={member.picture}
            firstName={member.firstName}
            lastName={member.lastName}
            studliId={member.studliId}
          />
        </ListItemAvatar>
        <ListItemText>
          {buildName(member.firstName, member.lastName)}
        </ListItemText>
        <ListItemSecondaryAction>
          <AbilityIndicator grade={abilityGrade} />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <>
      <DialogTitle>Status för mål &quot;{goal.title}&quot;</DialogTitle>
      <StyledDialogContent>
        {goal.inProgress.length > 0 && (
          <>
            <StyledLabelText>
              PÅBÖRJAT ({goal.inProgress.length})
            </StyledLabelText>
            <List>
              {goal.inProgress.map(member => renderInProgressListItem(member))}
            </List>
          </>
        )}
        {goal.done.length > 0 && (
          <>
            <StyledLabelText>KLAR ({goal.done.length})</StyledLabelText>
            <List>{goal.done.map(member => renderDoneListItem(member))}</List>
          </>
        )}
        {membersNotStartedGoal.length > 0 && (
          <>
            <StyledLabelText>
              EJ PÅBÖRJAT ({membersNotStartedGoal.length})
            </StyledLabelText>
            <List>
              {membersNotStartedGoal.map(member =>
                renderDoneListItem(member as MemberInGoalsList)
              )}
            </List>
          </>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          STÄNG
        </Button>
      </DialogActions>
    </>
  )
}

export default ProgressDialog
