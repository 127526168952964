/**
 * These select* functions are  for redux select(), they expose parts of the state.
 */

import { GoalsProgress } from "./types"
import { RootState } from "../store"
import { createSelector } from "reselect"

export const selectGoalsProgress = (store: RootState): GoalsProgress =>
  store.goalsProgress.goalsProgress || []

export const selectGoalsProgressLength = (store: RootState): number =>
  selectGoalsProgress(store).length

export const selectFinishedProgressForCurrentStudent = createSelector(
  selectGoalsProgress,
  ({ location }: RootState) => +location.payload.memberId,
  (goalsProgress, memberId) =>
    goalsProgress.filter(
      progress => progress.studliId === memberId && !progress.isInProgress
    )
)

export const selectProgressForCurrentStudent = createSelector(
  selectGoalsProgress,
  ({ location }: RootState) => +location.payload.memberId,
  (goalsProgress, memberId) =>
    goalsProgress.filter(progress => progress.studliId === memberId)
)
