/** This is a wrapper for the LoadingDots, it delays the show of them by specified delay in ms. */
import React, { useEffect, useState } from "react"

import { StyledLoader } from "./StyledLoader"
import LoadingDots from "../LoadingDots"
import { StudliTheme } from "../../shared/styles/theme"

type Props = {
  delay: number
  text?: string
  numberOfDots?: number
  color?: string
}

const Loader = (
  { delay, text, numberOfDots, color }: Props,
  props: Props & StudliTheme
) => {
  const [show, setShow] = useState(false)

  /**
   * Will set local-state variable delayed by some time. By doing it in this hook this component will react.
   * Note that the clearTimeout() is also called in when component un-mounts.
   */
  useEffect(() => {
    const setTimeoutId = window.setTimeout(() => setShow(true), delay)

    return () => window.clearTimeout(setTimeoutId)
    // eslint-disable-next-line
  }, [])

  const dotsColor = color || props.studli.palette.appBarBackground

  return (
    <StyledLoader>
      {text && show && <p>{text}</p>}
      {show && (
        <LoadingDots numberOfDots={numberOfDots} color={dotsColor.toString()} />
      )}
    </StyledLoader>
  )
}

export default Loader
