import { takeLatest, put, all, race, select } from "redux-saga/effects"

import * as actions from "./actions"
import * as communicationActions from "../communication/actions"
import * as applicationActions from "../application/actions"
import * as classroomTypes from "../classroom/types"
import * as classroomActions from "../classroom/actions"
import { MODULE_NAME } from "./constants"
import { sendRequest } from "../communication/actions"
import * as types from "./types"
import * as communicationTypes from "../communication/types"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import {
  selectAllActiveGroups,
  selectAllGroups,
  selectClassroomId
} from "../classroom/selectors"
import * as Sentry from "@sentry/browser"
import * as routerActions from "../router/actions"

function* searchExistingUsersSaga(message: types.SearchExistingUsersAction) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.SEARCH_EXISTING_USERS_REQUEST,
      message.payload
    )
  )
}

function* watchSearchExistingUsersSaga() {
  yield takeLatest(
    actions.REQUEST.SEARCH_EXISTING_USERS_REQUEST,
    searchExistingUsersSaga
  )
}

function* getStudentsFromClassroomsSaga(
  message: types.GetStudentsFromClassroomsAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_STUDENTS_FROM_CLASSROOMS_REQUEST,
      message.payload
    )
  )
}

function* watchGetStudentsFromClassroomsSaga() {
  yield takeLatest(
    actions.REQUEST.GET_STUDENTS_FROM_CLASSROOMS_REQUEST,
    getStudentsFromClassroomsSaga
  )
}

function* activateProductKeysSaga(message: types.ActivateProductKeysAction) {
  const keys = message.payload.keys

  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.ACTIVATE_PRODUCT_KEYS_REQUEST,
      { keys },
      message.payload.echo
    )
  )
}

function* watchActivateProductKeysSaga() {
  yield takeLatest(
    actions.REQUEST.ACTIVATE_PRODUCT_KEYS_REQUEST,
    activateProductKeysSaga
  )
}

function* validateUsernameSaga(message: types.ValidateUsernameAction) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.VALIDATE_USERNAME_REQUEST,
      message.payload
    )
  )
}

function* watchValidateUsernameSaga() {
  yield takeLatest(
    actions.REQUEST.VALIDATE_USERNAME_REQUEST,
    validateUsernameSaga
  )
}

function* getAuthUserInfoRequestSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_AUTH_USER_INFO_REQUEST
    )
  )
}

function* watchGetAuthUserInfoSaga() {
  yield takeLatest(
    actions.REQUEST.AUTH_USER_INFO_REQUEST,
    getAuthUserInfoRequestSaga
  )
}

function* updateUserSettingSaga(message: types.UpdateUserSettingRequestAction) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.UPDATE_USER_SETTINGS_REQUEST,
      message.payload
    )
  )
}

function* watchUpdateUserSettingSaga() {
  yield takeLatest(
    actions.REQUEST.UPDATE_USER_SETTINGS_REQUEST,
    updateUserSettingSaga
  )
}

function* getUsersWithProductSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_USERS_WITH_PRODUCT_REQUEST
    )
  )
}

function* watchGetUsersWithProductSaga() {
  yield takeLatest(
    actions.REQUEST.GET_USERS_WITH_PRODUCT_REQUEST,
    getUsersWithProductSaga
  )
}

function* getUsersOnlineSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_USERS_ONLINE_REQUEST
    )
  )
}

function* watchGetUsersOnlineSaga() {
  yield takeLatest(actions.REQUEST.GET_USERS_ONLINE_REQUEST, getUsersOnlineSaga)
}

function* getActiveProductsSaga(message: types.GetActiveProductsAction) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_ACTIVE_PRODUCTS_REQUEST
    )
  )
}

function* watchGetActiveProductsSaga() {
  yield takeLatest(
    actions.REQUEST.GET_ACTIVE_PRODUCTS_REQUEST,
    getActiveProductsSaga
  )
}

function* handleReceivedUsersResponseSaga(
  message: types.ReceiveUsersResponseMessagesTypes
) {
  const { type, action, payload, error, echo } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.GET_STUDENTS_FROM_CLASSROOMS_RESPONSE:
        yield receiveStudentsFromClassroomsResponse(
          payload as types.ReceiveStudentsFromClassroomsResponseAction["payload"],
          error
        )

        break

      case actions.SERVER_MESSAGE_ACTION.CREATE_USER_RESPONSE:
        yield receiveCreateUserResponse(
          payload as types.ReceiveCreateUserResponseAction["payload"],
          error
        )

        break

      // This response's data will be interpreted as two responses
      case actions.SERVER_MESSAGE_ACTION.ACTIVATE_PRODUCT_KEYS_RESPONSE:
        yield receiveActivateProductKeysResponse(
          payload as types.ReceiveActivateProductKeysResponseAction["payload"],
          echo as types.ReceiveActivateProductKeysResponseAction["echo"]
        )

        break
      case actions.SERVER_MESSAGE_ACTION.VALIDATE_USERNAME_RESPONSE:
        yield put(
          actions.receiveValidateUsernameResponse(
            payload as types.ReceiveValidateUsernameResponseAction["payload"]
          )
        )

        break

      case actions.SERVER_MESSAGE_ACTION.GET_ACTIVE_PRODUCTS_RESPONSE:
        yield put(
          actions.receiveGetActiveProductsResponse(
            payload as types.ReceiveGetActiveProductsResponseAction["payload"]
          )
        )

        break

      case actions.SERVER_MESSAGE_ACTION.GET_AUTH_USER_INFO_RESPONSE:
        const authUserPayload =
          payload as types.GetAuthUserInfoResponseAction["payload"]
        const userId: string =
          authUserPayload &&
          authUserPayload.user &&
          authUserPayload.user.studliId
            ? authUserPayload.user.studliId.toString()
            : "-1"

        // Makes it possible to filter issues on USER.ID (the teacher's studliId)
        Sentry.setUser({ id: userId })

        yield receiveAuthUserInfoResponse(authUserPayload, error)

        break

      case actions.SERVER_MESSAGE_ACTION.GET_USERS_WITH_PRODUCT_RESPONSE:
        yield put(
          actions.receiveGetUsersWithProductResponse(
            payload as types.ReceiveGetUsersWithProductResponseAction["payload"]
          )
        )

        break

      case actions.SERVER_MESSAGE_ACTION.GET_USERS_ONLINE_RESPONSE:
        yield put(
          actions.receiveGetUsersOnlineResponse(
            payload as types.ReceiveGetUsersOnlineResponseAction["payload"]
          )
        )

        break

      case actions.SERVER_MESSAGE_ACTION.UPDATE_USER_SETTINGS_RESPONSE:
        yield put(
          actions.receiveUpdateUserSettingResponse(
            payload as types.UpdateUserSettingResponseAction["payload"]
          )
        )
        break

      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in response`
        )
    }
  }
}

export function* handleReceiveUsersEventSaga(
  message: types.ReceiveUsersEventMessagesTypes
) {
  const { type, action, payload } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.USERS_ONLINE_UPDATED_EVENT:
        yield put(
          actions.receiveUsersOnlineUpdatedAction(
            payload as types.ReceiveUsersOnlineUpdatedAction["payload"]
          )
        )
        break
      case actions.SERVER_MESSAGE_ACTION.WITH_PRODUCT_UPDATED_EVENT:
        yield put(
          actions.receiveUsersWithProductUpdatedAction(
            payload as types.ReceiveUsersWithProductUpdatedAction["payload"]
          )
        )
        break

      case actions.SERVER_MESSAGE_ACTION.USERS_GROUP_ADDED_EVENT:
        const group = payload as types.ReceiveUsersGroupAddedAction["payload"]

        let groups = yield select(selectAllGroups)
        groups.push(group)

        const p: classroomTypes.ReceiveClassroomGroupsPayload = {
          groups: groups
        }

        yield put(classroomActions.addClassroomGroups(p))

        break
      case actions.SERVER_MESSAGE_ACTION.USERS_GROUP_REMOVED_EVENT:
        const groupId =
          payload as types.ReceiveUserGroupRemovedAction["payload"]

        const activeGroups = yield select(selectAllActiveGroups)

        const newActiveGroupIds = activeGroups
          .filter((g: classroomTypes.Group) => g.id !== groupId)
          .map((g: classroomTypes.Group) => g.id)

        if (activeGroups.length !== newActiveGroupIds.length) {
          const p: classroomTypes.ChangeClassroomGroupPayload = {
            activeGroupIds: newActiveGroupIds
          }

          yield put(classroomActions.changeClassroomGroups(p))
        }

        yield put(classroomActions.classroomGroupsRemoved([groupId]))

        break
      case actions.SERVER_MESSAGE_ACTION.USERS_REMOVED_EVENT:
        // TODO: Implement logic handling current user being removed from a school unit
        window.location.reload()
        break
    }
  }
}

export function* receiveAuthUserInfoResponse(
  payload: types.GetAuthUserInfoResponseAction["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.AUTH_USER_INFO_RESPONSE_FAILURE
      )
    )
  } else {
    yield put(actions.receiveGetAuthUserInfoResponse(payload))
  }
}

export function* receiveStudentsFromClassroomsResponse(
  payload: types.ReceiveStudentsFromClassroomsResponseAction["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.GET_STUDENTS_FROM_CLASSROOMS_RESPONSE_FAILURE
      )
    )
  } else {
    yield put(actions.receiveUsersFromClassroomsResponse(payload))
  }
}

/**
 * Receive update-member response.
 *
 * @param payload {Object} - data concerning the request and response.
 * @param error {Object} - if response indicates a failure this object will be defined, else not.
 */
export function* receiveCreateUserResponse(
  payload: types.ReceiveCreateUserResponseAction["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    // dispatch action setting error for component to handle (compare with "updateMemberError")
    yield put(actions.receiveCreateUserFailureResponse(error))
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.CREATE_USER_RESPONSE_FAILURE
      )
    )
  } else if (payload) {
    yield put(actions.receiveCreateUserSuccessResponse(payload))
    yield put(
      applicationActions.setSnackbar(snackbarMessages.CREATE_USER_RESPONSE)
    )
  } else {
    window.console.error(
      `Module users received CREATE_USER_RESPONSE with no payload nor error object. Ignoring it.`
    )
  }
}

/**
 * Receive activate product response.
 *
 * @param payload {Object} - data concerning the request and response.
 */
export function* receiveActivateProductKeysResponse(
  payload: types.ReceiveActivateProductKeysResponseAction["payload"],
  echo: "redirect" | "stay"
) {
  const activeKeys = payload.keys
  const activeProducts = payload.activeProducts
  yield put(
    actions.receiveActivateProductKeysResponse(
      {
        keys: activeKeys,
        activeProducts
      },
      echo
    )
  )
  // Only one product at a time can be activated, and if it was, snackbar should inform
  if (activeKeys && activeKeys[0] && activeKeys[0].valid === "yes") {
    yield put(
      applicationActions.setSnackbar(
        snackbarMessages.ACTIVATE_PRODUCT_KEYS_RESPONSE
      )
    )
    if (echo === "redirect") {
      const classroomId = yield select(selectClassroomId)
      yield put(
        routerActions.gotoRoute(routerActions.ROUTE_AKTIVERADE, { classroomId })
      )
    }
  }

  yield put(actions.receiveGetActiveProductsResponse({ activeProducts }))
  yield put(actions.getUsersWithProduct())
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceivedUsersResponseSaga
    ),
    yield takeLatest(
      communicationActions.RECEIVE_EVENT,
      handleReceiveUsersEventSaga
    )
  ])
}

export function* rootSaga() {
  yield all([
    watchSearchExistingUsersSaga(),
    watchActivateProductKeysSaga(),
    watchGetActiveProductsSaga(),
    watchValidateUsernameSaga(),
    watchGetAuthUserInfoSaga(),
    watchGetUsersWithProductSaga(),
    watchGetUsersOnlineSaga(),
    watchReceiverSaga(),
    watchGetStudentsFromClassroomsSaga(),
    watchUpdateUserSettingSaga()
  ])
}
