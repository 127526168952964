import React, { useMemo } from "react"
import { Assignment } from "../../modules/assignments/types"
import { AssignmentProgress } from "../../modules/assignmentsProgress/types"
import { StyledStatusTag } from "./StyledAssignmentStatusAndApprovedTags"

type Props = {
  progress: AssignmentProgress | undefined
  assignment: Assignment
}

type PossibleStatuses = "done" | "notStarted" | "inProgress"

const AdaptAssignmentStatusTag = ({ progress, assignment }: Props) => {
  const status:
    | { status: PossibleStatuses; time: string; translation: string }
    | undefined = useMemo(() => {
    if (assignment) {
      if (!progress) {
        return { status: "notStarted", time: "", translation: "Inte påbörjat" }
      }
      if (progress.progress >= assignment.threshold) {
        return { status: "done", time: "", translation: "Klar" }
      }
      return { status: "inProgress", time: "", translation: "Påbörjat" }
    }
    return undefined
  }, [progress, assignment])

  return (
    <>
      {status && (
        <StyledStatusTag status={status.status}>
          {status.translation}
        </StyledStatusTag>
      )}
    </>
  )
}

export default AdaptAssignmentStatusTag
