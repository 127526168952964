import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  FormativeQuestions,
  FormativeQuestion
} from "../../modules/formative/types"
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItemText,
  Checkbox,
  ListItemButton
} from "@mui/material"
import {
  StyledFormativeQuestionsList,
  StyledFormativeQuestionsListItem,
  StyledListItemIcon
} from "./StyledFormativeQuestionsDialog"
import { ReactComponent as FolderIcon } from "../../assets/images/folder-closed.svg"
import { ReactComponent as FolderOpenIcon } from "../../assets/images/folder-open.svg"
import FormativeQuestionsList from "../FormativeQuestionsList"
import { selectQuestionsWithChapters } from "../../modules/formative/selectors"
import { isQuestion } from "./helpers"
type Props = {
  onSubmitQuestions: (questions: FormativeQuestions) => void
  onCancel: () => void
  selectedQuestions: FormativeQuestions
}

type ChaptersWithQuestions = {
  [chapterName: string]: { children: FormativeQuestions }
}

const FormativeQuestionsDialog = ({
  onSubmitQuestions,
  onCancel,
  selectedQuestions
}: Props) => {
  const [openFolders, setOpenFolders] = useState<string[]>([])
  const [selectedQuestionsAndFolders, setSelectedQuestionsAndFolders] =
    useState<(string | FormativeQuestion)[]>([])

  const chapters = useSelector(selectQuestionsWithChapters)
  const addQuestions = () => {
    const questionsToAdd = selectedQuestionsAndFolders.filter(isQuestion)
    onSubmitQuestions(questionsToAdd)
  }

  useEffect(() => {
    if (
      selectedQuestions &&
      selectedQuestions.length &&
      !selectedQuestionsAndFolders.length
    ) {
      const selected: (string | FormativeQuestion)[] = [...selectedQuestions]
      selectedQuestions.forEach(question => {
        const folderName = `${question.folder} / ${question.category}`
        const indexOfFolderInSelected = selected.findIndex(
          selectQuestion =>
            !isQuestion(selectQuestion) && selectQuestion === folderName
        )
        if (indexOfFolderInSelected === -1) {
          selected.push(folderName)
        }
      })
      setSelectedQuestionsAndFolders(selected)
    }
    // eslint-disable-next-line
  }, [selectedQuestions])

  const onQuestionCheck = (question: FormativeQuestion) => {
    const index = selectedQuestionsAndFolders.findIndex(
      checked => isQuestion(checked) && checked.id === question.id
    )
    const newCheckedArray = [...selectedQuestionsAndFolders]
    if (index !== -1) {
      newCheckedArray.splice(index, 1)
    } else {
      newCheckedArray.push(question)
    }
    setSelectedQuestionsAndFolders(newCheckedArray)
  }

  const onFolderChecked = (
    event: any,
    folder: string,
    questions: FormativeQuestions
  ) => {
    event.stopPropagation()
    const index = selectedQuestionsAndFolders.findIndex(
      checked => checked === folder
    )
    let newCheckedArray = [...selectedQuestionsAndFolders]
    if (index === -1) {
      newCheckedArray = [...newCheckedArray, ...questions, folder]
    } else {
      newCheckedArray = newCheckedArray.filter(checked => {
        return isQuestion(checked)
          ? !questions.some(question => question.id === checked.id)
          : checked !== folder
      })
    }
    setSelectedQuestionsAndFolders(newCheckedArray)
  }
  const toggleFolderOpen = (folderToggle: string) => {
    const indexOfFolderInOpenFolers = openFolders.findIndex(
      folder => folder === folderToggle
    )
    let newOpenFolders = [...openFolders]
    if (indexOfFolderInOpenFolers === -1) {
      newOpenFolders.push(folderToggle)
    } else {
      newOpenFolders.splice(indexOfFolderInOpenFolers, 1)
    }
    setOpenFolders(newOpenFolders)
  }

  const renderFolderList = (chaptersToRender: ChaptersWithQuestions) => {
    return Object.keys(chaptersToRender).map(key => {
      const isOpen = openFolders.some(open => open === key)
      const questionsInFolder = chaptersToRender[key].children
      const isChecked = selectedQuestionsAndFolders.some(
        checked => checked === key
      )
      return (
        <StyledFormativeQuestionsList component="div" key={key}>
          <StyledFormativeQuestionsListItem divider={true}>
            <ListItemButton onClick={() => toggleFolderOpen(key)}>
              <StyledListItemIcon>
                <Checkbox
                  aria-label={key}
                  onClick={event =>
                    onFolderChecked(event, key, questionsInFolder)
                  }
                  checked={isChecked}
                />
                {isOpen ? <FolderOpenIcon /> : <FolderIcon />}
              </StyledListItemIcon>
              <ListItemText primary={`${key} (${questionsInFolder.length})`} />
            </ListItemButton>
          </StyledFormativeQuestionsListItem>
          <FormativeQuestionsList
            questions={questionsInFolder}
            isOpen={isOpen}
            checkedQuestions={selectedQuestionsAndFolders}
            onQuestionCheck={onQuestionCheck}
            collapse
            selectable
          />
        </StyledFormativeQuestionsList>
      )
    })
  }
  return (
    <>
      <DialogTitle>Välj innehåll</DialogTitle>
      <DialogContent dividers={true}>
        {chapters && renderFolderList(chapters)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">
          Avbryt
        </Button>
        <Button
          disabled={
            selectedQuestionsAndFolders.length < 1 &&
            selectedQuestions.length === 0
          }
          onClick={addQuestions}
          variant="outlined"
          color="primary"
        >
          Välj
        </Button>
      </DialogActions>
    </>
  )
}

export default FormativeQuestionsDialog
