import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectCurrentAssignment } from "../../modules/assignments/selectors"
import { selectGoalAssignmentByStudentAndAssignment } from "../../modules/assignmentsProgress/selectors"
import {
  AssignmentGoalProgress,
  AssignmentsGoalProgress
} from "../../modules/assignmentsProgress/types"
import { selectGoals } from "../../modules/goals/selectors"

export type ProgressForCard = {
  specific: ProgressWithTitle[]
  unspecific: ProgressWithTitle[]
}

export type ProgressWithTitle = AssignmentGoalProgress & {
  title: string
  order: number
  grades: AssignmentsGoalProgress
}

type ReturnProps = {
  progressWithTitle: ProgressForCard
}

function useAdaptStudentCard(): ReturnProps {
  const assignmentProgress = useSelector(
    selectGoalAssignmentByStudentAndAssignment
  )
  const goals = useSelector(selectGoals)
  const currentAssignment = useSelector(selectCurrentAssignment)

  const progressWithTitle = useMemo(() => {
    const getTitle = (goalRef: string) => {
      const goal = goals.find(g => g.goalRef === goalRef)
      return goal ? goal.title : ""
    }

    const getOrder = (goalRef: string) => {
      const goal = goals.find(g => g.goalRef === goalRef)
      return goal ? goal.order : 0
    }
    if (currentAssignment) {
      return assignmentProgress
        .filter(
          (a, index) =>
            assignmentProgress.findIndex(p => p.goalRef === a.goalRef) === index
        )
        .map(a => ({
          ...a,
          title: getTitle(a.goalRef),
          order: getOrder(a.goalRef),
          grades: assignmentProgress.filter(p => p.goalRef === a.goalRef)
        }))
        .reduce(
          (acc: ProgressForCard, curr) => {
            const isSpecific = currentAssignment.assignmentTasks.some(
              t => t.link === curr.goalRef
            )
            return isSpecific
              ? { ...acc, specific: [...acc.specific, curr] }
              : { ...acc, unspecific: [...acc.unspecific, curr] }
          },
          { specific: [], unspecific: [] }
        )
    }
    return { specific: [], unspecific: [] }
  }, [assignmentProgress, currentAssignment, goals])

  return {
    progressWithTitle
  }
}

export default useAdaptStudentCard
