import React from "react"
import { useDispatch } from "react-redux"
import useStudentFormativeList from "./useStudentFormativeList"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import { StyledNoInfo } from "../StudentHistoryList/StyledHistoryList"
import FormativeQuestionsTable from "../FormativeQuestionsTable"
import FormativeQuestionsTableAsList from "../FormativeQuestionsTableAsList"
import * as routerActions from "../../modules/router/actions"
import { formativeStatusSortWeight } from "../../pages/FormativeQuestions/filterSettings"
import moment from "moment"

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = () => {}

const StudentFormativeList = () => {
  const { tests, progress, member, activeProduct, classroomId } =
    useStudentFormativeList()
  const dispatch = useDispatch()
  const goToResults = (testId: number) => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR_ELEV_INFO, {
        classroomId,
        testId,
        memberId: member.studliId
      })
    )
  }

  const renderTests = tests.sort(
    (a, b) =>
      formativeStatusSortWeight.indexOf(a.status) -
        formativeStatusSortWeight.indexOf(b.status) ||
      moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
  )
  return (
    <>
      {renderTests.length > 0 ? (
        <MobileOrDesktop>
          {{
            desktop: (
              <>
                <FormativeQuestionsTable
                  onActionStatusClicked={NOOP}
                  onMenuClick={NOOP}
                  title=""
                  showIcon
                  tests={renderTests}
                  type="studentCard"
                  onProgressDialogClick={NOOP}
                  onRowClick={goToResults}
                  progress={progress}
                />
              </>
            ),
            mobile: (
              <>
                <FormativeQuestionsTableAsList
                  onActionStatusClicked={NOOP}
                  onMenuClick={NOOP}
                  title=""
                  tests={renderTests}
                  type="studentCard"
                  onProgressDialogClick={NOOP}
                  onRowClick={goToResults}
                  progress={progress}
                />
              </>
            )
          }}
        </MobileOrDesktop>
      ) : (
        <StyledNoInfo>
          {member.firstName} har ännu inte fått några test i {activeProduct}
        </StyledNoInfo>
      )}
    </>
  )
}

export default StudentFormativeList
