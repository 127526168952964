import React, { useEffect, useMemo, useState } from "react"
import { connect, useSelector, useDispatch } from "react-redux"

import { Container, Button, Grid, Dialog, Stack } from "@mui/material"

import CustomDialog from "../../components/Dialog"
import { MenuItems } from "../../components/PopupMenu/PopupMenu"
import TitleAndDescription from "../../components/TitleAndDescription"
import MembersTable from "../../components/MembersTable"
import MembersList from "../../components/MembersList"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import RemoveMemberDialog from "../../components/RemoveMemberDialog"
import ChangeMemberPasswordDialog from "../../components/ChangeMemberPasswordDialog"

import * as membersTypes from "../../modules/members/types"
import * as membersActions from "../../modules/members/actions"

import { RootState } from "../../modules/store"
import * as routerActions from "../../modules/router/actions"
import * as routerSelectors from "../../modules/router/selectors"
import { selectGoalsProgressLength } from "../../modules/goalsProgress/selectors"
import { selectGoalsLength } from "../../modules/goals/selectors"

import { buildNameAndUserName } from "../../shared/tools/strings"
import {
  VISIBLE_ADAPT_MEMBER_PROPS,
  VISIBLE_DILL_MEMBER_PROPS
} from "./constants"
import { ReactComponent as AddUserSVG } from "../../assets/images/card-add-user.svg"
import * as membersPagesTypes from "./store/types"
import { selectStudentsForStudentList } from "./store/selectors"
import { selectClassroomActiveProductInfo } from "../../modules/products/selectors"
import { Product } from "../../modules/products/types"
import { activeProductType } from "../../modules/products/constants"
import PDFGenerator from "../../shared/tools/PDFGenerator"
import {
  selectClassroomCode,
  selectClassroomName,
  selectTypeOfClassroom,
  selectClassroomFeatures
} from "../../modules/classroom/selectors"
import ClassroomCodeDialog from "../../components/ClassroomCodeDialog"
import OnBoarding from "../../components/OnBoarding"
import { selectRecentlyAddedMembers } from "../../modules/members/selectors"
import { selectExercisesLength } from "../../modules/exercises/selectors"
import ChangeClassPasswordDialog from "../../components/ChangeClassPasswordDialog"
import { TransitionProps } from "@mui/material/transitions"
import SetStartGoalDialog from "../../components/SetStartGoalDialog"
import { setStartGoal } from "../../modules/goals/actions"
import { getCommonStartgoal } from "./common/helpers"
import { Features } from "../../modules/classroom/types"
import FullWidthContainer from "../../containers/PageContainers/FullWidthContainer"

type OwnProps = {
  children?: never
}

type StateProps = {
  activeProduct: Product
  students: membersPagesTypes.StudentInStudentList[]
  goalsProgressLength: number
  goalsLength: number
  classroomId: number
  classroomCode: string
  classroomName: string
  exerciseLength: number
  classroomFeatures: Features
}

type DispatchProps = {
  removeMember: (
    id: membersTypes.Member["studliId"]
  ) => membersTypes.RemoveMemberAction
  changePassword: (
    id: membersTypes.Member["studliId"],
    password: string
  ) => membersTypes.ChangeMemberPasswordAction
  gotoRoute: (route: string, payload: object) => void
  setRecentlyAddedMembers: (ids: membersTypes.Member["studliId"][]) => void
}

type Props = OwnProps & DispatchProps & StateProps

export const Members = ({
  removeMember,
  changePassword,
  students = [],
  classroomId,
  activeProduct,
  classroomCode,
  gotoRoute,
  classroomName,
  setRecentlyAddedMembers,
  exerciseLength,
  classroomFeatures
}: Props) => {
  /**
   * Members from state are modified, enhanced, with ui-specific stuff before sent further to table and list.
   */
  const [studentsToShow, setStudentsToShow] = useState<
    membersPagesTypes.StudentWithMoreMenu[]
  >([])

  const [changeClassPasswordDialog, setChangeClassPasswordDialog] =
    useState(false)

  const [changeStartGoalDialog, setChangeStartGoalDialog] = useState<{
    members: membersPagesTypes.StudentInStudentList[]
    total: number
    currentGoal: string | undefined
  } | null>(null)

  const [memberToRemove, setMemberToRemove] = useState<
    membersPagesTypes.StudentInStudentList[] | null
  >(null)
  const [memberToChangePasswordFor, setMemberToChangePasswordFor] =
    useState<membersPagesTypes.StudentInStudentList | null>(null)

  const [checkedMembers, setCheckedMembers] = useState<
    membersPagesTypes.StudentInStudentList[]
  >([])

  const [classroomCodeDialogOpen, setClassroomCodeDialogOpen] = useState(false)
  const recentlyAddedMembers = useSelector(selectRecentlyAddedMembers)
  const classroomType = useSelector(selectTypeOfClassroom)

  const dispatch = useDispatch()
  useEffect(() => {
    const membersWithMoreMenu = createMembersWithMoreMenu(students)
    setStudentsToShow(membersWithMoreMenu)

    // eslint-disable-next-line
  }, [students])
  useEffect(() => {
    return () => setRecentlyAddedMembers([])
    // eslint-disable-next-line
  }, [])
  const createMemberWithMoreMenu = (
    student: membersPagesTypes.StudentInStudentList
  ): membersPagesTypes.StudentWithMoreMenu => {
    const moreMenu: MenuItems = []
    if (student.hasProduct && classroomType === "adapt") {
      moreMenu.push({
        option: "Ändra startmål",
        action: () =>
          setChangeStartGoalDialog({
            members: [student],
            total: studentsToShow.length,
            currentGoal: student.goalRef
          })
      })
    }

    return { ...student, moreMenuItems: moreMenu }
  }

  const createMembersWithMoreMenu = (
    students: membersPagesTypes.StudentInStudentList[]
  ) =>
    students.map(
      (student): membersPagesTypes.StudentWithMoreMenu =>
        createMemberWithMoreMenu(student)
    )

  const getPendingMemberName = (
    pendingMember?:
      | membersPagesTypes.StudentInStudentList[]
      | membersPagesTypes.StudentInStudentList
      | null
  ) => {
    if (Array.isArray(pendingMember)) {
      return pendingMember.length > 1
        ? `${pendingMember.length} elever`
        : buildNameAndUserName(
            pendingMember[0].firstName,
            pendingMember[0].lastName,
            pendingMember[0].username
          )
    } else if (pendingMember) {
      return buildNameAndUserName(
        pendingMember.firstName,
        pendingMember.lastName,
        pendingMember.username
      )
    }

    return ""
  }

  const removeMemberIfMarkedForRemoval = () => {
    memberToRemove &&
      memberToRemove.forEach(member => removeMember(member.studliId))
    setCheckedMembers([])
  }

  const changeMemberPasswordIfMemberToChangePasswordFor = (
    newPassword: string
  ) => {
    memberToChangePasswordFor &&
      changePassword(memberToChangePasswordFor.studliId, newPassword)
  }

  const renderTitleAndDescription = () => {
    const paragraphsTomoyo = [
      `Här kan du lägga till och ta bort elever. I elevlistan ser du eleverna du har lagt till i ditt klassrum. Du får en snabb översikt över aktivitet och vad de arbetar med just nu. Vill du ha fördjupad information kan du gå in på varje elev.`
    ]

    const paragraphsDill = [
      `I elevlistan ser du vilka elever som finns kopplade till ditt klassrum. Du kan följa hur många övningar och uppdrag eleverna är klara med. Du ser också när de senast var inloggade.`
    ]
    const paragraphs =
      activeProduct.type === "adapt" ? paragraphsTomoyo : paragraphsDill

    return <TitleAndDescription title="Din elevlista" paragraphs={paragraphs} />
  }

  const renderCardContent = () => {
    const memberListContentTomoyo = {
      title: "Din elevlista",
      body: [
        `Det finns inga elever att visa. För att kunna följa upp elever behöver du välja en grupp med elever att visa. Du kan gå med som lärare i en av skolans befintliga grupper eller skapa en helt ny grupp där du lägger till dig själv och de elever du vill följa.`
      ],
      image: <AddUserSVG />
    }
    const memberListContentDill = {
      title: "Din elevlista",
      body: [
        `Det finns inga elever att visa. För att kunna följa upp elever behöver du välja en grupp med elever att visa. Du kan gå med som lärare i en av skolans befintliga grupper eller skapa en helt ny grupp där du lägger till dig själv och de elever du vill följa.`
      ],
        image: <AddUserSVG />
    }

    return activeProduct.type === "adapt"
      ? memberListContentTomoyo
      : memberListContentDill
  }

  const getTableProps = () => {
    switch (activeProduct.type) {
      case activeProductType.ADAPT:
        return VISIBLE_ADAPT_MEMBER_PROPS
      case activeProductType.DILL:
      case activeProductType.DILL_CLOUD:
        let headers = VISIBLE_DILL_MEMBER_PROPS
        if (!classroomFeatures.length || !hasFormativeTests) {
          headers = headers.filter(p => p.id !== "completedTests")
        }
        return exerciseLength > 0
          ? headers
          : headers.filter(p => p.id !== "completedExercises")
      default:
        return VISIBLE_ADAPT_MEMBER_PROPS
    }
  }

  const onCheckboxClick = (member: membersPagesTypes.StudentInStudentList) => {
    const indexOfCheckedId = checkedMembers.findIndex(
      student => student.studliId === member.studliId
    )
    const newCheckedMembers = [...checkedMembers]
    if (indexOfCheckedId !== -1) {
      newCheckedMembers.splice(indexOfCheckedId, 1)
    } else {
      newCheckedMembers.push(member)
    }
    setCheckedMembers(newCheckedMembers)
  }

  const toggleAllMembers = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedMembers(studentsToShow.filter(s => s.hasProduct))

      return
    }
    setCheckedMembers([])
  }
  const handleChangeClassPassword = () => {
    setChangeClassPasswordDialog(true)
  }

  const onChangeStartGoal = (studliIds: number[], goalRef: string) => {
    dispatch(setStartGoal({ studliIds, goalRef }))
    setCheckedMembers([])
    setChangeStartGoalDialog(null)
  }

  const handlePrintClick = () => {
    const printMembers =
      checkedMembers && checkedMembers.length
        ? studentsToShow.filter(s =>
            checkedMembers.some(c => c.studliId === s.studliId)
          )
        : studentsToShow
    const doc = PDFGenerator.generateJSPDF(printMembers)
    const documentName = "Inloggningsuppgifter"

    doc.setProperties({
      title: documentName
    })
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      doc.output("save", documentName + ".pdf")
    } else {
      // Open in new tab OR window. It's up to the Browser's settings, we can't control it. NB! can be blocked by add-block.
      const pdfWindow = window.open(doc.output("bloburl"))

      window.setTimeout(() => {
        if (
          !(pdfWindow && pdfWindow.location && pdfWindow.location.href) ||
          (pdfWindow && pdfWindow.closed)
        ) {
          doc.save(documentName)
        }
      }, 700)
    }
  }

  const toolbarActions = [
    <Button
      key="goals"
      onClick={() =>
        setChangeStartGoalDialog({
          members: checkedMembers.filter(m => m.hasProduct),
          currentGoal: getCommonStartgoal(checkedMembers),
          total: studentsToShow.length
        })
      }
      sx={{ color: "white" }}
      variant="text"
    >
      Ändra startmål
    </Button>
  ]

  const unselectedToolbarActions = [
    <Button
      key="changePasswords"
      onClick={handleChangeClassPassword}
      variant="text"
    >
      Ändra lösenord
    </Button>
  ]

  const getUnselectedMenuItems = () => {
    const showChangePassword = students.some(s => s.isPasswordChangeable)

    return showChangePassword
      ? unselectedToolbarActions
      : unselectedToolbarActions.filter(
          action => action.key !== "changePasswords"
        )
  }

  const getSelectedMenuItems = () => {
    const showChangePassword = students.some(s => s.isPasswordChangeable)
    const actions =
      classroomType === "adapt"
        ? toolbarActions
        : toolbarActions.filter(t => t.key !== "goals")

    return showChangePassword
      ? actions
      : actions.filter(action => action.key !== "changePasswords")
  }

  const renderOnboarding = () => (
    <>
      <Container maxWidth="lg"></Container>
      <OnBoarding {...renderCardContent()} />
    </>
  )

  const goToStudentCard = (memberId: number) =>
    gotoRoute(routerActions.ROUTE_ELEVKORT, { classroomId, memberId })

  const hasFormativeTests = useMemo(
    () => classroomFeatures.some(feature => feature === "FORMATIVE_TESTS"),
    [classroomFeatures]
  )

  const redirectToGroups = () => {
    window.open(
      `${process.env.REACT_APP_BOOKSHELF_URL}/grupper`,
      "_blank",
      "noopener noreferrer"
    )
  }

  const renderMobileOrDesktop = () => (
    <React.Fragment>
      <Stack alignItems="flex-start">
        <Button
          onClick={redirectToGroups}
          component="a"
          variant="outlined"
          sx={{ width: "15rem" }}
        >
          Gå till grupper
        </Button>
      </Stack>
      <Stack maxWidth="lg">{renderTitleAndDescription()}</Stack>

      <Grid item>
        <MobileOrDesktop breakAt="lg">
          {{
            desktop: (
              <MembersTable
                members={studentsToShow}
                tableHeader={getTableProps()}
                onCheckboxClick={onCheckboxClick}
                checkedMembers={checkedMembers}
                toggleAllMembers={toggleAllMembers}
                activeProduct={activeProduct}
                recentlyAddedMembers={recentlyAddedMembers}
                toolbarActions={getSelectedMenuItems()}
                unselectedToolbarActions={getUnselectedMenuItems()}
                goToStudentCard={goToStudentCard}
                hasExercises={exerciseLength > 0}
                hasFormativeTests={hasFormativeTests}
              />
            ),
            mobile: (
              <MembersList
                members={studentsToShow}
                sortByList={getTableProps()}
                onCheckboxClick={onCheckboxClick}
                checkedMembers={checkedMembers}
                toggleAllMembers={toggleAllMembers}
                activeProduct={activeProduct}
                recentlyAddedMembers={recentlyAddedMembers}
                toolbarActions={getSelectedMenuItems()}
                unselectedToolbarActions={getUnselectedMenuItems()}
                goToStudentCard={goToStudentCard}
                hasFormativeTests={hasFormativeTests}
              />
            )
          }}
        </MobileOrDesktop>
      </Grid>
    </React.Fragment>
  )

  return (
    <FullWidthContainer useMargin>
      <Dialog
        TransitionProps={{ role: "dialog" } as TransitionProps}
        onClose={() => setMemberToRemove(null)}
        aria-labelledby="ta-bort-användare-modal"
        open={memberToRemove !== null}
      >
        <RemoveMemberDialog
          closeMethod={() => setMemberToRemove(null)}
          removeMethod={removeMemberIfMarkedForRemoval}
          name={getPendingMemberName(memberToRemove)}
        ></RemoveMemberDialog>
      </Dialog>
      <CustomDialog
        TransitionProps={{ role: "dialog" } as TransitionProps}
        onClose={() => setClassroomCodeDialogOpen(false)}
        aria-labelledby="informationstext-modal"
        open={classroomCodeDialogOpen}
        maxWidth="sm"
      >
        <ClassroomCodeDialog
          onClose={() => setClassroomCodeDialogOpen(false)}
          classroomCode={classroomCode}
          classroomName={classroomName}
        />
      </CustomDialog>
      <Dialog
        TransitionProps={{ role: "dialog" } as TransitionProps}
        fullWidth={true}
        aria-labelledby="byt-lösenord-modal"
        onClose={() => setMemberToChangePasswordFor(null)}
        open={memberToChangePasswordFor !== null}
      >
        <ChangeMemberPasswordDialog
          closeMethod={() => setMemberToChangePasswordFor(null)}
          changeMethod={changeMemberPasswordIfMemberToChangePasswordFor}
          name={getPendingMemberName(memberToChangePasswordFor)}
        ></ChangeMemberPasswordDialog>
      </Dialog>
      <Dialog
        TransitionProps={{ role: "dialog" } as TransitionProps}
        fullWidth
        maxWidth="md"
        aria-labelledby="byt-startmål-modal"
        onClose={() => setChangeStartGoalDialog(null)}
        open={Boolean(changeStartGoalDialog !== null)}
      >
        {changeStartGoalDialog && (
          <SetStartGoalDialog
            currentGoal={changeStartGoalDialog.currentGoal}
            onClose={() => setChangeStartGoalDialog(null)}
            total={changeStartGoalDialog.total}
            onSubmit={onChangeStartGoal}
            members={changeStartGoalDialog.members}
          />
        )}
      </Dialog>
      <Dialog
        TransitionProps={{ role: "dialog" } as TransitionProps}
        fullWidth={true}
        aria-labelledby="byt-lösenord-för-flera-modal"
        onClose={() => setChangeClassPasswordDialog(false)}
        open={changeClassPasswordDialog}
      >
        <ChangeClassPasswordDialog
          onClose={() => setChangeClassPasswordDialog(false)}
        />
      </Dialog>

      {students.length === 0 ? renderOnboarding() : renderMobileOrDesktop()}
    </FullWidthContainer>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => {
  return {
    activeProduct: selectClassroomActiveProductInfo(state),
    students: selectStudentsForStudentList(state),
    classroomId: routerSelectors.selectCurrentClassroomId(state),
    goalsProgressLength: selectGoalsProgressLength(state),
    goalsLength: selectGoalsLength(state),
    classroomCode: selectClassroomCode(state),
    classroomName: selectClassroomName(state),
    exerciseLength: selectExercisesLength(state),
    classroomFeatures: selectClassroomFeatures(state)
  }
}

/**
 * Map Dispatch To Props.
 */
const mapDispatchToProps: DispatchProps = {
  removeMember: membersActions.removeMember,
  changePassword: membersActions.changeMemberPassword,
  gotoRoute: routerActions.gotoRoute,
  setRecentlyAddedMembers: membersActions.setRecentlyAddedMembers
}

const ConnectedMembers = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(Members)

export default ConnectedMembers
