import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  styled,
  Typography
} from "@mui/material"
import React, { useMemo } from "react"
import { AssignmentTask } from "../../modules/assignments/types"
import { GoalWithProgress } from "../../modules/goals/types"
import AbilityIndicator from "../AbilityIndicator"
import { TABLE_EXERCISES } from "../ChooseGoalsDialog/useChooseGoals"
import MoreMenu from "../PopupMenu"
import SearchHighlight from "../SearchHighlight/SearchHighlight"
import { getAverageGrade } from "./helpers"

const GradeLabel = styled("div")`
  color: white;
  background-color: #606060;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: 0.4rem;
`

const ExerciseLabel = styled("div")`
  color: white;
  background-color: #72a06a;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: 0.4rem;
`

type Props = {
  goals: GoalWithProgress[]
  selectedGoals: GoalWithProgress[]
  onToggle: (goal: GoalWithProgress) => void
  inCreateList: boolean
  tasks?: AssignmentTask[]
  showRemove: boolean
  onRemove: (ref: string) => void
  members?: number[]
  includeLabels?: boolean
  search?: string
}
const GoalAssignmentList = ({
  goals,
  selectedGoals,
  onToggle,
  inCreateList,
  tasks,
  showRemove,
  onRemove,
  members,
  includeLabels,
  search = ""
}: Props) => {
  const convertedGoalsFromTasks = useMemo(() => {
    if (goals.length && inCreateList && tasks) {
      const converted = tasks.reduce((acc, curr) => {
        const goal = goals.find(g => g.goalRef === curr.link)
        return goal ? [...acc, goal] : acc
      }, [] as GoalWithProgress[])
      return converted
    }
    return []
  }, [inCreateList, goals, tasks])

  const getGradeLabel = (grade: number) => {
    return <GradeLabel>Årskurs {grade}</GradeLabel>
  }

  const getTableExerciseLabel = (table?: string) => {
    const exerciseLabel = TABLE_EXERCISES.find(t => t.value === table)?.label
    return <ExerciseLabel>{exerciseLabel}</ExerciseLabel>
  }
  return (
    <List>
      {(inCreateList ? convertedGoalsFromTasks : goals).map((g, i) => (
        <ListItem divider key={g.goalRef}>
          <ListItemIcon>
            {!inCreateList && (
              <Checkbox
                onClick={() => onToggle(g)}
                checked={selectedGoals.some(
                  selected => selected.goalRef === g.goalRef
                )}
              />
            )}
            <AbilityIndicator
              grade={getAverageGrade(
                members
                  ? g.progress.filter(p => members.some(m => m === p.studliId))
                  : g.progress
              )}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Stack direction="row" alignItems="center">
                <SearchHighlight
                  disabled={!search}
                  TextComponent={({ children }) => (
                    <Typography sx={{ whiteSpace: "pre-wrap" }} display="flex">
                      {children}
                    </Typography>
                  )}
                  HighLightComponent={({ children }) => (
                    <Typography
                      sx={{ whiteSpace: "pre-wrap" }}
                      fontWeight={600}
                      component="span"
                    >
                      {children}
                    </Typography>
                  )}
                  search={search}
                  value={` ${g.order}. ${g.title}`}
                />
                {includeLabels && (
                  <Stack direction="row" ml={2} spacing={2} flexWrap="wrap">
                    {getGradeLabel(g.grade)}{" "}
                    {g.isTable && getTableExerciseLabel(g.tableCategory)}
                  </Stack>
                )}
              </Stack>
            }
          />

          {showRemove && (
            <ListItemSecondaryAction>
              <MoreMenu
                menuItems={[
                  {
                    action: () => onRemove(g.goalRef),
                    option: "Ta bort"
                  }
                ]}
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default GoalAssignmentList
