import { styled } from "@mui/material"

export const StyledWorkedLatestContainer = styled("span")`
  display: block;
  margin-top: 0.25em;
`
export const StyledWorked = styled("span")`
  margin-right: 1.5em;
  display: inline-block;
`
export const StyledLatest = styled("span")`
  display: inline-block;
`

export const StyledLabel = styled("span")`
  font-weight: bold;
`
