import * as types from "./types"

const prepareForDownload = (
  assignments: types.AssignmentsWithStatusAndProgression
) => {
  const result = []
  for (let a of assignments) {
    const tasks = []
    for (let t of a.assignmentTasks) {
      const task = {
        id: t.id,
        link: t.link,
        type: t.type,
        title: t.title,
        answer: t.answer,
        question: t.question,
        updatedAt: t.updatedAt,
        instruction: t.instruction
      }
      tasks.push(task)
    }
    const assignment = {
      title: a.title,
      instruction: a.instruction,
      repetitionAmount: a.repetitionAmount,
      threshold: a.threshold,
      assignmentTasks: tasks
    }
    result.push(assignment)
  }
  return result
}

export const DownloadAssignments = (
  filename: string,
  ...assignments: types.AssignmentsWithStatusAndProgression
) => {
  const preparedAssignments = prepareForDownload(assignments)
  const link = document.createElement("a")
  const jsondata = JSON.stringify(preparedAssignments, null, 2)
  const file = new Blob([jsondata], { type: "application/json" })
  link.href = URL.createObjectURL(file)
  link.download = filename + ".json"
  link.click()
  URL.revokeObjectURL(link.href)
}
