import { Members } from "../members/types"
import { Assignment, AssignmentParticipant, Assignments } from "./types"

export const updateAssignmentParticipants = (
  assignments: Assignments,
  newMembers: Members
): Assignments => {
  return assignments.map((assignment: Assignment) => {
    if (assignment.participants) {
      return {
        ...assignment,
        participantsInfo: assignment.participantsInfo.map(
          (participant: AssignmentParticipant) => {
            const found = newMembers.find(
              memberAdded => memberAdded.studliId === participant.studliId
            )
            if (found && found.groups) {
              return {
                ...participant,
                groups: found.groups
              }
            }
            return participant
          }
        )
      }
    }
    return assignment
  })
}

export const removeMembersFromAssignments = (
  assignments: Assignments,
  memberIds: number[]
): Assignments => {
  return assignments.map((assignment: Assignment) => {
    if (assignment.participants) {
      return {
        ...assignment,
        participantsInfo: assignment.participantsInfo.filter(
          (participant: AssignmentParticipant) =>
            !memberIds.includes(participant.studliId)
        ),
        participants: assignment.participants.filter(
          (participant: number) => !memberIds.includes(participant)
        )
      }
    }
    return assignment
  })
}
