import {
  FormativeTest,
  ParsedFormativeProgressionAnswers
} from "../../modules/formative/types"

export type ProgressForBar = {
  correct: number
  wrong: number
  notAnswered: number
  rating: number
}

export const getProgressForBar = (
  sessionId: number,
  test: FormativeTest,
  answers: ParsedFormativeProgressionAnswers
) => {
  const progress: ProgressForBar = {
    correct: 0,
    wrong: 0,
    notAnswered: 0,
    rating: 0
  }
  if (answers && test) {
    const filteredAnswers = answers.filter(
      answer => answer.sessionId === sessionId
    )
    filteredAnswers.forEach(answer => {
      const foundQuestion = test.questions.find(
        question => question.id === answer.questionId
      )

      if (!foundQuestion) {
        progress.rating += 1
        return
      }
      if (answer.isCorrect && foundQuestion.module === "multiple") {
        progress.correct += 1
        return
      }

      if (!answer.isCorrect && foundQuestion.module === "multiple") {
        progress.wrong += 1
        return
      }
      if (foundQuestion.module === "rating") {
        progress.rating += 1
      }
    })
    let testLength = test.questions.length
    if (test && test.endQuestionId) testLength += 1
    progress.notAnswered = testLength - filteredAnswers.length
  }
  return progress
}
