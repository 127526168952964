import {
  Button,
  Collapse,
  DialogActions,
  DialogTitle,
  Divider,
  List
} from "@mui/material"
import React from "react"
import { PartialFormativeTest } from "../../modules/formative/types"
import { ListItem, SubListItem } from "../AssignmentImportDialog/ListItem"
import { StyledDialogContent } from "../Dialog/StyledDialog"
import {
  NotFoundItem,
  StyledDialogContentText
} from "./StyledFormativeTestDialogContent"
import useImportFormativeTests from "./useImportFormativeTests"
type Props = {
  onClose: () => void
  type: "prepared" | "import"
}

export interface Folder {
  id: number
  title: string
  tests: PartialFormativeTest[]
}

const FormativeTestImportDialogContent: React.FC<Props> = ({
  onClose,
  type
}) => {
  const [openFolders, toggleOpenFolders] = React.useState<number[]>([])
  const notFoundText = "Kan inte hitta några test"
  const [folders, importFormativeTests] = useImportFormativeTests(type)

  React.useEffect(() => {
    if (folders.length === 1 && openFolders.length === 0) {
      const [{ id }] = folders
      toggleOpenFolders([id])
    }
  }, [folders, toggleOpenFolders, openFolders])

  const _onSelect = (test: PartialFormativeTest) => importFormativeTests(test)

  const toggleFolder = (id: number) => {
    if (folders.length === 1) {
      // disable the toggle when only one folder exist
      return
    }

    if (openFolders.indexOf(id) > -1) {
      toggleOpenFolders(openFolders.filter(i => i !== id))

      return
    }

    toggleOpenFolders([...openFolders, id])
  }

  const renderListItems = (
    { id, title: folderTitle, tests }: Folder,
    i: number,
    arr: Folder[]
  ) => {
    const isOpen = openFolders.indexOf(id) > -1

    return (
      <React.Fragment key={id}>
        <ListItem
          title={folderTitle}
          items={tests.length}
          open={isOpen}
          onClick={() => toggleFolder(id)}
        />
        {(arr.length > 1 || isOpen) && <Divider light />}
        <Collapse in={isOpen}>
          {tests.map(test => {
            return (
              <React.Fragment key={test.id}>
                <SubListItem
                  title={test.title}
                  onClick={() => _onSelect(test)}
                />
                <Divider light />
              </React.Fragment>
            )
          })}
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <>
      <DialogTitle id={"import-test-classroom"}>
        {type === "prepared"
          ? `Förberedda tester ${
              folders.length > 0 &&
              `(${folders.reduce((acc: number, curr) => {
                return acc + curr.tests.length
              }, 0)})`
            }`
          : "Importera test"}
      </DialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>
          {type === "prepared"
            ? "Välj i listan nedan för att skapa ett test med förberett innehåll."
            : "Välj ett klassrum och test nedan för att skapa ett test i det här klassrummet med samma innehåll."}
        </StyledDialogContentText>
        <List component="div">
          {folders.map(renderListItems)}
          {folders.length === 0 && <NotFoundItem>{notFoundText}</NotFoundItem>}
        </List>
      </StyledDialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Avbryt
        </Button>
      </DialogActions>
    </>
  )
}

export default FormativeTestImportDialogContent
