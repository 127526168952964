import * as React from "react"
import MUITableRow, { TableRowProps } from "@mui/material/TableRow"

const blacklist = ["INPUT", "A", "BUTTON"]

const TableRow: React.FC<TableRowProps> = ({ onClick, ...rest }) => {
  const ref = React.useRef<HTMLTableRowElement>()

  const click = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if ((e.target as unknown as Element).parentElement !== ref.current) {
      return
    }

    if (blacklist.indexOf((e.target as unknown as Element).tagName) > -1) {
      return
    }

    onClick && onClick(e)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (e.keyCode !== 32 && e.keyCode !== 13) {
      return
    }

    onClick &&
      onClick(e as unknown as React.MouseEvent<HTMLTableRowElement, MouseEvent>)
  }

  return (
    <MUITableRow
      {...rest}
      ref={ref as React.RefObject<HTMLTableRowElement>}
      onClick={click}
      onKeyDown={onKeyDown}
      tabIndex={0}
    />
  )
}

export default TableRow
