import { pipe } from "fp-ts/lib/pipeable"
import { Lens } from "monocle-ts"

import { createReducer } from "../../shared/tools/redux"
import { filter, push } from "../../shared/tools/pipeables"
import * as actions from "./actions"
import {
  State,
  ReceiveProgressAction,
  AssignmentProgress,
  AssignmentTaskProgress,
  AssignmentsGoalProgress,
  AssignmentGoalProgress
} from "./types"
import { flow } from "fp-ts/lib/function"
import { fold, right, left } from "fp-ts/lib/Either"
import { map } from "fp-ts/lib/Array"

const initialState: State = {
  assignmentsProgress: [],
  assignmentsTaskProgress: [],
  assignmentsGoalProgress: []
}

const setProgress =
  (payload: ReceiveProgressAction["payload"]) => (state: State) => {
    const setAssignmentProgress = Lens.fromProp<State>()(
      "assignmentsProgress"
    ).modify(_ => payload.assignmentProgression)
    const setAssignmentTaskProgress = Lens.fromProp<State>()(
      "assignmentsTaskProgress"
    ).modify(_ => payload.assignmentTaskProgression)
    const setAssignmentGoalProgress = Lens.fromProp<State>()(
      "assignmentsGoalProgress"
    ).modify(_ => payload.assignmentGoalProgression)
    return pipe(
      state,
      setAssignmentProgress,
      setAssignmentTaskProgress,
      setAssignmentGoalProgress
    )
  }

const resetProgress = () => (state: State) => {
  const setAssignmentProgress = Lens.fromProp<State>()(
    "assignmentsProgress"
  ).modify(_ => initialState.assignmentsProgress)
  const setAssignmentTaskProgress = Lens.fromProp<State>()(
    "assignmentsTaskProgress"
  ).modify(_ => initialState.assignmentsTaskProgress)
  const setAssignmentGoalProgress = Lens.fromProp<State>()(
    "assignmentsGoalProgress"
  ).modify(_ => initialState.assignmentsGoalProgress)
  return pipe(
    state,
    setAssignmentProgress,
    setAssignmentTaskProgress,
    setAssignmentGoalProgress
  )
}

const filterTaskProgression =
  ({ studliId, assignmentId }: { studliId: number; assignmentId: number }) =>
  (state: State) =>
    Lens.fromProp<State>()("assignmentsTaskProgress").modify(
      filter(t => t.studliId !== studliId || t.assignmentId !== assignmentId)
    )(state)

const assignmentNotConnectedToIncoming =
  (inc: AssignmentProgress) => (old: AssignmentProgress) => {
    if (old.id === 0) {
      return (
        old.assignmentId !== inc.assignmentId || old.studliId !== inc.studliId
      )
    }

    return old.id !== inc.id
  }

const updateAssignmentProgress =
  (payload: { assignmentProgression: AssignmentProgress }) =>
  (state: State) => {
    return Lens.fromProp<State>()("assignmentsProgress").modify(
      flow(
        filter(assignmentNotConnectedToIncoming(payload.assignmentProgression)),
        push(payload.assignmentProgression)
      )
    )(state)
  }

const updateAssignmentGoalProgress =
  (payload: { assignmentGoalProgression: AssignmentsGoalProgress }) =>
  (state: State) => {
    return Lens.fromProp<State>()("assignmentsGoalProgress").modify(a => {
      const removedDuplicates = a.filter(
        old =>
          !hasAssignmentGoalProgress(old, payload.assignmentGoalProgression)
      )
      return [...removedDuplicates, ...payload.assignmentGoalProgression]
    })(state)
  }

const hasAssignmentGoalProgress = (
  old: AssignmentGoalProgress,
  inc: AssignmentsGoalProgress
) =>
  inc.some(
    newProgress =>
      old.assignmentId === newProgress.assignmentId &&
      old.studliId === newProgress.studliId &&
      old.goalRef === newProgress.goalRef &&
      old.difficulty === newProgress.difficulty
  )

const hasAssignmentTaskProgress =
  (old: AssignmentTaskProgress) => (inc: AssignmentTaskProgress) =>
    old.assignmentTaskId === inc.assignmentTaskId &&
    old.studliId === inc.studliId

const updateAssignmentTaskProgress =
  (payload: { assignmentTaskProgression: AssignmentTaskProgress }) =>
  (state: State) => {
    return Lens.fromProp<State>()("assignmentsTaskProgress").modify(
      flow(
        a =>
          a.some(hasAssignmentTaskProgress(payload.assignmentTaskProgression))
            ? right(a)
            : left(a),
        fold(
          push(payload.assignmentTaskProgression),
          map(a => {
            if (
              hasAssignmentTaskProgress(a)(payload.assignmentTaskProgression)
            ) {
              return {
                ...a,
                ...payload.assignmentTaskProgression
              }
            }

            return a
          })
        )
      )
    )(state)
  }

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.setAssignmentProgress, setProgress)
    .case(actions.resetAssignmentsProgress, resetProgress)
    .case(actions.clearAssignmentsTaskForAssignment, filterTaskProgression)
    .case(actions.updateAssignmentProgress, updateAssignmentProgress)
    .case(actions.updateAssignmentProgressTask, updateAssignmentTaskProgress)
    .case(actions.updateAssignmentGoalProgress, updateAssignmentGoalProgress)
)

export default reducer
