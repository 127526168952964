import { styled } from "@mui/material"
import { PROGRESS_SEGMENT } from "./constants"
import { Widths } from "./types"

type StyledProgressBarTypes = {
  widths: Widths
  segment: PROGRESS_SEGMENT
}

type StyledProgressTypes = {
  clickable?: boolean
}
export const StyledProgress = styled("span")<StyledProgressTypes>`
  width: 200px;
  height: 25px;
  display: flex;
  overflow: hidden;
  cursor: ${props => (props.clickable ? "pointer" : "default")};
`

export const StyledProgressBar = styled("div")<StyledProgressBarTypes>`
  width: ${props => props.widths[props.segment]}%;
  background-color: ${props =>
    props.theme.studli.progressBar.segmentColor[props.segment]};
  height: 23px;
  color: ${props =>
    props.theme.studli.progressBar.segmentTextColor[props.segment]};
  text-align: center;
  line-height: 23px;
  font-size: 13px;
  font-weight: bold;
`

export const SmallProgressBar = styled("div")<{ segment: PROGRESS_SEGMENT }>`
  background-color: ${props =>
    props.theme.studli.progressBar.segmentColor[props.segment]};
  min-height: 22px;
  color: ${props =>
    props.theme.studli.progressBar.segmentTextColor[props.segment]};
  text-align: center;
  line-height: 23px;
  font-size: 13px;
  font-weight: bold;
  padding: 0 0.25rem;
  border-radius: 3px;
  display: inline-block;
`
