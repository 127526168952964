import React from "react"
import { connect } from "react-redux"
import * as routerActions from "../../modules/router/actions"
import { RootState } from "../../modules/store"
import { selectActiveProductTitle } from "../../modules/products/selectors"
import AuthUser from "../AuthUser"
import Notifications from "../../components/Notifications"
import { Notification } from "../../modules/notifications/types"
import { selectAllNotifications } from "../../modules/notifications/selectors"
import { selectTeacherLoggedIn } from "../../modules/members/selectors"
import { Member } from "../../modules/members/types"
import { selectCurrentClassroomId } from "../../modules/router/selectors"
import * as notificationsActions from "../../modules/notifications/actions"
import { changeActiveProduct } from "../../modules/products/actions"
import HeaderHelp from "../../components/HeaderHelp"
import { Stack, useTheme } from "@mui/material"

type DispatchProps = {
  goToRoute: (type: string, payload: object) => void
  markNotificationAsRead: typeof notificationsActions.markNotificationAsRead
  changeActiveProduct: (productId: number) => void
}

type StateProps = {
  title: string
  teacherLoggedIn: Member | null
  classroomId: number
  notifications: Notification[]
}

type Props = DispatchProps & StateProps

const TopBar = ({
  title,
  teacherLoggedIn,
  goToRoute,
  classroomId,
  notifications,
  markNotificationAsRead,
  changeActiveProduct
}: Props) => {
  const theme = useTheme()
  return (
    <Stack
      width="100%"
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      color={theme.studli.classroom.themeTextColor}
    >
      <Notifications
        notifications={notifications}
        teacherLoggedIn={teacherLoggedIn}
        goToRoute={goToRoute}
        classroomId={classroomId}
        markNotificationAsRead={(memberId: number, notificationIds: number[]) =>
          markNotificationAsRead({ memberId, notificationIds })
        }
        changeActiveProduct={changeActiveProduct}
      />
      <HeaderHelp goToRoute={goToRoute} classroomId={classroomId} />
      <AuthUser color={theme.studli.classroom.themeTextColor} />
    </Stack>
  )
}

const mapDispatchToProps = {
  goToRoute: routerActions.gotoRoute,
  markNotificationAsRead: notificationsActions.markNotificationAsRead,
  changeActiveProduct: changeActiveProduct
}

const mapStateToProps = (state: RootState): StateProps => ({
  title: selectActiveProductTitle(state),
  teacherLoggedIn: selectTeacherLoggedIn(state),
  classroomId: selectCurrentClassroomId(state),
  notifications: selectAllNotifications(state)
})

const ConnectedTopBar = connect(mapStateToProps, mapDispatchToProps)(TopBar)

export default ConnectedTopBar
