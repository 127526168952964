import React, { useState } from "react"
import { Stickers, Sticker } from "../../modules/classroom/types"
import Button from "../Button"
import {
  StyledStickerWrapper,
  StyledCurrentSticker,
  StyledSticker
} from "./StyledChooseStickerDialogContent"
import { DialogTitle, DialogContent, DialogActions } from "@mui/material"

type OwnProps = {
  stickers: Stickers
  currentSticker: Sticker
  handleChange: (id: Sticker["id"]) => void
  handleCancel: () => void
}

const ChooseStickerDialogContent = ({
  stickers,
  currentSticker,
  handleChange,
  handleCancel
}: OwnProps) => {
  const [currentChosenSticker, setCurrentChosenSticker] =
    useState(currentSticker)

  return (
    <React.Fragment>
      <DialogTitle id="choose-sticker-dialog-title">Välj bild</DialogTitle>
      <DialogContent id="choose-sticker-dialog-description">
        <StyledStickerWrapper>
          {stickers.map((sticker: Sticker) => (
            <StyledSticker
              key={sticker.id}
              color={sticker.color}
              onClick={() => setCurrentChosenSticker(sticker)}
              elevation={currentChosenSticker.id === sticker.id ? 3 : 1}
              selected={currentChosenSticker.id === sticker.id ? 1 : 0}
            >
              <StyledCurrentSticker alt={sticker.name} src={sticker.path} />
            </StyledSticker>
          ))}
        </StyledStickerWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleCancel()}
        >
          Avbryt
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleChange(currentChosenSticker.id)}
          color="primary"
        >
          Välj
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default ChooseStickerDialogContent
