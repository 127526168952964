import { styled } from "@mui/material"

type StyledButtonProps = {
  active: boolean
}

type CustomEditorProps = {
  margintop: boolean
}

export const StyledMaxLength = styled("span")`
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin-left: 14px;
`

export const StyledButton = styled("span")<StyledButtonProps>`
  color: ${props => (props.active ? "#5890ff" : "#999")};
  margin: 0 0.25rem;
  padding: 0;

  &:first-child {
    margin: 0 0.25rem 0 0;
  }

  &:last-child {
    margin: 0 0 0 0.25rem;
  }

  & > svg {
    width: 24px;
  }
`

export const StyledEditorContainer = styled("div")<{
  hidePlaceholder: boolean
}>`
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 1rem;

  & .public-DraftEditor-content {
    min-height: 100px;
    padding: 1rem;
  }

  & .public-DraftEditorPlaceholder-root {
    padding: 1rem;
  }

  ${props =>
    props.hidePlaceholder &&
    `
    & .public-DraftEditorPlaceholder-root {
      display: none;
    }
  `}
`

export const StyledEditor = styled("div")<CustomEditorProps>`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1rem 0;
  ${props => props.margintop && "margin-top: 1em;"}
  & .public-DraftEditorPlaceholder-root {
    ${props => props.theme.breakpoints.up("sm")} {
      font-size: 1rem;
    }
    font-size: 14px;
  }
  & .RichEditor-controls {
    display: flex;
    flex: 1;
    flex-flow: wrap;
    padding: 0 0.75rem 1rem 0.75rem;
  }
`
