/**
 * These select* functions are  for redux select(), they expose parts of the state.
 */

import { createSelector } from "reselect"
import { RootState } from "../store"
import { ApplicationState, Banners } from "./types"
export const selectApplicationState = (store: RootState): ApplicationState =>
  store.application.applicationState

export const selectIsApplicationReady = (store: RootState): boolean =>
  store.application.applicationState === "ready"

export const selectBanners = (store: RootState): Banners =>
  store.application.banners

export const selectLatestAction = createSelector(
  ({ application }: RootState) => application.latestAction,
  latestAction => latestAction
)
