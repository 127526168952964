import React from "react"
import { ReactComponent as NoAnswerIcon } from "../../assets/images/blank.svg"
import { ReactComponent as WrongAnswerIcon } from "../../assets/images/incorrect-answer.svg"
import { ReactComponent as CorrectAnswerIcon } from "../../assets/images/correct-answer.svg"

import { styled } from "@mui/material"
import { AnswerTypes } from "./types"

type Props = {
  answerType: AnswerTypes
  opacity?: boolean
  endTime?: string
}

const FormativeQuestionAnswerTag = ({ answerType, opacity }: Props) => {
  const renderAnswer = () => {
    switch (answerType) {
      case "correct":
        return <StyledCorrectAnswer opacity={opacity && 0.6} />
      case "notAnswered":
        return <StyledNotAnswered opacity={opacity && 0.6} />
      case "rating":
        return <StyledRatingAnswer opacity={opacity && 0.6} />
      case "wrong":
        return <StyledWrongAnswer opacity={opacity && 0.6} />
    }
  }

  return renderAnswer()
}

export default FormativeQuestionAnswerTag

export const StyledCorrectAnswer = styled(CorrectAnswerIcon as any)`
  color: #038763;
  margin-right: 0.5em;
`

export const StyledRatingAnswer = styled(NoAnswerIcon as any)`
  color: #006cc8;
  margin-right: 0.5em;
`

export const StyledWrongAnswer = styled(WrongAnswerIcon as any)`
  margin-right: 0.5em;
`

export const StyledNotAnswered = styled(NoAnswerIcon as any)`
  margin-right: 0.5em;
  color: #dddddd;
`
