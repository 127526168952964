import React from "react"
import AppBar, { AppBarProps } from "@mui/material/AppBar"
import { Theme } from "@mui/material/styles"
import { Drawer, DrawerProps, styled } from "@mui/material"

import { sideBarWidth } from "./store/constants"
import * as appShellTypes from "./store/types"

export const StyledContainer = styled("div")`
  height: 100vh;
  width: 100%;
`

const barContainerTransitionToWide = (
  theme: Theme,
  attribute: appShellTypes.TransitionAttribute
) => {
  return `${theme.transitions.duration.leavingScreen}ms ${attribute} ${theme.transitions.easing.sharp};`
}
const barContainerTransitionToNarrow = (
  theme: Theme,
  attribute: appShellTypes.TransitionAttribute
) => {
  return `${theme.transitions.duration.enteringScreen}ms ${attribute} ${theme.transitions.easing.easeOut};`
}

export const StyledTopBarContainer = styled(
  AppBar as React.FC<AppBarProps & appShellTypes.StyleProps>
)`
  height: 64px;
  background-color: ${props => props.theme.studli.classroom.themeColor};
  color: ${props => props.theme.studli.classroom.themeTextColor};
  width: ${props =>
    props.is_side_bar_open ? `calc(100% - ${sideBarWidth})` : "100%"};
  transition: ${props =>
    props.is_side_bar_open
      ? barContainerTransitionToNarrow(
          props.theme,
          appShellTypes.TransitionAttribute.width
        )
      : barContainerTransitionToWide(
          props.theme,
          appShellTypes.TransitionAttribute.width
        )};
`
export const StyledContentSection = styled("section")`
  margin-top: 64px;
  margin-bottom: 5em;
  margin-left: ${(props: appShellTypes.StyleProps) =>
    props.is_side_bar_open ? sideBarWidth : 0};
  transition: ${props =>
    props.is_side_bar_open
      ? barContainerTransitionToNarrow(
          props.theme,
          appShellTypes.TransitionAttribute.margin
        )
      : barContainerTransitionToWide(
          props.theme,
          appShellTypes.TransitionAttribute.margin
        )};
`

// eslint-disable-next-line
export const StyledSideBarContainer = styled("aside")`
  width: calc(100vw - 56px);
  overflow: auto;
  height: 100%;
  ${props => props.theme.breakpoints.up("sm")} {
    width: ${sideBarWidth};
  }
`

export const StyledDrawer = styled(Drawer as React.FC<DrawerProps>)`
  > div {
    overflow: hidden;
  }
`
