import { createAction } from "../../shared/tools/redux"
import { Products } from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST",
  CHANGE_ACTIVE_PRODUCT_REQUEST = "CHANGE_ACTIVE_PRODUCT_REQUEST"
}
/**
 * Redux-actions, originating from server. As result from a client-request.
 */
export enum RESPONSE {
  GET_ALL_PRODUCTS_RESPONSE = "GET_ALL_PRODUCTS_RESPONSE",
  CHANGE_ACTIVE_PRODUCT_RESPONSE = "CHANGE_ACTIVE_PRODUCT_RESPONSE"
}

/**
 * Strings for "action" property when communicating with server.
 * Request and Response use the same string.
 * (The "type" property is this module's name).
 */
export enum SERVER_MESSAGE_ACTION {
  GET_ALL_PRODUCTS_REQUEST = "list",
  GET_ALL_PRODUCTS_RESPONSE = "list",
  CHANGE_ACTIVE_PRODUCT_REQUEST = "change",
  CHANGE_ACTIVE_PRODUCT_RESPONSE = "change"
}

export enum UI {
  PRODUCT_CHANGED = "PRODUCT_CHANGED",
  ALL_PRODUCT_RELATED_DATA_RESET = "ALL_PRODUCT_RELATED_DATA_RESET"
}

export const getAllProducts = createAction("GET_ALL_PRODUCTS_REQUEST")

export const changeActiveProduct = createAction<number>(
  "CHANGE_ACTIVE_PRODUCT_REQUEST"
)

export const productChanged = createAction("PRODUCT_CHANGED")

export const allProductRelatedDataReset = createAction(
  "ALL_PRODUCT_RELATED_DATA_RESET"
)

export const changeActiveProductResponse = createAction<number>(
  "CHANGE_ACTIVE_PRODUCT_RESPONSE"
)
export const addProducts = createAction<Products>("GET_ALL_PRODUCTS_RESPONSE")
