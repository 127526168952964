import { styled } from "@mui/material"
import List from "@mui/material/List"

export const StyledGoalsList = styled("span")`
  white-space: nowrap;
`

export const StyledList = styled(List as React.FC)`
  width: 100%;
`
