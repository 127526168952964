import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Menu, MenuItem } from "@mui/material"
import { StyledButton } from "./StyledFormativeQuestions"
import { selectClassroomId } from "../../modules/classroom/selectors"
import { gotoRoute } from "../../modules/router/actions"
import * as routerActions from "../../modules/router/actions"

type Props = {
  showImportTest: boolean
  showPreparedTest: boolean
  onBoarding: boolean
  openPreparedTestDialog: () => void
  openImportTestDialog: () => void
}
const AddTestButton = ({
  showImportTest,
  showPreparedTest,
  onBoarding,
  openImportTestDialog,
  openPreparedTestDialog
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dispatch = useDispatch()
  const classroomId = useSelector(selectClassroomId)

  const goToCreateFormativeQuestion = () => {
    dispatch(
      gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR_LAGGTILL, { classroomId })
    )
  }
  const renderCreateTestMenu = () => {
    return (
      <>
        <StyledButton
          marginleft={0}
          aria-controls="simple-menu"
          aria-haspopup="true"
          variant={onBoarding ? "contained" : "outlined"}
          color="primary"
          sx={{ width: "15rem"}}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget)
          }
        >
          lägg till test
        </StyledButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={goToCreateFormativeQuestion}>
            Skapa nytt test
          </MenuItem>
          {showPreparedTest && (
            <MenuItem
              onClick={() => {
                openPreparedTestDialog()
                setAnchorEl(null)
              }}
            >
              Välj förberett test
            </MenuItem>
          )}
          {showImportTest && (
            <MenuItem
              onClick={() => {
                openImportTestDialog()
                setAnchorEl(null)
              }}
            >
              Importera test från ett klassrum
            </MenuItem>
          )}
        </Menu>
      </>
    )
  }
  return <>{renderCreateTestMenu()}</>
}

export default AddTestButton
