import React, { useEffect, useState } from "react"
import { MembersInGoalsList } from "../../pages/Goals/store/types"
import Avatar from "../Avatar"
import {
  StyledAvatarGroupContainer,
  StyledFullIndicator
} from "./StyledAvatarGroup"
import Tooltip from "@mui/material/Tooltip"
import { buildName } from "../../shared/tools/strings"
import { styled } from "@mui/material"

type OwnProps = {
  userMax: number
  userList: MembersInGoalsList
}

const StyledTooltip = styled((props: any) => (
  <Tooltip
    classes={{ popper: props.className, tooltip: "tooltip" }}
    {...props}
  />
))`
  & .tooltip {
    opacity: 0;
    color: white;
    font-size: 12px;
    max-width: 65ch;
  }
`

const AvatarGroup = ({ userList, userMax }: OwnProps) => {
  const [avatars, setAvatars] = useState<MembersInGoalsList>([])
  const [overflownAvatars, setOverflownAvatars] = useState<MembersInGoalsList>(
    []
  )

  useEffect(() => {
    if (userList.length === userMax) {
      setAvatars(userList)

      return
    }
    const avatars: MembersInGoalsList = []
    const overflownAvatars: MembersInGoalsList = []
    userList.forEach((user, index) => {
      index + 1 < userMax ? avatars.push(user) : overflownAvatars.push(user)
    })
    setAvatars(avatars)
    setOverflownAvatars(overflownAvatars)
    // eslint-disable-next-line
  }, [userList])

  const renderAvatars = () => {
    return avatars.map(user => (
      <Avatar
        key={user.id}
        firstName={user.firstName}
        lastName={user.lastName}
        picture={user.picture}
        studliId={user.studliId}
      ></Avatar>
    ))
  }

  const renderOverflownAvatars = () => {
    const usersOverflowing = overflownAvatars
      .map(user => `${buildName(user.firstName, user.lastName)}`)
      .join(", ")

    return (
      <StyledTooltip title={usersOverflowing}>
        <StyledFullIndicator>+ {overflownAvatars.length}</StyledFullIndicator>
      </StyledTooltip>
    )
  }

  return (
    <StyledAvatarGroupContainer>
      {avatars.length > 0 && renderAvatars()}
      {overflownAvatars.length > 0 && renderOverflownAvatars()}
    </StyledAvatarGroupContainer>
  )
}

export default AvatarGroup
