import { all, call, fork, put, select, takeLatest } from "redux-saga/effects"

import { toggleShouldReload } from "./actions"
import routeMap from "../../routes"

const DELAY_TIMER_MS = 12 * (3600 * 1000) // 12 hours

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

function* routeChecker() {
  const shouldReload: boolean = yield select(state => state.router.shouldReload)

  if (!shouldReload) {
    return
  }

  window.location.reload()
}

function* timer() {
  yield call(delay, DELAY_TIMER_MS)
  yield put(toggleShouldReload())
}

function* root() {
  yield fork(timer)
  yield all(Object.keys(routeMap).map(route => takeLatest(route, routeChecker)))
}

export default root
