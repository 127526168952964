import { styled } from "@mui/material"

export const StyledBannerContainer = styled("div")`
  width: 100%;
  margin-bottom: 20px;
`
export const StyledIconContainer = styled("span")`
  margin-left: 10px;
  opacity: 0.4;
  cursor: pointer;
`
