import React from "react"
import TitleAndDescription from "../../components/TitleAndDescription"

export const PreparedAssignments = () => {
  const renderTitleAndDescription = () => {
    return <TitleAndDescription title="Förberedda uppdrag" paragraphs={[]} />
  }

  return <React.Fragment>{renderTitleAndDescription()}</React.Fragment>
}

export default PreparedAssignments
