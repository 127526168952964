import React, { useState } from "react"
import {
  Popover,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@mui/material"
import { Notifications, Notification } from "../../modules/notifications/types"
import {
  StyledNotificationsIcon,
  StyledListItemText,
  StyledNotificationsList,
  StyledNotificationText,
  StyledListItemSecondaryText,
  StyledListItemUnreadDot,
  StyledNotificationsListHeader,
  StyledNotificationsHeaderText,
  StyledNoNotificationsText,
  StyledBadge,
  StyledIconButton
} from "./StyledNotifications"
import { ReactComponent as AssignmentsIcon } from "../../assets/images/menu-task.svg"
import { ReactComponent as NewsIcon } from "../../assets/images/sl-logo.svg"
import { ReactComponent as NotificationsIcon } from "../../assets/images/notification-default.svg"
import { ReactComponent as Warning } from "../../assets/images/warning.svg"

import moment from "moment"
import { Member } from "../../modules/members/types"
import MoreMenu from "../PopupMenu"
import Dialog from "../Dialog"
import Button from "../Button"
import HtmlRenderer from "../HtmlRenderer"
import { useSelector } from "react-redux"
import { selectActiveProductId } from "../../modules/products/selectors"
import { convertURIToRoute } from "../../routes"

type OwnProps = {
  notifications: Notifications
  teacherLoggedIn: Member | null
  goToRoute: (type: string, payload: object) => void
  classroomId: number
  markNotificationAsRead: (
    memberId: Member["studliId"],
    notificationIds: Notification["id"][]
  ) => void
  changeActiveProduct: (productId: number) => void
}

type Props = OwnProps

type NotificationDialogData = { html: string; title: string }

const relativeTimeForNotifications = {
  future: "om %s",
  past: "för %s sedan",
  s: "",
  ss: "%d sek",
  m: "1 min",
  mm: "%d minuter",
  h: "1 tim",
  hh: "%d timmar",
  d: "1 dag",
  dd: "%d dagar",
  M: "1 mån",
  MM: "%d mån",
  y: "1 år",
  yy: "%d år"
}

const NOTIFICATION_TYPES_ICON: any = {
  assignment_finished: <AssignmentsIcon aria-label="Uppdrag klart" />,
  news: <NewsIcon aria-label="Nyhet" />,
  assignment_task_replaced: <Warning aria-label="Varning" />,
  assignment_needs_modification: <Warning aria-label="Varning" />
}

const NotificationsList = ({
  notifications,
  teacherLoggedIn,
  goToRoute,
  classroomId,
  markNotificationAsRead,
  changeActiveProduct
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [notificationDialogData, setNotificationDialogData] =
    useState<null | NotificationDialogData>(null)

  const activeProduct = useSelector(selectActiveProductId)

  const onClickedNotification = (
    notification: Notification,
    isRead: boolean,
    activeProductId: number
  ) => {
    if (notification.dataType === "url") {
      window.open(notification.data, "_blank", "noopener")
    }
    if (notification.dataType === "html") {
      setNotificationDialogData({
        html: notification.data,
        title: notification.text
      })
    }
    if (notification.dataType === "uri") {
      const { type, payload } = convertURIToRoute(
        notification.data,
        classroomId
      ) || {
        type: "",
        payload: {}
      }
      goToRoute(type, payload)
    }
    if (!isRead && teacherLoggedIn) {
      markNotificationAsRead(teacherLoggedIn.studliId, [notification.id])
    }
    setAnchorEl(null)
  }

  const markAllAsRead = () => {
    if (!teacherLoggedIn) {
      return
    }

    const notificationsRead = notifications
      .filter(
        notification =>
          !notification.readByMembers.some(
            member => teacherLoggedIn.studliId === member
          )
      )
      .map(notification => notification.id)
    markNotificationAsRead(teacherLoggedIn.studliId, notificationsRead)
  }

  const renderListItem = (notification: Notification) => {
    if (!teacherLoggedIn) {
      return null
    }
    moment.updateLocale("en", {
      relativeTime: relativeTimeForNotifications
    })
    let duration = ""
    if (Math.abs(moment().diff(notification.updatedAt)) < 60000) {
      duration = "Mindre än 1 minut"
    } else {
      duration = moment.utc(notification.updatedAt).fromNow()
    }

    const isRead = notification.readByMembers.some(
      member => teacherLoggedIn.studliId === member
    )

    return (
      <ListItem
        button
        key={notification.id}
        onClick={() =>
          onClickedNotification(notification, isRead, activeProduct)
        }
      >
        <ListItemIcon>{getIcon(notification.type)}</ListItemIcon>
        <StyledNotificationText>
          <StyledListItemText unread={!isRead}>
            {notification.text}
          </StyledListItemText>
          <StyledListItemSecondaryText>{duration}</StyledListItemSecondaryText>
        </StyledNotificationText>

        <ListItemSecondaryAction>
          {!isRead && <StyledListItemUnreadDot />}
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? "notifications-popover" : undefined
  const numberUnread =
    notifications &&
    teacherLoggedIn &&
    notifications.filter(
      notification =>
        !notification.readByMembers.some(
          member => member === teacherLoggedIn.studliId
        )
    ).length

  return (
    <>
      {teacherLoggedIn && (
        <>
          <StyledIconButton
            aria-label="Notifikationsknapp"
            aria-describedby={id}
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<any>) =>
              setAnchorEl(event.currentTarget)
            }
          >
            {numberUnread !== 0 ? (
              <StyledBadge badgeContent={numberUnread}>
                <StyledNotificationsIcon />
              </StyledBadge>
            ) : (
                <StyledNotificationsIcon />
              )}
          </StyledIconButton>
          <Popover
            id={id}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <StyledNotificationsListHeader>
              <StyledNotificationsHeaderText>
                Notiser
              </StyledNotificationsHeaderText>
              {notifications.length > 0 && (
                <MoreMenu
                  menuItems={[
                    {
                      action: () => markAllAsRead(),
                      option: "Markera alla som lästa"
                    }
                  ]}
                />
              )}
            </StyledNotificationsListHeader>
            {notifications.length ? (
              <StyledNotificationsList>
                {notifications.map(notification =>
                  renderListItem(notification)
                )}
              </StyledNotificationsList>
            ) : (
                <StyledNoNotificationsText>
                  Du har inga notiser
                </StyledNoNotificationsText>
              )}
          </Popover>
        </>
      )}
      {notificationDialogData && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={notificationDialogData !== null}
          onClose={() => setNotificationDialogData(null)}
        >
          <DialogTitle>{notificationDialogData.title}</DialogTitle>
          <DialogContent>
            <HtmlRenderer html={notificationDialogData.html} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setNotificationDialogData(null)}
            >
              Stäng
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default NotificationsList

const getIcon = (type: string) => {
  return NOTIFICATION_TYPES_ICON[type] || <NotificationsIcon />
}
