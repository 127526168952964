import * as types from "./types"
import { createAction } from "../../shared/tools/redux"

export enum REQUEST {
  GET_ALL_ASSIGNMENTS_REQUEST = "GET_ALL_ASSIGNMENTS_REQUEST",
  CREATE_ASSIGNMENT_REQUEST = "CREATE_ASSIGNMENT_REQUEST",
  EDIT_ASSIGNMENT_REQUEST = "EDIT_ASSIGNMENT_REQUEST",
  REMOVE_ASSIGNMENT_REQUEST = "REMOVE_ASSIGNMENT_REQUEST",
  IMPORT_ASSIGNMENT_REQUEST = "IMPORT_ASSIGNMENT_REQUEST",
  GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_REQUEST = "GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_REQUEST",
  GET_ALL_PREPARED_ASSIGNMENTS_REQUEST = "GET_ALL_PREPARED_ASSIGNMENTS_REQUEST",
  APPROVE_OR_DECLINE_ASSIGNMENT_REQUEST = "APPROVE_OR_DECLINE_ASSIGNMENT_REQUEST",
  GET_ALL_ASSIGNMENTS_MESSAGES_REQUEST = "GET_ALL_ASSIGNMENTS_MESSAGES_REQUEST",
  SEND_ASSIGNMENT_MESSAGE_REQUEST = "SEND_ASSIGNMENT_MESSAGE_REQUEST",
  GET_ASSIGNMENT = "GET_ASSIGNMENT",
  MARK_MESSAGE_AS_READ_REQUEST = "MARK_MESSAGE_AS_READ_REQUEST",
  STORE_PREVIEW_REQUEST = "STORE_PREVIEW_REQUEST"
}

export enum RESPONSE {
  GET_ALL_ASSIGNMENTS_RESPONSE = "GET_ALL_ASSIGNMENTS_RESPONSE",
  CREATE_ASSIGNMENT_RESPONSE = "CREATE_ASSIGNMENT_RESPONSE",
  EDIT_ASSIGNMENT_RESPONSE = "EDIT_ASSIGNMENT_RESPONSE",
  IMPORT_ASSIGNMENT_RESPONSE = "IMPORT_ASSIGNMENT_RESPONSE",
  CREATE_EDIT_ASSIGNMENT_RESPONSE_ERROR = "CREATE_EDIT_ASSIGNMENT_RESPONSE_ERROR",
  REMOVE_ASSIGNMENT_RESPONSE = "REMOVE_ASSIGNMENT_RESPONSE",
  GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_RESPONSE = "GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_RESPONSE",
  GET_ALL_PREPARED_ASSIGNMENTS_RESPONSE = "GET_ALL_PREPARED_ASSIGNMENTS_RESPONSE",
  APPROVE_OR_DECLINE_ASSIGNMENT_RESPONSE = "APPROVE_OR_DECLINE_ASSIGNMENT_RESPONSE",
  GET_ALL_ASSIGNMENTS_MESSAGES_RESPONSE = "GET_ALL_ASSIGNMENTS_MESSAGES_RESPONSE",
  SEND_ASSIGNMENT_MESSAGE_RESPONSE = "SEND_ASSIGNMENT_MESSAGE_RESPONSE",
  GET_ASSIGNMENT = "GET_ASSIGNMENT_RESPONSE",
  MARK_MESSAGE_AS_READ_RESPONSE = "MARK_MESSAGE_AS_READ_RESPONSE",
  STORE_PREVIEW_RESPONSE = "STORE_PREVIEW_RESPONSE"
}

export enum EVENT {
  ASSIGNMENT_MESSAGE_CREATED_EVENT = "ASSIGNMENT_MESSAGE_CREATED_EVENT",
  ASSIGNMENT_MESSAGE_READ_EVENT = "ASSIGNMENT_MESSAGE_READ_EVENT"
}

export enum SERVER_MESSAGE_ACTION {
  GET_ALL_ASSIGNMENTS_REQUEST = "list",
  GET_ALL_ASSIGNMENTS_RESPONSE = "list",
  GET_ASSIGNMENT_REQUEST = "get",
  GET_ASSIGNMENT_RESPONSE = "get",
  CREATE_ASSIGNMENT_REQUEST = "create",
  CREATE_ASSIGNMENT_RESPONSE = "create",
  EDIT_ASSIGNMENT_REQUEST = "edit",
  EDIT_ASSIGNMENT_RESPONSE = "edit",
  REMOVE_ASSIGNMENT_REQUEST = "remove",
  REMOVE_ASSIGNMENT_RESPONSE = "remove",
  GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_REQUEST = "importable",
  GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_RESPONSE = "importable",
  IMPORT_ASSIGNMENT_REQUEST = "import",
  IMPORT_ASSIGNMENT_RESPONSE = "import",
  GET_ALL_PREPARED_ASSIGNMENTS_REQUEST = "prepared",
  GET_ALL_PREPARED_ASSIGNMENTS_RESPONSE = "prepared",
  ASSIGNMENT_UPDATE_EVENT = "assignment_updated",
  ASSIGNMENT_REMOVED_EVENT = "assignment_removed",
  ASSIGNMENT_ADDED_EVENT = "assignment_added",
  APPROVE_OR_DECLINE_ASSIGNMENT_REQUEST = "approve_or_reject",
  APPROVE_OR_DECLINE_ASSIGNMENT_RESPONSE = "approve_or_reject",
  GET_ALL_ASSIGNMENTS_MESSAGES_REQUEST = "get_classroom_messages",
  GET_ALL_ASSIGNMENTS_MESSAGES_RESPONSE = "get_classroom_messages",
  SEND_ASSIGNMENT_MESSAGE_REQUEST = "send_message",
  SEND_ASSIGNMENT_MESSAGE_RESPONSE = "send_message",
  ASSIGNMENT_MESSAGE_CREATED_EVENT = "assignment_message_created",
  ASSIGNMENT_MESSAGE_READ_EVENT = "assignment_message_read",
  MARK_MESSAGE_AS_READ_REQUEST = "mark_message_as_read",
  MARK_MESSAGE_AS_READ_RESPONSE = "mark_message_as_read",
  STORE_PREVIEW_REQUEST = "store_preview",
  STORE_PREVIEW_RESPONSE = "store_preview"
}

export enum UI {
  RESET_ASSIGNMENTS = "RESET_ASSIGNMENTS",
  SET_IMPORTED_ASSIGNMENT = "SET_IMPORTED_ASSIGNMENT",
  RESET_IMPORTED_ASSIGNMENT = "RESET_IMPORTED_ASSIGNMENT",
  RESET_ASSIGNMENTS_MESSAGES = "RESET_ASSIGNMENTS_MESSAGES",
  UPDATE_ASSIGNMENTS_MESSAGES = "UPDATE_ASSIGNMENTS_MESSAGES",
  LOADING_IMPORT_ASSIGNMENTS = "LOADING_IMPORT_ASSIGNMENTS",
  REMOVE_IMPORTABLE_ASSIGNMENTS = "REMOVE_IMPORTABLE_ASSIGNMENTS",
  UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT"
}

export const getAllAssignments = (): types.getAllAssignmentsAction => ({
  type: REQUEST.GET_ALL_ASSIGNMENTS_REQUEST
})

export const createAssignment = (
  assignment: types.CreateAssignmentData
): types.CreateAssignmentAction => ({
  type: REQUEST.CREATE_ASSIGNMENT_REQUEST,
  payload: { assignment: assignment }
})

export const editAssignment = (
  assignment: types.Assignment,
  echo: types.EditEchoActionTypes
): types.EditAssignmentAction => ({
  type: REQUEST.EDIT_ASSIGNMENT_REQUEST,
  payload: { assignment: assignment },
  echo
})

export const removeAssignment = (
  assignmentId: types.Assignment["id"]
): types.RemoveAssignmentAction => ({
  type: REQUEST.REMOVE_ASSIGNMENT_REQUEST,
  payload: { assignmentId: assignmentId }
})

export const receiveAssignments = (
  assignments: types.Assignments
): types.ReceiveAssignmentsAction => {
  return {
    type: RESPONSE.GET_ALL_ASSIGNMENTS_RESPONSE,
    payload: { assignments }
  }
}

export const importAssignment = (
  ids: number[],
  withUsers: boolean
): types.ImportAssignmentAction => {
  return {
    type: REQUEST.IMPORT_ASSIGNMENT_REQUEST,
    payload: { assignmentIds: ids, withUsers }
  }
}

export const getAllClassroomRelatedAssignments =
  (): types.GetAllClassroomRelatedAssignmentsAction => ({
    type: REQUEST.GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_REQUEST
  })

export const getAllPreparedAssignments =
  (): types.GetAllPreparedAssignmentsAction => ({
    type: REQUEST.GET_ALL_PREPARED_ASSIGNMENTS_REQUEST
  })

export const approveOrDeclineAssignment = (
  payload: types.ApproveOrDeclineAssignmentAction["payload"]
): types.ApproveOrDeclineAssignmentAction => ({
  type: REQUEST.APPROVE_OR_DECLINE_ASSIGNMENT_REQUEST,
  payload: {
    approved: payload.approved,
    studliId: payload.studliId,
    assignmentId: payload.assignmentId
  }
})

export const getAllAssignmentsMessages =
  (): types.GetAllAssignmentsMessagesAction => ({
    type: REQUEST.GET_ALL_ASSIGNMENTS_MESSAGES_REQUEST
  })

export const sendAssignmentMessage = (
  payload: types.SendAssignmentMessageAction["payload"]
): types.SendAssignmentMessageAction => ({
  type: REQUEST.SEND_ASSIGNMENT_MESSAGE_REQUEST,
  payload
})

export const markMessageAsRead = (
  payload: types.MarkMessageAsReadAction["payload"]
): types.MarkMessageAsReadAction => ({
  type: REQUEST.MARK_MESSAGE_AS_READ_REQUEST,
  payload
})

export const getAssignment = (
  id: types.Assignment["id"]
): types.GetAssignmentRequestAction => ({
  type: REQUEST.GET_ASSIGNMENT,
  payload: {
    assignmentId: id
  }
})

export const storePreview = (data: string) => ({
  type: REQUEST.STORE_PREVIEW_REQUEST,
  payload: data
})

export const setAssignments = createAction<types.Assignments>(
  "GET_ALL_ASSIGNMENTS_RESPONSE"
)

export const addAssignment = createAction<{ assignment: types.Assignment }>(
  "CREATE_ASSIGNMENT_RESPONSE"
)
export const removeImportableAssignments = createAction<{
  assignments: number[]
}>("REMOVE_IMPORTABLE_ASSIGNMENTS")

export const modifyAssignment = createAction<{ assignment: types.Assignment }>(
  "EDIT_ASSIGNMENT_RESPONSE"
)

export const createEditAssignmentValidationError = createAction<{
  [key: number]: string
}>("CREATE_EDIT_ASSIGNMENT_RESPONSE_ERROR")

export const deleteAssignment = createAction<{ id: number }>(
  "REMOVE_ASSIGNMENT_RESPONSE"
)

export const setPreparedAssignments = createAction<types.PreparedAssignment[]>(
  "GET_ALL_PREPARED_ASSIGNMENTS_RESPONSE"
)

export const setImportableAssignments = createAction<
  types.ImportableAssignmentClassroom[]
>("GET_ALL_RELATED_CLASSROOM_ASSIGNMENT_RESPONSE")

export const addAssignmentMessage = createAction<{
  message: types.AssignmentMessage
}>("ASSIGNMENT_MESSAGE_CREATED_EVENT")

export const resetAssignments = createAction("RESET_ASSIGNMENTS")

export const setImportedAssignment = createAction<types.PartialAssignment>(
  "SET_IMPORTED_ASSIGNMENT"
)

export const resetImportedAssignment = createAction("RESET_IMPORTED_ASSIGNMENT")

export const setAssignmentMessages = createAction<types.AssignmentMessage[]>(
  "UPDATE_ASSIGNMENTS_MESSAGES"
)

export const resetAssignmentMessages = createAction(
  "RESET_ASSIGNMENTS_MESSAGES"
)

export const assignmentUpdatedEvent = createAction<{
  assignment: types.Assignment
}>("UPDATE_ASSIGNMENT")

export const assignmentRemovedEvent =
  createAction<{ id: number }>("assignment_removed")

export const assignmentMessageCreatedEvent = createAction<{
  message: types.AssignmentMessage
}>("assignment_message_created")

export const setPreviewUrl = createAction<string | undefined>(
  "SET_ASSIGNMENT_PREVIEW_URL"
)

export const setLoadingImportAssignment = createAction<number>(
  "LOADING_IMPORT_ASSIGNMENTS"
)
