import { styled, Theme } from "@mui/material/styles"

import DialogContent, { DialogContentProps } from "@mui/material/DialogContent"
import DialogContentText, {
  DialogContentTextProps
} from "@mui/material/DialogContentText"

export const StyledDialogContent = styled(
  DialogContent as React.FC<DialogContentProps>
)`
  padding: 0;
`
export const StyledDialogContentText = styled(
  DialogContentText as React.FC<DialogContentTextProps>
)`
  padding: 0 ${props => `${(props.theme as Theme).spacing(3)}`};
`
