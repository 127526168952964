import React, { useEffect } from "react"
import { ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material"
import { ParentOrExerciseNode } from "../../modules/exercises/types"
import * as TreeTypes from "./TreeTypes"
import {
  StyledTreeItem,
  StyledTreeView,
  StyledListWrapper
} from "./StyledTreeView"
import { isParent, countNumberOfNodesBelowNode } from "./common/helpers"
import { Members } from "../../modules/members/types"
import { ExerciseProgress } from "../../modules/exercisesProgress/types"
import InfoPopover from "../InfoPopover"
import {
  StyledLegendContainer,
  StyledLegendBox
} from "../GoalsTable/StyledGoalsTable"
import { ChapterOrPage } from "../../modules/book/types"
import { PROGRESS_SEGMENT } from "../Progress/constants"
import BookPagesListItem from "../BookPagesListItem"
import ExerciseListItem from "../ExerciseListItem"

type Props = {
  nodes: TreeTypes.Nodes
  expandedNodes: TreeTypes.NodeIds
  exercisesProgress: ExerciseProgress[]
  members?: Members
  setExpandedNodes: (nodeId: TreeTypes.NodeIds) => void
  topNodesNotExpanded: TreeTypes.NodeIds
  setTopNodesNotExpanded: (nodeId: TreeTypes.NodeIds) => void
  checkedNodes: TreeTypes.NodeIds
  onCheckboxClicked: Function
  onProgressDialogOpen?: Function
  hideStatus: boolean
  onItemClick?: Function
  type: "exercises" | "pages" | "studentCard"
  selectable?: boolean
}

const CustomTreeView = ({
  nodes,
  expandedNodes,
  setExpandedNodes,
  topNodesNotExpanded,
  setTopNodesNotExpanded,
  checkedNodes,
  onCheckboxClicked,
  exercisesProgress,
  members,
  onProgressDialogOpen,
  hideStatus,
  onItemClick,
  type,
  selectable = false
}: Props) => {
  useEffect(() => {
    if (nodes && topNodesNotExpanded.length === 0) {
      const topNodesId: TreeTypes.NodeIds = []
      nodes.forEach(entry => {
        topNodesId.push(entry.uniqueId)
      })
      setTopNodesNotExpanded(topNodesId)
    }
    // eslint-disable-next-line
  }, [nodes])

  const shouldExpandNode = (nodeId: TreeTypes.NodeId) => {
    if (expandedNodes.some(expandedNode => expandedNode === nodeId)) {
      collapseNode(nodeId)

      return
    }

    if (topNodesNotExpanded.some(topNode => topNode === nodeId)) {
      {
        const node = nodes.find(entry => entry.uniqueId === nodeId)
        node && expandNodesFromParent(node)
      }
      removeTopNodeFromHasTopNodeExpanded(nodeId)

      return
    }
    expandNode(nodeId)
  }

  const expandNode = (nodeId: TreeTypes.NodeId) => {
    const currentExpandedNodes = [...expandedNodes, nodeId]
    setExpandedNodes(currentExpandedNodes)
  }

  const collapseNode = (nodeId: TreeTypes.NodeId) => {
    let currentExpandedNodes = [...expandedNodes]
    currentExpandedNodes = currentExpandedNodes.filter(
      expandedNode => expandedNode !== nodeId
    )
    setExpandedNodes(currentExpandedNodes)
  }

  const removeTopNodeFromHasTopNodeExpanded = (nodeId: TreeTypes.NodeId) => {
    let currentTopNodesNotExpanded = [...topNodesNotExpanded]
    currentTopNodesNotExpanded = currentTopNodesNotExpanded.filter(
      expandedNode => expandedNode !== nodeId
    )
    setTopNodesNotExpanded(currentTopNodesNotExpanded)
  }

  const expandNodesFromParent = (node: TreeTypes.ParentOrLeafNode) => {
    const nodesToExpand: TreeTypes.NodeIds = []

    traverse(node, nodesToExpand)

    const currentExpandedNodes = [...expandedNodes, ...nodesToExpand]
    setExpandedNodes(currentExpandedNodes)
  }

  const traverse = (
    node: TreeTypes.ParentOrLeafNode,
    nodesToExpand: string[]
  ) => {
    if (isParent(node) && node.children) {
      nodesToExpand.push(node.uniqueId)
      node.children.forEach((childNode: TreeTypes.ParentOrLeafNode) =>
        traverse(childNode, nodesToExpand)
      )
    }
  }

  const renderTreeItem = (node: TreeTypes.ParentOrLeafNode) => {
    let numberOfNodesBelow = 0
    if (isParent(node)) {
      numberOfNodesBelow = countNumberOfNodesBelowNode(node)
    }

    const isChecked = checkedNodes.some(
      exerciseId => exerciseId === node.uniqueId
    )

    return (
      <StyledTreeItem
        key={node.uniqueId}
        onClick={() => shouldExpandNode(node.uniqueId)}
        nodeId={node.uniqueId}
        notSelectable={!isParent(node) && type === "studentCard"}
        label={
          type === "exercises" || type === "studentCard" ? (
            <ExerciseListItem
              numberOfNodesBelow={numberOfNodesBelow}
              expandedNodes={expandedNodes}
              node={node as ParentOrExerciseNode}
              onItemClick={onItemClick ? onItemClick : undefined}
              onCheckboxClicked={onCheckboxClicked}
              isChecked={isChecked}
              exercisesProgress={exercisesProgress}
              members={members}
              hideStatus={hideStatus}
              selectable={selectable}
              onProgressDialogOpen={onProgressDialogOpen}
              type={type}
            />
          ) : (
            <BookPagesListItem
              numberOfNodesBelow={numberOfNodesBelow}
              expandedNodes={expandedNodes}
              node={node as ChapterOrPage}
              onItemClick={onItemClick ? onItemClick : undefined}
              onCheckboxClicked={onCheckboxClicked}
              isChecked={isChecked}
            />
          )
        }
      >
        {isParent(node)
          ? node.children &&
            node.children.map((child: TreeTypes.ParentOrLeafNode) =>
              renderTreeItem(child)
            )
          : null}
      </StyledTreeItem>
    )
  }

  return (
    <StyledTreeView expanded={expandedNodes}>
      {type !== "studentCard" && (
        <StyledListWrapper>
          <ListItem divider component={"div" as any}>
            <ListItemText>Namn</ListItemText>
            {!hideStatus && (
              <ListItemSecondaryAction>
                Elevernas aktivitet
                <InfoPopover
                  type="Info"
                  content={
                    <div>
                      <StyledLegendContainer>
                        <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                        Klar
                      </StyledLegendContainer>
                      <StyledLegendContainer>
                        <StyledLegendBox
                          segment={PROGRESS_SEGMENT.IN_PROGRESS}
                        />
                        Påbörjad
                      </StyledLegendContainer>
                      <StyledLegendContainer>
                        <StyledLegendBox
                          segment={PROGRESS_SEGMENT.NOT_STARTED}
                        />
                        Ej påbörjad
                      </StyledLegendContainer>
                    </div>
                  }
                />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </StyledListWrapper>
      )}
      {nodes.map((chapter: TreeTypes.ParentOrLeafNode) =>
        renderTreeItem(chapter)
      )}
    </StyledTreeView>
  )
}

export default CustomTreeView
