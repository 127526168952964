import React from "react"
import { connect } from "react-redux"

import { StyledListWrapper } from "./StyledTeachers"
import * as membersTypes from "../../modules/members/types"
import * as memberSelectors from "../../modules/members/selectors"
import * as routerSelectors from "../../modules/router/selectors"

import { RootState } from "../../modules/store"
import TitleAndDescription from "../../components/TitleAndDescription"
import TeachersList from "../../components/TeachersList"
import CenteredContainer from "../../containers/PageContainers/CenteredContainer"

type OwnProps = {
  children?: never
}

type StateProps = {
  classroomId: number
  teachers: membersTypes.Members
}

type Props = OwnProps & StateProps

export const Teachers = ({ classroomId, teachers }: Props) => {
  const renderTitleAndDescription = () => {
    const paragraphs = [
      `Här kan du se de lärare som har tillgång till klassrummet, d v s de ingår i minst en vald grupp och som har tillgång till minst en produkt som visas i klassrummet. Du kan administrera lärare, grupper och licenser i Min bokhylla.`
    ]

    return (
      <TitleAndDescription
        title="Lärare i klassrummet"
        paragraphs={paragraphs}
      />
    )
  }

  const renderTeachersList = () => (
    <StyledListWrapper>
      <TeachersList
        teachers={teachers.sort((a, b) => a.lastName.localeCompare(b.lastName))}
      />
    </StyledListWrapper>
  )

  return (
    <CenteredContainer>
      <React.Fragment>
        {renderTitleAndDescription()}
        {renderTeachersList()}
      </React.Fragment>
    </CenteredContainer>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => {
  return {
    classroomId: routerSelectors.selectCurrentClassroomId(state),
    teachers: memberSelectors.selectTeachers(state)
  }
}

const ConnectedTeachers = connect(mapStateToProps)(Teachers)

export default ConnectedTeachers
