import React, { useState, useEffect } from "react"
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Stack
} from "@mui/material"
import { AssignmentTask } from "../../modules/assignments/types"
import { GoalWithProgress } from "../../modules/goals/types"
import Button from "../Button"
import GoalAssignmentList from "../GoalAssignmentList/GoalAssignmentList"
import useChooseGoals, { TABLE_EXERCISES } from "./useChooseGoals"
import { convertGoalsToTasks } from "./helpers"
import { StudentInStudentList } from "../../pages/Members/store/types"
import { Members } from "../../modules/members/types"
import SearchIcon from "@mui/icons-material/Search"
import { Clear } from "@mui/icons-material"

type Props = {
  goals: GoalWithProgress[]
  onCancel: () => void
  onSubmit: (tasks: AssignmentTask[]) => void
  alreadyAddedGoals: AssignmentTask[]
  maxSelect: number
  members: Members
}

const NOOP = () => {}

const ChooseGoalsDialog = ({
  goals,
  onCancel,
  onSubmit,
  alreadyAddedGoals,
  maxSelect,
  members
}: Props) => {
  const [selectedGoals, setSelectedGoals] = useState<GoalWithProgress[]>([])
  const [gradeEl, setGradeEl] = React.useState<null | HTMLElement>(null)
  const [tableEl, setTableEl] = React.useState<null | HTMLElement>(null)

  const {
    currentGoals,
    gradeFilters,
    selectedGradeFilter,
    setSelectedGradeFilter,
    selectedTableFilter,
    setSelectedTableFilter,
    searchText,
    setSearchText
  } = useChooseGoals({
    goals,
    members: members as unknown as StudentInStudentList[]
  })

  useEffect(() => {
    if (!selectedGoals.length && alreadyAddedGoals.length) {
      setSelectedGoals(
        goals.filter(g =>
          alreadyAddedGoals.some(task => task.link === g.goalRef)
        )
      )
    }
  }, [alreadyAddedGoals, goals, selectedGoals])

  const submitGoals = () => {
    const tasks = convertGoalsToTasks(
      selectedGoals.sort((a, b) => a.order - b.order)
    )
    onSubmit(tasks)
    onCancel()
  }

  const onFilterGrades = (grade: number) => {
    if (grade === 0) {
      setSelectedGradeFilter([])
      return
    }
    if (selectedGradeFilter.includes(grade)) {
      setSelectedGradeFilter(selectedGradeFilter.filter(g => g !== grade))
      return
    }
    if (selectedGradeFilter.length === gradeFilters?.length - 1) {
      setSelectedGradeFilter([])
      return
    }

    setSelectedGradeFilter([...selectedGradeFilter, grade])
  }

  const onFilterTableExercises = (exerciseType: string) => {
    if (exerciseType === "all") {
      setSelectedTableFilter([])
      return
    }
    if (selectedTableFilter.includes(exerciseType)) {
      setSelectedTableFilter(
        selectedTableFilter.filter(g => g !== exerciseType)
      )
      return
    }
    if (selectedTableFilter.length === TABLE_EXERCISES.length - 1) {
      setSelectedTableFilter([])
      return
    }

    setSelectedTableFilter([...selectedTableFilter, exerciseType])
  }

  const getGradeFilterLabel = () => {
    if (
      selectedGradeFilter.length === 0 ||
      selectedGradeFilter.length === gradeFilters?.length
    ) {
      return "Årskurs: alla"
    }
    if (selectedGradeFilter.length === 1) {
      return `Årskurs: ${selectedGradeFilter[0]}`
    }

    return `Årskurs: ${selectedGradeFilter.sort().join(", ")}`
  }

  const getTableFilterLabel = () => {
    if (
      selectedTableFilter.length === 0 ||
      selectedTableFilter.length === TABLE_EXERCISES.length
    ) {
      return "Tabellträning: alla"
    }
    const filterLabels = TABLE_EXERCISES.filter(t =>
      selectedTableFilter.includes(t.value)
    ).map(t => t.label)
    if (selectedTableFilter.length === 1) {
      return `Tabellträning: ${filterLabels[0]}`
    }

    return `Tabellträning: ${filterLabels[0]} +${
      selectedTableFilter.length - 1
    }`
  }

  const onToggle = (goal: GoalWithProgress) => {
    const isChecked = selectedGoals.some(
      selected => selected.goalRef === goal.goalRef
    )
    if (!isChecked && selectedGoals.length === maxSelect) {
      return
    }
    setSelectedGoals(
      isChecked
        ? selectedGoals.filter(selected => selected.goalRef !== goal.goalRef)
        : [...selectedGoals, goal]
    )
  }

  return (
    <>
      <DialogTitle>Välj uppdragsmål</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vilka mål vill du att eleverna ska träna lite extra på? Välj ut 1-10
          mål från hela mållistan. Du kan leta mål per årskurs eller välja mål
          med tabellträning. Du kan också söka på målets titel.
        </DialogContentText>
        <Stack
          alignItems="center"
          mt={2}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            {gradeFilters && (
              <Box>
                <Button
                  sx={{ textTransform: "none" }}
                  id="filter-grade-button"
                  aria-controls={
                    Boolean(gradeEl) ? "filter-grade-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={Boolean(gradeEl) ? "true" : undefined}
                  onClick={e => setGradeEl(e.currentTarget)}
                  variant="outlined"
                >
                  {getGradeFilterLabel()}
                </Button>
                <Menu
                  id="filter-grade-menu"
                  anchorEl={gradeEl}
                  open={Boolean(gradeEl)}
                  onClose={() => setGradeEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "filter-grade-button"
                  }}
                >
                  <MenuItem
                    key="all"
                    value={0}
                    onClick={() => onFilterGrades(0)}
                  >
                    <Checkbox
                      checked={
                        selectedGradeFilter.length === gradeFilters.length ||
                        selectedGradeFilter.length === 0
                      }
                    />
                    Alla
                  </MenuItem>
                  {gradeFilters.map(f => (
                    <MenuItem
                      key={f.value}
                      value={f.value}
                      onClick={() => onFilterGrades(f.value)}
                    >
                      <Checkbox
                        checked={
                          selectedGradeFilter.includes(f.value) &&
                          selectedGradeFilter.length !== gradeFilters.length
                        }
                      />
                      {f.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
            <Box>
              <Button
                sx={{ textTransform: "none" }}
                id="filter-table-button"
                aria-controls={
                  Boolean(tableEl) ? "filter-table-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={Boolean(tableEl) ? "true" : undefined}
                onClick={e => setTableEl(e.currentTarget)}
                variant="outlined"
              >
                {getTableFilterLabel()}
              </Button>
              <Menu
                id="filter-table-menu"
                anchorEl={tableEl}
                open={Boolean(tableEl)}
                onClose={() => setTableEl(null)}
                MenuListProps={{
                  "aria-labelledby": "filter-table-button"
                }}
              >
                {TABLE_EXERCISES.map(t => (
                  <MenuItem
                    key={t.value}
                    value={t.value}
                    onClick={() => onFilterTableExercises(t.value)}
                  >
                    <Checkbox
                      checked={
                        selectedTableFilter.includes(t.value) &&
                        selectedTableFilter.length !== TABLE_EXERCISES.length
                      }
                    />
                    {t.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Stack>
          <Box>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <InputLabel htmlFor="Sök mål input">Sök mål</InputLabel>
              <OutlinedInput
                id="Sök mål input"
                type="text"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                label="Sök mål"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Rensa sök"
                      disabled={!searchText.length}
                      onClick={() => setSearchText("")}
                    >
                      {searchText.length ? <Clear /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Stack>
        <GoalAssignmentList
          selectedGoals={selectedGoals}
          onToggle={onToggle}
          goals={currentGoals}
          inCreateList={false}
          showRemove={false}
          onRemove={NOOP}
          includeLabels
          search={searchText}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} color="primary">
          Avbryt
        </Button>
        <Button
          disabled={!selectedGoals.length}
          variant="outlined"
          onClick={submitGoals}
          color="primary"
        >
          Välj
        </Button>
      </DialogActions>
    </>
  )
}

export default ChooseGoalsDialog
