export type ModuleName = "auth"

export const MODULE_NAME: ModuleName = "auth"

export const AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_KEY = "auth_original_route"
export const AUTH_LOCALSTORAGE_ORIGINAL_ROUTE_PAYLOAD_KEY =
  "auth_original_route_payload"
export const AUTH_LOCALSTORAGE_ORIGINAL_PATH_KEY = "auth_original_path"
export const AUTH_URL =
  "https://loggain.studentlitteratur.se/oauth2/authorize.php"
