import React, { useState, useEffect } from "react"
import {
  FormativeTest,
  ParsedFormativeProgressionAnswers,
  FormativeSession,
  FormativeQuestionsWithStudent
} from "../../modules/formative/types"
import FormativeQuestionAnswerItem, {
  ProgressItem
} from "../../components/FormativeQuestionsListItem/FormativeQuestionAnswerItem"
import Dialog from "../../components/Dialog"
import FormativeResultDialog from "../../components/FormativeResultDialog"
import FormativeEndQuestionResult from "./FormativeEndQuestionResult"
import { getMembersWithAnswers } from "./helpers"
import { useSelector } from "react-redux"
import { selectAllMembers } from "../../modules/members/selectors"

type Props = {
  test: FormativeTest
  answers: ParsedFormativeProgressionAnswers
  session: FormativeSession
  prevSession: FormativeSession | null
  showAnswers: boolean
  type: "student" | "class"
}

const FormativeQuestionAnswers = ({
  test,
  answers,
  session,
  prevSession,
  showAnswers,
  type
}: Props) => {
  const members = useSelector(selectAllMembers)
  const [questionsWithAnsweredStudents, setQuestionsWithAnsweredStudents] =
    useState<FormativeQuestionsWithStudent | null>(null)

  const [
    prevQuestionsWithAnsweredStudents,
    setPrevQuestionsWithAnsweredStudents
  ] = useState<FormativeQuestionsWithStudent | null>(null)

  const [progressDialog, setProgressDialog] = useState<null | ProgressItem>(
    null
  )

  useEffect(() => {
    const s = {
      ...session,
      members: session.members.filter(m =>
        members.some(member => member.studliId === m)
      )
    }

    setQuestionsWithAnsweredStudents(getMembersWithAnswers(s, test, answers))
    if (prevSession) {
      const prevS = {
        ...session,
        members: prevSession.members.filter(m =>
          members.some(member => member.studliId === m)
        )
      }
      setPrevQuestionsWithAnsweredStudents(
        getMembersWithAnswers(prevS, test, answers)
      )
    }
    if (!prevSession && prevQuestionsWithAnsweredStudents) {
      setPrevQuestionsWithAnsweredStudents(null)
    }
    // eslint-disable-next-line
  }, [test, answers, session, prevSession])

  const renderQuestions = (
    renderQuestionsArr: FormativeQuestionsWithStudent
  ) => {
    return (
      <>
        {renderQuestionsArr.map((question, index) => {
          const previousQuestion = prevQuestionsWithAnsweredStudents
            ? prevQuestionsWithAnsweredStudents[index]
            : null
          return (
            <FormativeQuestionAnswerItem
              key={question.id}
              question={question}
              showAnswers={showAnswers}
              type={type}
              showProgressInfo={index === 0}
              prevQuestion={previousQuestion}
              setProgressDialog={setProgressDialog}
            />
          )
        })}
        {test.endQuestionId && renderEndQuestion(test.endQuestionId)}
      </>
    )
  }

  const renderEndQuestion = (endQuestionId: number) => {
    const endQuestionAnswers = answers.filter(
      answer =>
        answer.questionId === endQuestionId && answer.sessionId === session.id
    )
    const prevEndQuestionAnswers = prevSession
      ? answers.filter(
          answer =>
            answer.questionId === endQuestionId &&
            answer.sessionId === prevSession.id
        )
      : null
    return (
      <FormativeEndQuestionResult
        showAnswers={showAnswers}
        test={test}
        type={type}
        setProgressDialog={setProgressDialog}
        endQuestionAnswers={endQuestionAnswers}
        prevEndQuestionAnswers={prevEndQuestionAnswers}
        session={session}
        prevSession={prevSession ? prevSession : null}
      />
    )
  }
  return (
    <>
      {questionsWithAnsweredStudents &&
        renderQuestions(questionsWithAnsweredStudents)}
      {progressDialog && (
        <Dialog
          onClose={() => setProgressDialog(null)}
          maxWidth="xs"
          fullWidth
          open
          role="dialog"
          aria-modal="true"
          aria-labelledby="progress-dialog-title"
          aria-describedby="progress-dialog-description"
        >
          <FormativeResultDialog
            onCancel={() => setProgressDialog(null)}
            ariaTitleId="progress-dialog-title"
            ariaDescId="progress-dialog-description"
            progress={progressDialog}
          />
        </Dialog>
      )}
    </>
  )
}

export default FormativeQuestionAnswers
