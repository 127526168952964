export const statuses = {
  published: "Kommande",
  ongoing: "Pågående",
  stopped: "Avslutat",
  draft: "Utkast",
  archived: "Arkiverat"
}

export const menuItemActions = {
  TOGGLE_PRIVATE: "togglePrivate",
  START: "start",
  EDIT: "edit",
  CREATE_COPY: "createCopy",
  FULLSCREEN: "fullscreen",
  ARCHIVE: "archive",
  STOP: "stop",
  DO_AGAIN: "doAgain",
  ACTIVATE: "activate",
  REMOVE: "remove"
}
