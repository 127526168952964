import { takeLatest, put, all } from "redux-saga/effects"

import * as actions from "./actions"
import * as communicationActions from "../communication/actions"

import { MODULE_NAME } from "./constants"
import * as communicationTypes from "../communication/types"
import { sendRequest } from "../communication/actions"

import * as types from "./types"
import { setSnackbar } from "../application/actions"
import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import { getAllGoalsProgressRequest } from "../goalsProgress/actions"

function* getAllGoalsSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.GET_ALL_GOALS_REQUEST
    )
  )
}

function* watchGetAllGoalsSaga() {
  yield takeLatest(actions.getAllGoalsRequest, getAllGoalsSaga)
}

function* handleReceivedGoalResponseSaga(
  message: types.ReceiveGoalResponseMessagesTypes
) {
  const { type, action, payload, error } = message.payload
  if (type === MODULE_NAME) {
    switch (action) {
      case actions.SERVER_MESSAGE_ACTION.GET_ALL_GOALS_RESPONSE:
        const getAllGoalsResponsePayload =
          payload as types.ReceiveGetAllGoalsResponseAction["payload"]
        yield receiveGetAllGoalsResponse(getAllGoalsResponsePayload, error)
        break
      case actions.SERVER_MESSAGE_ACTION.SET_START_GOAL_RESPONSE:
        if (error) {
          yield put(
            setSnackbar(snackbarMessages.SET_START_GOAL_RESPONSE_FAILURE)
          )
          return
        }
        yield put(setSnackbar(snackbarMessages.SET_START_GOAL_RESPONSE_SUCCESS))
        yield put(getAllGoalsProgressRequest())
        break
      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in response`
        )
    }
  }
}

export function* receiveGetAllGoalsResponse(
  payload: types.ReceiveGetAllGoalsResponseAction["payload"],
  error: communicationTypes.CommunicationError
) {
  if (error) {
    // Action not initiated by user, so no snackbar needed. Rely on lower layer to handle this error...
  } else {
    yield put(actions.addGoals(payload))
  }
}

function* watchSetStartGoalSaga() {
  yield takeLatest(actions.setStartGoal, setStartGoalSaga)
}

function* setStartGoalSaga(message: any) {
  yield put(
    sendRequest(
      MODULE_NAME,
      actions.SERVER_MESSAGE_ACTION.SET_START_GOAL_REQUEST,
      message.payload
    )
  )
}

function* watchReceiverSaga() {
  yield takeLatest(
    communicationActions.RECEIVE_RESPONSE,
    handleReceivedGoalResponseSaga
  )
}

export function* rootSaga() {
  yield all([
    watchGetAllGoalsSaga(),
    watchReceiverSaga(),
    watchSetStartGoalSaga()
  ])
}
