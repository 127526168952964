import React from "react"

import {
  StyledInvalidClassroomError,
  StyledStyledInvalidClassroomError,
  StyledNavigationButton
} from "./StyledInvalidClassroomError"

import { BOOKSHELF_URL } from "../../containers/AuthUser/constants"
import Logo from "../Logo"
import Button from "../Button"

type Props = {
  message: string
  reload?: boolean
}

const InvalidClassroomError = ({ message, reload }: Props) => {
  let action = (
    <StyledNavigationButton href={`${BOOKSHELF_URL}`}>
      <Button variant="outlined" color="inherit">
        Gå till min bokhylla
      </Button>
    </StyledNavigationButton>
  )

  if (reload) {
    action = (
      <StyledNavigationButton
        href="#"
        onClick={e => {
          e.preventDefault()
          window.location.reload()
        }}
      >
        <Button variant="outlined" color="inherit">
          Ladda om sidan
        </Button>
      </StyledNavigationButton>
    )
  }

  return (
    <StyledInvalidClassroomError>
      <Logo />
      <StyledStyledInvalidClassroomError variant="subtitle1" component="p">
        {message}
      </StyledStyledInvalidClassroomError>
      {action}
    </StyledInvalidClassroomError>
  )
}

export default InvalidClassroomError
