import { styled } from "@mui/material"

export const StyledMessageListItem = styled("div")`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
export const StyledMessageContent = styled("div")`
  display: block;
  margin-left: 1rem;
`
export const StyledMessage = styled("div")`
  margin-top: 0.5rem;
`
