import * as React from "react"
import * as Sentry from "@sentry/browser"
import { ErrorPage } from "./ErrorPage"

interface ErrorBoundaryProps {
  children?: React.ReactChild
}

interface ErrorBoundaryState {
  error: Error | null
}

interface ErrorInfo {
  [key: string]: any
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  /**
   * Constructor
   * @param props
   */
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  /**
   * Catches all uncaught errors in application, logs to Sentry and displays Error page
   * @param error
   * @param errorInfo
   */
  public componentDidCatch(error: Error | null, errorInfo: ErrorInfo) {
    this.setState({ error })

    window.console.error(
      `Unhandled exception was caught in application's ErrorBoundary ${error}`
    )

    this.logToSentryInProduction(error, errorInfo)
  }

  private logToSentryInProduction(error: Error | null, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV === "production") {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key])
        })
        Sentry.captureException(error)
      })
    }
  }

  /**
   * Render function for ErrorBoundary component
   */
  public render() {
    if (!this.state.error) {
      return this.props.children
    }

    return <ErrorPage />
  }
}

export default ErrorBoundary
