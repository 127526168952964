import React, { useState } from "react"
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox
} from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { selectMembersThatCanChangePassword } from "../../modules/members/selectors"
import Button from "../Button"
import { buildName } from "../../shared/tools/strings"
import ChangeMemberPasswordDialog from "../ChangeMemberPasswordDialog"
import { changeClassPassword } from "../../modules/members/actions"

type Props = {
  onClose: () => void
}

const ChangeClassPasswordDialog = ({ onClose }: Props) => {
  const eligableMembers = useSelector(selectMembersThatCanChangePassword)
  const [selectedMembers, setSelectedMembers] = useState<number[]>([])
  const [showChangePassword, setShowChangePassword] = useState(false)
  const dispatch = useDispatch()
  const onCheckboxClick = (memberId: number) => {
    const isChecked = selectedMembers.some(selected => selected === memberId)
    setSelectedMembers(
      isChecked
        ? selectedMembers.filter(selected => selected !== memberId)
        : [...selectedMembers, memberId]
    )
  }

  const onChangePassword = (password: string) => {
    dispatch(changeClassPassword(selectedMembers, password))
    onClose()
  }

  const renderChangePassword = () => {
    return (
      <ChangeMemberPasswordDialog
        closeMethod={() => setShowChangePassword(false)}
        changeMethod={onChangePassword}
        name="valda elever"
      />
    )
  }

  const renderSelectMembers = () => {
    return (
      <>
        <DialogTitle id="byt-lösenord-för-flera-modal">Välj elever</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Endast elever som det går att byta lösenord på visas här.
          </DialogContentText>
          <List>
            {eligableMembers.map(member => (
              <ListItem
                button
                key={member.studliId}
                divider
                onClick={() => onCheckboxClick(member.studliId)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    onClick={() => onCheckboxClick(member.studliId)}
                    checked={selectedMembers.some(
                      selected => member.studliId === selected
                    )}
                  />
                </ListItemIcon>
                <ListItemText>
                  {buildName(member.firstName, member.lastName)}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text" color="primary">
            Avbryt
          </Button>
          <Button
            variant="text"
            disabled={!selectedMembers.length}
            color="primary"
            onClick={() => setShowChangePassword(true)}
          >
            Gå vidare
          </Button>
        </DialogActions>
      </>
    )
  }
  return (
    <>{showChangePassword ? renderChangePassword() : renderSelectMembers()}</>
  )
}

export default ChangeClassPasswordDialog
