import * as types from "./types"
import { createAction } from "../../shared/tools/redux"

export enum REQUEST {
  GET_BOOKS_REQUEST = "GET_BOOKS_REQUEST"
}

export enum RESPONSE {
  GET_BOOKS_RESPONSE = "GET_BOOKS_RESPONSE"
}

export enum SERVER_MESSAGE_ACTION {
  GET_BOOKS_REQUEST = "toc",
  GET_BOOKS_RESPONSE = "toc"
}

export enum UI {
  RESET_ASSIGNMENTS = "RESET_ASSIGNMENTS"
}

export const getBooks = createAction("GET_BOOKS_REQUEST")

export const addBooks = createAction<types.BooksPayload>("GET_BOOKS_RESPONSE")

export const resetBooks = createAction("RESET_BOOKS")
