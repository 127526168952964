import React from "react"
import { StyledTableToolbar, StyledTitle } from "./StyledTableToolbar"
import { booleanToString } from "../../shared/tools/strings"

type Props = {
  title: string
  totalItems?: number
  actions: any[]
  unselectedActions?: any[]
  numSelected: number
  selectedTitle: string
  onToggle?: Function
  filterComponent?: React.ReactNode
}

const TableToolbar = ({
  title,
  numSelected,
  totalItems,
  selectedTitle,
  filterComponent
}: Props) => {
  return (
    <StyledTableToolbar active={booleanToString(numSelected > 0)}>
      {title && (
        <>
          {numSelected > 0 ? (
            <StyledTitle variant="h6" component="h2">
              {numSelected} {selectedTitle}
            </StyledTitle>
          ) : (
            <StyledTitle variant="h6" component="h2">
              {title}{" "}
              {totalItems !== undefined && totalItems >= 0 && `(${totalItems})`}
            </StyledTitle>
          )}
        </>
      )}
      {filterComponent && filterComponent}
    </StyledTableToolbar>
  )
}

export default TableToolbar
