import * as React from "react"
import { StyledLoader, StyledLoadingDot } from "./StyledLoadingDots"

type Props = {
  numberOfDots?: number
  color?: string
}

const LoadingDots = ({ numberOfDots, color = "white" }: Props) => {
  const dotsCount = numberOfDots || 5
  const dots = []

  for (let i = 0; i < dotsCount; i++) {
    dots.push(<StyledLoadingDot key={i} color={color} />)
  }

  return <StyledLoader>{dots}</StyledLoader>
}

export default LoadingDots
