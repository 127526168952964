import { Members } from "../members/types"
import { FormativeTest, FormativeTests, TestParticipant } from "./types"

export const updateTestParticipants = (
  tests: FormativeTests,
  newMembers: Members
): FormativeTests => {
  return tests.map((test: FormativeTest) => {
    if (test.participantsInfo) {
      return {
        ...test,
        participantsInfo: test.participantsInfo.map(
          (participant: TestParticipant) => {
            const found = newMembers.find(
              memberAdded => memberAdded.studliId === participant.studliId
            )
            if (found && found.groups) {
              return {
                ...participant,
                groups: found.groups
              }
            }
            return participant
          }
        )
      }
    }
    return test
  })
}

export const removeMembersFromTests = (
  tests: FormativeTests,
  memberIds: number[]
): FormativeTests => {
  return tests.map((test: FormativeTest) => {
    if (test.participantsInfo) {
      return {
        ...test,
        participantsInfo: test.participantsInfo.filter(
          (participant: TestParticipant) =>
            !memberIds.includes(participant.studliId)
        )
      }
    }
    return test
  })
}
