import {
  UsersSearchResult,
  SearchExistingUsersAction,
  ReceiveExistingUsersAction,
  CreateUserAction,
  RemoveProductKeyAction,
  UpdateActiveProductsAction,
  ActivateProductKeysAction,
  GetActiveProductsAction,
  GetAuthUserInfoRequestAction,
  GetAuthUserInfoResponseAction,
  ReceiveActivateProductKeysResponseAction,
  ReceiveGetActiveProductsResponseAction,
  ProductKeys,
  ActiveProducts,
  ReceiveCreateUserResponseAction,
  ReceiveCreateUserFailureResponseAction,
  ValidateUsernameAction,
  ReceiveValidateUsernameResponseAction,
  ResetFoundUsersAction,
  UserRole,
  CreateUserData,
  GetUsersWithProductRequestAction,
  ReceiveGetUsersWithProductResponseAction,
  ResetUsersWithProductAction,
  GetUsersOnlineRequestAction,
  ReceiveGetUsersOnlineResponseAction,
  ResetUsersOnlineAction,
  ReceiveUsersOnlineUpdatedAction,
  ReceiveUsersWithProductUpdatedAction,
  GetStudentsFromClassroomsAction,
  ReceiveStudentsFromClassroomsResponseAction,
  ResetFoundClassroomsAction,
  ResetActiveProductsAction,
  UpdateUserSettingRequestAction,
  UpdateUserSettingResponseAction
} from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  SEARCH_EXISTING_USERS_REQUEST = "SEARCH_EXISTING_USERS_REQUEST",
  CREATE_USER_REQUEST = "CREATE_USER_REQUEST",
  ACTIVATE_PRODUCT_KEYS_REQUEST = "ACTIVATE_PRODUCT_KEYS_REQUEST", // TODO: shouldn't all the product-related stuff live in Products module instead of here... in Users?
  GET_ACTIVE_PRODUCTS_REQUEST = "GET_ACTIVE_PRODUCTS_REQUEST",
  VALIDATE_USERNAME_REQUEST = "VALIDATE_USERNAME_REQUEST",
  AUTH_USER_INFO_REQUEST = "AUTH_USER_INFO_REQUEST",
  GET_USERS_WITH_PRODUCT_REQUEST = "GET_USERS_WITH_PRODUCT_REQUEST",
  GET_USERS_ONLINE_REQUEST = "GET_USERS_ONLINE_REQUEST",
  GET_STUDENTS_FROM_CLASSROOMS_REQUEST = "GET_STUDENTS_FROM_CLASSROOMS_REQUEST",
  UPDATE_USER_SETTINGS_REQUEST = "UPDATE_USER_SETTING_REQUEST"
}
/**
 * Redux-actions, originating from server. As result from a client-request.
 */
export enum RESPONSE {
  SEARCH_EXISTING_USERS_RESPONSE = "SEARCH_EXISTING_USERS_RESPONSE",
  CREATE_USER_RESPONSE = "CREATE_USER_RESPONSE",
  CREATE_USER_FAILURE_RESPONSE = "CREATE_USER_FAILURE_RESPONSE",
  ACTIVATE_PRODUCT_KEYS_RESPONSE = "ACTIVATE_PRODUCT_KEYS_RESPONSE",
  GET_ACTIVE_PRODUCTS_RESPONSE = "GET_ACTIVE_PRODUCTS_RESPONSE",
  VALIDATE_USERNAME_RESPONSE = "VALIDATE_USERNAME_RESPONSE",
  AUTH_USER_INFO_RESPONSE = "AUTH_USER_INFO_RESPONSE",
  GET_USERS_WITH_PRODUCT_RESPONSE = "GET_USERS_WITH_PRODUCT_RESPONSE",
  GET_USERS_ONLINE_RESPONSE = "GET_USERS_ONLINE_RESPONSE",
  GET_STUDENTS_FROM_CLASSROOMS_RESPONSE = "GET_STUDENTS_FROM_CLASSROOMS_RESPONSE",
  UPDATE_USER_SETTINGS_RESPONSE = "UPDATE_USER_SETTING_RESPONSE"
}

/**
 * Strings for "action" property when communicating with server.
 * Request and Response use the same string.
 * (The "type" property is this module's name).
 */
export enum SERVER_MESSAGE_ACTION {
  SEARCH_EXISTING_USERS_REQUEST = "search",
  SEARCH_EXISTING_USERS_RESPONSE = "search",
  CREATE_USER_REQUEST = "create_user",
  CREATE_USER_RESPONSE = "create_user",
  ACTIVATE_PRODUCT_KEYS_REQUEST = "activate_product_keys", // "key" and "keys" is a temp. solution. When classrooms-code is implemented only "keys" will be used. Only this action-sting will be different we still send array of keys.
  ACTIVATE_PRODUCT_KEYS_RESPONSE = "activate_product_keys",
  ACTIVATE_PRODUCT_KEY_REQUEST = "activate_product_key",
  ACTIVATE_PRODUCT_KEY_RESPONSE = "activate_product_key",
  GET_ACTIVE_PRODUCTS_REQUEST = "get_active_products",
  GET_ACTIVE_PRODUCTS_RESPONSE = "get_active_products",
  VALIDATE_USERNAME_REQUEST = "check_username",
  VALIDATE_USERNAME_RESPONSE = "check_username",
  GET_AUTH_USER_INFO_REQUEST = "auth_user_info",
  GET_AUTH_USER_INFO_RESPONSE = "auth_user_info",
  GET_USERS_WITH_PRODUCT_REQUEST = "with_product",
  GET_USERS_WITH_PRODUCT_RESPONSE = "with_product",
  GET_USERS_ONLINE_REQUEST = "online",
  GET_USERS_ONLINE_RESPONSE = "online",
  GET_STUDENTS_FROM_CLASSROOMS_REQUEST = "get_students_from_classrooms",
  GET_STUDENTS_FROM_CLASSROOMS_RESPONSE = "get_students_from_classrooms",
  UPDATE_USER_SETTINGS_REQUEST = "settings",
  UPDATE_USER_SETTINGS_RESPONSE = "settings",

  USERS_ONLINE_UPDATED_EVENT = "online_updated",
  WITH_PRODUCT_UPDATED_EVENT = "with_product_updated",
  USERS_GROUP_ADDED_EVENT = "group_added",
  USERS_GROUP_REMOVED_EVENT = "group_removed",
  USERS_REMOVED_EVENT = "removed"
}

/**
 * Actions on UI only
 */
export enum UI {
  SET_VALIDATED_PRODUCT_KEYS = "SET_VALIDATED_PRODUCT_KEYS",
  REMOVE_PRODUCT_KEY = "REMOVE_PRODUCT_KEY",
  UPDATE_ACTIVE_PRODUCTS = "UPDATE_ACTIVE_PRODUCTS",
  RESET_FOUND_USERS = "RESET_FOUND_USERS",
  RESET_USERS_WITH_PRODUCT = "RESET_USERS_WITH_PRODUCT",
  RESET_USERS_ONLINE = "RESET_USERS_ONLINE",
  RESET_FOUND_CLASSROOMS = "RESET_FOUND_CLASSROOMS",
  RESET_ACTIVE_PRODUCTS = "RESET_ACTIVE_PRODUCTS"
}

/**
 * Redux-actions originating from server. Because of this or another client's actions.
 */
export enum EVENT {
  USERS_ONLINE_UPDATED_EVENT = "USERS_ONLINE_UPDATED_EVENT",
  WITH_PRODUCT_UPDATED_EVENT = "WITH_PRODUCT_UPDATED_EVENT",
  USERS_GROUP_ADDED_EVENT = "USERS_GROUP_ADDED_EVENT",
  USER_GROUP_REMOVED_EVENT = "USERS_GROUP_REMOVED_EVENT",
  USERS_REMOVED_EVENT = "USERS_REMOVED_EVENT"
}

/**
 * Queries server for existing users matching specified search-criteria.
 *
 * @param searchCriteria {string} - Username or e-mail
 */
export const searchExistingUser = (
  searchCriteria: string,
  role: UserRole
): SearchExistingUsersAction => ({
  type: REQUEST.SEARCH_EXISTING_USERS_REQUEST,
  payload: { search: searchCriteria, role: role }
})

/**
 * Receives users from server. (to put in "matching-existing-users-list")
 *
 * @param users {Users} - Received users.
 */
export const receiveSearchResult = (
  searchResult: UsersSearchResult
): ReceiveExistingUsersAction => {
  return {
    type: RESPONSE.SEARCH_EXISTING_USERS_RESPONSE,
    payload: searchResult
  }
}

/**
 * Requests creation of user, from specified data.
 *
 * @param newUserData {object} - firstName, LastName, username, password, email
 */
export const createUser = (newUserData: CreateUserData): CreateUserAction => ({
  type: REQUEST.CREATE_USER_REQUEST,
  payload: { user: newUserData }
})
export const receiveCreateUserSuccessResponse = (
  payload: ReceiveCreateUserResponseAction["payload"]
): ReceiveCreateUserResponseAction => ({
  type: RESPONSE.CREATE_USER_RESPONSE,
  payload: { user: payload.user, ssoError: payload.ssoError }
})
export const receiveCreateUserFailureResponse = (
  error: ReceiveCreateUserFailureResponseAction["payload"]["error"]
): ReceiveCreateUserFailureResponseAction => ({
  type: RESPONSE.CREATE_USER_FAILURE_RESPONSE,
  payload: { error }
})

export const activateProductKeys = (
  productKeys: ProductKeys,
  echo: "redirect" | "stay"
): ActivateProductKeysAction => ({
  type: REQUEST.ACTIVATE_PRODUCT_KEYS_REQUEST,
  payload: { keys: productKeys, echo }
})

export const receiveActivateProductKeysResponse = (
  payload: ReceiveActivateProductKeysResponseAction["payload"],
  echo: "redirect" | "stay"
): ReceiveActivateProductKeysResponseAction => {
  return {
    type: RESPONSE.ACTIVATE_PRODUCT_KEYS_RESPONSE,
    payload: payload,
    echo
  }
}

export const validateUsername = (username: string): ValidateUsernameAction => ({
  type: REQUEST.VALIDATE_USERNAME_REQUEST,
  payload: { username: username }
})

export const receiveValidateUsernameResponse = (
  payload: ReceiveValidateUsernameResponseAction["payload"]
): ReceiveValidateUsernameResponseAction => ({
  type: RESPONSE.VALIDATE_USERNAME_RESPONSE,
  payload: payload
})

/**
 * Get active products for this classroom's students.
 */
export const getActiveProducts = (): GetActiveProductsAction => ({
  type: REQUEST.GET_ACTIVE_PRODUCTS_REQUEST
})

/**
 * Receive response to get active products.
 *
 * @param payload {Object} - list of objects where each has a studliId and a list of product names.
 */
export const receiveGetActiveProductsResponse = (payload: {
  activeProducts: ActiveProducts
}): ReceiveGetActiveProductsResponseAction => ({
  type: RESPONSE.GET_ACTIVE_PRODUCTS_RESPONSE,
  payload
})

/**
 * Get authenticated user info. No payload required as JWT has the userId.
 */
export const getAuthUserInfoRequest = (): GetAuthUserInfoRequestAction => ({
  type: REQUEST.AUTH_USER_INFO_REQUEST
})
/**
 * Receive response about authenticated user.
 */
export const receiveGetAuthUserInfoResponse = (
  payload: GetAuthUserInfoResponseAction["payload"]
): GetAuthUserInfoResponseAction => ({
  type: RESPONSE.AUTH_USER_INFO_RESPONSE,
  payload
})

/**
 * Update setting for a user.
 */
export const updateUserSettingRequest = (
  studliId: number,
  setting:
    | "setting-show-welcome-back-banner"
    | "setting-show-tip-assignment"
    | "setting-show-tip-formative-test",
  value: boolean
): UpdateUserSettingRequestAction => {
  return {
    type: REQUEST.UPDATE_USER_SETTINGS_REQUEST,
    payload: {
      studliId,
      setting,
      value
    }
  }
}

export const receiveUpdateUserSettingResponse = (
  payload: UpdateUserSettingResponseAction["payload"]
): UpdateUserSettingResponseAction => ({
  type: RESPONSE.UPDATE_USER_SETTINGS_RESPONSE,
  payload
})

/**
 * Get all users that are online.
 */
export const getUsersOnline = (): GetUsersOnlineRequestAction => ({
  type: REQUEST.GET_USERS_ONLINE_REQUEST
})
/**
 * Receive response about users online.
 */
export const receiveGetUsersOnlineResponse = (
  payload: ReceiveGetUsersOnlineResponseAction["payload"]
): ReceiveGetUsersOnlineResponseAction => ({
  type: RESPONSE.GET_USERS_ONLINE_RESPONSE,
  payload
})

/**
 * Get all users that have the currently selected product activated, BE knows which.
 */
export const getUsersWithProduct = (): GetUsersWithProductRequestAction => ({
  type: REQUEST.GET_USERS_WITH_PRODUCT_REQUEST
})
/**
 * Receive response with users with current product active.
 */
export const receiveGetUsersWithProductResponse = (
  payload: ReceiveGetUsersWithProductResponseAction["payload"]
): ReceiveGetUsersWithProductResponseAction => ({
  type: RESPONSE.GET_USERS_WITH_PRODUCT_RESPONSE,
  payload
})

export const getUsersFromClassrooms = (
  payload: GetStudentsFromClassroomsAction["payload"]
): GetStudentsFromClassroomsAction => ({
  type: REQUEST.GET_STUDENTS_FROM_CLASSROOMS_REQUEST,
  payload
})

export const receiveUsersFromClassroomsResponse = (
  payload: ReceiveStudentsFromClassroomsResponseAction["payload"]
): ReceiveStudentsFromClassroomsResponseAction => ({
  type: RESPONSE.GET_STUDENTS_FROM_CLASSROOMS_RESPONSE,
  payload
})

export const removeProductKey = (studliId: number): RemoveProductKeyAction => ({
  type: UI.REMOVE_PRODUCT_KEY,
  payload: studliId
})

/**
 * Update info about active products for specified user.
 *
 * @param payload {object}
 */
export const updateActiveProducts = (payload: {
  studliId: number
  productName: string
}): UpdateActiveProductsAction => ({
  type: UI.UPDATE_ACTIVE_PRODUCTS,
  payload
})

export const resetFoundUsers = (): ResetFoundUsersAction => ({
  type: UI.RESET_FOUND_USERS
})

export const resetFoundUserClassrooms = (): ResetFoundClassroomsAction => ({
  type: UI.RESET_FOUND_CLASSROOMS
})

export const resetUsersWithProduct = (): ResetUsersWithProductAction => {
  return { type: UI.RESET_USERS_WITH_PRODUCT }
}

export const resetActiveProducts = (): ResetActiveProductsAction => {
  return { type: UI.RESET_ACTIVE_PRODUCTS }
}

export const resetUsersOnline = (): ResetUsersOnlineAction => {
  return { type: UI.RESET_USERS_ONLINE }
}

export const receiveUsersOnlineUpdatedAction = (
  payload: ReceiveUsersOnlineUpdatedAction["payload"]
): ReceiveUsersOnlineUpdatedAction => {
  return { type: EVENT.USERS_ONLINE_UPDATED_EVENT, payload }
}

export const receiveUsersWithProductUpdatedAction = (
  payload: ReceiveUsersWithProductUpdatedAction["payload"]
): ReceiveUsersWithProductUpdatedAction => {
  return { type: EVENT.WITH_PRODUCT_UPDATED_EVENT, payload }
}
