import { SelectOption } from "../../../components/Select/Select"
import { TableData } from "../../../components/GoalPageTable/common/types"
import { GoalsProgress } from "../../../modules/goalsProgress/types"
import { Members } from "../../../modules/members/types"
import { Goal, SampleExercises } from "../../../modules/goals/types"

type MenuItems = {
  1: { difficulty: number; label: string }
  2: { difficulty: number; label: string }
  3: { difficulty: number; label: string }
  [key: number]: { difficulty: number; label: string }
}

export const selecterOptions: SelectOption[] = [
  {
    id: "1",
    value: "lastName",
    label: "Namn"
  },
  {
    id: "2",
    value: "lastActive",
    label: "Status"
  },
  {
    id: "3",
    value: "goalExercisesCount",
    label: "Antal övningar"
  },
  {
    id: "4",
    value: "goalActiveTimeMilliseconds",
    label: "Aktiv tid"
  },
  {
    id: "5",
    value: "goalAbilityGrade",
    label: "Svårighetsnivå"
  }
]

export const filterProgress = (
  data: TableData[],
  progress: string,
  studLength?: number
): number => {
  if (progress === "done") {
    return data.filter((f: TableData) => !f.isInProgress).length
  } else if (progress === "inProgress") {
    return data.filter((f: TableData) => f.isInProgress).length
  } else if (progress === "notStarted") {
    return studLength
      ? studLength -
          data.filter((f: TableData) => f.isInProgress).length -
          data.filter((f: TableData) => !f.isInProgress).length
      : 0
  }
  return 0
}

export const collectTableData = (
  students: Members,
  studentProgress: GoalsProgress,
  goal: Goal,
  usersOnline: number[] | null
) => {
  return students
    .reduce((collectedData: TableData[], student) => {
      const res = studentProgress.find(
        progress =>
          progress.goalRef === goal.goalRef &&
          progress.studliId === student.studliId
      )
      return res
        ? [
            ...collectedData,
            {
              ...res,
              ...student,
              isOnline: usersOnline
                ? usersOnline.includes(student.studliId)
                : false
            }
          ]
        : collectedData
    }, [])
    .sort((first, second) => first.lastName.localeCompare(second.lastName))
}
export const calcAverageGrade = (studentData: TableData[]) => {
  const completedStudents = studentData.filter(student => !student.isInProgress)
  return completedStudents.length
    ? Math.round(
        completedStudents.reduce((acc, current) => {
          return acc + current.goalAbilityGrade
        }, 0) / completedStudents.length
      )
    : 0
}

export const renderMenuItems = (sampleExercises: SampleExercises) => {
  const menuItems: MenuItems = {
    1: { difficulty: 1, label: "Lätt" },
    2: { difficulty: 2, label: "Medel" },
    3: { difficulty: 3, label: "Svår" }
  }
  return Object.keys(sampleExercises).map(key => menuItems[+key])
}
