import * as React from "react"
import {
  StyledErrorBoundary,
  StyledErrorContent,
  StyledErrorColumn,
  StyledErrorHeading,
  StyledErrorText,
  StyledErrorTextContainer,
  StyledErrorButtonContainer,
  StyledCharacterImage
} from "./StyledErrorBoundary"
import Button from "../../components/Button"

export const ErrorPage: React.SFC = () => {
  /**
   * Reloads the current page
   */
  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <StyledErrorBoundary
      backgroundImage={
        "https://www.studentlitteratur.se/media/mr/story/images/mr_symboler_bg_noise_tile.jpg"
      }
    >
      <StyledErrorContent>
        <StyledErrorColumn>
          <StyledErrorTextContainer>
            <StyledErrorHeading>Oops!</StyledErrorHeading>
            <StyledErrorText>
              Något blev fel. Klicka på knappen för att försöka igen.
            </StyledErrorText>
          </StyledErrorTextContainer>
          <StyledErrorButtonContainer>
            {/* <PrimaryButton 
              overrideStyles={buttonStyles} 
              labelFirst={true}
              label={'Ladda om'}
              icon={<ReloadIcon color="#FFFFFF"  />}
              onClick={reloadPage}
              animation={'pulse'}
            /> */}
            <Button color="primary" variant="contained" onClick={reloadPage}>
              Ladda om
            </Button>
          </StyledErrorButtonContainer>
        </StyledErrorColumn>
        <StyledErrorColumn>
          <StyledCharacterImage
            src={
              "https://www.studentlitteratur.se/media/mr/story/images/_characters/Pim_ledsen_2.svg"
            }
          />
        </StyledErrorColumn>
      </StyledErrorContent>
    </StyledErrorBoundary>
  )
}
