import { createAction } from "../../shared/tools/redux"
import * as types from "./types"

/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_ASSIGNMENTS_PROGRESS_REQUEST = "GET_ALL_ASSIGNMENTS_PROGRESS_REQUEST"
}

export enum RESPONSE {
  GET_ALL_ASSIGNMENTS_PROGRESS_RESPONSE = "GET_ALL_ASSIGNMENTS_PROGRESS_RESPONSE"
}

export enum EVENT {
  ASSIGNMENTS_PROGRESS_UPDATE_EVENT = "ASSIGNMENTS_PROGRESS_UPDATE_EVENT",
  ASSIGNMENTS_TASK_PROGRESS_UPDATE_EVENT = "ASSIGNMENTS_TASK_PROGRESS_UPDATE_EVENT",
  ASSIGNMENTS_GOAL_PROGRESS_UPDATE_EVENT = "ASSIGNMENTS_GOAL_PROGRESS_UPDATE_EVENT"
}

export enum SERVER_MESSAGE_ACTION {
  GET_ALL_ASSIGNMENTS_PROGRESS_REQUEST = "progression",
  GET_ALL_ASSIGNMENTS_PROGRESS_RESPONSE = "progression",
  ASSIGNMENTS_PROGRESS_UPDATE_EVENT = "assignment_progression_updated",
  ASSIGNMENTS_TASK_PROGRESS_UPDATE_EVENT = "assignment_task_progression_updated",
  ASSIGNMENTS_GOAL_PROGRESS_UPDATE_EVENT = "assignment_goal_progression_updated"
}

export enum UI {
  RESET_ASSIGNMENTS_PROGRESS = "RESET_ASSIGNMENTS_PROGRESS",
  SET_ASSIGNMENTS_PROGRESS = "SET_ASSIGNMENTS_PROGRESS",
  CLEAR_ASSIGNMENTS_TASK_PROGRESS_FOR_ASSIGNMENT = "CLEAR_ASSIGNMENTS_TASK_PROGRESS_FOR_ASSIGNMENT"
}

export const getAllAssignmentsProgress =
  (): types.getAllAssignmentsProgressAction => ({
    type: REQUEST.GET_ALL_ASSIGNMENTS_PROGRESS_REQUEST
  })

export const receiveAssignmentsProgress = (
  payload: types.ReceiveProgressAction["payload"]
): types.ReceiveProgressAction => {
  return {
    type: RESPONSE.GET_ALL_ASSIGNMENTS_PROGRESS_RESPONSE,
    payload: {
      assignmentProgression: payload.assignmentProgression,
      assignmentTaskProgression: payload.assignmentTaskProgression,
      assignmentGoalProgression: payload.assignmentGoalProgression
    }
  }
}

export const receiveAssignmentsProgressUpdated = (
  assignmentProgression: types.AssignmentProgress
): types.ReceiveAssignmentsProgressUpdatedAction => {
  return {
    type: EVENT.ASSIGNMENTS_PROGRESS_UPDATE_EVENT,
    payload: { assignmentProgression }
  }
}

export const ReceiveAssignmentsGoalProgressUpdatedAction = (
  assignmentGoalProgression: types.AssignmentsGoalProgress
): types.ReceiveAssignmentsGoalProgressUpdatedAction => {
  return {
    type: EVENT.ASSIGNMENTS_GOAL_PROGRESS_UPDATE_EVENT,
    payload: { assignmentGoalProgression }
  }
}

export const receiveAssignmentsTaskProgressUpdated = (
  assignmentTaskProgression: types.AssignmentTaskProgress
): types.ReceiveAssignmentsTaskProgressUpdatedAction => {
  return {
    type: EVENT.ASSIGNMENTS_TASK_PROGRESS_UPDATE_EVENT,
    payload: { assignmentTaskProgression }
  }
}

export const setAssignmentsProgress = (
  progress: types.AssignmentProgress[]
): types.SetAssignmentsProgress => {
  return {
    type: UI.SET_ASSIGNMENTS_PROGRESS,
    payload: { progress }
  }
}

export const setAssignmentProgress = createAction<
  types.ReceiveProgressAction["payload"]
>("GET_ALL_ASSIGNMENTS_PROGRESS_RESPONSE")
export const resetAssignmentsProgress = createAction(
  "RESET_ASSIGNMENTS_PROGRESS"
)
export const updateAssignmentProgress = createAction<
  types.ReceiveAssignmentsProgressUpdatedAction["payload"]
>("assignment_progression_updated")
export const updateAssignmentGoalProgress = createAction<
  types.ReceiveAssignmentsGoalProgressUpdatedAction["payload"]
>("assignment_goal_progression_updated")
export const updateAssignmentProgressTask = createAction<
  types.ReceiveAssignmentsTaskProgressUpdatedAction["payload"]
>("assignment_task_progression_updated")
export const clearAssignmentsTaskForAssignment = createAction<
  types.ClearAssignmentsTaskForAssignmentAction["payload"]
>("CLEAR_ASSIGNMENTS_TASK_PROGRESS_FOR_ASSIGNMENT")
