import * as React from "react"
import { ListItemButton, styled } from "@mui/material"
import { ReactComponent as FolderIcon } from "../../assets/images/folder-closed.svg"
import { ReactComponent as FolderOpenIcon } from "../../assets/images/folder-open.svg"

import {
  StyledListItem,
  StyledListTreeItem,
  AssignmentCount,
  Title,
  StyledListItemIcon,
  StyledSubListItem,
  StyledAssignmentsIcon
} from "./StyledAssignmentImportDialog"

export interface Props {
  title: string
  items: number
  open?: boolean
  indent?: number
  onClick: () => void
}

export const ListTreeItem: React.FC<Props> = ({
  title,
  items,
  open,
  indent,
  onClick
}) => (
  <StyledListTreeItem indent={indent || 0}>
    <ListItemButton onClick={onClick}>
      <StyledListItemIcon>
        {open ? <FolderOpenIcon /> : <FolderIcon />}
      </StyledListItemIcon>
      <Title>{`${title} `}</Title>
      <AssignmentCount>({items})</AssignmentCount>
    </ListItemButton>
  </StyledListTreeItem>
)

export const ListTreeItemLeaf: React.FC<{
  title?: string
  indent: number
  onClick: () => void
}> = ({ title, indent, onClick }) => (
  <StyledListTreeItem indent={indent}>
    <ListItemButton onClick={onClick}>
      <StyledListItemIcon>
        <StyledAssignmentsIcon />
      </StyledListItemIcon>
      {title}
    </ListItemButton>
  </StyledListTreeItem>
)

export const ListItem: React.FC<Props> = ({ title, items, open, onClick }) => (
  <StyledListItem>
    <ListItemButton onClick={onClick}>
      <StyledListItemIcon>
        {open ? <FolderOpenIcon /> : <FolderIcon />}
      </StyledListItemIcon>
      <Title>{`${title} `}</Title>
      <AssignmentCount>({items})</AssignmentCount>
    </ListItemButton>
  </StyledListItem>
)

export const SubListItem: React.FC<{ title?: string; onClick: () => void }> = ({
  title,
  onClick
}) => {
  return (
    <StyledSubListItem>
      <ListItemButton onClick={onClick}>
        <StyledListItemIcon>
          <StyledAssignmentsIcon />
        </StyledListItemIcon>
        {title}
      </ListItemButton>
    </StyledSubListItem>
  )
}

export const NotFoundItem = styled(StyledListItem)`
  justify-content: center;
`

export default ListItem
