import { Button, FormControl, styled } from "@mui/material"
import { AssignmentWithStatus } from "../../modules/assignments/types"
type StatusTagProps = {
  status: AssignmentWithStatus["calculatedStatus"]
}

type ButtonProps = {
  marginleft: number
}

export const StyledStatusTag = styled("div")`
  color: ${(props: StatusTagProps) => getStatusTextColor(props.status)};
  background-color: ${(props: StatusTagProps) => getStatusColor(props.status)};
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 6px 2px 6px;
  display: inline-block;
`
export const StyledDivider = styled("div")`
  height: 50px;
`

const getStatusColor = (status: AssignmentWithStatus["calculatedStatus"]) => {
  switch (status) {
    case "ongoing":
      return "#038763"
    case "archived":
      return "#2C3E51"
    case "draft":
      return "#35209e"
    case "coming":
      return "#217CBB"
    case "passed":
      return "#D34420"
    case "finished":
      return "#006CC8"
    default:
      return "rgb(48,134,98)"
  }
}

const getStatusTextColor = (status: string) => {
  switch (status) {
    case "notStarted":
      return "#006CC8"
    case "started":
      return "white"
    default:
      return "white"
  }
}

export const StyledAssignmentsHeaderWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1em;
`

export const StyledButton = styled(Button as React.FC<any & ButtonProps>)`
  ${props => props.theme.breakpoints.up("sm")} {
    margin-right: 0.5em;
  }
  ${props => props.marginleft && "margin-left:1.5rem"}
  ${props => props.theme.breakpoints.down("xs")} {
    margin-bottom: 0.5em;
    margin-right: 0;
  }
`

export const StyledFormControl = styled(FormControl)`
  min-width: 11rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  ${props => props.theme.breakpoints.down("xs")} {
    margin-left: 1rem;
  }
`
export const StyledDownloadButton = styled(Button)`
  margin-left: 24px;
  margin-top: 12px;
`
