import { fromNullable, fold } from "fp-ts/lib/Option"
import { pipe } from "fp-ts/lib/pipeable"
import { Action as ReduxAction } from "redux"

export interface Action<Type, Payload> extends ReduxAction<Type> {
  payload: Payload
}

export interface ActionCreator<Payload> {
  (payload?: Payload): Action<string, Payload>
  toString(): string
}

export function createAction<Payload>(type: string): ActionCreator<Payload> {
  function actionFn(payload?: Payload): Action<typeof type, Payload> {
    return {
      type,
      payload: payload as Payload
    }
  }

  actionFn.toString = () => type

  return actionFn
}

export function createRequestAction<RequestPayload, ResponsePayload>(
  type: string
): {
  request: ActionCreator<RequestPayload>
  response: ActionCreator<ResponsePayload>
} {
  const requestType = `@@REQUEST/${type}`
  const responseType = `@@RESPONSE/${type}`

  return {
    request: createAction<RequestPayload>(requestType),
    response: createAction<ResponsePayload>(responseType)
  }
}

interface ChainableCase<State> {
  case<A extends ActionCreator<any>>(
    action: A,
    fn: (payload: ReturnType<A>["payload"]) => (state: State) => State
  ): ChainableCase<State>
}

interface FoldableBuilder<State> extends ChainableCase<State> {
  fold(): Handlers<State>
}

interface Handler<State> {
  <Payload>(state: State, payload: Payload): State
  action: ActionCreator<any>
}

interface Handlers<State> {
  [action: string]: Handler<State>
}

function createBuilder<State>(): FoldableBuilder<State> {
  let handlers: Handlers<State> = {}

  function foldFn() {
    return handlers
  }

  function caseFn<A extends ActionCreator<any>>(
    action: A,
    fn: (payload: ReturnType<A>["payload"]) => (state: State) => State
  ): FoldableBuilder<State> {
    function handler(state: State, payload: ReturnType<A>["payload"]) {
      return fn(payload)(state)
    }

    handler.action = action

    handlers = {
      ...handlers,
      [action.toString()]: handler
    }

    return {
      case: caseFn,
      fold: foldFn
    }
  }

  return {
    case: caseFn,
    fold: foldFn
  }
}

function createReducerFn<State>(
  handlers: Handlers<State>,
  initialState: State
) {
  return function reduce(
    state: State = initialState,
    action: ReturnType<ActionCreator<unknown>>
  ) {
    return pipe(
      fromNullable(handlers[action.type]),
      fold(
        () => state,
        f => f(state, action.payload)
      )
    )
  }
}

export function createReducer<State>(
  initialState: State,
  buildReducer: (builder: ChainableCase<State>) => ChainableCase<State>
) {
  const builder = createBuilder<State>()

  const handlers = (buildReducer(builder) as FoldableBuilder<State>).fold()

  return createReducerFn(handlers, initialState)
}
