import React from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { menuProps, StyledIconButton } from "./StyledPopupMenu"

type MenuItemProps = {
  option: string
  action: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export type MenuItems = MenuItemProps[]

type Props = {
  menuItems: MenuItems
  icon?: React.ReactNode
  id?: number
  disabled?: boolean
  children?: never
  iconColor?: string
  setAnchor?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const MoreMenu = ({
  disabled,
  menuItems,
  id,
  icon,
  iconColor,
  setAnchor
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchor && setAnchor(event)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: any) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleAction = (action: MenuItemProps["action"], event: any) => {
    event.stopPropagation()
    action(event)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <div>
      <StyledIconButton
        disabled={disabled}
        aria-label="More"
        aria-owns={open ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        iconcolor={iconColor}
      >
        {icon ? icon : <MoreVertIcon />}
      </StyledIconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={menuProps}
      >
        {menuItems.map(item => (
          <MenuItem
            key={item.option}
            onClick={event => handleAction(item.action, event)}
          >
            {item.option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default MoreMenu
