import {
  Button,
  Popover,
  Stack,
  List,
  ListItem,
  ListItemButton,
  Typography
} from "@mui/material"
import React, { useState } from "react"
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FilterValue } from "./types"
import CheckIcon from "@mui/icons-material/Check"
import { StyledFilterCount } from "./shared"

export type Filters = { label: string; value: any }[]

type Props = {
  filter: FilterValue[]
  valuesChecked: FilterValue[]
  selectLabel: string
  menuLabel?: string
  onFilterChange: (filter: FilterValue) => void
  filterKey: string
}

const FilterButton = ({
  filter,
  valuesChecked,
  selectLabel,
  menuLabel,
  onFilterChange,
  filterKey
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getAditionalText = () => {
    const checked = valuesChecked.filter(v => v.key === filterKey)
    if (checked.length === 0) {
      return ""
    }
    return (
      <StyledFilterCount textColor="#002443" bgcolor="#AACEED">
        {checked.length}
      </StyledFilterCount>
    )
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="outlined"
        sx={{ color: "black", borderColor: "black" }}
        onClick={handleClick}
        endIcon={<ArrowDownIcon />}
      >
        {selectLabel} {getAditionalText()}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <List disablePadding>
          {filter.map((f, i) => {
            const isChecked = valuesChecked.some(
              v => v.val === f.val && v.key === filterKey
            )
            return (
              <ListItem disablePadding key={f.val + f.label}>
                <ListItemButton
                  onClick={() => onFilterChange(f)}
                  sx={{ p: 2, pr: 4 }}
                >
                  <Stack direction="row" spacing={2}>
                    <CheckIcon
                      sx={{ visibility: isChecked ? "visible" : "hidden" }}
                    />
                    <Typography>{f.label}</Typography>
                  </Stack>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </Stack>
  )
}

export default FilterButton
