import {
  FormativeSession,
  FormativeTest,
  FormativeProgressionArr,
  FormativeProgressionAnswers
} from "../../modules/formative/types"
import moment from "moment"
import { menuItemActions } from "./constants"
import { ProgressForBar } from "../../components/FormativeQuestionsTable/types"

export const getLatestSession = (sessions: FormativeSession[]) => {
  return sessions.sort(
    (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
  )[0]
}

export const getFirstSession = (sessions: FormativeSession[]) => {
  return sessions.sort(
    (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
  )[0]
}

const OPTIONS_PER_STATUS_LIST = {
  [menuItemActions.START]: ["published"],
  [menuItemActions.STOP]: ["ongoing"],
  [menuItemActions.EDIT]: ["published", "draft"],
  [menuItemActions.DO_AGAIN]: ["stopped"],
  [menuItemActions.CREATE_COPY]: ["published", "ongoing", "stopped", "draft"],
  [menuItemActions.ACTIVATE]: ["archived"],
  [menuItemActions.FULLSCREEN]: ["published", "ongoing", "stopped", "archived"],
  [menuItemActions.ARCHIVE]: ["published", "stopped"],
  [menuItemActions.REMOVE]: ["archived", "draft"]
}

const OPTIONS_PER_STATUS_SINGLE = {
  [menuItemActions.START]: [],
  [menuItemActions.STOP]: [],
  [menuItemActions.EDIT]: ["published", "draft"],
  [menuItemActions.DO_AGAIN]: [],
  [menuItemActions.CREATE_COPY]: ["published", "ongoing", "stopped", "draft"],
  [menuItemActions.ACTIVATE]: ["archived"],
  [menuItemActions.FULLSCREEN]: [],
  [menuItemActions.ARCHIVE]: ["published", "stopped"],
  [menuItemActions.REMOVE]: ["archived", "draft"]
}

export const menuOptions = (
  test: FormativeTest,
  menuItemClick: Function,
  type: "list" | "single",
  isCreator?: boolean
) => {
  let options = getMenuOptions(
    menuItemClick,
    test.id,
    test.status,
    type,
    test.isPublic,
    isCreator
  )

  if (test.status === "published" && test.sessions.length > 1) {
    options = options.filter(option => option.option !== "Redigera")
  }

  if (test.status === "draft") {
    options = options.filter(option => option.option !== "Redigera")
  }

  return options
}

export const getMenuOptions = (
  menuItemClick: Function,
  formativeTestId: FormativeTest["id"],
  testStatus: FormativeTest["status"],
  type: "list" | "single",
  isPublic?: boolean,
  isCreator?: boolean
) => {
  const OPTIONS: { [key: string]: any } = {
    start: {
      action: () => menuItemClick(menuItemActions.START, formativeTestId),
      option: "Starta"
    },
    edit: {
      action: () => menuItemClick(menuItemActions.EDIT, formativeTestId),
      option: "Redigera"
    },
    createCopy: {
      action: () => menuItemClick(menuItemActions.CREATE_COPY, formativeTestId),
      option: "Skapa kopia"
    },
    fullscreen: {
      action: () => menuItemClick(menuItemActions.FULLSCREEN, formativeTestId),
      option: "Visa i helskärm"
    },
    remove: {
      action: () => menuItemClick(menuItemActions.REMOVE, formativeTestId),
      option: "Ta bort"
    },
    stop: {
      action: () => menuItemClick(menuItemActions.STOP, formativeTestId),
      option: "Avsluta"
    },
    doAgain: {
      action: () => menuItemClick(menuItemActions.DO_AGAIN, formativeTestId),
      option: "Gör igen"
    },
    activate: {
      action: () => menuItemClick(menuItemActions.ACTIVATE, formativeTestId),
      option: "Aktivera"
    },
    archive: {
      action: () => menuItemClick(menuItemActions.ARCHIVE, formativeTestId),
      option: "Arkivera"
    }
  } as const

  const whichOptions = Object.keys(
    type === "list" ? OPTIONS_PER_STATUS_LIST : OPTIONS_PER_STATUS_SINGLE
  ).filter(perStatus =>
    type === "list"
      ? OPTIONS_PER_STATUS_LIST[perStatus].some(status => status === testStatus)
      : OPTIONS_PER_STATUS_SINGLE[perStatus].some(
          status => status === testStatus
        )
  )
  let mappedOptions = whichOptions.map(option => OPTIONS[option])
  if (!isCreator) {
    mappedOptions = mappedOptions.filter(option => option.option !== "Ta bort")
  }

  if (isCreator) {
    mappedOptions.unshift({
      option: isPublic ? "Gör privat" : "Dela",
      action: () =>
        menuItemClick(menuItemActions.TOGGLE_PRIVATE, formativeTestId)
    })
  }
  return mappedOptions
}

export const getProgressForBar = (
  session: FormativeSession,
  progress: FormativeProgressionArr
) => {
  let progressForBar: ProgressForBar = {
    done: [],
    inProgress: [],
    notStarted: []
  }
  const testProgression = progress.filter(
    progressPost => progressPost.sessionId === session.id
  )
  testProgression.forEach(progressPost => {
    if (
      !session.members.some(sessMember => sessMember === progressPost.memberId)
    ) {
      return
    }
    if (progressPost.startedAt && progressPost.submittedAt) {
      progressForBar.done.push(progressPost.memberId)
      return
    }
    if (progressPost.startedAt && !progressPost.submittedAt) {
      progressForBar.inProgress.push(progressPost.memberId)
      return
    }
    progressForBar.notStarted.push(progressPost.memberId)
  })
  return progressForBar
}

export const parseAnswersForTest = (
  answers: FormativeProgressionAnswers,
  testId: number
) => {
  return answers
    .filter(answer => answer.testId === testId)
    .map(testAnswer => {
      return { ...testAnswer, answer: JSON.parse(testAnswer.answer) }
    })
}

export const getStatusString = (status: FormativeTest["status"]) => {
  switch (status) {
    case "published":
      return "Kommande"
    case "draft":
      return "Utkast"
    case "ongoing":
      return "Pågående"
    case "stopped":
      return "Avslutad"
    case "archived":
      return "Arkiverad"
  }
}

export const getTestStatusWeight = (status: FormativeTest["status"]) => {
  switch (status) {
    case "ongoing":
      return 2
    case "published":
      return 0
    case "stopped":
      return 3
    case "draft":
      return 1
    case "archived":
      return 4
  }
}
