import createClient, { AudioClient } from "./AudioClient"
import {
  useEffect,
  useRef,
  useReducer,
  useCallback,
  MutableRefObject
} from "react"
import getDuration from "./getDuration"

export interface Data {
  duration: number
  currentTime: number
  playing: boolean
  exactCurrentTime: number
}

type Action =
  | { type: "playing" }
  | { type: "stopping" }
  | { type: "update"; payload: { duration?: number; currentTime?: number } }

function reducer(state: Data, action: Action): Data {
  switch (action.type) {
    case "playing":
      return {
        ...state,
        playing: true
      }

    case "stopping":
      return {
        ...state,
        playing: false
      }

    case "update":
      return {
        ...state,
        currentTime: action.payload.currentTime
          ? Math.floor(action.payload.currentTime)
          : state.currentTime,
        duration: action.payload.duration
          ? Math.floor(action.payload.duration)
          : state.duration,
        exactCurrentTime: action.payload.currentTime || state.currentTime
      }

    default:
      return state
  }
}

function useAudio(
  src: string
): [Data, () => void, MutableRefObject<HTMLAudioElement>] {
  const client = useRef<AudioClient>()
  const player = useRef<HTMLAudioElement>()
  const [state, dispatch] = useReducer(reducer, {
    playing: false,
    duration: 0,
    currentTime: 0,
    exactCurrentTime: 0
  })

  const play = useCallback(() => {
    if (state.playing || !player.current) {
      // we are already playing or the player does not exist
      return
    }

    client.current && client.current.playing()
    const playPromise = player.current.play()

    if (playPromise) {
      playPromise.then(() => dispatch({ type: "playing" }))

      return
    }

    dispatch({ type: "playing" })
  }, [state.playing, player, client, dispatch])

  const stop = useCallback(() => {
    if (!state.playing) {
      // we are not playing
      return
    }

    if (player.current) {
      player.current.pause()
      player.current.currentTime = 0
    }

    dispatch({ type: "stopping" })
  }, [state.playing, player, dispatch])

  const updateData = useCallback(() => {
    if (!player.current) {
      return
    }

    const { currentTime } = player.current

    dispatch({
      type: "update",
      payload: {
        currentTime
      }
    })
  }, [player, dispatch])

  const onEnded = useCallback(() => {
    dispatch({ type: "stopping" })
    if (player.current) {
      player.current.currentTime = 0
    }
  }, [dispatch, player])

  useEffect(() => {
    // create a audio client
    if (!client.current) {
      client.current = createClient(stop)
    }

    return () => {
      if (!client.current) {
        return
      }

      client.current = client.current.destroy()
    }
  }, [stop, client])

  // handle duration
  useEffect(() => {
    async function asyncDuration() {
      const duration = await getDuration(src)

      dispatch({
        type: "update",
        payload: {
          duration
        }
      })
    }

    asyncDuration()
  }, [src, dispatch])

  useEffect(() => {
    const ref = player.current

    // start listen to when time updates
    if (ref) {
      ref.src = src

      ref.addEventListener("timeupdate", updateData)
      ref.addEventListener("ended", onEnded)
    }

    return () => {
      if (!ref) {
        return
      }

      ref.removeEventListener("timeupdate", updateData)
      ref.removeEventListener("ended", onEnded)
    }
  }, [player, dispatch, src, updateData, onEnded])

  return [
    state,
    () => (state.playing ? stop() : play()),
    player as MutableRefObject<HTMLAudioElement>
  ]
}

export default useAudio
