import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { APPLICATION_READY } from "../../modules/application/actions"
import {
  selectApplicationState,
  selectLatestAction
} from "../../modules/application/selectors"
import { RESPONSE as productActions } from "../../modules/products/actions"
import * as classroomActions from "../../modules/classroom/actions"
import { ApplicationState } from "../../modules/application/types"
import { createSelector } from "reselect"
import {
  selectExercisesCacheOrFetch,
  selectTocCacheOrFetch,
  selectNotificationsCacheOrFetch,
  selectActiveProductsCacheOrFetch,
  selectAssignmentsCacheOrFetch
} from "../../modules/router/dataSelectors"
import { ActionCreator } from "redux"

const actions = [
  APPLICATION_READY,
  productActions.CHANGE_ACTIVE_PRODUCT_RESPONSE,
  classroomActions.RESPONSE.CLASSROOM_INFO_RESPONSE,
  classroomActions.RESPONSE.CLASSROOM_CHANGE_GROUP_RESPONSE
]

const selectOnRefreshDataFetchingActions = createSelector(
  selectExercisesCacheOrFetch,
  selectNotificationsCacheOrFetch,
  selectAssignmentsCacheOrFetch,
  selectTocCacheOrFetch,
  selectActiveProductsCacheOrFetch,
  (...selectActions) => selectActions
)

function useOnLoadData() {
  const latestAction = useSelector(selectLatestAction)
  const applicationState = useSelector(selectApplicationState)
  const fetchActions = useSelector(selectOnRefreshDataFetchingActions)
  const dispatch = useDispatch()
  useEffect(() => {
    if (applicationState === ApplicationState.ready) {
      if (actions.some(a => a === latestAction)) {
        fetchActions
          .filter(Boolean)
          .forEach(a => dispatch((a as unknown as ActionCreator<unknown>)()))
      }
    }
  }, [latestAction, applicationState, fetchActions, dispatch])
}

export default useOnLoadData
