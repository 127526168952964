import { styled } from "@mui/material"

interface StyledErrorBoundaryProps {
  backgroundImage?: string
}

export const StyledErrorBoundary = styled("div")`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    ),
    url(${(props: StyledErrorBoundaryProps) => props.backgroundImage});
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;

  @media (-ms-high-contrast: none),
    (-ms-high-contrast: active) /* FIX IE 10 & 11 */ {
    *::-ms-backdrop {
      background: url(${(props: StyledErrorBoundaryProps) =>
          props.backgroundImage})
        repeat-x repeat-y;
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    );
  }
`

export const StyledErrorContent = styled("div")`
  display: flex;
  justify-content: center;
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  padding: 0 40px;
`

export const StyledErrorColumn = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 50%;
  font-size: 16px;

  @media (max-width: 800px), (max-height: 500px) {
    font-size: 12px;
  }
`

export const StyledErrorTextContainer = styled("div")`
  width: 100%;
  max-width: 600px;
  margin-bottom: 50px;
  color: ${props => props.theme.palette.primary.main};
`

export const StyledErrorHeading = styled("h1")`
  color: inherit;
  font-size: 10em;
  margin-bottom: 16px;
`

export const StyledErrorText = styled("p")`
  color: inherit;
  font-size: 2em;
  line-height: 1.4;
`

export const StyledErrorButtonContainer = styled("div")`
  flex-shrink: 1;

  svg {
    margin-left: 12px;
    width: 54px;
    height: auto;
  }
`

export const StyledCharacterImage = styled("img")`
  max-width: 100%;
  width: 400px;
  max-height: 100%;
  margin-left: 20px;
`
