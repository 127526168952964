import * as actions from "./actions"
import {
  State,
  GoalsActionTypes,
  GoalsActionTypesWithoutPayload,
  ReceiveGetAllGoalsProgressResponseAction,
  ReceiveGoalsProgressUpdatedAction,
  GoalsProgress,
  RemoveGoalsProgressForMember
} from "./types"

import * as membersActions from "../members/actions"
import * as membersTypes from "../members/types"
//import * as goalsActions from "../goals/actions" // for goal_removed
//import * as goalsTypes from "../goals/types" // for goal_removed

const initialState: State = {
  goalsProgress: []
}

const reducer = (
  state = initialState,
  action:
    | GoalsActionTypes
    | GoalsActionTypesWithoutPayload
    | membersTypes.MemberRemovedEventAction
): State => {
  switch (action.type) {
    case actions.RESPONSE.GET_ALL_GOALS_PROGRESS_RESPONSE:
      const receivedGoalsProgress: GoalsProgress = (
        action as ReceiveGetAllGoalsProgressResponseAction
      ).payload.goalsProgress

      return {
        ...state,
        goalsProgress: receivedGoalsProgress || []
      }

    case actions.UI.REMOVE_GOALS_PROGRESS_FOR_MEMBER:
      const memberIdToRemoveGoalsProgressFor = (
        action as RemoveGoalsProgressForMember
      ).payload.studliIds[0]

      const purgedGoalsProgress = removeGoalsProgressForMember(
        state,
        memberIdToRemoveGoalsProgressFor
      )

      return {
        ...state,
        goalsProgress: purgedGoalsProgress
      }

    case actions.EVENT.GOALS_PROGRESS_UPDATED_EVENT:
      const goalProgressUpdatedPayload = (
        action as ReceiveGoalsProgressUpdatedAction
      ).payload

      const newGoalsProgress = goalProgressUpdatedPayload.goalsProgress || []

      const updatedGoalsProgress = handleGoalsProgressUpdated(
        state,
        newGoalsProgress
      )

      return { ...state, goalsProgress: updatedGoalsProgress }

    case membersActions.EVENT.MEMBER_REMOVED_EVENT:
      const memberRemovedPayloadIds = (
        action as membersTypes.MemberRemovedEventAction
      ).payload

      const purgedGoalsMembers = state.goalsProgress.filter(
        goalProgress => !memberRemovedPayloadIds.includes(goalProgress.studliId)
      )

      return {
        ...state,
        goalsProgress: purgedGoalsMembers
      }

    case actions.UI.RESET_GOALS_PROGRESS:
      return { ...state, goalsProgress: [] }

    default:
      return state
  }
}

const removeGoalsProgressForMember = (state: State, memberId: number) =>
  state.goalsProgress.filter(goalProgress => goalProgress.studliId !== memberId)

const handleGoalsProgressUpdated = (
  state: State,
  newGoalsProgress: GoalsProgress
) => {
  const currentGoalsProgress = [...state.goalsProgress]

  newGoalsProgress.forEach(newGoalProgress => {
    const indexInCurrentGoalsProgress = currentGoalsProgress.findIndex(
      currentGoalProgress =>
        currentGoalProgress.goalRef === newGoalProgress.goalRef &&
        currentGoalProgress.studliId === newGoalProgress.studliId
    )

    if (indexInCurrentGoalsProgress !== -1) {
      const currentGoalProgress =
        currentGoalsProgress[indexInCurrentGoalsProgress]
      const currentGoalActiveTimeMilliseconds =
        currentGoalProgress.goalActiveTimeMilliseconds
      const currentGoalExercisesCount =
        currentGoalProgress.goalExercisesCount || 0

      currentGoalsProgress[indexInCurrentGoalsProgress] = {
        ...currentGoalProgress,
        ...newGoalProgress
      }

      if (
        currentGoalActiveTimeMilliseconds &&
        newGoalProgress.goalActiveTimeMilliseconds
      ) {
        currentGoalsProgress[
          indexInCurrentGoalsProgress
        ].goalActiveTimeMilliseconds =
          currentGoalActiveTimeMilliseconds +
          newGoalProgress.goalActiveTimeMilliseconds
      }

      if (newGoalProgress.goalActiveTimeMilliseconds) {
        currentGoalsProgress[indexInCurrentGoalsProgress].goalExercisesCount =
          currentGoalExercisesCount + 1
      }
    } else {
      currentGoalsProgress.push(newGoalProgress)
    }
  })

  return currentGoalsProgress
}

export default reducer
