import Avatar, { AvatarProps } from "@mui/material/Avatar"
import { styled, Theme } from "@mui/material/styles"

const size = 32 //px
const borderSize = size * 0.075 //px

const getTextAndBorderColor = (props: any) => {
  const theme: Theme = props.theme

  return props.color || theme.palette.text.secondary
}

const getCircleBackgroundColor = (props: any) => {
  const theme = props.theme.studli.classroom
  const bgColor =
    theme.themeTextColor === "white" ? "rgba(0,0,0,.3)" : "rgba(255,255,255,.4)"

  return props.color ? bgColor : "rgba(255,255,255,.4)"
}

export const StyledInitialsAvatar = styled(Avatar as React.FC<AvatarProps>)`
  background-color: ${props => getCircleBackgroundColor(props)};
  border: ${borderSize}px solid ${props => getTextAndBorderColor(props)};
  position: absolute;

  padding: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  color: ${props => getTextAndBorderColor(props)};
  font-size: ${size * 0.45}px;
`

export const StyledPictureAvatar = styled(Avatar as React.FC<AvatarProps>)`
  position: absolute;
  color: ${props => getTextAndBorderColor(props)};
  background-color: transparent;
  position: absolute;

  padding: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  user-select: none;
  color: ${props => getTextAndBorderColor(props)};
`

export const StyledOnlineIndicator = styled("div")`
  background-color: ${props => {
    return props.theme.studli.palette.onlineGreen
  }};
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: ${size * 0.3}px;
  height: ${size * 0.3}px;
`

export const StyledAvatarContainer = styled("div")`
  position: relative;
  max-width: ${size + borderSize * 2}px;
  min-width: ${size + borderSize * 2}px;
  height: ${size + borderSize * 2}px;
`
