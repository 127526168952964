import React, { useState, useEffect } from "react"
import {
  StyledProgress,
  StyledProgressBar,
  StyledLastActiveContainer,
  StyledProgressContainer
} from "./StyledFormativeResultProgress"
import Tooltip from "@mui/material/Tooltip"
import LastActive from "../LastActive"

const MIN_WIDTH = 10

export const PROGRESS_SEGMENT = {
  CORRECT: "correct",
  WRONG: "wrong",
  NOT_ANSWERED: "notAnswered"
}

type Props = {
  correct: number
  wrong: number
  notAnswered: number
  rating?: number
  displayType: "student" | "class"
  type: "rating" | "multiple"
  current: boolean
  endTime: string
  children?: never
  onOpenProgressDialog: Function
}

export type Widths = {
  correct: number
  wrong: number
  notAnswered: number
  rating: number
  noMembers: number
}

const FormativeResultProgress = ({
  correct,
  displayType,
  wrong,
  notAnswered,
  onOpenProgressDialog,
  type,
  endTime,
  rating = 0,
  current
}: Props) => {
  const total = correct + wrong + rating + notAnswered
  const [widths, setWidths] = useState<Widths>({
    correct: 0,
    wrong: 0,
    notAnswered: 0,
    rating: 0,
    noMembers: 100
  })

  useEffect(() => {
    calculateWidth()
    // eslint-disable-next-line
  }, [correct, wrong, notAnswered, rating])
  const calculateWidth = () => {
    const arrayOfBars = [
      { key: "correct", value: correct },
      { key: "wrong", value: wrong },
      { key: "rating", value: rating },
      { key: "notAnswered", value: notAnswered }
    ].sort((a, b) => a.value - b.value)
    let collectedWidths = { ...widths }
    let currentTotalWidth = 0
    arrayOfBars.forEach(bar => {
      let percentage = Math.round((bar.value / total) * 100)
      if (percentage + currentTotalWidth > 100) {
        percentage = 100 - currentTotalWidth
      }

      const returnWidth = bar.value
        ? percentage >= MIN_WIDTH
          ? percentage
          : MIN_WIDTH
        : bar.value

      currentTotalWidth += returnWidth
      collectedWidths = {
        ...collectedWidths,
        [bar.key]: returnWidth
      }
    })
    setWidths(collectedWidths)
  }

  const openProgressDialogIfMembers = (event: any) => {
    event.stopPropagation()
    if (canOpenProgressDialog) {
      onOpenProgressDialog()
    }
  }
  const canOpenProgressDialog = correct + wrong > 0

  return (
    <StyledProgressContainer>
      {!current && endTime && (
        <StyledLastActiveContainer>
          <LastActive date={endTime} />:
        </StyledLastActiveContainer>
      )}
      <StyledProgress
        current={current}
        clickable={canOpenProgressDialog}
        onClick={openProgressDialogIfMembers}
      >
        {total ? (
          <>
            <Tooltip
              title={
                displayType === "class"
                  ? `Antal elever som svarat ${
                      type === "multiple" ? "rätt" : ""
                    }`
                  : `Antal ${type === "multiple" ? "rätta" : ""} svar`
              }
            >
              <StyledProgressBar
                type={type === "rating" ? "rating" : "correct"}
                width={widths.correct}
              >
                {correct}
              </StyledProgressBar>
            </Tooltip>
            <Tooltip
              title={
                displayType === "class"
                  ? "Antal elever som svarat fel"
                  : "Antal fel svar"
              }
            >
              <StyledProgressBar type="wrong" width={widths.wrong}>
                {wrong}
              </StyledProgressBar>
            </Tooltip>
            <Tooltip title="Antal svar utan rättning">
              <StyledProgressBar type="rating" width={widths.rating}>
                {rating}
              </StyledProgressBar>
            </Tooltip>
            <Tooltip
              title={
                displayType === "class"
                  ? "Antal elever som ej svarat"
                  : "Antal frågor som inte är besvarade"
              }
            >
              <StyledProgressBar type="notAnswered" width={widths.notAnswered}>
                {notAnswered}
              </StyledProgressBar>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Inga elever i klassrummet med aktiverad produkt.">
            <StyledProgressBar
              type="notAnswered"
              width={widths.notAnswered}
            ></StyledProgressBar>
          </Tooltip>
        )}
      </StyledProgress>
    </StyledProgressContainer>
  )
}

export default FormativeResultProgress
