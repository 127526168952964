import React from "react"
import AddIcon from "@mui/icons-material/AddCircleOutline"
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline"
import {
  StyledIncrementerWrapper,
  StyledIncrementerAmount,
  StyledIncrementerButton
} from "./StyledCreateAssignmentForm"
type Props = {
  label: string
  min: number
  max: number
  increments: number
  value: number
  field: string
  setFunction: (field: string, value: number) => void
  disabled: boolean
}

export const NumberIncrementer = ({
  label,
  min,
  max,
  increments,
  value,
  setFunction,
  field,
  disabled
}: Props) => {
  return (
    <StyledIncrementerWrapper>
      <StyledIncrementerAmount>
        {value} {label}
      </StyledIncrementerAmount>
      <StyledIncrementerButton
        aria-label={`sänk till ${value - increments} ${label}`}
        disabled={value === min || disabled}
        onClick={() => setFunction(field, value - increments)}
      >
        <RemoveIcon />
      </StyledIncrementerButton>
      <StyledIncrementerButton
        aria-label={`höj till ${value + increments} ${label}`}
        disabled={value === max || disabled}
        onClick={() => setFunction(field, value + increments)}
      >
        <AddIcon />
      </StyledIncrementerButton>
    </StyledIncrementerWrapper>
  )
}

export default NumberIncrementer
