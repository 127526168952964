import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import "draft-js/dist/Draft.css"
import { DateRangePicker } from "react-dates"
import {
  Typography,
  Divider,
  Menu,
  MenuItem,
  Radio,
  Stack,
  FormControl,
  FormControlLabel,
  RadioGroup
} from "@mui/material"
import {
  StyledPaper,
  StyledButton,
  StyledButtonContainer,
  StyledPaperContent,
  StyledForm,
  StyledPaperActions,
  StyledErrorMessage,
  StyledStickerWrapper,
  StyledCurrentSticker,
  StyledSticker,
  StyledButtonWrapper,
  StyledAudioRecorderContainer,
  StyledPaperHeader,
  StyledThresholdWrapper,
  StyledDescriptionText,
  StyledRadioWrapper,
  StyledRadioText
} from "./StyledCreateAssignmentForm"
import FormikTextEditor from "../FormikTextEditor"
import {
  FormValues,
  PreviewValues
} from "../../pages/CreateEditAssignment/store/types"
import {
  Form,
  FormikProps,
  FormikValues,
  FormikErrors,
  FormikTouched,
  ErrorMessage
} from "formik"
import ChooseUsersDialogContent from "../../components/ChooseUsersDialogContent"
import Dialog from "../../components/Dialog"
import { USER_ROLE } from "../../modules/users/constants"
import { Members, Member } from "../../modules/members/types"
import { Users } from "../../modules/users/types"
import AssignmentDialogContent from "../AssignmentDialogContent"
import { AssignmentTask } from "../../modules/assignments/types"
import AssignmentTaskList from "../AssignmentTaskList"
import ChooseExercisesDialog from "../../components/ChooseExercisesDialog"
import {
  Exercise,
  ExerciseNode,
  ParentOrExerciseNode
} from "../../modules/exercises/types"
import ChooseStickerDialogContent from "../ChooseStickerDialogContent"
import { Stickers, Sticker, Features } from "../../modules/classroom/types"
import AssignmentsStudentsList from "../AssignmentStudentsList"
import { Product } from "../../modules/products/types"
import {
  findById,
  buildPathToExercise
} from "../../pages/Exercises/common/helpers"
import AudioRecorder from "../AudioRecorder"
import { selectTypeOfClassroom } from "../../modules/classroom/selectors"
import { setSnackbar } from "../../modules/application/actions"
import { createAssignmentMessages } from "../../pages/CreateEditAssignment/common/errorMessages"
import moment from "moment"
import { selectBooksTocLength } from "../../modules/book/selectors"
import PreviewModal from "../PrevieModal"
import LimitedTextField from "../FormativeQuestionsForm/LimitedTextField"
import GoalAssignmentList from "../GoalAssignmentList/GoalAssignmentList"
import ChooseGoalsDialog from "../ChooseGoalsDialog/ChooseGoalsDialog"
import { selectGoalsWithProgress } from "../../modules/goals/selectors"
import NumberIncrementer from "./NumberIncrementer"
import {
  selectCreateEditValidationError,
  selectCurrentAssignment
} from "../../modules/assignments/selectors"
import { ContentAdd } from "./types"
import InfoPopover from "../InfoPopover"
import { selectActiveProductTitle } from "../../modules/products/selectors"

type OwnProps = {
  members: Members
  exercises: Exercise
  setIsDirty: Function
  handleSaveAsDraft: (values: FormValues) => void
  stickers: Stickers
  activeProduct: Product
  classroomFeatures: Features
  noStudentsMessage: string
  selectGoalsForAdapt: boolean
  isCreator: boolean
  setSelectGoalsForAdapt: (val: boolean) => void
  handlePreview: (values: PreviewValues) => void
}

type Props = OwnProps & FormikProps<FormikValues>

const NOOP = () => {}

const CreateAssignmentForm = ({
  members,
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  dirty,
  setIsDirty,
  exercises,
  handleSaveAsDraft,
  stickers,
  activeProduct,
  noStudentsMessage,
  isSubmitting,
  isValidating,
  handlePreview,
  selectGoalsForAdapt,
  setSelectGoalsForAdapt,
  classroomFeatures,
  isCreator
}: Props) => {
  const [chooseExerciseDialogOpen, setChooseExerciseDialogOpen] =
    useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [chooseGoalsDialog, setChooseGoalsDialog] = useState(false)
  const [selectMembersDialogOpen, setSelectMembersDialogOpen] = useState(false)
  const [selectContentDialogType, setSelectContentDialogType] =
    useState<ContentAdd>(null)
  const [selectedAssignmentTask, setSelectedAssignmentTask] =
    useState<AssignmentTask | null>(null)
  const [selectStickerDialogOpen, setSelectStickerDialogOpen] = useState(false)
  const classroomType = useSelector(selectTypeOfClassroom)
  const booksLength = useSelector(selectBooksTocLength)
  const goals = useSelector(selectGoalsWithProgress)
  const assignment = useSelector(selectCurrentAssignment)
  const productTitle = useSelector(selectActiveProductTitle)
  const validationError = useSelector(selectCreateEditValidationError)
  const titleRef = useRef<HTMLDivElement>(null)
  const titleInputRef = useRef<HTMLInputElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const membersRef = useRef<HTMLDivElement>(null)
  const accessRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const disableAdaptEditing = Boolean(
    classroomType === "adapt" &&
      assignment &&
      assignment.status === "published" &&
      moment(assignment.startDate).isSameOrBefore(moment())
  )
  useEffect(() => {
    if (values.assignmentTasks.length && classroomType === "adapt") {
      setSelectGoalsForAdapt(true)
    }
  }, [values, classroomType, setSelectGoalsForAdapt])

  useEffect(() => {
    if (dirty) {
      setIsDirty(true)
    }
  }, [dirty, setIsDirty])

  useEffect(() => {
    if (isSubmitting && isValidating && errors) {
      scrollToFirstError()
    }
    // eslint-disable-next-line
  }, [isSubmitting, isValidating, errors])

  const setAudioForForm = async (
    filePath: string | undefined,
    name: string
  ) => {
    if (filePath) {
      setFieldValue(name, { ...values[name], audio: filePath })

      return
    }
    setFieldValue(name, { ...values[name], audio: "" })
  }

  const renderAudioRecorder = (type: string) => {
    const current = values[type]
    const currentAudio = current.audio ? current.audio : ""
    if (isCreator) {
      return (
        <AudioRecorder
          type="standalone"
          setAudioForForm={setAudioForForm}
          formType={type}
          editAudio={currentAudio}
          conversation={false}
        />
      )
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const toggleOfSelectAdaptGoals = () => {
    setFieldValue("assignmentTasks", [])
    setFieldValue("repetitionAmount", 20)
    setSelectGoalsForAdapt(false)
  }

  const scrollToFirstError = () => {
    if (errors["title"] && titleRef.current) {
      titleRef.current.scrollIntoView(true)
      dispatch(setSnackbar(createAssignmentMessages.TITLE_MISSING))
      if (titleInputRef.current) {
        titleInputRef.current.focus()
      }

      return
    }

    if (errors["assignmentTasks"] && contentRef.current) {
      contentRef.current.scrollIntoView(true)

      dispatch(
        setSnackbar(
          classroomType === "adapt"
            ? createAssignmentMessages.GOALS_MISSING
            : createAssignmentMessages.ASSIGNMENT_TASKS_MISSING
        )
      )

      return
    }

    if (errors["participants"] && membersRef.current) {
      membersRef.current.scrollIntoView(true)
      dispatch(setSnackbar(createAssignmentMessages.STUDENTS_MISSING))

      return
    }

    if (errors["isPublic"] && accessRef.current) {
      accessRef.current.scrollIntoView(true)
      dispatch(setSnackbar(createAssignmentMessages.ACCESS_MISSING))

      return
    }
  }

  const onTextEditorBlur = (name: string, value: any) => {
    setFieldValue(name, { ...values[name], text: value })
  }
  const showChangeStickerButton =
    (stickers.length > 1 && classroomType === "fnDill") ||
    classroomType === "adapt"

  const renderInformation = () => {
    return (
      <StyledPaper>
        <StyledPaperContent ref={titleRef}>
          <StyledPaperHeader variant="h2" display="block">
            Information
          </StyledPaperHeader>
        </StyledPaperContent>
        <Divider />
        <StyledPaperContent>
          <LimitedTextField
            inputRef={titleInputRef}
            name="title"
            label="Titel"
            maxLength={30}
            value={values.title}
            disabled={!isCreator}
            onChange={handleChange}
            helperText={buildInputHelperText(errors, touched, "title")}
            error={Boolean(showInputError(errors, touched, "title"))}
          />
        </StyledPaperContent>
        <Divider />
        {isCreator && (
          <StyledPaperContent>
            <FormikTextEditor
              name="instruction"
              ariaLabel="Instruktion"
              value={values.instruction.text}
              disabled={!isCreator}
              onBlurHandler={onTextEditorBlur}
              maxLength={500}
              placeholder={"Här kan du skriva en instruktion till eleverna"}
            />
            <StyledAudioRecorderContainer>
              {renderAudioRecorder("instruction")}
            </StyledAudioRecorderContainer>
          </StyledPaperContent>
        )}
        <Divider />
        {(classroomType === "fnDill" || classroomType === "adapt") && (
          <StyledPaperContent>
            <StyledStickerWrapper>
              <StyledSticker color={getCurrentSticker(values.stickerId).color}>
                <StyledCurrentSticker
                  alt={getCurrentSticker(values.stickerId).name}
                  src={getCurrentSticker(values.stickerId).path}
                />
              </StyledSticker>
              {showChangeStickerButton && (
                <StyledButtonWrapper>
                  {classroomType === "adapt" && (
                    <StyledDescriptionText variant="body1">
                      När eleven är klar med uppdraget får hen ett märke.
                    </StyledDescriptionText>
                  )}
                  <StyledButton
                    onClick={() => setSelectStickerDialogOpen(true)}
                    variant="outlined"
                    color="primary"
                    disabled={disableAdaptEditing || !isCreator}
                  >
                    {classroomType === "fnDill" ? "Byt bild" : "Byt märke"}
                  </StyledButton>
                </StyledButtonWrapper>
              )}
              <StyledErrorMessage>
                <ErrorMessage name="stickerId">
                  {msg => <div>{msg}</div>}
                </ErrorMessage>
              </StyledErrorMessage>
            </StyledStickerWrapper>
          </StyledPaperContent>
        )}
      </StyledPaper>
    )
  }

  const removeTask = (index: number) => {
    const tasks = [...values.assignmentTasks]
    tasks.splice(index, 1)
    setFieldValue("assignmentTasks", tasks)
  }

  const editTask = (index: number) => {
    const task = values.assignmentTasks[index]
    setSelectedAssignmentTask(task)
    switch (task.type) {
      case "book":
        setSelectContentDialogType("pagesInBook")
        break
      case "open-question":
        setSelectContentDialogType("openQuestion")
        break
      case "link":
        setSelectContentDialogType("externalLink")
        break
      case "exercise":
        setSelectContentDialogType("fromBook")
        break
    }
  }

  const handleRemoveTasks = (assignmentTasks: AssignmentTask[]) => {
    const newTasks = values.assignmentTasks.filter(
      (task: AssignmentTask) =>
        // eslint-disable-next-line
        !assignmentTasks.some(removedTask => removedTask.id == task.id)
    )
    setFieldValue("assignmentTasks", newTasks)
  }

  const removeGoal = (goalRef: string) => {
    setFieldValue(
      "assignmentTasks",
      values.assignmentTasks.filter((t: AssignmentTask) => t.link !== goalRef)
    )
  }

  const renderAssignmentTaskList = () => {
    return (
      <AssignmentTaskList
        classroomFeatures={classroomFeatures}
        editTask={editTask}
        removeTask={removeTask}
        setFieldValue={setFieldValue}
        editMode={isCreator}
        handleRemoveTasks={handleRemoveTasks}
        assignmentTasks={values.assignmentTasks}
        assignmentMembers={[]}
        assignmentsTaskProgress={[]}
        members={[]}
      />
    )
  }

  const renderAdaptTasksList = () => {
    return (
      <>
        <StyledPaperContent ref={contentRef}>
          <StyledPaperHeader variant="h2" display="block">
            Uppdragsmål
          </StyledPaperHeader>
        </StyledPaperContent>
        <Divider />
        <StyledPaperContent>
          <GoalAssignmentList
            selectedGoals={[]}
            onToggle={NOOP}
            tasks={values.assignmentTasks as AssignmentTask[]}
            goals={goals}
            inCreateList
            showRemove={isCreator}
            onRemove={removeGoal}
          />
          <StyledButton
            color="primary"
            variant="outlined"
            aria-label="välj mål"
            disabled={disableAdaptEditing || !isCreator}
            onClick={() => setChooseGoalsDialog(true)}
          >
            välj uppdragsmål
          </StyledButton>
        </StyledPaperContent>
        <Divider />
        <StyledPaperContent>
          <StyledDescriptionText>
            Hur stor andel av det eleverna räknar ska vara de mål du valt som
            uppdragsmål? Resterande andel räknar eleverna vidare på de mål de är
            på.
          </StyledDescriptionText>
          <NumberIncrementer
            min={20}
            max={80}
            label="%"
            disabled={disableAdaptEditing || !isCreator}
            field="repetitionAmount"
            setFunction={setFieldValue}
            increments={20}
            value={+values.repetitionAmount || 20}
          />
        </StyledPaperContent>
      </>
    )
  }

  const renderAddContent = () => {
    return (
      <StyledPaper>
        <StyledPaperContent ref={contentRef}>
          <StyledPaperHeader variant="h2" display="block">
            Innehåll
          </StyledPaperHeader>
        </StyledPaperContent>
        <Divider />
        {classroomType === "adapt" && (
          <>
            <StyledPaperContent>
              <StyledDescriptionText>
                Eleverna fortsätter att arbeta med målen i tur och ordning, i
                sin takt och på sin nivå i uppdraget. Välj om du vill lägga till
                specifika uppdragsmål som eleverna ska träna lite extra på.
              </StyledDescriptionText>
              <StyledRadioWrapper>
                <Radio
                  disabled={disableAdaptEditing || !isCreator}
                  checked={!selectGoalsForAdapt}
                  onClick={toggleOfSelectAdaptGoals}
                  color="primary"
                />
                <StyledRadioText>
                  Eleverna räknar vidare där de är
                </StyledRadioText>
              </StyledRadioWrapper>
              <StyledRadioWrapper>
                <Radio
                  disabled={disableAdaptEditing || !isCreator}
                  checked={selectGoalsForAdapt}
                  onClick={() => setSelectGoalsForAdapt(true)}
                  color="primary"
                />
                <StyledRadioText>Välj specifika uppdragsmål</StyledRadioText>
              </StyledRadioWrapper>
            </StyledPaperContent>
            <Divider />
          </>
        )}
        {classroomType === "adapt" &&
          selectGoalsForAdapt &&
          renderAdaptTasksList()}
        {values.assignmentTasks &&
          values.assignmentTasks.length > 0 &&
          classroomType !== "adapt" && (
            <>
              <StyledPaperContent>
                {renderAssignmentTaskList()}
              </StyledPaperContent>
              <Divider />
            </>
          )}
        <StyledPaperContent>
          {classroomType !== "adapt" && (
            <>
              <StyledButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                disabled={!isCreator}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  setAnchorEl(event.currentTarget)
                }
              >
                lägg till innehåll
              </StyledButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {exercises.length > 0 && (
                  <MenuItem onClick={() => setChooseExerciseDialogOpen(true)}>
                    Innehåll från läromedlet
                  </MenuItem>
                )}
                {classroomType === "dill" && (
                  <MenuItem
                    onClick={() => setSelectContentDialogType("externalLink")}
                  >
                    Extern länk
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => setSelectContentDialogType("openQuestion")}
                >
                  Öppen fråga
                </MenuItem>
                {booksLength > 0 && (
                  <MenuItem
                    onClick={() => setSelectContentDialogType("pagesInBook")}
                  >
                    Sidor i läromedlet
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
          <StyledErrorMessage>
            <ErrorMessage name="assignmentTasks">
              {msg => <div>{msg}</div>}
            </ErrorMessage>
          </StyledErrorMessage>
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const removeStudentFromAssignment = (id: Member["studliId"]) => {
    let studentsOnAssignment = [...values.participants]
    studentsOnAssignment = studentsOnAssignment.filter(
      studentId => studentId !== id
    )

    setFieldValue("participants", studentsOnAssignment)
  }

  const renderChosenStudentList = () => {
    const participants = assignment?.participantsInfo as unknown as Users
    let assignmentMembers
    if (participants) {
      assignmentMembers = [...members, ...participants].filter(
        (user, i, arr) =>
          user &&
          user.studliId &&
          i === arr.findIndex(u => u.studliId === user.studliId)
      )
    } else {
      assignmentMembers = members
    }
    return (
      <AssignmentsStudentsList
        members={assignmentMembers}
        memberIds={values.participants}
        removeStudentFromAssignment={removeStudentFromAssignment}
        editMode
        showMenu={!disableAdaptEditing}
        hasOnClick={false}
        assignmentId={0}
        validationError={validationError}
        progress={{ assignmentsProgress: [], assignmentsTaskProgress: [] }}
        onAssignmentStudentClick={() => null}
      />
    )
  }
  const checkIfStartDateIsMoreThan30Days = (day: any) => {
    if (focusedInput === "startDate") {
      return day.diff(moment(), "days") >= 30
    }
  }

  const renderChooseStudents = () => {
    const hasMembers = members.length > 0 || values.participants.length > 0

    const content = renderChosenStudentList()

    return (
      <StyledPaper>
        <StyledPaperContent ref={membersRef}>
          <StyledPaperHeader variant="h2" display="block">
            Elever
          </StyledPaperHeader>
        </StyledPaperContent>
        <Divider />
        {hasMembers ? (
          <>
            {values.participants.length > 0 && (
              <>
                <StyledPaperContent>
                  {content}
                  <StyledErrorMessage>
                    <ErrorMessage name="students">
                      {msg => <div>{msg}</div>}
                    </ErrorMessage>
                  </StyledErrorMessage>
                </StyledPaperContent>
                <Divider />
              </>
            )}
          </>
        ) : (
          <>
            <StyledPaperContent>
              <Typography variant="subtitle1">{noStudentsMessage}</Typography>
            </StyledPaperContent>
            <Divider />
          </>
        )}
        <StyledPaperActions>
          <StyledPaperContent>
            {hasMembers && (
              <StyledButton
                variant="outlined"
                color="primary"
                disabled={disableAdaptEditing}
                onClick={() => setSelectMembersDialogOpen(true)}
              >
                Välj elever
              </StyledButton>
            )}
            <StyledErrorMessage>
              <ErrorMessage name="members">
                {msg => <div>{msg}</div>}
              </ErrorMessage>
            </StyledErrorMessage>
          </StyledPaperContent>
        </StyledPaperActions>
      </StyledPaper>
    )
  }

  const renderChooseDate = () => {
    moment.locale("sv")
    return (
      <StyledPaper>
        <StyledPaperContent>
          <StyledPaperHeader variant="h2" display="block">
            {classroomType === "adapt" ? "Tidsperiod" : "Datum"}
          </StyledPaperHeader>
        </StyledPaperContent>
        <Divider />
        {classroomType === "adapt" && (
          <StyledThresholdWrapper>
            <StyledDescriptionText>
              Avgör hur länge eleverna ska räkna i uppdraget
            </StyledDescriptionText>
            <NumberIncrementer
              field="threshold"
              min={5}
              max={60}
              label="min"
              disabled={disableAdaptEditing}
              setFunction={setFieldValue}
              increments={5}
              value={+values.threshold || 20}
            />
          </StyledThresholdWrapper>
        )}
        <StyledPaperContent>
          <DateRangePicker
            startDateId="1"
            endDateId="2"
            startDatePlaceholderText="Börjar på"
            endDatePlaceholderText="Slutar"
            startDate={values.startDate}
            displayFormat="YYYY-MM-DD"
            disabled={disableAdaptEditing && "startDate"}
            endDate={values.endDate}
            onDatesChange={({ startDate, endDate }: any) => {
              if (startDate) {
                setFieldValue(
                  "startDate",
                  startDate.set({ hour: 0, minute: 0, second: 0 })
                )
              }

              setFieldValue(
                "endDate",
                endDate
                  ? endDate.set({ hour: 23, minute: 59, second: 59 })
                  : null
              )
            }}
            isOutsideRange={checkIfStartDateIsMoreThan30Days}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
          />
          <StyledErrorMessage>
            <ErrorMessage name="startDate">
              {msg => <div>{msg}</div>}
            </ErrorMessage>
            <ErrorMessage name="endDate">
              {msg => <div>{msg}</div>}
            </ErrorMessage>
          </StyledErrorMessage>
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const saveAsDraftHandler = () => {
    values.status = "draft"
    handleSaveAsDraft(values as FormValues)
  }

  const buildInputHelperText = (
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
    inputField: keyof FormValues,
    helpText?: string
  ) => {
    if (touched[inputField] && errors[inputField]) {
      return errors[inputField]
    } else {
      return helpText
    }
  }

  const showInputError = (
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
    inputField: keyof FormValues
  ) => errors[inputField] && touched[inputField]

  const renderButtons = () => {
    return (
      <StyledButtonContainer>
        {classroomType !== "adapt" && (
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={() => {
              const previewValues = values
              const currentSticker = getCurrentSticker(values.stickerId)
              if (currentSticker) {
                previewValues.stickerUrl = currentSticker.path
              }
              handlePreview(previewValues as PreviewValues)
            }}
          >
            Förhandsgranska
          </StyledButton>
        )}
        <StyledButton
          onClick={saveAsDraftHandler}
          variant="outlined"
          color="primary"
          disabled={values.status === "published"}
        >
          Spara som utkast
        </StyledButton>
        <StyledButton type="submit" variant="contained" color="primary">
          {values.status === "published" ? "Spara" : "Publicera"}
        </StyledButton>
      </StyledButtonContainer>
    )
  }

  const addSelectedMembers = (MembersToAdd: Users) => {
    const convertedMembers = MembersToAdd as Members
    setFieldValue(
      "participants",
      convertedMembers.map(member => member.studliId)
    )
    setSelectMembersDialogOpen(false)
  }

  const renderSelectMembersDialog = () => {
    const participants = assignment?.participantsInfo as unknown as Users
    let assignmentMembers
    if (participants) {
      assignmentMembers = [...members, ...participants].filter(
        (user, i, arr) =>
          user &&
          user.studliId &&
          i === arr.findIndex(u => u.studliId === user.studliId)
      )
    } else {
      assignmentMembers = members
    }
    const alreadyCheckedMembers = assignmentMembers.filter(member =>
      values.participants.some(
        (participant: number) => participant === member.studliId
      )
    )
    return (
      <Dialog
        open={selectMembersDialogOpen}
        onClose={() => setSelectMembersDialogOpen(false)}
        aria-modal="true"
        fullWidth
        maxWidth="md"
        aria-labelledby="choose-users-dialog-title"
        aria-describedby="choose-users-dialog-description"
      >
        <ChooseUsersDialogContent
          handleAdd={addSelectedMembers}
          handleCancel={() => setSelectMembersDialogOpen(false)}
          userRole={USER_ROLE.STUDENT}
          foundUsers={assignmentMembers}
          type="addToAssignment"
          alreadyCheckedUsers={alreadyCheckedMembers as Users}
        />
      </Dialog>
    )
  }

  const changeCurrentSticker = (id: Sticker["id"]) => {
    setFieldValue("stickerId", id)
    setSelectStickerDialogOpen(false)
  }

  const getCurrentSticker = (id: Sticker["id"]) => {
    const currentSticker = stickers.filter(
      (sticker: Sticker) => sticker.id === id
    )

    return currentSticker.length > 0 ? currentSticker[0] : stickers[0]
  }

  const renderSelectStickerDialog = () => {
    return (
      <Dialog
        open={selectStickerDialogOpen}
        maxWidth={"md"}
        onClose={() => setSelectStickerDialogOpen(false)}
        aria-modal="true"
        aria-labelledby="choose-sticker-dialog-title"
        aria-describedby="choose-sticker-dialog-description"
      >
        <ChooseStickerDialogContent
          stickers={stickers}
          currentSticker={getCurrentSticker(values.stickerId)}
          handleChange={changeCurrentSticker}
          handleCancel={() => setSelectStickerDialogOpen(false)}
        />
      </Dialog>
    )
  }

  const getEmptyAssignmentTask = (): AssignmentTask => {
    return {
      id: (Math.floor(Math.random() * 1000000000) + 1).toString(),
      type: "exercise",
      title: "",
      instruction: { text: "", audio: "" },
      answer: "",
      question: { text: "", audio: "" },
      pages: "",
      link: ""
    }
  }

  const addAssignmentTask = (newTask: AssignmentTask) => {
    const newAssignmentTask = { ...getEmptyAssignmentTask(), ...newTask }

    values.assignmentTasks = values.assignmentTasks
      ? values.assignmentTasks
      : []

    const newAssignmentTasks = [...values.assignmentTasks]

    const indexOfAssignmentTask = newAssignmentTasks.findIndex(
      assignmentTask => assignmentTask.id === newTask.id
    )
    if (indexOfAssignmentTask !== -1) {
      newAssignmentTasks[indexOfAssignmentTask] = newAssignmentTask
    } else {
      newAssignmentTasks.push(newAssignmentTask)
    }

    setFieldValue("assignmentTasks", newAssignmentTasks)
    setSelectedAssignmentTask(null)
    setSelectContentDialogType(null)
    setAnchorEl(null)
  }

  const addMultipleAssignmentTasks = (newTasks: AssignmentTask[]) => {
    values.assignmentTasks = values.assignmentTasks
      ? values.assignmentTasks
      : []

    setFieldValue("assignmentTasks", [...values.assignmentTasks, ...newTasks])

    setSelectContentDialogType(null)
    setAnchorEl(null)
  }

  const replaceAssignmentTasks = (newTasks: AssignmentTask[]) => {
    setFieldValue("assignmentTasks", [...newTasks])
  }

  const cancelDialogHandler = () => {
    setSelectContentDialogType(null)
    setSelectedAssignmentTask(null)
    setAnchorEl(null)
  }

  const cancelExerciseDialogHandler = () => {
    setChooseExerciseDialogOpen(false)
    setAnchorEl(null)
  }

  const renderIsPublic = () => {
    const handleToggleIsPublic = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFieldValue("isPublic", event.target.value === "true")
    }
    if (!isCreator) {
      return null
    }
    return (
      <StyledPaper>
        <StyledPaperContent ref={accessRef}>
          <Stack direction="row" alignItems="center">
            <StyledPaperHeader variant="h2" display="block">
              Inställning för åtkomst
            </StyledPaperHeader>
            <InfoPopover
              type="Info"
              content={
                <Stack>
                  <Typography fontWeight="bold">Ett delat uppdrag:</Typography>
                  <ul>
                    <li>{`visas för alla de lärare på skolan som har tillgång till läromedel som ingår i ${productTitle}`}</li>
                    <li>kan startas, avslutas etc av andra lärare på skolan</li>
                    <li>
                      kan bara den lärare som skapat uppdraget redigera
                      innehållet i
                    </li>
                  </ul>
                  <Typography fontWeight="bold">Ett privat uppdrag:</Typography>
                  <ul>
                    <li>
                      visas bara för den <i>lärare som skapat uppdraget</i>
                    </li>
                    <li>
                      kan bara den <i>lärare som skapat uppdraget</i> starta,
                      avsluta etc samt redigera innehållet i
                    </li>
                  </ul>
                </Stack>
              }
            />
          </Stack>
        </StyledPaperContent>
        <Divider />
        <StyledPaperContent>
          <Stack mt={2}>
            <FormControl>
              <RadioGroup
                value={values.isPublic}
                onChange={handleToggleIsPublic}
                aria-labelledby="choose-private-assignment"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Delat - tillgängligt även för andra lärare på skolan"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Privat - endast synligt/möjligt att hantera för mig själv"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </StyledPaperContent>
      </StyledPaper>
    )
  }

  const renderSelectContentDialog = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={selectContentDialogType !== null}
        onClose={cancelDialogHandler}
        aria-labelledby="choose-tasks-dialog-title"
        aria-describedby="choose-tasks-dialog-description"
      >
        {selectContentDialogType !== null && (
          <AssignmentDialogContent
            addAssignmentTask={addAssignmentTask}
            contentType={selectContentDialogType}
            handleCancel={cancelDialogHandler}
            assignmentTask={selectedAssignmentTask}
            activeProduct={activeProduct}
            addMultipleAssignmentTasks={addMultipleAssignmentTasks}
            getEmptyAssignmentTask={getEmptyAssignmentTask}
          />
        )}
      </Dialog>
    )
  }

  const exerciseDialogCallback = (
    checkedExercises: string[] | ExerciseNode
  ) => {
    setChooseExerciseDialogOpen(false)
    let exerciseList = []
    if (Array.isArray(checkedExercises)) {
      exerciseList = checkedExercises.map(
        (exerciseId: string): ExerciseNode => {
          return findById(exercises, exerciseId, "uniqueId")
        }
      )
    } else {
      exerciseList[0] = checkedExercises
    }
    const tasks = exerciseList
      .filter(
        exercise =>
          exercise.type &&
          !values.assignmentTasks.some(
            (task: AssignmentTask) => task.link === exercise.id
          )
      )
      .map((exercise: ExerciseNode): AssignmentTask => {
        let exercisePath = ""
        for (let i = 0; i < exercises.length; i++) {
          const path = buildPathToExercise(exercises[i], exercise.id)
          if (path.length) {
            path.splice(path.length - 1, 1)
            exercisePath = path
              .map((exercise: ParentOrExerciseNode) => exercise.title)
              .join(" / ")
            break
          }
        }
        const newTask: AssignmentTask = {
          ...getEmptyAssignmentTask(),
          type: exercise.type !== "link" ? "exercise" : "link",
          exercisePath,
          title: exercise.title,
          link: exercise.id,
          icon: exercise.type
        }
        newTask.instruction = JSON.stringify(newTask.instruction)
        newTask.question = JSON.stringify(newTask.question)

        return newTask
      })
    addMultipleAssignmentTasks(tasks)
  }

  const renderSelectExerciseDialog = () => {
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={chooseExerciseDialogOpen}>
        <ChooseExercisesDialog
          exercises={exercises}
          members={members}
          okDialogHandler={exerciseDialogCallback}
          cancelDialogHandler={cancelExerciseDialogHandler}
        />
      </Dialog>
    )
  }

  const renderSelectGoalsDialog = () => {
    return (
      <Dialog fullWidth={true} maxWidth="md" open={chooseGoalsDialog}>
        <ChooseGoalsDialog
          onSubmit={replaceAssignmentTasks}
          onCancel={() => setChooseGoalsDialog(false)}
          goals={goals}
          maxSelect={10}
          members={members}
          alreadyAddedGoals={values.assignmentTasks}
        />
      </Dialog>
    )
  }

  return (
    <React.Fragment>
      <StyledForm>
        <Form>
          {renderInformation()}
          {renderAddContent()}
          {renderChooseStudents()}
          {renderChooseDate()}
          <Divider />
          {renderIsPublic()}
          {renderButtons()}
          {renderSelectStickerDialog()}
          {renderSelectMembersDialog()}
          {renderSelectContentDialog()}
          {renderSelectGoalsDialog()}
          {renderSelectExerciseDialog()}
        </Form>
      </StyledForm>
      <PreviewModal />
    </React.Fragment>
  )
}

export default CreateAssignmentForm
