import React from "react"
import ConfirmationDialog from "../ConfirmationDialog"
import { useSelector } from "react-redux"
import {
  selectCanLeave,
  selectIsModalOpen,
  selectModalMsg
} from "./store/selectors"
import Dialog from "../../components/Dialog"

function ConfirmBeforeLeaveDialog() {
  const handleCloseModal = useSelector(selectCanLeave)
  const modalState = useSelector(selectIsModalOpen)
  const modalMsg = useSelector(selectModalMsg)
  return (
    <Dialog
      aria-labelledby="dialog-popup-title"
      fullScreen={false}
      maxWidth="sm"
      open={modalState}
    >
      <ConfirmationDialog
        title="Vill du verkligen lämna sidan?"
        body={modalMsg}
        onCancel={() => handleCloseModal(false)}
        confirmLabel="LÄMNA SIDAN"
        cancelLabel="STANNA KVAR PÅ SIDAN"
        onConfirm={() => handleCloseModal(true)}
        ariaTitleId="dialog-popup-title"
        ariaDescId="dialog-description"
      />
    </Dialog>
  )
}

export default ConfirmBeforeLeaveDialog
