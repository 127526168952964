import { Box, Chip, Grid, styled, Tooltip, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { Group } from "../../modules/classroom/types"

const StyledGroupChip = styled(Chip, {
  shouldForwardProp: prop => prop !== "bColor" && prop !== "textColor"
})<{ bColor: string; textColor: string }>`
  border-color: ${props => props.textColor};
  color: ${props => props.textColor};
  background-color: ${props => props.bColor};
`

const GroupChip = ({
  groups,
  truncate
}: {
  groups: Group[] | undefined
  truncate?: boolean
}): JSX.Element => {
  const groupColors = useCallback((group: Group) => {
    const colors = {
      bColor: "white",
      textColor: "black"
    }
    switch (group.color) {
      case "turquoise":
        return { bColor: "#D4FCF4", textColor: "#0A3F35" }
      case "lightPurple":
        return { bColor: "#E4DCF9", textColor: "#432B5E" }
      case "yellow":
        return { bColor: "#FCE4B4", textColor: "#7B5437" }
      case "orange":
        return { bColor: "#F0C9AD", textColor: "#AA251F" }
      case "lightRed":
        return { bColor: "#F9DAD7", textColor: "#AA251F" }
      case "lightBlue":
        return { bColor: "#E3ECFE", textColor: "#4160B9" }
      case "darkGreen":
        return { bColor: "#0A3F35", textColor: "#D4FCF4" }
      case "purple":
        return { bColor: "#432B5E", textColor: "#E4DCF9" }
      case "blue":
        return { bColor: "#4160B9", textColor: "#E3ECFE" }
      case "red":
        return { bColor: "#AA251F", textColor: "#F9DAD7" }
      default:
        return colors
    }
  }, [])

  const firstGroup = useMemo(
    () => (groups && groups.length > 0 ? groups[0] : undefined),
    [groups]
  )

  const getTruncatedGroups = (truncGroups?: Group[]) => {
    if (truncGroups && truncGroups.length > 1) {
      const g = truncGroups.slice(1, truncGroups.length)
      return (
        <Grid item>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#606060"
                }
              }
            }}
            title={
              <Box>
                {g.map((gr, i) => (
                  <Typography key={gr.id} fontSize="0.6875rem">
                    {gr.name + (i !== g.length - 1 ? "," : "")}
                  </Typography>
                ))}
              </Box>
            }
          >
            <StyledGroupChip
              label={`+${g.length}`}
              bColor="#606060"
              textColor="white"
            />
          </Tooltip>
        </Grid>
      )
    }
    return null
  }

  const getNonTruncatedGroups = (nonTruncGroups?: Group[]) => {
    if (nonTruncGroups && nonTruncGroups.length > 1) {
      const g = nonTruncGroups.slice(1, nonTruncGroups.length)

      return g.map(group => (
        <Grid item key={group.id}>
          <StyledGroupChip
            label={group.name}
            bColor={groupColors(group).bColor}
            textColor={groupColors(group).textColor}
          />
        </Grid>
      ))
    }
    return null
  }

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      {firstGroup && (
        <Grid item>
          <StyledGroupChip
            key={firstGroup.id}
            label={firstGroup.name}
            bColor={groupColors(firstGroup).bColor}
            textColor={groupColors(firstGroup).textColor}
          />
        </Grid>
      )}
      {truncate ? getTruncatedGroups(groups) : getNonTruncatedGroups(groups)}
    </Grid>
  )
}

export default GroupChip
