import { IconButton, styled } from "@mui/material"

export const StyledClassroomCode = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: ${props => props.theme.studli.classroom.themeColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const StyledClassroomTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const StyledClassroomInfo = styled("p")`
  color: white;
  font-size: 1.7rem;
`

export const StyledClassroomCodeText = styled("p")`
  color: white;
  font-size: 5rem;
  margin: 0;
`
export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
