import * as React from "react"

import Dialog from "../Dialog/Dialog"
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  List,
  Typography,
  Stack,
  Button,
  IconButton,
  ListItemAvatar,
  Checkbox
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { ChevronLeft } from "@mui/icons-material"
import { selectAllImportableTests } from "../../modules/formative/selectors"
import {
  FormativeTestStatuses,
  ImportableTestClassroom
} from "../../modules/formative/types"
import { StyledStatusTag } from "../../pages/FormativeQuestions/FormativeStatusTag"
import { importTests } from "../../modules/formative/actions"

export interface Props {
  onClose: () => void
}

const FormativeImportDialog: React.FC<Props> = ({ onClose }): JSX.Element => {
  const classrooms = useSelector(selectAllImportableTests)
  const dispatch = useDispatch()
  const [selectedClassroom, setSelectedClassroom] =
    React.useState<ImportableTestClassroom | null>(null)
  const [selectedTests, setSelectedTests] = React.useState<number[]>([])

  const onImport = () => {
    dispatch(importTests({ formativeTestIds: selectedTests }))
    onClose()
  }

  const onToggleAssignment = (id: number) => {
    if (selectedTests.includes(id)) {
      setSelectedTests(selectedTests.filter(t => t !== id))
    } else {
      setSelectedTests([...selectedTests, id])
    }
  }

  const translateStatus = (status: FormativeTestStatuses) => {
    switch (status) {
      case "archived":
        return "Arkiverat"
      case "ongoing":
        return "Pågående"
      case "stopped":
        return "Avslutat"
      case "published":
        return "Kommande"
      case "draft":
        return "Utkast"
      default:
    }
  }

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        {selectedClassroom ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={() => setSelectedClassroom(null)}
              sx={{ color: "black" }}
            >
              <ChevronLeft height="2rem" width="2rem" />
            </IconButton>
            <span>{selectedClassroom.title}</span>
          </Stack>
        ) : (
          "Importera test"
        )}
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Stack px={3}>
          <Typography>
            {!selectedClassroom
              ? "Välj klassrum att importera ifrån"
              : "Välj test att importera"}
          </Typography>
        </Stack>
        {!selectedClassroom ? (
          <List>
            {classrooms.map(c => (
              <ListItem disablePadding key={c.id} divider>
                <ListItemButton
                  sx={{ height: "4rem" }}
                  onClick={() => setSelectedClassroom(c)}
                >
                  {c.title}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <List>
            {selectedClassroom.formativeTests.map(({ id, title, status }) => {
              return (
                <ListItem disablePadding divider key={id} button>
                  <ListItemButton
                    sx={{ height: "4rem" }}
                    onClick={() => onToggleAssignment(id)}
                  >
                    <ListItemAvatar>
                      <Checkbox
                        checked={selectedTests.includes(id)}
                        onClick={() => onToggleAssignment(id)}
                      />
                    </ListItemAvatar>
                    <Stack direction="row" spacing={2}>
                      <Typography>{title}</Typography>
                      <StyledStatusTag status={status}>
                        {translateStatus(status)}
                      </StyledStatusTag>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Avbryt</Button>
        <Button
          onClick={onImport}
          disabled={selectedTests.length === 0}
          color="primary"
        >
          Välj
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormativeImportDialog
