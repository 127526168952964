import React, { useState, useEffect } from "react"
import {
  FormativeQuestion,
  QuestionAlternative
} from "../../modules/formative/types"
import {
  StyledQuestionListItem,
  StyledQuestionText,
  StyledQuestionAnswer,
  StyledCheckbox,
  StyledCommonErrorsButton,
  StyledHideAnswerButton,
  StyledInfoIcon,
  StyledCommonErrorContainer,
  StyledChapterText,
  StyledChapterGrid,
  StyledDraggableIcon,
  StyledAnswerGrid,
  StyledFormativeTaskImage,
  StyledFormativeAnswerImage
} from "./StyledFormativeQuestionsListItem"
import { Grid, Popover } from "@mui/material"
import HtmlRenderer from "../HtmlRenderer"
import MathJax from "react-mathjax"
import MoreMenu from "../PopupMenu"
import AudioPlayer from "../AudioPlayer"

type Props = {
  question: FormativeQuestion
  isChecked: boolean
  onChecked: (question: FormativeQuestion) => void
  marginLeft: boolean
  editMode: boolean
  onRemove?: (question: FormativeQuestion) => void
  index: number
  showCheckbox?: boolean
  isEditDisabled?: boolean
}

const FormativeQuestionsListItem = ({
  question,
  isChecked,
  onChecked,
  marginLeft,
  editMode,
  onRemove,
  index,
  isEditDisabled,
  showCheckbox = true
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showAnswer, setShowAnswer] = useState<boolean>(true)

  useEffect(() => {
    if (editMode) {
      setShowAnswer(false)
    }
    // eslint-disable-next-line
  }, [])

  const renderMathBlock = (block: string, index: number) => {
    const parts = block
      .replace("$$", "$")
      .split("$")
      .map((part, i) =>
        i % 2 === 0 ? (
          part === "" ? null : (
            <HtmlRenderer key={i} html={part} />
          )
        ) : (
          <MathJax.Node key={i} inline formula={part} />
        )
      )
    return <MathJax.Provider key={index}>{parts}</MathJax.Provider>
  }

  const renderAnswer = (answer: QuestionAlternative, answerIndex: number) => {
    return (
      <StyledQuestionAnswer variant="body2" correct={answer.points}>
        {answer.main.map((answerText, index) => (
          <>
            {answerText.text && (
              <HtmlRenderer key={answerText.text} html={answerText.text} />
            )}
            {answerText.image && (
              <StyledFormativeAnswerImage
                src={answerText.image}
                alt="fråga-svar"
              />
            )}
            {answerText.mediaplayer &&
              answerText.mediaplayer.type === "audio" && (
                <AudioPlayer
                  src={answerText.mediaplayer.src}
                  label="Fråga-svar-ljud"
                />
              )}
            {answerText.math && renderMathBlock(answerText.math, index)}
          </>
        ))}
      </StyledQuestionAnswer>
    )
  }

  const renderCommonErrors = () => {
    return (
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        anchorEl={anchorEl}
      >
        <StyledCommonErrorContainer>
          {question.content.commonErrors.map((error, index) => (
            <>
              {error.text && (
                <HtmlRenderer key={error.text} html={error.text} />
              )}
              {error.math && renderMathBlock(error.math, index)}
            </>
          ))}
        </StyledCommonErrorContainer>
      </Popover>
    )
  }

  const handleOpenCommonError = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  return (
    <StyledQuestionListItem button divider>
      {!editMode && showCheckbox && (
        <StyledCheckbox
          margin_left={marginLeft ? 1 : 0}
          checked={isChecked}
          aria-label={`${question.folder} ${question.category} fråga nummer ${
            index + 1
          }`}
          onClick={() => onChecked(question)}
        />
      )}
      <Grid container spacing={3}>
        {editMode && !isEditDisabled && (
          <StyledChapterGrid item xs={12}>
            <StyledDraggableIcon aria-label="Drag and drop" />
            <StyledChapterText>{`${question.category} / ${question.folder}`}</StyledChapterText>
          </StyledChapterGrid>
        )}
        <Grid item xs={12}>
          {question.content.task.map((task, index) => (
            <>
              {task.text && (
                <StyledQuestionText variant="body1" key={task.text}>
                  <HtmlRenderer html={task.text} />
                </StyledQuestionText>
              )}
              {task.image && (
                <StyledFormativeTaskImage src={task.image} alt="fråga-bild" />
              )}
              {task.mediaplayer && task.mediaplayer.type === "audio" && (
                <AudioPlayer
                  src={task.mediaplayer.src}
                  label="Fråga-svar-ljud"
                />
              )}
              {task.math && (
                <StyledQuestionText variant="body1" key={task.math}>
                  {renderMathBlock(task.math, index)}
                </StyledQuestionText>
              )}
            </>
          ))}
        </Grid>
        {editMode && (
          <Grid item xs={12}>
            {" "}
            <StyledHideAnswerButton onClick={() => setShowAnswer(!showAnswer)}>
              {showAnswer ? "Dölj svar" : "Visa svar"}
            </StyledHideAnswerButton>
          </Grid>
        )}
        {question.content.alternatives.map((answer, answerIndex) => (
          <StyledAnswerGrid
            show={showAnswer ? 1 : 0}
            key={answerIndex}
            item
            xs={12}
            sm={3}
          >
            {renderAnswer(answer, answerIndex)}
          </StyledAnswerGrid>
        ))}
        {question.content.commonErrors && showAnswer && (
          <Grid item xs={12}>
            <StyledCommonErrorsButton onClick={handleOpenCommonError}>
              <StyledInfoIcon />
              {question.content.settings.nameError ?? "Vanliga fel"}
            </StyledCommonErrorsButton>
            {renderCommonErrors()}
          </Grid>
        )}
      </Grid>
      {editMode && onRemove && !isEditDisabled && (
        <MoreMenu
          menuItems={[{ option: "Ta bort", action: () => onRemove(question) }]}
        />
      )}
    </StyledQuestionListItem>
  )
}

export default FormativeQuestionsListItem
