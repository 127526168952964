import { styled } from "@mui/material"

const size = 32 //px
const borderSize = size * 0.075 //px

export const StyledAvatarGroupContainer = styled("div")`
  display: flex;
  margin-top: 0.5em;
`

export const StyledFullIndicator = styled("div")`
  position: relative;
  max-width: ${size + borderSize * 2}px;
  min-width: ${size + borderSize * 2}px;
  height: ${size + borderSize * 2}px;
  border-radius: 50%;
  background-color: #eeeeee;
  color: #6f6f6f;
  display: flex;
  justify-content: center;
  align-items: center;
`
