import { styled } from "@mui/material"

import Table from "@mui/material/Table"
import TableCell, { TableCellProps } from "@mui/material/TableCell"
import TableHead, { TableHeadProps } from "@mui/material/TableHead"
import { Theme } from "@mui/material/styles"
import TableRow from "@mui/material/TableRow"

type LegendBoxStyles = {
  segment: string
}

export const StyledTable = styled(Table as React.FC<TableCellProps>)``

export const StyledHeadCell = styled(TableCell as React.FC<TableCellProps>)`
  text-align: left;
  color: ${props => (props.theme as Theme).palette.text.secondary};
`
export const StyledTableRow = styled(TableRow)`
  th:first-child {
    padding-left: 1.5rem;
  }
  th:last-child {
    padding-right: 1.5rem;
  }
`

export const StyledTableHead = styled(TableHead as React.FC<TableHeadProps>)`
  white-space: nowrap;
`
export const StyledLegendBox = styled("div")<LegendBoxStyles>`
  height: 25px;
  width: 25px;
  background-color: ${props =>
    props.theme.studli.progressBar.segmentColor[props.segment]};
  margin-right: 10px;
`

export const StyledLegendContainer = styled("div")`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`
