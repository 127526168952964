import React from "react"
import StyledTextField from "../../components/StyledTextField"

type Props = {
  name: string
  label: string
  value: string
  onChange: Function
  helperText: any
  error: boolean
  inputRef?: any
  maxLength: number
  disabled?: boolean
}

const LimitedTextField = ({
  name,
  label,
  value,
  onChange,
  helperText,
  error,
  maxLength,
  inputRef,
  disabled
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length - 1 >= maxLength) {
      return
    }
    onChange(event)
  }

  const getHelperText = () => {
    return helperText ? helperText : `${maxLength - value.length} tecken kvar`
  }

  return (
    <StyledTextField
      inputRef={inputRef ? inputRef : null}
      inputProps={{ "aria-label": label }}
      disabled={disabled}
      name={name}
      label={label}
      margin="dense"
      aria-label={label}
      variant="outlined"
      autoComplete="off"
      width="75%"
      value={value}
      onChange={handleChange}
      helperText={getHelperText()}
      error={error}
    />
  )
}

export default LimitedTextField
