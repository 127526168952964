import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material"
import {
  FormativeProgressionArr,
  FormativeTest,
  FormativeSession,
  FormativeQuestion
} from "../../modules/formative/types"
import { selectStudentsWithProduct } from "../../modules/members/selectors"
import { selectAllProgressionAnswers } from "../../modules/formative/selectors"
import Avatar from "../../components/Avatar"
import { buildName } from "../../shared/tools/strings"
import {
  StyledStatusTag,
  StyledAvatarCell,
  StyledNameCell,
  StyledNameCellWrapper,
  StyledLegendContainer,
  StyledTableRow
} from "./StyledFormativeQuestionsResult"
import FormativeQuestionAnswerTag from "./FormativeQuestionAnswerTag"
import LastActive from "../../components/LastActive"
import InfoPopover from "../../components/InfoPopover"
import * as routerActions from "../../modules/router/actions"
import { selectClassroomId } from "../../modules/classroom/selectors"
import { AnswerTypes } from "./types"
type Props = {
  progress: FormativeProgressionArr
  test: FormativeTest
  session: FormativeSession
}

const FormativeStudentsResult = ({ progress, test, session }: Props) => {
  const students = useSelector(selectStudentsWithProduct)
  const answers = useSelector(selectAllProgressionAnswers)
  const classroomId = useSelector(selectClassroomId)
  const dispatch = useDispatch()

  const getAnswerType = (
    question: FormativeQuestion,
    member: number
  ): AnswerTypes => {
    const questionAnswer = answers.find(
      answer =>
        answer.questionId === question.id &&
        answer.sessionId === session.id &&
        answer.memberId === member
    )

    if (question.module === "rating" && questionAnswer) {
      return "rating"
    }
    if (questionAnswer && questionAnswer.isCorrect) {
      return "correct"
    }
    if (questionAnswer && !questionAnswer.isCorrect) {
      return "wrong"
    }
    return "notAnswered"
  }

  const goToStudentResultPage = (memberId: number) => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR_ELEV_INFO, {
        classroomId,
        testId: test.id,
        memberId
      })
    )
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledAvatarCell></StyledAvatarCell>
          <StyledNameCell>Elev</StyledNameCell>
          <TableCell>
            <InfoPopover
              type="Info"
              content={
                <div>
                  <StyledLegendContainer>
                    <FormativeQuestionAnswerTag answerType="correct" />
                    Rätt svar
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <FormativeQuestionAnswerTag answerType="wrong" />
                    Fel svar
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <FormativeQuestionAnswerTag answerType="rating" />
                    Angivet svar som inte rättas
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <FormativeQuestionAnswerTag answerType="notAnswered" />
                    Inget svar finns registrerat
                  </StyledLegendContainer>
                </div>
              }
            />
            Resultat
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {session.members
          .filter(sm => students.some(s => s.studliId === sm))
          .map(member => {
            const foundStudent = students.find(
              student => student.studliId === member
            )
            let hasAnsweredEndQuestion = false

            const studentProgress = progress.find(
              progressPost =>
                progressPost.memberId === member &&
                progressPost.sessionId === session.id
            )
            if (test.endQuestionId) {
              hasAnsweredEndQuestion = answers.some(
                answer =>
                  answer.questionId === test.endQuestionId &&
                  answer.sessionId === session.id &&
                  answer.memberId === member
              )
            }
            return (
              <StyledTableRow
                onClick={() => goToStudentResultPage(member)}
                key={member}
              >
                <StyledAvatarCell>
                  {foundStudent ? (
                    <Avatar
                      picture={foundStudent.picture}
                      firstName={foundStudent.firstName}
                      lastName={foundStudent.lastName}
                      studliId={foundStudent.studliId}
                    />
                  ) : (
                    ""
                  )}
                </StyledAvatarCell>
                <StyledNameCell>
                  <StyledNameCellWrapper>
                    {foundStudent
                      ? buildName(foundStudent.firstName, foundStudent.lastName)
                      : ""}
                    {studentProgress && studentProgress.startedAt && (
                      <StyledStatusTag
                        status={
                          studentProgress.submittedAt ? "done" : "inProgress"
                        }
                      >
                        {studentProgress.submittedAt
                          ? "Inlämnat - "
                          : "Påbörjat - "}
                        <LastActive
                          date={
                            studentProgress.submittedAt
                              ? studentProgress.submittedAt
                              : studentProgress.startedAt
                          }
                        />
                      </StyledStatusTag>
                    )}
                  </StyledNameCellWrapper>
                </StyledNameCell>
                <TableCell>
                  {test.questions.map(question => {
                    const answerType = getAnswerType(question, member)
                    return (
                      <FormativeQuestionAnswerTag
                        key={question.id}
                        answerType={answerType}
                      />
                    )
                  })}
                  {test.endQuestionId && (
                    <FormativeQuestionAnswerTag
                      key={test.endQuestionId}
                      answerType={
                        hasAnsweredEndQuestion ? "rating" : "notAnswered"
                      }
                    />
                  )}
                </TableCell>
              </StyledTableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}

export default FormativeStudentsResult
