import React from "react"
import { ReactComponent as BackgroundSVG } from "../../assets/images/card-background.svg"
import {
  StyledOnBoardingLogo,
  StyledOnBoardingContainer,
  StyledOnBoardingSection
} from "./StyledOnBoarding"
import { Typography } from "@mui/material"
import HtmlRenderer from "../HtmlRenderer"

type Props = {
  title: string
  body: string[]
  image: React.ReactNode
}

const OnBoarding = ({ title, body, image }: Props) => {
  return (
    <>
      <StyledOnBoardingLogo>
        <BackgroundSVG />
        {image}
      </StyledOnBoardingLogo>
      <StyledOnBoardingContainer maxWidth="lg">
        <StyledOnBoardingSection>
          <Typography variant="h6">{title}</Typography>

          <Typography variant="body1" component="div">
            <br />
            {body &&
              body.map((paragraph: string, index: number) => (
                <HtmlRenderer
                  key={index}
                  component={Typography}
                  componentProps={{ variant: "body1", component: "p" }}
                  html={paragraph}
                />
              ))}
          </Typography>
        </StyledOnBoardingSection>
      </StyledOnBoardingContainer>
    </>
  )
}

export default OnBoarding
