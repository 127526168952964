import { useState, useEffect, useMemo } from "react"
import { GoalWithProgress } from "../../modules/goals/types"
import { StudentInStudentList } from "../../pages/Members/store/types"

export const TABLE_EXERCISES = [
  {
    label: "Addition",
    value: "addition"
  },
  {
    label: "Subtraktion",
    value: "subtraction"
  },
  {
    label: "Addition och subtraktion (blandat)",
    value: "additionsubtraction"
  },
  {
    label: "Multiplikation",
    value: "multiplication"
  },
  {
    label: "Division",
    value: "division"
  },
  {
    label: "Multiplikation och division (blandat)",
    value: "multiplicationdivision"
  }
] as const

type Props = {
  goals: GoalWithProgress[]
  members: StudentInStudentList[]
}

export type FilterOptions = {
  title: string
  value: number
}[]

type ReturnProps = {
  currentGoals: GoalWithProgress[]
  gradeFilters: FilterOptions | undefined
  selectedGradeFilter: number[]
  setSelectedGradeFilter: (grades: number[]) => void
  selectedTableFilter: string[]
  setSelectedTableFilter: (tableExercises: string[]) => void
  searchText: string
  setSearchText: (text: string) => void
}

const extractFiltersFromGoals = (goals: GoalWithProgress[]) => {
  const a = new Set(goals.map(g => g.grade))
  const withTitle = Array.from(a).map(g => ({
    title: `Åk ${g}`,
    value: g
  }))
  return withTitle
}

function useChooseGoals({ goals }: Props): ReturnProps {
  const [gradeFilters, setGradeFilters] = useState<FilterOptions>()
  const [selectedGradeFilter, setSelectedGradeFilter] = useState<number[]>([])
  const [selectedTableFilter, setSelectedTableFilter] = useState<string[]>([])
  const [searchText, setSearchText] = useState<string>("")
  useEffect(() => {
    const filterOptions = extractFiltersFromGoals(goals)
    setGradeFilters(filterOptions)
    // flyttar vi in filter här så behöver vi skriva den även utanför
    //eslint-disable-next-line
  }, [goals])

  const filterOnGrades = (g: GoalWithProgress[]) => {
    if (selectedGradeFilter.length === 0) {
      return g
    }
    return g.filter(goal => selectedGradeFilter.includes(goal.grade))
  }

  const filterOnTableExercises = (g: GoalWithProgress[]) => {
    if (selectedTableFilter.length === 0) {
      return g
    }
    return g.filter(goal => selectedTableFilter.includes(goal?.tableCategory))
  }

  const filterOnSearchText = (g: GoalWithProgress[]) => {
    if (searchText === "") {
      return g
    }
    return g.filter(goal =>
      goal.title.toLowerCase().includes(searchText.toLowerCase())
    )
  }

  const currentGoals = useMemo(() => {
    let g = goals
    g = filterOnGrades(g)
    g = filterOnTableExercises(g)
    g = filterOnSearchText(g)
    return g
  }, [goals, selectedGradeFilter, selectedTableFilter, searchText])

  return {
    gradeFilters,
    selectedGradeFilter,
    currentGoals,
    setSelectedGradeFilter,
    selectedTableFilter,
    setSelectedTableFilter,
    searchText,
    setSearchText
  }
}

export default useChooseGoals
