import React, { useCallback } from "react"
import { StyledTitle } from "./StyledAssignmentAndTestList"
import {
  Assignment,
  AssignmentsWithStatusAndProgression,
  AssignmentWithStatusAndProgression
} from "../../modules/assignments/types"
import StyledList from "../StyledList"
import { Stickers } from "../../modules/classroom/types"
import { useSelector } from "react-redux"
import {
  selectAllProgression,
  selectAllTests
} from "../../modules/formative/selectors"
import AssignmentAndTestListItem from "../AssignmentAndTestListItem/AssignmentAndTestListItem"
import { doesCurrentUserHasATeacherGuide } from "../../modules/users/selectors"
import { FormativeTest } from "../../modules/formative/types"
import { selectAllActiveGroups } from "../../modules/classroom/selectors"
import { selectAllMembers } from "../../modules/members/selectors"

type Props = {
  assignments: AssignmentsWithStatusAndProgression
  onAssignmentClick: (assignmentId: Assignment["id"]) => void
  stickers: Stickers
  onProgressClick: (value: AssignmentWithStatusAndProgression) => void
}

function AssignmentAndTestList({
  assignments,
  onAssignmentClick,
  stickers,
  onProgressClick
}: Props) {
  const tests = useSelector(selectAllTests)
  const progress = useSelector(selectAllProgression)
  const activeGroups = useSelector(selectAllActiveGroups)
  const members = useSelector(selectAllMembers)
  const hasTeacherGuide = useSelector(doesCurrentUserHasATeacherGuide)
  const getAssignmentGroups = useCallback(
    (a: Assignment) => {
      const assignmentMembers = members.filter(m =>
        a.participants.includes(m.studliId)
      )
      const groupIds = Array.from(
        new Set(assignmentMembers.map(m => m.groups).flat())
      )
      return groupIds
    },
    [members]
  )

  const getTestGroups = useCallback((t: FormativeTest) => {
    const allGroups = t.participantsInfo.reduce(
      (acc: number[], participant) => {
        return [...acc, ...participant.groups]
      },
      []
    )
    return Array.from(new Set(allGroups))
  }, [])

  const filteredTest = tests
    .filter(t => {
      const testGroups = getTestGroups(t)
      return activeGroups.some(g => testGroups.includes(g.id))
    })
    .map(test => {
      return {
        test,
        assignment: null
      }
    })
    .filter(t => t.test.status === "ongoing")

  const filteredAssignment = assignments
    .filter(a => {
      const assignmentGroups = getAssignmentGroups(a)
      return activeGroups.some(g => assignmentGroups.includes(g.id))
    })
    .map(assignment => {
      return {
        assignment: {
          ...assignment,
          onAssignmentClick: onAssignmentClick,
          onProgressClick: onProgressClick
        },
        test: null
      }
    })
  const assignmentsAndTests = [...filteredTest, ...filteredAssignment]

  return (
    <>
      <StyledTitle component="h1" variant="h6">
        Aktuellt
      </StyledTitle>
      <StyledList>
        {assignmentsAndTests.map(item => (
          <AssignmentAndTestListItem
            key={item.test ? item.test.id : item.assignment.id}
            test={hasTeacherGuide ? item.test : null}
            assignment={item.assignment}
            progress={progress}
            stickers={stickers}
          />
        ))}
      </StyledList>
    </>
  )
}

export default AssignmentAndTestList
