/**
 * These select* functions are  for redux select(), they expose parts of the state.
 */
import { createSelector } from "reselect"
import { Goals, GoalWithProgress } from "./types"
import { RootState } from "../store"
import { selectGoalsProgress } from "../goalsProgress/selectors"

export const selectGoals = (store: RootState): Goals => store.goals.goals || []

export const selectGoalsLength = (store: RootState): number =>
  selectGoals(store).length

export const selectCurrentGoalId = (store: RootState): string =>
  store.location.payload.goalId

export const selectGoalTitleAndOrderFromRoute = createSelector(
  [selectGoals, selectCurrentGoalId],
  (goals, goalId) => {
    const goal = goals.find(g => g.goalRef === goalId)
    if (!goal) {
      return ""
    }
    return `${goal.order}. ${goal.title}`
  }
)

export const selectGoalsWithProgress = createSelector(
  selectGoals,
  selectGoalsProgress,
  (goals, progress): GoalWithProgress[] => {
    return goals.map(g => {
      const progressForGoal = progress.filter(p => p.goalRef === g.goalRef)
      return { ...g, progress: progressForGoal }
    })
  }
)

export const selectGoal = createSelector(
  [selectGoals, selectCurrentGoalId],
  (goals, goalId) => {
    const goal = goals.find(g => g.goalRef === goalId)
    if (!goal) {
      return ""
    }
    return goal
  }
)
