/**
 * Used during development to mark what's not implemented yet / what's left TODO
 */

import React from "react"
import {
  StyledStar,
  StyledStarBackground,
  StyledAbilityIndicatorContainer,
  StyledToolTipText
} from "./StyledAbilityIndicator"
import ToolTip from "@mui/material/Tooltip"
type Props = {
  grade?: number
  children?: never
  size?: number
}

const GRADES: { [key: number]: string } = {
  1: "Svårighetsnivå: Lätt",
  2: "Svårighetsnivå: Lätt och medel",
  3: "Svårighetsnivå: Medel",
  4: "Svårighetsnivå: Medel och svår",
  5: "Svårighetsnivå: Svår"
}

const AbilityIndicator = ({ grade, size }: Props) => {
  const color = !grade ? "rgba(0, 0, 0, 0.12)" : undefined
  return (
    <StyledAbilityIndicatorContainer size={size}>
      <ToolTip
        title={
          <StyledToolTipText>
            {grade ? GRADES[grade] : "Inget påbörjat arbete"}
          </StyledToolTipText>
        }
      >
        <StyledStar grade={grade} size={size} />
      </ToolTip>
      <StyledStarBackground size={size} backgroundcolor={color} />
    </StyledAbilityIndicatorContainer>
  )
}

export default AbilityIndicator
