import React, { useEffect } from "react"
import { connect } from "react-redux"

import { ReactComponent as RecordIcon } from "../../assets/images/recorder-microphone.svg"
import {
  Wrapper,
  StyledButton,
  Time,
  StyledStopRecordingIcon,
  StyledErrorMessage,
  StyledButtonContainer,
  StyledCircularProgress,
  StyledUploadingText
} from "./StyledAudioRecorder"
import useAudioRecorder from "./useAudioRecorder"
import { getTime } from "../../shared/tools/misc"
import Button from "../Button"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import { RootState } from "../../modules/store"

import {
  User
} from "../../modules/users/types"

type OwnProps = {
  onSave: (blob: string) => void
  maxLength: number
  button: boolean
  editAudio?: Blob
  conversation: boolean
}

type StateProps = {
  authenticatedUser: User | undefined
}

type Props = OwnProps & StateProps

const Recorder: React.FC<Props> = ({
  onSave,
  maxLength,
  button,
  conversation,
  authenticatedUser
}) => {
  const {
    startRecording,
    stopRecording,
    timeRecorded,
    isRecording,
    cantUseRecorder,
    initializationError,
    isUploading
  } = useAudioRecorder({ conversation, studliID: authenticatedUser?.studliId })

  useEffect(() => {
    if (timeRecorded > maxLength * 1000) {
      stopRec()
    }

    // eslint-disable-next-line
  }, [timeRecorded])

  const stopRec = async () => {
    const file = await stopRecording()
    onSave(file)
  }

  const togglePlayPause = () => {
    if (!isRecording) {
      startRecording()

      return
    }

    stopRec()
  }

  const time = Math.floor(timeRecorded / 1000)
  return (
    <>
      {cantUseRecorder ? (
        <StyledErrorMessage>
          Din webbläsare stödjer tyvärr inte ljudinspelning.
        </StyledErrorMessage>
      ) : (
        <>
          {(isRecording || isUploading) && (
            <Wrapper fullWidth={!button} to={maxLength} from={time}>
              {isUploading ? (
                <StyledUploadingText>Laddar upp</StyledUploadingText>
              ) : (
                <Time>
                  {getTime(time)} / {getTime(maxLength)}
                </Time>
              )}
              {button && (
                <StyledButton
                  onClick={stopRec}
                  aria-label={!isUploading ? "Stoppa inspelning" : "Laddar"}
                >
                  {!isUploading ? (
                    <StyledStopRecordingIcon />
                  ) : (
                    <StyledCircularProgress color="primary" />
                  )}
                </StyledButton>
              )}
            </Wrapper>
          )}
          {button && !isRecording && !isUploading && (
            <StyledButtonContainer>
              <Button
                color="primary"
                variant="outlined"
                onClick={startRecording}
              >
                Spela in ljud
              </Button>
              {initializationError === "NotAllowedError" && (
                <StyledErrorMessage>
                  Du behöver ge tillåtelse att använda mikrofonen för att spela
                  in
                </StyledErrorMessage>
              )}
            </StyledButtonContainer>
          )}
          {!button && (
            <StyledButtonContainer>
              <StyledButton
                onClick={togglePlayPause}
                aria-label={isRecording ? "Spela in ljud" : "Stoppa inspelning"}
              >
                {isRecording && <StyledStopRecordingIcon />}
                {!isRecording && !isUploading && <RecordIcon />}
                {!isRecording && isUploading && (
                  <StyledCircularProgress color="primary" />
                )}
              </StyledButton>
              {initializationError === "NotAllowedError" && (
                <StyledErrorMessage>
                  Du behöver ge tillåtelse att använda mikrofonen för att spela
                  in
                </StyledErrorMessage>
              )}
            </StyledButtonContainer>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  authenticatedUser: selectAuthenticatedUser(state)
})

export default connect(mapStateToProps)(Recorder)
