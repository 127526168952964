import * as actions from "./actions"
import * as types from "./types"
import { State, AppShellActionTypes } from "./types"
import { calcSizes } from "../../../hooks/useWindowSize"

const sizes = calcSizes(window.innerWidth)
const initialState: State = { isSideBarOpen: sizes.lg || sizes.xl }

const reducer = (state = initialState, action: AppShellActionTypes): State => {
  switch (action.type) {
    case actions.UI.TOGGLE_SIDE_BAR:
      return { ...state, isSideBarOpen: !state.isSideBarOpen }

    case actions.UI.SET_VIEWPORT_WIDTH:
      const viewportWidth = (action as types.SetViewportWidthAction).payload

      return { ...state, viewportWidth }

    default:
      return state
  }
}

export default reducer
