import React from "react"
import MenuItem from "@mui/material/MenuItem"
import { SortableProps } from "../../pages/Members/store/types"
import { TextField } from "@mui/material"

type OwnProps = {
  selectLabel: string
  options: SelectOptions
  onChangeCallback: Function
  selected: SelectValue
}

export type SelectOption = {
  id: string
  value: SelectValue
  label: string
}

export type SelectValue = string | number | keyof SortableProps

export type SelectOptions = SelectOption[]

export const Select = ({
  selected,
  onChangeCallback,
  selectLabel,
  options
}: OwnProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCallback(event.target.value)
  }

  const selectProps = {
    autoWidth: false
  }

  return (
    <TextField
      select
      label={selectLabel}
      value={selected}
      onChange={handleChange}
      margin="dense"
      variant="outlined"
      fullWidth={true}
      SelectProps={selectProps}
    >
      {options.map(option => (
        <MenuItem
          aria-label={option.label}
          key={option.id}
          value={option.value}
        >
          {option.label || ""}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default Select
