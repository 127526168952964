/**
 * These select* functions expose parts of the state. Play nice with redux select() and inside mapStateToProps etc.
 */
import { Order, SortableProps, StudentWithMoreMenu } from "./types"
import { RootState } from "../../../modules/store"
import { createSelector } from "reselect"

import {
  selectStudents,
  selectStudentsOnline
} from "../../../modules/members/selectors"
import { selectGoalsProgress } from "../../../modules/goalsProgress/selectors"
import { selectGoals } from "../../../modules/goals/selectors"
import {
  selectAllReadingStates,
  selectExerciseProgressWithTemporaryProgress
} from "../../../modules/exercisesProgress/selectors"
import { buildGoalTitle } from "../../Goals/store/tools"
import { Member } from "../../../modules/members/types"
import { GoalsProgress } from "../../../modules/goalsProgress/types"
import { Goals } from "../../../modules/goals/types"
import {
  ExerciseProgress,
  ReadingState
} from "../../../modules/exercisesProgress/types"
import {
  selectClassroomActiveProductInfo,
  selectActiveProductWithTwins
} from "../../../modules/products/selectors"
import { activeProductType } from "../../../modules/products/constants"
import { selectAllAssignmentsProgress } from "../../../modules/assignmentsProgress/selectors"
import { AssignmentsProgress } from "../../../modules/assignmentsProgress/types"
import { selectUserActiveProducts } from "../../../modules/users/selectors"
import { sortMembers } from "../common/TableAndListSort"
import { selectCurrentMemberId } from "../../../modules/router/selectors"
import { ActiveProduct } from "../../../modules/users/types"
import { FormativeProgressionArr } from "../../../modules/formative/types"
import { selectAllProgression } from "../../../modules/formative/selectors"
import { selectAllGroups } from "../../../modules/classroom/selectors"
import { Group } from "../../../modules/classroom/types"

export const selectMemberListOrderBy = (
  store: RootState
): keyof SortableProps => store.pages.membersPage.memberListOrderBy

export const selectMemberListOrder = (store: RootState): Order =>
  store.pages.membersPage.memberListOrder

export const selectStudentsForStudentList = createSelector(
  selectStudents,
  selectUserActiveProducts,
  selectStudentsOnline,
  selectGoals,
  selectGoalsProgress,
  selectExerciseProgressWithTemporaryProgress,
  selectAllReadingStates,
  selectAllAssignmentsProgress,
  selectClassroomActiveProductInfo,
  selectActiveProductWithTwins,
  selectAllProgression,
  selectAllGroups,
  (
    students,
    activeProducts,
    studentsOnline,
    goals = [],
    goalsProgress = [],
    exercisesProgress = [],
    readingStates = [],
    assignmentsProgress = [],
    activeProduct,
    activeProductWithTwins,
    formativeProgress,
    groups
  ) => {
    return students.map(student => {
      const userActiveProducts =
        activeProducts.find(p => p.studliId === student.studliId) ||
        ({
          products: [],
          studliId: 0,
          codes: [],
          active: false,
          activatedByOtherStudliId: 0
        } as ActiveProduct)

      const studentHasActiveProduct =
        userActiveProducts.products.reduce<boolean>((res, code) => {
          if (res) {
            return res
          }

          return (
            activeProductWithTwins.findIndex(p => p.sesamName === code) > -1
          )
        }, false)

      const isStudentOnline = studentsOnline.includes(student)

      return activeProduct.type === activeProductType.ADAPT
        ? studentsForAdaptList(
            student,
            studentHasActiveProduct,
            isStudentOnline,
            goalsProgress,
            goals,
            groups
          )
        : studentsForDillList(
            student,
            studentHasActiveProduct,
            exercisesProgress,
            readingStates,
            assignmentsProgress,
            formativeProgress,
            groups
          )
    })
  }
)

const studentsForAdaptList = (
  student: Member,
  studentHasProduct: boolean,
  isStudentOnline: boolean,
  goalsProgress: GoalsProgress,
  goals: Goals,
  groups: Group[]
) => {
  let goalInfoForGoalInProgress = {
    goalTitle: "",
    goalAbilityGrade: 0,
    goalActiveTimeMilliseconds: 0,
    goalRef: "",
    goalOrder: 0,
    gradeLabel: ""
  }
  let lastActive = ""

  let userGroups = groups.filter(group => student.groups?.includes(group.id))

  goalsProgress.forEach(goalProgress => {
    if (student.studliId === goalProgress.studliId) {
      if (goalProgress.isCurrentGoal) {
        const goal = goals.find(goal => goal.goalRef === goalProgress.goalRef)

        if (goal) {
          goalInfoForGoalInProgress.goalTitle = buildGoalTitle(
            goal.title,
            goal.order
          )
          goalInfoForGoalInProgress.gradeLabel = goal.gradeLabel
          goalInfoForGoalInProgress.goalOrder = goal.order
        }

        goalInfoForGoalInProgress = {
          ...goalInfoForGoalInProgress,
          ...goalProgress
        }
      }

      if (lastActive < goalProgress.lastActive) {
        lastActive = goalProgress.lastActive
      }
    }
  })
  return {
    studliId: student.studliId,
    firstName: student.firstName,
    lastName: student.lastName,
    username: student.username,
    picture: student.picture,
    email: student.email,
    password: student.password,
    goalTitle: goalInfoForGoalInProgress.goalTitle,
    goalRef: goalInfoForGoalInProgress.goalRef,
    gradeLabel: goalInfoForGoalInProgress.gradeLabel,
    goalAbilityGrade: goalInfoForGoalInProgress.goalAbilityGrade,
    goalOrder: goalInfoForGoalInProgress.goalOrder,
    goalActiveTimeMilliseconds:
      goalInfoForGoalInProgress.goalActiveTimeMilliseconds,
    lastActive: lastActive,
    isPasswordChangeable: student.isPasswordChangeable, //TODO: should get from the correct part of store
    isOnline: isStudentOnline,
    hasProduct: studentHasProduct,
    groups: userGroups
  }
}

const studentsForDillList = (
  student: Member,
  studentHasProduct: boolean,
  exercisesProgress: ExerciseProgress[],
  readingStates: ReadingState[],
  assignmentsProgress: AssignmentsProgress,
  formativeProgress: FormativeProgressionArr,
  groups: Group[]
) => {
  const completedExercises = exercisesProgress
    .filter(
      progress =>
        progress.studliId === student.studliId && progress.status === "finished"
    )
    .sort((a, b) => (a.latestAt > b.latestAt ? -1 : 1))

  const lastActiveExercise = completedExercises.length
    ? completedExercises[0].latestAt
    : ""

  const userReadingStates = readingStates
    .filter(state => state.studliId === student.studliId)
    .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))

  const lastReadingStateTimestamp = userReadingStates.length
    ? userReadingStates[0].timestamp
    : ""

  const lastActive =
    lastActiveExercise > lastReadingStateTimestamp
      ? lastActiveExercise
      : lastReadingStateTimestamp

  const completedAssignments = assignmentsProgress.filter(
    progress => progress.studliId === student.studliId && progress.approvedAt
  ).length

  let userGroups = groups.filter(group => student.groups?.includes(group.id))

  const completedTests = formativeProgress.filter(
    p => p.memberId === student.studliId && p.submittedAt
  ).length
  return {
    studliId: student.studliId,
    firstName: student.firstName,
    lastName: student.lastName,
    username: student.username,
    picture: student.picture,
    email: student.email,
    password: student.password,
    completedExercises: completedExercises.length,
    lastActive,
    isPasswordChangeable: student.isPasswordChangeable, //TODO: should get from the correct part of store
    hasProduct: studentHasProduct,
    completedAssignments,
    completedTests,
    groups: userGroups
  }
}

export const SelectMembersIdWithCurrentSorting = createSelector(
  selectStudentsForStudentList,
  selectMemberListOrderBy,
  selectMemberListOrder,
  (students, sortBy, sortOrder) =>
    sortMembers(
      students as unknown as StudentWithMoreMenu[],
      sortOrder,
      sortBy
    ).map(student => student.studliId)
)

export const selectLastActiveForCurrentMember = createSelector(
  selectStudentsForStudentList,
  selectCurrentMemberId,
  (students, memberId) => {
    const currentStudent = students.find(s => s.studliId === memberId)

    return currentStudent ? currentStudent.lastActive : undefined
  }
)
