import { styled } from "@mui/material"
import Paper from "@mui/material/Paper"
import Button from "../Button"

export const StyledPaper = styled(Paper)`
  margin-bottom: 1.5em;
`

export const StyledPaperContent = styled("div")`
  padding: 1.5em;
  width: 100%;
`

export const StyledErrorMessage = styled("div")`
  padding: 1.5em;
  color: ${props => props.theme.studli.status.error};
`

export const StyledAudioRecorderContainer = styled("div")`
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  ${props => props.theme.breakpoints.up("sm")} {
    margin-right: 0.5em;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    margin-bottom: 0.5em;
    margin-right: 0;
  }
`
export const StyledButtonContainer = styled("div")`
  display: flex;
  margin-top: 2em;
  margin-bottom: 4em;
  ${props => props.theme.breakpoints.down("xs")} {
    flex-direction: column;
  }
`
