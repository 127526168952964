import React, { useState, useEffect, useCallback } from "react"
import {
  DialogTitle,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Stack,
  Typography,
  TableSortLabel
} from "@mui/material"
import {
  StyledDialogContent,
  StyledDialogContentText
} from "./StyledChooseUserDialog"
import Button from "../Button"
import { User, Users, UserRole } from "../../modules/users/types"
import { USER_ROLE } from "../../modules/users/constants"
import TableToolbar from "../TableToolbar"
import TableFilter from "../TableFilter/TableFilter"
import { useSelector } from "react-redux"
import { selectAllGroups } from "../../modules/classroom/selectors"
import { FilterValue } from "../TableFilter/types"
import { StudentInStudentList } from "../../pages/Members/store/types"
import GroupChip from "../GroupChip/GroupChip"
import { buildName } from "../../shared/tools/strings"
import Avatar from "../Avatar"
import { Group } from "../../modules/classroom/types"
import { tableFilter } from "../../shared/tools/filter"

type OwnProps = {
  handleAdd: (checkedUsers: Users) => void
  handleCancel: () => void
  userRole: UserRole
  foundUsers: Users
  alreadyCheckedUsers?: Users
  type: "addToAssignment" | "addToClassroom" | "addToTest"
}

type Props = OwnProps

export const ChooseUsersDialogContent = ({
  foundUsers,
  handleAdd,
  handleCancel,
  userRole,
  alreadyCheckedUsers,
  type
}: Props) => {
  const [checkedUsers, setCheckedUsers] = useState<Users>([])

  const [sort, setSort] = useState<"asc" | "desc">("asc")
  useEffect(() => {
    if (
      alreadyCheckedUsers &&
      alreadyCheckedUsers.length &&
      !checkedUsers.length
    ) {
      setCheckedUsers(alreadyCheckedUsers)
    }
    // eslint-disable-next-line
  }, [alreadyCheckedUsers])

  const groups = useSelector(selectAllGroups)

  const sortMembers = (members: Users) => {
    return members.sort((a, b) => {
      if (a.lastName && b.lastName) {
        return sort === "asc"
          ? a.lastName.localeCompare(b.lastName)
          : b.lastName.localeCompare(a.lastName)
      }
      return 0
    })
  }

  const [sortedMembers, setSortedMembers] = React.useState(
    sortMembers(foundUsers)
  )

  const [currentFilter, setCurrentFilter] = React.useState<FilterValue[]>([])

  const onFilter = useCallback(
    (filterValues: FilterValue[]) => {
      const filteredMembers = tableFilter(filterValues, foundUsers)
      setSortedMembers(sortMembers(filteredMembers))
    },
    [foundUsers, sort]
  )

  useEffect(() => {
    onFilter(currentFilter)
  }, [foundUsers, currentFilter, onFilter])

  const setFilters = (filterValues: FilterValue[]) => {
    setCurrentFilter(filterValues)
  }

  const getFilters = () => {
    return [
      {
        label: "Grupper",
        key: "groups",
        options: groups.map(group => ({
          label: group.name,
          val: group.id,
          key: "groups",
          filterFunction: (m: StudentInStudentList) => {
            const userGroups = m.groups as unknown as number[]
            return userGroups && userGroups.some(g => g === group.id)
          }
        }))
      }
    ]
  }

  const searchFunction = (m: StudentInStudentList, searchText: string) =>
    m.email?.toLowerCase().includes(searchText.toLowerCase()) ||
    m.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
    m.lastName?.toLowerCase().includes(searchText.toLowerCase())

  const handleSelectAll = () => {
    if (checkedUsers.length === sortedMembers.length) {
      setCheckedUsers([])
    } else {
      setCheckedUsers(sortedMembers)
    }
  }

  const handleUserChecked = (toggledUser: User) => {
    let newCheckedUsers: Users = [...checkedUsers]

    const userInCheckedUsers = newCheckedUsers.find(
      user => user.studliId === toggledUser.studliId
    )

    if (userInCheckedUsers) {
      newCheckedUsers = newCheckedUsers.filter(
        user => user.studliId !== toggledUser.studliId
      )
    } else {
      newCheckedUsers.push(toggledUser)
    }

    setCheckedUsers(newCheckedUsers)
  }

  /**
   * Determines if "Select all" button should be disabled or not.
   *
   * @returns {boolean} - True if "Select all" button should be disabled, else false.
   */

  const isAddButtonDisabled = () => {
    const isRequiredType = type === "addToAssignment" || type === "addToTest"
    if (alreadyCheckedUsers && alreadyCheckedUsers.length) {
      return false
    }
    if (
      isRequiredType &&
      alreadyCheckedUsers &&
      checkedUsers.length === alreadyCheckedUsers.length
    ) {
      return checkedUsers.every(user =>
        alreadyCheckedUsers.some(
          checkedUser => checkedUser.studliId === user.studliId
        )
      )
    }
    return checkedUsers.length === 0
  }

  const getGroups = useCallback(
    (userGroups?: number[]) => {
      return groups.filter(g => userGroups && userGroups.includes(g.id))
    },
    [groups]
  )

  const getStudentOrTeacherText = () =>
    userRole === USER_ROLE.TEACHER ? "lärare" : "elever"

  return (
    <>
      <DialogTitle id="choose-users-dialog-title">
        Välj {getStudentOrTeacherText()}
      </DialogTitle>
      <StyledDialogContent id="choose-users-dialog-description">
        <StyledDialogContentText sx={{ mb: 1 }}>
          {type === "addToClassroom" &&
            `Du fick flera träffar. Markera den/de ${getStudentOrTeacherText()} du
          vill lägga in i listan och tryck på Lägg till.`}
          {type === "addToAssignment" &&
            `Markera de elever du vill skicka uppdraget till.`}
          {type === "addToTest" &&
            `Markera de elever du vill skicka testet till.`}
        </StyledDialogContentText>
        <TableToolbar
          title=""
          selectedTitle=""
          numSelected={checkedUsers.length}
          actions={[]}
          totalItems={foundUsers.length}
          filterComponent={
            <TableFilter
              filters={getFilters()}
              onFilter={setFilters}
              searchFunction={searchFunction}
              searchLabel="Sök elev"
              results={sortedMembers.length}
            />
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 2 }} padding="checkbox">
                <Checkbox
                  checked={checkedUsers.length === sortedMembers.length}
                  onClick={handleSelectAll}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active
                  direction={sort}
                  onClick={() => setSort(sort === "asc" ? "desc" : "asc")}
                >
                  Namn
                </TableSortLabel>
              </TableCell>
              <TableCell>Grupp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedMembers.map(member => (
              <TableRow
                key={member.studliId}
                onClick={() => handleUserChecked(member)}
                hover
              >
                <TableCell sx={{ pl: 2 }} padding="checkbox">
                  <Checkbox
                    checked={checkedUsers.some(
                      user => user.studliId === member.studliId
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                  >
                    <Avatar
                      firstName={member.firstName}
                      lastName={member.lastName}
                      picture={member.picture}
                      studliId={member.studliId}
                    />
                    <Typography>
                      {buildName(member.firstName, member.lastName)}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <GroupChip
                    groups={getGroups(member.groups) as unknown as Group[]}
                    truncate
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="text">
          Avbryt
        </Button>
        <Button
          onClick={() => handleAdd(checkedUsers)}
          disabled={isAddButtonDisabled()}
          color="primary"
          variant="text"
        >
          {type === "addToClassroom" && `Lägg till`}
          {(type === "addToAssignment" || type === "addToTest") && `Välj`}
        </Button>
      </DialogActions>
    </>
  )
}

export default ChooseUsersDialogContent
