import {
  ListItemIcon,
  ListItemProps,
  DialogContentText,
  styled
} from "@mui/material"
import MUIListItem from "@mui/material/ListItem"
import { grey } from "@mui/material/colors"
import DialogContent from "@mui/material/DialogContent"

import { ReactComponent as AssignmentsIcon } from "../../assets/images/menu-task.svg"

export const StyledListTreeItem = styled(
  MUIListItem as React.FC<ListItemProps & { indent: number }>
)`
  padding: 0.75rem;
  ${({ indent }) =>
    indent === 0 ? "" : "padding-left: " + (0.75 + 1.75 * indent) + "rem;"}
`

export const StyledListItem = styled(MUIListItem)`
  padding: 0.75rem;
`

export const StyledSubListItem = styled(MUIListItem)`
  padding: 0.75rem;
  padding-left: 3.25rem;
`

export const Title = styled("span")`
  font-weight: bold;
`

export const AssignmentCount = styled("span")`
  color: ${grey};
  margin-left: 0.25rem;
`

export const StyledDialogContentText = styled(DialogContentText)`
  padding-left: 24px;
  padding-right: 24px;
`

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 32px;
  margin-right: 0.75rem;
  & > svg {
    height: 32px;
    width: 32px;
  }
`

export const StyledAssignmentsIcon = styled(AssignmentsIcon)`
  & g {
    stroke: #006cc8;
  }
`

export const StyledDialogContent = styled(DialogContent)`
  padding: 0.5rem 0 1.75rem 0;
  min-width: 560px;
`
