import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import AdaptAssignmentProgressBar from "../../components/AdaptAssignmentProgressBar"
import { Member } from "../../modules/members/types"
import AssignmentStatusTags from "../../components/AssignmentStatusAndApprovedTags"
import { Assignment } from "../../modules/assignments/types"
import {
  AssignmentsTaskProgress,
  AssignmentsProgress
} from "../../modules/assignmentsProgress/types"
import { buildName } from "../../shared/tools/strings"
import Avatar from "../../components/Avatar"
import {
  StyledAvatarCell,
  StyledTableRow,
  StyledNameCell
} from "./StyledAssignmentCard"
import { MembersWithOnlineStatus } from "../Exercises/store/types"
import { selectAllGoalsAssignmentsProgress } from "../../modules/assignmentsProgress/selectors"
import InfoPopover from "../../components/InfoPopover"
import MessageIndicator from "../../components/MessageIndicator"
import { Theme } from "@mui/system"

type Props = {
  isAdapt: boolean
  assignment: Assignment
  progress: {
    assignmentsTaskProgress: AssignmentsTaskProgress
    assignmentsProgress: AssignmentsProgress
  }
  members: MembersWithOnlineStatus
  rowsClickable: boolean
  onRowClick: (memberId: number) => void
}

const NOOP = () => {}


const AssignmentCardTable = ({
  isAdapt,
  assignment,
  progress,
  members,
  onRowClick
}: Props) => {
  const assignmentGoalsProgress = useSelector(selectAllGoalsAssignmentsProgress)
  const sortedMembers = assignment.participants
    .reduce((res: MembersWithOnlineStatus, currentMember) => {
      const memberIndex: number = members.findIndex(
        member => member.studliId === currentMember
      )
      if (memberIndex !== -1) {
        res.push(members[memberIndex])
      }

      return res
    }, [])
    .sort((first, second) => first.lastName.localeCompare(second.lastName))
  const getStatus = (member: Member) => {
    if (!isAdapt) {
      return (
        <AssignmentStatusTags
          progress={progress}
          memberId={member.studliId}
          assignmentId={assignment.id}
        />
      )
    }
    const progressForAssignment = progress.assignmentsProgress.find(
      p => p.assignmentId === assignment.id && p.studliId === member.studliId
    )
    return (
      <AdaptAssignmentProgressBar
        threshold={assignment.threshold}
        progress={progressForAssignment ? progressForAssignment.progress : 0}
      />
    )
  }

  const isMobile = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down("sm")
  )

  const getNbrExercises = (memberId: number) => {
    const progress = assignmentGoalsProgress.filter(
      p => p.assignmentId === assignment.id && p.studliId === memberId
    )

    const exercisesCount = progress.reduce((acc, curr) => {
      return acc + curr.nbrExercises
    }, 0)

    return exercisesCount ? exercisesCount : ""
  }

  const hasStarted = (memberId: number) => {
    const progressForAssignment = progress.assignmentsProgress.find(
      p => p.assignmentId === assignment.id && p.studliId === memberId
    )
    return Boolean(progressForAssignment)
  }

  return (
    <>
    {!isMobile ? (
     <Table>
      <TableHead>
        <TableRow>
          <StyledAvatarCell></StyledAvatarCell>
          <TableCell>Namn</TableCell>
          <TableCell>
            {isAdapt ? "Aktiva minuter" : "Status"}
            {isAdapt && (
              <InfoPopover
                type="Info"
                content={
                  <Typography variant="body2">
                    Antal minuter eleven arbetat i uppdraget
                  </Typography>
                }
              />
            )}
          </TableCell>
          {isAdapt && <TableCell align="right">Antal övningar</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedMembers.map(m => {
          const hasOnClick = !isAdapt || hasStarted(m.studliId)
          return (
            <StyledTableRow
              onClick={hasOnClick ? () => onRowClick(m.studliId) : NOOP}
              has_on_click={hasOnClick ? 1 : 0}
              hover={hasOnClick}
            >
              <StyledAvatarCell>
                <Avatar
                  firstName={m.firstName}
                  lastName={m.lastName}
                  picture={m.picture}
                  studliId={m.studliId}
                />
              </StyledAvatarCell>
              <StyledNameCell>
                <Stack direction="row" alignItems="inherit">
                  <ListItemText
                    primary={buildName(m.firstName, m.lastName)}
                    secondary={m.email}
                  />
                  {!isAdapt && (
                    <MessageIndicator
                      assignmentId={assignment.id}
                      memberId={m.studliId}
                    />
                  )}
                </Stack>
              </StyledNameCell>
              <TableCell>{getStatus(m)}</TableCell>
              {isAdapt && (
                <TableCell align="right">
                  {getNbrExercises(m.studliId)}
                </TableCell>
              )}
            </StyledTableRow>
          )
        })}
      </TableBody>
    </Table>
    ) : (
      <List>
        {sortedMembers.map(m => {
         const hasOnClick = !isAdapt || hasStarted(m.studliId)
         return (
           <ListItemButton
             key={m.studliId}
             onClick={hasOnClick ? () => onRowClick(m.studliId) : NOOP}
             sx={{display: "flex", flexDirection: "column", alignItems: "start"}}
             divider
           >
            <Stack direction="row" alignItems="center" width="100%">
              <Avatar
                 firstName={m.firstName}
                 lastName={m.lastName}
                 picture={m.picture}
                 studliId={m.studliId}
               />
              <ListItemText
                primary={buildName(m.firstName, m.lastName)}
                sx={{ marginLeft: 1}}
              />
              {!isAdapt ? (
                <>
                  <MessageIndicator
                    assignmentId={assignment.id}
                    memberId={m.studliId}
                    />
                  <Box>{getStatus(m)}</Box>
                </>
                ) : (
                <Box>
                  {getNbrExercises(m.studliId)}
                </Box>
              )}
            </Stack>
             <ListItemText
              secondary={m.email} 
              sx={{ marginLeft: 5.8}}
              />
              {isAdapt && (
                <Box width="75%" ml={"3rem"}>
                  {getStatus(m)}
                </Box>
              )}
           </ListItemButton>
         )
       })}
      </List>
    )}
  </>
  )}

export default AssignmentCardTable
