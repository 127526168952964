import React from "react"
import Typography from "@mui/material/Typography"
import AbilityIndicator from "../../components/AbilityIndicator"

import {
  StyledAbilityStarWrapper,
  StyledAbilityStarContent,
  StyledAbilityStars
} from "../../components/MembersTable/StyledMembersTable"
import { VisibleMemberProp } from "./store/types"

export type ModuleName = "members"
export const MODULE_NAME: ModuleName = "members"

export const VISIBLE_ADAPT_MEMBER_PROPS: VisibleMemberProp[] = [
  { id: "avatar", sortKey: "lastName", isSortable: false },
  {
    id: "lastName",
    sortKey: "lastName",
    isSortable: true,
    label: "Namn",
    info: {
      content: (
        <div>
          <Typography variant="body2">Elevens för- och efternamn.</Typography>
          <Typography variant="body2">
            Listan sorteras på efternamnen.
          </Typography>
        </div>
      ),
      type: "Info"
    }
  },
  {
    id: "groups",
    sortKey: "groups",
    isSortable: false,
    label: "Grupper",
    info: {
      content: (
        <div>
          <Typography variant="body2">Elevens grupper</Typography>
        </div>
      ),
      type: "Info"
    }
  },
  {
    id: "goalTitle",
    sortKey: "goalOrder",
    isSortable: true,
    label: "Mål",
    info: {
      content: (
        <Typography variant="body2">
          Aktuellt mål som eleven arbetar på just nu.
        </Typography>
      ),
      type: "Info"
    }
  },
  {
    id: "level",
    sortKey: "goalAbilityGrade",
    isSortable: false,
    label: "Årskurs",
    info: {
      content: (
        <Typography variant="body2">
          Årskurs för målet eleven arbetar på nu
        </Typography>
      ),
      type: "Info"
    }
  },
  {
    id: "goalAbilityGrade",
    sortKey: "goalAbilityGrade",
    isSortable: true,
    label: "Svårighetsnivå",
    info: {
      content: (
        <div>
          <Typography variant="body2">
            Svårighetsnivå på övningarna eleven arbetar på just nu.
          </Typography>
          <Typography variant="body2">
            Släckt stjärna = eleven har just börjat arbeta på målet och systemet
            har för lite data för att räkna ut svårighetsnivå.
          </Typography>
          <StyledAbilityStars>
            {["Lätt", "Lätt/Medel", "Medel", "Medel/Svår", "Svår"].map(
              (difficulty: string, index: number) => (
                <StyledAbilityStarWrapper key={difficulty}>
                  <StyledAbilityStarContent>
                    <AbilityIndicator grade={index + 1} />
                  </StyledAbilityStarContent>
                  <StyledAbilityStarContent>
                    <Typography variant="caption">{difficulty}</Typography>
                  </StyledAbilityStarContent>
                </StyledAbilityStarWrapper>
              )
            )}
          </StyledAbilityStars>
        </div>
      ),
      type: "Info"
    }
  },
  {
    id: "goalActiveTimeMilliseconds",
    sortKey: "goalActiveTimeMilliseconds",
    isSortable: true,
    label: "Arbetat i mål",
    info: {
      content: (
        <Typography variant="body2">
          Tid som eleven arbetat på det aktuella målet. Visar bara aktiv tid i
          övningarna – tid i berättelsen räknas inte in.
        </Typography>
      ),
      type: "Info"
    }
  },
  {
    id: "lastActive",
    sortKey: "lastActive",
    isSortable: true,
    label: "Senast arbetat",
    info: {
      content: (
        <Typography variant="body2">När eleven senast var aktiv.</Typography>
      ),
      type: "Info"
    }
  }
]

export const VISIBLE_DILL_MEMBER_PROPS: VisibleMemberProp[] = [
  { id: "avatar", sortKey: "lastName", isSortable: false },
  {
    id: "lastName",
    sortKey: "lastName",
    isSortable: true,
    label: "Namn",
    info: {
      content: (
        <div>
          <Typography variant="body2">Elevens för- och efternamn.</Typography>
          <Typography variant="body2">
            Listan sorteras på efternamnen.
          </Typography>
        </div>
      ),
      type: "Info"
    }
  },
  {
    id: "groups",
    sortKey: "groups",
    isSortable: false,
    label: "Grupper",
    info: {
      content: (
        <div>
          <Typography variant="body2">Elevens grupper</Typography>
        </div>
      ),
      type: "Info"
    }
  },
  {
    id: "completedExercises",
    sortKey: "completedExercises",
    isSortable: true,
    label: "Färdiga Övningar",
    alignRight: true,
    info: {
      content: (
        <Typography variant="body2">Antal övningar eleven klarat</Typography>
      ),
      type: "Info"
    }
  },
  {
    id: "completedAssignments",
    sortKey: "completedAssignments",
    isSortable: true,
    label: "Antal uppdrag klara",
    alignRight: true,
    info: {
      content: (
        <Typography variant="body2">Antal uppdrag eleven klarat</Typography>
      ),
      type: "Info"
    }
  },
  {
    id: "completedTests",
    sortKey: "completedTests",
    isSortable: true,
    label: "Antal test klara",
    alignRight: true,
    info: {
      content: (
        <Typography variant="body2">Antal test eleven lämnat in</Typography>
      ),
      type: "Info"
    }
  },
  {
    id: "lastActive",
    sortKey: "lastActive",
    isSortable: true,
    label: "Senast arbetat",
    info: {
      content: (
        <Typography variant="body2">När eleven senast var aktiv.</Typography>
      ),
      type: "Info"
    }
  }
]
