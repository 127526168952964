import React from "react"
import { connect } from "react-redux"
import { ApplicationState } from "../../modules/application/types"
import { selectApplicationState } from "../../modules/application/selectors"
import { RootState } from "../../modules/store"
import SplashScreen from "../../pages/SplashScreen"
import { selectCommunicationError } from "../../modules/communication/selectors"
import { CommunicationError } from "../../modules/communication/types"

type OwnProps = {
  children: React.ReactElement
}

type StateProps = {
  applicationState: ApplicationState
  communicationError: CommunicationError | undefined
}

type Props = OwnProps & StateProps

const AppInitializer = ({
  applicationState,
  children,
  communicationError
}: Props) => {
  const isApplicationReady = () => {
    return (
      applicationState === ApplicationState.ready ||
      applicationState === ApplicationState.reconnecting
    )
  }

  return isApplicationReady() ? (
    children
  ) : (
    <SplashScreen communicationError={communicationError} />
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    applicationState: selectApplicationState(state),
    communicationError: selectCommunicationError(state)
  }
}

export default connect(mapStateToProps)(AppInitializer)
