import { createSelector } from "reselect"

import { RootState } from "../store"
import { Products, Product } from "./types"
import { activeProductType } from "./constants"

export const selectActiveProductId = (store: RootState): number =>
  store.products.activeProductId

export const selectClassroomProducts = (store: RootState): Products => {
  const products = store.products.products

  return products.filter((product: Product) => !product.isTeacherGuide)
}

export const selectProducts = (store: RootState): Products =>
  store.products.products

export const selectActiveProductTitle = (store: RootState): string => {
  const activeProduct = selectClassroomActiveProductInfo(store)

  return activeProduct.niceName ?? activeProduct.title
}

export const selectClassroomActiveProductInfo = (store: RootState): Product => {
  const id = selectActiveProductId(store)

  const activeProduct = selectClassroomProducts(store).filter(
    product => product.id === id
  )

  return activeProduct[0] || {}
}

export const selectActiveProduct = createSelector(
  selectActiveProductId,
  selectProducts,
  (productId, products) => products.find(p => p.id === productId)
)

export const isActiveProductAdapt = createSelector(
  selectClassroomActiveProductInfo,
  ({ type }) => type === activeProductType.ADAPT
)

export const isActiveProductFormativeTests = createSelector(
  selectClassroomActiveProductInfo,
  ({ features }) => features.includes("FORMATIVE_TESTS")
)

export const selectClassroomHasMultipleProducts = (
  store: RootState
): boolean => {
  return (
    selectClassroomProducts(store).reduce((res, prod) => {
      if (!!prod.productTwinId && res.some(p => p.productTwinId === prod.id)) {
        return res
      }

      return [...res, prod]
    }, [] as Products).length > 1
  )
}

export const hasSameNiceNameAsAnotherProduct = (
  products: Products,
  niceName: string
) => products.findIndex(p => p.niceName === niceName) > -1

const getProductLinks = (products: Products, teacherGuide = false) =>
  products
    .filter(product => product.isTeacherGuide === teacherGuide)
    .map(product => ({
      href: product.productUrl as string,
      title: hasSameNiceNameAsAnotherProduct(
        products.filter(p => p.id !== product.id),
        product.niceName
      )
        ? product.title
        : product.niceName
    }))

export const selectProductLinks = createSelector(
  selectProducts,
  ({ users }: RootState) => users.authenticatedUser,
  ({ users }: RootState) => users.activeProducts || [],
  (products, user, userActivatedProducts) => {
    if (!user) {
      return {
        studentLinks: [],
        teacherLinks: []
      }
    }

    const activatedProducts = userActivatedProducts.find(
      u => u.studliId === user.studliId
    )

    if (!activatedProducts) {
      return {
        studentLinks: [],
        teacherLinks: getProductLinks(products, true)
      }
    }

    return {
      studentLinks: getProductLinks(
        products.filter(
          p => activatedProducts.products.indexOf(p.sesamName) > -1
        )
      ),
      teacherLinks: getProductLinks(products, true)
    }
  }
)

export const selectActiveProductFeatures = createSelector(
  ({ products }: RootState) =>
    products.products.find(p => p.id === products.activeProductId),
  product => {
    if (!product) {
      return ""
    }

    return product.features
  }
)

export const selectActiveProductName = createSelector(
  ({ products }: RootState) =>
    products.products.find(p => p.id === products.activeProductId),
  product => {
    if (!product) {
      return ""
    }

    return product.niceName || product.title
  }
)

export const selectActiveProductShouldUseTemaToc = createSelector(
  ({ products }: RootState) =>
    products.products.find(p => p.id === products.activeProductId),
  product => {
    if (!product) {
      return false
    }

    return product.useTemaToc
  }
)

export const selectHasActiveProduct = createSelector(
  selectProducts,
  selectActiveProductId,
  ({ users }: RootState) => users.authenticatedUser,
  ({ users }: RootState) => users.activeProducts || [],
  (products, activeProductId, user, userActivatedProducts) => {
    if (!user) {
      return false
    }

    const activeProduct = products.find(p => p.id === activeProductId)

    if (!activeProduct) {
      return false
    }

    const activeTwins = products.filter(
      p => p.productTwinId === activeProduct.id
    )

    const activatedProducts = userActivatedProducts.find(
      u => u.studliId === user.studliId
    )

    if (!activatedProducts) {
      return false
    }

    return activatedProducts.products.reduce((res, sesamCode) => {
      if (res) {
        return res
      }

      return activeTwins.some(p => p.sesamName === sesamCode)
    }, false)
  }
)

export const selectActiveProductWithTwins = createSelector(
  selectProducts,
  selectActiveProductId,
  (products, activeProductId) => {
    const activeProduct = products.find(p => p.id === activeProductId)

    if (!activeProduct) {
      return []
    }

    if (!activeProduct.productTwinId) {
      return [activeProduct]
    }

    return products.filter(
      p => p.id === activeProduct.id || p.productTwinId === activeProduct.id
    )
  }
)

export const selectClassroomActiveProductToOpen = createSelector(
  selectActiveProductWithTwins,
  selectActiveProductId,
  ({ users }: RootState) => users.authenticatedUser,
  ({ users }: RootState) => users.activeProducts || [],
  (productsWithTwins, activeProductId, user, userActivatedProducts) => {
    if (!user) {
      return null
    }
    const activeProduct = productsWithTwins.find(p => p.id === activeProductId)
    if (!activeProduct) {
      return null
    }
    const activatedProducts = userActivatedProducts.find(
      u => u.studliId === user.studliId
    )
    if (!activatedProducts) {
      return null
    }
    const activeProductActivated = activatedProducts.products.some(
      p => p === activeProduct.sesamName
    )
    if (activeProductActivated) {
      return activeProduct
    }
    const twinProduct = productsWithTwins.find(p => p.id !== activeProductId)
    if (twinProduct) {
      const twinProductActivated = activatedProducts.products.some(
        p => p === twinProduct.sesamName
      )
      if (twinProductActivated) {
        return twinProduct
      }
    }
    return null
  }
)
