import { Breakpoint } from "@mui/material"
import { useState, useEffect } from "react"
import theme from "../shared/styles/theme"

type WindowSizes = { [key in Breakpoint]: boolean }

export const calcSizes = (width: number) => ({
  xs: width >= theme.breakpoints.values["xs"],
  sm: width >= theme.breakpoints.values["sm"],
  md: width >= theme.breakpoints.values["md"],
  lg: width >= theme.breakpoints.values["lg"],
  xl: width >= theme.breakpoints.values["xl"]
})

function useWindowSize(): WindowSizes {
  const [sizes, setSizes] = useState(calcSizes(window.innerWidth))

  useEffect(() => {
    function handleResize() {
      setSizes(calcSizes(window.innerWidth))
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return sizes
}

export default useWindowSize
