import { styled } from "@mui/material"

const Wrapper = styled("div")`
  width: 100%;
  margin-bottom: 4rem;

  & .MuiTabs-indicator {
    background-color: #006cc8;
  }

  & .Mui-selected {
    color: #006cc8;
  }

  & .MuiTab-wrapper {
    letter-spacing: 1.25px;
  }
`

export default Wrapper
