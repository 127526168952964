import { styled } from "@mui/material"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import Divider, { DividerProps } from "@mui/material/Divider"

/**
 * AppBar height varies depending on viewport size. The values are fetched from MUI theme.mixins.toolbar.
 * The SideBarHeader needs to have the same height.
 * The height attribute has to correspond to these variations, else the it won't be horizontally aligned with the AppBar's bottom.
 *
 * @returns {String} the css in SC style for the height of the AppBar
 */
// const buildHeaderHeightAttribute = () => {
//   return `height: 56px;
//             @media (min-width: 0px) and (orientation: landscape) {
//               height: 48px;
//             }
//             @media (min-width: 600px) {
//               height: 64px;
//             }`
// }

export const StyledContainer = styled("nav")`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  height: 100%;
`

export const StyledHeader = styled("section")`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
`
export const StyledIconButton = styled(IconButton as React.FC<IconButtonProps>)`
  height: 48px;
  width: 48px;
`

export const StyledClassroomName = styled("section")`
  width: 100%;
`

export const StyledAppVersion = styled("section")`
  margin-left: 1.8em;
  margin-bottom: 1em;
`
export const StyledDivider = styled(Divider as React.FC<DividerProps>)`
  width: 100%;
`
export const StyledProductsContainer = styled("section")`
  margin: 1.25rem 0 1.25rem 1.25rem;
`
export const StyledProductLinksContainer = styled("section")`
  flex-grow: 1;
  margin: 1.25rem;
`
