import React from "react"
import { connect } from "react-redux"
import { RootState } from "../../modules/store"
import { Products } from "../../modules/products/types"
import * as productActions from "../../modules/products/actions"
import {
  selectActiveProductId,
  selectClassroomProducts
} from "../../modules/products/selectors"
import * as routeSelectors from "../../modules/router/selectors"
import { MODULE_NAME as mod } from "../../modules/products/constants"
import * as routerActions from "../../modules/router/actions"
import useLoader from "../../hooks/useLoader"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material"
import CoverImageAndTitle from "../../components/CoverImageAndTitle"

const NOOP = () => {}

type StateProps = {
  products: Products
  activeProductId: number
  currentRoute: string
}

type DispatchProps = {
  changeProduct: typeof productActions.changeActiveProduct
}

type Props = StateProps & DispatchProps

const disabledRoutes = [
  routerActions.ROUTE_UPPDRAG_REDIGERA,
  routerActions.ROUTE_UPPDRAG_LAGGTILL_NYTT,
  routerActions.ROUTE_UPPDRAG_INFO,
  routerActions.ROUTE_UPPDRAG_STUDENT_INFO,
  routerActions.ROUTE_OVNING_INFO,
  routerActions.ROUTE_FORMATIVA_FRAGOR_ELEV_INFO,
  routerActions.ROUTE_FORMATIVA_FRAGOR_INFO,
  routerActions.ROUTE_FORMATIVA_FRAGOR_LAGGTILL,
  routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA
]

export const ProductSelector = ({
  products,
  activeProductId,
  changeProduct,
  currentRoute
}: Props) => {
  const [chooseProductDialog, setChooseProductDialog] = React.useState(false)
  const [productId, setProductId] = React.useState(activeProductId)

  const isLoading = useLoader({
    init: productActions.SERVER_MESSAGE_ACTION.CHANGE_ACTIVE_PRODUCT_REQUEST,
    mod
  })

  const activeProduct = React.useMemo(() => {
    return products.find(p => p.id === activeProductId)
  }, [activeProductId, products])

  const isDisabled = disabledRoutes.indexOf(currentRoute) > -1

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductId(Number(event.target.value))
  }

  const onConfirm = () => {
    changeProduct(productId)
    setChooseProductDialog(false)
  }
  return (
    <ListItem disablePadding sx={{ background: "rgba(48, 101, 98, 0.13)" }}>
      <Stack direction="row" spacing={2} width="100%">
        <Stack direction="column" px={2} width="100%">
          <Typography variant="body2" color="textSecondary">
            Valt läromedel
          </Typography>
          <Typography>
            {activeProduct && (activeProduct.niceName || activeProduct.title)}
          </Typography>
          {products.length > 1 && (
            <Button
              sx={{ my: 2 }}
              variant="outlined"
              disabled={isDisabled || isLoading}
              onClick={() => setChooseProductDialog(true)}
            >
              Välj läromedel
            </Button>
          )}
        </Stack>
      </Stack>
      <Dialog
        onClose={() => setChooseProductDialog(false)}
        open={chooseProductDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Välj läromedel</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <List disablePadding>
            <FormControl fullWidth>
              <RadioGroup
                value={productId}
                aria-labelledby="choose-product"
                onChange={handleChange}
              >
                {products.map((p, index) => (
                  <ListItem
                    key={p.id}
                    disableGutters
                    divider={index < products.length - 1}
                    disablePadding
                  >
                    <ListItemButton disableGutters sx={{ cursor: "pointer" }}>
                      <FormControlLabel
                        sx={{ width: "100%", pl: "1.5rem" }}
                        value={p.id}
                        control={<Radio />}
                        label={
                          <Stack direction="row" alignItems="center" p={0}>
                            <ListItemAvatar
                              sx={{
                                pt: "0.6rem",
                                ml: "1rem",
                                minWidth: "3rem"
                              }}
                            >
                              <CoverImageAndTitle
                                productInfo={p}
                                toggleMenuHandler={NOOP}
                              />
                            </ListItemAvatar>
                            {p.niceName || p.title}
                          </Stack>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </RadioGroup>
            </FormControl>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChooseProductDialog(false)}>Avbryt</Button>
          <Button onClick={onConfirm}>Visa läromedel</Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  products: selectClassroomProducts(state),
  activeProductId: selectActiveProductId(state),
  currentRoute: routeSelectors.selectCurrentRoute(state)
})

const mapDispatchToProps: DispatchProps = {
  changeProduct: productActions.changeActiveProduct
}

const ConnectedProductSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSelector)

export default ConnectedProductSelector
