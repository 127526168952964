import React from "react"
import { ListItemText, ListItemIcon } from "@mui/material"
import {
  StyledExerciseListContainer,
  StyledListItem
} from "./StyledTreeViewList"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ExerciseListItem from "../ExerciseListItem"
import {
  ParentOrExerciseNode,
  ParentNode,
  ExerciseNode
} from "../../modules/exercises/types"
import {
  isParent,
  countNumberOfNodesBelowNode
} from "../TreeView/common/helpers"
import { ExerciseProgress } from "../../modules/exercisesProgress/types"
import { Members } from "../../modules/members/types"
import * as TreeTypes from "../TreeView/TreeTypes"
import BookPagesListItem from "../BookPagesListItem"
import { ChapterOrPage } from "../../modules/book/types"
import handleKeyboard from "../../shared/tools/button-keyhandler"

type Props = {
  nodes: TreeTypes.Nodes
  checkedNodes: TreeTypes.NodeIds
  currentMobileBreadcrumbs: ParentNode[]
  exercisesProgress: ExerciseProgress[]
  members?: Members
  onCheckboxClicked: Function
  onMobileRowClick: Function
  onBreadcrumbClick: Function
  onProgressDialogOpen: (node: ExerciseNode) => void
  onItemClick?: Function
  type: "exercises" | "pages" | "studentCard"
  selectable?: boolean
  hideStatus: boolean
  onlyOneMember?: boolean
}

const TreeViewList = ({
  nodes,
  onCheckboxClicked,
  checkedNodes,
  onMobileRowClick,
  currentMobileBreadcrumbs,
  onBreadcrumbClick,
  exercisesProgress,
  members,
  onProgressDialogOpen,
  onItemClick,
  hideStatus,
  type,
  selectable = false,
  onlyOneMember = false
}: Props) => {
  const renderBreadcrumbs = () => {
    return (
      <StyledListItem divider={true}>
        {currentMobileBreadcrumbs.length >= 1 && (
          <ListItemIcon>
            <ArrowBackIosIcon
              onClick={() =>
                onBreadcrumbClick(
                  currentMobileBreadcrumbs[currentMobileBreadcrumbs.length - 2]
                )
              }
            />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            currentMobileBreadcrumbs.length < 1
              ? type === "exercises" || type === "studentCard"
                ? "Övningar"
                : "Bok"
              : currentMobileBreadcrumbs[currentMobileBreadcrumbs.length - 1]
                  .title
          }
        ></ListItemText>
      </StyledListItem>
    )
  }

  const renderTreeItem = (node: TreeTypes.ParentOrLeafNode) => {
    const isChecked = checkedNodes.some(
      exerciseId => exerciseId === node.uniqueId
    )

    let numberOfNodesBelow = 0
    if (isParent(node)) {
      numberOfNodesBelow = countNumberOfNodesBelowNode(node)
    }

    return (
      <span
        key={node.uniqueId}
        onClick={() => onMobileRowClick(node)}
        role="button"
        tabIndex={0}
        onKeyUp={handleKeyboard(() => onMobileRowClick(node))}
      >
        {type === "exercises" || type === "studentCard" ? (
          <ExerciseListItem
            numberOfNodesBelow={numberOfNodesBelow}
            node={node as ParentOrExerciseNode}
            onItemClick={onItemClick ? onItemClick : undefined}
            onCheckboxClicked={onCheckboxClicked}
            isChecked={isChecked}
            exercisesProgress={exercisesProgress}
            members={members}
            hideStatus={hideStatus}
            selectable={selectable}
            onProgressDialogOpen={onProgressDialogOpen}
            type={type}
          ></ExerciseListItem>
        ) : (
          <BookPagesListItem
            numberOfNodesBelow={numberOfNodesBelow}
            node={node as ChapterOrPage}
            onItemClick={onItemClick ? onItemClick : undefined}
            onCheckboxClicked={onCheckboxClicked}
            isChecked={isChecked}
          />
        )}
      </span>
    )
  }

  return (
    <>
      {renderBreadcrumbs()}
      <StyledExerciseListContainer>
        {nodes.map(node => renderTreeItem(node))}
      </StyledExerciseListContainer>
    </>
  )
}

export default TreeViewList
