import React, { useState, useEffect } from "react"
import { StyledProgress, StyledProgressBar } from "./StyledProgress"
import Tooltip from "@mui/material/Tooltip"
import { PROGRESS_SEGMENT } from "./constants"
import { Widths } from "./types"
const MIN_WIDTH = 10

type Props = {
  done: number
  inProgress: number
  notStarted: number
  children?: never
  type:
    | "exercises"
    | "goals"
    | "assignments"
    | "assignmentsTask"
    | "formativeQuestions"
  onOpenProgressDialog: Function
}

const Progress = ({
  done,
  inProgress,
  notStarted,
  onOpenProgressDialog,
  type
}: Props) => {
  const total = done + inProgress + notStarted
  const [widths, setWidths] = useState<Widths>({
    done: 0,
    notStarted: 0,
    inProgress: 0,
    noMembers: 100
  })

  useEffect(() => {
    calculateWidth()
    // eslint-disable-next-line
  }, [done, inProgress, notStarted])

  const calculateWidth = () => {
    const arrayOfBars = [
      { key: "done", value: done },
      { key: "inProgress", value: inProgress },
      { key: "notStarted", value: notStarted }
    ].sort((a, b) => a.value - b.value)
    let collectedWidths = { ...widths }
    let currentTotalWidth = 0

    arrayOfBars.forEach(bar => {
      let percentage = Math.round((bar.value / total) * 100)
      if (percentage + currentTotalWidth > 100) {
        percentage = 100 - currentTotalWidth
      }

      const returnWidth = bar.value
        ? percentage >= MIN_WIDTH
          ? percentage
          : MIN_WIDTH
        : bar.value

      currentTotalWidth += returnWidth
      collectedWidths = {
        ...collectedWidths,
        [bar.key]: returnWidth
      }
    })
    setWidths(collectedWidths)
  }

  const getTooltipType = () => {
    switch (type) {
      case "exercises":
        return "övningen"
      case "goals":
        return "målet"
      case "assignments":
        return "uppdraget"
      case "formativeQuestions":
        return "testet"
      case "assignmentsTask":
        return "deluppgiften"
    }
  }

  const openProgressDialogIfMembers = (event: any) => {
    event.stopPropagation()
    if (canOpenProgressDialog) {
      onOpenProgressDialog()
    }
  }
  const canOpenProgressDialog =
    done + inProgress > 0 ||
    type === "assignments" ||
    type === "formativeQuestions"

  const renderProgressBar = () => {
    return (
      <>
        {done !== 0 && (
          <Tooltip
            title={`Antal elever som ${
              type === "assignments" ? "lämnat in " : "är färdiga med "
            } ${getTooltipType()}`}
          >
            <StyledProgressBar segment={PROGRESS_SEGMENT.DONE} widths={widths}>
              {done}
            </StyledProgressBar>
          </Tooltip>
        )}

        {inProgress !== 0 && (
          <Tooltip title={`Antal elever som påbörjat ${getTooltipType()}`}>
            <StyledProgressBar
              segment={PROGRESS_SEGMENT.IN_PROGRESS}
              widths={widths}
            >
              {inProgress}
            </StyledProgressBar>
          </Tooltip>
        )}

        {notStarted > 0 && (
          <Tooltip title={`Antal elever som ej påbörjat ${getTooltipType()}`}>
            <StyledProgressBar
              segment={PROGRESS_SEGMENT.NOT_STARTED}
              widths={widths}
            >
              {notStarted}
            </StyledProgressBar>
          </Tooltip>
        )}
      </>
    )
  }

  return (
    <StyledProgress
      clickable={canOpenProgressDialog}
      onClick={openProgressDialogIfMembers}
    >
      {total ? (
        renderProgressBar()
      ) : (
        <Tooltip title="Inga elever i den valda gruppen har aktiverad produkt.">
          <StyledProgressBar
            sx={{ minWidth: Boolean(widths.inProgress) ? "100%" : null }}
            segment={PROGRESS_SEGMENT.IN_PROGRESS}
            widths={widths}
          ></StyledProgressBar>
        </Tooltip>
      )}
    </StyledProgress>
  )
}

export default Progress
