import React, { SyntheticEvent } from "react"
import { connect } from "react-redux"

import { setSnackbar } from "../../modules/application/actions"
import { RootState } from "../../modules/store"
import { IconButton } from "@mui/material"

import MuiSnackbar from "@mui/material/Snackbar"

import { StyledSnackbarContent, StyledMessage } from "./StyledSnackbar"

import ErrorIcon from "@mui/icons-material/Error"
import InfoIcon from "@mui/icons-material/Info"
import WarningIcon from "@mui/icons-material/Warning"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CloseIcon from "@mui/icons-material/Close"
import { Props, StateProps, DispatchProps } from "./types"

const Snackbar = (props: Props) => {
  const { severity, message } = props.snackbar || {
    severity: "info",
    message: ""
  }

  const autoHideDuration =
    props.autoHideDuration || severity === "error" ? 60000 : 4000

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }

    props.setSnackbar()
  }

  /**
   * Render correct icon (to be placed to the left of the message) depending on severity of the message.
   *
   * @returns {Object} - Icon to show to the left of the message.
   */
  const renderIcon = (): React.ReactElement => {
    const style = {
      fontSize: "1.3em",
      opacity: 0.9,
      marginRight: "0.9em"
    }

    let icon = <InfoIcon style={style} />

    switch (severity) {
      case "error":
        icon = <ErrorIcon style={style} />
        break
      case "info":
        icon = <InfoIcon style={style} />
        break
      case "success":
        icon = <CheckCircleIcon style={style} />
        break
      case "warning":
        icon = <WarningIcon style={style} />
        break
      default:
        icon = <InfoIcon style={style} />
    }

    return icon
  }

  const renderSnackbarContent = () => {
    return (
      <StyledSnackbarContent
        snackbar={props.snackbar}
        message={
          <StyledMessage>
            {renderIcon()}
            {message}
          </StyledMessage>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    )
  }

  return (
    <MuiSnackbar
      color={"yellow"}
      open={Boolean(props.snackbar)}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      transitionDuration={{ enter: 700, exit: 200 }}
    >
      {renderSnackbarContent()}
    </MuiSnackbar>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  snackbar: state.application.snackbar || undefined
})

const mapDispatchToProps: DispatchProps = {
  setSnackbar: setSnackbar
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)
