import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"

import { RootState } from "../../modules/store"
import { Dialog } from "@mui/material"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import CustomTreeView from "../../components/TreeView"
import ExerciseList from "../../components/TreeViewList"
import TitleAndDescription from "../../components/TitleAndDescription"
import {
  Exercise,
  ParentOrExerciseNode,
  ExerciseId,
  ParentNode,
  ExerciseNode
} from "../../modules/exercises/types"
import { ExerciseProgress } from "../../modules/exercisesProgress/types"
import * as exercisesSelector from "../../modules/exercises/selectors"
import * as exercisesProgressSelector from "../../modules/exercisesProgress/selectors"
import { isParent } from "./common/helpers"
import { Members } from "../../modules/members/types"
import {
  SetTreeStatusAction,
  TreeStatus,
  TreeStatusUpdate,
  MemberWithOnlineStatus
} from "./store/types"
import * as membersSelector from "../../modules/members/selectors"
import * as exercisesPageActions from "./store/actions"
import * as exercisesPageSelector from "./store/selectors"
import ExercisesProgressDialog from "../../components/ExercisesProgressDialog"
import { User } from "../../modules/users/types"
import { selectUsersOnline } from "../../modules/users/selectors"
import { selectClassroomId } from "../../modules/classroom/selectors"
import * as routerActions from "../../modules/router/actions"
import { selectActiveProductName } from "../../modules/products/selectors"
import { StyledLoaderWrapper } from "../Goals/StyledGoals"
import Loader from "../../components/Loader"
import useLoader from "../../hooks/useLoader"
import { MODULE_NAME as mod } from "../../modules/exercises/constants"
import * as exerciseActions from "../../modules/exercises/actions"
import CenteredContainer from "../../containers/PageContainers/CenteredContainer"

type StateProps = {
  exercises: Exercise
  exercisesProgress: ExerciseProgress[]
  members: Members
  usersOnline: User["studliId"][] | null
  treeStatus: TreeStatus
  classroomId: number
}

type DispatchProps = {
  setTreeStatus: (payload: TreeStatusUpdate) => SetTreeStatusAction
  gotoRoute: (route: string, payload: object) => void
}

type ProgressDialogDataProps = {
  done: MemberWithOnlineStatus[]
  inProgress: MemberWithOnlineStatus[]
  title: string
}

type Props = StateProps & DispatchProps

const Exercises = ({
  exercisesProgress,
  members,
  usersOnline = [],
  exercises,
  treeStatus,
  setTreeStatus,
  gotoRoute,
  classroomId
}: Props) => {
  const isLoading = useLoader({
    init: exerciseActions.SERVER_MESSAGE_ACTION.GET_ALL_EXERCISES_REQUEST,
    mod
  })
  const [progressDialogData, setProgressDialogData] =
    useState<ProgressDialogDataProps | null>(null)
  const activeProductName = useSelector(selectActiveProductName)
  useEffect(() => {
    if (!treeStatus.currentMobileNodeChosen.length && exercises.length) {
      setTreeStatus({ currentMobileNodeChosen: exercises })
    }
    // eslint-disable-next-line
  }, [exercises])

  const onCheckboxClicked = (event: Event, node: ParentOrExerciseNode) => {
    event.stopPropagation()
    const nodeIsAlreadyChecked = treeStatus.checkedExercises.some(
      exerciseId => node.uniqueId === exerciseId
    )
    const nodeIds = traverseForIdsBelowNode(node)
    let newCheckedExercises = [...treeStatus.checkedExercises]
    if (nodeIsAlreadyChecked) {
      newCheckedExercises = newCheckedExercises.filter(
        exerciseId => !nodeIds.some(nodeId => nodeId === exerciseId)
      )
    } else {
      newCheckedExercises = [...newCheckedExercises, ...nodeIds]
    }
    setTreeStatus({ checkedExercises: newCheckedExercises })
  }

  const traverseForIdsBelowNode = (node: ParentOrExerciseNode) => {
    const nodeIds: ExerciseId[] = []
    traverse(node, nodeIds)

    return nodeIds
  }

  const traverse = (node: ParentOrExerciseNode, nodeIds: ExerciseId[]) => {
    nodeIds.push(node.uniqueId)
    if (isParent(node) && node.children) {
      node.children.forEach((child: ParentOrExerciseNode) => {
        traverse(child, nodeIds)
      })
    }
  }

  const setExpandedNodes = (nodes: ExerciseId[]) => {
    setTreeStatus({ expandedNodes: nodes })
  }

  const setTopNodesNotExpanded = (nodes: ExerciseId[]) => {
    setTreeStatus({ topNodesNotExpanded: nodes })
  }

  const onMobileRowClick = (node: ParentOrExerciseNode) => {
    if (isParent(node)) {
      const newBreadcrumbs = [...treeStatus.currentMobileBreadcrumbs, node]
      setTreeStatus({
        currentMobileNodeChosen: node.children,
        currentMobileBreadcrumbs: newBreadcrumbs
      })
      return
    }
    onExerciseClick(node)
  }

  const onBreadcrumbClick = (node: ParentNode) => {
    if (treeStatus.currentMobileBreadcrumbs.length === 1) {
      setTreeStatus({
        currentMobileNodeChosen: exercises,
        currentMobileBreadcrumbs: []
      })

      return
    }
    const newBreadcrumbs = [...treeStatus.currentMobileBreadcrumbs]
    newBreadcrumbs.splice(treeStatus.currentMobileBreadcrumbs.length - 1)
    setTreeStatus({
      currentMobileNodeChosen: node.children,
      currentMobileBreadcrumbs: newBreadcrumbs
    })
  }

  const renderTitleAndDescription = () => {
    const paragraphsWhenFoundExercises = [
      `I listan över övningar ser du de självrättande övningar och andra resurser som eleven har tillgång till i sitt digitala läromedel. Du kan lätt se vilka övningar eleverna gjort. `
    ]
    const paragraphsWhenNoExercises = [
      `Det finns inga självrättande övningar i ${activeProductName}`
    ]
    return (
      <TitleAndDescription
        title="Läromedlets övningar"
        paragraphs={
          exercises.length
            ? paragraphsWhenFoundExercises
            : paragraphsWhenNoExercises
        }
      />
    )
  }

  const onProgressDialogOpen = (node: ExerciseNode) => {
    const inProgress = exercisesProgress.filter(
      progress => progress.exerciseId === node.id && progress.isInProgress
    )
    const done = exercisesProgress.filter(
      progress => progress.exerciseId === node.id && !progress.isInProgress
    )

    const membersInProgress: MemberWithOnlineStatus[] = []
    const membersDone: MemberWithOnlineStatus[] = []

    inProgress.forEach(progress => {
      const memberInProgress = members.find(
        member => member.studliId === progress.studliId
      )

      if (memberInProgress) {
        const memberInProgressWithOnlineStatus: MemberWithOnlineStatus =
          memberInProgress as MemberWithOnlineStatus
        memberInProgressWithOnlineStatus.isOnline = usersOnline
          ? usersOnline.includes(memberInProgress.studliId)
          : false

        membersInProgress.push(memberInProgressWithOnlineStatus)
      }
    })

    done.forEach(progress => {
      const memberDone = members.find(
        member => member.studliId === progress.studliId
      )
      if (memberDone) {
        const memberDoneWithOnlineStatus: MemberWithOnlineStatus =
          memberDone as MemberWithOnlineStatus
        memberDoneWithOnlineStatus.isOnline = usersOnline
          ? usersOnline.includes(memberDone.studliId)
          : false

        membersDone.push(memberDoneWithOnlineStatus)
      }
    })

    setProgressDialogData({
      done: membersDone,
      inProgress: membersInProgress,
      title: node.title
    })
  }

  const closeProgressDialog = () => {
    setProgressDialogData(null)
  }

  const onExerciseClick = (node: ExerciseNode) => {
    gotoRoute(routerActions.ROUTE_OVNING_INFO, {
      classroomId,
      exerciseId: node.id
    })
  }

  const renderLoader = () => (
    <StyledLoaderWrapper>
      <Loader color="green" delay={0} text="Hämtar övningar" />
    </StyledLoaderWrapper>
  )

  return (
    <CenteredContainer>
      {renderTitleAndDescription()}
      {isLoading && renderLoader()}
      {exercises.length > 0 && !isLoading && (
        <MobileOrDesktop>
          {{
            desktop: (
              <CustomTreeView
                type={"exercises"}
                nodes={exercises}
                expandedNodes={treeStatus.expandedNodes}
                setExpandedNodes={setExpandedNodes}
                topNodesNotExpanded={treeStatus.topNodesNotExpanded}
                setTopNodesNotExpanded={setTopNodesNotExpanded}
                checkedNodes={treeStatus.checkedExercises}
                onCheckboxClicked={onCheckboxClicked}
                members={members}
                exercisesProgress={exercisesProgress}
                onProgressDialogOpen={onProgressDialogOpen}
                hideStatus={false}
                onItemClick={onExerciseClick}
              />
            ),
            mobile: (
              <ExerciseList
                nodes={treeStatus.currentMobileNodeChosen}
                onMobileRowClick={onMobileRowClick}
                checkedNodes={treeStatus.checkedExercises}
                onCheckboxClicked={onCheckboxClicked}
                currentMobileBreadcrumbs={treeStatus.currentMobileBreadcrumbs}
                onBreadcrumbClick={onBreadcrumbClick}
                members={members}
                exercisesProgress={exercisesProgress}
                onProgressDialogOpen={onProgressDialogOpen}
                type="exercises"
                hideStatus={false}
              />
            )
          }}
        </MobileOrDesktop>
      )}
      {progressDialogData !== null && (
        <Dialog
          maxWidth="lg"
          onClose={closeProgressDialog}
          open={progressDialogData !== null}
        >
          <ExercisesProgressDialog
            onClose={closeProgressDialog}
            inProgress={progressDialogData.inProgress}
            done={progressDialogData.done}
            type="exercises"
            title={progressDialogData.title}
          />
        </Dialog>
      )}
    </CenteredContainer>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    exercises: exercisesSelector.selectAllExercises(state),
    exercisesProgress:
      exercisesProgressSelector.selectExerciseProgressWithTemporaryProgress(
        state
      ),
    members: membersSelector.selectStudentsWithProduct(state),
    usersOnline: selectUsersOnline(state),
    treeStatus: exercisesPageSelector.selectTreeStatus(state),
    classroomId: selectClassroomId(state)
  }
}

const mapDispatchToProps: DispatchProps = {
  setTreeStatus: exercisesPageActions.setTreeStatus,
  gotoRoute: routerActions.gotoRoute
}

const ConnectedExercises = connect(
  mapStateToProps,
  mapDispatchToProps
)(Exercises)

export default ConnectedExercises
