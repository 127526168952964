import * as React from "react"
import CancelIcon from "@mui/icons-material/Cancel"

import { ReactComponent as PlayIcon } from "../../assets/images/recorder-play.svg"
import { ReactComponent as PauseIcon } from "../../assets/images/recorder-pause.svg"
import useAudio from "../AudioPlayer/useAudio"
import { StyledButton, Time, Wrapper } from "./StyledAudioRecorder"
import { getTime } from "../../shared/tools/misc"
import { Button, Typography } from "@mui/material"
import { Box } from "@mui/system"

type Props = {
  src: string
  onCancel: () => void
  resetRecording?: () => void
  fullWidth?: boolean
}

const Playback: React.FC<Props> = ({
  src,
  onCancel,
  resetRecording,
  fullWidth
}) => {
  const [audioSrcError, setAudioSrcError] = React.useState(false)

  const [
    { duration, currentTime, exactCurrentTime, playing },
    toggle,
    setPlayer
  ] = useAudio(src)

  const handleOnClick = () => {
    if (resetRecording !== undefined) {
      resetRecording()
      setAudioSrcError(false)
    }
  }
  return (
    <>
      {!audioSrcError ? (
        <Wrapper
          to={duration}
          from={exactCurrentTime}
          fullWidth={fullWidth}
          onError={() => setAudioSrcError(true)}
        >
          <StyledButton
            onClick={toggle}
            aria-label={playing ? "Pausa" : "Spela upp"}
          >
            {playing ? <PauseIcon /> : <PlayIcon />}
          </StyledButton>
          <Time>
            {getTime(currentTime)} / {getTime(duration)}
          </Time>
          <StyledButton onClick={onCancel} aria-label="Ta bort inspelning">
            <CancelIcon />
          </StyledButton>
          <audio ref={setPlayer} />
        </Wrapper>
      ) : (
        <Box display="flex" flexDirection="column">
          <Typography sx={{ fontStyle: "italic" }}>
            Ljudfilen har tagits bort
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ alignSelf: "flex-end" }}
            onClick={handleOnClick}
          >
            Återställ
          </Button>
        </Box>
      )}
    </>
  )
}

export default Playback
