import { AssignmentTask } from "../../modules/assignments/types"
import { Goals } from "../../modules/goals/types"
import { FilterOptions } from "./types"

export const extractFiltersFromGoals = (goals: Goals) => {
  return goals.reduce((acc, curr) => {
    return acc[curr.grade]
      ? acc
      : {
          ...acc,
          [curr.grade]: { title: `Åk ${curr.grade}`, value: curr.grade }
        }
  }, {} as FilterOptions)
}

export const convertGoalsToTasks = (goals: Goals): AssignmentTask[] =>
  goals.map(g => ({
    id: g.goalRef,
    type: "goal",
    title: g.title,
    link: g.goalRef
  }))
