import React from "react"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { Store } from "redux"
import { Provider } from "react-redux"
import "react-dates/lib/css/_datepicker.css"

import theme from "../shared/styles/theme"

import AppInitializer from "../containers/AppInitializer"
import AppShell from "../pages/AppShell"
import TopBar from "../containers/TopBar"
import SideBar from "../containers/SideBar"
import Router from "../containers/Router"
import { StyledContainer } from "./StyledApp"
import { Auth } from "../containers/Auth/Auth"
import ConfirmBeforeLeaveDialog from "../components/ConfirmBeforeLeaveDialog/ConfirmBeforeLeaveDialog"
declare global {
  interface Window {
    Modernizr: any
  }
}

type Props = {
  store: Store
}

const App: React.FC<Props> = ({ store }) => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <StyledContainer>
          <Auth>
            <AppInitializer>
              <AppShell>
                {{
                  topBar: <TopBar />,
                  sideBar: <SideBar />,
                  content: <Router />
                }}
              </AppShell>
            </AppInitializer>
          </Auth>
        </StyledContainer>
        <ConfirmBeforeLeaveDialog />
      </MuiThemeProvider>
    </Provider>
  )
}

export default App
