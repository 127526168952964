import React from "react"

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { ReactComponent as InfoOutlinedIcon } from "../../assets/images/menu-information.svg"

import { Popover } from "@mui/material"
import { StyledPopoverContent, StyledIconButton } from "./StyledInfoPopover"

export type OwnProps = {
  type: "Help" | "Info"
  content: React.ReactNode
  padding?: boolean
  color?: string
  anchor?: "top" | "bottom" | "center"
}

export const InfoPopover = ({
  type,
  content,
  padding = true,
  color,
  anchor = "bottom"
}: OwnProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <React.Fragment>
      <StyledIconButton
        aria-describedby={id}
        onClick={handleClick}
        custom_color={color ? color : ""}
        padding={padding ? 1 : 0}
        aria-label={type === "Help" ? "Hjälp" : "Mer info"}
      >
        {type === "Help" ? <HelpOutlineOutlinedIcon /> : <InfoOutlinedIcon />}
      </StyledIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchor,
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {content && <StyledPopoverContent>{content}</StyledPopoverContent>}
      </Popover>
    </React.Fragment>
  )
}

export default InfoPopover
