import {
  TableCell,
  TableCellProps,
  Paper,
  Theme,
  Typography,
  styled
} from "@mui/material"
export const StyledHeaderContainer = styled("div")`
  margin: 1rem 3rem 2rem 3rem;
`

export const StyledTitle = styled(Typography)`
  font-size: 1.2rem;
  margin-right: 1em;
  font-weight: 350;
  padding: 0.2em;
`

export const StyledPathContainer = styled("div")`
  margin-top: 1rem;
  display: flex;
`
export const StyledIcon = styled("div")`
  color: #006cc8;
`
export const StyledNameAndResult = styled("div")`
  margin-left: 0.5rem;
  margin-top: 0.5rem;
`

export const StyledTableCell = styled(TableCell)`
  display: flex;
`

export const StyledPathText = styled(Typography)`
  font-size: 1em;
  display: flex;
  font-weight: 350;
`

export const StyledPaper = styled(Paper)`
  margin: 2rem 3rem 0 3rem;
`
export const StyledHeadCell = styled(TableCell as React.FC<TableCellProps>)`
  text-align: left;
  color: ${props => (props.theme as Theme).palette.text.secondary};
`

type StatusTagProps = {
  status: "done" | "inProgress" | "notStarted"
}
export const StyledStatusTag = styled("div")`
  color: ${(props: StatusTagProps) => getStatusColor(props.status)};
  background-color: ${(props: StatusTagProps) =>
    getStatusBackgroundColor(props.status)};
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 4px 2px 4px;
  display: inline-block;
  margin-left: 0.3rem;
`
const getStatusBackgroundColor = (status: string) => {
  switch (status) {
    case "done":
      return "#006cc8"
    case "inProgress":
      return "#42b9fe"
    case "notStarted":
      return "#ecf4fb"
  }
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "done":
      return "white"
    case "inProgress":
      return "white"
    case "notStarted":
      return "#006cc8"
  }
}

export const StyledPath = styled("div")``
