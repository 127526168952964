export const testForAudioOrText = (
  path: string,
  createError: Function,
  value: any
) => {
  if ((value.text && value.text.length > 7) || value.audio) {
    return true
  }

  return createError({
    path,
    message: "Det saknas en fråga. Skriv och/eller spela in frågan."
  })
}
