type Primer = (v: any) => any

type SortReturn = 0 | 1 | -1

type Detailed<K> = {
  name: K
  primer?: Primer
  reverse?: boolean
}

type FieldCompile<T, K extends keyof T> = {
  name: K
  f: (a: T[K], b: T[K]) => SortReturn
}

function createDefaultSortFunc<T>() {
  return (a: T[keyof T], b: T[keyof T]): SortReturn => {
    if (a === b) {
      return 0
    }

    return a < b ? -1 : 1
  }
}

function createReverser<T>(f: (a: T[keyof T], b: T[keyof T]) => SortReturn) {
  return (a: T[keyof T], b: T[keyof T]) => {
    const result = f(a, b)

    return (-1 * result) as SortReturn
  }
}

function createMaybePrimer<T>(
  f: (a: T[keyof T], b: T[keyof T]) => SortReturn,
  primer?: Primer
) {
  return primer ? (a: T[keyof T], b: T[keyof T]) => f(primer(a), primer(b)) : f
}

function createSortFunc<T>(primer?: Primer, reverse = false) {
  const def = createDefaultSortFunc<T>()
  const withMaybePrimer = createMaybePrimer<T>(def, primer)

  return reverse ? createReverser<T>(withMaybePrimer) : withMaybePrimer
}

const processFields = <T, K extends keyof T>(
  fields: (Detailed<K> | K)[]
): FieldCompile<T, K>[] =>
  fields.map(field => {
    if (typeof field === "string") {
      return {
        name: field,
        f: createDefaultSortFunc<T>()
      }
    }

    const { name, primer, reverse } = field as Detailed<K>

    return {
      name,
      f: createSortFunc(primer, reverse)
    }
  })

function sortBy<T>(...fields: (Detailed<keyof T> | keyof T)[]) {
  const processedFields = processFields<T, keyof T>(fields)

  return (a: T, b: T) =>
    processedFields.reduce((result, field) => {
      if (result !== 0) {
        return result
      }

      const { name, f } = field

      return f(a[name], b[name])
    }, 0)
}

export default sortBy
