import { styled, TableCell, TableCellProps } from "@mui/material"
import TableRow from "@mui/material/TableRow"

export const StyledMenuTableCell = styled(
  TableCell as React.FC<TableCellProps>
)``

export const StyledTitleCell = styled(TableCell)`
  min-width: 30%;
  > * {
    vertical-align: middle;
  }
`

export const StyledTitle = styled("span")`
  margin-right: 10px;
`

export const StyledStatusCell = styled(TableCell)`
  min-width: 20%;
`

export const StyledSmallCell = styled(TableCell as React.FC<TableCellProps>)`
  padding-right: 0;
  width: 1px;
`

export const StyledTableRow = styled(TableRow as any)`
  &:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }
  cursor: pointer;

  ${({ missingGroups }) =>
    missingGroups &&
    `background-color: rgba(218, 30, 40, 0.1);
    &:hover {
      background-color: rgba(218, 30, 40, 0.2);
    }
    `}

  ${({ redbar }) =>
    redbar === 1 &&
    `
      border-left: 5px solid #CC0000;
  `}
  td:first-child {
    padding-left: 1rem;
  }
  td:last-child {
    padding-right: 1rem;
  }
`

export const StyledErrorMsg = styled("span")`
  height: 20px;
  color: #cc0000;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.23px;
  line-height: 20px;
  margin-top: 6px;
`

export const StyledSticker = styled("img")`
  width: 40px;
`
