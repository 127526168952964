import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import AbilityIndicator from "../AbilityIndicator"
import { MobileProps } from "./common/types"
import { renderStatus } from "./common/helpers"
import LastActive from "../LastActive"
import Duration from "../../components/Duration/Duration"
import React from "react"
import {
  StyledGoalTable,
  StyledNameSpan,
  StyledAvatar,
  StyledCompletedTask,
  StyledUnCompletedTask,
  StyledGoalWrapper,
  StyledInfoWrapper,
  StyledAvatarAndAbilityWrapper
} from "./StyledMobileGoalPageTable"

export const MobileGoalTable = (props: MobileProps) => {
  return (
    <StyledGoalTable>
      <TableContainer component={Paper}>
        <Table aria-label="Mål tabell">
          <TableBody>
            {props.data.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  <StyledGoalWrapper>
                    <StyledAvatarAndAbilityWrapper>
                      <StyledAvatar
                        firstName={row.firstName}
                        lastName={row.lastName}
                        picture={row.picture}
                        studliId={row.studliId}
                      />
                    </StyledAvatarAndAbilityWrapper>
                    <StyledInfoWrapper>
                      <StyledNameSpan>
                        {row.firstName} {row.lastName}
                      </StyledNameSpan>
                      <div>
                        {row.isInProgress ? (
                          <StyledUnCompletedTask>
                            {renderStatus(row.lastActive)}
                          </StyledUnCompletedTask>
                        ) : (
                          <StyledCompletedTask>
                            Klar - {LastActive.renderLastActive(row.lastActive)}
                          </StyledCompletedTask>
                        )}
                      </div>
                      <div>
                        {row.goalExercisesCount !== 0 &&
                          `Övningar: ${row.goalExercisesCount} `}
                        {row.goalActiveTimeMilliseconds !== 0 && (
                          <>
                            Tid:{" "}
                            <Duration
                              duration={row.goalActiveTimeMilliseconds}
                            />
                          </>
                        )}
                      </div>
                    </StyledInfoWrapper>
                    <StyledAvatarAndAbilityWrapper>
                      {row.goalAbilityGrade !== 0 && (
                        <AbilityIndicator grade={row.goalAbilityGrade} />
                      )}
                    </StyledAvatarAndAbilityWrapper>
                  </StyledGoalWrapper>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGoalTable>
  )
}
