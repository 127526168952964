import React from "react"
import { useSelector, useDispatch } from "react-redux"
import Tabs from "../../components/Tabs"
import { isActiveProductFormativeTests } from "../../modules/products/selectors"
import { SelectMembersIdWithCurrentSorting } from "../Members/store/selectors"
import Pagination from "../AssignmentStudentCard/Pagination"
import { selectStudentCardMember } from "../../modules/members/selectors"
import * as routerActions from "../../modules/router/actions"
import {
  selectClassroomId,
  selectTypeOfClassroom
} from "../../modules/classroom/selectors"
import StudentExerciseList from "../../components/StudentExerciseList"
import StudentInfo from "../../components/StudentInfo"
import StudentHistoryList from "../../components/StudentHistoryList"
import { ReactComponent as InfoOutlinedIcon } from "../../assets/images/menu-information.svg"
import { ReactComponent as ExercisesIcon } from "../../assets/images/menu-exercise.svg"
import { ReactComponent as AssignmentIcon } from "../../assets/images/menu-task.svg"
import { ReactComponent as HistoryIcon } from "../../assets/images/clock.svg"
import { ReactComponent as GoalIcon } from "../../assets/images/menu-goal.svg"

import useWindowSize from "../../hooks/useWindowSize"
import StudentAssignmentList from "../../components/StudentAssignmentList"
import StudentFormativeList from "../../components/StudentFormativeList"
import { selectExercisesLength } from "../../modules/exercises/selectors"
import { ReactComponent as TestIcon } from "../../assets/images/test-icon.svg"
import StudentGoalList from "../../components/StudentGoalList"
import FullWidthContainer from "../../containers/PageContainers/FullWidthContainer"

const StudentCard = () => {
  const hasFormativeTests = useSelector(isActiveProductFormativeTests)
  const carouselOrder = useSelector(SelectMembersIdWithCurrentSorting)
  const currentMember = useSelector(selectStudentCardMember)
  const classroomId = useSelector(selectClassroomId)
  const exercisesLength = useSelector(selectExercisesLength)
  const windowSize = useWindowSize()
  const dispatch = useDispatch()
  const classroomType = useSelector(selectTypeOfClassroom)
  let currentCarouselIndex = 0
  if (currentMember) {
    currentCarouselIndex = carouselOrder.indexOf(currentMember.studliId)
  }

  const tabs = [
    {
      label: !windowSize.md ? <InfoOutlinedIcon aria-label="Info" /> : "Info",
      Component: StudentInfo,
      filterLabel: "Info"
    },
    {
      label: !windowSize.md ? (
        <ExercisesIcon aria-label="Övningar" />
      ) : (
        "Övningar"
      ),
      Component: StudentExerciseList,
      filterLabel: "Övningar"
    },
    {
      label: !windowSize.md ? (
        <AssignmentIcon aria-label="Uppdrag" />
      ) : (
        "Uppdrag"
      ),
      Component: StudentAssignmentList,
      filterLabel: "Uppdrag"
    },
    {
      label: !windowSize.md ? <TestIcon aria-label="Test" /> : "Test",
      Component: StudentFormativeList,
      filterLabel: "Test"
    },
    {
      label: !windowSize.md ? <GoalIcon aria-label="Mål" /> : "Mål",
      Component: StudentGoalList,
      filterLabel: "Mål"
    },
    {
      label: !windowSize.md ? (
        <HistoryIcon aria-label="Historik" />
      ) : (
        "Historik"
      ),
      Component: StudentHistoryList,
      filterLabel: "Historik"
    }
  ]

  const goToStudent = (id: number) => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_ELEVKORT, {
        classroomId,
        memberId: id
      })
    )
  }

  const filterTabs = () => {
    const filter = []
    if (!hasFormativeTests) {
      filter.push("Test")
    }
    if (classroomType !== "adapt") {
      filter.push("Mål")
    }
    if (!exercisesLength) {
      filter.push("Övningar")
    }
    return filter
  }

  return (
    <FullWidthContainer useMargin role="man">
      <Pagination
        current={currentCarouselIndex + 1}
        total={carouselOrder.length}
        disableBack={currentCarouselIndex === 0}
        disableForward={currentCarouselIndex === carouselOrder.length - 1}
        back={() => goToStudent(carouselOrder[currentCarouselIndex - 1])}
        forward={() => goToStudent(carouselOrder[currentCarouselIndex + 1])}
      />

      <Tabs
        items={tabs.filter(
          tab => !filterTabs().some(f => f === tab.filterLabel)
        )}
        prefix="student-card"
        label="Historisk information"
      />
    </FullWidthContainer>
  )
}

export default StudentCard
