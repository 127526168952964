import { styled } from "@mui/material"

export const StyledAdaptProgressBar = styled("div")`
  height: 25px;
  background-color: #eeeeee;
  overflow: hidden;
  max-width: 250px;
`

export const StyledProgressFiller = styled("div")<{
  progress: number
  threshold: number
}>`
  height: 100%;
  background-color: #038763;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  width: ${props => calcWidth(props.progress, props.threshold)}%;
`

const calcWidth = (progress: number, threshold: number) => {
  const percentage = Math.round((progress / threshold) * 100)
  return percentage > 100 ? 100 : percentage
}
