import {
  takeLatest,
  put,
  take,
  all,
  select,
  delay,
  race
} from "redux-saga/effects"
import * as authActions from "../auth/actions"
import * as socketActions from "../socket/actions"

import { selectApplicationState } from "./selectors"
import { ApplicationState } from "./types"
import * as applicationActions from "./actions"

import * as membersActions from "../members/actions"
import * as classroomActions from "../classroom/actions"

import * as routerActions from "../../modules/router/actions"
import * as appShellActions from "../../pages/AppShell/store/actions"
import * as appShellSelectors from "../../pages/AppShell/store/selectors"

import { getAllMembers, RESPONSE } from "../members/actions"

import { openSocket } from "../socket/actions"

import { getApplicationVersion } from "../../shared/tools/misc"
import { MAX_AGE_OF_PENDING_REQUEST } from "../communication/constants"
import { setCommunicationError } from "../communication/actions"
import { calcSizes } from "../../hooks/useWindowSize"

const printConsoleGroupStartWithVersion = () => {
  const version = getApplicationVersion()
  const name = "Klassrum"

  if (version) {
    window.console.group(
      `%c${name} %cversion %c${version} %cinitializing`,
      "color: cyan; font-size: 1.5em",
      "",
      "color: cyan; font-size: 1.5em",
      ""
    )
  } else {
    window.console.group(
      `%c${name} initializing`,
      "color: cyan; font-size: 1.5em"
    )
  }
}

/**
 * Waiting for socket to be successfully connected,
 * after MAX_AGE_OF_PENDING_REQUEST we will trigger a timeout error
 */
function* waitForSocketConnected() {
  const { timeout } = yield race({
    socket: take(socketActions.SOCKET_CONNECTED),
    timeout: delay(MAX_AGE_OF_PENDING_REQUEST)
  })

  if (timeout) {
    yield put(
      setCommunicationError({
        id: 99,
        message: "Socket connection timed out",
        reporter: "Frontend"
      })
    )

    return true
  }

  return false
}

/**
 * Dispatch application is ready action.
 */
function* setApplicationReady() {
  printConsoleGroupStartWithVersion()

  window.console.log("Init started, authenticating...")
  yield take(authActions.AUTH_TOKEN_SUCCESS)

  window.console.log("Authenticated, opening socket...")
  yield put(openSocket())
  const failed = yield waitForSocketConnected()

  if (failed) {
    return
  }

  window.console.log("Socket opened, getting classroom info...")
  yield put(classroomActions.getClassroomInfo())
  yield take(classroomActions.RESPONSE.CLASSROOM_INFO_RESPONSE)

  window.console.log("Getting all groups")
  yield put(classroomActions.getClassroomGroups())
  yield take(classroomActions.RESPONSE.CLASSROOM_GROUPS_RESPONSE)

  window.console.log("Getting all school unit groups")
  yield put(classroomActions.getSchoolUnitGroups())
  yield take(classroomActions.RESPONSE.CLASSROOM_ALL_GROUPS_RESPONSE)

  window.console.log("getting all members...")
  const getMembersStart = Date.now()
  yield put(getAllMembers())
  yield take(RESPONSE.GET_ALL_MEMBERS_RESPONSE)

  window.console.log(
    `Members gotten. (in ${(Date.now() - getMembersStart) / 1000} s)`
  )
  yield put(applicationActions.setApplicationReady())
  window.console.groupEnd()

  window.console.log("%cKlassrum initialized.", "color:cyan")
}

/**
 * Watcher saga for application mount.
 */
export function* watchApplicationMount() {
  //DEBUG: debugger The effect of the below line is that application loads even though all members aren't gotten!
  //       It loads prior to all stuff in applicationReady(). So it loads much quicker. Perfect for debugging.
  //yield put(applicationReady())

  yield takeLatest(applicationActions.setApplicationMount, setApplicationReady)
}

/**
 * This saga will run each time a route is changed.
 */
export function* routeChangedSaga() {
  yield conditionallyToggleSideBar()
}

/**
 * If sideBar is opened and viewportWidth is so narrow that the sideBar takes up the whole width
 * close the sideBar.
 */
export function* conditionallyToggleSideBar() {
  const isSideBarOpen = yield select(appShellSelectors.selectIsSideBarOpen)

  const { lg, xl } = calcSizes(window.innerWidth)
  if (isSideBarOpen && !lg && !xl) {
    yield put(appShellActions.toggleSideBar())
  }
}

/**
 * Worker saga that handles SOCKET_OPENED by dispatching an application-ready state
 * if current application state is in reconnecting.
 */
export function* socketOpenedSaga() {
  const currentApplicationState = yield select(selectApplicationState)

  if (currentApplicationState === ApplicationState.reconnecting) {
    yield put(applicationActions.setApplicationReady())

    // Opened socket when in reconnecting state this means we have been "sleeping".
    // Changes during "sleep" are not reflected in current state that's why we need to refresh the state, re-fetching data.
    yield put(membersActions.getAllMembers())
    yield put(classroomActions.getClassroomInfo())
  }
}

/**
 * Watcher saga to handle socket opened actions
 */
function* watchSocketOpenedSaga() {
  yield takeLatest(socketActions.SOCKET_OPENED, socketOpenedSaga)
}

/**
 * Watcher saga for route changed.
 */
export function* watchRouteChanged() {
  //TODO: Have all routes in an enum ant iterate over it, this way none will get forgotten and really ALL route changes will trigger.
  //      Now as new route is added it will be forgotten here and thus routeChangeSaga won't be triggered.
  yield takeLatest(
    [
      routerActions.ROUTE_ELEVER,
      routerActions.ROUTE_START,
      routerActions.ROUTE_MAL,
      routerActions.ROUTE_LARARE,
      routerActions.ROUTE_AKTIVERADE,
      routerActions.ROUTE_OVNINGAR,
      routerActions.ROUTE_UPPDRAG,
      routerActions.ROUTE_FORMATIVA_FRAGOR
    ],
    routeChangedSaga
  )
}

/**
 * Worker saga that handles SOCKET_CLOSED.
 * If in state ready assume socket was closed by remote.
 * Else if UI wants to close it should start by changing application state.
 */
export function* socketClosedSaga() {
  const currentApplicationState = yield select(selectApplicationState)

  if (currentApplicationState === ApplicationState.ready) {
    // Received SOCKET_CLOSED while in ready-state, hence assuming closed by remote. Initiating reconnect.
    yield put(applicationActions.setApplicationReconnecting())
    yield put(socketActions.reconnectSocket())
  }
}

/**
 * Watcher saga for route changed.
 */
export function* watchSocketClosedSaga() {
  yield takeLatest(socketActions.SOCKET_CLOSED, socketClosedSaga)
}

export function* rootSaga() {
  yield all([
    watchApplicationMount(),
    watchRouteChanged(),
    watchSocketClosedSaga(),
    watchSocketOpenedSaga()
  ])
}
