import React from "react"

import DialogTitle from "@mui/material/DialogTitle"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"

import Button from "../Button"
import StyledWordBreak from "../StyledWordBreak"

type Props = {
  closeMethod: () => void
  removeMethod: () => void
  name: string
  children?: never
}

const RemoveMemberDialog = ({ closeMethod, removeMethod, name }: Props) => {
  const nameOrDefault = name === "" ? "vald elev" : name

  return (
    <React.Fragment>
      <DialogTitle id="simple-dialog-title">Ta bort</DialogTitle>
      <DialogContent id="ta-bort-användare-modal">
        <DialogContentText>
          <StyledWordBreak>
            Ta bort {nameOrDefault} från klassrummet?
          </StyledWordBreak>
        </DialogContentText>
        <DialogActions>
          <Button onClick={closeMethod}>Avbryt</Button>
          <Button
            color="primary"
            onClick={() => {
              removeMethod()
              closeMethod()
            }}
          >
            Ta bort
          </Button>
        </DialogActions>
      </DialogContent>
    </React.Fragment>
  )
}

export default RemoveMemberDialog
