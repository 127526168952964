import React from "react"
import { Product } from "../../modules/products/types"
import { StyledTomoyoLogo } from "../../components/Logo/StyledLogo"

import {
  StyledProductImageWrapper,
  StyledCoverImage,
  StyledCoverImageWrapper
} from "./StyledCoverImageAndTitle"
import { isDillProduct } from "../../shared/tools/is_dill_product"

type Props = {
  productInfo: Product
  toggleMenuHandler: () => void
}

export const CoverImageAndTitle = ({ productInfo }: Props) => {
  const renderTomoyoLogo = () =>
    productInfo.type && productInfo.type === "adapt" && <StyledTomoyoLogo />

  const renderDillProduct = () =>
    productInfo.type &&
    isDillProduct(productInfo.type) && (
      <StyledCoverImageWrapper>
        <StyledCoverImage
          alt={productInfo.niceName ?? productInfo.title}
          src={productInfo.coverUrl}
        />
      </StyledCoverImageWrapper>
    )

  return (
    <StyledProductImageWrapper>
      {renderTomoyoLogo()}
      {renderDillProduct()}
    </StyledProductImageWrapper>
  )
}

export default CoverImageAndTitle
