/**
 * Concatenates first and last name separating them by one space character.
 * Also sets first letter of each name to upper case.
 * Handles multiple words in each name, "bo  göran ek   dahl " => "Bo Göran Ek Dahl"
 *
 * @param firstName {string} - First name.
 * @param lastName {string} - Last name.
 * @param maxLength? {string} - Maximum length of the returned name.
 *
 * @returns {string} - Full name in syntax "First Last".
 */
export const buildName = (
  firstName = "",
  lastName = "",
  maxLength?: number
): string => {
  const first = capitalizeEachWordInString(firstName)
  const last = capitalizeEachWordInString(lastName)

  const nameToReturn = `${first} ${last}`.trim()

  return maxLength ? cutAtMaxLength(nameToReturn, maxLength) : nameToReturn
}

/**
 * Remove unwanted spaces from between and around string of words.
 *
 * @param string {String} - input one or more words, separated and/or surrounded by one or more spaces.
 * @returns string {String} - words without leading or trailing spaces and with exactly one space character in between.
 */
const removeMultipleSpaces = (string = "") => {
  const words = string.trim().split(" ")

  return words.filter((word: string) => word !== "")
}

/**
 * Remove unwanted spaces from between and around string of words and capitalizes first letter of each word.
 */
export const capitalizeEachWordInString = (string: string): string => {
  const wordsWithoutMultipleSpaces = removeMultipleSpaces(string)

  const capitalizedWords = wordsWithoutMultipleSpaces.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
  )

  return capitalizedWords.join(" ")
}

/**
 * Remove Emojis from string
 * @param str
 */
export const removeEmojis = (str: string) => {
  const regex =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g

  return str.replace(regex, "")
}

/**
 * Concatenates first and last name separating them by one space character and appends user name.
 * Also sets first letter of first and last name to upper case.
 *
 * @param firstName {string} - First name.
 * @param lastName {string} - Last name.
 * @param username {string} - User name.
 * @param maxLength? {string} - Maximum length of the returned name.
 *
 * @returns {string} - Full name in syntax "First Last, (username)".
 */
export const buildNameAndUserName = (
  firstName: string,
  lastName: string,
  username = "",
  maxLength?: number
): string => {
  const user = username.trim()
  const name = buildName(firstName, lastName)

  const nameToReturn =
    user.length > 0 ? `${name} (${user})`.trim() : `${name}`.trim()

  return maxLength ? cutAtMaxLength(nameToReturn, maxLength) : nameToReturn
}

/**
 * Builds an initials string always two capital letters if both first and last name are given.
 * If only either is given, one capital letter. If none, empty string.
 *
 * @param firstName {string} - First name.
 * @param lastName {string} - Last name.
 *
 * @returns {string} - Initials, tow, one or none capital letters.
 */
export const buildInitials = (firstName = "", lastName = ""): string => {
  let first = firstName.trim().split(" ")[0]
  let last = lastName.trim().split(" ")[0]

  first = first.charAt(0).toUpperCase()
  last = last.charAt(0).toUpperCase()

  return `${first}${last}`
}

/**
 * Converts flag of type {?: boolean} into string "true" or "false".
 * Using this one instead of Boolean.toString() will interpret `undefined` as "false".
 *
 * @param flag {boolean} - If clickable the whole list-item can be clicked on to fire click-action
 */
export const booleanToString = (flag?: boolean): string =>
  flag ? "true" : "false"

/**
 * Cuts off a lengthy string ending it with "..." to show it has been cut.
 *
 * @param maxLength {number} - the maximum length that shall not be ecceeded. maxLength 5 => "ab...", maxLength <= 3 => "..."
 * @returns string {string} - the modified, shortened, string
 */
export const cutAtMaxLength = (string: string, maxLength = 3) => {
  const stringTrimmed = string.trim()

  // in some cases don't cut anything, just return what we got.
  if (
    stringTrimmed.length <= 3 ||
    maxLength >= stringTrimmed.length ||
    maxLength === maxLength - 3
  ) {
    return string
  }

  if (maxLength < 3) {
    return stringTrimmed.slice(0, maxLength)
  }

  const retValue = stringTrimmed.slice(0, maxLength - 3).concat("...")

  return retValue
}

export const getHighlightedSearch = (
  search: string,
  value: string
): { origin: string[]; matches: string[] } => {
  const aggregatedWithoutAccent = value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
  const aggregated = value

  const searchMatches = [
    ...aggregatedWithoutAccent.matchAll(
      new RegExp(
        search
          .normalize("NFD")
          .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
          .replace(/[\u0300-\u036f]/g, ""),
        "gi"
      )
    )
  ].map(a => a.index)

  const split: string[] = []

  searchMatches.forEach((splitIndex, index) => {
    if (index === 0) {
      split.push(aggregated.substring(0, splitIndex))
    }
    const current = splitIndex + search.length
    split.push(
      aggregated.substring(
        current,
        searchMatches[index + 1] || aggregated.length
      )
    )
  })

  const extractedMatches = searchMatches.map(index =>
    aggregated.substring(index, index + search.length)
  )

  return search
    ? { origin: split, matches: extractedMatches }
    : { origin: [aggregated], matches: [] }
}
