import React, { useCallback, useEffect } from "react"
import { connect, useSelector } from "react-redux"

import Divider from "@mui/material/Divider"
import StyledList from "../StyledList"
import MembersListItem from "../MembersListItem"
import { StudentWithMoreMenu } from "../../pages/Members/store/types"
import * as membersPagesTypes from "../../pages/Members/store/types"
import * as membersPageActions from "../../pages/Members/store/actions"
import { RootState } from "../../modules/store"
import { Select, SelectOption, SelectOptions } from "../Select/Select"
import {
  selectMemberListOrderBy,
  selectMemberListOrder
} from "../../pages/Members/store/selectors"
import { sortMembers } from "../../pages/Members/common/TableAndListSort"
import TableToolbar from "../TableToolbar"
import { Product } from "../../modules/products/types"
import { Member } from "../../modules/members/types"
import TableFilter from "../TableFilter/TableFilter"
import { selectAllGroups } from "../../modules/classroom/selectors"
import { FilterValue } from "../TableFilter/types"
import { tableFilter } from "../../shared/tools/filter"

type OwnProps = {
  members: StudentWithMoreMenu[]
  sortByList: membersPagesTypes.VisibleMemberProp[]
  onCheckboxClick: (student: membersPagesTypes.StudentInStudentList) => void
  checkedMembers: membersPagesTypes.StudentInStudentList[]
  toggleAllMembers: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  activeProduct: Product
  toolbarActions: any[]
  unselectedToolbarActions: any[]
  goToStudentCard?: (memberId: number) => void
  recentlyAddedMembers: Member["studliId"][]
  hasFormativeTests?: boolean
}

type StateProps = {
  memberListOrderBy: keyof membersPagesTypes.SortableProps
  memberListOrder: membersPagesTypes.Order
}

type DispatchProps = {
  setMemberListOrder: (
    order: membersPagesTypes.Order,
    orderBy: keyof membersPagesTypes.SortableProps
  ) => membersPagesTypes.SetMemberListOrder
}

type Props = OwnProps & DispatchProps & StateProps

const MembersList: React.FC<Props> = ({
  members = [],
  sortByList,
  setMemberListOrder,
  memberListOrderBy,
  memberListOrder,
  checkedMembers,
  toolbarActions,
  unselectedToolbarActions,
  activeProduct,
  recentlyAddedMembers,
  goToStudentCard,
  hasFormativeTests
}: Props) => {
  const groups = useSelector(selectAllGroups)
  const [sortedMembers, setSortedMembers] = React.useState(
    sortMembers(members, memberListOrder, memberListOrderBy)
  )

  const [currentFilter, setCurrentFilter] = React.useState<FilterValue[]>([])

  const getFilters = () => {
    const memberGroups = groups.filter(g => {
      return members.some(m => m.groups?.some(gm => gm.id === g.id))
    })
    return [
      {
        label: "Grupper",
        key: "groups",
        options: memberGroups.map(group => ({
          label: group.name,
          val: group.id,
          key: "groups",
          filterFunction: (m: membersPagesTypes.StudentInStudentList) =>
            m.groups?.some(g => g.id === group.id)
        }))
      }
    ]
  }

  const onFilter = useCallback(
    (filterValues: FilterValue[]) => {
      const filteredMembers = tableFilter(filterValues, members)
      setSortedMembers(
        sortMembers(filteredMembers, memberListOrder, memberListOrderBy)
      )
    },
    [memberListOrder, memberListOrderBy, members]
  )

  useEffect(() => {
    onFilter(currentFilter)
  }, [members, currentFilter, onFilter])

  const setFilters = (filterValues: FilterValue[]) => {
    setCurrentFilter(filterValues)
  }

  const searchFunction = (
    m: membersPagesTypes.StudentInStudentList,
    searchText: string
  ) =>
    m.email?.toLowerCase().includes(searchText.toLowerCase()) ||
    m.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
    m.lastName?.toLowerCase().includes(searchText.toLowerCase())

  const sortProperties: SelectOptions = sortByList.reduce(
    (acc: SelectOptions, memberProp: membersPagesTypes.VisibleMemberProp) => {
      if (memberProp.isSortable) {
        const newSelectOption: SelectOption = {
          id: memberProp.id,
          value: memberProp.sortKey,
          label: memberProp.label || ""
        }
        acc.push(newSelectOption)
      }

      return acc
    },
    []
  )

  const onChange = (id: keyof membersPagesTypes.SortableProps) => {
    setMemberListOrder("desc", id)
  }

  return (
    <>
      <TableToolbar
        title="Elever"
        selectedTitle="elever valda"
        numSelected={checkedMembers.length}
        actions={toolbarActions}
        totalItems={sortedMembers.length}
        unselectedActions={unselectedToolbarActions}
        filterComponent={
          <TableFilter
            filters={getFilters()}
            onFilter={setFilters}
            searchFunction={searchFunction}
            searchLabel="Sök elever"
            results={sortedMembers.length}
            sortingComponent={
              <Select
                selectLabel="Sortera på"
                selected={memberListOrderBy}
                options={sortProperties}
                onChangeCallback={onChange}
              />
            }
          />
        }
      />
      <StyledList component="div">
        {sortedMembers.map(member => {
          const isRecentlyAdded = recentlyAddedMembers.some(
            memberId => memberId === member.studliId
          )

          return (
            <div key={member.studliId}>
              <MembersListItem
                member={member}
                moreMenuItems={member.moreMenuItems}
                checkedMembers={checkedMembers}
                activeProduct={activeProduct}
                isRecentlyAdded={isRecentlyAdded}
                hasFormativeTests={hasFormativeTests}
                goToStudentCard={
                  goToStudentCard
                    ? () => goToStudentCard(member.studliId)
                    : undefined
                }
              />
              <Divider light />
            </div>
          )
        })}
      </StyledList>
    </>
  )
}

/**
 * Map State To Props.
 *
 * @param state {RootState} - Application's Root State.
 * @returns {StateProps} - Props for this component to use.
 */
const mapStateToProps = (state: RootState): StateProps => {
  return {
    memberListOrderBy: selectMemberListOrderBy(state),
    memberListOrder: selectMemberListOrder(state)
  }
}

/**
 * Map Dispatch To Props.
 */
const mapDispatchToProps: DispatchProps = {
  setMemberListOrder: membersPageActions.setMemberListOrder
}

const ConnectedMembersList = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(MembersList)

export default ConnectedMembersList
