import React from "react"
import { styled, Typography } from "@mui/material"
import {
  ExerciseProgress,
  ExerciseState
} from "../../modules/exercisesProgress/types"
import { getTime } from "../../pages/AssignmentStudentCard/common/helpers"
import { showTimeAgo } from "../../shared/tools/misc"

const StyledScore = styled(Typography)`
  font-size: 14px;
`

type Props = {
  progress?: ExerciseProgress
  exerciseState?: ExerciseState
}

const ExerciseResult = ({ progress, exerciseState }: Props) => {
  const renderProgressResult = (exeProgress: ExerciseProgress) => {
    return (
      <>
        {hasProgressScore(exeProgress) && (
          <>
            <StyledScore variant="body1">
              Bästa resultat: {<b>{exeProgress.bestScore}</b>} av{" "}
              {<b>{exeProgress.maxScore}</b>} rätt{" "}
              {exeProgress.extraTries ? (
                <>
                  med <b>{exeProgress.extraTries}</b> extra försök
                </>
              ) : null}
            </StyledScore>
            <StyledScore variant="body1">
              Bästa tid: {<b>{getTime(exeProgress.bestTime, false)}</b>}
            </StyledScore>
          </>
        )}
        {hasProgressTime(exeProgress) && (
          <StyledScore variant="body1">
            Senast öppnad <b>{showTimeAgo(exeProgress.latestAt)}</b> sedan
            {exeProgress.tries > 0 && (
              <>
                {" "}
                - öppnad{" "}
                <b>
                  {exeProgress.tries}{" "}
                  {exeProgress.tries > 1 ? "gånger" : "gång"}
                </b>
              </>
            )}
          </StyledScore>
        )}
      </>
    )
  }

  const renderExerciseResult = (exeState: ExerciseState) => {
    return (
      <>
        {hasExerciseScore(exeState) && (
          <>
            <StyledScore variant="body1">
              Resultat: {<b>{exeState.score}</b>} av{" "}
              {<b>{exeState.maxScore}</b>} rätt{" "}
              {exeState.extraTries ? (
                <>
                  med <b>{exeState.extraTries}</b> extra försök
                </>
              ) : null}
            </StyledScore>
            {exeState.time && (
              <StyledScore variant="body1">
                Tid: {<b>{getTime(exeState.time, false)}</b>}
              </StyledScore>
            )}
          </>
        )}
        {hasExerciseTime(exeState) && (
          <StyledScore variant="body1">
            Öppnad <b>{showTimeAgo(exeState.modifiedAt)}</b> sedan
          </StyledScore>
        )}
      </>
    )
  }

  return (
    <>
      {progress && renderProgressResult(progress)}
      {exerciseState && renderExerciseResult(exerciseState)}
    </>
  )
}

const hasProgressScore = (node: ExerciseProgress): boolean => {
  return !!node.maxScore
}

const hasProgressTime = (node: ExerciseProgress): boolean => {
  return !!node.latestAt
}

const hasExerciseScore = (exeState: ExerciseState): boolean => {
  return (
    exeState.score !== undefined && exeState.score >= 0 && !!exeState.maxScore
  )
}

const hasExerciseTime = (exeState: ExerciseState): boolean => {
  return !!exeState.modifiedAt
}

export default ExerciseResult
