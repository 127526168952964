import React, { PropsWithChildren } from "react"
import { getHighlightedSearch } from "../../shared/tools/strings"

type Props = {
  value: string
  search: string
  TextComponent: React.FC<PropsWithChildren<unknown>>
  HighLightComponent: React.FC<PropsWithChildren<unknown>>
  disabled?: boolean
}

const SearchHighlight = ({
  value,
  search,
  TextComponent,
  HighLightComponent,
  disabled
}: Props) => {
  const searchHighlight = getHighlightedSearch(search, value)
  return disabled ? (
    <TextComponent>{value}</TextComponent>
  ) : (
    <TextComponent>
      {Boolean(!searchHighlight.origin.length) &&
        Boolean(!searchHighlight.matches.length) &&
        value}
      {searchHighlight.origin.map((s, i, a) => {
        if (i + 1 === a.length) {
          return <React.Fragment key={s + i}>{s}</React.Fragment>
        }
        return (
          <React.Fragment key={s + i}>
            {s === " " ? <span> </span> : s}
            <HighLightComponent key={s}>
              {searchHighlight.matches[i]}
            </HighLightComponent>
          </React.Fragment>
        )
      })}
    </TextComponent>
  )
}

export default SearchHighlight
