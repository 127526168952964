import * as O from "fp-ts/lib/Option"
import { pipe } from "fp-ts/lib/pipeable"
import * as E from "fp-ts/lib/Either"
import moment from "moment"
import { reduce, map } from "fp-ts/lib/Array"
import { flow } from "fp-ts/lib/function"

import { Assignment, AssignmentStatuses } from "../assignments/types"
import {
  ExerciseNode,
  ParentNode,
  ParentOrExerciseNode
} from "../exercises/types"
import { Notification } from "./types"
import { Product } from "../products/types"
import { isParent } from "../../pages/Exercises/common/helpers"

const getEitherParentOrExerciseNode =
  <T>(onLeft: (e: ExerciseNode) => T, onRight: (p: ParentNode) => T) =>
  (exe: ParentOrExerciseNode) =>
    pipe(
      exe as ParentNode,
      E.fromPredicate(
        n => isParent(n),
        () => exe as ExerciseNode
      ),
      E.fold(onLeft, onRight)
    )

const getReplacingExercises = (
  exercises: ParentOrExerciseNode[]
): O.Option<ParentOrExerciseNode[]> =>
  pipe(
    exercises,
    O.fromNullable,
    O.chain<ParentOrExerciseNode[], ParentOrExerciseNode[]>(
      flow(
        reduce([] as ParentOrExerciseNode[], (res, exe) =>
          getEitherParentOrExerciseNode(
            e => (e.replaces ? [...res, e] : res),
            p =>
              pipe(
                getReplacingExercises(p.children),
                O.getOrElse(() => res as ParentOrExerciseNode[])
              )
          )(exe)
        ),
        arr => (arr.length > 0 ? O.some(arr) : O.none)
      )
    )
  )

const onlyAssignmentsWithStatus =
  (status: AssignmentStatuses) => (assignments: Assignment[]) =>
    O.some(assignments.filter(a => a.status === status))

const onlyAssignmentsWithReplacingExercises =
  (exercises: ParentOrExerciseNode[]) => (assignments: Assignment[]) =>
    pipe(
      assignments.filter(a =>
        (a.assignmentTasks || []).some(t =>
          exercises.some(e => e.replaces === t.link)
        )
      ),
      O.some
    )

const getAssignmentsWithReplacingExercises =
  (assignments: Assignment[]) => (exercises: ParentOrExerciseNode[]) =>
    pipe(
      assignments,
      O.fromNullable,
      O.chain(onlyAssignmentsWithStatus("published")),
      O.chain(onlyAssignmentsWithReplacingExercises(exercises))
    )

const generateUniqueId = (notifications: Notification[]): number =>
  pipe(
    Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
    id => (notifications.some(n => n.id === id) ? O.none : O.some(id)),
    O.fold(
      () => generateUniqueId(notifications),
      id => id
    )
  )

const assignmentsToNotifications =
  (notifications: Notification[], activeProduct: Product) =>
  (assignments: Assignment[]) =>
    pipe(
      assignments,
      map<Assignment, Notification>(a => ({
        id: generateUniqueId(notifications),
        type: "ASSIGNMENT_TASK_REPLACED",
        dataType: "uri",
        local: true,
        text: `${activeProduct.niceName} har uppdaterats. Innehållet i uppdrag ${a.title} är förändrat. Se över deluppgifterna.`,
        data: `tecla:product:${activeProduct.id}:assignment:${a.id}`,
        readByMembers: [],
        updatedAt: moment()
      })),
      O.some
    )

export const generateNotificationsForExercisesThatAreReplaced = (
  exercises: ParentOrExerciseNode[],
  assignments: Assignment[],
  notifications: Notification[],
  activeProduct: Product
) =>
  pipe(
    exercises,
    getReplacingExercises,
    O.chain(getAssignmentsWithReplacingExercises(assignments)),
    O.chain(assignmentsToNotifications(notifications, activeProduct)),
    O.getOrElse(() => [] as Notification[])
  )
