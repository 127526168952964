import { Lens } from "monocle-ts"

import { setField } from "../../shared/tools/monocle"
import { createReducer } from "../../shared/tools/redux"
import * as actions from "./actions"
import updateGoal from "./helper"
import { State, Goals } from "./types"

const initialState: State = {
  goals: []
}

const setGoals = setField<
  State,
  {
    goals: Goals
  }
>("goals", p => p.goals)

const updateGoalWithReason =
  (reason: "started" | "completed") =>
  (payload: { studliId: number; goalRef: string; goalAbilityGrade: number }) =>
    Lens.fromProp<State>()("goals").modify(updateGoal(payload, reason))

const emptyGoals = setField<State>("goals", () => [])

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.addGoals, setGoals)
    .case(actions.addGoalsEvent, setGoals)
    .case(actions.markGoalCompleted, updateGoalWithReason("completed"))
    .case(actions.goalUpdatedEvent, updateGoalWithReason("started"))
    .case(actions.resetGoals, emptyGoals)
)

export default reducer
