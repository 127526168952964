import React, { useState } from "react"
import {
  StyledResultWrapper,
  StyledExtraListWrapper,
  StyledListItemContent
} from "./StyledExerciseResultWithHistory"
import { Collapse, List } from "@mui/material"
import ExerciseResult from "../ExerciseResult"
import { ExerciseStateWithExerciseData } from "../StudentHistoryList/useHistoryListForStudent"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import {
  ExerciseProgress,
  ExerciseState
} from "../../modules/exercisesProgress/types"
import moment from "moment"
import handleKeyboard from "../../shared/tools/button-keyhandler"

type Props = {
  exercisesForHistory:
    | ExerciseProgressWithHistory
    | ExerciseStateWithExerciseData
  type: "bestScore" | "score"
}

export interface ExerciseProgressWithHistory extends ExerciseProgress {
  moreExercises: ExerciseState[] | null
}

const ExerciseResultWithHistory = ({ exercisesForHistory, type }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const renderPreviousList = (data: ExerciseStateWithExerciseData) => {
    const previous = data.moreExercises

    return (
      <Collapse in={isCollapsed}>
        <List>
          {previous &&
            previous
              .sort(
                (a, b) =>
                  moment(b.modifiedAt).valueOf() -
                  moment(a.modifiedAt).valueOf()
              )
              .map(item => (
                <StyledListItemContent key={item.modifiedAt}>
                  <ExerciseResult exerciseState={item} />
                </StyledListItemContent>
              ))}
        </List>
      </Collapse>
    )
  }

  return (
    <div
      aria-label="knapp för historik"
      onClick={() => setIsCollapsed(!isCollapsed)}
      role="button"
      tabIndex={0}
      onKeyUp={handleKeyboard(() => setIsCollapsed(!isCollapsed))}
    >
      <StyledResultWrapper>
        <div>
          {type === "bestScore" ? (
            <ExerciseResult
              progress={exercisesForHistory as ExerciseProgressWithHistory}
            />
          ) : (
            <ExerciseResult
              exerciseState={
                exercisesForHistory as ExerciseStateWithExerciseData
              }
            />
          )}
        </div>
        {exercisesForHistory.moreExercises && (
          <>{isCollapsed ? <ExpandLess /> : <ExpandMore />}</>
        )}
      </StyledResultWrapper>
      <StyledExtraListWrapper>
        {exercisesForHistory.moreExercises &&
          renderPreviousList(
            exercisesForHistory as ExerciseStateWithExerciseData
          )}
      </StyledExtraListWrapper>
    </div>
  )
}

export default ExerciseResultWithHistory
