import * as React from "react"

import { SmallProgressBar } from "./StyledProgress"
import { PROGRESS_SEGMENT } from "./constants"

type Status = "done" | "inProgress" | "notStarted" | "approved"

type Props = {
  status: string
  type: "exercise" | "assignment"
}

export function getSegment(status: Status) {
  if (status === "done" || status === "approved") {
    return PROGRESS_SEGMENT.DONE
  }

  if (status === "inProgress") {
    return PROGRESS_SEGMENT.IN_PROGRESS
  }

  return PROGRESS_SEGMENT.NOT_STARTED
}

function getTooltipText(status: Status, type: "exercise" | "assignment") {
  if (status === "approved") {
    return "Klar"
  }

  if (status === "done") {
    return type === "assignment" ? "Inlämnat" : "Klar"
  }

  if (status === "inProgress") {
    return "Påbörjat"
  }

  return "Ej påbörjat"
}

const SimpleProgress: React.FC<Props> = ({ status, type }) => {
  const segment = getSegment(status as Status)
  const text = getTooltipText(status as Status, type)

  return <SmallProgressBar segment={segment}>{text}</SmallProgressBar>
}

SimpleProgress.defaultProps = {
  type: "exercise"
}

export default SimpleProgress
