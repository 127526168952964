import { createAction } from "../../shared/tools/redux"
import * as types from "./types"
import { Moment } from "moment"
/**
 * Redux-actions, client-requests that affect the server.
 */
export enum REQUEST {
  GET_ALL_QUESTIONS_REQUEST = "GET_ALL_QUESTIONS_REQUEST",
  CREATE_FORMATIVE_TEST_REQUEST = "CREATE_FORMATIVE_TEST_REQUEST",
  EDIT_FORMATIVE_TEST_REQUEST = "EDIT_FORMATIVE_TEST_REQUEST",
  GET_ALL_TESTS_REQUEST = "GET_ALL_TESTS_REQUEST",
  GET_FORMATIVE_PROGRESSION_REQUEST = "GET_FORMATIVE_PROGRESSION_REQUEST",
  GET_ALL_IMPORTABLE_TESTS_REQUEST = "GET_ALL_IMPORTABLE_TESTS_REQUEST",
  GET_PREPARED_FORMATIVE_TESTS_REQUEST = "GET_PREPARED_FORMATIVE_TESTS_REQUEST",
  GET_FORMATIVE_TEST_REQUEST = "GET_FORMATIVE_TEST_REQUEST"
}

export enum RESPONSE {
  GET_ALL_QUESTIONS_RESPONSE = "GET_ALL_QUESTIONS_RESPONSE",
  CREATE_FORMATIVE_TEST_RESPONSE = "CREATE_FORMATIVE_TEST_RESPONSE",
  GET_ALL_TESTS_RESPONSE = "GET_ALL_TESTS_RESPONSE",
  GET_ALL_IMPORTABLE_TESTS_RESPONSE = "GET_ALL_IMPORTABLE_TESTS_RESPONSE",
  REMOVE_FORMATIVE_TEST_RESPONSE = "REMOVE_FORMATIVE_TEST_RESPONSE",
  GET_FORMATIVE_PROGRESSION_RESPONSE = "GET_FORMATIVE_PROGRESSION_RESPONSE",
  GET_PREPARED_FORMATIVE_TESTS_RESPONSE = "GET_PREPARED_FORMATIVE_TESTS_RESPONSE",
  GET_FORMATIVE_TEST_RESPONSE = "GET_FORMATIVE_TEST_RESPONSE"
}

export enum SERVER_MESSAGE_ACTION {
  GET_ALL_QUESTIONS_REQUEST = "questions",
  GET_ALL_QUESTIONS_RESPONSE = "questions",
  CREATE_FORMATIVE_TEST_REQUEST = "create",
  CREATE_FORMATIVE_TEST_RESPONSE = "create",
  GET_ALL_TESTS_REQUEST = "list",
  GET_ALL_TESTS_RESPONSE = "list",
  START_FORMATIVE_TEST_REQUEST = "start",
  START_FORMATIVE_TEST_RESPONSE = "start",
  STOP_FORMATIVE_TEST_REQUEST = "stop",
  STOP_FORMATIVE_TEST_RESPONSE = "stop",
  REDO_FORMATIVE_TEST_REQUEST = "redo",
  REDO_FORMATIVE_TEST_RESPONSE = "redo",
  FORMATIVE_TEST_UPDATED_EVENT = "formative_test_updated",
  FORMATIVE_TEST_REMOVED_EVENT = "formative_test_removed",
  FORMATIVE_PROGRESS_UPDATE_EVENT = "formative_test_progress_updated",
  FORMATIVE_ANSWER_UPDATE_EVENT = "formative_test_answer_updated",
  EDIT_FORMATIVE_TEST_REQUEST = "update",
  EDIT_FORMATIVE_TEST_RESPONSE = "update",
  REMOVE_FORMATIVE_TEST_REQUEST = "delete",
  REMOVE_FORMATIVE_TEST_RESPONSE = "delete",
  GET_ALL_IMPORTABLE_TESTS_REQUEST = "importable",
  GET_ALL_IMPORTABLE_TESTS_RESPONSE = "importable",
  IMPORT_TESTS_REQUEST = "import",
  IMPORT_TESTS_RESPONSE = "import",
  GET_FORMATIVE_PROGRESSION_REQUEST = "progression",
  GET_FORMATIVE_PROGRESSION_RESPONSE = "progression",
  GET_PREPARED_FORMATIVE_TESTS_REQUEST = "prepared",
  GET_PREPARED_FORMATIVE_TESTS_RESPONSE = "prepared",
  GET_FORMATIVE_TEST_REQUEST = "get",
  GET_FORMATIVE_TEST_RESPONSE = "get"
}

export enum EVENT {
  ADD_FORMATIVE_TEST = "ADD_FORMATIVE_TEST",
  ADD_PROGRESS_POST = "ADD_PROGRESS_POST",
  ADD_ANSWER_POST = "ADD_ANSWER_POST",
  REMOVE_FORMATIVE_TEST = "REMOVE_FORMATIVE_TEST",
  FORMATIVE_TEST_UPDATED_EVENT = "FORMATIVE_TEST_UPDATED_EVENT",
  FORMATIVE_TEST_REMOVED_EVENT = "FORMATIVE_TEST_REMOVED_EVENT",
  FORMATIVE_PROGRESS_UPDATE_EVENT = "FORMATIVE_PROGRESS_UPDATE_EVENT",
  FORMATIVE_ANSWER_UPDATE_EVENT = "FORMATIVE_ANSWER_UPDATE_EVENT"
}

export enum UI {
  SET_IMPORTED_TEST = "SET_IMPORTED_TEST",
  RESET_IMPORTED_TEST = "RESET_IMPORTED_TEST",
  RESET_FORMATIVE_QUESTIONS = "RESET_FORMATIVE_QUESTIONS"
}

export const getAllQuestions = createAction("GET_ALL_QUESTIONS_REQUEST")

export const getAllTests = createAction("GET_ALL_TESTS_REQUEST")

export const getFormativeProgression = createAction(
  "GET_FORMATIVE_PROGRESSION_REQUEST"
)

export const getTest = createAction<{ id: number }>(
  "GET_FORMATIVE_TEST_REQUEST"
)

export const getAllImportableTests = createAction(
  "GET_ALL_IMPORTABLE_TESTS_REQUEST"
)

export const importTests = createAction<{ formativeTestIds: number[] }>(
  "IMPORT_TESTS_REQUEST"
)

export const getAllPreparedTests = createAction(
  "GET_PREPARED_FORMATIVE_TESTS_REQUEST"
)

export const setImportableTests = createAction<types.FormativeTests>(
  "GET_ALL_IMPORTABLE_TESTS_RESPONSE"
)

export const setPreparedTests = createAction<types.PreparedTests>(
  "GET_PREPARED_FORMATIVE_TESTS_RESPONSE"
)

export const setQuestions = createAction<types.FormativeQuestions>(
  "GET_ALL_QUESTIONS_RESPONSE"
)

export const removeFormativeTestFromList = createAction<{ testId: number }>(
  "REMOVE_FORMATIVE_TEST"
)

export const startFormativeTest = createAction<{ testId: number }>(
  "START_FORMATIVE_TEST_REQUEST"
)

export const removeFormativeTest = createAction<{ testId: number }>(
  "REMOVE_FORMATIVE_TEST_REQUEST"
)

export const stopFormativeTest = createAction<{
  testId: number
  stopAt: Moment
}>("STOP_FORMATIVE_TEST_REQUEST")

export const redoFormativeTest = createAction<{
  testId: number
  members: number[]
  instruction: string
  startNow: boolean
}>("REDO_FORMATIVE_TEST_REQUEST")

export const setTests = createAction<types.FormativeTests>(
  "GET_ALL_TESTS_RESPONSE"
)

export const setProgression = createAction<{
  progression: types.FormativeProgressionArr
  answers: types.FormativeProgressionAnswers
}>("GET_FORMATIVE_PROGRESSION_RESPONSE")

export const addFormativeTest =
  createAction<types.FormativeTest>("ADD_FORMATIVE_TEST")

export const addProgressPost =
  createAction<types.FormativeProgression>("ADD_PROGRESS_POST")

export const addAnswerPost =
  createAction<types.FormativeProgressionAnswer>("ADD_ANSWER_POST")

export const createFormativeTest = createAction<types.CreateFormativeTest>(
  "CREATE_FORMATIVE_TEST_REQUEST"
)

export const editFormativeTest = createAction<{
  test: types.EditFormativeTest
  echo: types.EditTestEchoActions
}>("EDIT_FORMATIVE_TEST_REQUEST")

export const setImportedTest =
  createAction<types.PartialFormativeTest>("SET_IMPORTED_TEST")

export const resetImportedTest = createAction("RESET_IMPORTED_TEST")

export const resetFormativeQuestions = createAction("RESET_FORMATIVE_QUESTIONS")
