import { Divider } from "@mui/material"
import React, { useCallback, useEffect, useMemo } from "react"
import {
  FormativeTest,
  FormativeTestMenuOptions,
  FormativeTests,
  FormativeProgressionArr
} from "../../modules/formative/types"
import { PossibleStatusActions } from "../../pages/FormativeQuestions/types"
import {
  getStatusString,
  menuOptions
} from "../../pages/FormativeQuestions/helpers"
import StyledList from "../StyledList"
import TableToolbar from "../TableToolbar"
import FormativeQuestionsTableAsListItem from "./FormativeQuestionsTableAsListItem"
import { ProgressForBar } from "../FormativeQuestionsTable/types"
import { useSelector } from "react-redux"
import {
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import { FilterValue } from "../TableFilter/types"
import { Members } from "../../modules/members/types"
import { buildName } from "../../shared/tools/strings"
import TableFilter from "../TableFilter/TableFilter"
import { tableFilter } from "../../shared/tools/filter"
import { selectAuthenticatedUser } from "../../modules/users/selectors"

const NOOP = () => {}

type Props = {
  tests: FormativeTests
  title: string
  progress: FormativeProgressionArr
  onActionStatusClicked: (
    event: any,
    status: PossibleStatusActions,
    testName: string,
    testId: number
  ) => void
  onMenuClick: (
    type: FormativeTestMenuOptions,
    testId: FormativeTest["id"]
  ) => void
  onProgressDialogClick: (progress: ProgressForBar, title: string) => void
  onRowClick: (testId: number) => void
  type: "studentCard" | "class"
  members?: Members

  showFilter?: boolean
}

const FormativeQuestionsTableAsList = ({
  tests,
  title,
  onActionStatusClicked,
  onMenuClick,
  onProgressDialogClick,
  onRowClick,
  progress,
  type,
  showFilter,
  members
}: Props) => {
  const groups = useSelector(selectAllGroups)
  const activeGroups = useSelector(selectAllActiveGroups)
  const [sortedTests, setSortedTests] = React.useState(tests)
  const [currentFilters, setCurrentFilters] = React.useState<FilterValue[]>([])
  const user = useSelector(selectAuthenticatedUser)

  const onFilter = useCallback(
    (filterValues: FilterValue[]) => {
      return tableFilter(filterValues, tests)
    },
    [tests]
  )

  useEffect(() => {
    const filteredTests = onFilter(currentFilters)
    setSortedTests(filteredTests)
  }, [tests, currentFilters, onFilter])

  const testsCreatedBy = useMemo(() => {
    return [
      ...Array.from(
        new Set(
          tests.map(test => ({
            createdBy: test.createdBy,
            createdByFirstName: test.createdByFirstName,
            createdByLastName: test.createdByLastName,
            createdByPictureUrl: test.createdByPictureUrl
          }))
        )
      ).filter(
        (createdBy, index, self) =>
          index === self.findIndex(c => c.createdBy === createdBy.createdBy)
      )
    ]
  }, [tests])

  const getTestGroups = useCallback((t: FormativeTest) => {
    const allGroups = t.participantsInfo.reduce(
      (acc: number[], participant) => {
        return [...acc, ...participant.groups]
      },
      []
    )
    return Array.from(new Set(allGroups))
  }, [])

  const getTestsStatusFilterOptions = () => {
    return [
      ...Array.from(new Set(tests.map(test => test.status as string)))
    ].map((status: string) => ({
      label: getStatusString(status as any),
      val: status,
      key: "status",
      filterFunction: (test: FormativeTest) => {
        return test.status === status
      }
    }))
  }

  const getFilters = () => {
    return [
      {
        label: "Grupper",
        key: "groups",
        options: groups.map(group => ({
          label: group.name,
          val: group.id,
          key: "groups",
          filterFunction: (t: FormativeTest) =>
            t.sessions.length
              ? getTestGroups(t).some(g => g === group.id)
              : NOOP
        }))
      },
      {
        label: "Status",
        key: "status",
        options: getTestsStatusFilterOptions()
      },
      {
        label: "Skapat av",
        key: "createdBy",
        options: testsCreatedBy.map(createdBy => ({
          label: buildName(
            createdBy.createdByFirstName,
            createdBy.createdByLastName
          ),
          val: createdBy,
          key: "createdBy",
          filterFunction: (t: FormativeTest) =>
            t.createdBy === createdBy.createdBy
        }))
      }
    ]
  }

  const initialGroupFilter = useMemo(() => {
    if (groups.length !== activeGroups.length) {
      return activeGroups.map(group => ({
        label: group.name,
        val: group.id,
        key: "groups",
        filterFunction: (t: FormativeTest) =>
          t.sessions.length ? getTestGroups(t).some(g => g === group.id) : NOOP
      }))
    }
  }, [groups, activeGroups, getTestGroups])

  const setFilters = (filterValues: FilterValue[]) => {
    setCurrentFilters(filterValues)
  }

  const searchFunction = (t: FormativeTest, searchText: string) =>
    t.title?.toLowerCase().includes(searchText.toLowerCase())

  const menuItemClick = (
    type: FormativeTestMenuOptions,
    testId: FormativeTest["id"]
  ) => {
    onMenuClick(type, testId)
  }

  return (
    <>
      {type === "class" && (
        <TableToolbar
          title={title}
          totalItems={tests.length}
          numSelected={0}
          selectedTitle="test valda"
          actions={[]}
          filterComponent={
            showFilter ? (
              <TableFilter
                filters={getFilters()}
                onFilter={setFilters}
                searchFunction={searchFunction}
                searchLabel="Sök titel"
                results={sortedTests.length}
                initialFilters={initialGroupFilter}
              />
            ) : null
          }
        />
      )}
      <StyledList>
        {sortedTests.map(test => {
          return (
            <React.Fragment key={test.id}>
              <FormativeQuestionsTableAsListItem
                test={test}
                progress={progress}
                onActionStatusClicked={onActionStatusClicked}
                moreMenuOptions={menuOptions(
                  test,
                  menuItemClick,
                  "list",
                  user?.studliId === test.createdBy
                )}
                onProgressDialogClick={onProgressDialogClick}
                onRowClick={onRowClick}
                type={type}
              />
              <Divider light />
            </React.Fragment>
          )
        })}
      </StyledList>
    </>
  )
}

export default FormativeQuestionsTableAsList
