import { takeLatest, put, all, select, race } from "redux-saga/effects"

import * as applicationActions from "../application/actions"
import * as membersActions from "./actions"
import * as usersActions from "../users/actions"
import * as communicationActions from "../communication/actions"

import { MODULE_NAME } from "../../pages/Members/constants"
import { sendRequest } from "../communication/actions"

import * as membersTypes from "./types"
import * as userTypes from "../users/types"
import * as communicationTypes from "../communication/types"
import * as membersSelectors from "./selectors"

import { snackbarMessages } from "../../containers/Snackbar/snackbarMessages"
import { getAllGoalsProgressRequest } from "../goalsProgress/actions"
import { selectAllAssignments } from "../assignments/selectors"
import { setAssignments } from "../assignments/actions"
import {
  removeMembersFromAssignments,
  updateAssignmentParticipants
} from "../assignments/helpers"
import { selectAllTests } from "../formative/selectors"
import {
  removeMembersFromTests,
  updateTestParticipants
} from "../formative/helpers"
import { setTests } from "../formative/actions"
import { selectAllActiveGroups } from "../classroom/selectors"
import { Group } from "../classroom/types"
import { Assignment, AssignmentParticipant } from "../assignments/types"

function* changeMemberPasswordSaga(
  message: membersTypes.ChangeMemberPasswordAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      membersActions.SERVER_MESSAGE_ACTION.UPDATE_MEMBER_REQUEST,
      message.payload
    )
  )
}

function* watchChangeMemberPasswordSaga() {
  yield takeLatest(
    membersActions.REQUEST.UPDATE_MEMBER_REQUEST,
    changeMemberPasswordSaga
  )
}

function* changeClassPasswordSaga(
  message: membersTypes.ChangeMemberPasswordAction
) {
  yield put(
    sendRequest(
      MODULE_NAME,
      membersActions.SERVER_MESSAGE_ACTION.CHANGE_CLASS_PASSWORD_REQUEST,
      message.payload
    )
  )
}

function* watchChangeClassPasswordSaga() {
  yield takeLatest(
    membersActions.REQUEST.CHANGE_CLASS_PASSWORD_REQUEST,
    changeClassPasswordSaga
  )
}

export function* getAllMembersSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      membersActions.SERVER_MESSAGE_ACTION.GET_ALL_MEMBERS_REQUEST
    )
  )
}

function* watchGetAllMembersSaga() {
  yield takeLatest(
    membersActions.REQUEST.GET_ALL_MEMBERS_REQUEST,
    getAllMembersSaga
  )
}

function* watchUpdateMembersListSaga() {
  yield takeLatest(
    usersActions.RESPONSE.ACTIVATE_PRODUCT_KEYS_RESPONSE,
    updateMembersListFromProductActivationSaga
  )
}

export function* updateMembersListFromProductActivationSaga(
  message: userTypes.ReceiveActivateProductKeysResponseAction
) {
  const members = yield select(membersSelectors.selectAllMembers)
  const activeProducts = message.payload.activeProducts
  if (members.length && activeProducts.length) {
    const mergedMembers = members.map((member: membersTypes.Member) => {
      const activeProductIndex = activeProducts.findIndex(
        product => product.studliId === member.studliId
      )
      if (activeProductIndex !== -1) {
        const products = activeProducts[activeProductIndex].products || []

        return {
          ...member,
          hasProduct: products && products.length > 0
        }
      }

      return { ...member }
    })
    yield put(membersActions.setMembers(mergedMembers))
  }
}

export function* handleReceiveMembersEventSaga(
  message: membersTypes.ReceiveMembersEventMessagesTypes
) {
  const { type, action, payload } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case membersActions.SERVER_MESSAGE_ACTION.MEMBER_UPDATED_EVENT:
        yield put(
          membersActions.receiveMemberUpdatedAction(
            payload as membersTypes.ReceiveMemberUpdatedAction["payload"]
          )
        )
        break

      case membersActions.SERVER_MESSAGE_ACTION.MEMBER_REMOVED_EVENT:
        const memberRemovedEventPayload =
          payload as membersTypes.MemberRemovedEventAction["payload"]

        const activeGroups = yield select(selectAllActiveGroups)
        const currMembers = yield select(membersSelectors.selectAllMembers)

        const updatedMembers = currMembers.map((m: membersTypes.Member) => ({
          ...m,
          groups: memberRemovedEventPayload.studliIds.includes(m.studliId)
            ? m.groups?.filter(g => g !== memberRemovedEventPayload.groupId)
            : m.groups
        }))

        const { removedIds, newMembers } = updatedMembers.reduce(
          (
            acc: { removedIds: number[]; newMembers: membersTypes.Members },
            curr: membersTypes.Member
          ) => {
            if (
              !curr.groups?.length ||
              !activeGroups.some((g: Group) => curr.groups?.includes(g.id))
            ) {
              return {
                ...acc,
                removedIds: [...acc.removedIds, curr.studliId]
              }
            }
            return {
              ...acc,
              newMembers: [...acc.newMembers, curr]
            }
          },
          { removedIds: [], newMembers: [] }
        )

        const assignmentsList = yield select(selectAllAssignments)

        const rAssignmentParticipants = removeMembersFromAssignments(
          assignmentsList,
          removedIds
        )
        yield put(setAssignments(rAssignmentParticipants))

        const testsList = yield select(selectAllTests)
        const rTestParticipants = removeMembersFromTests(testsList, removedIds)
        yield put(setTests(rTestParticipants))
        yield put(membersActions.setMembers(newMembers))

        break
      case membersActions.SERVER_MESSAGE_ACTION.MEMBER_ADDED_EVENT:
        const memberAddedEventPayload =
          payload as membersTypes.ReceiveMemberAddedAction["payload"]

        yield put(membersActions.receiveMemberAdded(memberAddedEventPayload))
        yield put(usersActions.getActiveProducts())
        yield put(getAllGoalsProgressRequest())
        yield put(usersActions.getUsersWithProduct())
        const assignments = yield select(selectAllAssignments)

        const uAssignmentParticipants = updateAssignmentParticipants(
          assignments,
          memberAddedEventPayload
        )
        yield put(setAssignments(uAssignmentParticipants))
        const tests = yield select(selectAllTests)
        const uTestParticipants = updateTestParticipants(
          tests,
          memberAddedEventPayload
        )
        yield put(setTests(uTestParticipants))

        break
      case membersActions.SERVER_MESSAGE_ACTION.NEW_ANSWER_EVENT:
        yield put(
          membersActions.receiveNewAnswer(
            payload as membersTypes.ReceiveNewAnswerAction["payload"]
          )
        )
        break

      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in event`
        )
    }
  }
}

export function* handleReceiveMembersResponseSaga(
  message: membersTypes.ReceiveMembersResponseMessagesTypes
) {
  const { type, action, payload, error } = message.payload
  if (type === MODULE_NAME) {
    switch (action) {
      case membersActions.SERVER_MESSAGE_ACTION.GET_ALL_MEMBERS_RESPONSE:
        const getAllMembersResponsePayload =
          payload as membersTypes.ReceiveMembersAction["payload"]

        const allMembers =
          getAllMembersResponsePayload && getAllMembersResponsePayload.members
        if (allMembers) {
          yield put(membersActions.receiveMembers(allMembers))
        }

        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.GET_ALL_MEMBERS_RESPONSE_FAILURE
            )
          )
        }

        if (allMembers) {
          allMembers.forEach((member: membersTypes.Member) => {
            ;(member.firstName.length === 0 || member.lastName.length === 0) &&
              window.console.error(
                `Member received without first or last name for member with studliId ${member.studliId}`
              )
          })
        }

        break

      case membersActions.SERVER_MESSAGE_ACTION.UPDATE_MEMBER_RESPONSE:
        yield receiveUpdateMemberResponse(payload, error)

        break

      case membersActions.SERVER_MESSAGE_ACTION.CHANGE_CLASS_PASSWORD_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.CHANGE_CLASS_PASSWORD_RESPONSE_FAILURE
            )
          )
          break
        }
        const changedPasswordPayload =
          payload as membersTypes.ReceiveChangeClassPasswordAction["payload"]
        yield put(membersActions.setPasswordForMembers(changedPasswordPayload))
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.CHANGE_CLASS_PASSWORD_RESPONSE_SUCCESS
          )
        )
        break
      case membersActions.SERVER_MESSAGE_ACTION.ADD_MEMBER_TO_GROUP_RESPONSE:
        if (error) {
          yield put(
            applicationActions.setSnackbar(
              snackbarMessages.ADD_MEMBER_TO_GROUP_RESPONSE_FAILURE
            )
          )
          break
        }

        const addedToGroupPayload =
          payload as membersTypes.AddMembersToGroupAction["payload"]
        const activeGroups = yield select(selectAllActiveGroups)
        if (
          !activeGroups.some((g: Group) => g.id === addedToGroupPayload.groupId)
        ) {
          const assignments = yield select(selectAllAssignments)
          const updatedAss = assignments.map((a: Assignment) => ({
            ...a,
            participantsInfo: a.participantsInfo.map(
              (p: AssignmentParticipant) =>
                addedToGroupPayload.studentStudliIds.includes(p.studliId)
                  ? { ...p, groups: [...p.groups, addedToGroupPayload.groupId] }
                  : p
            )
          }))
          yield put(setAssignments(updatedAss))
        }
        yield put(
          applicationActions.setSnackbar(
            snackbarMessages.ADD_MEMBER_TO_GROUP_RESPONSE_SUCCESS
          )
        )
        break
      default:
        window.console.error(
          `Received unhandled message action ${action} for message type ${type} in response`
        )
    }
  }
}

/**
 * Receive update-member response.
 *
 * @param payload {Object} - data concerning the request and response.
 * @param error {Object} - if response indicates a failure this object will be defined, else not.
 */
export function* receiveUpdateMemberResponse(
  payload: { id: membersTypes.Member["studliId"]; password: string },
  error: communicationTypes.CommunicationError
) {
  if (payload.password) {
    if (error) {
      yield put(
        applicationActions.setSnackbar(
          snackbarMessages.CHANGE_MEMBER_PASSWORD_RESPONSE_FAILURE
        )
      )
    } else {
      yield put(
        applicationActions.setSnackbar(
          snackbarMessages.CHANGE_MEMBER_PASSWORD_RESPONSE
        )
      )
    }
  } else {
    window.console.error(
      `Received a update-member-response and it's not about a password-change. Can't handle that yet.`
    )
  }
}

function* addMembersToGroupSaga(message: membersTypes.AddMembersToGroupAction) {
  yield put(
    sendRequest(
      MODULE_NAME,
      membersActions.SERVER_MESSAGE_ACTION.ADD_MEMBER_TO_GROUP_REQUEST,
      message.payload
    )
  )
}

function* watchAddMembersToGroupSaga() {
  yield takeLatest(
    membersActions.REQUEST.ADD_MEMBER_TO_GROUP_REQUEST,
    addMembersToGroupSaga
  )
}

/**
 * Receive add-member response. (when users-module is in isAdding-state)
 *
 * @param payload {Object} - data concerning the request and response.
 * @param error {Object} - if response indicates a failure this object will be defined, else not.
 */

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceiveMembersResponseSaga
    ),
    yield takeLatest(
      communicationActions.RECEIVE_EVENT,
      handleReceiveMembersEventSaga
    )
  ])
}

export function* rootSaga() {
  yield all([
    watchGetAllMembersSaga(),
    watchReceiverSaga(),
    watchChangeMemberPasswordSaga(),
    watchAddMembersToGroupSaga(),
    watchUpdateMembersListSaga(),
    watchChangeClassPasswordSaga()
  ])
}
