import React from "react"
import { useSelector } from "react-redux"
import { Members, Member } from "../../modules/members/types"
import { ListItemAvatar, ListItemText, List } from "@mui/material"
import Avatar from "../Avatar"
import { buildName } from "../../shared/tools/strings"
import MoreMenu from "../PopupMenu/PopupMenu"
import {
  AssignmentsTaskProgress,
  AssignmentsProgress
} from "../../modules/assignmentsProgress/types"
import { Assignment } from "../../modules/assignments/types"
import {
  StyledListItem,
  StyledTextProgressWrapper,
  StyledValidationErrorMessage
} from "./StyledAssignmentStudentList"
import AssignmentStatusTags from "../AssignmentStatusAndApprovedTags"
import MessageIndicator from "../MessageIndicator"
import { selectCurrentAssignment } from "../../modules/assignments/selectors"
import AdaptAssignmentProgressBar from "../AdaptAssignmentProgressBar"
import { VALIDATION_ERRORS } from "../../modules/assignments/constants"

type OwnProps = {
  memberIds: Member["studliId"][]
  members: Members
  hasOnClick: boolean
  showMenu?: boolean
  removeStudentFromAssignment: (memberId: Member["studliId"]) => void
  editMode: boolean
  progress: {
    assignmentsTaskProgress: AssignmentsTaskProgress
    assignmentsProgress: AssignmentsProgress
  }
  assignmentId: Assignment["id"]
  onAssignmentStudentClick: (memberId: Member["studliId"]) => void
  classroomType?: "dill" | "adapt" | "fnDill"
  validationError?: { [key: number]: string } | null
}
const AssignmentStudentsList = ({
  memberIds,
  members,
  removeStudentFromAssignment,
  editMode,
  assignmentId,
  progress,
  hasOnClick,
  showMenu,
  onAssignmentStudentClick,
  classroomType,
  validationError
}: OwnProps) => {
  const assignment = useSelector(selectCurrentAssignment)
  const sortedMembers = memberIds
    .reduce((res: Members, currentMember) => {
      const memberIndex: number = members.findIndex(
        member => member.studliId === currentMember
      )
      if (memberIndex !== -1) {
        res.push(members[memberIndex])
      }

      return res
    }, [])
    .sort((first, second) => first.lastName.localeCompare(second.lastName))
  const getStudentValidationError = (member: Member) => {
    if (validationError) {
      const error = VALIDATION_ERRORS[validationError[member.studliId]]
      return error
    }
    return ""
  }

  const renderSecondary = (member: Member) => {
    if (classroomType !== "adapt") {
      return (
        <AssignmentStatusTags
          progress={progress}
          memberId={member.studliId}
          assignmentId={assignmentId}
        />
      )
    }
    if (assignment) {
      const progressForAssignment = progress.assignmentsProgress.find(
        p => p.assignmentId === assignment.id && p.studliId === member.studliId
      )
      return (
        <AdaptAssignmentProgressBar
          threshold={assignment.threshold}
          progress={progressForAssignment ? progressForAssignment.progress : 0}
        />
      )
    }
  }

  return (
    <>
      <List>
        {sortedMembers.map((member: Member) => {
          const studentValidationError = getStudentValidationError(member)
          return (
            <StyledListItem
              ContainerComponent="div"
              divider
              key={member.studliId}
              hasonclick={hasOnClick ? 1 : 0}
              onClick={() => onAssignmentStudentClick(member.studliId)}
            >
              <ListItemAvatar>
                <Avatar
                  picture={member.picture}
                  firstName={member.firstName}
                  lastName={member.lastName}
                  studliId={member.studliId}
                />
              </ListItemAvatar>
              <StyledTextProgressWrapper>
                <ListItemText>
                  {buildName(member.firstName, member.lastName)}
                  <MessageIndicator
                    assignmentId={assignmentId}
                    memberId={member.studliId}
                  />
                  {studentValidationError && (
                    <StyledValidationErrorMessage>
                      {studentValidationError}
                    </StyledValidationErrorMessage>
                  )}
                </ListItemText>

                {editMode ? (
                  <>
                    {showMenu && (
                      <MoreMenu
                        menuItems={[
                          {
                            action: () =>
                              removeStudentFromAssignment(member.studliId),
                            option: "Ta bort"
                          }
                        ]}
                      />
                    )}
                  </>
                ) : (
                  progress && renderSecondary(member)
                )}
              </StyledTextProgressWrapper>
            </StyledListItem>
          )
        })}
      </List>
    </>
  )
}

export default AssignmentStudentsList
