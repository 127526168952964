import { styled } from "@mui/material"

export const StyledLoader = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: auto;

  font-size: 1.5em;
`
