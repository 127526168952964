import { setField } from "../../shared/tools/monocle"
import { createReducer } from "../../shared/tools/redux"
import * as actions from "./actions"
import { pipe } from "fp-ts/lib/pipeable"
import {
  State,
  FormativeQuestions,
  FormativeTest,
  FormativeTests,
  PartialFormativeTest,
  ReceiveProgressionAction,
  FormativeProgression,
  FormativeProgressionAnswer,
  PreparedTests
} from "./types"
import { Lens } from "monocle-ts"

const initialState: State = {
  questions: [],
  tests: [],
  importableTests: [],
  preparedTests: [],
  progression: [],
  progressionAnswers: []
}
const setQuestions = setField<State, FormativeQuestions>("questions", p => p)

const setTests = setField<State, FormativeTests>("tests", p => p)

const setProgress =
  (payload: ReceiveProgressionAction["payload"]) => (state: State) => {
    const setProgress = Lens.fromProp<State>()("progression").modify(
      _ => payload.progression
    )
    const setProgressAnswers = Lens.fromProp<State>()(
      "progressionAnswers"
    ).modify(_ => payload.answers)

    return pipe(state, setProgress, setProgressAnswers)
  }

const setImportableTests = setField<State, FormativeTests>(
  "importableTests",
  p => p
)

const setPreparedTests = setField<State, PreparedTests>("preparedTests", p => p)

const addFormativeTest = (test: FormativeTest) =>
  Lens.fromProp<State>()("tests").modify(states => {
    return [...states.filter(savedTest => savedTest.id !== test.id), test]
  })

const addProgressPost = (progressPost: FormativeProgression) =>
  Lens.fromProp<State>()("progression").modify(states => {
    const indexOfSavedProgress = states.findIndex(
      savedProgress =>
        savedProgress.memberId === progressPost.memberId &&
        savedProgress.sessionId === progressPost.sessionId &&
        savedProgress.testId === progressPost.testId
    )
    const newProgress = [...states]
    if (indexOfSavedProgress !== -1) {
      newProgress.splice(indexOfSavedProgress, 1)
    }
    return [...newProgress, progressPost]
  })

const addAnswerPost = (answerPost: FormativeProgressionAnswer) =>
  Lens.fromProp<State>()("progressionAnswers").modify(states => {
    const indexOfSavedProgress = states.findIndex(
      savedProgress =>
        savedProgress.memberId === answerPost.memberId &&
        savedProgress.sessionId === answerPost.sessionId &&
        savedProgress.testId === answerPost.testId &&
        savedProgress.questionId === answerPost.questionId
    )
    const newProgressionAnswers = [...states]
    if (indexOfSavedProgress !== -1) {
      newProgressionAnswers.splice(indexOfSavedProgress, 1)
    }
    return [...newProgressionAnswers, answerPost]
  })

const removeFormativeTest = ({ testId }: { testId: number }) =>
  Lens.fromProp<State>()("tests").modify(states => {
    return states.filter(savedTest => savedTest.id !== testId)
  })

const setPendingTestImport = setField<State, PartialFormativeTest>(
  "pendingImport",
  p => p
)
const emptyPendingTestImport = setField<State>("pendingImport", () => undefined)

const setInitialState = () => (state: State) => initialState

const reducer = createReducer<State>(initialState, builder =>
  builder
    .case(actions.setQuestions, setQuestions)
    .case(actions.addFormativeTest, addFormativeTest)
    .case(actions.setTests, setTests)
    .case(actions.setImportableTests, setImportableTests)
    .case(actions.setImportedTest, setPendingTestImport)
    .case(actions.resetImportedTest, emptyPendingTestImport)
    .case(actions.removeFormativeTestFromList, removeFormativeTest)
    .case(actions.setProgression, setProgress)
    .case(actions.resetFormativeQuestions, setInitialState)
    .case(actions.addProgressPost, addProgressPost)
    .case(actions.addAnswerPost, addAnswerPost)
    .case(actions.setPreparedTests, setPreparedTests)
)

export default reducer
