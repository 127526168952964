/* eslint-disable import/no-cycle */

import * as routerActions from "./modules/router/actions"
import { RootState } from "./modules/store"
import { createSelector } from "reselect"
import { GotoRoute } from "./modules/router/types"
import {
  selectAssignmentProgressCacheOrFetch,
  selectBooksCacheOrFetch,
  selectExercisesCacheOrFetch,
  selectExercisesProgressCacheOrFetch,
  selectFormativeQuestionsCacheOrFetch,
  selectGoalsCacheOrFetch,
  selectGoalsProgressCacheOrFetch,
  selectImportableAssignmentsCacheOrFetch,
  selectImportableTestsCacheOrFetch,
  selectMembersCacheOrFetch,
  selectMessagesCacheOrFetch,
  selectPreparedAssignmentsCacheOrFetch,
  selectPreparedTestsCacheOrFetch,
  selectTestProgressionCacheOrFetch,
  selectTestsCacheOrFetch,
  selectTocCacheOrFetch,
  selectUsersOnlineCacheOrFetch,
  selectUsersWithProductCacheOrFetch
} from "./modules/router/dataSelectors"
import { USER_ROLE } from "./modules/users/constants"
import { findById } from "./pages/Exercises/common/helpers"

const confirmMsg = `Du har gjort ändringar som inte kommer att sparas om du väljer att lämna sidan.`
const handleHasEditedForm = (isEdited: boolean) => {
  if (isEdited) {
    return confirmMsg
  }
}

export const routesMap: any = {
  // Accepts both "/klassrum/:classroomId/start" and "/klassrum/:classroomId" see https://github.com/faceyspacey/redux-first-router/issues/83#issuecomment-327703226
  [routerActions.ROUTE_START]: {
    path: "/:classroomId/(start)?",
    title: "Start",
    helpTags: ["Kom igång", "Lägg till elever", "Uppdrag"],
    data: createSelector(
      selectMembersCacheOrFetch,
      selectUsersOnlineCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectAssignmentProgressCacheOrFetch,
      selectMessagesCacheOrFetch,
      selectTestsCacheOrFetch,
      selectTestProgressionCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_ELEVER]: {
    path: "/:classroomId/elever",
    parent: routerActions.ROUTE_START,
    title: "Elever",
    helpTags: [
      "Lägg till elever",
      "Hämta befintliga elever",
      "Skapa konto till elever",
      "Klassrumskoden",
      "Aktivera",
      "Mål",
      "Följ upp elevernas arbete"
    ],
    uriMatch: (uri: string) => uri === "students",
    data: createSelector(
      [
        selectMembersCacheOrFetch,
        selectUsersOnlineCacheOrFetch,
        selectUsersWithProductCacheOrFetch,
        selectAssignmentProgressCacheOrFetch,
        selectTestProgressionCacheOrFetch,
        selectExercisesProgressCacheOrFetch,
        selectGoalsCacheOrFetch,
        selectGoalsProgressCacheOrFetch
      ],
      (...actions) => actions.filter(Boolean)
    )
  },
  [routerActions.ROUTE_LAGGTILL]: {
    path: "/:classroomId/elever/laggtill",
    parent: routerActions.ROUTE_ELEVER,
    title: "Lägg till elever",
    helpTags: [
      "Skapa konto till elever",
      "Lägg till elever",
      "Klassrumskoden",
      "Hämta befintliga elever"
    ],
    uriMatch: (uri: string) => uri === "students:add"
  },

  [routerActions.ROUTE_ELEVKORT]: {
    path: "/:classroomId/elever/:memberId",
    parent: routerActions.ROUTE_ELEVER,
    helpTags: ["Följ upp elevernas arbete"],
    title: createSelector(
      ({ location }: RootState) => location.payload,
      ({ members }: RootState) =>
        members.members.filter(m => m.type === USER_ROLE.STUDENT),

      ({ memberId }, students) => {
        const student = students.find(
          s => s.studliId === parseInt(memberId, 10)
        )

        if (!student) {
          return ""
        }

        return `${student.firstName} ${student.lastName}`
      }
    ),
    uriParams: [
      { from: "student", to: "studentId" },
      { from: "product", to: "productId" }
    ],
    uriMatch: (uri: string) => {
      const arr = uri.split(":")

      if (arr.length !== 4) {
        return false
      }

      const [productKey, productId, studentKey, studentId] = arr

      if (studentKey !== "student" || productKey !== "product") {
        return false
      }

      return !isNaN(parseInt(studentId, 10)) || !isNaN(parseInt(productId, 10))
    },
    data: createSelector(
      selectMembersCacheOrFetch,
      selectUsersOnlineCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectExercisesCacheOrFetch,
      selectTestProgressionCacheOrFetch,
      selectTestsCacheOrFetch,
      selectAssignmentProgressCacheOrFetch,
      selectGoalsCacheOrFetch,
      selectGoalsProgressCacheOrFetch,
      selectExercisesProgressCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_LARARE]: {
    path: "/:classroomId/larare",
    parent: routerActions.ROUTE_START,
    title: "Lärare",
    helpTags: ["Lärare"],
    uriMatch: (uri: string) => uri === "teachers",
    data: createSelector(selectMembersCacheOrFetch, (...actions) =>
      actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_LAGGTILL_LARARE]: {
    path: "/:classroomId/larare/laggtill",
    parent: routerActions.ROUTE_LARARE,
    title: "Lägg till lärare",
    helpTags: ["Lärare"],
    uriMatch: (uri: string) => uri === "teachers:add"
  },

  [routerActions.ROUTE_MAL]: {
    path: "/:classroomId/mal",
    parent: routerActions.ROUTE_START,
    title: "Mål",
    helpTags: ["Mål"],
    uriMatch: (uri: string) => uri === "goals",
    data: createSelector(
      selectMembersCacheOrFetch,
      selectGoalsCacheOrFetch,
      selectUsersOnlineCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectGoalsProgressCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_GOALS_DETAILS]: {
    path: "/:classroomId/mal/:goalId",
    parent: routerActions.ROUTE_MAL,
    title: createSelector(
      ({ goals }: RootState) => goals.goals,
      ({ location }: RootState) => location.payload.goalId,
      (goals, goalId) => {
        const goal = goals.find(g => g.goalRef === goalId)
        if (!goal) {
          return ""
        }

        return `${goal.order}. ${goal.title}`
      }
    ),
    helpTags: ["Mål", "Följ upp elevernas arbete"],
    uriMatch: (uri: string) => uri === "goalsdetails",
    data: createSelector(
      selectMembersCacheOrFetch,
      selectGoalsCacheOrFetch,
      selectUsersOnlineCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectGoalsProgressCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_OVNINGAR]: {
    path: "/:classroomId/ovningar",
    parent: routerActions.ROUTE_START,
    title: "Övningar",
    helpTags: ["Övningar"],
    uriMatch: (uri: string) => uri === "exercises",
    data: createSelector(
      [
        selectExercisesProgressCacheOrFetch,
        selectMembersCacheOrFetch,
        selectUsersOnlineCacheOrFetch,
        selectUsersWithProductCacheOrFetch
      ],
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_OVNING_INFO]: {
    path: "/:classroomId/ovning/:exerciseId",
    parent: routerActions.ROUTE_OVNINGAR,
    helpTags: ["Övningar", "Följ upp elevernas arbete"],
    title: createSelector(
      ({ exercises }: RootState) => exercises.exercises,
      ({ location }: RootState) => location.payload.exerciseId,
      (exercises, exerciseId) => {
        const exercise = findById(exercises, exerciseId, "id")

        if (!exercise) {
          return ""
        }

        return exercise.title
      }
    ),
    data: createSelector(
      selectMembersCacheOrFetch,
      selectExercisesProgressCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_UPPDRAG]: {
    path: "/:classroomId/uppdrag",
    parent: routerActions.ROUTE_START,
    title: "Uppdrag",
    helpTags: ["Skapa uppdrag", "Uppdrag", "Följ upp ett uppdrag"],
    uriMatch: (uri: string) => uri === "assignments",
    data: createSelector(
      selectMembersCacheOrFetch,
      selectUsersOnlineCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectAssignmentProgressCacheOrFetch,
      selectPreparedAssignmentsCacheOrFetch,
      selectImportableAssignmentsCacheOrFetch,
      selectMessagesCacheOrFetch,
      selectBooksCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_UPPDRAG_LAGGTILL_NYTT]: {
    path: "/:classroomId/uppdrag/laggtill/nytt",
    parent: routerActions.ROUTE_UPPDRAG,
    title: "Lägg till nytt",
    helpTags: ["Skapa uppdrag"],
    confirmLeave: (state: RootState) => {
      const hasEditedForm = state.pages.createEditAssignment.isCreateFormDirty
      return handleHasEditedForm(hasEditedForm)
    },
    uriMatch: (uri: string) => uri === "assignments:add",
    data: createSelector(
      selectMembersCacheOrFetch,
      selectUsersOnlineCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectAssignmentProgressCacheOrFetch,
      selectBooksCacheOrFetch,
      selectGoalsCacheOrFetch,
      selectGoalsProgressCacheOrFetch,
      selectTocCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_UPPDRAG_INFO]: {
    path: "/:classroomId/uppdrag/:assignmentId",
    parent: routerActions.ROUTE_UPPDRAG,
    helpTags: ["Följ upp ett uppdrag"],
    title: createSelector(
      ({ location }: RootState) => location.payload,
      ({ assignments }: RootState) => assignments.assignments,
      ({ assignmentId }, assignments) => {
        const assignment = assignments.find(a => a.id === Number(assignmentId))

        if (!assignment) {
          return ""
        }

        return assignment.title
      }
    ),
    uriParams: [
      { from: "assignment", to: "assignmentId" },
      { from: "product", to: "productId" }
    ],
    uriMatch: (uri: string) => {
      const arr = uri.split(":")

      if (arr.length !== 4) {
        return false
      }

      const [productKey, productId, assignmentKey, assignmentId] = arr

      if (assignmentKey !== "assignment" || productKey !== "product") {
        return false
      }

      return (
        !isNaN(parseInt(assignmentId, 10)) || !isNaN(parseInt(productId, 10))
      )
    },
    data: createSelector(
      selectMembersCacheOrFetch,
      selectAssignmentProgressCacheOrFetch,
      selectMessagesCacheOrFetch,
      selectGoalsCacheOrFetch,
      selectGoalsProgressCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_UPPDRAG_STUDENT_INFO]: {
    path: "/:classroomId/uppdrag/:assignmentId/elev/:memberId",
    parent: routerActions.ROUTE_UPPDRAG_INFO,
    helpTags: ["Följ upp ett uppdrag"],
    confirmLeave: (state: RootState) => {
      const hasSentFeedback = state.pages.assignmentStudentCard.hasSentFeedback
      const hasSentBackAssignment =
        state.pages.assignmentStudentCard.hasSentBackAssignment

      if (hasSentFeedback && !hasSentBackAssignment) {
        return confirmMsg
      }
    },
    title: createSelector(
      ({ location }: RootState) => location.payload,
      ({ members }: RootState) => members.members,
      ({ memberId }, members) => {
        const member = members.find(m => m.studliId === Number(memberId))

        if (!member) {
          return ""
        }

        return `${member.firstName} ${member.lastName}`
      }
    ),
    uriParams: [
      { from: "product", to: "productId" },
      { from: "assignment", to: "assignmentId" },
      { from: "member", to: "memberId" }
    ],
    uriMatch: (uri: string) => {
      const arr = uri.split(":")

      if (arr.length !== 6) {
        return false
      }

      const [
        productKey,
        productId,
        assignmentKey,
        assignmentId,
        memberKey,
        memberId
      ] = arr

      if (
        assignmentKey !== "assignment" ||
        memberKey !== "member" ||
        productKey !== "product"
      ) {
        return false
      }

      return (
        !isNaN(parseInt(assignmentId, 10)) ||
        !isNaN(parseInt(memberId, 10)) ||
        !isNaN(parseInt(productId, 10))
      )
    },
    data: createSelector(
      selectMembersCacheOrFetch,
      selectAssignmentProgressCacheOrFetch,
      selectExercisesProgressCacheOrFetch,
      selectMessagesCacheOrFetch,
      selectGoalsCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },

  [routerActions.ROUTE_UPPDRAG_REDIGERA]: {
    path: "/:classroomId/uppdrag/:assignmentId/redigera",
    parent: routerActions.ROUTE_UPPDRAG,
    helpTags: ["Redigera uppdrag"],
    confirmLeave: (state: RootState) => {
      const hasEditedForm = state.pages.createEditAssignment.isCreateFormDirty
      return handleHasEditedForm(hasEditedForm)
    },
    title: createSelector(
      ({ location }: RootState) => location.payload,
      ({ assignments }: RootState) => assignments.assignments,
      ({ assignmentId }, assignments) => {
        const assignment = assignments.find(a => a.id === Number(assignmentId))

        if (!assignment) {
          return ""
        }

        return `Redigera ${assignment.title}`
      }
    ),
    uriParams: [
      { from: "assignment", to: "assignmentId" },
      { from: "product", to: "productId" }
    ],
    uriMatch: (uri: string) => {
      const arr = uri.split(":")

      if (arr.length !== 5) {
        return false
      }

      const [productKey, productId, assignmentKey, assignmentId, action] = arr

      if (
        assignmentKey !== "assignment" ||
        action !== "edit" ||
        productKey !== "product"
      ) {
        return false
      }

      return (
        !isNaN(parseInt(assignmentId, 10)) || !isNaN(parseInt(productId, 10))
      )
    },
    data: createSelector(
      selectMembersCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectBooksCacheOrFetch,
      selectAssignmentProgressCacheOrFetch,
      selectGoalsCacheOrFetch,
      selectGoalsProgressCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },
  [routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA]: {
    path: "/:classroomId/formativafragor/:testId/redigera",
    parent: routerActions.ROUTE_FORMATIVA_FRAGOR,
    helpTags: ["Redigera test"],
    title: createSelector(
      ({ location }: RootState) => location.payload,
      ({ formative }: RootState) => formative.tests,
      ({ testId }, tests) => {
        const test = tests.find(t => t.id === Number(testId))

        if (!test) {
          return ""
        }

        return `Redigera ${test.title}`
      }
    ),
    data: createSelector(
      selectTestsCacheOrFetch,
      selectMembersCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectFormativeQuestionsCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },
  [routerActions.ROUTE_KLASSRUMSKOD]: "/:classroomId/klassrumskod",
  [routerActions.ROUTE_FORMATIVA_FRAGOR_LAGGTILL]: {
    path: "/:classroomId/formativafragor/laggtill/nytt",
    parent: routerActions.ROUTE_FORMATIVA_FRAGOR,
    title: "Lägg till nytt",
    helpTags: ["Skapa test"],
    data: createSelector(
      selectFormativeQuestionsCacheOrFetch,
      selectMembersCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },
  [routerActions.ROUTE_FORMATIVA_FRAGOR]: {
    path: "/:classroomId/formativafragor",
    parent: routerActions.ROUTE_START,
    title: "Formativa frågor",
    helpTags: ["Formativa frågor", "Skapa test", "Följ upp ett test"],
    data: createSelector(
      selectTestsCacheOrFetch,
      selectMembersCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectImportableTestsCacheOrFetch,
      selectPreparedTestsCacheOrFetch,
      selectTestProgressionCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },
  [routerActions.ROUTE_FORMATIVA_FRAGOR_INFO]: {
    path: "/:classroomId/formativafragor/:testId",
    parent: routerActions.ROUTE_FORMATIVA_FRAGOR,
    helpTags: ["Följ upp ett test"],
    uriParams: [
      { from: "product", to: "productId" },
      { from: "test", to: "testId" },
      { from: "session", to: "sessionId" }
    ],
    uriMatch: (uri: string) => {
      const arr = uri.split(":")

      if (arr.length !== 6) {
        return false
      }

      const [productKey, productId, testKey, testId] = arr
      if (testKey !== "test" || productKey !== "product") {
        return false
      }

      return !isNaN(parseInt(testId, 10)) || !isNaN(parseInt(productId, 10))
    },
    title: createSelector(
      ({ location }: RootState) => location.payload,
      ({ formative }: RootState) => formative.tests,
      ({ testId }, tests) => {
        const test = tests.find(t => t.id === Number(testId))

        if (!test) {
          return ""
        }

        return test.title
      }
    ),
    data: createSelector(
      selectTestsCacheOrFetch,
      selectMembersCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectFormativeQuestionsCacheOrFetch,
      selectTestProgressionCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  },
  [routerActions.ROUTE_FORMATIVA_FRAGOR_ELEV_INFO]: {
    path: "/:classroomId/formativafragor/:testId/elev/:memberId",
    parent: routerActions.ROUTE_FORMATIVA_FRAGOR_INFO,
    helpTags: ["Följ upp ett test"],
    title: createSelector(
      ({ location }: RootState) => location.payload,
      ({ members }: RootState) => members.members,
      ({ memberId }, members) => {
        const member = members.find(m => m.studliId === memberId)
        if (!member) {
          return ""
        }

        return `${member.firstName} ${member.lastName}`
      }
    ),
    data: createSelector(
      selectTestsCacheOrFetch,
      selectMembersCacheOrFetch,
      selectUsersWithProductCacheOrFetch,
      selectFormativeQuestionsCacheOrFetch,
      selectTestProgressionCacheOrFetch,
      (...actions) => actions.filter(Boolean)
    )
  }
}

function Tuples<K, V>(arr: unknown[]) {
  const data = arr.reduce((res, item, i) => {
    if (i % 2 === 0) {
      return [...(res as [K, V][]), [item, arr[i + 1]]] as [K, V][]
    }

    return res as [K, V][]
  }, [] as [K, V][]) as [K, V][]

  return Object.freeze({
    data,
    get: (key: K) => {
      const item = data.find(i => i.length === 2 && i[0] === key)

      if (!item) {
        return
      }

      return item[1]
    }
  })
}

export function convertURIToRoute(
  uri: string,
  classroomId: number
): GotoRoute | undefined {
  const [base, ...rest] = uri.split(":")
  if (base !== "tecla") {
    console.warn("external uri:s are not supported yet")

    return
  }

  return Object.keys(
    routesMap as {
      [key: string]: {
        uriMatch: (uri: string) => boolean
        uriParams: { from: string; to: string }[]
      }
    }
  ).reduce<GotoRoute | undefined>((res, key) => {
    if (res) {
      return res
    }

    const route = routesMap[key] as {
      uriMatch?: (uri: string) => boolean
      uriParams?: { from: string; to: string }[]
    }
    if (!route.uriMatch || !route.uriMatch(rest.join(":"))) {
      return undefined
    }

    const data = Tuples<string, string>(rest)

    return routerActions.gotoRoute(key, {
      classroomId,
      ...(route.uriParams || []).reduce((result, { from, to }) => {
        return {
          ...result,
          [to]: data.get(from)
        } as { [key: string]: string | undefined }
      }, {} as { [key: string]: string | undefined })
    })
  }, undefined)
}

export default routesMap
