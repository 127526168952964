import React, { useCallback, useEffect, useMemo } from "react"
import {
  StyledTableHead,
  StyledHeadCell
} from "../MembersTable/StyledMembersTable"
import { TableRow, Table, TableBody, TableSortLabel } from "@mui/material"
import InfoPopover from "../InfoPopover"
import { PROGRESS_SEGMENT } from "../Progress/constants"
import {
  StyledLegendContainer,
  StyledLegendBox
} from "../GoalsTable/StyledGoalsTable"
import {
  FormativeTest,
  FormativeTestMenuOptions,
  FormativeTests,
  FormativeProgressionArr
} from "../../modules/formative/types"
import { ProgressForBar } from "./types"
import FormativeQuestionsTableRow from "./FormativeQuestionsTableRow"
import TableToolbar from "../TableToolbar"
import { PossibleStatusActions } from "../../pages/FormativeQuestions/types"
import {
  getStatusString,
  getTestStatusWeight,
  menuOptions
} from "../../pages/FormativeQuestions/helpers"
import TableFilter from "../TableFilter/TableFilter"
import { FilterValue } from "../TableFilter/types"
import { Members } from "../../modules/members/types"
import { useSelector } from "react-redux"
import {
  selectAllActiveGroups,
  selectAllGroups
} from "../../modules/classroom/selectors"
import { buildName } from "../../shared/tools/strings"
import { sortByLastName } from "../AssignmentsTable/helpers"
import { tableFilter } from "../../shared/tools/filter"
import { selectAuthenticatedUser } from "../../modules/users/selectors"

type Sort = {
  orderBy: string
  order: "asc" | "desc"
}

const NOOP = () => {}

type Props = {
  tests: FormativeTests
  title: string
  onActionStatusClicked: (
    event: any,
    status: PossibleStatusActions,
    testName: string,
    testId: number
  ) => void
  showIcon?: boolean
  progress: FormativeProgressionArr
  onMenuClick: (
    type: FormativeTestMenuOptions,
    testId: FormativeTest["id"]
  ) => void
  onProgressDialogClick: (progress: ProgressForBar, title: string) => void
  onRowClick: (testId: number) => void
  type: "studentCard" | "class"
  members?: Members
  showFilter?: boolean
}
const FormativeQuestionsTable = ({
  tests,
  title,
  onActionStatusClicked,
  onMenuClick,
  onProgressDialogClick,
  onRowClick,
  progress,
  type,
  showIcon,
  members,
  showFilter
}: Props) => {
  const groups = useSelector(selectAllGroups)
  const activeGroups = useSelector(selectAllActiveGroups)
  const user = useSelector(selectAuthenticatedUser)

  const [sortedTests, setSortedTests] = React.useState(tests)
  const [sortVal, setSortVal] = React.useState<Sort>({
    orderBy: "status",
    order: "asc"
  })
  const [currentFilters, setCurrentFilters] = React.useState<FilterValue[]>([])
  const menuItemClick = (
    type: FormativeTestMenuOptions,
    testId: FormativeTest["id"]
  ) => {
    onMenuClick(type, testId)
  }

  const onFilter = useCallback(
    (filterValues: FilterValue[]) => {
      return tableFilter(filterValues, tests)
    },
    [tests]
  )

  const onSort = (sort: Sort, filteredTests: FormativeTests) => {
    const { orderBy, order } = sort
    const newOrder = order === "asc" ? "desc" : "asc"
    const sorted = [...filteredTests].sort((a, b) => {
      if (orderBy === "title") {
        return newOrder === "asc"
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title)
      }
      if (orderBy === "status") {
        return newOrder === "asc"
          ? getTestStatusWeight(a.status) - getTestStatusWeight(b.status)
          : getTestStatusWeight(b.status) - getTestStatusWeight(a.status)
      }
      return sortByLastName(newOrder, a.createdByLastName, b.createdByLastName)
    })
    return sorted
  }

  const setSort = (orderBy: string) => {
    setSortVal(prev => ({
      orderBy,
      order:
        prev.orderBy === orderBy
          ? prev.order === "asc"
            ? "desc"
            : "asc"
          : "asc"
    }))
  }

  useEffect(() => {
    const filteredTests = onFilter(currentFilters)
    const sorted = onSort(sortVal, filteredTests)
    setSortedTests(sorted)
  }, [tests, currentFilters, onFilter, sortVal])

  const testsCreatedBy = useMemo(() => {
    return [
      ...Array.from(
        new Set(
          tests.map(test => ({
            createdBy: test.createdBy,
            createdByFirstName: test.createdByFirstName,
            createdByLastName: test.createdByLastName,
            createdByPictureUrl: test.createdByPictureUrl
          }))
        )
      ).filter(
        (createdBy, index, self) =>
          index === self.findIndex(c => c.createdBy === createdBy.createdBy)
      )
    ]
  }, [tests])

  const getTestGroups = useCallback((t: FormativeTest) => {
    const allGroups = t.participantsInfo.reduce(
      (acc: number[], participant) => {
        return [...acc, ...participant.groups]
      },
      []
    )
    return Array.from(new Set(allGroups))
  }, [])

  const getTestsStatusFilterOptions = () => {
    return [
      ...Array.from(new Set(tests.map(test => test.status as string)))
    ].map((status: string) => ({
      label: getStatusString(status as any),
      val: status,
      key: "status",
      filterFunction: (test: FormativeTest) => {
        return test.status === status
      }
    }))
  }

  const getFilters = () => {
    return [
      {
        label: "Grupper",
        key: "groups",
        options: groups.map(group => ({
          label: group.name,
          val: group.id,
          key: "groups",
          filterFunction: (t: FormativeTest) =>
            t.sessions.length
              ? getTestGroups(t).some(g => g === group.id)
              : NOOP
        }))
      },
      {
        label: "Status",
        key: "status",
        options: getTestsStatusFilterOptions()
      },
      {
        label: "Skapat av",
        key: "createdBy",
        options: testsCreatedBy.map(createdBy => ({
          label: buildName(
            createdBy.createdByFirstName,
            createdBy.createdByLastName
          ),
          val: createdBy,
          key: "createdBy",
          filterFunction: (t: FormativeTest) =>
            t.createdBy === createdBy.createdBy
        }))
      }
    ]
  }

  const initialGroupFilter = useMemo(() => {
    if (groups.length !== activeGroups.length) {
      return activeGroups.map(group => ({
        label: group.name,
        val: group.id,
        key: "groups",
        filterFunction: (t: FormativeTest) =>
          t.sessions.length ? getTestGroups(t).some(g => g === group.id) : NOOP
      }))
    }
  }, [groups, activeGroups, getTestGroups])

  const setFilters = (filterValues: FilterValue[]) => {
    setCurrentFilters(filterValues)
  }

  const searchFunction = (t: FormativeTest, searchText: string) =>
    t.title?.toLowerCase().includes(searchText.toLowerCase())

  const renderTableHeader = () => {
    return (
      <StyledTableHead>
        <TableRow>
          {type === "class" && <StyledHeadCell></StyledHeadCell>}
          {showIcon && <StyledHeadCell></StyledHeadCell>}
          <StyledHeadCell>
            {type === "class" ? (
              <TableSortLabel
                active={sortVal.orderBy === "title"}
                direction={sortVal.orderBy === "title" ? sortVal.order : "asc"}
                onClick={() => setSort("title")}
              >
                Titel
              </TableSortLabel>
            ) : (
              "Titel"
            )}
          </StyledHeadCell>
          <StyledHeadCell>
            {" "}
            {type === "class" ? (
              <TableSortLabel
                active={sortVal.orderBy === "status"}
                direction={sortVal.orderBy === "status" ? sortVal.order : "asc"}
                onClick={() => setSort("status")}
              >
                Status
              </TableSortLabel>
            ) : (
              "Status"
            )}
          </StyledHeadCell>
          {type === "class" && <StyledHeadCell>Grupper</StyledHeadCell>}

          {type === "class" && <StyledHeadCell>Senast ändrat</StyledHeadCell>}
          <StyledHeadCell>
            {type === "class" ? "Elevernas aktivitet" : "Elevens aktivitet"}
            <InfoPopover
              type="Info"
              content={
                <div>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.DONE} />
                    Inlämnat / Klar
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.IN_PROGRESS} />
                    Påbörjad
                  </StyledLegendContainer>
                  <StyledLegendContainer>
                    <StyledLegendBox segment={PROGRESS_SEGMENT.NOT_STARTED} />
                    Ej påbörjad
                  </StyledLegendContainer>
                </div>
              }
            />
          </StyledHeadCell>
          {type === "class" && (
            <StyledHeadCell>
              <TableSortLabel
                active={sortVal.orderBy === "createdBy"}
                direction={
                  sortVal.orderBy === "createdBy" ? sortVal.order : "asc"
                }
                onClick={() => setSort("createdBy")}
              >
                Skapat av
              </TableSortLabel>
            </StyledHeadCell>
          )}

          {type === "class" && <StyledHeadCell></StyledHeadCell>}
        </TableRow>
      </StyledTableHead>
    )
  }

  const renderTableBody = () => {
    return (
      <TableBody>
        {sortedTests.map(test => {
          return (
            <FormativeQuestionsTableRow
              onActionStatusClicked={onActionStatusClicked}
              test={test}
              key={test.id}
              type={type}
              showIcon={showIcon}
              progress={progress}
              testGroups={getTestGroups(test)}
              onProgressDialogClick={onProgressDialogClick}
              moreMenuOptions={menuOptions(
                test,
                menuItemClick,
                "list",
                user?.studliId === test.createdBy
              )}
              onRowClick={onRowClick}
            />
          )
        })}
      </TableBody>
    )
  }

  return (
    <>
      {type === "class" && (
        <TableToolbar
          title={title}
          totalItems={tests.length}
          numSelected={0}
          selectedTitle="test valda"
          actions={[]}
          filterComponent={
            showFilter ? (
              <TableFilter
                filters={getFilters()}
                onFilter={setFilters}
                searchFunction={searchFunction}
                searchLabel="Sök titel"
                results={sortedTests.length}
                initialFilters={initialGroupFilter}
              />
            ) : null
          }
        />
      )}
      <Table>
        {renderTableHeader()}
        {renderTableBody()}
      </Table>
    </>
  )
}

export default FormativeQuestionsTable
