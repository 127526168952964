import React, { useState } from "react"
import { useSelector } from "react-redux"
import MoreMenu, { MenuItems } from "../PopupMenu/PopupMenu"
import { StyledHelpIcon } from "./StyledHeaderHelp"
import { Help } from "@studli/aidahelp"
import "@studli/aidahelp/dist/index.css"
import { selectClassroomHelpTags } from "../../modules/classroom/selectors"

type Props = {
  classroomId: number
  goToRoute: (type: string, payload: object) => void
}

export const TEMP_FIX = ["Portia", "Portia/Bas", "Portia/Lärare"]

const HeaderHelp = ({ goToRoute, classroomId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const helpClassroomTags = useSelector(selectClassroomHelpTags)

  const setIconButtonAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const menuItems: MenuItems = [
    { option: "Rapportera problem", action: () => redirectToSupportForm() },
    {
      option: "Hjälp",
      action: () => setIsOpen(true)
    }
  ]

  const redirectToSupportForm = () => {
    const url = process.env.REACT_APP_BOOKSHELF_URL
    const encodedURI = encodeURIComponent(window.location.href)
    const redirectURL = `${url}/kontakta-oss?url=${encodedURI}`
    window.open(redirectURL, "_blank", "noopener")
  }

  return (
    <div>
      <MoreMenu
        aria-label="Hjälpknapp"
        menuItems={menuItems}
        icon={<StyledHelpIcon />}
        iconColor="currentColor"
        setAnchor={setIconButtonAnchor}
      />
      {isOpen && (
        <Help
          position={{ windowPosition: "topRight" }}
          anchorEl={anchorEl}
          open={isOpen}
          env={process.env.REACT_APP_ENV as "dev" | "test" | "production"}
          applicationTag="Tecla"
          tags={[]}
          excludedTags={helpClassroomTags ? helpClassroomTags.excludedTags : []}
          excludeVisibleTags={
            helpClassroomTags
              ? [helpClassroomTags.activeTag, ...TEMP_FIX]
              : [...TEMP_FIX]
          }
          closeHelp={handleClose}
          supportURL={
            `${process.env.REACT_APP_BOOKSHELF_URL}/kontakta-oss` || ""
          }
        />
      )}
    </div>
  )
}

export default HeaderHelp
