import * as React from "react"
import MTabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

import TabPanel from "./TabPanel"
import { Divider } from "@mui/material"
import Wrapper from "./Wrapper"
import useWindowSize from "../../hooks/useWindowSize"

export type Item = {
  label: React.ReactNode
  filterLabel: string
  Component: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

export type Props = {
  prefix: string
  label: string
  items: Item[]
}

function a11yProps(prefix: string, index: number) {
  return {
    id: `${prefix}-${index}`,
    "aria-controls": `${prefix}-tabpanel-${index}`
  }
}

const Tabs: React.FC<Props> = ({ items, prefix, label }) => {
  const [value, setValue] = React.useState(0)
  const windowSize = useWindowSize()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) =>
    setValue(newValue)

  return (
    <Wrapper>
      <MTabs
        centered={windowSize.md}
        variant={!windowSize.md ? "fullWidth" : undefined}
        value={value}
        onChange={handleChange}
        aria-label={label}
      >
        {items.map((item, i) => (
          <Tab key={i} label={item.label} {...a11yProps(prefix, i)} />
        ))}
      </MTabs>
      <Divider />
      {items.map(({ Component, label: itemLabel }, i) => {
        const a11y = a11yProps(prefix, i)

        return (
          <TabPanel
            key={i}
            role="tabpanel"
            hidden={value !== i}
            id={a11y["aria-controls"]}
            aria-labelledby={a11y.id}
          >
            {value === i && <Component />}
          </TabPanel>
        )
      })}
    </Wrapper>
  )
}

Tabs.defaultProps = {
  prefix: "tab"
}

export default Tabs
