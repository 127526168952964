import "react-app-polyfill/ie11"
import "react-dates/initialize"
import React from "react"
import ReactDOM from "react-dom"
import ReactGA from "react-ga"
import * as Sentry from "@sentry/browser"

import App from "./App"
import createStore from "./modules/store"
import * as ApplicationModule from "./modules/application"
import * as Classroom from "./modules/classroom"
import * as Communication from "./modules/communication"
import * as SocketModule from "./modules/socket"
import * as ProductModule from "./modules/products"
import * as MembersModule from "./modules/members"
import * as UsersModule from "./modules/users"
import * as AuthModule from "./modules/auth"
import * as GoalsModule from "./modules/goals"
import * as GoalsProgressModule from "./modules/goalsProgress"
import * as ExercisesModule from "./modules/exercises"
import * as ExercisesProgressModule from "./modules/exercisesProgress"
import * as AssignmentsModule from "./modules/assignments"
import * as AssignmentsProgressModule from "./modules/assignmentsProgress"
import * as NotificationsModule from "./modules/notifications"
import * as BooksModule from "./modules/book"
import * as RouterModule from "./modules/router"
import * as FormativeModule from "./modules/formative"

import packageJson from "../package.json"
import { getApplicationVersion } from "./shared/tools/misc"

if (["prod", "production"].indexOf(process.env.REACT_APP_ENV || "") !== -1) {
  Sentry.init({
    dsn: "https://fb90f060859b445296cd6fe294d2297d@sentry.io/1846398",
    release: `${packageJson.name}@${getApplicationVersion()}`,
    environment: "production"
  })
}

ReactGA.initialize("UA-36803650-3")

// Init all sagas:
ApplicationModule.init()
RouterModule.init()
Classroom.init()
Communication.init()
SocketModule.init()
ProductModule.init()
MembersModule.init()
UsersModule.init()
AuthModule.init()
GoalsModule.init()
GoalsProgressModule.init()
ExercisesModule.init()
ExercisesProgressModule.init()
AssignmentsModule.init()
AssignmentsProgressModule.init()
BooksModule.init()
NotificationsModule.init()
FormativeModule.init()
export const store = createStore()

ReactDOM.render(
  <App store={store} />,
  document.getElementById("root") as HTMLElement
)
