import React from "react"
import {
  AssignmentsWithStatusAndProgression,
  AssignmentWithStatusAndProgression,
  Assignment
} from "../../modules/assignments/types"
import Progress, { SimpleProgress } from "../Progress"
import { StyledStatusTag } from "../../pages/Assignments/StyledAssignments"
import MoreMenu, { MenuItems } from "../PopupMenu/PopupMenu"
import {
  StyledPrimaryTextContainer,
  StyledPrimaryText,
  StyledSticker,
  StyledListItemContent,
  StyledListItemIcon,
  StyledAssignmentIcon,
  StyledListItem,
  StyledErrorMsg
} from "./StyledAssignmentsListItem"
import { calculatedStatusTag } from "../../pages/Assignments/common/helpers"
import { Sticker, Stickers } from "../../modules/classroom/types"
import MessageIndicator from "../MessageIndicator"
import { ListItemButton } from "@mui/material"

type Props = {
  assignment: AssignmentWithStatusAndProgression
  onCheckboxClick: (
    event: any,
    assignment: AssignmentWithStatusAndProgression
  ) => void
  checkedAssignments: AssignmentsWithStatusAndProgression
  moreMenuOptions: MenuItems
  onAssignmentClick: (assignmentId: Assignment["id"]) => void
  onProgressClick: (assignment: AssignmentWithStatusAndProgression) => void
  type: "start" | "assignmentsList" | "studentCard"
  stickers: Stickers
  needManualModification?: boolean
}

const AssignmentsListItem = ({
  assignment,
  checkedAssignments,
  moreMenuOptions,
  onAssignmentClick,
  onProgressClick,
  type,
  stickers,
  needManualModification
}: Props) => {
  const getCurrentSticker = (id: Sticker["id"]) => {
    const currentSticker = stickers.filter(
      (sticker: Sticker) => sticker.id === id
    )

    return currentSticker.length > 0 ? currentSticker[0] : stickers[0]
  }

  let status = "notStarted"
  const inProgress = assignment.progress.inProgress.length
  const done = assignment.progress.done.length
  const notStarted = assignment.progress.notStarted.length
  const approved = assignment.progress.approved.length

  if (approved > done) {
    status = "approved"
  } else if (done > inProgress) {
    status = "done"
  } else if (inProgress > notStarted) {
    status = "inProgress"
  }

  return (
    <StyledListItem redbar={needManualModification ? 1 : 0}>
      <ListItemButton onClick={() => onAssignmentClick(assignment.id)}>
        <StyledListItemIcon>
          {assignment.stickerId ? (
            <StyledSticker
              alt={getCurrentSticker(assignment.stickerId).name}
              src={getCurrentSticker(assignment.stickerId).path}
            />
          ) : (
            <StyledAssignmentIcon />
          )}
        </StyledListItemIcon>
        <StyledListItemContent>
          <StyledPrimaryTextContainer>
            <StyledPrimaryText>
              {assignment.title}{" "}
              {needManualModification && (
                <>
                  <br />
                  <StyledErrorMsg>
                    Innehållet i uppdraget är förändrat. Se över deluppgifterna.
                  </StyledErrorMsg>
                </>
              )}
            </StyledPrimaryText>
            {assignment.participants.length > 0 && (
              <MessageIndicator assignmentId={assignment.id} />
            )}
          </StyledPrimaryTextContainer>
          <StyledStatusTag status={assignment.calculatedStatus}>
            {calculatedStatusTag(
              assignment.calculatedStatus,
              assignment.startDate,
              assignment.endDate
            )}
          </StyledStatusTag>
          {type === "studentCard" ? (
            <SimpleProgress status={status} type="assignment" />
          ) : (
            <Progress
              onOpenProgressDialog={() => onProgressClick(assignment)}
              done={done + approved}
              type="assignments"
              inProgress={inProgress}
              notStarted={notStarted}
            />
          )}
        </StyledListItemContent>
        {type === "assignmentsList" && <MoreMenu menuItems={moreMenuOptions} />}
      </ListItemButton>
    </StyledListItem>
  )
}

export default AssignmentsListItem
