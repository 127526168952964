import {
  Typography,
  FormControl,
  Paper,
  TableCell,
  GridProps,
  Grid,
  TableRow,
  styled
} from "@mui/material"
export const StyledHeaderContainer = styled("div")`
  padding: 3em 1em;
`

export const StyledDescription = styled("div")`
  ${props => props.theme.breakpoints.up("sm")} {
    width: 60%;
  }
`

export const StyledHeaderButtonsWrapper = styled("div")`
  display: flex;
  align-items: center;
`
export const StyledButtonWrapper = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledQuestionNumber = styled("div")`
  margin-right: 4px;
`

export const StyledTestNameContainer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 2em;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const StyledTitle = styled(Typography)`
  font-size: 2rem;
  margin-right: 1em;
`

export const StyledFormControl = styled(FormControl)`
  min-width: 11rem;
  margin-right: 1em;
`

export const StyledTestTitleTagContainer = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledPaperContent = styled("div")`
  padding: 1.5em;
  width: 100%;
`

export const StyledResultsButtonsWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`

export const StyledFinishedTextWrapper = styled("div")`
  display: flex;
`

export const StyledFinishedText = styled("div")`
  margin-right: 3px;
`

export const StyledPaper = styled(Paper)``

export const StyledResultsTitle = styled(Typography)`
  font-size: 1.5rem;
`

export const Wrapper = styled("div")`
  width: 100%;

  & .MuiTabs-indicator {
    background-color: #057dda;
  }

  & .Mui-selected {
    color: #057dda;
  }

  & .MuiTab-wrapper {
    letter-spacing: 1.25px;
  }
`

export const StyledProgressWrapper = styled("div")`
  display: flex;
  align-items: center;
`

type StatusTagProps = {
  status: "done" | "inProgress"
}

export const StyledStatusTag = styled("div")`
  background-color: ${(props: StatusTagProps) =>
    props.status === "done" ? "#006CC8;" : "#038763;"}
  color:white;
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 6px 2px 6px;
  display: inline-block;
`

export const StyledAvatarCell = styled(TableCell)`
  max-width: 4em;
  width: 4em;
`

export const StyledNameCell = styled(TableCell)`
  min-width: 12em;
  width: 30%;
`

export const StyledAnswerGrid = styled(
  Grid as React.FC<GridProps & { show?: number }>
)`
  padding: 0 12px 12px 12px;
  display: ${props => (props.show ? "flex" : "none")};
`

export const StyledAnswerStudentGrid = styled(Grid as any)`
  ${props => props.opacity === 1 && "opacity:0.6"}
  display: flex;
  align-items: center;
`

export const StyledAnswerWrapperGrid = styled(Grid as any)`
  ${props => props.opacity === 1 && "opacity:0.6"}
  margin-top: 0.5em;
`

export const StyledEndQuestionTagWrapper = styled("div")`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`

export const StyledEndQuestion = styled(Typography)`
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const StyledLegendContainer = styled("div")`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  align-items: center;
`

export const StyledStudentName = styled("div")`
  margin-left: 1em;
`

export const StyledNameCellWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StyledTableRow = styled(TableRow as any)`
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme && props.theme.studli.table.hover};
  }

  td:first-child {
    padding-left: 1rem;
  }
  td:last-child {
    padding-right: 1rem;
  }
`
