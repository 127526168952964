import DialogActions, { DialogActionsProps } from "@mui/material/DialogActions"
import {
  Typography,
  TextField,
  DialogTitle,
  Select,
  styled
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

export const StyledPagesTextField = styled(TextField)`
  margin-top: 1em !important;
`

export const StyledTableOfContentTitle = styled("div")`
  display: flex;
  align-items: center;
`

export const StyledSelectBookContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`

export const StyledForm = styled("div")`
  & > div {
    margin: 1rem 0;
  }

  & > div:last-child {
    margin: 0;
  }
`

export const StyledRecorderContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`

export const HelpText = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
`

export const StyledDialogContentButtonGroup = styled("div")`
  margin-top: 3em;
`
export const StyledDialogActions = styled(
  DialogActions as React.FC<DialogActionsProps>
)`
  margin-top: 3em;
`

export const StyledErrorMessage = styled("div")`
  padding: 1.5em 0;
  color: ${props => props.theme.studli.status.error};
`

export const StyledSubHeader = styled(Typography)`
  padding-top: 1em;
`

export const StyledDialogTitle = styled(DialogTitle)`
  padding-left: 0;
`

export const StyledCloseIcon = styled(CloseIcon)`
  height: 1.2em;
  width: 1.2em;
`

export const StyledSingleBookName = styled(Typography)``

export const StyledTextSpan = styled(Typography)`
  padding: 0 0 0 0.5rem;
  margin: 0;
`

export const StyledSelect = styled(Select)`
  width: 45%;
  overflow: hidden;
  text-overflow: ellipsis;
`
