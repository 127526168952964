import { Button, Stack, Typography } from "@mui/material"
import React, { useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { updateUserSettingRequest } from "../../modules/users/actions"
import { SETTING } from "../../modules/users/constants"
import { selectAuthenticatedUser } from "../../modules/users/selectors"

const WelcomeBackBanner = () => {
  const user = useSelector(selectAuthenticatedUser)
  const dispatch = useDispatch()

  const showBanner = useMemo(
    () => user?.settings.settingShowWelcomeBackBanner,
    [user]
  )

  const hideBanner = () => {
    dispatch(
      updateUserSettingRequest(
        user?.studliId as number,
        SETTING.settingShowWelcomeBackBanner,
        true
      )
    )
  }

  return showBanner ? (
    <Stack
      sx={{
        bgcolor: "#FFF",
        border: "1px solid #00000017"
      }}
      direction="row"
      role="banner"
      spacing={2}
    >
      <Stack
        direction="row"
        sx={{
          width: "100%",
          flexWrap: "wrap",
          justifyContent: { md: "space-between", xs: "flex-end" }
        }}
      >
        <Stack
          justifyContent="center"
          sx={{ maxWidth: { md: "70%", sm: "100%" }, p: 2 }}
        >
          <Typography fontWeight="500">Välkommen tillbaka</Typography>
          <Typography>
            Vi har genomfört förändringar under sommaren som påverkar dig som
            använder klassrummen. Läs mer om vad det innebär för dig.
          </Typography>
        </Stack>
        <Stack
          p={2}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button
            component="a"
            href={`${process.env.REACT_APP_BOOKSHELF_URL}/info`}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            Läs mer om nyheterna
          </Button>
          <Button color="inherit" onClick={() => hideBanner()}>
            Dölj
          </Button>
        </Stack>
      </Stack>
    </Stack>
  ) : null
}

export default WelcomeBackBanner
