import React from "react"
import {
  FormativeQuestions,
  FormativeQuestion
} from "../../modules/formative/types"
import { Collapse } from "@mui/material"
import FormativeQuestionsListItem from "../FormativeQuestionsListItem"
import { isQuestion } from "../FormativeQuestionsDialog/helpers"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
type Props = {
  questions: FormativeQuestions
  selectable: boolean
  collapse: boolean
  isOpen: boolean
  editMode?: boolean
  setFieldValue?: (field: string, questions: FormativeQuestions) => void
  checkedQuestions: (string | FormativeQuestion)[]
  onQuestionCheck: (question: FormativeQuestion) => void
  isEditDisabled?: boolean
}

const FormativeQuestionsList = ({
  questions,
  selectable,
  isOpen,
  collapse,
  checkedQuestions,
  onQuestionCheck,
  editMode,
  setFieldValue,
  isEditDisabled
}: Props) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      questions,
      result.source.index,
      result.destination.index
    )

    setFieldValue && setFieldValue("questions", items)
  }

  const onRemove = (questionToRemove: FormativeQuestion) => {
    const newQuestions = questions.filter(
      question => question.id !== questionToRemove.id
    )
    setFieldValue && setFieldValue("questions", newQuestions)
  }

  const renderFormativeQuestionsList = () => {
    return questions.map((question, index) => {
      if (editMode) {
        return (
          <Draggable
            key={question.id}
            draggableId={question.id.toString()}
            index={index}
          >
            {(provided: any, snapshot: any) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <FormativeQuestionsListItem
                  key={question.id}
                  question={question}
                  editMode
                  isEditDisabled={isEditDisabled}
                  index={index}
                  onChecked={onQuestionCheck}
                  marginLeft={collapse}
                  onRemove={onRemove}
                  isChecked={checkedQuestions.some(
                    checked => isQuestion(checked) && checked.id === question.id
                  )}
                />
              </div>
            )}
          </Draggable>
        )
      }
      return (
        <FormativeQuestionsListItem
          key={question.id}
          question={question}
          editMode={!!editMode}
          onChecked={onQuestionCheck}
          marginLeft={collapse}
          index={index}
          isChecked={checkedQuestions.some(
            checked => isQuestion(checked) && checked.id === question.id
          )}
        />
      )
    })
  }
  const renderList = () => {
    if (collapse) {
      return (
        isOpen && (
          <Collapse in={isOpen}>{renderFormativeQuestionsList()}</Collapse>
        )
      )
    }
    if (editMode) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: any, snapshot: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {renderFormativeQuestionsList()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    }
    return renderFormativeQuestionsList()
  }
  return <>{renderList()}</>
}

export default FormativeQuestionsList

const reorder = (
  list: FormativeQuestions,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
