import * as React from "react"
import { useSelector } from "react-redux"
import MUIBreadcrumbs from "@mui/material/Breadcrumbs"
import Link from "redux-first-router-link"
import Typography, { TypographyProps } from "@mui/material/Typography"

import {
  selectBreadcrumbsStructureForRoute,
  selectCurrentRoute
} from "../../modules/router/selectors"
import { ReactComponent as ChevronRight } from "../../assets/images/chevron-right.svg"
import { styled } from "@mui/material"
import HtmlRenderer from "../HtmlRenderer"

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  color: inherit;

  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }
`

const StyledText = styled(Typography)<TypographyProps & { component: string }>`
  color: inherit;
  padding: 0.5rem;
  font-weight: bold;
`

const StyledBreadcrumbs = styled(MUIBreadcrumbs)`
  align-items: center;
  display: flex;
  font-size: 1rem;
  margin-left: 0.25em;
  padding: 0.625rem 0.75rem;
  min-height: 56px;
  color: rgba(0, 0, 0, 0.87);

  ${props => props.theme.breakpoints.up("sm")} {
    margin-left: 1.5rem;
  }

  & > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-separator {
    color: rgba(0, 0, 0, 0.5);
  }
`

const Breadcrumbs = () => {
  const structure = useSelector(selectBreadcrumbsStructureForRoute)
  const currentRoute = useSelector(selectCurrentRoute)

  if (structure.length === 0) {
    return null
  }

  if (currentRoute === structure[0].type) {
    return null
  }

  return (
    <StyledBreadcrumbs separator={<ChevronRight width="14px" height="14px" />}>
      {structure.map(path => {
        if (path.type !== currentRoute) {
          return (
            <StyledLink key={path.type} to={path}>
              {path.title}
            </StyledLink>
          )
        }

        return (
          <StyledText component="span" variant="body1" key={path.type}>
            <HtmlRenderer html={path.title} />
          </StyledText>
        )
      })}
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
