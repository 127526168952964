import * as React from "react"
import { styled } from "@mui/material"
import { ArrowBack, ArrowForward } from "@mui/icons-material/"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

type Props = {
  disableBack: boolean
  disableForward: boolean
  forward: () => void
  back: () => void
  total: number
  current: number
}

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  background: #fff;
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  & > button:first-child {
    margin-right: auto;
  }

  & > button:not(:first-child) {
    margin-left: auto;
  }

  ${props => props.theme.breakpoints.up("md")} {
    position: absolute;
    top: 64px;
    bottom: auto;
    left: auto;
    background: transparent;
    box-shadow: none;
    z-index: 0;
    padding: 0.25rem;
  }
`

const Pagination: React.FC<Props> = ({
  disableBack,
  disableForward,
  forward,
  back,
  total,
  current
}) => {
  return (
    <Wrapper>
      <IconButton
        aria-label="Föregående elev"
        disabled={disableBack}
        onClick={back}
      >
        <ArrowBack />
      </IconButton>
      <Typography>
        {current} / {total}
      </Typography>
      <IconButton
        aria-label="Nästa elev"
        disabled={disableForward}
        onClick={forward}
      >
        <ArrowForward />
      </IconButton>
    </Wrapper>
  )
}

export default Pagination
