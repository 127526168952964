import { takeLatest, put, all, race, select } from "redux-saga/effects"
import { sendRequest } from "../communication/actions"
import * as exercisesProgressActions from "./actions"
import * as communicationActions from "../communication/actions"
import * as exercisesProgressTypes from "./types"
import {
  selectAllExercisesProgress,
  selectTemporaryProgress
} from "./selectors"
import { MODULE_NAME } from "./constants"
import * as membersTypes from "../members/types"
import * as membersActions from "../members/actions"

function* getAllExercisesSaga() {
  yield put(
    sendRequest(
      MODULE_NAME,
      exercisesProgressActions.SERVER_MESSAGE_ACTION
        .GET_ALL_EXERCISES_PROGRESS_REQUEST_REQUEST
    )
  )
}

function* watchGetAllExercisesProgressSaga() {
  yield takeLatest(
    exercisesProgressActions.REQUEST.GET_ALL_EXERCISES_PROGRESS_REQUEST,
    getAllExercisesSaga
  )
}

export function* handleReceiveExercisesProgressResponseSaga(
  message: exercisesProgressTypes.ReceiveExercisesResponseMessagesTypes
) {
  const { type, action, payload, error } = message.payload

  if (type === MODULE_NAME) {
    switch (action) {
      case exercisesProgressActions.SERVER_MESSAGE_ACTION
        .GET_ALL_EXERCISES_PROGRESS_REQUEST_RESPONSE:
        if (error) {
          return
        }

        const { progress, readingState, history } =
          payload as exercisesProgressTypes.ReceiveProgressAction["payload"]

        if (Array.isArray(progress)) {
          yield put(
            exercisesProgressActions.setExerciseProgress({
              progress,
              readingState: readingState || [],
              history: history || []
            })
          )
        }
    }
  }
}

const isScoreBetter = (
  curr: exercisesProgressTypes.ExerciseProgress,
  inc: exercisesProgressTypes.ExerciseProgress
) => {
  // Not better if no incoming score or incoming score is lower
  if (!inc.score || inc.score < curr.bestScore) {
    return false
  }

  // If same score, check extra tries
  if (inc.score === curr.bestScore) {
    if (inc.extraTries === undefined && curr.extraTries !== undefined) {
      return false
    }

    if (curr.extraTries === undefined && inc.extraTries !== undefined) {
      return true
    }

    if (
      inc.extraTries !== undefined &&
      curr.extraTries !== undefined &&
      inc.extraTries < curr.extraTries
    ) {
      return true
    }
    // If same extra tries, check best time
    if (inc.extraTries === curr.extraTries) {
      return inc.time !== undefined && inc.time < curr.bestTime
    }
  }

  // If none of the above, incoming score is better
  return true
}

const hasNeededData = (item: exercisesProgressTypes.ExerciseProgress) =>
  item.score !== undefined &&
  item.time !== undefined &&
  item.extraTries !== undefined

export function* handleReceiveExercisesProgressEventSaga(
  message: exercisesProgressTypes.ReceiveExercisesProgressEventMessagesTypes
) {
  const { type, action, payload } = message.payload
  if (type === MODULE_NAME) {
    switch (action) {
      case exercisesProgressActions.SERVER_MESSAGE_ACTION
        .EXERCISES_PROGRESS_UPDATE_EVENT:
        const payloadProgress =
          payload as exercisesProgressTypes.ReceiveExercisesProgressUpdatedAction["payload"]

        if (payloadProgress.progress) {
          const tempProgress: exercisesProgressTypes.ExerciseProgress[] =
            yield select(selectTemporaryProgress)

          yield put(
            exercisesProgressActions.updateExerciseStates(
              payloadProgress.progress
            )
          )

          // Adding and merging progress into the temporary list
          const progress = payloadProgress.progress.reduce((res, item) => {
            const oldIndex = res.findIndex(
              i =>
                i.exerciseId === item.exerciseId && i.studliId === item.studliId
            )

            if (oldIndex === -1) {
              return [...res, item]
            }

            const old = res[oldIndex]

            return Object.assign([], res, {
              [oldIndex]: {
                ...old,
                ...item
              }
            })
          }, tempProgress)

          // split progress that should remain temps and progress that are ready to merge with the real data
          const [temp, real] = progress.reduce(
            (res, item) => {
              const [currTemp, currReal] = res

              if (hasNeededData(item)) {
                return [currTemp, [...currReal, item]]
              }

              return [[...currTemp, item], currReal]
            },
            [
              [] as exercisesProgressTypes.ExerciseProgress[],
              [] as exercisesProgressTypes.ExerciseProgress[]
            ]
          )

          yield put(exercisesProgressActions.updateTemporaryProgress(temp))

          if (!real.length) {
            return
          }

          const currentProgress: exercisesProgressTypes.ExerciseProgress[] =
            yield select(selectAllExercisesProgress)

          // merge "real" progress
          const mergeProgress = currentProgress.map(item => {
            const inc = real.find(
              i =>
                i.exerciseId === item.exerciseId && i.studliId === item.studliId
            )

            if (!inc) {
              return item
            }

            // if the current score is better do nothing
            if (!isScoreBetter(item, inc)) {
              return {
                ...item,
                latestAt: inc.modifiedAt,
                tries: !item.tries ? 1 : item.tries + 1
              }
            }

            return {
              ...item,
              ...inc,
              bestTime: inc.time as number,
              bestScore: inc.score as number,
              latestAt: inc.modifiedAt,
              tries: !item.tries ? 1 : item.tries + 1
            }
          })

          yield put(
            exercisesProgressActions.updateExerciseProgress([
              ...mergeProgress,
              ...real
                .filter(
                  i =>
                    !mergeProgress.find(
                      item =>
                        item.exerciseId === i.exerciseId &&
                        item.studliId === i.studliId
                    )
                )
                .map(i => ({
                  ...i,
                  bestTime: i.time as number,
                  bestScore: i.score as number,
                  latestAt: i.modifiedAt,
                  tries: !i.tries ? 1 : i.tries + 1
                }))
            ])
          )
        }

        if (payloadProgress.readingState) {
          yield put(
            exercisesProgressActions.updateReadingState(
              payloadProgress.readingState
            )
          )
        }
        break
    }
  }
}

export function* handleRemovedMemberSaga(
  message: membersTypes.MemberRemovedEventAction
) {
  if (message.payload.length > 0) {
    let currentExercisesProgress: exercisesProgressTypes.ExerciseProgress[] =
      yield select(selectAllExercisesProgress)
    currentExercisesProgress = currentExercisesProgress.filter(
      progress =>
        message.payload.findIndex(member => member === progress.studliId) === -1
    )
    yield put(
      exercisesProgressActions.updateExerciseProgress(currentExercisesProgress)
    )
  }
}

function* watchRemovedMemberSaga() {
  yield takeLatest(
    membersActions.EVENT.MEMBER_REMOVED_EVENT,
    handleRemovedMemberSaga
  )
}

function* watchReceiverSaga() {
  yield race([
    yield takeLatest(
      communicationActions.RECEIVE_RESPONSE,
      handleReceiveExercisesProgressResponseSaga
    ),
    yield takeLatest(
      communicationActions.RECEIVE_EVENT,
      handleReceiveExercisesProgressEventSaga
    )
  ])
}

export function* rootSaga() {
  yield all([
    watchGetAllExercisesProgressSaga(),
    watchReceiverSaga(),
    watchRemovedMemberSaga()
  ])
}
