import React from "react"
import MUIDialog, { DialogProps } from "@mui/material/Dialog"
import { styled, Theme, useMediaQuery } from "@mui/material"

type OwnProps = {
  open: DialogProps["open"]
  fullScreen?: boolean
  onClose?: DialogProps["onClose"]
  children?: React.ReactNode
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined
  fullWidth?: boolean
}

type Props = OwnProps & DialogProps

type CustomDialogProps = {
  full_screen: number
}

const StyledDialog = styled(
  MUIDialog as React.FC<DialogProps & CustomDialogProps>
)`
  ${props => props.full_screen && `width: 100vw`}
`

export const Dialog = ({
  open,
  children,
  fullScreen,
  onClose,
  fullWidth,
  maxWidth,
  ...rest
}: Props) => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"))
  return (
    <StyledDialog
      aria-label="pop-up-dialog"
      full_screen={fullScreen ? 1 : 0}
      fullWidth={fullWidth ? fullWidth : false}
      maxWidth={maxWidth ? maxWidth : false}
      open={open}
      fullScreen={isMobile ? true : fullScreen}
      onClose={onClose}
      {...rest}
    >
      {children}
    </StyledDialog>
  )
}

export default Dialog
