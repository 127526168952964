import { styled } from "@mui/material"

type StatusTagProps = {
  status: "done" | "inProgress" | "notStarted" | "approved"
}
export const StyledStatusTag = styled("div")`
  color: ${(props: StatusTagProps) => getStatusColor(props.status)};
  background-color: ${(props: StatusTagProps) =>
    getStatusBackgroundColor(props.status)};
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding: 2px 4px 2px 4px;
  display: inline-block;
  margin-left: 0.3rem;
`
const getStatusBackgroundColor = (status: string) => {
  switch (status) {
    case "done":
      return "#006cc8"
    case "inProgress":
      return "#42b9fe"
    case "approved":
      return "#04B887"
    case "notStarted":
      return "#ecf4fb"
  }
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "done":
      return "white"
    case "inProgress":
      return "white"
    case "notStarted":
      return "#006cc8"
    case "approved":
      return "white"
  }
}
