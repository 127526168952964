import Typography, { TypographyProps } from "@mui/material/Typography"
import Link, { LinkProps } from "@mui/material/Link"
import Card from "@mui/material/Card"
import CardMedia, { CardMediaProps } from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import RLink from "redux-first-router-link"
import { styled } from "@mui/material"

type CustomCardProps = {
  flexwidth: string
}

export const StyledNavigationButton = styled(RLink)`
  text-decoration: none;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: #3f51b5;
  padding: 6px 8px;

  &:hover {
    background-color: rgba(63, 81, 181, 0.04);
  }
`
export const StyledCardMedium = styled(Card as React.FC<CustomCardProps>)`
  flex: ${props => getFlex(props.flexwidth)};
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const StyledCardMedia = styled(CardMedia as React.FC<CardMediaProps>)`
  width: 100%;
  height: 18em;
  position: relative;
  background-repeat: repeat;
  background-size: auto;
  & > svg {
    position: absolute;
    height: 100%;
    width: 100%;
    &:last-child {
      position: absolute;
      top: 50%;
      bottom: 50%;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      height: 80%;
      width: 80%;
    }
  }
`

const getFlex = (fullWidth: string) => {
  return fullWidth === "true" ? "0 0 96%;" : "1 1 23em;"
}

export const StyledCardContent = styled(
  CardContent as React.FC<{ $addpadding: boolean }>
)`
  flex-grow: 1;
  &:last-child {
    padding: ${props => (props.$addpadding ? "16px" : "0")};
  }
`

export const StyledCardTitle = styled(
  Typography as React.FC<TypographyProps & { component: string }>
)``

export const StyledLink = styled(Link as React.FC<LinkProps>)`
  text-decoration: none;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: #3f51b5;
  padding: 6px 8px;

  &:hover {
    background-color: rgba(63, 81, 181, 0.04);
  }
`
