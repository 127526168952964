import React from "react"
import { connect, useSelector } from "react-redux"

import * as routerActions from "../../modules/router/actions"
import NavigationListItem from "../NavigationListItem"

import { ReactComponent as ExercisesIcon } from "../../assets/images/menu-exercise.svg"
import { ReactComponent as AssignmentsIcon } from "../../assets/images/menu-task.svg"
import { ReactComponent as StudentsIcon } from "../../assets/images/menu-student.svg"
import { ReactComponent as HomeIcon } from "../../assets/images/menu-home.svg"
import { ReactComponent as TargetIcon } from "../../assets/images/menu-goal.svg"
import { ReactComponent as TestIcon } from "../../assets/images/test-icon.svg"
import { ReactComponent as TeachersIcon } from "../../assets/images/menu-teacher.svg"

import * as routeSelectors from "../../modules/router/selectors"
import { RootState } from "../../modules/store"
import { Product } from "../../modules/products/types"
import { StyledList } from "./StyledNavigationLinks"
import { Features } from "../../modules/classroom/types"
import { doesCurrentUserHasATeacherGuide } from "../../modules/users/selectors"
import { isDillProduct } from "../../shared/tools/is_dill_product"
import {
  selectStudentsLength,
  selectTeachers
} from "../../modules/members/selectors"

type StateProps = {
  currentRoute: string
  classroomId: number
  hasTeacherGuide: boolean
}

type OwnProps = {
  productInfo: Product
  classroomFeatures: Features
  isMobile: boolean
  onCloseDrawer: () => void
}

type Props = StateProps & OwnProps

/* Start

Uppdrag

Övningar

Mål

Elever

Lärare

Aktiverade läromedel

Hjälp */

export const NavigationLinks = ({
  currentRoute,
  productInfo,
  classroomId,
  classroomFeatures,
  hasTeacherGuide,
  isMobile,
  onCloseDrawer
}: Props) => {
  const numberOfStudents = useSelector(selectStudentsLength)
  const numberOfTeachers = useSelector(selectTeachers).length
  const onNavItemClick = () => {
    if (isMobile) {
      onCloseDrawer()
    }
  }

  const showFormativeTests =
    classroomFeatures.some(feature => feature === "FORMATIVE_TESTS") &&
    hasTeacherGuide
  return (
    <StyledList sx={{ marginLeft: 1 }}>
      <NavigationListItem
        onClick={onNavItemClick}
        to={{ type: routerActions.ROUTE_START }}
        text="Start"
        icon={<HomeIcon aria-label="Start" />}
        selected={currentRoute === routerActions.ROUTE_START}
      />
      <NavigationListItem
        onClick={onNavItemClick}
        to={{ type: routerActions.ROUTE_UPPDRAG }}
        text="Uppdrag"
        icon={<AssignmentsIcon aria-label="Uppdrag" />}
        selected={currentRoute === routerActions.ROUTE_UPPDRAG}
      />
      {showFormativeTests && (
        <NavigationListItem
          onClick={onNavItemClick}
          to={{
            type: routerActions.ROUTE_FORMATIVA_FRAGOR,
            payload: { classroomId }
          }}
          text="Formativa frågor"
          icon={<TestIcon aria-label="Formativa frågor" />}
          selected={currentRoute === routerActions.ROUTE_FORMATIVA_FRAGOR}
        />
      )}
      {productInfo.type && isDillProduct(productInfo.type) && (
        <NavigationListItem
          onClick={onNavItemClick}
          to={{ type: routerActions.ROUTE_OVNINGAR }}
          text="Övningar"
          icon={<ExercisesIcon aria-label="Övningar" />}
          selected={currentRoute === routerActions.ROUTE_OVNINGAR}
        />
      )}
      {productInfo.type && productInfo.type === "adapt" && (
        <NavigationListItem
          onClick={onNavItemClick}
          to={{ type: routerActions.ROUTE_MAL }}
          text="Läromedlets mål"
          icon={<TargetIcon aria-label="Mål" />}
          selected={currentRoute === routerActions.ROUTE_MAL}
        />
      )}
      <NavigationListItem
        onClick={onNavItemClick}
        to={{ type: routerActions.ROUTE_ELEVER }}
        text={`Elever (${numberOfStudents})`}
        icon={<StudentsIcon aria-label="Elever" />}
        selected={currentRoute === routerActions.ROUTE_ELEVER}
      />
      <NavigationListItem
        onClick={onNavItemClick}
        to={{ type: routerActions.ROUTE_LARARE }}
        text={`Lärare (${numberOfTeachers})`}
        icon={<TeachersIcon aria-label="Lärare" />}
        selected={currentRoute === routerActions.ROUTE_LARARE}
      />
    </StyledList>
  )
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    currentRoute: routeSelectors.selectCurrentRoute(state),
    classroomId: routeSelectors.selectCurrentClassroomId(state),
    hasTeacherGuide: doesCurrentUserHasATeacherGuide(state)
  }
}

export const ConnectedNavigationLinks =
  connect(mapStateToProps)(NavigationLinks)

export default ConnectedNavigationLinks
