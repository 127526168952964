import React from "react"
import {
  StyledAdaptProgressBar,
  StyledProgressFiller
} from "./StyledAdaptAssignmentProgressBar"
type Props = {
  progress: number
  threshold: number
}

const AdaptAssignmentProgressBar = ({ progress, threshold }: Props) => {
  return (
    <StyledAdaptProgressBar>
      <StyledProgressFiller progress={progress} threshold={threshold}>
        {progress > 0 && progress}
      </StyledProgressFiller>
    </StyledAdaptProgressBar>
  )
}

export default AdaptAssignmentProgressBar
