import React, { useState, useEffect } from "react"
import { DialogTitle } from "@mui/material"
import StyledButton from "../Button"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import CustomTreeView from "../TreeView"
import ExerciseList from "../TreeViewList"
import {
  ExerciseId,
  ParentOrExerciseNode,
  Exercise,
  ExerciseNode
} from "../../modules/exercises/types"
import { isParent } from "../../pages/Exercises/common/helpers"
import { Members } from "../../modules/members/types"
import {
  StyledChooseExercisesDialogContent,
  StyledDialogActions,
  StyledSubHeader
} from "./StyledChooseExercisesDialog"

type OwnProps = {
  exercises: Exercise
  members: Members
  okDialogHandler: Function
  cancelDialogHandler: () => void
}

const ChooseExercisesDialog = ({
  exercises,
  members,
  okDialogHandler,
  cancelDialogHandler
}: OwnProps) => {
  const [expandedNodes, setExpandedNodes] = useState<ExerciseId[]>([])
  const [checkedExercises, setCheckedExercises] = useState<ExerciseId[]>([])
  const [currentMobileNodeChosen, setCurrentMobileNodeChosen] = useState<any>(
    []
  )
  const [currentMobileBreadcrumbs, setCurrentMobileBreadcrumbs] = useState<any>(
    []
  )

  useEffect(() => {
    if (!currentMobileNodeChosen.length && exercises.length) {
      setCurrentMobileNodeChosen(exercises)
    }
    // eslint-disable-next-line
  }, [exercises])

  const onCheckboxClicked = (event: Event, node: ParentOrExerciseNode) => {
    event.stopPropagation()
    const nodeIsAlreadyChecked = checkedExercises.some(
      exerciseId => node.uniqueId === exerciseId
    )
    const nodeIds = traverseForIdsBelowNode(node)

    if (nodeIsAlreadyChecked) {
      setCheckedExercises([
        ...checkedExercises.filter(id => nodeIds.indexOf(id) === -1)
      ])

      return
    }

    setCheckedExercises([
      ...checkedExercises,
      ...nodeIds.filter(nodeId => checkedExercises.indexOf(nodeId) === -1)
    ])
  }

  const traverseForIdsBelowNode = (node: ParentOrExerciseNode) => {
    const nodeIds: ExerciseId[] = []
    traverse(node, nodeIds)

    return nodeIds
  }

  const onItemClick = (node: ExerciseNode) => {
    okDialogHandler(node)
  }

  const traverse = (node: ParentOrExerciseNode, nodeIds: ExerciseId[]) => {
    nodeIds.push(node.uniqueId)
    if (isParent(node) && node.children) {
      node.children.forEach((child: ParentOrExerciseNode) => {
        traverse(child, nodeIds)
      })
    }
  }

  const onMobileRowClick = (node: ParentOrExerciseNode) => {
    if (isParent(node)) {
      const newBreadcrumbs = [...currentMobileBreadcrumbs, node]

      setCurrentMobileNodeChosen(node.children)
      setCurrentMobileBreadcrumbs(newBreadcrumbs)
    }
  }

  const onBreadcrumbClick = (node: any) => {
    if (currentMobileBreadcrumbs.length === 1) {
      setCurrentMobileNodeChosen(exercises)
      setCurrentMobileBreadcrumbs([])

      return
    }
    const newBreadcrumbs = [...currentMobileBreadcrumbs]
    newBreadcrumbs.splice(currentMobileBreadcrumbs.length - 1)
    setCurrentMobileNodeChosen(node.children)
    setCurrentMobileBreadcrumbs(newBreadcrumbs)
  }

  return (
    <>
      <DialogTitle id="välj-övningar">Välj Innehåll</DialogTitle>
      <StyledSubHeader variant="subtitle1">
        Öppna mapparna för att se innehållet i läromedlet. Markera en eller
        flera delar för att lägga till dem till uppdraget.
      </StyledSubHeader>
      <StyledChooseExercisesDialogContent>
        <MobileOrDesktop>
          {{
            desktop: (
              <CustomTreeView
                type={"exercises"}
                nodes={exercises}
                expandedNodes={expandedNodes}
                setExpandedNodes={setExpandedNodes}
                topNodesNotExpanded={[]}
                setTopNodesNotExpanded={() => null}
                checkedNodes={checkedExercises}
                onCheckboxClicked={onCheckboxClicked}
                members={members}
                exercisesProgress={[]}
                onProgressDialogOpen={() => null}
                hideStatus={true}
                onItemClick={onItemClick}
                selectable
              />
            ),
            mobile: (
              <ExerciseList
                nodes={currentMobileNodeChosen}
                onMobileRowClick={onMobileRowClick}
                checkedNodes={checkedExercises}
                onCheckboxClicked={onCheckboxClicked}
                currentMobileBreadcrumbs={currentMobileBreadcrumbs}
                onBreadcrumbClick={onBreadcrumbClick}
                members={members}
                exercisesProgress={[]}
                onProgressDialogOpen={() => null}
                onItemClick={onItemClick}
                type="exercises"
                hideStatus={true}
                selectable
              />
            )
          }}
        </MobileOrDesktop>
      </StyledChooseExercisesDialogContent>
      <StyledDialogActions>
        <StyledButton variant="outlined" onClick={() => cancelDialogHandler()}>
          Avbryt
        </StyledButton>
        <StyledButton
          variant="outlined"
          color="primary"
          onClick={() => okDialogHandler(checkedExercises)}
          disabled={checkedExercises.length < 1}
        >
          Välj
        </StyledButton>
      </StyledDialogActions>
    </>
  )
}

export default ChooseExercisesDialog
