import * as React from "react"

import MobileOrDesktop from "../../containers/MobileOrDesktop"
import CustomTreeView from "../../components/TreeView"
import ExerciseList from "../../components/TreeViewList"
import useStudentExerciseList from "./useStudentExerciseList"
import { StyledNoInfo } from "../StudentHistoryList/StyledHistoryList"

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = () => {}

const StudentExerciseList = () => {
  const {
    exercises,
    treeStatus,
    progress,
    members,
    setExpandedNodes,
    setTopNodesNotExpanded,
    onMobileRowClick,
    onBreadcrumbClick,
    member,
    activeProduct
  } = useStudentExerciseList()
  return (
    <>
      {progress.length > 0 ? (
        <MobileOrDesktop>
          {{
            desktop: (
              <CustomTreeView
                nodes={exercises}
                expandedNodes={treeStatus.expandedNodes}
                setExpandedNodes={setExpandedNodes}
                topNodesNotExpanded={treeStatus.topNodesNotExpanded}
                setTopNodesNotExpanded={setTopNodesNotExpanded}
                checkedNodes={treeStatus.checkedExercises}
                onCheckboxClicked={NOOP}
                members={members}
                exercisesProgress={progress}
                hideStatus={false}
                type="studentCard"
              />
            ),
            mobile: (
              <ExerciseList
                nodes={treeStatus.currentMobileNodeChosen}
                onMobileRowClick={onMobileRowClick}
                checkedNodes={treeStatus.checkedExercises}
                onCheckboxClicked={NOOP}
                currentMobileBreadcrumbs={treeStatus.currentMobileBreadcrumbs}
                onBreadcrumbClick={onBreadcrumbClick}
                members={members}
                exercisesProgress={progress}
                onProgressDialogOpen={NOOP}
                type="studentCard"
                hideStatus={false}
                onlyOneMember
              />
            )
          }}
        </MobileOrDesktop>
      ) : (
        <StyledNoInfo>
          {member.firstName} har ännu inte gjort några övningar i{" "}
          {activeProduct}
        </StyledNoInfo>
      )}
    </>
  )
}

export default StudentExerciseList
