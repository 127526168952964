import {
  Theme,
  useMediaQuery,
  Link as MUILink,
  Stack,
  Divider,
  useTheme,
  styled,
  Typography,
  TypographyProps
} from "@mui/material"
import {
  Drawer,
  Toolbar,
  Content,
  Footer,
  DrawerHeader,
  FooterBlock,
  FooterBlockSection,
  FooterBlockTitle,
  FooterBlockText
} from "@studli/studli-shared-components"
import { useState } from "react"
import React from "react"
import TopBar from "../../containers/TopBar"
import { selectActiveProductTitle } from "../../modules/products/selectors"
import { useDispatch, useSelector } from "react-redux"
import Logo from "../Logo"
import SideBar from "../../containers/SideBar"
import * as routerActions from "../../modules/router/actions"
import { selectClassroomId } from "../../modules/classroom/selectors"

type Props = {
  Component: React.ReactElement
}

export const StyledTitle = styled(Typography as React.FC<TypographyProps>)`
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
`

const DRAWERWIDTH = 300

const PageContainer = ({ Component }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const theme = useTheme()
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("lg"))
  const isSmallMobile = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down("sm")
  )

  const classroomId = useSelector(selectClassroomId)

  const dispatch = useDispatch()

  const title = useSelector(selectActiveProductTitle)
  const onCloseDrawer = () => setIsDrawerOpen(false)
  const onOpenDrawer = () => setIsDrawerOpen(true)

  const goToStartPage = () => {
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_START, { classroomId })
    )
  }

  const showCookieConsent = () => {
    window.CookieConsent?.renew?.()
  }

  const footerLinks = [
    <MUILink
      key="contact"
      href={`${
        process.env.REACT_APP_BOOKSHELF_URL
      }/kontakta-oss?url=${encodeURIComponent(window.location.href)}`}
      gutterBottom
      color="#ffffff"
    >
      Kontakta oss
    </MUILink>,
    <MUILink
      key="report"
      href={`${
        process.env.REACT_APP_BOOKSHELF_URL
      }/kontakta-oss?url=${encodeURIComponent(window.location.href)}`}
      gutterBottom
      color="#ffffff"
    >
      Rapportera fel
    </MUILink>,
    <MUILink
      href="https://www.studentlitteratur.se/om-oss/kontakta-oss/kundservice/gdpr-och-personuppgifter/"
      target="_blank"
      rel="noopener noreferrer"
      key="gdpr"
      gutterBottom
      color="#ffffff"
    >
      GDPR och personuppgifter
    </MUILink>,

    <MUILink
      key="cookies"
      component="button"
      onClick={showCookieConsent}
      variant="body1"
      textAlign="left"
      gutterBottom
      color="#ffffff"
    >
      Cookies
    </MUILink>
  ]
  return (
    <>
      <Toolbar
        open={isDrawerOpen}
        handleDrawerClose={onCloseDrawer}
        handleDrawerOpen={onOpenDrawer}
        toolbarColor={theme.studli.classroom.themeColor}
        logo={
          <MUILink sx={{ cursor: "pointer" }} onClick={goToStartPage}>
            <Logo />
          </MUILink>
        }
        title={isSmallMobile ? "" : title}
        sx={{ color: theme.studli.classroom.themeTextColor }}
      >
        <TopBar />
      </Toolbar>
      <Drawer
        drawerVariant={isMobile ? "temporary" : "persistent"}
        drawerWidth={DRAWERWIDTH}
        mobile={isMobile}
        onClose={onCloseDrawer}
        open={isDrawerOpen}
      >
        <>
          {isMobile && (
            <>
              <DrawerHeader callback={onCloseDrawer} />
              <Divider />
            </>
          )}
          <SideBar isMobile={isMobile} onCloseDrawer={onCloseDrawer} />
        </>
      </Drawer>
      <Stack justifyContent="space-between" height="100%">
        <Content
          drawerWidth={DRAWERWIDTH}
          mobile={isMobile}
          open={isDrawerOpen}
        >
          {Component}
        </Content>

        <Footer
          open={isDrawerOpen}
          mobile={isMobile}
          drawerWidth={DRAWERWIDTH}
          logo={
            <MUILink sx={{ cursor: "pointer" }} onClick={goToStartPage}>
              <Logo footerLogo />
            </MUILink>
          }
        >
          <FooterBlockSection
            sx={{
              marginRight: "5rem"
            }}
          >
            <FooterBlock>
              <FooterBlockTitle>Adress</FooterBlockTitle>
              <FooterBlockText>Box 141</FooterBlockText>
              <FooterBlockText>221 00 Lund</FooterBlockText>
            </FooterBlock>
            <FooterBlock>
              <FooterBlockTitle>Besöksadress</FooterBlockTitle>
              <FooterBlockText>Åkergränden 1</FooterBlockText>
              <FooterBlockText>Lund</FooterBlockText>
            </FooterBlock>
          </FooterBlockSection>
          <FooterBlockSection sx={{ marginRight: "10rem" }}>
            <FooterBlock>
              <FooterBlockTitle>Telefon</FooterBlockTitle>
              <MUILink color="#ffffff" href="tel:046312000" rel="noopener">
                046-31 20 00
              </MUILink>
            </FooterBlock>
            <FooterBlock>
              <FooterBlockTitle>E-post</FooterBlockTitle>
              <MUILink
                color="#ffffff"
                rel="noopener"
                href="mailto:info@studentlitteratur.se"
              >
                info@studentlitteratur.se
              </MUILink>
            </FooterBlock>
          </FooterBlockSection>
          <FooterBlockSection>
            <FooterBlock>
              <FooterBlockTitle>Behöver du hjälp?</FooterBlockTitle>
              {footerLinks.map(l => l)}
            </FooterBlock>
          </FooterBlockSection>
        </Footer>
      </Stack>
    </>
  )
}

export default PageContainer
