import { Box, BoxProps, useTheme } from "@mui/material";
import { getCenteredMargins, getCenteredWidth } from "./getSxMargins";
import useBreakpoints from "./useBreakpoints";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const CenteredContainer = ({ children, ...rest }: Props & BoxProps) => {
  const breakpoints = useBreakpoints();
  const theme = useTheme();
  return (
    <Box
      {...rest}
      sx={{
        margin: getCenteredMargins(theme, false, breakpoints),
        maxWidth: getCenteredWidth(breakpoints),
      }}
    >
      {children}
    </Box>
  );
};
export default CenteredContainer;