import { useSelector, useDispatch } from "react-redux"
import moment from "moment"

import { selectAllPreparedAssignments } from "../../modules/assignments/selectors"
import {
  AssignmentTask,
  PartialAssignment,
  PartialAssignmentWithDepth,
  PreparedAssignment
} from "../../modules/assignments/types"
import { setImportedAssignment } from "../../modules/assignments/actions"
import {
  gotoRoute,
  ROUTE_UPPDRAG_LAGGTILL_NYTT
} from "../../modules/router/actions"
import { selectClassroomId } from "../../modules/classroom/selectors"
import { selectExercisePaths } from "../../modules/exercises/selectors"
import { selectBooksToc } from "../../modules/book/selectors"
import { buildPathToPageFromPageNr } from "../../pages/CreateEditAssignment/common/helpers"
import { ChapterOrPage } from "../../modules/book/types"

export interface Chapter {
  id: string
  title: string
  count: number
  depth: number
  assignments?: PartialAssignmentWithDepth[]
  subChapters?: Chapter[]
}

function useImportAssignments(): [
  Chapter[],
  (assignment: PartialAssignment) => void
] {
  const importedAssignments = useSelector(selectAllPreparedAssignments)
  const classroomId = useSelector(selectClassroomId)
  const exercisePaths = useSelector(selectExercisePaths)
  const booksToc = useSelector(selectBooksToc)
  const dispatch = useDispatch()

  const findPathsForImportedAssignments = (
    assignmentTask: Partial<AssignmentTask>
  ) => {
    if (assignmentTask.type === "exercise") {
      const exercise = exercisePaths.filter(
        exe => exe.exerciseId === assignmentTask.link
      )
      if (exercise.length > 0) {
        if (exercise.length === 1) {
          return {
            ...assignmentTask,
            exercisePath: exercise[0].path.join(" / "),
            icon: exercise[0].exercise.type
          }
        }
        // if exerciseId is found on multiple places you can't decide which path is correct.
        // For now use the first instance but this needs to be resolved somehow. (210125)
        return {
          ...assignmentTask,
          exercisePath: exercise[0].path.join(" / "),
          icon: exercise[0].exercise.type
        }
      }
      return assignmentTask
    }
    if (assignmentTask.type === "book") {
      let currentBook = assignmentTask.link && assignmentTask.link.split("#")[0]
      if (assignmentTask.link && currentBook === assignmentTask.link) {
        currentBook = assignmentTask.link.split("?")[0]
      }
      const currentPage =
        assignmentTask.link && assignmentTask.link.split("=")[1]
      const bookToc = booksToc.filter(book => book.bookId === currentBook)

      if (bookToc && currentPage !== undefined) {
        const path = buildPathToPageFromPageNr(bookToc[0], Number(currentPage))
        if (path.length) {
          path.splice(path.length - 1, 1)
          const pagePath = path
            .map((page: ChapterOrPage) => page.title)
            .join(" / ")
          return { ...assignmentTask, exercisePath: pagePath }
        }
        return assignmentTask
      }
      return assignmentTask
    }

    return assignmentTask
  }

  const importAssignment = (assignment: PartialAssignment) => {
    dispatch(
      setImportedAssignment({
        stickerId: assignment.stickerId,
        title: assignment.title,
        instruction: assignment.instruction,
        startDate: moment(),
        threshold: assignment.threshold ? assignment.threshold : 20,
        repetitionAmount: assignment.repetitionAmount
          ? assignment.repetitionAmount
          : 20,
        assignmentTasks: (assignment.assignmentTasks || []).map(task => {
          const taskWithPaths = findPathsForImportedAssignments(task)
          return {
            ...taskWithPaths,
            id: (Math.floor(Math.random() * 1000000000) + 1).toString()
          }
        }) as Partial<AssignmentTask>[]
      })
    )

    dispatch(
      gotoRoute(ROUTE_UPPDRAG_LAGGTILL_NYTT, {
        classroomId
      })
    )
  }

  const insertDataIntoChapter = (
    chapters: Chapter[],
    path: string[],
    data: PreparedAssignment,
    depth: number,
    parentID?: string
  ) => {
    let folderName = path.shift()
    if (!folderName) {
      return chapters
    }
    let subChapter = chapters.find(
      subChapter => subChapter.title === folderName
    )
    if (!subChapter) {
      subChapter = {
        id: (parentID ? parentID + ":" : "") + chapters.length,
        depth: depth,
        title: folderName
      } as Chapter
      chapters.push(subChapter)
    }
    if (path.length) {
      subChapter.subChapters = insertDataIntoChapter(
        subChapter.subChapters || [],
        path,
        data,
        depth + 1,
        subChapter.id
      )
    } else {
      if (!subChapter.assignments) {
        subChapter.assignments = []
      }
      subChapter.assignments.push({
        id: data.id,
        depth: depth + 1,
        ...JSON.parse(data.content)
      })
    }
    return chapters
  }

  const countAssignmentsInChapters = (chapters: Chapter[]) => {
    let sum = 0
    for (let chapter of chapters) {
      let count =
        countAssignmentsInChapters(chapter.subChapters || []) +
        (chapter.assignments || []).length
      chapter.count = count
      sum = sum + count
    }
    return sum
  }

  const buildChaptersTree = (data: PreparedAssignment[]) => {
    let chapters: Chapter[] = []
    for (let d of data) {
      let path = d.folder.split("$")
      chapters = insertDataIntoChapter(chapters, path, d, 0)
    }

    countAssignmentsInChapters(chapters)
    return chapters
  }

  let chapters = buildChaptersTree(importedAssignments)

  return [chapters, importAssignment]
}

export default useImportAssignments
