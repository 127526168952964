import { Breakpoint } from "@mui/material"

export type ViewportWidth = Breakpoint

export type State = {
  isSideBarOpen: boolean
  viewportWidth?: ViewportWidth
}

export type ToggleSideBarAction = {
  type: string
}
export type SetViewportWidthAction = {
  type: string
  payload: ViewportWidth
}

export type StyleProps = {
  is_side_bar_open?: number
}

export enum TransitionAttribute {
  width = "width",
  margin = "margin"
}

export type AppShellActionTypes = ToggleSideBarAction | SetViewportWidthAction
