import { DialogContent, DialogActions, Typography, styled } from "@mui/material"

export const StyledChooseExercisesDialogContent = styled(DialogContent as any)`
  min-width: 100%;
`
export const StyledDialogActions = styled(DialogActions as any)`
  margin-top: 1em;
`
export const StyledSubHeader = styled(Typography)`
  padding: 0 1.5em 0 1.5em;
`
