import { styled } from "@mui/material"

type StyledProgressBarTypes = {
  width: number
  type: AnswerTypes
}

type AnswerTypes = "rating" | "correct" | "wrong" | "notAnswered"
type StyledProgressTypes = {
  clickable?: boolean
  current: boolean
}
export const StyledProgress = styled("span")<StyledProgressTypes>`
  width: 200px;
  height: 25px;
  display: flex;
  overflow: hidden;
  margin-bottom: 2px;
  ${props => !props.current && "opacity: 0.6;"}
  cursor: ${props => (props.clickable ? "pointer" : "default")};
`

export const StyledProgressContainer = styled("div")`
  color: gray;
  display: flex;
  justify-content: flex-end;
`

export const StyledLastActiveContainer = styled("div")`
  margin-right: 5px;
`

export const StyledProgressBar = styled("div")<StyledProgressBarTypes>`
  width: ${props => props.width}%;
  background-color: ${props => getBackgroundColor(props.type)};
  color: ${props => getTextColor(props.type)};
  ${props =>
    props.type === "wrong" &&
    props.width > 0 &&
    "border: 2.5px dotted #e84d3c;"}
  height: 23px;
  text-align: center;
  ${props => props.type !== "wrong" && "line-height:23px"}
  font-size: 13px;
  font-weight: bold;
`

const getBackgroundColor = (type: AnswerTypes) => {
  switch (type) {
    case "rating":
      return "#006CC8"
    case "correct":
      return "#038763"
    case "wrong":
      return "#fef6f5"
    case "notAnswered":
      return "#eeeeee"
  }
}

const getTextColor = (type: AnswerTypes) => {
  switch (type) {
    case "rating":
      return "white"
    case "correct":
      return "white"
    case "wrong":
      return "#e84d3c"
    case "notAnswered":
      return "black"
  }
}
