import { ListItem, styled } from "@mui/material"
import { ReactComponent as AssignmentIcon } from "../../assets/images/menu-task.svg"

export const StyledPrimaryTextContainer = styled("div")`
  display: flex;
  justify-content: flex-start;

  ${props => props.theme.breakpoints.up("sm")} {
    width: 35%;
    align-items: center;
  }
`

export const StyledAssignmentIcon = styled(AssignmentIcon)`
  color: #057dda;
`

export const StyledPrimaryText = styled("div")`
  ${props => props.theme.breakpoints.up("sm")} {
    margin-right: 10px;
  }
`

export const StyledListItemContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin: 0.25rem 0;
  }

  ${props => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      margin: inherit;
    }
  }
`

export const StyledStatusWrapper = styled("div")`
  width: 30%;
  display: flex;
  justify-content: flex-start;
  ${props => props.theme.breakpoints.down("xs")} {
    width: auto;
  }
`

export const StyledListItem = styled(ListItem)`
  &:last-child {
    border-bottom: none;
  }
`
