import * as React from "react"
import StyledList from "../StyledList"
import { ListItemIcon, ListItemText } from "@mui/material"
import {
  StyledAssignmentSticker,
  StyledListItem,
  StyledHistoryListWrapper,
  StyledPaginationWrapper,
  StyledAssignmentListItem,
  StyledResultWrapper,
  StyledListItemContent,
  StyledNoInfo,
  StyledTestIcon,
  StyledGoalIcon
} from "./StyledHistoryList"
import useHistoryListForStudent, {
  ExerciseStateWithExerciseData,
  GoalProgressWithGoal,
  StudentHistoryItem,
  AssignmentProgressWithAssignment
} from "./useHistoryListForStudent"
import ExerciseResultWithHistory from "../ExerciseResultWithHistory"
import Button from "../Button"
import { showTimeAgo } from "../../shared/tools/misc"
import moment from "moment"
import uuid from "uuid"
import { StyledAssignmentIcon } from "../AssignmentsListItem/StyledAssignmentsListItem"
import ExerciseTypeIcon from "../ExerciseTypeIcon"
import HtmlRenderer from "../HtmlRenderer"
import { FormativeTest } from "../../modules/formative/types"

const StudentHistoryList = () => {
  const {
    studentHistoryList: { historyItems, totalNumberOfItems },
    stickers,
    goToStudentAssignment,
    goToFormativeTest,
    goToGoal,
    incrementNumberOfItemsToShow,
    member,
    activeProduct
  } = useHistoryListForStudent()

  const renderAssignmentHistoryItem = (entry: StudentHistoryItem) => {
    const assignmentProgress = entry.content as AssignmentProgressWithAssignment
    const data = assignmentProgress.assignmentData
    const currentSticker =
      data && data.stickerId
        ? stickers.filter(sticker => sticker.id === data.stickerId)
        : null

    return (
      <React.Fragment>
        <ListItemIcon>
          {currentSticker !== null ? (
            <StyledAssignmentSticker
              alt={currentSticker[0].name}
              src={currentSticker[0].path}
            />
          ) : (
            <StyledAssignmentIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <b>{data && data.title}</b> {getAssignmentText(entry.status)}
            </>
          }
          secondary={`${showTimeAgo(entry.sortTime)} sedan`}
        />
      </React.Fragment>
    )
  }

  const renderFormativeHistoryItem = (entry: StudentHistoryItem) => {
    const data = entry.content as FormativeTest

    return (
      <React.Fragment>
        <ListItemIcon>
          <StyledTestIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <b>{data && data.title}</b> {getAssignmentText(entry.status)}
            </>
          }
          secondary={`${showTimeAgo(entry.sortTime)} sedan`}
        />
      </React.Fragment>
    )
  }

  const renderGoalsHistoryItem = (data: GoalProgressWithGoal) => {
    return (
      <React.Fragment>
        <ListItemIcon>
          <StyledGoalIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <b>
                {data.order}. {data.title}
              </b>{" "}
              är klart
            </>
          }
          secondary={`${showTimeAgo(data.lastActive)} sedan`}
        />
      </React.Fragment>
    )
  }

  const getAssignmentText = (status: string | undefined) => {
    if (!status) {
      return ""
    }

    switch (status) {
      case "submitted":
        return "lämnades in"
      case "rejected":
        return "skickades tillbaka"
      case "approved":
        return "markerades som klar"
      default:
        return ""
    }
  }

  const renderMoreButton = () =>
    historyItems.length < totalNumberOfItems && (
      <StyledPaginationWrapper>
        <Button
          style={{ width: "12rem" }}
          variant="outlined"
          color="primary"
          onClick={incrementNumberOfItemsToShow}
        >
          Visa fler
        </Button>
      </StyledPaginationWrapper>
    )

  const renderExerciseHistoryItem = (
    exercisesForHistory: ExerciseStateWithExerciseData
  ) => {
    const exeData = exercisesForHistory.exerciseData
    const exePath = exercisesForHistory.path
      ? `(${exercisesForHistory.path.join(" / ")})`
      : ""

    return (
      <StyledResultWrapper>
        <ListItemIcon>
          {exeData && <ExerciseTypeIcon type={exeData.type} iconColor={""} />}
        </ListItemIcon>
        <StyledListItemContent>
          <ListItemText
            primary={
              exeData ? (
                <>
                  <b>{renderText(exeData.title)}</b>
                </>
              ) : (
                ""
              )
            }
            secondary={renderText(exePath)}
          />
          <ExerciseResultWithHistory
            type="score"
            exercisesForHistory={exercisesForHistory}
          />
        </StyledListItemContent>
      </StyledResultWrapper>
    )
  }

  const renderText = (htmlText: string) => {
    return <HtmlRenderer html={htmlText} component="span" />
  }

  return (
    <>
      {historyItems.length > 0 ? (
        <StyledHistoryListWrapper>
          <StyledList>
            {historyItems.length > 0 &&
              historyItems.map((historyItem: StudentHistoryItem) => {
                if (historyItem.type === "assignment") {
                  const ap =
                    historyItem.content as AssignmentProgressWithAssignment

                  return (
                    <StyledAssignmentListItem
                      key={uuid()}
                      onClick={() => goToStudentAssignment(ap.assignmentId)}
                    >
                      {renderAssignmentHistoryItem(historyItem)}
                    </StyledAssignmentListItem>
                  )
                }
                if (historyItem.type === "exercise") {
                  const ep =
                    historyItem.content as ExerciseStateWithExerciseData

                  return (
                    <StyledListItem key={uuid()}>
                      {renderExerciseHistoryItem(ep)}
                    </StyledListItem>
                  )
                }

                if (historyItem.type === "goal") {
                  const gp = historyItem.content as GoalProgressWithGoal

                  return (
                    <StyledListItem
                      onClick={() => goToGoal(gp.goalRef)}
                      key={uuid()}
                    >
                      {renderGoalsHistoryItem(gp)}
                    </StyledListItem>
                  )
                }
                if (historyItem.type === "formativeTest") {
                  const test = historyItem.content as FormativeTest

                  return (
                    <StyledAssignmentListItem
                      key={moment(historyItem.sortTime).valueOf()}
                      onClick={() => goToFormativeTest(test.id)}
                    >
                      {renderFormativeHistoryItem(historyItem)}
                    </StyledAssignmentListItem>
                  )
                }

                return null
              })}
          </StyledList>
          {renderMoreButton()}
        </StyledHistoryListWrapper>
      ) : (
        <StyledNoInfo>
          Det finns ännu ingen historik att visa för {member.firstName} i{" "}
          {activeProduct}
        </StyledNoInfo>
      )}
    </>
  )
}

export default StudentHistoryList
