import { ListItem, ListItemProps, styled } from "@mui/material"

export const StyledExerciseListContainer = styled("div")`
  margin-top: 9px;
  margin-right: 10px;
`

export const StyledListItem = styled(ListItem as React.FC<ListItemProps>)`
  padding-left: 0;
`
