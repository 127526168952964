import React from "react"
import { Member } from "../../modules/members/types"
import moment from "moment"
import {
  AssignmentsProgress,
  AssignmentsTaskProgress
} from "../../modules/assignmentsProgress/types"
import { Assignment } from "../../modules/assignments/types"
import { StyledStatusTag } from "./StyledAssignmentStatusAndApprovedTags"

export const getStatusTag = (
  memberId: Member["studliId"],
  assignmentsProgress: AssignmentsProgress,
  assignmentsTaskProgress: AssignmentsTaskProgress,
  assignmentId: Assignment["id"]
) => {
  const currentDate = moment()
  const progressForMember = assignmentsProgress.filter(
    progress =>
      progress.assignmentId === assignmentId && progress.studliId === memberId
  )
  const isDone = progressForMember[progressForMember.length - 1]

  if (isDone && !isDone.rejectedAt) {
    const isToday = currentDate.isSame(isDone.submittedAt, "day")

    if (isToday) {
      return <StyledStatusTag status={"done"}>Inlämnat - idag</StyledStatusTag>
    }

    return (
      <StyledStatusTag status={"done"}>
        {`Inlämnat - ${moment(isDone.submittedAt).fromNow(true)} sedan`}
      </StyledStatusTag>
    )
  }

  const hasStarted = assignmentsTaskProgress
    .filter(
      progress =>
        progress.assignmentId === assignmentId && progress.studliId === memberId
    )
    .sort(
      (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf()
    )
  const started = hasStarted[0]
  if (started) {
    const isToday = currentDate.isSame(started.updatedAt, "day")

    if (isToday) {
      return (
        <StyledStatusTag status={"inProgress"}>Påbörjat - idag</StyledStatusTag>
      )
    }

    return (
      <StyledStatusTag status={"inProgress"}>
        {`Påbörjat - ${moment(started.updatedAt).fromNow(true)} sedan`}
      </StyledStatusTag>
    )
  }

  return <StyledStatusTag status={"notStarted"}>Ej påbörjat</StyledStatusTag>
}
