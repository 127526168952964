import {
  Collapse,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack
} from "@mui/material"
import FilterButton from "./FilterButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import SearchIcon from "@mui/icons-material/Search"
import React from "react"
import { Filters, FilterValue } from "./types"
import { FilterPrimaryButton, StyledFilterCount } from "./shared"

type Props = {
  activeFilters: FilterValue[]
  onToggleFilter: (filter: FilterValue) => void
  filters: Filters[]
  onRemoveFilter: (filter: FilterValue) => void
  clearFilters: () => void
  addSearchText: (event: React.FormEvent<HTMLFormElement>) => void
  setSearchText: (text: string) => void
  searchText: string
  searchLabel: string
}

const DesktopFilter = ({
  activeFilters,
  filters,
  onToggleFilter,
  onRemoveFilter,
  searchLabel,
  searchText,
  setSearchText,
  clearFilters,
  addSearchText
}: Props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <FilterPrimaryButton onClick={() => setOpen(!open)}>
        {<SearchIcon />} Sök och filtrera{" "}
        {activeFilters.length > 0 && (
          <StyledFilterCount sx={{ mr: 0.5 }}>
            {activeFilters.length}
          </StyledFilterCount>
        )}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </FilterPrimaryButton>
      <Collapse sx={{ width: "100%" }} in={open}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            {filters.map(filter => (
              <FilterButton
                key={filter.key}
                filter={filter.options}
                selectLabel={filter.label}
                onFilterChange={onToggleFilter}
                filterKey={filter.key}
                valuesChecked={activeFilters}
              />
            ))}
          </Stack>
          <Stack component="form" onSubmit={addSearchText}>
            <FormControl variant="outlined">
              <OutlinedInput
                size="small"
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                placeholder={searchLabel}
                id="search-filter"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Stack>
        </Stack>
      </Collapse>
    </>
  )
}

export default DesktopFilter
