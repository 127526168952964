import { TableData } from "./types"

export const sortBy = (
  list: TableData[],
  orderBy: string,
  sortType: string
): TableData[] => {
  if (sortType === "lastActive") {
    lastActive(list, sortType)
  }

  if (orderBy === "desc") {
    return desc(list, sortType)
  }
  if (orderBy === "asc") {
    return asc(list, sortType)
  }
  return list
}

const lastActive = (list: TableData[], sortType: string) => {
  list.forEach((s: TableData) => {
    if (s[sortType] === undefined) {
      s[sortType] = "0"
    }
  })
}

const desc = (list: TableData[], sortType: string) => {
  return list.sort((a: TableData, b: TableData) =>
    a[sortType] > b[sortType] ? 1 : -1
  )
}

const asc = (list: TableData[], sortType: string) => {
  return list.sort((a: TableData, b: TableData) =>
    a[sortType] < b[sortType] ? 1 : -1
  )
}
