/**
 * The origin is taken from here: node_modules/moment/locale/sv.js
 * Here modified to suit our preferences.
 */
export const sv = {
  months:
    "januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december".split(
      "_"
    ),
  monthsShort: "jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec".split("_"),
  weekdays: "söndag_måndag_tisdag_onsdag_torsdag_fredag_lördag".split("_"),
  weekdaysShort: "sön_mån_tis_ons_tor_fre_lör".split("_"),
  weekdaysMin: "sö_må_ti_on_to_fr_lö".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "YYYY-MM-DD",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY [kl.] HH:mm",
    LLLL: "dddd D MMMM YYYY [kl.] HH:mm",
    lll: "D MMM YYYY HH:mm",
    llll: "ddd D MMM YYYY HH:mm"
  },
  calendar: {
    sameDay: "[Idag]",
    nextDay: "[Imorgon]",
    lastDay: "[Igår]",
    nextWeek: "[På] dddd",
    lastWeek: "[I] dddd[s]",
    sameElse: "L"
  },
  relativeTime: {
    future: "om %s",
    past: "för %s sedan",
    s: "",
    ss: "%d sek",
    m: "1 min",
    mm: "%d min",
    h: "1 tim",
    hh: "%d tim",
    d: "1 dag",
    dd: "%d d",
    M: "1 mån",
    MM: "%d mån",
    y: "1 år",
    yy: "%d år"
  },
  dayOfMonthOrdinalParse: /\d{1,2}(e|a)/,
  ordinal: function (number: number) {
    const b = number % 10,
      output =
        ~~((number % 100) / 10) === 1
          ? "e"
          : b === 1
          ? "a"
          : b === 2
          ? "a"
          : b === 3
          ? "e"
          : "e"

    return number + output
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
}
