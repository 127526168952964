import React from "react"
import { StyledMuiButton } from "./StyledButton"
import { Props } from "./types"

/**
 * The reasons for wrapping this Material-ui component are:
 * 1) Set default value(s) for some attributes
 * 2) Add additional style via styled
 * 3) Enhance control over attributes, like which are allowed and mandatory
 *
 * @param props
 */
const Button = (props: Props) => {
  const variantFromPropsOrDefault = props.variant || "text"

  return (
    <StyledMuiButton
      variant={variantFromPropsOrDefault}
      disabled={props.disabled}
      {...props}
    >
      {props.children}
    </StyledMuiButton>
  )
}

export default Button
