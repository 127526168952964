import React from "react"
import { connect } from "react-redux"
import * as pages from "../../pages"
import * as routerActions from "../../modules/router/actions"

type Props = {
  route: string
  children?: never
}

const Router = ({ route }: Props) => {
  switch (route) {
    case routerActions.ROUTE_ELEVER:
      return <pages.Members />
    case routerActions.ROUTE_START:
      return <pages.Start />
    case routerActions.ROUTE_MAL:
      return <pages.Goals />
    case routerActions.ROUTE_GOALS_DETAILS:
      return <pages.GoalDetailCard />
    case routerActions.ROUTE_LARARE:
      return <pages.Teachers />
    case routerActions.ROUTE_OVNINGAR:
      return <pages.Exercises />
    case routerActions.ROUTE_UPPDRAG_INFO:
      return <pages.AssignmentCard />
    case routerActions.ROUTE_UPPDRAG_STUDENT_INFO:
      return <pages.AssignmentStudentCard />
    case routerActions.ROUTE_UPPDRAG_LAGGTILL:
      return <pages.PreparedAssignments />
    case routerActions.ROUTE_UPPDRAG_REDIGERA:
      return <pages.CreateEditAssignment />
    case routerActions.ROUTE_UPPDRAG_LAGGTILL_NYTT:
      return <pages.CreateEditAssignment />
    case routerActions.ROUTE_UPPDRAG:
      return <pages.Assignments />
    case routerActions.ROUTE_KLASSRUMSKOD:
      return <pages.ClassroomCode />
    case routerActions.ROUTE_OVNING_INFO:
      return <pages.ExercisesCard />
    case routerActions.ROUTE_ELEVKORT:
      return <pages.StudentCard />
    case routerActions.ROUTE_FORMATIVA_FRAGOR:
      return <pages.FormativeQuestions />
    case routerActions.ROUTE_FORMATIVA_FRAGOR_LAGGTILL:
      return <pages.CreateFormativeQuestion />
    case routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA:
      return <pages.CreateFormativeQuestion />
    case routerActions.ROUTE_FORMATIVA_FRAGOR_INFO:
      return <pages.FormativeQuestionsResult />
    case routerActions.ROUTE_FORMATIVA_FRAGOR_ELEV_INFO:
      return <pages.FormativeQuestionsStudentResult />
    default:
      return <pages.NotFound />
  }
}

type State = {
  location: { type: string }
}

const mapStateToProps = (state: State) => ({
  route: state.location.type
})

export default connect(mapStateToProps)(Router)
