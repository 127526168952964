import React from "react"
import FormikTextEditor from "../FormikTextEditor"
import AudioRecorder from "../AudioRecorder"
import { StyledErrorMessage } from "../AssignmentContentForm/StyledAssignmentContentForm"
import { ErrorMessage, FormikProps, FormikValues, Form } from "formik"
import { DialogActions, DialogContent, styled } from "@mui/material"
import Button from "../Button"
type OwnProps = {
  onClose: () => void
}

const StyledRecorderContainer = styled("div")`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`

type Props = OwnProps & FormikProps<FormikValues>

const AddEndQuestionForm = ({
  values,
  setFieldValue,
  onClose,
  submitForm
}: Props) => {
  const onTextEditorBlur = (name: string, value: any) => {
    if (typeof values[name] === "string") {
      setFieldValue(name, value)

      return
    }
    setFieldValue(name, { ...values[name], text: value })
  }

  const setAudioForForm = async (
    filePath: string | undefined,
    name: string
  ) => {
    if (filePath) {
      setFieldValue(name, { ...values[name], audio: filePath })

      return
    }
    setFieldValue(name, { ...values[name], audio: "" })
  }
  const currentAudio = values["question"].audio ? values["question"].audio : ""
  return (
    <Form>
      <DialogContent>
        <FormikTextEditor
          name="question"
          ariaLabel="Slutfråga"
          value={values.question.text}
          placeholder="Fråga till eleven"
          onBlurHandler={onTextEditorBlur}
        />
        <StyledRecorderContainer>
          <AudioRecorder
            type="standalone"
            formType="question"
            setAudioForForm={setAudioForForm}
            editAudio={currentAudio}
            conversation={false}
          />
        </StyledRecorderContainer>
        <StyledErrorMessage>
          <ErrorMessage name="question" />
        </StyledErrorMessage>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Avbryt
        </Button>
        <Button onClick={() => submitForm()} color="primary" variant="text">
          Lägg till
        </Button>
      </DialogActions>
    </Form>
  )
}

export default AddEndQuestionForm
