import React from "react"
import { ListItemIcon } from "@mui/material"
import { ReactComponent as Folder } from "../../assets/images/folder-closed.svg"
import { ReactComponent as FolderOpen } from "../../assets/images/folder-open.svg"
import {
  StyledIconContainer,
  StyledListItem,
  StyledNumberOfPagesText,
  StyledPrimaryTextContainer,
  StyledTextSegment
} from "./StyledBookPagesListItem"
import {
  ChapterOrPage,
  BookTocId,
  BookChapterNode
} from "../../modules/book/types"
import { ReactComponent as PagesIcon } from "../../assets/images/page-flip.svg"
import Button from "../Button"
import HtmlRenderer from "../HtmlRenderer"

type Props = {
  node: ChapterOrPage
  expandedNodes?: BookTocId[]
  numberOfNodesBelow: number
  isChecked: boolean
  onCheckboxClicked: Function
  onItemClick?: Function
}

const BookPagesListItem = ({
  node,
  expandedNodes = [],
  numberOfNodesBelow,
  onItemClick
}: Props) => {
  const isParent = (node: ChapterOrPage): node is BookChapterNode => {
    if ((node as BookChapterNode).children) {
      return true
    }

    return false
  }

  const renderIcon = () => {
    if (!isParent(node)) {
      return (
        <StyledIconContainer iconColor="#4f93d5">
          <PagesIcon aria-label="Sidor" />
        </StyledIconContainer>
      )
    }

    return (
      <StyledIconContainer iconColor="#5e646c">
        {expandedNodes.some(expandedNode => expandedNode === node.uniqueId) ? (
          <FolderOpen aria-label="Mapp öppen" />
        ) : (
          <Folder aria-label="Mapp" />
        )}
      </StyledIconContainer>
    )
  }

  const rowClicked = () => {
    if (onItemClick && !isParent(node)) {
      onItemClick(node)
    }
  }

  const chooseChapter = (e: any) => {
    e.stopPropagation()
    if (onItemClick) {
      onItemClick(node)
    }
  }

  return (
    <StyledListItem
      onClick={rowClicked}
      component={"div" as any}
      divider={true}
    >
      <ListItemIcon>{renderIcon()}</ListItemIcon>
      <StyledPrimaryTextContainer>
        <StyledTextSegment>
          <HtmlRenderer html={node.title} />
          <StyledNumberOfPagesText>
            {numberOfNodesBelow ? `(${numberOfNodesBelow})` : ""}
          </StyledNumberOfPagesText>
        </StyledTextSegment>
        {isParent(node) && (
          <Button onClick={chooseChapter} variant="outlined" color="primary">
            Välj
          </Button>
        )}
      </StyledPrimaryTextContainer>
    </StyledListItem>
  )
}

export default BookPagesListItem
