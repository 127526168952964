import Typography, { TypographyProps } from "@mui/material/Typography"
import { styled } from "@mui/material"

type Props = {
  strongTxt?: boolean
}

export const StyledProgressHistoryWrapper = styled("div")`
  margin-top: 1rem;
  margin-bottom: 1rem;
`
export const StyledProgressItem = styled("ul")`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
`
export const StyledProgressHistoryMessage = styled("div")`
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`
export const StyledMessage = styled(
  Typography as React.FC<TypographyProps & Props>
)`
  font-weight: ${props => (props.strongTxt ? "600" : "400")};
`
