import {
  ParentOrExerciseNode,
  ParentNode,
  Exercise,
  ExerciseId,
  ExerciseNode
} from "../../../modules/exercises/types"

export const isParent = (node: ParentOrExerciseNode): node is ParentNode => {
  if ((node as ParentNode).children) {
    return true
  }

  return false
}

export const findById = (
  AllExercises: Exercise,
  id: ExerciseId,
  key: string
): any => {
  if (AllExercises.length === 0) return null

  return (
    AllExercises.find((d: any) => d[key] === id) ||
    findById(
      AllExercises.flatMap((d: any) => d["children"] || []),
      id,
      key
    ) ||
    null
  )
}

export const getPathToExercise = (
  AllExercises: Exercise,
  id: ExerciseId,
  key: string
): any => {
  if (AllExercises.length === 0) return null

  return (
    AllExercises.find((d: any) => d[key] === id) ||
    findById(
      AllExercises.flatMap((d: any) => d["children"] || []),
      id,
      key
    ) ||
    null
  )
}

export const buildPathToExercise = (
  exercises: ParentOrExerciseNode,
  exerciseId: ExerciseId,
  currentPath: any = []
) => {
  const findPath = (node: ParentOrExerciseNode, exerciseId: ExerciseId) => {
    if (!isParent(node) && node.id === exerciseId) {
      return [node]
    } else if (isParent(node)) {
      for (const child of node.children) {
        const childPath: any = findPath(child, exerciseId)
        if (Array.isArray(childPath)) {
          childPath.unshift(child)

          return childPath
        }
      }
    }
  }

  const foundPath = findPath(exercises, exerciseId)

  if (Array.isArray(foundPath)) {
    currentPath.push(exercises)
    currentPath.push(...foundPath)
  }
  currentPath.splice(currentPath.length - 1, 1)

  return currentPath
}

type ExePath = {
  exerciseId: ExerciseId
  exercise: ExerciseNode
  path: string[]
}

export const pathsForExercises = (
  exercises: Exercise,
  exercisePaths: ExePath[] = []
) => {
  const buildPath = (
    node: ParentOrExerciseNode,
    exercisePaths: ExePath[],
    path: string[]
  ) => {
    if (!isParent(node) && node.id) {
      exercisePaths.push({
        exerciseId: node.id,
        exercise: node,
        path
      })
    }

    if (isParent(node) && node.children) {
      node.children.forEach((child: ParentOrExerciseNode) => {
        buildPath(child, exercisePaths, [...path, node.title])
      })
    }
  }

  exercises.forEach(exercise => buildPath(exercise, exercisePaths, []))

  return exercisePaths
}
